import {ColSpec} from "../../../../components/table/MithraTableHeadColumns";

export const PARENT_SUPPLIER_COLUMNS: Array<ColSpec> = [
    // {txt: 'p_id', label: 'p_id', minWidth: 170},
    {cls: 'col-dropdown', txt: '', align: 'center', width: 50},
    {cls: 'col-sp_n_suppliers xs-padding', txt: '', align: 'center'},
    {cls: 'col-sp_name', txt: 'Parent supplier', width: 320},
    {cls: 'col-sp_spend', txt: 'Total spend', align: 'right', width: 100},
    {cls: 'col-l1s', txt: 'L1(s) of parent'},
    // {cls: 'col-bus', txt: 'Entity'},
    // {cls: 'col-s_cats', txt: ''},
    // {cls: 'col-s_YN', txt: 'Approve', align: 'right' as const},
]

export const SUPPLIER_COLUMNS: ColSpec[] = [
    {cls: 'col-choose-parent', txt: '', width: 350},
    {cls: 'col-s_name', txt: 'Child supplier', width: 300},
    {cls: 'col-s_spend', txt: 'Spend', align: 'right', width: 105},
    {cls: 'col-s_city', txt: 'Location', width: 100},
    {cls: 'col-s_l1s', txt: 'L1(s) of child', width: 400},
    // {cls: 'col-s_suggestion_score', txt: 'Suggestion score'},
]
