import React from "react";
import './IconStyles.scss';

export const TaxonomyMapperIcon: React.FC = () => {
    return <svg width="50" height="50" viewBox="-5 -5 50 50" className="TaxonomyMapperIcon icon">
        <g transform="translate(-203 -368)">
            <g transform="translate(203 368)"
               fill="none"
               stroke="#193150"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeWidth="1.5">
                <rect x="0.5" y="0.5" width="9" height="39"/>
            </g>
            <g transform="translate(233 368)"
               fill="none"
               stroke="#193150"
               strokeLinecap="round"
               strokeLinejoin="round"
               strokeWidth="1.5">
                <rect x="0.5" y="0.5" width="9" height="39"/>
            </g>
            <g fill="none"
               stroke="#193150"
               strokeLinecap="round"
               strokeWidth="1">
                <line x2="12" transform="translate(217 388)"/>
                <line y1="3" x2="3" transform="translate(217 385)"/>
                <line x1="3" y1="3" transform="translate(226 385)"/>
                <line x1="3" y1="3" transform="translate(217 388)"/>
                <line y1="3" x2="3" transform="translate(226 388)"/>
            </g>
        </g>
    </svg>
}
