import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../constants";
import {toCurrency} from "../../components/currency-component/CurrencyComponent";
import {MatReviewLevelStatisticsTreeSerializer} from "./MaterializedClasses";
import {single_bar_chart} from "../../components/visualization/single-barchart/SingleBarChartBuilder";

export type AiChangeChart = {
    values: single_bar_chart.DataPoint[];
    category: string
    label: string
};

export function findUnclassified(root: MatReviewLevelStatisticsTreeSerializer): MatReviewLevelStatisticsTreeSerializer | null {
    const candidate = root.children.find(n => n.label === UNCATEGORIZED_VALUE)
    if (!candidate) {
        console.warn('Could not find statistics for UNCATEGORIZED')
        return null;
    }
    // We reached the uncat leaf
    if (candidate.children.length === 0) return candidate;
    return findUnclassified(candidate);
}

export function convertSingleToChart(node: MatReviewLevelStatisticsTreeSerializer, currencySymbol: string, includeOld: boolean): AiChangeChart {
    const category = node.label;
    const isUncat = category === UNCATEGORIZED_VALUE;
    const label = isUncat ? UNCATEGORIZED_LABEL : category
    const isDeepest = node.children.length === 0;
    const values = node.values;
    const chartValues: Array<AiChangeChart['values'][number]> = [];
    if (includeOld) {
        chartValues.push({
            dataCls: 'old',
            label: `${toCurrency(currencySymbol, values.pre_spend)}`
                + ` | ${values.pre_parts} parts | ${values.pre_suppliers} suppliers`,
            value: Math.max(0, values.pre_spend),
        })
    } else {
        // Hack to show old as the same size as new
        chartValues.push({
            dataCls: 'old',
            label: `${toCurrency(currencySymbol, values.post_spend)}`
                + ` | ${values.post_parts} parts | ${values.post_suppliers} suppliers`,
            value: Math.max(0, values.post_spend),
        })
    }
    let unCatLabel: string;
    if (typeof values.post_uncategorized_parts !== 'number' || typeof values.post_uncategorized_spend !== 'number') {
        unCatLabel = '';
    } else {
        if (isUncat || isDeepest) {
            unCatLabel = '';
        } else if (values.post_uncategorized_parts === 0) {
            unCatLabel = ` | No uncategorized`
        } else {
            unCatLabel = ` | ${values.post_uncategorized_parts} (${toCurrency(currencySymbol, values.post_uncategorized_spend)}) uncategorized`
        }
    }
    chartValues.push({
        dataCls: 'new',
        label: `${toCurrency(currencySymbol, values.post_spend)}`
            + ` | ${values.post_parts} parts | ${values.post_suppliers} suppliers`
            + unCatLabel,
        value: Math.max(0, values.post_spend),
        subValue: typeof values.post_uncategorized_spend === 'number' ? values.post_uncategorized_spend : undefined,
    })
    return ({
        category,
        label,
        values: chartValues,
    });
}

export function convertToChart(node: MatReviewLevelStatisticsTreeSerializer[], currencySymbol: string): AiChangeChart[] {
    const result = node.map(n => convertSingleToChart(n, currencySymbol, true));
    result.sort((a, b) => b.values[1].value - a.values[1].value)
    return result
}

export function convertToChartOnlyNew(node: MatReviewLevelStatisticsTreeSerializer[], currencySymbol: string): AiChangeChart[] {
    let result = node.map(n => convertSingleToChart(n, currencySymbol, false));

    // Hack to only show positive values
    result = result.filter(n => n.values[0].value > 0);
    result.sort((a, b) => b.values[1].value - a.values[1].value)

    return result
}
