import {JobStatusEnum} from "./StatusEnums";

export declare type Bag = JobSerializer;

export interface SimpleJobStatusSerializer {
    timestamp: string;
    status: JobStatusEnum;
    task: string;
    name: JobStatusEnum;
}

export interface JobSerializer {
    id: number;
    name: string;
    timestamp: string;
    current_status: SimpleJobStatusSerializer;
    current_taxonomy_id: number | null;
    taxonomy_size: number;
    latest_version: number // Bag ID (or current job id)
    baseline: number // Bag ID
    hidden: boolean
}

export declare type JobInputIndex = number; // An index of the Job.inputs array

/**
 * @deprecated Bags are read beforehand now... But this will come back in the future
 */
export function canChangeOnServer(j: Bag) {
    switch (j.current_status.status) {
        case JobStatusEnum.B_CONFIGURING:
        case JobStatusEnum.B_PROCESSING:
        case JobStatusEnum.B_BUSY:
            return true;
        case JobStatusEnum.B_READY:
        case JobStatusEnum.B_ERROR:
        case JobStatusEnum.B_DISCARDED:
            return false;
    }
}
