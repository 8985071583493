import './MarkOpportunityButton.scss';
import React from "react";
import {CheckCircleOutline} from "@mui/icons-material";

type Props = {
    isMarked: boolean,
    onMark?: () => void
    disabled?: boolean
}
export const MarkOpportunityButton: React.FC<Props> = ({isMarked, onMark, disabled}) => {
    return <div className="mark-opportunity-button">
        <CheckCircleOutline
            className={
                'marked'
                + (isMarked ? ' active' : ' inactive')
                + (onMark ? ' clickable' : '')
                + (disabled ? ' disabled' : '')
            }
            onClick={onMark}
        />
    </div>;
}
