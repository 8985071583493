import React from "react";
import {FormControl, InputLabel, MenuItem, SelectChangeEvent, Typography} from "@mui/material";
import Select from "@mui/material/Select";

const menuId = 'dashboard-type-menu';

export type DashboardTypeItem<T extends string> = {
    key: T
    label: string
    icon?: React.ReactNode
}

type Props<T extends string = string> = {
    items: DashboardTypeItem<T>[]
    selectedKey?: T
    onSelect: (key: string) => void
}
export const DashboardTypeMenu: React.FC<Props> = ({items, selectedKey, onSelect}) => {
    const onChange = (event: SelectChangeEvent) => {
        const key = event.target.value as string;
        onSelect(key);
    }
    return <>
        <FormControl
            sx={{ m: 1, minWidth: 80 }}
            variant="outlined"
        >
            <InputLabel id={menuId + "-label"}></InputLabel>
            <Select
                labelId={menuId + "-label"}
                id={menuId}
                value={selectedKey || ''}
                autoWidth
                onChange={onChange}
            >
                {items.map(item =>
                    <MenuItem
                        key={item.key}
                        value={item.key}
                    >
                        {item.icon}
                        <Typography variant="inherit">{item.label}</Typography>
                    </MenuItem>
                )}
            </Select>
        </FormControl>
        {/*<IconButton*/}
        {/*    // edge="end"*/}
        {/*    // size="medium"*/}
        {/*    // aria-label="account"*/}
        {/*    aria-controls={isMenuOpen ? menuId : undefined}*/}
        {/*    aria-haspopup="true"*/}
        {/*    aria-expanded={isMenuOpen ? 'true' : undefined}*/}
        {/*    onClick={handleClickOpenMenu}*/}
        {/*    color="inherit">*/}
        {/*    */}
        {/*</IconButton>*/}
        {/*<Menu*/}
        {/*    anchorEl={menuElement}*/}
        {/*    anchorOrigin={{vertical: 'top', horizontal: 'right'}}*/}
        {/*    id={menuId}*/}
        {/*    keepMounted*/}
        {/*    transformOrigin={{vertical: 'top', horizontal: 'right'}}*/}
        {/*    open={isMenuOpen}*/}
        {/*    onClose={handleMenuClose}*/}
        {/*    TransitionComponent={Grow}*/}
        {/*>*/}
        {/*    {items.map(item =>*/}
        {/*        <MenuItem*/}
        {/*            key={item.key}*/}
        {/*            onClick={item.onClick}*/}
        {/*            selected={item.key === active}*/}
        {/*        >*/}
        {/*            {item.icon}*/}
        {/*            <Typography variant="inherit">{item.label}</Typography>*/}
        {/*        </MenuItem>*/}
        {/*    )}*/}
        {/*</Menu>*/}
    </>
}
