import * as d3 from "d3";
import {interpolateRgb} from "d3";
import {darken, lighten, Theme} from "@mui/material";
import {PaletteOptions} from "@mui/material/styles/createPalette";

// https://material.io/design/color/the-color-system.html
// https://material.io/resources/color/
// https://color.adobe.com/

const SPOT_COLOR_1 = '#193150';
const SPOT_COLOR_2 = '#436185';
const SPOT_COLOR_2_LIGHT = '#75a6e6';
const SPOT_COLOR_3 = '#DBF2FF';
const SPOT_COLOR_4 = '#DEA01E';
const SPOT_COLOR_5 = '#D3DCE6';

const COLOR_BEIGE_BLACK = '#0d0c0a';

export const COLOR_OK = '#279400';
export const COLOR_WARN = SPOT_COLOR_4;
export const COLOR_CRITICAL = '#E62D15';

export const COLOR_UNCATEGORIZED = '#de4b1e';

export const colors: PaletteOptions = {
    // // Version 1
    // primary: {
    //     light: '#3f6f83',
    //     main: '#0a4456',
    //     dark: '#001d2d',
    //     contrastText: '#ffe7c9',
    // },
    // secondary: {
    //     light: '#76d5ff',
    //     main: '#37a4df',
    //     dark: '#0075ad',
    //     contrastText: '#000000',
    // }

    // Version 2
    primary: {
        main: SPOT_COLOR_1,
    },
    secondary: {
        main: SPOT_COLOR_2,
        light: SPOT_COLOR_2_LIGHT,
    },
    text: {
        primary: '#193150',
        secondary: 'rgba(25,48,79,0.54)',
    },
    success: {
        main: SPOT_COLOR_4,
        contrastText: COLOR_BEIGE_BLACK,
    },
    warning: {
        main: COLOR_WARN,
        contrastText: COLOR_BEIGE_BLACK,
    },
    error: {
        main: COLOR_CRITICAL,
        contrastText: COLOR_BEIGE_BLACK,
    },
    info: {
        main: SPOT_COLOR_3,
        contrastText: COLOR_BEIGE_BLACK,
    },
    grey: {
        50: lighten(SPOT_COLOR_5, 1 / 2),
        100: SPOT_COLOR_5,
        200: darken(SPOT_COLOR_5, 1 / 9),
        300: darken(SPOT_COLOR_5, 2 / 9),
        400: darken(SPOT_COLOR_5, 3 / 9),
        500: darken(SPOT_COLOR_5, 4 / 9),
        600: darken(SPOT_COLOR_5, 5 / 9),
        700: darken(SPOT_COLOR_5, 6 / 9),
        800: darken(SPOT_COLOR_5, 7 / 9),
        900: darken(SPOT_COLOR_5, 8 / 9),
        // 50: lighten(SPOT_COLOR_5, 2.5 / 3),
        // 100: lighten(SPOT_COLOR_5, 2 / 3),
        // 200: lighten(SPOT_COLOR_5, 1 / 3),
        // 300: SPOT_COLOR_5,
        // 400: darken(SPOT_COLOR_5, 1 / 7),
        // 500: darken(SPOT_COLOR_5, 2 / 7),
        // 600: darken(SPOT_COLOR_5, 3 / 7),
        // 700: darken(SPOT_COLOR_5, 4 / 7),
        // 800: darken(SPOT_COLOR_5, 5 / 7),
        // 900: darken(SPOT_COLOR_5, 6 / 7),
    }
};

// https://github.com/d3/d3-scale-chromatic/blob/master/README.md
export const MAIN_COLOR = SPOT_COLOR_1;
const categoryColors = [SPOT_COLOR_1, SPOT_COLOR_2_LIGHT]
const supplierColors = ['#DBF2FF', '#EEE7C9']
export const SUPPLIER_CUMULATIVE_COLOR = darken(categoryColors[0], 0.5);
// export const PARETO_IDEAL_COLOR = darken(SPOT_COLOR_4, 0.2);
export const PARETO_IDEAL_COLOR = SPOT_COLOR_4;

export const categoryColorGradient = interpolateRgb.gamma(2.2)(categoryColors[0], categoryColors[1]);
export const supplierColorGradient = interpolateRgb.gamma(2.2)(supplierColors[0], supplierColors[1]);
// export const primaryColorScheme = interpolateRgbBasis(interpolateColors);
// export const purpleColorScheme = interpolatePurples;
export const primaryColorGradient = categoryColorGradient;

export const attentionColor = SPOT_COLOR_4;

const colorSteps = 15;
export const vizColor = d3.scaleOrdinal([...Array(colorSteps)].map((_, i) =>
    // primaryColorGradient(i / (colorSteps - 1) * 0.3)
    primaryColorGradient(i / (colorSteps - 1))
));
export const oldVizColor = d3.scaleOrdinal([...Array(colorSteps)].map((_, i) =>
    interpolateRgb.gamma(2.2)('#76d5ff', '#0075ad')(i / (colorSteps - 1))
));
export const catColor = d3.scaleOrdinal([...Array(colorSteps)].map((_, i) =>
    categoryColorGradient(i / (colorSteps - 1) * 0.2)
));

export function getColorByCat(category: string | null): string {
    if (category === '' || category === null) {
        return COLOR_UNCATEGORIZED;
    } else {
        return vizColor(category);
    }
}

export function getColorMonotone(theme: Theme | any, category: string | null): string {
    if (category === '' || category === null) {
        return COLOR_UNCATEGORIZED;
    }
    if (!theme.palette?.primary?.main) {
        console.warn('Migration breaking change from MUI v4')
        return SPOT_COLOR_1;
    }
    return theme.palette.primary.main;
}

export const HIGHLIGHT_COLOR = COLOR_WARN;
