import {TaxonomyMapperStore} from "./TaxonomyMapperStore";
import {makeAutoObservable} from "mobx";
import {Subject} from "rxjs";
import {
    CollapsibleIndentTreeBuilder,
    FilterSpecification
} from "../../../components/visualization/collapsible-indent-tree/CollapsibleIndentTree";
import {
    MatchNodeDataType,
    Tree
} from "../../../components/visualization/match-categories-tree-v2/MatchCategoriesTreeVisualization";

import Fuse from "fuse.js";

const ENABLE_FUSE: boolean = true;
const FUSE_OPTIONS: Fuse.IFuseOptions<any> = {
    threshold: .15,
};

type FilterSource = {
    byLevel: Subject<number>
}

export class TaxonomyMapFilterDelegate {
    /**
     * @deprecated This is a temporary solution. We should be able to get the taxonomy size from the API
     */
    public static readonly LEFT_TAXONOMY_SIZE = 3;
    /**
     * @deprecated This is a temporary solution. We should be able to get the taxonomy size from the API
     */
    public static readonly RIGHT_TAXONOMY_SIZE = 3;

    leftSearchString = ''
    private readonly leftFilters = {
        byLevel: new Subject<number>(),
    };
    leftHits: number | undefined;

    rightSearchString = ''
    private readonly rightFilters = {
        byLevel: new Subject<number>(),
    };
    rightHits: number | undefined;

    leftLastSelectedLevel = TaxonomyMapFilterDelegate.LEFT_TAXONOMY_SIZE - 1
    rightLastSelectedLevel = TaxonomyMapFilterDelegate.RIGHT_TAXONOMY_SIZE - 1

    highlightUnmatched = false;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private taxonomyMapperStore: TaxonomyMapperStore,
    ) {
        makeAutoObservable(this)
    }

    reset() {
        this.leftLastSelectedLevel = TaxonomyMapFilterDelegate.LEFT_TAXONOMY_SIZE - 1
        this.rightLastSelectedLevel = TaxonomyMapFilterDelegate.RIGHT_TAXONOMY_SIZE - 1
        this.highlightUnmatched = false;
        this.setSearch(true, '');
        this.setSearch(false, '');
    }

    setSearch(left: boolean, search: string) {
        if (left) {
            this.leftSearchString = search;
            this.leftHits = undefined;
        } else {
            this.rightSearchString = search;
            this.rightHits = undefined;
        }
    }

    setAndClearRightSearch(search: string) {
        this.setSearch(false, search);
        this.implClearSearch(false);
    }

    clearSearch(left: boolean) {
        if (left) {
            this.highlightUnmatched = false;
        }
        this.setSearch(left, '')
        this.implClearSearch(left); // Already clears all the highlighting
    }

    doSearch(left: boolean) {
        if (left) {
            this.highlightUnmatched = false;
        }
        let searchString = left ? this.leftSearchString : this.rightSearchString;
        searchString = searchString.toLowerCase();
        if (!searchString) {
            this.implClearSearch(left)
        } else {
            this.implSearch(left, searchString)
        }
    }

    private implClearSearch(left: boolean) {
        const treeController: CollapsibleIndentTreeBuilder<MatchNodeDataType> =
            left ? this.taxonomyMapperStore.treeController.controller.leftTree
                : this.taxonomyMapperStore.treeController.controller.rightTree;

        treeController.beforeEach(d => {
            d.data.highlighted = false;
        });
        this.taxonomyMapperStore.treeController.controller.resetYAxis()
        this.taxonomyMapperStore.treeController.controller.redraw()
    }

    private implSearch(left: boolean, searchString: string) {
        const treeController: CollapsibleIndentTreeBuilder<MatchNodeDataType> =
            left ? this.taxonomyMapperStore.treeController.controller.leftTree
                : this.taxonomyMapperStore.treeController.controller.rightTree;

        let hits = 0;
        const openNodes = new Set<number>();
        if (ENABLE_FUSE) {
            // TODO: Very hacky this is
            const nodes: Tree[] = [];
            treeController.beforeEach(n => nodes.push(n))
            const fuse = new Fuse(nodes.map(n => n.data.label), FUSE_OPTIONS)
            const result = fuse.search(searchString)
            hits = result.length;
            const resultIds = new Set<number>(result.map(r => nodes[r.refIndex].data.id));

            treeController.beforeEach(d => {
                if (resultIds.has(d.data.id)) {
                    // Match on search
                    d.data.highlighted = true;
                    // Open this node and all it's parents
                    d.ancestors().forEach(p => openNodes.add(p.data.id));
                } else {
                    d.data.highlighted = false;
                }
            })
        } else {
            treeController.beforeEach(d => {
                const label = d.data.label?.toLowerCase() || '';
                if (label.includes(searchString)) {
                    // Match on search
                    d.data.highlighted = true;
                    // Open this node and all it's parents
                    hits += 1;
                    d.ancestors().forEach(p => openNodes.add(p.data.id));
                } else {
                    d.data.highlighted = false;
                }
            })
        }
        if (hits === 0) {
            // no match
        } else {
            treeController.openOnlyNode(d => openNodes.has(d.data.id))
            this.taxonomyMapperStore.treeController.controller.resetYAxis()
            this.taxonomyMapperStore.treeController.controller.redraw()
        }
        if (left) this.leftHits = hits
        else this.rightHits = hits;
    }

    /**
     * @deprecated Move to treeController
     */
    collapseLeft(level: number) {
        // Due to FRAN-19 bug we have to press it twice
        this.leftFilters.byLevel.next(level)
        this.leftFilters.byLevel.next(level)
    }

    /**
     * @deprecated Move to treeController
     */
    collapseRight(level: number) {
        // Due to FRAN-19 bug we have to press it twice
        this.rightFilters.byLevel.next(level)
        this.rightFilters.byLevel.next(level)
    }

    /**
     * @deprecated Move to treeController
     */
    getLeftAsFilterSpecification(): FilterSpecification {
        return TaxonomyMapFilterDelegate.constructFilterSpec(this.leftFilters);
    }

    /**
     * @deprecated Move to treeController
     */
    getRightAsFilterSpecification(): FilterSpecification {
        return TaxonomyMapFilterDelegate.constructFilterSpec(this.rightFilters);
    }

    /**
     * @deprecated Move to treeController
     */
    private static constructFilterSpec(s: FilterSource): FilterSpecification {
        return {
            byLevel: s.byLevel,
        }
    }

    setLeftLastSelectedLevel(level: number) {
        this.leftLastSelectedLevel = level
    }

    setRightLastSelectedLevel(level: number) {
        this.rightLastSelectedLevel = level
    }

    setHighlightUnmatched(highlight: boolean) {
        this.highlightUnmatched = highlight;

        const treeController = this.taxonomyMapperStore.treeController.controller.leftTree;
        if (highlight) {
            const nodeIds = this.taxonomyMapperStore.mapper.getLeftUnmatchedNodeIds()
            treeController.beforeEach(d => {
                if (nodeIds.includes(d.data.id)) {
                    // Open and highlight this one
                    d.data.highlighted = true;
                    d.ancestors().forEach(p => CollapsibleIndentTreeBuilder.setNodeCollapsed(p, false))
                } else {
                    // Close the rest
                    d.data.highlighted = false;
                    CollapsibleIndentTreeBuilder.setNodeCollapsed(d, true)
                }
            })
        } else {
            // do not highlight any node anymore
            treeController.beforeEach(d => {
                d.data.highlighted = false;
            });
        }
        this.taxonomyMapperStore.treeController.controller.setYOffsets(0, 0)
        this.taxonomyMapperStore.treeController.controller.resetYAxis()
        this.taxonomyMapperStore.treeController.controller.redraw()
    }
}
