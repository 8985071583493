import {GraphResponse, MatKpiTreeValues} from "../../../services/ApiTypes";
import React, {useEffect, useMemo, useRef} from "react";
import * as d3 from "d3";
import {range, sum} from "d3";
import * as d3Sankey from "d3-sankey";
import {SankeyGraph, SankeyLayout, SankeyLink, SankeyNode} from "d3-sankey";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import './HierarchicalSankeyChart.scss';
import {deepCopy} from "../../../utils/js-utils";
import Margin from "../../../utils/margin";
import {D3GSelection} from "../../../utils/global";
import {environment} from "../../../env";
import {kpiToDataFormat} from "../../../services/ApiHelpers";
import {useStores} from "../../../stores";
import {DEFAULT_SANKEY_VISIBILITY_FRACTION} from "../../../stores/ProfileStore";

namespace sankey {
    const showDebugValues = false;

    type NodeId = string;
    type NodeType = { name: string, nodeId: NodeId, taxonomyLevel: number };
    type _ExtendedNode = NodeType & { value?: number };
    type LinkType = { source: NodeId, target: NodeId, value: number };

    type SNode = SankeyNode<NodeType, LinkType>
    type SLink = SankeyLink<NodeType, LinkType>
    type SGraph = SankeyGraph<NodeType, LinkType>;
    type SLayout = SankeyLayout<SGraph, NodeType, LinkType>

    /**
     * - `depth` the level of the tree that we are in (0 => leaf)
     * - `height` is the number of grandchildren (0 => root)
     */
    type ProcessedSNode = SNode & { x0: number, x1: number, y0: number, y1: number, depth: number, height: number }
    type ProcessedSLink = Omit<SLink, 'source' | 'target'> & { source: ProcessedSNode, target: ProcessedSNode }
    type ProcessedSGraph = { nodes: ProcessedSNode[], links: ProcessedSLink[] };
    type NodeSelection = D3GSelection<ProcessedSNode>
    type LinksSelection = D3GSelection<ProcessedSLink>
    type _Data = { nodes: NodeType[], links: LinkType[] }

    export class SankeyTreeBuilder {
        private nodesGroup: NodeSelection;
        private linksGroup: LinksSelection;
        private headerGroup: D3GSelection<any>;

        private layout: SLayout;
        private graph: ProcessedSGraph;

        private mostLeftLevel = 1
        private readonly dataFormat: "n" | "currency" | "?";

        private readonly graphWidth: number;
        private readonly graphHeight: number;

        constructor(
            private readonly root: D3GSelection<any>,
            private inputData: _Data,
            private kpi: MatKpiTreeValues | undefined,
            private options: HierarchicalSankeyOptions,
            private currencyFormat: Intl.NumberFormat,
        ) {
            const height = options.height === 'auto' ? 100 : options.height;
            let graphWidth = options.width - options.margin.left - options.margin.right
            const graphHeight = height - options.headerHeight - options.margin.top - options.margin.bottom
            let marginLeft = options.margin.left;
            let marginRight = options.margin.left;

            if (options.maxDepth === 2) {
                const extraMargin = graphWidth / 3;
                marginLeft += extraMargin / 2;
                marginRight += extraMargin / 2;
                graphWidth = options.width - marginLeft - marginRight;
            }

            // // DEBUG: show margins
            // svg.append('rect')
            //     .attr('x', marginLeft)
            //     .attr('y', options.margin.top + options.headerHeight)
            //     .attr('width', graphWidth)
            //     .attr('height', graphHeight);

            this.graphHeight = graphHeight;
            this.graphWidth = graphWidth;
            this.layout = this.buildSankeyLayout()
            this.dataFormat = kpi ? kpiToDataFormat(kpi) : '?';

            this.root
                .classed('collapsible-indent-tree', true)
                .attr("transform", "translate(" + marginLeft + "," + options.margin.top + ")");

            this.linksGroup = this.root.append('g')
                // .attr('id', ID_PREFIX + 'links')
                .attr('class', 'links')
                .selectAll('g')
            this.nodesGroup = this.root.append('g')
                // .attr('id', ID_PREFIX + 'nodes')
                .attr('class', 'nodes')
                .selectAll('g')
            this.headerGroup = this.root.append('g')
                .attr('class', 'headers')
                .selectAll('text')

            const pre = SankeyTreeBuilder.preProcessData(this.inputData, this.options.removeRoot, this.options.maxDepth)
            this.inputData = pre.data;
            this.graph = this.applySankeyLayout(deepCopy(inputData)); // Can this not be used from the `pre` variable?
            this.postProcessData()

            this.createNodes()
            this.createLinks()
            this.joinHeader()
        }

        static parseData(data: GraphResponse): _Data {
            return {
                links: data.links.map(d => ({
                    source: `id_${d.source}`,
                    target: `id_${d.target}`,
                    value: d.value,
                })),
                nodes: data.nodes.map(d => ({
                    name: d.name,
                    nodeId: `id_${d.nodeId}`,
                    taxonomyLevel: -10,
                }))
            }
        }

        private createNodes() {
            const nodesData = this.graph.nodes;
            const update: NodeSelection = this.nodesGroup.data(nodesData, d => d.nodeId)
            const enter: NodeSelection = update.enter()
                .append('g')
                .classed('node', true)
                .classed('clickable', d => d.height > 0)
                .classed('is-leaf', d => d.height === 0)
                .classed('is-root', d => d.depth === 0)
                .classed('uncat', d => d.name === UNCATEGORIZED_VALUE)
            const onClickNode = this.onClickNode.bind(this);
            enter.on('click', function () {
                onClickNode(this);
            })
            enter.append('rect')
                .attr('x', d => d.x0 || 0)
                .attr('y', d => d.y0 || 0)
                .attr('height', d => (d.y1 || 0) - (d.y0 || 0))
                .attr('width', d => (d.x1 || 0) - (d.x0 || 0))
            enter.append('text')
                .attr('x', d => (d.x0 || 0) - 6)
                // .attr('x', d => (d.x0 || 0) + 15)
                // .attr('fill', 'white')
                .attr('y', d => ((d.y1 || 0) + (d.y0 || 0)) / 2)
                .attr('dy', '0.35em')
                .attr('text-anchor', 'end')
                .classed('overflow-label', (d: _ExtendedNode) =>
                    d.value ? d.value <= this.options.minValueForLabel : true
                )
                .text((d) => {
                    if (showDebugValues)
                        return `${d.name} (h=${d.height} d=${d.depth})`

                    const d2 = d as _ExtendedNode
                    if (!d) return '';
                    if (d2.name === UNCATEGORIZED_VALUE) return UNCATEGORIZED_LABEL;
                    if (d2.value === undefined) return d2.name
                    return d2.name;
                })
            enter.append('title')
                .text(d => {
                    if (this.dataFormat === 'currency') {
                        return `${d.name}\n${this.currencyFormat.format((d as any).value)}`
                    }
                    return `${d.name}\n${(d as any).value}`;
                })
            return [enter, update]
        }

        private updateNodes(clickedNode: ProcessedSNode | undefined) {
            const [enter, update] = this.createNodes()
            update.exit().remove()
            const next = update.merge(enter)
            next
                .classed('selected', d => d.nodeId === clickedNode?.nodeId)
            next.select('rect')
                .attr('x', d => d.x0 || 0)
                .attr('y', d => d.y0 || 0)
                .attr('height', d => (d.y1 || 0) - (d.y0 || 0))
                .attr('width', d => (d.x1 || 0) - (d.x0 || 0))
            next.select('text')
                .attr('x', d => (d.x0 || 0) - 6)
                .attr('y', d => ((d.y1 || 0) + (d.y0 || 0)) / 2)
        }

        private createLinks() {
            const linksData = this.graph.links;
            const update = this.linksGroup.data(linksData, d => d.source.nodeId + '|' + d.target.nodeId) as LinksSelection

            const enter = update.enter()
                .append('g')
                .classed('link', true)
                .classed('uncat', d => d.source.name === UNCATEGORIZED_VALUE || d.target.name === UNCATEGORIZED_VALUE)

            enter.append('path')
                .attr('d', d3Sankey.sankeyLinkHorizontal())
                .attr('stroke-width', function (d: any) {
                    return Math.max(1, d.width);
                })
            if (!environment.production) console.log(
                'createLinks enter, update', enter.size(), update.size()
            )
            return [enter, update]
        }

        private updateLinks() {
            // console.log('updateLinks', c.graph.links.length);
            const [enter, update] = this.createLinks()
            const exit = update.exit();
            const next = update.merge(enter)
            if (!environment.production) console.log(
                'updateLinks exit, next', exit.size(), next.size()
            )
            exit.remove()
            next.select('path')
                .attr('d', d3Sankey.sankeyLinkHorizontal())
                .attr('stroke-width', function (d: any) {
                    return Math.max(1, d.width);
                })
        }

        private joinHeader() {
            // HEADERS
            const maxDepth = Math.max(...this.graph.nodes.map(n => n.height || 0))
            const labelsData = [...Array(maxDepth + 1)].map((_, i) => ({
                label: `L${this.mostLeftLevel + i}`,
                x: this.options.nodeWidth / 2 + (i) * (this.graphWidth - this.options.nodeWidth) / maxDepth,
                // // Start at L1
                // label: `L${i + 1}`,
                // x: this.nodeWidth / 2 + (i + 1) * (_GRAPH_WIDTH - this.nodeWidth) / maxDepth,
            }));
            this.headerGroup.data(labelsData, d => d.label)
                .join(
                    // https://observablehq.com/@d3/selection-join
                    // https://observablehq.com/@d3/learn-d3-joins?collection=@d3/learn-d3
                    enter => enter.append('text')
                        .attr('text-anchor', 'middle')
                        .attr('x', d => d.x)
                        .attr('y', -this.options.headerHeight / 2)
                        .text(d => d.label),
                    update => update
                        .attr('x', d => d.x),
                    exit => exit.remove(),
                )
        }

        private onClickNode(clickedThis: any) {
            let nextMostLeftLevel;
            const clickedNode = d3.select(clickedThis).datum() as ProcessedSNode;

            const isLeaf = clickedNode.height === 0
            const isTop = clickedNode.depth === 0
            const isRoot = this.isSingleRoot(clickedNode)
            console.log(`onClickNode (leaf=${isLeaf} top=${isTop} root=${isRoot})`, clickedNode);

            if (isLeaf) {
                // When a leaf is pressed, don't do anything
                return
            }
            if (isRoot) {
                // When the only value at the left is clicked, just redraw the whole graph from the start again
                // TODO: Maybe consider going up and down a level?

                // Nuke approach: just redraw everything
                const _inputData = deepCopy(this.inputData);
                let newData = SankeyTreeBuilder.dataFilterAddParent(_inputData, clickedNode)
                if (newData.nodes.length === 0) {
                    newData = _inputData;
                    nextMostLeftLevel = 1
                } else {
                    nextMostLeftLevel = clickedNode.taxonomyLevel - 1;
                }

                this.mostLeftLevel = nextMostLeftLevel
                this.layout = this.buildSankeyLayout()
                this.graph = this.applySankeyLayout(newData)

                this.nodesGroup = this.root.select('.nodes').selectAll('.node')
                this.linksGroup = this.root.select('.links').selectAll('.link')
                this.headerGroup = this.root.select('.headers').selectAll('text')

                this.updateNodes(undefined);
                this.updateLinks();
                this.joinHeader()

            } else {
                if (isTop) {
                    // A node at the far left is clicked, but there are multiple nodes to the left
                } else {
                    // Some intermediate node is clicked
                }
                // In both cases, make the selected node the new root

                const newData = SankeyTreeBuilder.rootDataFilter(deepCopy(this.inputData), clickedNode)
                nextMostLeftLevel = clickedNode.taxonomyLevel;

                // // OPTION1 (possible to add animation)
                // const layout = c.layout;
                // const graph = layout.update(newData) as SGraph;

                // OPTION2
                this.mostLeftLevel = nextMostLeftLevel
                this.layout = this.buildSankeyLayout()
                this.graph = this.applySankeyLayout(newData)

                this.nodesGroup = this.root.select('.nodes').selectAll('.node')
                this.linksGroup = this.root.select('.links').selectAll('.link')
                this.headerGroup = this.root.select('.headers').selectAll('text')

                this.updateNodes(clickedNode);
                this.updateLinks();
                this.joinHeader()
            }
        }

        private applySankeyLayout(data: _Data): ProcessedSGraph {
            const graph = this.layout(data) as SGraph;
            // const maxDepth = Math.max(...graph.nodes.map(n => n.height || 0))
            // const levels = graph.links.reduce((arr, link) => {
            //     const depth = (link.target.depth || 1) - 1;
            //     const value = link.value;
            //     console.log('depth', depth)
            //     arr[depth].push(value);
            //     return arr;
            // }, [...Array(maxDepth)].map(() => [] as number[]))
            //
            // const nItems = levels.map(ls => ls.length);
            // const maxItemsPerRow = Math.max(...nItems);
            //
            //
            // const sLevels = levels.map(ls => ls.sort((a, b) => a - b))
            // const totals = sLevels.map(ls => sum(ls))
            // const totalPixels = sLevels.map(ls => sum(ls) + ls.length * MARGIN)
            // const maxPixels = Math.max(...totalPixels);
            // const max = Math.max(...sLevels.map(l => l[0] || 1))
            // const min = Math.min(...sLevels.map(l => l.length === 0 ? 1 : l[l.length - 1]))
            //
            // console.log('totalPixels', totalPixels, 'maxPixels', maxPixels);
            return graph as ProcessedSGraph;
        }

        private static minimalSankey(): SLayout {
            return d3Sankey.sankey<NodeType, LinkType>()
                .nodeId(d => d.nodeId as NodeId)
                .nodeAlign(d3Sankey.sankeyCenter)
                // .nodeAlign(d3Sankey.sankeyRight)
                // .nodeAlign(d3Sankey.sankeyJustify)
                .nodeSort((a: any, b: any) => {
                    if (!a.code || !b.code) return 0;
                    const aParentCode = a.code.substring(0, a.code.length - 3);
                    const bParentCode = b.code.substring(0, b.code.length - 3);
                    const diffP = bParentCode - aParentCode;
                    if (diffP === 0) {
                        return b.value - a.value;
                    }
                    return diffP;
                })
        }

        private buildSankeyLayout(): SLayout {
            return SankeyTreeBuilder.minimalSankey()
                .nodeWidth(this.options.nodeWidth)
                .nodePadding(0.8)
                .size([this.graphWidth, this.graphHeight])
        }

        private static dataFilterAddParent(graph: _Data, child: ProcessedSNode) {
            // Do the same as rootDataFilter but with all parents

            const parentIds = graph.links.filter(link => link.target === child.nodeId).map(link => link.source);
            const parents = graph.nodes.filter(node => parentIds.includes(node.nodeId));

            const seenNodesIds = new Set<NodeId>(parentIds); // All nodes visited
            let nextNodes = parents; // nodes to visit in next iteration
            const result: _Data = {links: [], nodes: parents};
            SankeyTreeBuilder._get_all_children(nextNodes, graph, result, seenNodesIds);
            if (!environment.production) console.log(
                'dataFilter(', graph.nodes.length, graph.links.length, '->', result.nodes.length, result.links.length
            );
            return result;

        }

        private static _dataFilterRight = (graph: _Data, rootId: NodeId): _Data => {
            const links = graph.links.filter(link => link.source === rootId);
            const nodeIds = new Set<NodeId>(links.map(link => link.target));
            const nodes = graph.nodes.filter(node => nodeIds.has(node.nodeId))
            return {nodes, links};
        }
        private static rootDataFilter = (graph: _Data, newRoot: NodeType): _Data => {
            if (!newRoot) return graph;

            const seenNodesIds = new Set<NodeId>([newRoot.nodeId]); // All nodes visited
            let nextNodes = [newRoot]; // nodes to visit in next iteration
            const result: _Data = {links: [], nodes: [newRoot]};
            SankeyTreeBuilder._get_all_children(nextNodes, graph, result, seenNodesIds);
            console.log('rootDataFilter(', graph.nodes.length, graph.links.length, '->', result.nodes.length, result.links.length);
            return result;
        };

        private static _get_all_children(nextNodes: NodeType[], graph: _Data, result: _Data, seenNodesIds: Set<NodeId>) {
            while (nextNodes.length > 0) {
                const additions = nextNodes.map(n => SankeyTreeBuilder._dataFilterRight(graph, n.nodeId));
                result.links.push(...additions.flatMap(g => g.links)) // TODO: This could duplicate links
                nextNodes = []
                for (const g of additions) {
                    for (const n of g.nodes) {
                        if (seenNodesIds.has(n.nodeId)) {
                            // ignore already seen nodes
                        } else {
                            // Check this node in the new iteration
                            nextNodes.push(n);
                            seenNodesIds.add(n.nodeId);
                        }
                    }
                }
                result.nodes.push(...nextNodes)
            }
        }

        public static calcHeightEstimate(sGraph: ProcessedSGraph, maxDepth: number, minVisibilityFraction: number): number {
            const nodesPerLevel = range(maxDepth).map(i => sGraph.nodes.filter(n => n.depth === i))

            const nPerLevel = nodesPerLevel.map(ns => ns.length)
            const minSizeByLabels = Math.max(...nPerLevel) * 20

            const heightsPerLevel: number[][] = nodesPerLevel.map(ns => ns.filter(n => n.value && n.value !== 0).map(n => n.value as number))
            const minHeightPortionsPerLevel = heightsPerLevel.map(heights => {
                heights = heights.sort((a, b) => -(b - a));
                const n = Math.floor(heights.length * minVisibilityFraction);
                const lowHeight = heights[n];
                console.log(heights, '->', lowHeight)
                return lowHeight / sum(heights);
            })
            const minHeightPortion = Math.min(...minHeightPortionsPerLevel);
            const minSizeByPortion = 20 / minHeightPortion;

            const heightEstimate = Math.max(minSizeByLabels, minSizeByPortion);
            console.log({minSizeByLabels, minSizeByPortion});

            return heightEstimate;
        }

        /**
         * Process the data before the layout is calculated
         */
        public static preProcessData(inputData: _Data, removeRoot: boolean, maxDepth: number): { data: _Data, graph: ProcessedSGraph } {
            console.time('SankeyDiagram.preProcessing')
            let roots = [] as any[];
            let removeNodes = new Set();
            if (removeRoot) {
                // Find all destination nodes
                const nodesWithIn = new Set<NodeId>(inputData.links.map(l => l.target));
                const nodesWithOut = new Set<NodeId>(inputData.links.map(l => l.source));
                roots = Array.from(nodesWithOut).filter(n => !nodesWithIn.has(n))

                inputData.nodes = inputData.nodes.filter(n => !roots.includes(n.nodeId))
                inputData.links = inputData.links.filter(l => !roots.includes(l.source))

                // const mapToParents = new Map<NodeId, Set<NodeId>>(); // child -> parents
                // inputData.links.forEach(l => {
                //     let parents = mapToParents.get(l.target)
                //     if(!parents) {
                //         parents = new Set([l.source])
                //         mapToParents.set(l.target, parents)
                //     } else {
                //         parents.has(l.source)
                //     }
                // })
                // const roots = new Set<NodeId>();
                // mapToParents.forEach((value, key) => {
                //     value.forEach(v => {
                //         if()
                //     })
                // })
            }
            const graph = SankeyTreeBuilder.minimalSankey()(deepCopy(inputData)) as ProcessedSGraph

            if (maxDepth !== -1) {
                // Limit the depth of the Sankey Diagram to the taxonomy size
                removeNodes = new Set(graph.nodes.filter(n => n.depth >= maxDepth).map(n => n.nodeId))
                inputData.nodes = inputData.nodes.filter(n => !removeNodes.has(n.nodeId))
                inputData.links = inputData.links.filter(l => !removeNodes.has(l.source) && !removeNodes.has(l.target))
            }

            console.timeEnd('SankeyDiagram.preProcessing')
            return {data: inputData, graph};
        }

        /**
         * Process the data, after the layout if calculated
         */
        private postProcessData() {
            console.log('postProcessData')

            // Store the taxonomyLevel in the input data
            this.inputData.nodes.forEach(inputNode => {
                const graphNode = this.graph.nodes.find(_n => _n.nodeId === inputNode.nodeId)
                if (!graphNode) {
                    console.log('Could not determine position of node', inputNode)
                } else {
                    const taxonomyLevel = graphNode.depth + 1;
                    inputNode.taxonomyLevel = taxonomyLevel
                    graphNode.taxonomyLevel = taxonomyLevel
                }
            })
        }

        private isSingleRoot(clickedNode: ProcessedSNode) {
            if (clickedNode.depth === 0) {
                // It is at the top
                // But it might not be the only one
                const siblingRoots = this.graph.nodes.filter(n => n.depth === 0 && n.nodeId !== clickedNode.nodeId)
                if (siblingRoots.length === 0) {
                    return true
                }
            }
            return false
        }
    }
}

type RequiredOptions = 'maxDepth'

export interface HierarchicalSankeyOptions {
    width: number,
    height: 'auto' | number,
    headerHeight: number,
    nodeWidth: number,
    margin: Margin,
    removeRoot: boolean,
    minValueForLabel: number,
    maxDepth: number,
}

export type Options =
    Partial<Omit<HierarchicalSankeyOptions, RequiredOptions>>
    & Pick<HierarchicalSankeyOptions, RequiredOptions>;
type Props = {
    data: GraphResponse,
    kpi?: MatKpiTreeValues,
    options?: Options
};
export const HierarchicalSankeyChart: React.FC<Props> = ({data, kpi, options}) => {
    const svgRef = useRef<SVGSVGElement>(null)
    const {p} = useStores();

    const o = useMemo<HierarchicalSankeyOptions>(() => ({
            width: 1000,
            height: 'auto',
            headerHeight: 20,
            nodeWidth: 50,
            margin: {
                left: 100,
                right: 5,
                top: 5,
                bottom: 5,
            },
            removeRoot: false,
            minValueForLabel: 0,
            maxDepth: -1,
            ...options,
        }
    ), [options])
    const inputData = sankey.SankeyTreeBuilder.parseData(data)
    const pre = sankey.SankeyTreeBuilder.preProcessData(deepCopy(inputData), o.removeRoot, o.maxDepth);
    if (o.height === 'auto') {
        const minVisibilityFraction = p.p.sankeyVisibilityFraction || DEFAULT_SANKEY_VISIBILITY_FRACTION;
        o.height = sankey.SankeyTreeBuilder.calcHeightEstimate(pre.graph, o.maxDepth, minVisibilityFraction);
    }
    if (p.p.sankeyMinHeight !== undefined) {
        o.height = Math.max(o.height, p.p.sankeyMinHeight)
    }
    if (p.p.sankeyMaxHeight !== undefined) {
        o.height = Math.min(o.height, p.p.sankeyMaxHeight)
    }

    useEffect(() => {
        if (!inputData || !svgRef.current) {
            return;
        }
        const svg = d3.select(svgRef.current as SVGElement);
        svg.html(''); // clear
        const root = svg.append("g")
        new sankey.SankeyTreeBuilder(root, inputData, kpi, o, p.currencyFormat);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputData, kpi, o])

    return <svg className="hierarchical-sankey-chart" ref={svgRef} viewBox={`0 0 ${o.width} ${o.height}`}/>;
};
