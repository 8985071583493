import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Collapse, IconButton, TableCell, TableRow} from "@mui/material";
import {ExpandMore, KeyboardArrowRight} from "@mui/icons-material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {ParentSupplierReviewSubTable} from "./ParentSupplierReviewSubTable";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../constants";
import {getSpend, ParentSupplierReviewRowState,} from "../SupplierNormalization.classes";
import {getCollapseAnimationTiming} from "../SupplierNormalization.utils";
import {PARENT_SUPPLIER_COLUMNS} from "./columns";
import {useStores} from "../../../../stores";
import {MithraTableCell} from "../../../../components/table/MithraTableCell";
import {GoogleLink} from "../../../../components/_tiny/GoogleLink";

type Props = {
    row: ParentSupplierReviewRowState,
    forceOpen?: boolean
};
export const ParentSupplierReviewMainRow: React.FC<Props> = observer(({row, forceOpen}) => {
    const {supplierNormalizationStore} = useStores();
    const review = supplierNormalizationStore.review;
    const [open, setOpen] = React.useState(false);
    const nSubRows = row.subRows?.length;

    useEffect(() => {
        if (!open && review.allOpen === true)
            setOpen(true);
        if (open && review.allOpen === false)
            setOpen(false);
    }, [open, review.allOpen]);

    return <>
        <TableRow className="main-row">
            <MithraTableCell c={PARENT_SUPPLIER_COLUMNS[0]}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                        review.setAllOpen(null);
                        setOpen(!open);
                    }}
                    disabled={row.subRows === null}>
                    {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            </MithraTableCell>
            <MithraTableCell c={PARENT_SUPPLIER_COLUMNS[1]}>
                <GoogleLink type="parent_supplier" objs={[row]}>
                    {row.sp_name}
                </GoogleLink>
            </MithraTableCell>
            <MithraTableCell c={PARENT_SUPPLIER_COLUMNS[2]}>
                <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                    <CurrencyComponent v={getSpend(row)} abbreviation={abbreviation}/>
                }</CurrencyAbbreviationContext.Consumer>
            </MithraTableCell>
            <MithraTableCell c={PARENT_SUPPLIER_COLUMNS[3]}>
                {row.sp_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
            </MithraTableCell>
            {/*{row.s_total_bus.map(l => l === null ? 'None' : l).join(', ')}*/}
        </TableRow>
        <TableRow>
            <TableCell
                className="cell-sub-table"
                colSpan={PARENT_SUPPLIER_COLUMNS.length}
                style={{paddingLeft: PARENT_SUPPLIER_COLUMNS[0].width}}>
                {nSubRows !== undefined && nSubRows > 0 &&
                    <Collapse in={open || forceOpen} timeout={getCollapseAnimationTiming(row.subRows)} unmountOnExit>
                        <ParentSupplierReviewSubTable data={row}/>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})
