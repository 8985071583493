import {from, map, Subscription} from "rxjs";
import {AxiosResponse} from "axios";
import {PageResponse} from "../../services/ApiTypes";
import {makeAutoObservable, runInAction, toJS} from "mobx";

export class PageResponseManager<RequestData = void, Data = unknown, ResponseData = Data> {
    data?: Data[]

    isLoading = false
    page = 1;
    count = 0;
    hasNext = false;
    hasPrev = false;

    private lastRequestData: RequestData | undefined;

    req?: Subscription;

    constructor(
        readonly pageSize: number,
        private readonly requester: (page: number, request: RequestData) => Promise<AxiosResponse<PageResponse<ResponseData>>>,
        private readonly parser?: (data: ResponseData, request: RequestData) => Data,
    ) {
        makeAutoObservable(this, {req: false})
    }

    init(request: RequestData) {
        console.log('init', request);
        this.page = 1;
        this.count = 0;
        this.hasNext = false;
        this.hasPrev = false;
        this.data = undefined;
        this._request(request);
    }

    _request(request: RequestData) {
        this.lastRequestData = request;
        this.isLoading = true;
        if (this.req) this.req.unsubscribe();

        this.req = from(this.requester(this.page, request))
            .pipe(map(resp => {
                console.assert(Array.isArray(resp.data.results))
                if (resp.data.results.length === 0) {
                    // No data present
                    return ({data: [], count: 0, hasNext: false, hasPrev: false});
                }
                let data: Data[];
                if (this.parser) {
                    const parser = this.parser.bind(this);
                    data = resp.data.results.map(r => parser(r, request) as Data) as Data[];
                } else {
                    data = resp.data.results as unknown as Data[];
                }
                return ({
                    data: data,
                    count: resp.data.count,
                    hasNext: Boolean(resp.data.next),
                    hasPrev: Boolean(resp.data.previous),
                });
            }))
            .subscribe({
                next: ({data, count, hasNext, hasPrev}) => this.setData(data, count, hasNext, hasPrev),
                complete: () => runInAction(() => {
                    this.isLoading = false;
                })
            })
    }

    setData(data: Data[] | undefined, count: number, hasNext: boolean, hasPrev: boolean) {
        this.data = data;
        this.count = count;
        this.hasNext = hasNext;
        this.hasPrev = hasPrev;
    }

    changePage(page: number) {
        if (!this.lastRequestData) {
            console.warn('Cannot change page', page, this.lastRequestData);
            return
        }
        this.setPage(page, this.lastRequestData);
    }

    setPage(page: number, request: RequestData) {
        request = toJS(request);
        console.log('setPage', {page, request});
        this.page = page
        this._request(request);
    }

    reset() {
        this.page = 1;
        this.count = 0;
        this.hasNext = false;
        this.hasPrev = false;
        this.data = undefined;
        this.isLoading = false;
        this.lastRequestData = undefined;
        if (this.req) this.req.unsubscribe();
    }

    reloadView() {
        if (this.lastRequestData) {
            this._request(this.lastRequestData);
        }
    }
}
