import './TaxonomySuggestor.scss';
import React, {useMemo} from "react";
import ProfileStore from "../../stores/ProfileStore";
import {ColSpec, MithraTableHeadColumns} from "../../components/table/MithraTableHeadColumns";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {PartReCategorizationControllerStore} from "../../components/categorization/PartReCategorizationControllerStore";
import {PartReCategorizationHandler} from "../../components/categorization/PartRecategorizationHandler";
import {Table, TableBody, TableCell, TableRow} from "@mui/material";
import {MithraTableCell} from "../../components/table/MithraTableCell";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {ResultTableRows} from "../../stores/TaxonomySuggestorStore";


export function getDatasetTargetColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-p_description', txt: p.partDescriptionColumnName},
        {cls: 'col-s_name', txt: p.supplierDataName},
        {cls: 'col-d_source', txt: 'Dataset source', width: 300},
        {cls: 'col-p_spend', txt: 'Amount', width: 300},
        {cls: 'col-p_date', txt: 'Date', width: 300},
    ]
    return arr.filter(c => c) as ColSpec[];
}

export const TaxonomySuggestionTable: React.FC = observer(() => {
    const {p, categorizationStore, categorizationReviewStore} = useStores()

    const controller: any = useMemo(() => {
        if (!categorizationReviewStore.categories.taxonomy_categories) {
            if (categorizationReviewStore.categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categorizationReviewStore.categories.taxonomy_categories);
    }, [categorizationReviewStore.categories.taxonomy_categories])

    const isLoading = categorizationStore.data.isLoadingParts;
    const tableClass = 'mithra-table x-design x-design-narrow' + (isLoading ? ' loading' : '');

    // const data = dataIngestionStore.dataMappingResultTableFormatted;
    const data: ResultTableRows[] = [
        {
            p__description: "Product A",
            d__source: "Source 1",
            s__name: "Supplier X",
            p__spend: 100,
            p__purchase_date: "2023-05-01",
        },
        {
            p__description: "Product B",
            d__source: "Source 2",
            s__name: "Supplier Y",
            p__spend: 50,
            p__purchase_date: "2023-04-15",
        },
        {
            p__description: "Product C",
            d__source: "Source 1",
            s__name: "Supplier Z",
            p__spend: 200,
            p__purchase_date: "2023-05-10",
        },
        {
            p__description: "Product D",
            d__source: "Source 3",
            s__name: "Supplier X",
            p__spend: 80,
            p__purchase_date: "2023-04-28",
        },
        {
            p__description: "Product E",
            d__source: "Source 2",
            s__name: "Supplier Y",
            p__spend: 120,
            p__purchase_date: "2023-05-05",
        },
        {
            p__description: "Product F",
            d__source: "Source 1",
            s__name: "Supplier Z",
            p__spend: 150,
            p__purchase_date: "2023-04-20",
        },
        {
            p__description: "Product G",
            d__source: "Source 3",
            s__name: "Supplier X",
            p__spend: 70,
            p__purchase_date: "2023-05-07",
        },
        {
            p__description: "Product H",
            d__source: "Source 2",
            s__name: "Supplier Y",
            p__spend: 90,
            p__purchase_date: "2023-04-25",
        },
        {
            p__description: "Product I",
            d__source: "Source 1",
            s__name: "Supplier Z",
            p__spend: 180,
            p__purchase_date: "2023-05-14",
        },
        {
            p__description: "Product J",
            d__source: "Source 3",
            s__name: "Supplier X",
            p__spend: 110,
            p__purchase_date: "2023-05-03",
        },
        // Add more mock data entries as needed
    ];

    const COLUMNS = getDatasetTargetColumns(p)

    return <div className={tableClass}>
        {controller && <PartReCategorizationHandler controller={controller}/>}
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody className="categorization-review-table-body">
                {data ? <>
                        {data.map((row, i) => <React.Fragment key={i}>
                            <TaxonomySuggestionRow part={row} controller={controller}/>
                        </React.Fragment>)}
                        {data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
        </Table>
    </div>
})

type Props = {
    part: any
    controller: PartReCategorizationControllerStore // TODO: Move to context
};
export const TaxonomySuggestionRow: React.FC<Props> = observer(({part, controller}) => {
    const {p} = useStores();
    const COLUMNS = getDatasetTargetColumns(p);

    let i = 0;

    return <TableRow>
        {p.p.p_description_col_name !== false &&
            <MithraTableCell c={COLUMNS[i++]}>
                {part.p__description ? part.p__description : 'N/A'}
            </MithraTableCell>
        }
        <MithraTableCell c={COLUMNS[i++]}>
            {part.s__name ? part.s__name : 'N/A'}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.d__source ? part.d__source : 'N/A'}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.p__spend}></CurrencyComponent>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.p__purchase_date ? part.p__purchase_date : 'N/A'}
        </MithraTableCell>
    </TableRow>
})