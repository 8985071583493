import React from "react";
import {generatePath, useHistory} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {CmpDataBagRouteMatch, routes, TaxonomyRouteMatch} from "../../../routing/routes";
import {Button, Grid, Typography} from "@mui/material";
import {MenuItem} from "../../../components/main/MenuItems";

export const MainMenuSynergyComponent: React.FC = () => {
    // const {p} = useStores();
    const history = useHistory();
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();
    const hasId1 = m.params.id !== undefined;
    // const hasId2 = m.params['id2'] !== undefined;
    let menu: MenuItem[] = [
        {
            label: 'Data',
            link: hasId1 ? generatePath(routes.synergy_v3_data, m.params) : routes.synergy_v3,
            active: (
                m.path === routes.synergy_v3
                || m.path === routes.synergy_v3_data
            ),
            // className: '',
        },
        {
            label: 'Synergy Analytics',
            link: hasId1 ? generatePath(routes.synergy_v3_dashboard, m.params) : routes.synergy_v3,
            active: (
                m.path === routes.synergy_v3_dashboard
                || m.path === routes.synergy_v3_suppliers
                || m.path === routes.synergy_v3_categories
                || m.path === routes.synergy_v3_spend
            ),
            // className: '',
        },
        {
            label: 'Merge',
            link: hasId1 ? generatePath(routes.synergy_merge_dashboard, m.params) : routes.synergy_v3,
            active: (
                m.path === routes.synergy_merge_dashboard
                || m.path === routes.synergy_merge_taxonomy_viewer
                || m.path === routes.synergy_merge_taxonomy_viewer_id
                || m.path === routes.synergy_merge_taxonomy_builder
                || m.path === routes.synergy_merge_taxonomy_builder_id
                || m.path === routes.synergy_merge_review
                || m.path === routes.synergy_merge_review_result
            ),
            // className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.synergy_v3_opportunities_id, m.params) : routes.synergy_v3_opportunities,
            active: (
                m.path === routes.synergy_v3_opportunities
                || m.path === routes.synergy_v3_opportunities_id
            ),
            // className: 'menu-item-2nd-last',
        },
        {
            label: 'Report',
            link: hasId1 ? generatePath(routes.synergy_v3_report, m.params) : routes.synergy_v3,
            active: (
                m.path === routes.synergy_v3_report
            ),
            // className: 'menu-item-last',
        },
    ];
    return <Grid container className="main-menu-buttons">
        {menu.map(({label, link, active, isTrial, className}, i) =>
            <Grid item key={i} className={className}>
                <Button
                    variant="text"
                    color="inherit"
                    onClick={() => history.push(link)}
                    className={'main-menu-button' + (active ? ' active' : '')}
                    disabled={Boolean(isTrial)}
                >
                    <Typography variant="h5" className="button-text">
                        {label}
                    </Typography>
                </Button>
            </Grid>
        )}
    </Grid>
}
