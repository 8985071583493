import {observer} from "mobx-react-lite";
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import React from "react";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {useStores} from "../../../stores";
import {DataColumn, getValDataTable} from "../../../components/data-table/DataTable";

const PPV_TABLE_COLUMNS: DataColumn[] = [
    // Should this not be removed? Because by definition there should be as many fields in this column, as the group size.
    // That ranges from 3 to 830891 for the first page already
    {
        dataIndex: 'group_key',
        headerText: 'Mat desc', // TODO: Move to ProfileStore
        ltr: true,
    },
    {
        dataIndex: 'ppv_opportunity',
        headerText: 'Price variance cost',
        dataCategory: 'currency',
        help: 'Total opportunity if all transactions were at the minimal unit price',
    },
    {
        dataIndex: 'spend_sum',
        headerText: 'Total spend',
        dataCategory: 'currency',
        help: 'Sum of all spend',
    },

    {
        dataIndex: 'unit_cost_min',
        headerText: 'Minimal cost',
        dataCategory: 'currency',
        help: 'Minimal unit cost'
    },
    {
        dataIndex: 'unit_cost_avg',
        headerText: 'Average unit cost',
        dataCategory: 'currency',
        help: 'The average unit cost in all transactions'
    },

    // {
    //     dataIndex: 'unit_cost_max',
    //     headerText: 'Max cost',
    //     dataCategory: 'currency',
    //     help: 'Maximal price for unit cost'
    // },

    {
        dataIndex: 'n_supplier_ids',
        headerText: 'Suppliers',
    },
    {
        dataIndex: 'group_size',
        headerText: 'Transactions',
        help: 'Total number of transactions',
    },

    {
        dataIndex: 'quantity_sum',
        headerText: 'Total units',
        help: 'Sum of all the quantities',
    },

    // Remove for now
    // {
    //     dataIndex: 'ppv_delta',
    //     headerText: 'PV_DELTA?',
    //     dataCategory: 'currency',
    // },
    // {
    //     dataIndex: 'ppv',
    //     headerText: 'Price variance',
    //     dataCategory: 'currency',
    // },
    // {dataIndex: 'unit_cost__opportunity', headerText: ''}, // Same, but different calculation method
];

export const PpvTable = observer(() => {
    const {ppvControllerStore} = useStores();
    const paginator = ppvControllerStore._ppvGroupRequestManager
    const isLoading = !paginator || paginator.isLoading;
    const columns = PPV_TABLE_COLUMNS;
    const data = ppvControllerStore.ppvTable;
    return <TableContainer className={'mithra-table x-design parts-table' + (isLoading ? ' loading' : '')}>
        <Table cellPadding="0">
            <TableHead>
                <TableRow>
                    {columns.map(col =>
                        <TableCell
                            key={col.dataIndex}
                            className={'col-' + col.dataIndex + (col.help ? ' col-help' : '')}
                            align={col.ltr ? 'left' : 'right'}
                            title={col.help} // Tooltip
                        >
                            {col.headerText}
                        </TableCell>)}
                </TableRow>
                <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
                    <td colSpan={columns.length}>
                        {isLoading && <LinearProgress variant="indeterminate"/>}
                    </td>
                </tr>
            </TableHead>
            <TableBody className="ppv-table-body">
                {data ?
                    <>
                        {data.map((d, di) =>
                            <TableRow key={d.group_key}>
                                {columns.map((col, index) =>
                                    <TableCell
                                        key={di + '_' + col.dataIndex}
                                        className={'col-' + col.dataIndex}
                                        scope={index === 0 ? 'row' : undefined}
                                        align={col.ltr ? 'left' : 'right'}
                                        component={index === 0 ? 'th' : undefined}>
                                        {getValDataTable(col, d)}
                                    </TableCell>)}
                            </TableRow>
                        )}
                        {data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={columns.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        //
                        rowsPerPageOptions={[]}
                        colSpan={columns.length}
                        count={paginator.count}
                        rowsPerPage={paginator.pageSize}
                        page={paginator.page - 1}
                        // SelectProps={{
                        //     inputProps: {'aria-label': 'rows per page'},
                        //     native: true,
                        // }}
                        // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                        onPageChange={(e, page) => paginator.changePage(page + 1)}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
