import React from "react";
import {Typography} from "@mui/material";
import {AcceptRejectButtons} from "./AcceptRejectButtons";
import {CombinedFeedbackState} from "../../stores/approval/CategorizationReviewFeedbackDelegate";

type Props = {
    onAccept?: () => void
    onReject?: () => void
    state: CombinedFeedbackState
}
export const CombinedApprovalButtons: React.FC<Props> = ({onAccept, onReject, state}) => {
    // TODO: CAT-548
    const isAccept = state.btn === 'accept' || state.btn === 'both';
    const isReject = state.btn === 'reject' || state.btn === 'both';
    let extra: JSX.Element;
    switch (state.extra) {
        case "mixed_states":
            extra = <Typography>Mixed</Typography>
            break;
        case "":
            extra = <></>;
            break;
    }
    return <>
        {state.btn !== 'hidden' &&
            <AcceptRejectButtons
                isAccept={isAccept}
                isReject={isReject}
                onAccept={onAccept}
                onReject={onReject}
                byOther={state.feedbackByOther}
            />
        }
        {extra}
    </>
}
