import React from "react";
import {OpenInNew} from "@mui/icons-material";
import {useStores} from "../../stores";
import {MatPartReviewRow} from "../../services/classes/MaterializedClasses";
import {SearchConfiguration, SearchType} from "../../customization/SearchConfiguration";
import {SingularMatPartReviewRowState, SomeMatSupplierReviewRow} from "../../services/classes/MatReviewClasses";
import {
    ParentSupplierReviewRowSerializer
} from "../../pages/supplier-normalization/pages/SupplierNormalization.classes";

type Props = {
    type: SearchType
    objs: any[]
}
export const GoogleLink: React.FC<Props> = ({type, objs, children}) => {
    const {p} = useStores();
    if (p.p.searchConfiguration) {
        const {query, fullLink} = buildGoogleQuery(type, p.p.searchConfiguration, objs);
        if (query) {
            const url = `https://www.google.com/search?q=${encodeURIComponent(query)}`;
            if (fullLink) {
                return <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer">
                    {children}
                </a>
            } else {
                return <div
                    className="link-secondary">
                    {children}
                    <a
                        className="link-secondary-icon"
                        href={url}
                        target="_bank"
                        rel="noopener noreferrer">
                        <OpenInNew fontSize="inherit"/>
                        {/*<CallMade fontSize="inherit"/>*/}
                        {/*<Google fontSize="inherit"/>*/}
                    </a>
                </div>
            }
        }
    }
    return <>{children}</>
}

type Q = { query: string | null, fullLink: boolean };

function buildGoogleQuery(type: SearchType, config: SearchConfiguration, objs: any[]): Q {
    switch (config) {
        case 'default_p_desc':
            switch (type) {
                case 'part':
                    const part = objs[0] as MatPartReviewRow;
                    return {query: part.p_description, fullLink: false};
                case 'part_with_supplier':
                    const partWithSupplier = objs[0] as SingularMatPartReviewRowState & { s_name: string };
                    return {query: partWithSupplier.s_name, fullLink: false};
                case "supplier":
                    const supplier = objs[0] as SomeMatSupplierReviewRow;
                    return {query: supplier.s_name, fullLink: false};
                case "parent_supplier":
                    const parentSupplier = objs[0] as ParentSupplierReviewRowSerializer;
                    return {query: parentSupplier.sp_name, fullLink: false};
            }
            break
        case 'context1':
            switch (type) {
                case 'part':
                    const p_part = objs[0] as MatPartReviewRow;
                    return {query: p_part.p_description, fullLink: false};
                case 'part_with_supplier':
                    const partWithSupplier = objs[0] as SingularMatPartReviewRowState & { s_name: string };
                    return {
                        query: partWithSupplier.s_name + ' ' + partWithSupplier.p_context_1,
                        fullLink: false,
                    };
                case "supplier":
                    const supplier = objs[0] as SomeMatSupplierReviewRow;
                    const firstPart = objs.at(1) as MatPartReviewRow;
                    return {
                        query: supplier.s_name + (firstPart ? (' ' + firstPart.p_context_1) : ''),
                        fullLink: false,
                    };
                case "parent_supplier":
                    const parentSupplier = objs[0] as ParentSupplierReviewRowSerializer;
                    return {query: parentSupplier.sp_name, fullLink: false};
            }
            break
    }
}
