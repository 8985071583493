import {observer} from "mobx-react-lite";
import {FormControl, IconButton, Input, InputAdornment, InputLabel} from "@mui/material";
import {Clear, Search} from "@mui/icons-material";
import React from "react";
import {useStores} from "../../../stores";

export const SpendConcentrationSearchComponent = observer(() => {
    const {spendConcentrationStore} = useStores();
    return <FormControl variant="standard" fullWidth={true} className="search-field">
        <InputLabel htmlFor="supplier-search-field">
            Vendor search
            {/*{categorizationStore.data.filter.hits !== undefined &&*/}
            {/*    ` (${categorizationStore.data.filter.hits} result${categorizationStore.data.hits.leftHits === 1 ? '' : 's'})`*/}
            {/*}*/}
        </InputLabel>
        <Input
            id="supplier-search-field"
            type="text"
            fullWidth={true}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="clear search"
                        onClick={() => spendConcentrationStore.search.clearSearch()}
                    >
                        <Clear/>
                    </IconButton>
                    <IconButton
                        aria-label="search"
                        onClick={() => spendConcentrationStore.search.doSearch()}
                    >
                        <Search/>
                    </IconButton>
                </InputAdornment>
            }
            value={spendConcentrationStore.search.searchString}
            onChange={e => spendConcentrationStore.search.setSearch(e.target.value)}
            onKeyDown={e => e.key === 'Enter' ? spendConcentrationStore.search.doSearch() : undefined}
        />
    </FormControl>
})