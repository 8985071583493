/* eslint-disable @typescript-eslint/no-unused-vars */
import {AppConfiguration} from "./app-configuration-types";

export const C: AppConfiguration = {
    package: 'merge_x',  // This is a copy of Nestle
    dashboardVariant: 'simple',
    menu: 'hidden',
    profile: {
        p_name_col_name: 'PO Number',
        p_description_col_name: 'Description',
        locale: 'en-gb',
        currency: 'GBP',
        hardcodedSynergyId: 2,
        hardcodeMasterBagId: 1500,
        hardcodedSubsidiaryBagId: 1501,
        hardcodedCombinedBagId: 1521, // Supplier data with only common supplier in the results
        hardcodedCategorizationBagId: 1503,
        masterTaxonomyId: 1,
        hardcodedBaseTaxonomyId: 8, // Used for Taxonomy Mapper & Taxonomy Builder
        categorizationShowScoreForBag: [1501, 1503],
        disableRunningOfModels: true,
        masterCompanyName: 'Liberty Global',
        compareCompanyName: 'Egg',
        taxonomyBuilderHeight: 1600, // Fine-tuned labels barely show for 1490
        trialCards: [
            'koi-spend-segmentation',
            'koi-ppv',
        ],
        categorizationShowScore: false,
        skipAiSupplierNormalizationModel: true,
    },
}
