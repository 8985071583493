import React, {useEffect} from "react";
import {useStores} from "../../../stores";
import {Alert, Grid} from "@mui/material";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {DataColumn, DataTable} from "../../../components/data-table/DataTable";
import './KOISupplierSegmentation.scss';
import {SupplierSegmentationHelp} from "./SupplierSegmentationHelp";
import {from} from "rxjs";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from "../../../routing/routes";
import {generateHomePath} from "../../../routing/routing.utils";

const tableColumns: DataColumn[] = [
    {
        dataIndex: 's_id',
        headerText: 'Supplier name',
        ltr: true,
    },
    {
        dataIndex: 'p_spend',
        headerText: 'Total spend',
        dataCategory: 'currency',
    },
    {
        dataIndex: 's_n_parts',
        headerText: 'Number of transactions',
        // dataCategory: 'currency',
    },
    {
        dataIndex: 's_n_cats',
        // headerText: 'Number of sub-categories',
        headerText: 'Number of entities',
        help: 'Number of lowest level category',
    },

    {
        dataIndex: 'cum_p_spend_perc',
        headerText: 'Cumulative spend',
        dataCategory: 'percentage',
    },
    {
        dataIndex: 's_seg',
        headerText: 'Proposed segmentation',
        ltr: true,
    },
]

// type KeyValues = {
//     totalSuppliers: number,
//     nOpportunitySuppliers: string,
//     totalSpend: number,
//     opportunity: number,
// };
// const HARDCODED_KEY_VALUES: KeyValues = {
//     totalSuppliers: 127,
//     nOpportunitySuppliers: '68 (57%)',
//     totalSpend: 52_400_000,
//     opportunity: 1_750_000,
// }

export const KOISupplierSegmentation: React.FC = () => {
    const {rootAppStore, bagStore, materializedApi} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const bagId = bagStore.bagId;

    // const [keyValues, setKeyValues] = React.useState<KeyValues | undefined>(undefined);

    const [pvvTable, setPpvTable] = React.useState<any[] | undefined>(undefined);
    const [pvvTableError, setPpvTableError] = React.useState('');
    useEffect(() => {
        const sub = from(materializedApi.listSupplierSegmentationResult(bagId)).subscribe({
            next: r => {
                setPpvTable(r.data);
                setPpvTableError('');
            },
            error: err => setPpvTableError(String(err))
        })
        // For the keyValues:
        // const sub2 = from()
        return () => sub.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bagId])

    return <KoiFacade
        title="Supplier Segmentation"
        helpContent={<SupplierSegmentationHelp/>}
        intro={<></>}
        back={generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)}
        className="koi-supplier-seg-page"
    >
        <div className="page-item">

            {/*<Grid container justifyContent="space-evenly">*/}
            {/*    /!*<Grid item xs={2}/>*!/*/}
            {/*    <Grid item xs={9}*/}
            {/*          container justifyContent="space-around" style={{alignContent: 'space-around'}} alignItems="center">*/}

            {/*        <Grid className="key-value-container text-center font-bigger"*/}
            {/*              item xs={11}*/}
            {/*              container spacing={4}>*/}
            {/*            /!* Row 1 *!/*/}
            {/*            <Grid item*/}
            {/*                  container*/}
            {/*                  style={{justifyContent: "space-between"}}>*/}
            {/*                <Grid item>*/}
            {/*                    <Typography variant="caption">*/}
            {/*                        Total<br/>*/}
            {/*                        suppliers*/}
            {/*                    </Typography>*/}
            {/*                    <Typography variant="h5" className="font-weight-bold key-value">*/}
            {/*                        {keyValues*/}
            {/*                            ? `${keyValues.totalSuppliers}`*/}
            {/*                            : <>&nbsp;</>}*/}
            {/*                    </Typography>*/}
            {/*                </Grid>*/}
            {/*                <Grid item>*/}
            {/*                    <Typography variant="caption">*/}
            {/*                        Suppliers in <br/>*/}
            {/*                        opportunity*/}
            {/*                    </Typography>*/}
            {/*                    <Typography variant="h5" className="font-weight-bold key-value">*/}
            {/*                        {keyValues*/}
            {/*                            ? `${keyValues.nOpportunitySuppliers}`*/}
            {/*                            : <>&nbsp;</>}*/}
            {/*                    </Typography>*/}
            {/*                </Grid>*/}
            {/*                <Grid item>*/}
            {/*                    <Typography variant="caption">*/}
            {/*                        Total<br/>*/}
            {/*                        spend*/}
            {/*                    </Typography>*/}
            {/*                    <Typography variant="h5" className="font-weight-bold key-value">*/}
            {/*                        {keyValues*/}
            {/*                            ? <CurrencyComponent v={keyValues.totalSpend} nDigits={3}/>*/}
            {/*                            : <>&nbsp;</>}*/}
            {/*                    </Typography>*/}
            {/*                </Grid>*/}
            {/*                <Grid item>*/}
            {/*                    <Typography variant="caption">*/}
            {/*                        Spend in <br/>*/}
            {/*                        opportunity*/}
            {/*                    </Typography>*/}
            {/*                    <Typography variant="h5" className="font-weight-bold key-value">*/}
            {/*                        {keyValues*/}
            {/*                            ? <CurrencyComponent v={keyValues.opportunity} nDigits={3}/>*/}
            {/*                            : <>&nbsp;</>}*/}
            {/*                    </Typography>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*            /!*<Grid item*!/*/}
            {/*            /!*      container*!/*/}
            {/*            /!*      style={{justifyContent: "space-evenly"}}>*!/*/}
            {/*            /!*</Grid>*!/*/}
            {/*        </Grid>*/}

            {/*        /!*<Grid item xs={9}>*!/*/}
            {/*        /!*    <Typography variant="body1" style={{width: '17em', margin: '0 auto'}}>*!/*/}
            {/*        /!*        Price variance cost reflects potential savings across the portfolio*!/*/}
            {/*        /!*        to be explored and ultimately realized.*!/*/}
            {/*        /!*    </Typography>*!/*/}
            {/*        /!*</Grid>*!/*/}

            {/*    </Grid>*/}
            {/*    /!*<Grid item xs={2}/>*!/*/}
            {/*</Grid>*/}
        </div>

        {/*<Grid container justifyContent="space-evenly" className="page-item">*/}
        {/*    <Grid item xs={12}>*/}
        {/*        <p>clickedSupplier2 = {JSON.stringify(selectedCategoryL1)}</p>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}

        <div className="page-item">
            {/*<Grid container justifyContent="flex-end">*/}
            {/*    <Grid item xs={2} style={{textAlign: 'center', marginBottom: theme.spacing(2)}}>*/}
            {/*        <Button*/}
            {/*            style={{width: '13em'}}*/}
            {/*            variant="outlined"*/}
            {/*            color="primary"*/}
            {/*            // onClick={() => startKOI2Download()}*/}
            {/*            disabled={downloading}>*/}
            {/*            {downloading ? 'Downloading...' : 'Download all'}*/}
            {/*        </Button>*/}
            {/*    </Grid>*/}
            {/*    /!*<Grid item xs={2}/>*!/*/}
            {/*</Grid>*/}
            <Grid container justifyContent="space-evenly">
                {/*<Grid item xs={2}>*/}
                {/*{selectedSupplier === undefined*/}
                {/*    ? <></>*/}
                {/*    : <Typography variant="subtitle1">*/}
                {/*        Transactions related to supplier {selectedSupplier.name}*/}
                {/*    </Typography>}*/}
                {/*</Grid>*/}
                <Grid item xs={12} style={{minHeight: '10em'}}>
                    {pvvTableError &&
                        <Alert severity="error">{pvvTableError}</Alert>
                    }
                    <DataTable
                        className="parts-table x-design"
                        noContainer
                        tableColumns={tableColumns}
                        data={pvvTable}
                        emptyCaption="Loading"
                        pageSize={50}
                        pageSizeOptions={[
                            50,
                            100,
                            200,
                            // {label: 'Opportunity', value: 23},
                            // {label: 'All', value: -1}
                        ]}
                    />
                </Grid>
                {/*<Grid item xs={2}/>*/}
            </Grid>
        </div>
    </KoiFacade>
};
