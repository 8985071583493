import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {MithraTablePaginationActions} from "../../../components/table-pagination/MithraTablePaginationActions";
import {CurrencyAbbreviationContext} from "../../../components/currency-component/CurrencyAbbreviationContext";
import {SynergyCommonSupplierReviewMainRow} from "./SynergyCommonSupplierReviewMainRow";
import {SYN_COMMON_SUPPLIER_COLUMNS} from "./columns";
import {getAbbreviation} from "../../supplier-normalization/pages/SupplierNormalization.classes";
import {MithraTableHeadColumns} from "../../../components/table/MithraTableHeadColumns";

export const SynergyCommonSupplierReviewMainTable: React.FC = observer(() => {
    const {synergyStore} = useStores()
    const isLoading = synergyStore.commonSuppliersDelegate.review.isLoadingRows;
    const dataPages = synergyStore.commonSuppliersDelegate.review._commonSupplierGroupRequest;
    // const dataPages = undefined;
    const data = synergyStore.commonSuppliersDelegate.review._commonSupplierGroupRequest.data;
    const abbreviation = getAbbreviation(data);
    const tableClass = 'mithra-table x-design synergy-common-supplier-table' + (isLoading ? ' loading' : '');
    return <TableContainer className={tableClass}>
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={SYN_COMMON_SUPPLIER_COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody>
                {data ? <>
                        {data.map(row => <React.Fragment key={row.id}>
                            <CurrencyAbbreviationContext.Provider value={abbreviation}>
                                <SynergyCommonSupplierReviewMainRow row={row}/>
                            </CurrencyAbbreviationContext.Provider>
                        </React.Fragment>)}
                        {data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={SYN_COMMON_SUPPLIER_COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        //
                        rowsPerPageOptions={[]}
                        colSpan={SYN_COMMON_SUPPLIER_COLUMNS.length}
                        count={dataPages.count}
                        rowsPerPage={dataPages.pageSize}
                        page={dataPages.page - 1}
                        // SelectProps={{
                        //     inputProps: {'aria-label': 'rows per page'},
                        //     native: true,
                        // }}
                        // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                        onPageChange={(e, page) => dataPages.changePage(page + 1)}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
