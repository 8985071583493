import './TaxonomySuggestor.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {
    CollapsibleSuggestionIndentTree
} from "../../components/visualization/collapsible-suggestion-indent-tree/CollapsibleSuggestionIndentTree";
import {ApiUpdateSuggestionTreeResponse} from "../../services/ApiHelpers";
import {m_taxonomy} from "../../services/classes/TaxonomyClasses";
import {Button, ButtonGroup, Grid} from "@mui/material";
import {ChevronLeft, SaveAsOutlined} from "@mui/icons-material";
import {generatePath, useHistory} from "react-router";
import {routes} from "../../routing/routes";
import {
    ExtendedTree,
    Options,
    SuggestionTreeDataType
} from "../../components/visualization/collapsible-suggestion-indent-tree/TaxonomySuggestionTreeBuilder";
import {TaxonomySuggestionSelection} from "./TaxonomySuggestionSelection";
import {TaxonomySuggestionTable} from "./TaxonomySuggestionTable";


export const TaxonomySuggestorPage: React.FC = observer(() => {
    const {taxonomySuggestorStore, bagStore} = useStores();
    const [filter, setFilter] = React.useState<any | undefined>(0);

    // const routeMatch = useRouteMatch<JobRouteMatch>();
    const bagId = bagStore.bagId;
    const history = useHistory();


    const hiddenRoot = true;
    const hiddenUncat = false;
    const options: Options = {
        height: 5000,
        width: 1500,
        margin: {
            top: 20,
            right: 20,
            bottom: 20,
            left: 50,
        },
        leftToRight: true,
        columns: taxonomySuggestorStore.tableColumns,

        onDataChanged: (d: ApiUpdateSuggestionTreeResponse<m_taxonomy.Data>) => {
            taxonomySuggestorStore.putTaxonomySuggestion(d)
        },
        overrideOnClickSelect: (d: ExtendedTree | undefined) => {

            //Convert d from ExtendedTree to SuggestionTreeStoreType<m_taxonomy.Data>
            // function convertNode(d: ExtendedTree | undefined): SuggestionTreeStoreType<m_taxonomy.Data> | undefined {
            //     const node: SuggestionTreeStoreType<m_taxonomy.Data> = {
            //         label: d.data.label || '',
            //         children: (d.children || []).map(c => convertNode(c)),
            //         values: d.data.values,
            //         review: {
            //             apiData: {
            //                 deleted: d.data.reviewStatus?.deleted || false,
            //                 added: d.data.reviewStatus?.added || false,
            //                 renamed: d.data.reviewStatus?.renamed || false,
            //                 accepted: d.data.reviewStatus?.accepted || false,
            //                 rejected: d.data.reviewStatus?.rejected || false,
            //                 oldLabel: d.data.reviewStatus?.oldLabel || '',
            //                 newLabel: d.data.reviewStatus?.newLabel || '',
            //             },
            //             stateData: {
            //                 hasChangesInChildren: d.data.reviewStatus?.hasChangesInChildren || false
            //             },
            //         },
            //     };
            //
            //     return node;
            // }
            //
            // const node = convertNode(d);

            // console.log('Selected node store: ', taxonomySuggestorStore.selectedNode)
            // console.log('Selected node click: ', taxonomySuggestorStore.selectedNode)

            // if (taxonomySuggestorStore.selectedPreviousNode(node)) {
            //     taxonomySuggestorStore.setSelectedNode(undefined)
            //     console.log('Selected node unset in store...')
            // } else {
            //     taxonomySuggestorStore.setSelectedNode(node)
            //     console.log('Selected node set in store...')
            // }

            taxonomySuggestorStore.setSelectedNodeLabel(d?.data.label || undefined)
            taxonomySuggestorStore.setSelectedNodeValues(d?.data.values || undefined)

        }
    };

    return <>
        {/*<style>{'svg {border: solid 1px;}'}</style>*/}
    {/*<h3 className="m0">TaxonomySuggestionTreePage.tsx</h3>*/}
    {/*<div style={{ width: '1000px', height: '1000px', margin: '20px', display: 'inline-block' }}>*/}
        <div className='taxonomy-suggestor-page'>
            <div className='taxonomy-suggestor-toolbar'>
                <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<ChevronLeft/>}
                    onClick={() => {
                        history.push(generatePath(routes.job_taxonomy_builder, {id: bagId, taxonomyId: 1}))
                    }}>
                    Back
                </Button>

                <ButtonGroup>
                    <Button
                        color="error"
                        variant="outlined"
                        onClick={() => {
                            console.log('Updating values...')
                            taxonomySuggestorStore.updateTaxonomySuggestionValues()
                        }}
                    >
                        Update values
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<SaveAsOutlined/>}
                        onClick={() => {
                            taxonomySuggestorStore.saveTaxonomySuggestion()
                            history.push(generatePath(routes.job_taxonomy_builder, {id: bagId, taxonomyId: 1}))
                        }}>
                        Save
                    </Button>
                </ButtonGroup>
            </div>

            <div>
                <ButtonGroup className="ml-4">
                    <Button variant={filter === 0 ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => {
                                setFilter(0)
                            }
                            }>
                        No filter
                    </Button>
                    <Button variant={filter === 1 ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => {
                                setFilter(1)
                            }
                            }>
                        {taxonomySuggestorStore.numberOfAdditions} Added
                    </Button>
                    <Button variant={filter === 2 ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => {
                                setFilter(2)
                            }
                            }>
                        {taxonomySuggestorStore.numberOfDeletions} Deleted
                    </Button>
                    <Button variant={filter === 3 ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => {
                                setFilter(3)
                            }
                            }>
                        {taxonomySuggestorStore.numberOfRenames} Renamed
                    </Button>
                </ButtonGroup>
            </div>

            <Grid container>
                <Grid item
                      xs={(taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData) ? 5 : 12}>
                    {/*<Grid item xs={6}>*/}
                    <div className='taxonomy-container'>
                        {taxonomySuggestorStore.taxonomySuggestionState &&
                            <CollapsibleSuggestionIndentTree
                                data={taxonomySuggestorStore.taxonomySuggestionState}
                                options={options}
                                hiddenRoot={hiddenRoot}
                                hiddenUncat={hiddenUncat}
                                filter={filter}
                                hiddenColumns={(taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData) ? true : undefined}
                            />}
                    </div>
                </Grid>
                <Grid container
                      item
                      xs={(taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData) ? 7 : 12}>
                    <Grid item xs={12} className='selection'>
                        {taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData &&
                            <TaxonomySuggestionSelection selectedNodeLabel={taxonomySuggestorStore.selectedNodeLabel}
                                                         selectedNodeData={taxonomySuggestorStore.selectedNodeData}/>}
                    </Grid>
                    <Grid item xs={12} className='selection-table'>
                        {taxonomySuggestorStore.selectedNodeLabel && taxonomySuggestorStore.selectedNodeData &&
                            <TaxonomySuggestionTable/>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </>
});

type Filter = undefined | ((d: d3.HierarchyNode<SuggestionTreeDataType>) => boolean);
export const TSTB_FILTERS: Filter[] = [
    undefined,
    d => !d?.data?.reviewStatus || d.data.reviewStatus.added,
    d => !d?.data?.reviewStatus || d.data.reviewStatus.deleted,
    d => !d?.data?.reviewStatus || d.data.reviewStatus.renamed,
]