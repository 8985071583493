import Toolbar from "@mui/material/Toolbar";
import {AppBar} from "@mui/material";
import React from "react";

export const SmallHeader: React.FC = () => {
    return <AppBar id="main-header" className="small" position="relative">
        <Toolbar>
            <img src="/logo_135x30/Mithra_logo.png" alt="Mithra" className="mithra-logo"/>
        </Toolbar>
    </AppBar>;
}
