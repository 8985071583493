import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Grid} from "@mui/material";
import {DashboardRow} from "../../../../components/dashboard/DashboardRow";
import {DASHBOARD_CONSTANTS, drawOpportunityValue} from "../../../../components/dashboard/Dashboard.utils";
import {DashBoardCard} from "../../../dashboard/DashBoardCard";
import {generatePath} from "react-router";
import {routes} from "../../../../routing/routes";
import {ClassificationIcon} from "../../../../components/icons/ClassificationIcon";
import {TaxonomyMapperIcon} from "../../../../components/icons/TaxonomyMapperIcon";
import {environment} from "../../../../env";
import {SynergySuppliersIcon} from "../../../../components/icons/SynergySuppliersIcon";

/**
 * Dashboard that uses only merge-1 links, without any databags in the url
 * The Combined version using in the Simple dashboard variant
 * This contains more cards in the same dashboard
 */
export const MergeXMergeCombinedDashboard: React.FC = observer(() => {
    const {
        bagStore,
        categorizationStore,
        masterSupplierNormalizationStore,
        taxonomyMapperStore,
    } = useStores();
    const bagId = bagStore.bagId;
    const unclassifiedEgoSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    const unmatchedCategories = taxonomyMapperStore.mapper.unMatchedCategories?.length;
    const supplierNormalizationKpi = masterSupplierNormalizationStore.postKeyValues?.parent_suppliers_count;

    return <>
        {environment.isTest && <h1>MergeXMergeCombinedDashboard</h1>}
        <Grid container
              className="dashboard merge-x-dashboard merge-dashboard merge-combined-dashboard"
              justifyContent="space-evenly"
              spacing={0}>
            <DashboardRow
                // title="Suppliers"
                className="suppliers-row"
                // subtitle={bagStore.kpi.common
                //     ? String(bagStore.kpi.common.n_suppliers)
                //     : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}
            >

                <DashBoardCard
                    cardKey="synergy-suppliers"
                    avatar={<SynergySuppliersIcon/>}
                    title="Common Suppliers"
                    // value={supplierNormalizationKpi !== undefined ? supplierNormalizationKpi : DASHBOARD_CONSTANTS.VALUE_LOADING}
                    subtitle="suppliers"
                    link={generatePath(routes.merge_x_merge_common_suppliers, {id: bagId})}
                    badge="Ai"
                />
                {/*TODO: CAT-877 CAT-873 CAT-880: LG and SHV product offerings are entangled also on dashboard flow level*/}
                {/* As of 230611 Both SHV and LG are not using this page*/}
                <DashBoardCard
                    cardKey="synergy-overview"
                    avatar={<ClassificationIcon/>}
                    title="Synergy Overview"
                    link={generatePath(routes.synergy_overview, {id: bagId})}
                    badge="Ai"
                />
                {/*<DashBoardCard*/}
                {/*    cardKey="synergy-spend"*/}
                {/*    avatar={<SynergySpendIcon/>}*/}
                {/*    title="Common Spend"*/}
                {/*    link={generatePath(routes.merge_x_merge_spend, {id: bagId})}*/}
                {/*/>*/}

            </DashboardRow>
            <DashboardRow
                title="Synergy"
                className="categories-row"
                // subtitle={bagStore.kpi.common
                //     ? String(bagStore.kpi.common.n_categories)
                //     : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}
            >

                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper"
                    // value={unmatchedCategories === undefined ? DASHBOARD_CONSTANTS.SUBTITLE_LOADING : unmatchedCategories}
                    subtitle="unmapped categories"
                    link={generatePath(routes.merge_x_merge_taxonomy_mapper_target, {id: bagId})}
                    badge="Ai"
                />

            </DashboardRow>
            <DashboardRow
                // title="Spend"
                className="spend-row"
                // subtitle={bagStore.kpi.common
                //     ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                //     : DASHBOARD_CONSTANTS.SUBTITLE_LOADING}
            >

                <DashBoardCard
                    cardKey="merge-improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedEgoSpend)}
                    valueColor={unclassifiedEgoSpend
                        ? (unclassifiedEgoSpend >= DASHBOARD_CONSTANTS.OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.merge_x_merge_categorization, {id: bagId})}
                    badge="Ai"
                />
            </DashboardRow>
        </Grid>
    </>
});
