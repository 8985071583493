import React from "react";
import './IconStyles.scss';

// Mithra-AI Solutions\CAT-Product - Documents\03-UI-UX Design\Resources\FE Icons\noun-classification-hierarchy-4577689-193150.svg
export const ClassificationHierarchyIcon: React.FC = () => <svg width={50} height={50} viewBox="0 0 1200 1200"
                                                                className="ClassificationHierarchyIcon icon">
    <g fill="#19304f">
        <path
            d="m770.11 393.52h-338.74c-9.0859 0-16.457-7.3711-16.457-16.457v-183.29c0-9.0859 7.3711-16.457 16.457-16.457h338.74c9.0859 0 16.457 7.3711 16.457 16.457v183.26c0 9.1172-7.3711 16.488-16.457 16.488zm-322.29-32.914h305.83v-150.37h-305.83z"/>
        <path
            d="m410.03 669.03h-169.4c-9.0859 0-16.457-7.3711-16.457-16.457v-169.37c0-9.0859 7.3711-16.457 16.457-16.457h169.37c9.0859 0 16.457 7.3711 16.457 16.457v169.37c0.027344 9.0859-7.3438 16.457-16.43 16.457zm-152.91-32.914h136.46v-136.46h-136.46z"/>
        <path
            d="m960.86 669.03h-169.37c-9.0859 0-16.457-7.3711-16.457-16.457v-169.37c0-9.0859 7.3711-16.457 16.457-16.457h169.37c9.0859 0 16.457 7.3711 16.457 16.457v169.37c-0.027344 9.0859-7.3711 16.457-16.457 16.457zm-152.94-32.914h136.46v-136.46h-136.46z"/>
        <path
            d="m175.26 1026.9c-55.77 0-101.14-45.371-101.14-101.14 0-55.77 45.371-101.14 101.14-101.14 55.77 0 101.14 45.371 101.14 101.14-0.003906 55.773-45.375 101.14-101.14 101.14zm0-169.4c-37.629 0-68.23 30.602-68.23 68.23s30.602 68.23 68.23 68.23c37.629 0 68.23-30.602 68.23-68.23-0.003906-37.602-30.602-68.23-68.23-68.23z"/>
        <path
            d="m742.57 1026.9c-55.77 0-101.14-45.371-101.14-101.14 0-55.77 45.371-101.14 101.14-101.14 55.77 0 101.14 45.371 101.14 101.14 0 55.773-45.371 101.14-101.14 101.14zm0-169.4c-37.629 0-68.23 30.602-68.23 68.23s30.602 68.23 68.23 68.23c37.629 0 68.23-30.602 68.23-68.23 0-37.602-30.602-68.23-68.23-68.23z"/>
        <path
            d="m458.91 1026.9c-55.77 0-101.14-45.371-101.14-101.14 0-55.77 45.371-101.14 101.14-101.14 55.77 0 101.14 45.371 101.14 101.14 0 55.773-45.371 101.14-101.14 101.14zm0-169.4c-37.629 0-68.23 30.602-68.23 68.23s30.602 68.23 68.23 68.23c37.629 0 68.23-30.602 68.23-68.23 0-37.602-30.602-68.23-68.23-68.23z"/>
        <path
            d="m1026.2 1026.9c-55.77 0-101.14-45.371-101.14-101.14 0-55.77 45.371-101.14 101.14-101.14 55.77 0 101.14 45.371 101.14 101.14-0.003906 55.773-45.375 101.14-101.14 101.14zm0-169.4c-37.629 0-68.23 30.602-68.23 68.23s30.602 68.23 68.23 68.23c37.629 0 68.23-30.602 68.23-68.23-0.003907-37.602-30.605-68.23-68.23-68.23z"/>
        <path
            d="m788.97 584.17h-376.26c-9.0859 0-16.457-7.3711-16.457-16.457 0-9.0859 7.3711-16.457 16.457-16.457h376.26c9.0859 0 16.457 7.3711 16.457 16.457 0 9.0859-7.3711 16.457-16.457 16.457z"/>
        <path
            d="m600.74 583.11c-9.0859 0-16.457-7.2852-16.457-16.312v-188.86c0-9 7.3711-16.312 16.457-16.312 9.0859 0 16.457 7.2852 16.457 16.312v188.86c0 9-7.3711 16.312-16.457 16.312z"/>
        <path
            d="m459.14 856.29c-9.0859 0-16.457-7.3711-16.457-16.457v-68.684h-117.69c-9.0859 0-16.457-7.3711-16.457-16.457v-100.17c0-9.0859 7.3711-16.457 16.457-16.457s16.457 7.3711 16.457 16.457v83.715h117.69c9.0859 0 16.457 7.3711 16.457 16.457v85.113c0 9.1133-7.3711 16.484-16.457 16.484z"/>
        <path
            d="m175.26 855.74c-9.0859 0-16.457-7.3711-16.457-16.457v-84.602c0-9.0859 7.3711-16.457 16.457-16.457h149.74c9.0859 0 16.457 7.3711 16.457 16.457 0 9.0859-7.3711 16.457-16.457 16.457l-133.29 0.003906v68.145c0 9.082-7.3711 16.453-16.457 16.453z"/>
        <path
            d="m742.34 855.57c-9.0859 0-16.457-7.3711-16.457-16.457v-87.113c0-9.0859 7.3711-16.457 16.457-16.457h117.03v-82c0-9.0859 7.3711-16.457 16.457-16.457s16.457 7.3711 16.457 16.457v98.457c0 9.0859-7.3711 16.457-16.457 16.457h-117.03v70.656c0 9.0859-7.3711 16.457-16.457 16.457z"/>
        <path
            d="m1026.2 856.2c-9.0859 0-16.457-7.3711-16.457-16.457v-71.285h-133.94c-9.0859 0-16.457-7.3711-16.457-16.457s7.3711-16.457 16.457-16.457h150.4c9.0859 0 16.457 7.3711 16.457 16.457v87.742c0 9.0859-7.375 16.457-16.457 16.457z"/>
    </g>
</svg>
