import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../stores";
import {Button, Checkbox, Collapse, IconButton, TableCell, TableRow} from "@mui/material";
import {ExpandMore, KeyboardArrowDown, KeyboardArrowRight} from "@mui/icons-material";
import {CurrencyComponent} from "../../../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../../../components/currency-component/CurrencyAbbreviationContext";
import {
    PartReCategorizationControllerStore
} from "../../../../../components/categorization/PartReCategorizationControllerStore";
import {ReviewChoice} from "../../../../../services/classes/MaterializedClasses";
import {PartReCategorizationComponent} from "../../../../../components/categorization/PartReCategorizationComponent";
import {CategorizationReviewStore} from "../../../../../stores/CategorizationReviewStore";
import {GroupedCategorizationReviewSubTable} from "./GroupedCategorizationReviewSubTable";
import {fromArrayCategories} from "../../../../../services/ApiHelpers";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../../constants";
import {
    getSpend,
    MatPartReviewRowState,
    SomeMatSupplierReviewRowState
} from "../../../../../services/classes/MatReviewClasses";
import {getCatReviewColumns} from "../columns";
import {MithraTableCell} from "../../../../../components/table/MithraTableCell";
import {getCollapseAnimationTiming} from "../../../../../components/table/utils";
import {CombinedReviewButtons} from "../../../../../components/approval-acc-rej/CombinedReviewButtons";
import {GoogleLink} from "../../../../../components/_tiny/GoogleLink";

type Props = {
    row: SomeMatSupplierReviewRowState
    controller: PartReCategorizationControllerStore // TODO: Move to context
};

export const GroupedCategorizationReviewMainRow: React.FC<Props> = observer(({row, controller}) => {
    const {p, authStore, bagStore, categorizationReviewStore} = useStores();
    const [open, setOpen] = React.useState(false);
    const COLUMNS = getCatReviewColumns(p);

    let combinedAccRejState = CategorizationReviewStore.calcCombinedReviewState(row.parts);
    let onAccept: undefined | (() => void) = undefined;

    const hasParts = row.parts ? row.parts.length > 0 : false;
    const nParts = row.parts?.length;
    const firstPart: MatPartReviewRowState | undefined = row.parts && row.parts.length > 0 ? row.parts[0] : undefined;

    let recatControlsComponent;
    if (row.parts) {
        const parts = row.parts;
        if (!authStore.viewOnly && combinedAccRejState.editAllowed) {
            onAccept = () => categorizationReviewStore.clickAcceptRejectParts(parts, ReviewChoice.ACCEPT);
        }

        if (row.combined_state) {
            // The combined state is calculated
            if (row.combined_state.allSameCategory === false) {
                // It's inconclusive
                recatControlsComponent = <Button
                    endIcon={<KeyboardArrowDown/>}
                    onClick={event => {
                        const anchor = event.currentTarget;
                        controller.setOnSelect((newCats) => categorizationReviewStore.clickReCatParts(parts, newCats))
                        controller.open(anchor)
                    }}
                >
                    Mixed categories
                </Button>
            } else if (combinedAccRejState.editAllowed
                && categorizationReviewStore.categories.taxonomy_categories
                && row.parts.length >= 1) {
                const effectiveLabels = fromArrayCategories(row.combined_state.allSameCategory, bagStore.taxonomy_size);
                recatControlsComponent = <PartReCategorizationComponent
                    cats={effectiveLabels}
                    handleOpenSearch={event => {
                        const anchor = event.currentTarget;
                        controller.setOnSelect((newCats) => categorizationReviewStore.clickReCatParts(parts, newCats))
                        controller.open(anchor)
                    }}
                />
            }
        }
    }
    let i = 0;
    return <>
        <TableRow className="supplier-row">
            <MithraTableCell c={COLUMNS[i++]}>
                <Checkbox checked={row.selected} onClick={() => categorizationReviewStore.toggleSelectionRow(row)}/>
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]} onClick={() => setOpen(!open)}>
                {hasParts &&
                    <IconButton aria-label="expand row" size="small">
                        {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                    </IconButton>
                }
                {nParts}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                <GoogleLink type="supplier" objs={[row, firstPart]}>
                    {row.s_name}
                </GoogleLink>
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                <CurrencyAbbreviationContext.Consumer>{abbreviation => {
                    return <CurrencyComponent v={getSpend(row)} abbreviation={abbreviation}/>;
                }}</CurrencyAbbreviationContext.Consumer>
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                {row.s_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
            </MithraTableCell>
            {!p.p.hideBuInCategorizationTable &&
                <MithraTableCell c={COLUMNS[i++]}>
                    {row.s_total_bus.map(l => l === null ? 'None' : l).join(', ')}
                </MithraTableCell>
            }
            <MithraTableCell c={COLUMNS[i++]}>
                {recatControlsComponent}
            </MithraTableCell>
            <MithraTableCell c={COLUMNS[i++]}>
                <CombinedReviewButtons onAccept={onAccept} state={combinedAccRejState}/>
            </MithraTableCell>
        </TableRow>
        <TableRow>
            <TableCell
                className="cell-sub-table"
                colSpan={COLUMNS.length}
                style={{paddingLeft: COLUMNS[0].width}}>
                {nParts !== undefined && nParts > 0 &&
                    <Collapse in={open} timeout={getCollapseAnimationTiming(row.parts)} unmountOnExit>
                        <GroupedCategorizationReviewSubTable data={row} controller={controller}/>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})
