import React from "react";
import {observer} from "mobx-react-lite";
import {Table, TableBody} from "@mui/material";
import {
    PartReCategorizationControllerStore
} from "../../../../../components/categorization/PartReCategorizationControllerStore";
import {GroupedCategorizationReviewSubRow} from "./GroupedCategorizationReviewSubRow";
import {useStores} from "../../../../../stores";
import {SomeMatSupplierReviewRowState} from "../../../../../services/classes/MatReviewClasses";
import {MithraTableHeadColumns} from "../../../../../components/table/MithraTableHeadColumns";
import {getCatReviewSubColumns} from "../columns";

type Props = {
    data: SomeMatSupplierReviewRowState
    controller: PartReCategorizationControllerStore // TODO: Move to context
};
export const GroupedCategorizationReviewSubTable: React.FC<Props> = observer(({data, controller}) => {
    const {p, categorizationStore} = useStores();

    // let abbreviation: CurrencyAbbreviation = NO_ABBREVIATION;
    // if (data.parts) {
    //     const spends = data.parts.map(d => d.p_spend)
    //     if (spends.length > 0)
    //         abbreviation = findAbbreviationOfGroup(spends)
    // }

    return <Table
        className="sub-table"
        size="small">
        <MithraTableHeadColumns columns={getCatReviewSubColumns(p, categorizationStore.desiredBagId, false)}/>
        <TableBody>
            {data.parts?.map(part =>
                <React.Fragment key={part.id}>
                    {/*<CurrencyAbbreviationContext.Provider value={abbreviation}>*/}
                        <GroupedCategorizationReviewSubRow part={part} controller={controller}/>
                    {/*</CurrencyAbbreviationContext.Provider>*/}
                </React.Fragment>
            )}
        </TableBody>
    </Table>
})
