import React from "react";
import {observer} from "mobx-react-lite";
import {Stack, TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {getSpend, ParentSupplierMatch, SupplierReviewRowState} from "../SupplierNormalization.classes";
import {SUPPLIER_COLUMNS} from "./columns";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../constants";
import {ParentSupplierChooser} from "../components/ParentSupplierChooser";
import {useStores} from "../../../../stores";
import {MithraTableCell} from "../../../../components/table/MithraTableCell";

type Props = {
    subRow: SupplierReviewRowState
};
export const ParentSupplierReviewSubRow: React.FC<Props> = observer(({subRow}) => {
    const {p, supplierNormalizationStore} = useStores()
    const [value, setValue] = React.useState<ParentSupplierMatch | null>(subRow.parent_match);
    const changeValue = (newValue: ParentSupplierMatch | null) => {
        supplierNormalizationStore.review.sendSubRowUpdate(subRow, newValue);
        setValue(newValue);
    }
    const isBase = subRow.s_context_3 === 'base';
    const isMaster = subRow.s_context_3 === 'master';
    return <TableRow className={'sub-row' + (isBase ? ' base' : '') + (isMaster ? ' master' : '')}>
        <MithraTableCell c={SUPPLIER_COLUMNS[0]}>
            <Stack direction="row" className="supplier-parent-chooser-wrapper">
                <ParentSupplierChooser value={value} setValue={changeValue} label="Matched on"/>
            </Stack>
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[1]}>
            {subRow.s_name}
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[2]}>
            {/*{environment.isTest && <>p_id={part.p_id}<br/></>}*/}
            <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                <CurrencyComponent v={getSpend(subRow)} abbreviation={abbreviation}/>
            }</CurrencyAbbreviationContext.Consumer>
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[3]}>
            {isMaster ? subRow.s_country : subRow.s_city}
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[4]}>
            {subRow.s_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[5]}>
            {isBase
                ? p.p.compareCompanyName : isMaster
                    ? p.p.masterCompanyName
                    : subRow.s_context_3}
        </MithraTableCell>
        {/*<MithraTableCell c={SUPPLIER_COLUMNS[4]}>*/}
        {/*    {Math.round(subRow.s_suggestion_score)}*/}
        {/*</MithraTableCell>*/}
    </TableRow>
})
