import {SearchManager} from "../../../stores/managers/SearchManager";
import {ParentSupplierQueryFilter, ParentSupplierReviewDelegate} from "./ParentSupplierReviewDelegate";
import {makeAutoObservable} from "mobx";
import {SupplierNormalizationStore} from "../SupplierNormalizationStore";

const SHOW_STANDALONE_SUPPLIERS_DEFAULT = false;

export class ParentSupplierFilterDelegate {
    readonly parentSupplierSearch = new SearchManager(() => this.startRowsRequest());

    filter: Pick<ParentSupplierQueryFilter, 'showStandaloneSuppliers'> = {
        showStandaloneSuppliers: SHOW_STANDALONE_SUPPLIERS_DEFAULT
    }

    constructor(
        private supplierNormalizationStore: SupplierNormalizationStore,
        private parentSupplierReviewDelegate: ParentSupplierReviewDelegate,
    ) {
        makeAutoObservable(this)
    }

    startRowsRequest() {
        if (!this.supplierNormalizationStore.ps._parentSupplierManager.result) {
            console.warn('ParentSupplierReviewDelegate startRowsRequest() should not start the request, ' +
                'as there are not parent suppliers to match the rows against...')
            return;
        }
        const databag = this.supplierNormalizationStore.databagId;
        if (!databag) {
            console.error('ParentSupplierReviewDelegate startRowsRequest() no databag')
            this.parentSupplierReviewDelegate._parentSupplierRowsRequest.reset();
            this.parentSupplierReviewDelegate.isLoadingSubRows = false;
            return;
        }
        this.parentSupplierReviewDelegate.isLoadingSubRows = true;
        const filter = {
            parent_search: this.parentSupplierSearch.activeSearchString,
            showStandaloneSuppliers: this.filter.showStandaloneSuppliers,
        };
        this.parentSupplierReviewDelegate._parentSupplierRowsRequest._request({databag, filter})
    }

    get hits() {
        return this.parentSupplierReviewDelegate._parentSupplierRowsRequest.count;
    }

    setShowStandAloneSuppliers(showStandAloneSuppliers: boolean) {
        this.filter.showStandaloneSuppliers = showStandAloneSuppliers;
        this.startRowsRequest();
    }

    toggleShowStandAloneSuppliers() {
        this.setShowStandAloneSuppliers(!this.filter.showStandaloneSuppliers);
    }

    reset() {
        this.parentSupplierSearch.resetSearch();
        this.filter.showStandaloneSuppliers = SHOW_STANDALONE_SUPPLIERS_DEFAULT;
    }
}
