import React from "react";
import {EditorModalComponent} from "../EditorModalComponent";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Alert, Grid, TextField, Typography} from "@mui/material";
import {CategoryChip} from "../../../components/category-chip/CategoryChip";

export const ConfirmSendForApproval: React.FC = observer(() => {
    const {taxonomyManagerStore, taxonomyEditorStore} = useStores();

    const incompleteCategoriesError: string = taxonomyManagerStore.taxonomy && taxonomyManagerStore.incompleteCategories.length > 0
        ? `Please make sure the following categories have categories until L${taxonomyManagerStore.taxonomy.size} to complete the taxonomy:`
        : '';

    const stateInSync = taxonomyManagerStore.stateInSync;

    return <EditorModalComponent
        titleId={"taxonomy-editor-modal-title"}
        className="taxonomy-model-component confirm-send-for-approval-component"
        title="Should the taxonomy be submitted for approval?"
        canSave={!taxonomyManagerStore.isBusySendForApproval && stateInSync && !incompleteCategoriesError}
        onSave={() => {
            taxonomyManagerStore.sendForApproval();

            // TODO: Move this to a better spot, onClick should only call 1 action
            taxonomyEditorStore.setEditMode(false);
        }}
        onClose={() => taxonomyManagerStore.setSendForApprovalDialog(false)}
    >
        {stateInSync && incompleteCategoriesError ?
            <>
                <Typography className="mt-3 mb-2">{incompleteCategoriesError}</Typography>
                {taxonomyManagerStore.incompleteCategories.map(cats =>
                    <Grid key={cats.join('|')} item className="mt-1">
                        <Alert severity="error"><CategoryChip labels={cats}/> is missing a level</Alert>
                    </Grid>
                )}
            </>
            : <>
                <Typography variant="body1">No more changes can be applied until the review is completed</Typography>

                <TextField
                    multiline
                    fullWidth
                    id="taxonomy-editor-modal-approval-note-field"
                    label="Notes for the reviewer (optional)"
                    // error={false}
                    // helperText={}
                    variant="outlined"
                    value={taxonomyManagerStore.approvalNotes}
                    onChange={e => taxonomyManagerStore.setApprovalNotes(e.target.value)}
                    disabled={taxonomyManagerStore.isBusySendForApproval}
                />
            </>}
    </EditorModalComponent>
})
