import ProfileStore from "../ProfileStore";
import {makeAutoObservable, reaction} from "mobx";
import {CategorizationReviewDataDelegate} from "./CategorizationReviewDataDelegate";
import {SearchManager} from "../managers/SearchManager";
import {matGetFilterType, MatSearch, MatSupplierFilter} from "../../services/classes/MatReviewClasses";
import {catsToDict, setLeveLFilterUrlParamsFromArray, setUrlParamOrNull} from "../../services/ApiHelpers";
import {SearchType, SearchTypeId, TypedSearchManager} from "../managers/TypedSearchManager";

export const AI_CATEGORIZATION_SCORE_FACTOR = 100;
export const AI_CATEGORIZATION_SCORE_DIGITS = 0;
const AI_CATEGORIZATION_ROUNDING_ERROR_CORRECTION = .5 / AI_CATEGORIZATION_SCORE_FACTOR;
const AI_CATEGORIZATION_SCORE_INITIAL_MIN = 0.5;
const AI_CATEGORIZATION_SCORE_INITIAL_MAX = 1.0;

function getBeFields(t: SearchTypeId): string[] {
    switch (t) {
        case 'supplier':
            return ['mat_supplier_review_row__s_name']
        case 'p_name':
            return ['p_name']
        case 'p_description':
            return ['p_description']
        case 'p_context_1':
            return ['p_context_1']
        case 'p_context_2':
            return ['p_context_2']
        case 'p_name_description':
            return ['p_name', 'p_description']
        case 'p_description_context_1':
            return ['p_description', 'p_context_1']
        case 'p_description_context_2':
            return ['p_description', 'p_context_2']
        case 'p_name_description_context_1':
            return ['p_name', 'p_description', 'p_context_1']
        case 'p_name_description_context_2':
            return ['p_name', 'p_description', 'p_context_2']
        case 'all_context_1':
            return ['mat_supplier_review_row__s_name', 'p_name', 'p_description', 'p_context_1']
        case 'all_context_2':
            return ['mat_supplier_review_row__s_name', 'p_name', 'p_description', 'p_context_2']
    }
    console.error('getBeField: Unsupported search type', t)
}

type AdvancedFilterTypes = {
    scoreLow: number | null
    scoreHigh: number | null
}

export function isAllowedInGroupedMode(t: SearchTypeId | undefined): boolean {
    // WARNING: Only use for supplier atm
    if (t) switch (t) {
        case 'supplier':
            return true
    }
    return false
}

const DEFAULT_SINGLE_MODE = true;

export type Ordering = {
    orderBy: string
    direction: 'asc' | 'desc'
}

/**
 * All method related to navigating the categorization review
 */
export class CategorizationReviewFilterManagerDelegate {
    readonly search1 = new TypedSearchManager(this.profile.searchOptions1.options, (search, type) => {
        this.onSearchChange(1, search, type)
    }, this.profile.searchOptions1.defaultIndex);
    readonly search2 = new TypedSearchManager(this.profile.searchOptions2.options, (search, type) => {
        this.onSearchChange(2, search, type)
    }, this.profile.searchOptions2.defaultIndex);
    readonly search3 = new TypedSearchManager(this.profile.searchOptions3.options, (search, type) => {
        this.onSearchChange(3, search, type)
    }, this.profile.searchOptions3.defaultIndex);

    readonly supplierSearch = new SearchManager(() => {
        this.data.requestSupplierPages()
    })

    advFilter: AdvancedFilterTypes = {
        scoreLow: null,
        scoreHigh: null,
    }

    scoreLowFilterField = String(AI_CATEGORIZATION_SCORE_INITIAL_MIN * AI_CATEGORIZATION_SCORE_FACTOR);
    scoreLowFilterFieldError = '';
    scoreHighFilterField = String(AI_CATEGORIZATION_SCORE_INITIAL_MAX * AI_CATEGORIZATION_SCORE_FACTOR);
    scoreHighFilterFieldError = '';
    scoreFilterFieldEnabled = false;

    /**
     * The business unit that is selected,
     * null if only the missing business units are selected,
     * undefined if all business units are selected
     */
    filteredBusinessUnitId: number | null | undefined;
    filteredApproval?: number;
    selectedCategory: string[] = [];
    singleMode = DEFAULT_SINGLE_MODE;

    ordering?: Ordering;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private data: CategorizationReviewDataDelegate,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)

        reaction(() => [this.data.requestedBagId, this.filteredBusinessUnitId, this.singleMode] as const, ([bagId, businessUnitId, singleMode]) => {
            console.debug('CategorizationReviewFilterManagerDelegate: change', {bagId, businessUnitId, singleMode});
            this.data.loadData()
        })
    }

    get searches() {
        return [this.search1, this.search2, this.search3]
    }

    resetFilter(approval: number | undefined) {
        this.filteredApproval = approval;
        this.selectedCategory = []
    }

    get selectedFilter(): MatSupplierFilter {
        const lCats = catsToDict(this.selectedCategory, this.selectedLevel);

        let search: MatSearch | undefined = undefined;
        if (this.supplierSearch.activeSearchString) {
            search = {
                supplier: this.supplierSearch.activeSearchString,
            }
        }
        let requestedBagId = this.data.requestedBagId;
        if (!requestedBagId) {
            console.warn('CategorizationReviewDataDelegate: requestedBagId is not properly refactored', requestedBagId)
        }

        return {
            databag: requestedBagId || -1,
            business_unit: this.filteredBusinessUnitId,
            search,
            level: this.selectedLevel,
            ...lCats,
            approval: this.filteredApproval,
        }
    }

    getPartUrlSearchParams(): URLSearchParams {
        // Convert search 1 to query params
        const params = new URLSearchParams();

        this.searches.forEach((s, i) => {
            // FE is indexed 1, BE is indexed 0
            if (s.search.activeSearchString && s.type?.typeId) {
                params.append(`search${i}_query`, s.search.activeSearchString);
                getBeFields(s.type?.typeId).forEach(f => params.append(`search${i}_fields`, f))
            }
        })

        // Also take into account the business unit and level filtering
        // TODO: [CAT-701] BE API needs to be updated to use business_unit_id instead of part__business_unit_id
        setUrlParamOrNull(params, 'part__business_unit_id', this.filteredBusinessUnitId)
        console.debug('CategorizationReviewFilterManagerDelegate.newSingleFilter()', params.toString())

        const filterOnFieldsPrefix = 'p_suggested_cat'
        setLeveLFilterUrlParamsFromArray(params, this.selectedLevel, this.selectedCategory, filterOnFieldsPrefix);

        if (this.advFilter.scoreLow !== null) {
            params.set('p_suggestion_score__gte', this.advFilter.scoreLow.toString());
        }
        if (this.advFilter.scoreHigh !== null) {
            params.set('p_suggestion_score__lte', this.advFilter.scoreHigh.toString());
        }

        if (this.ordering) {
            params.set('ordering', (this.ordering.direction === 'desc' ? '-' : '') + this.ordering.orderBy);
        }

        return params;
    }

    get selectedFilterType() {
        return matGetFilterType(this.selectedFilter)
    }

    get selectedLevel(): number {
        return this.selectedCategory.length;
    }

    setSingleMode(singleMode: boolean) {
        this.singleMode = singleMode;
    }

    toggleSingleMode() {
        this.setSingleMode(!this.singleMode);
    }

    setFilteredBusinessUnitId(businessUnitId: number | null | undefined) {
        this.filteredBusinessUnitId = businessUnitId
        const bagId = this.data.requestedBagId
        const taxonomySize = this.data.maxTaxonomySize;
        console.debug('CategorizationReviewFilterManagerDelegate.setFilteredBusinessUnitId', {
            bagId,
            businessUnitId,
            taxonomySize
        });
        if (bagId) {
            if (this.singleMode) {
                this.data.requestPartPages();
            } else {
                this.data._reviewLevelStatistics.request(({bagId, businessUnitId, taxonomySize}));
                this.data.requestSupplierPages(); // TODO[integration]: test this
            }
        }
    }

    setSelectedCategory(selectedCategory: string[]) {
        const obj = {old: `${this.selectedCategory}`, new: `${selectedCategory}`};
        console.debug('CategorizationReviewFilterManagerDelegate.setSelectedCategory', obj);

        this.selectedCategory = selectedCategory;
        if (this.singleMode) {
            this.data.requestPartPages();
        } else {
            this.data.requestSupplierPages();
        }
    }

    unsetSelectedCat() {
        this.selectedCategory = [];
        this.data.supplierPages.reset();
    }

    selectOneCategoryUp() {
        this.selectedCategory = this.selectedCategory.slice(0, -1);
    }


    canClickParentChart(index: number) {
        return index !== this.selectedLevel - 1;
    }

    clickParentChart(index: number) {
        if (index === this.selectedLevel - 1) {
            // The lowest element is clicked, which is already active
            console.warn('Categorization clicked lowest element for navigation which is ignored',
                `selectedCategory=${this.selectedCategory}`)
            return;
        }
        if (this.selectedCategory.length < 1) {
            console.warn('Unexpected click of navigation')
            return;
        }
        this.setSelectedCategory(this.selectedCategory.slice(0, index + 1))
    }

    get hasRemainingSelectionLevels(): boolean {
        if (this.selectedLevel >= this.data.maxTaxonomySize) {
            return false
        }
        // noinspection RedundantIfStatementJS
        if (this.selectedLevel >= 1 && !this.selectedCategoryLabel) {
            // Do not allow to go deeper in uncategorized
            return false;
        }
        return true;
    }

    get canSelectLevelDeeper(): boolean {
        if (!this.hasRemainingSelectionLevels) {
            return false;
        }
        // // Dirty hack for filtering out endless uncategorized tree leaves,
        // // not needed when the correct taxonomy size is used
        // if (this.selectedLevel >= 1) {
        //     if (this.data.currentSelectionStats?.length === 1 && this.data.currentSelectionStats[0].label === UNCATEGORIZED_VALUE) {
        //         return false;
        //     }
        // }
        return true;
    }

    get selectedCategoryLabel(): string | undefined {
        if (this.selectedCategory.length > 0) {
            return this.selectedCategory[this.selectedCategory.length - 1]
        }
        return undefined;
    }

    get selectedL1Category(): string | undefined {
        if (this.selectedCategory.length > 0) {
            return this.selectedCategory[0]
        }
        return undefined;
    }

    selectNextCategoryDown(selectedCategory: string) {
        this.setSelectedCategory([...this.selectedCategory, selectedCategory])
    }

    navigateToLevel(level: number) {
        this.setSelectedCategory(this.selectedCategory.slice(0, level));
    }

    onSearchChange(searchIndex: number, search: string, type: SearchType | undefined) {
        const inGroupMode = !this.singleMode

        const obj = {searchIndex, search, type, inGroupMode};
        console.debug('CategorizationReviewFilterManagerDelegate.onSearchChange', obj);

        if (inGroupMode) {
            if (isAllowedInGroupedMode(type?.typeId)) {
                // Do a grouped search
                this.supplierSearch.setSearch(search)
                this.supplierSearch.doSearch();
            }
        } else {
            // Just do a search
            // const searchActive = this.searches.some(s => s.search.activeSearchString);
            // // if (searchActive) {
            // //     this.setSingleMode(true)
            // // } else {
            // //     this.setSingleMode(false)
            // // }
            this.data.loadData()
        }


    }

    static isScoreFieldValid(value: string, min: number, max: number): string {
        if (value === '') {
            return '';
        }
        const num = Number(value);
        if (isNaN(num)) {
            return 'Not a number';
        }
        if (num < min || num > max) {
            return `Must be between ${min} and ${max}`;
        }
        return '';
    }

    setScoreLowFilterField(value: string) {
        this.scoreLowFilterField = value;
        // this.scoreLowFilterFieldError = CategorizationReviewFilterManagerDelegate.isScoreFieldValid(this.scoreLowFilterField, 0, this.scoreHighFilterFieldValue);
        // this.scoreHighFilterFieldError = CategorizationReviewFilterManagerDelegate.isScoreFieldValid(this.scoreHighFilterField, this.scoreLowFilterFieldValue, AI_CATEGORIZATION_SCORE_FACTOR);
        this.scoreFilterFieldEnabled = true;
    }

    private static clam(value: number, min: number, max: number): number {
        if (value < min) {
            return min;
        }
        if (value > max) {
            return max;
        }
        return value;
    }

    private static numOrDefault(value: string, defaultValue: number): number {
        if (value === '') {
            return defaultValue;
        }
        const num = Number(value);
        if(isNaN(num)) {
            return defaultValue;
        }
        return num;
    }

    get scoreLowFilterFieldValue(): number {
        const num = CategorizationReviewFilterManagerDelegate.numOrDefault(this.scoreLowFilterField, 0);
        return CategorizationReviewFilterManagerDelegate.clam(num, 0, AI_CATEGORIZATION_SCORE_FACTOR);
    }

    get scoreHighFilterFieldValue(): number {
        const num = CategorizationReviewFilterManagerDelegate.numOrDefault(this.scoreHighFilterField, AI_CATEGORIZATION_SCORE_FACTOR);
        return CategorizationReviewFilterManagerDelegate.clam(num, 0, AI_CATEGORIZATION_SCORE_FACTOR);
    }

    setScoreHighFilterField(value: string) {
        this.scoreHighFilterField = value;
        // this.scoreLowFilterFieldError = CategorizationReviewFilterManagerDelegate.isScoreFieldValid(this.scoreLowFilterField, 0, this.scoreHighFilterFieldValue);
        // this.scoreHighFilterFieldError = CategorizationReviewFilterManagerDelegate.isScoreFieldValid(this.scoreHighFilterField, this.scoreLowFilterFieldValue, AI_CATEGORIZATION_SCORE_FACTOR);
        this.scoreFilterFieldEnabled = true;
    }

    setScoreFilterFieldEnabled() {
        this.scoreFilterFieldEnabled = true;
    }

    toggleScoreFilterFieldEnabled() {
        this.scoreFilterFieldEnabled = !this.scoreFilterFieldEnabled;
    }

    get isAdvancedFilterEnabled() {
        return this.advFilter.scoreLow !== null
            || this.advFilter.scoreHigh !== null
    }

    get isAnyAdvancedFilterFieldEnabled() {
        return this.scoreFilterFieldEnabled
    }

    disableAdvancedFilter() {
        this.scoreFilterFieldEnabled = false;
        this.checkAdvancedFilterIsValid()

        let change = false;
        const newScoreLow = null;
        const newScoreHigh = null;
        if (this.advFilter.scoreLow !== newScoreLow) {
            this.advFilter.scoreLow = newScoreLow;
            change = true;
        }
        if (this.advFilter.scoreHigh !== newScoreHigh) {
            this.advFilter.scoreHigh = newScoreHigh;
            change = true;
        }

        if (change) {
            this.data.loadData()
        }
    }

    checkAdvancedFilterIsValid() {
        // Check validity
        this.scoreLowFilterFieldError = CategorizationReviewFilterManagerDelegate.isScoreFieldValid(this.scoreLowFilterField, 0, this.scoreHighFilterFieldValue);
        this.scoreHighFilterFieldError = CategorizationReviewFilterManagerDelegate.isScoreFieldValid(this.scoreHighFilterField, this.scoreLowFilterFieldValue, AI_CATEGORIZATION_SCORE_FACTOR);

        return this.advancedFilterIsValid;
    }

    get advancedFilterIsValid() {
        return this.scoreLowFilterFieldError === ''
            && this.scoreHighFilterFieldError === ''
    }

    applyAdvancedFilter() {
        let change = false;

        if(!this.advancedFilterIsValid) {
            console.warn('CategorizationReviewFilterManagerDelegate.applyAdvancedFilter: advanced filter is not valid')
            return;
        }
        this.scoreLowFilterField = String(this.scoreLowFilterFieldValue);
        this.scoreHighFilterField = String(this.scoreHighFilterFieldValue);
        if(this.scoreLowFilterFieldValue === 0 && this.scoreHighFilterFieldValue === AI_CATEGORIZATION_SCORE_FACTOR) {
            // Both are at the max
            this.scoreFilterFieldEnabled = false;
        }

        if (this.scoreFilterFieldEnabled) {
            const newScoreLow = this.scoreLowFilterFieldValue / AI_CATEGORIZATION_SCORE_FACTOR - AI_CATEGORIZATION_ROUNDING_ERROR_CORRECTION;
            const newScoreHigh = this.scoreHighFilterFieldValue / AI_CATEGORIZATION_SCORE_FACTOR + AI_CATEGORIZATION_ROUNDING_ERROR_CORRECTION;
            if (this.advFilter.scoreLow !== newScoreLow) {
                this.advFilter.scoreLow = newScoreLow;
                change = true;
            }
            if (this.advFilter.scoreHigh !== newScoreHigh) {
                this.advFilter.scoreHigh = newScoreHigh;
                change = true;
            }
        } else {
            const newScoreLow = null;
            const newScoreHigh = null;
            if (this.advFilter.scoreLow !== newScoreLow) {
                this.advFilter.scoreLow = newScoreLow;
                change = true;
            }
            if (this.advFilter.scoreHigh !== newScoreHigh) {
                this.advFilter.scoreHigh = newScoreHigh;
                change = true;
            }
        }

        if (change) {
            this.data.loadData()
        }

        return true;
    }

    toggleOrderBy(columnFieldId: string | undefined) {
        if (!columnFieldId) {
            this.ordering = undefined;
        } else {
            const defaultOrderingDirection = columnFieldId === 'p__spend' ? 'asc' : 'desc';
            this.ordering = {
                orderBy: columnFieldId,
                direction: (
                    this.ordering?.orderBy === columnFieldId
                        ? (this.ordering.direction === 'asc' ? 'desc' : 'asc') // Toggle it
                        : defaultOrderingDirection
                ),
            }
        }
        this.data.loadData()
    }
}
