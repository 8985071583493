import React from "react";
import {
    AnimatedCombinedParetoChart,
    ParetoData,
    ParetoProcessedDataType
} from "../../../../components/visualization/pereto/AnimatedCombinedParetoChart";

const width = 800;

type Props = {
    data?: ParetoProcessedDataType
    setClickedSupplier: (d: ParetoData) => void
}
export const SupplierSpendVisualization: React.FC<Props> = ({data, setClickedSupplier}) => {
    return <section className="supplier-spend-visualization">
        {/*l={'' + data?.level} l={'' + data?.allSuppliers.at(0)?.allStats.at(0)?.level}<br/>*/}
        {data && <AnimatedCombinedParetoChart
            data={data}
            width={width}
            height={270}
            showPP={false}
            hideLinePlot={true}
            hideCumulativePlot={true}
            hideLegend={true}
            paretoParam={0.8}
            onClick={setClickedSupplier}
            catAxisTitleEnd={''}
            hideRightAxis={true}
            barPadding={.5}
        />}
    </section>
}
