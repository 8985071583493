import React from "react";
import {observer} from "mobx-react-lite";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch
} from "@mui/material";
import {useStores} from "../../../stores";
import {TypedSearchManager} from "../../../stores/managers/TypedSearchManager";
import {Clear, FilterAlt, Search} from "@mui/icons-material";
import {isAllowedInGroupedMode} from "../../../stores/categorization/CategorizationReviewFilterManagerDelegate";
import {ChangeCategoryButton} from "./ChangeCategoryButton";

export const ReviewFilterBar: React.FC = observer(() => {
    const {categorizationStore, categorizationReviewStore} = useStores();
    const search1Id = `search-${categorizationStore.data.filterManager.search1.type?.typeId ?? '1'}`;
    const search2Id = `search-${categorizationStore.data.filterManager.search2.type?.typeId ?? '2'}`;
    // const search3Id = `search-${categorizationStore.data.filterManager.search3.type?.typeId ?? '3'}`;

    return <Grid container justifyContent="start" className="review-filter-bar">
        <Grid item xs={4}>
            <Checkbox checked={categorizationStore.data.allSelected}
                      onClick={() => categorizationReviewStore.toggleAllSelected()}/>

            <ChangeCategoryButton/>

            <FormControlLabel
                className="grouped-switch"
                control={<Switch size="small"
                                 checked={!categorizationStore.data.filterManager.singleMode}
                                 onChange={() => categorizationStore.data.filterManager.toggleSingleMode()}/>}
                label="Group by supplier"
            />

            {categorizationStore.data.filterManager.singleMode &&
                <Button
                    endIcon={<FilterAlt/>}
                    variant={categorizationStore.data.filterManager.isAdvancedFilterEnabled ? 'contained' : 'outlined'}
                    onClick={() => categorizationReviewStore.setAdvancedFilterDialogOpen(true)}>
                    Filter
                </Button>}
        </Grid>
        <Grid item xs={8}
              container spacing={4}>
            <Grid item xs={2}/>
            <Grid item xs={4}>
                <TypedSearchComponent
                    className="first"
                    sm={categorizationStore.data.filterManager.search1} cmpId={search1Id}/>
            </Grid>
            <Grid item xs={5}>
                <TypedSearchComponent sm={categorizationStore.data.filterManager.search2} cmpId={search2Id}/>
                {/*<TypedSearchComponent sm={categorizationStore.data.filterManager.search3} cmpId={search3Id}/>*/}
            </Grid>
            <Grid item xs={1} />
        </Grid>
    </Grid>
})

const TypedSearchComponent: React.FC<{ sm: TypedSearchManager, cmpId: string, className?: string }> = observer(
    ({
         sm,
         cmpId,
         className
     }) => {
        const {categorizationStore} = useStores();
        const searchTypeLabel = 'Search type';
        const hasOptions = sm.options.length > 1;

        const inGroupMode = !categorizationStore.data.filterManager.singleMode
        const isDisabled = inGroupMode && !isAllowedInGroupedMode(sm.type?.typeId);
        const searchFieldLabel = `${sm.type?.placeholder || ''}`;
        const searchString = sm.search.searchString;
        const color = (isDisabled && Boolean(searchString)) ? 'error' : (isDisabled ? 'warning' : undefined);
        return <div
            className={'typed-search-component' + (className ? ' ' + className : '') + (hasOptions ? ' has-options' : ' has-no-options')}>
            {hasOptions &&
                <FormControl variant="standard" className="typed-search-field-type">
                    <InputLabel htmlFor={cmpId + '-type'}>{searchTypeLabel}</InputLabel>
                    <Select
                        id={cmpId + '-type'}
                        value={sm.type?.typeId}
                        label={searchTypeLabel}
                        onChange={event => sm.setType(event.target.value as string)}
                    >
                        {sm.options.map(option =>
                            <MenuItem key={option.typeId} value={option.typeId}>{option.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            }
            <FormControl
                className="typed-search-field"
                variant="standard"
                fullWidth={!hasOptions}
                color={color}>
                <InputLabel htmlFor={cmpId + '-field'}>
                    {searchFieldLabel}
                </InputLabel>
                <Input
                    id={cmpId + '-field'}
                    type="text"
                    fullWidth={true}
                    endAdornment={
                        <InputAdornment position="end">
                            {searchString &&
                                <IconButton
                                    aria-label="clear search"
                                    onClick={() => sm.search.clearSearch()}
                                >
                                    <Clear/>
                                </IconButton>
                            }
                            <IconButton
                                aria-label="search"
                                onClick={() => sm.search.doSearch()}
                            >
                                <Search/>
                            </IconButton>
                        </InputAdornment>
                    }
                    value={searchString}
                    onChange={e => sm.search.setSearch(e.target.value)}
                    onKeyDown={e => e.key === 'Enter' ? sm.search.doSearch() : undefined}
                    aria-describedby={cmpId + '-error'}
                />
                {isDisabled &&
                    <FormHelperText id={cmpId + '-error'} error={Boolean(searchString)}>
                        This search is ignored in grouped mode
                    </FormHelperText>
                }
            </FormControl>
        </div>
    })
