import ProfileStore from "../../../../stores/ProfileStore";
import {ColSpec} from "../../../../components/table/MithraTableHeadColumns";

export function getSupplierBreakdownColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-p_name', txt: p.p.p_name_col_name, width: 125},
        p.p.p_description_col_name !== false ? {cls: 'col-p_description', txt: p.p.p_description_col_name} : undefined,
    ].concat(p.partContextColNames.map(({dataField, colName}) => (
        {cls: `col-${dataField}`, txt: colName}
    ))).concat([
        {cls: 'col-p_spend', txt: 'Amount', width: 90},
    ])
    return arr.filter(c => c) as ColSpec[];
}