import {RequestManager} from "../../../stores/managers/RequestManager";
import {AbsTreeNodeFilter, filterOnRoot} from "../../../services/classes/MatReviewClasses";
import {AxiosResponse} from "axios";
import {
    MatCategoryConcentrationStatistics,
    PpvGroup,
    PpvStatistic
} from "../../../services/classes/MaterializedClasses";
import {from} from "rxjs";
import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {BagStore} from "../../../stores/BagStore";
import ProfileStore from "../../../stores/ProfileStore";
import {makeAutoObservable} from "mobx";
import {catsToDict} from "../../../services/ApiHelpers";
import {BarDataPoint} from "../../../components/visualization/BarChart";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import {
    CurrencyAbbreviation,
    findAbbreviationOfGroup,
    toCurrency
} from "../../../components/currency-component/CurrencyComponent";
import {PageResponseManager} from "../../../stores/managers/PageResponseManager";
import {MatCommonKpiSerializer} from "../../../services/ApiTypes";


export const PPV_EXPORT_CONFIG = {
    fields: [
        "group_key",
        "ppv_opportunity",
        "spend_sum",
        "unit_cost_min",
        "unit_cost_avg",
        "n_supplier_ids",
        "group_size",
        "quantity_sum"
    ],
    currency_fields: [
        "ppv_opportunity",
        "spend_sum",
        "unit_cost_min",
        "unit_cost_avg",
    ],
    headers: [
        "Description",
        "Price variance cost",
        "Total spend",
        "Minimal cost",
        "Average unit cost",
        "Suppliers",
        "Transactions",
        "Total units"
    ],
}

export class PpvControllerStore {
    databag_id: number | undefined;
    selectedCategory: string[] = [];
    maxTaxonomySize = 2; // Do not allow to go deeper than L2 initially

    /**
     * Special request manager to manage the top root result. To show the total
     */
    readonly _ppvRootStatRequestManager = new RequestManager<{ databag: number }, AxiosResponse<PpvStatistic[]>>(
        ({databag}) => from(this.api.listPpvStatistics(filterOnRoot(databag)))
    );
    readonly _rootStatRequestManager = new RequestManager<{ databag: number }, AxiosResponse<MatCommonKpiSerializer>>(
        ({databag}) => from(this.api.getCommonKpi(databag))
    );
    /**
     * This request can change together with the selected category
     */
    readonly _ppvStatRequestManager = new RequestManager<AbsTreeNodeFilter, AxiosResponse<PpvStatistic[]>>(
        filter => from(this.api.listPpvStatistics(filter, {
            field: 'total_ppv_opportunity',
            desc: true
        }))
    );
    readonly _ppvGroupRequestManager = new PageResponseManager<AbsTreeNodeFilter, PpvGroup>(
        100,
        (page, filter) => this.api.listPpvGroups(page, filter, {
            field: 'ppv_opportunity',
            desc: true
        })
    );

    // Only used for showing the KeyValue statistics
    readonly _spendConcentration = new RequestManager<AbsTreeNodeFilter, AxiosResponse<MatCategoryConcentrationStatistics[]>>(
        filter => from(this.api.listSpendConcentrationCategory(filter.databag, filter.level))
    );

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    init(databagId: number, taxonomySize: number) {
        this.databag_id = databagId;
        this.maxTaxonomySize = taxonomySize;
        this.selectedCategory = [];
        if (this.activeFilter) {
            this._ppvRootStatRequestManager.request({databag: databagId})
            this._rootStatRequestManager.request({databag: databagId})
            this._ppvStatRequestManager.request(this.activeFilter)
            this._ppvGroupRequestManager.init(this.activeFilter)
            this._spendConcentration.request(this.activeFilter)
        } else {
            this._ppvRootStatRequestManager.cleanup()
            this._rootStatRequestManager.cleanup()
            this._ppvStatRequestManager.cleanup()
            this._ppvGroupRequestManager.reset()
            this._spendConcentration.cleanup()
        }
    }

    get activeFilter(): AbsTreeNodeFilter | undefined {
        if (this.databag_id === undefined) return undefined;
        const level = this.selectedCategory.length
        const selectedCats = catsToDict(this.selectedCategory, level);
        return {
            type: 'children',
            databag: this.databag_id,
            level: level,
            ...selectedCats,
        }
    }

    get activePpvStatistics(): PpvStatistic[] | undefined {
        return this._ppvStatRequestManager.result?.data || this._ppvRootStatRequestManager.result?.data
    }

    /**
     * PPV barchart data
     */
    get ppvCategoryData(): BarDataPoint[] | undefined | null {
        if (!this.activePpvStatistics) return undefined;
        if (this.activePpvStatistics.length === 0) return null;
        const abbreviation: CurrencyAbbreviation = findAbbreviationOfGroup(
            this.activePpvStatistics.map(d => d.total_ppv_opportunity)
        );
        return this.activePpvStatistics.map(d => {
            const category = d[`l${d.level}`];
            return ({
                category,
                categoryLabel: category === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : category,
                value: d.total_ppv_opportunity,
                valueLabel: toCurrency(this.profileStore.currencySymbol, d.total_ppv_opportunity, 3, abbreviation)
            });
        })
    }

    get ppvDataDoesNotExist() {
        return this.ppvCategoryData === null;
    }

    /**
     * PPV table data
     */
    get ppvTable(): PpvGroup[] | undefined {
        if (!this._ppvGroupRequestManager.data) return undefined;
        // const abbreviation: CurrencyAbbreviation = findAbbreviationOfGroup(
        //     this._ppvGroupRequestManager.data.flatMap(d =>
        //         [d.ppv_opportunity, d.spend_sum, d.unit_cost_min, d.unit_cost_avg]
        //     )
        // );
        return this._ppvGroupRequestManager.data;
    }

    get spendConcentration(): number | undefined {
        if (!this._spendConcentration.result) return undefined;
        const statistic = this._spendConcentration.result.data.find(d => d.level === 0);
        return statistic?.top_n_spend_percentage;
    }

    get spendTotalPPV(): number | undefined {
        if (!this._ppvRootStatRequestManager.result) return undefined;
        const statistic = this._ppvRootStatRequestManager.result.data.find(d => d.level === 0);
        return statistic?.total_ppv_opportunity;
    }

    get totalSuppliers(): number | undefined {
        return this._rootStatRequestManager.result?.data.n_suppliers;
    }

    get totalSpend(): number | undefined {
        return this._rootStatRequestManager.result?.data.total_spend;
    }

    get unclassifiedSpend(): number | undefined {
        return this._rootStatRequestManager.result?.data.unclassified_spend;
    }
}
