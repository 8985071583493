import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {TableCell, TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {PartCategorizationComponent} from "../../../../components/categorization/PartCategorizationComponent";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {SingleApprovalButtons} from "../../../../components/approval-acc-rej/SingleApprovalButtons";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {environment} from "../../../../env";
import {ReviewChoice} from "../../../../services/classes/MaterializedClasses";
import {getSpecificCategorization} from "../../../../services/classes/MatReviewHelpers";
import {MatPartReviewRowState} from "../../../../services/classes/MatReviewClasses";
import {ApprovalStatusEnum} from "../../../../services/classes/AiClasses";
import {CategorizationReviewFeedbackDelegate} from "../../../../stores/approval/CategorizationReviewFeedbackDelegate";
import {GoogleLink} from "../../../../components/_tiny/GoogleLink";
import {AiScore} from "../../../../components/_tiny/AiScore";
import moment from "moment";


type Props = {
    part: MatPartReviewRowState
    controller?: PartReCategorizationControllerStore // TODO: Move to context
};
export const CategorizationReviewFeedbackSubRow: React.FC<Props> = observer(({part}) => {
    const {authStore, bagStore, approvalStore, p} = useStores();

    const partState = CategorizationReviewFeedbackDelegate.calcSingleState(part);
    partState.editAllowed = !authStore.viewOnly && ApprovalStatusEnum.userCanUpdate(approvalStore.approval?.current_status.status);

    // FIXME: This Taxonomy size should come from the approvalStore?
    const leftCats = getSpecificCategorization(part, bagStore.taxonomy_size, partState.leftCat);
    const rightCats = getSpecificCategorization(part, bagStore.taxonomy_size, partState.rightCat);

    let onAccept: undefined | (() => void) = undefined;
    let onReject: undefined | (() => void) = undefined;
    if (partState.editAllowed) {
        onAccept = () => approvalStore.cat.reviewFeedback.clickAcceptReject(part, ReviewChoice.ACCEPT);
        onReject = () => approvalStore.cat.reviewFeedback.clickAcceptReject(part, ReviewChoice.REJECT);
        // if (approvalStore.cat.categories.taxonomy_categories) {
        //     categoryComponent = <PartReCategorizationComponent
        //         cats={currentCats}
        //         handleOpenSearch={event => {
        //             const anchor = event.currentTarget;
        //             controller.setOnSelect((newCats) => {
        //                 approvalStore.clickRecatPart(part, newCats)
        //             })
        //             controller.open(anchor)
        //         }}
        //     />
        // } else {
    }

    return <TableRow>
        <TableCell className="col-p_name" component="th" scope="row">
            {/*n_cats={'' + taxonomy_categories?.length}*/}
            {environment.isTest && <>p_id={part.p_id}<br/></>}
            {part.p_name || '-'}
        </TableCell>
        <TableCell className="col-p_spend">
            <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                <CurrencyComponent v={part.p_spend} abbreviation={abbreviation}/>
            }</CurrencyAbbreviationContext.Consumer>
        </TableCell>

        {p.p.p_description_col_name !== false &&
            <TableCell className="col-p_description">
                <GoogleLink type="part" objs={[part]}>
                    {part.p_description}
                </GoogleLink>
            </TableCell>
        }

        {p.partContextColNames.map(({dataField, colType, colClassName}) =>
            <TableCell className={colClassName}>
                {colType === 'date'
                    ? moment(part[dataField]).format('L')
                    : String(part[dataField])}
            </TableCell>
        )}

        <TableCell className="col-p_input_l">
            <PartCategorizationComponent cats={leftCats}/>
        </TableCell>
        <TableCell className="col-p_reviewed_l">
            <PartCategorizationComponent cats={rightCats}/>
        </TableCell>


        {p.showCategorizationScoreForBag(approvalStore.approval?.baseline_bag?.id) &&
            <TableCell className="col-p_alg_conf">
                <AiScore score={part.p_suggestion_score}/>
            </TableCell>
        }
        <TableCell className="col-p_YN" align="right">
            <SingleApprovalButtons
                onAccept={onAccept}
                onReject={onReject}
                state={partState}
            />
        </TableCell>
    </TableRow>
})
