import {Typography} from "@mui/material";
import React from "react";

export const SupplierSegmentationHelp: React.FC = () => <>
    <Typography color="textSecondary" gutterBottom>
        Supplier Segmentation
    </Typography>
    <Typography component="p">
        Segmenting your supplier base provides you with insights to optimize resource allocation as well as strategies
        across categories
    </Typography>
</>;
