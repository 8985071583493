import './TaxonomySuggestor.scss';
import React, {useMemo} from "react";
import ProfileStore from "../../stores/ProfileStore";
import {ColSpec, MithraTableHeadColumns} from "../../components/table/MithraTableHeadColumns";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {PartReCategorizationControllerStore} from "../../components/categorization/PartReCategorizationControllerStore";
import {PartReCategorizationHandler} from "../../components/categorization/PartRecategorizationHandler";
import {Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import {MithraTableCell} from "../../components/table/MithraTableCell";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {SelectionTableRows} from "../../stores/TaxonomySuggestorStore";

export function getDatasetTargetColumns(p: ProfileStore): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-p_spend', txt: 'Total spend', width: 300},
        {cls: 'col-p_spend_group_a', txt: 'Spend group A', width: 300},
        {cls: 'col-p_spend_group_b', txt: 'Spend group B', width: 300},
        {cls: 'col-p_spend_group_c', txt: 'Spend group C', width: 300},
        {cls: 'col-p_suppliers', txt: p.supplierDataName, width: 300},
        {cls: 'col-p_transactions', txt: 'Transactions', width: 300},
    ]
    return arr.filter(c => c) as ColSpec[];
}

type SelectionProps = {
    selectedNodeLabel: String
    // selectedNodeData: m_taxonomy.Data
    selectedNodeData: any
}

export const TaxonomySuggestionSelection: React.FC<SelectionProps> = observer(({
                                                                                   selectedNodeLabel,
                                                                                   selectedNodeData
                                                                               }) => {
    const {p, categorizationStore, categorizationReviewStore, taxonomySuggestorStore} = useStores()

    const controller: any = useMemo(() => {
        if (!categorizationReviewStore.categories.taxonomy_categories) {
            if (categorizationReviewStore.categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categorizationReviewStore.categories.taxonomy_categories);
    }, [categorizationReviewStore.categories.taxonomy_categories])

    const isLoading = categorizationStore.data.isLoadingParts;
    const tableClass = 'mithra-table x-design x-design-narrow' + (isLoading ? ' loading' : '');
    const data: SelectionTableRows[] = [{
        p__spend: selectedNodeData.p__spend__sum ? selectedNodeData.p__spend__sum : 0,
        p__spend_group_a: 0,
        p__spend_group_b: 0,
        p__spend_group_c: 0,
        s_suppliers: selectedNodeData.n_suppliers ? selectedNodeData.n_suppliers : 0,
        p__transactions: selectedNodeData.n_transactions ? selectedNodeData.n_transactions : 0,
    }]
    // const data: SelectionTableRows[] = [{
    //     p__spend: 3600000000,
    //     p__spend_group_a: 0,
    //     p__spend_group_b: 0,
    //     p__spend_group_c: 0,
    //     s_suppliers: 32000,
    //     p__transactions: 41000,
    // }]

    const COLUMNS = getDatasetTargetColumns(p)
    //FIXME: Take out the controller
    return <div className={tableClass}>
        {controller && <PartReCategorizationHandler controller={controller}/>}
        <Typography variant='h4'>{selectedNodeLabel}</Typography>
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody className="categorization-review-table-body">
                {data ? <>
                        {data.map((row, i) => <React.Fragment key={i}>
                            <TaxonomySuggestionRow part={row} controller={controller}/>
                        </React.Fragment>)}
                        {data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
        </Table>
    </div>
})

type Props = {
    part: any
    controller: PartReCategorizationControllerStore // TODO: Move to context
};

export const TaxonomySuggestionRow: React.FC<Props> = observer(({part, controller}) => {
    const {p} = useStores();
    const COLUMNS = getDatasetTargetColumns(p);

    let i = 0;

    return <TableRow>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.p__spend}></CurrencyComponent>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.p__spend_group_a}></CurrencyComponent>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.p__spend_group_b}></CurrencyComponent>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyComponent v={part.p__spend_group_c}></CurrencyComponent>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.s_suppliers ? part.s_suppliers : 'N/A'}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {part.p__transactions ? part.p__transactions : 'N/A'}
        </MithraTableCell>
    </TableRow>
})