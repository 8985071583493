import React from "react";
import {observer} from "mobx-react-lite";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {useStores} from "../../../../stores";
import {MithraTableHeadColumns} from "../../../../components/table/MithraTableHeadColumns";
import {MithraTablePaginationActions} from "../../../../components/table-pagination/MithraTablePaginationActions";
import {ApprovalStatusEnum} from "../../../../services/classes/AiClasses";
import {SingularCategorizationApprovalRow} from "./SingularCategorizationApprovalRow";
import {getCatApprovalSubColumns} from "../CategorizationApprovalColumns";

export const SingularCategorizationApprovalTable: React.FC = observer(() => {
    const {p, approvalStore} = useStores()
    const catApproval = approvalStore.cat

    const pages = catApproval.data.partPages;
    const data = pages.data;
    const isLoading = catApproval.data.isLoadingParts;

    if (data?.length === 0 && (
        approvalStore.approval?.current_status.status === ApprovalStatusEnum.APPROVED ||
        approvalStore.approval?.current_status.status === ApprovalStatusEnum.REJECTED
    )) return <></>;

    const tableClass = 'mithra-table x-design categorization-approval-table singular x-design-narrow' + (isLoading ? ' loading' : '');
    const COLUMNS = getCatApprovalSubColumns(p, approvalStore.approval?.baseline_bag?.id, true);
    return <TableContainer className={tableClass}>
        <p>approval data = {'' + data?.length}</p>
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody className="categorization-approval-table-body">
                {data ? <>
                        {data.map(row => <React.Fragment key={row.id}>
                            {/*<CurrencyAbbreviationContext.Provider value={abbreviation}>*/}
                            <SingularCategorizationApprovalRow part={row}/>
                            {/*</CurrencyAbbreviationContext.Provider>*/}
                        </React.Fragment>)}
                        {data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    {/*<TableCell colSpan={3}>*/}
                    {/*    <ReviewFilterFooter/>*/}
                    {/*</TableCell>*/}
                    <TablePagination
                        //
                        rowsPerPageOptions={[]}
                        colSpan={COLUMNS.length - 3}
                        count={pages.count}
                        rowsPerPage={pages.pageSize}
                        page={pages.page - 1}
                        // SelectProps={{
                        //     inputProps: {'aria-label': 'rows per page'},
                        //     native: true,
                        // }}
                        // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                        onPageChange={(e, page) => pages.changePage(page + 1)}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
