import './LookerPreviewPage.scss';
import React from "react";
import {environment} from "../../env";
import {useStores} from "../../stores";

type Props = {
    lookerUrl?: string
    /**
     * hack Only for SHV
     */
    subsidiaryLookerUrls?: string[]
}
export const LookerPreviewPage: React.FC<Props> = ({lookerUrl, subsidiaryLookerUrls}) => {
    const {bagStore} = useStores();

    if (subsidiaryLookerUrls) {
        switch (bagStore.bagId) {
            case 1411: // Take the first one
                lookerUrl = subsidiaryLookerUrls.at(0)
                break;
            case 1402:
                lookerUrl = subsidiaryLookerUrls.at(1)
                break;
            case 1403:
                lookerUrl = subsidiaryLookerUrls.at(2)
                break;
            default:
                console.warn("LookerPreviewPage: No lookerUrl for bagId: " + bagStore.bagId)
        }
    }

    return <iframe
        title="looker-preview-page"
        className="looker-preview-page"
        src={lookerUrl ? lookerUrl : environment.lookerPreviewUrl}
        width={"1860px"}
        height="911px" // height="820px"
    />
};
