import './BarChart2.scss';
import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
import {Axis} from "d3";
import {useTheme} from "@mui/styles";
import {Theme} from '@mui/material';
import {getColorMonotone} from "../../../style/colors";
import {getParetoFunction} from "../functions";
import {appendLine} from "../../../utils/d3-utils";
import Margin from "../../../utils/margin";
import {D3GSelection} from "../../../utils/global";

export type Options = {
    height: number | 'auto'
    width: number
    margin: Margin
    vertical: boolean
    labelMargin: number
    valueLabelPxW: number
    valueLabelPxH: number
    tickSize: number
    curve?: (v: number) => number
    valueAxisPercentage: boolean
    redLine?: number
    redLineText1?: string
    redLineText2?: string
    axisLabelAngle?: number
    valueAxisTitle?: string
    categoryAxisTitle?: string
    addHover: boolean
    hideAxis: boolean
    padding: number | 'auto'
    barPadding: number
    axisTitleMargin: number
    axisTitleHeight: number
    onClicked?: (d: BarDataPoint) => void
}

export type BarDataPoint = {
    value: number
    category: string
    categoryLabel?: string
    valueLabel?: string
}
export type Data = BarDataPoint[];

type Props = {
    data: Data
    options: Partial<Options>
};

const DEFAULT_MARGIN: Margin = {
    left: 1,
    right: 1,
    top: 1,
    bottom: 1,
};
const lineTextOffset = 3;
const lineText2Offset = 15;

// Taken from: https://observablehq.com/@d3/horizontal-bar-chart
// D3+React: https://medium.com/@jeffbutsch/using-d3-in-react-with-hooks-4a6c61f1d102
export const BarChartV2: React.FC<Props> = ({data, options}) => {
    const theme = useTheme();
    const svgRef = useRef<SVGSVGElement>(null);

    const o: Options = deduceAdvancedOptions({
        height: 'auto' as const,
        width: 200,
        vertical: false,
        labelMargin: 100, // Of the total width or height
        valueLabelPxW: options?.valueAxisPercentage ? 32 : 20,
        valueLabelPxH: 16,
        tickSize: 4,
        valueAxisPercentage: false,
        addHover: true,
        hideAxis: false,
        padding: .7,
        barPadding: .5,
        axisTitleMargin: 5,
        axisTitleHeight: 40,
        ...options,
    })

    const graphHeight: number = o.height === 'auto' ? data.length * o.valueLabelPxH : o.height;
    const height = graphHeight + o.margin.top + o.margin.bottom;
    const padding: number = o.padding === 'auto' ? (data.length < 15 ? .4 : .4) : o.padding;

    let max: number;
    if (o.valueAxisPercentage) {
        max = 1;
    } else {
        max = d3.max(data, d => d.value) as number;
        const max2 = d3.max(data, d => (d as any).plot_value) as number;
        if (max2 !== undefined) {
            max = Math.max(max, max2);
        }
    }

    useEffect(() => {
        if (!svgRef.current || !data) return;
        const svg = d3.select(svgRef.current as SVGElement)
        svg.html('')
        const root = svg.append('g')
        new BarChartBuilder(root, o, data, max, theme as any, graphHeight, padding)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, svgRef, graphHeight])


    return <svg
        ref={svgRef}
        className="bar-chart-v2"
        viewBox={`0 0 ${o.width} ${height}`}
        style={{width: '100%', height: 'auto'}}
    />;
};

class BarChartBuilder {
    constructor(
        private readonly root: D3GSelection<any>,
        private readonly options: Options,
        data: Data,
        max: number,
        theme: Theme,
        graphHeight: number,
        padding: number,
    ) {
        const graphWidth = this.options.width - this.options.margin.left - this.options.margin.right;
        // console.log('graphWidth', graphWidth, 'graphHeight', graphHeight)

        // // DEBUG: show margins
        // this.root.append('rect')
        //     .attr('x', 0)
        //     .attr('y', 0)
        //     .attr('width', graphWidth)
        //     .attr('height', graphHeight);

        this.root
            .classed('bar-chart', true)
            .attr("transform", "translate(" + options.margin.left + "," + options.margin.top + ")");

        // set the ranges
        const catRange = this.options.vertical ? [0, graphWidth] : [0, graphHeight]
        const catDomainLabels: { [name: string]: string } = data.reduce((dict, data) => {
            dict[data.category] = data.categoryLabel ? data.categoryLabel : data.category;
            return dict;
        }, {});
        let catAxis = d3.scaleBand()
            .domain(data.map(d => d.category))
            .range(catRange)
            .padding(padding)
        const BAR_SPACING = this.options.barPadding / 2 * catAxis.bandwidth()

        const valRange = this.options.vertical ? [graphHeight, 0] : [0, graphWidth];
        const valDomain = [0, max];
        // [0, d3.max(data, function(d) { return d.sales; })]
        let valueAxis = d3.scaleLinear()
            .domain(valDomain)
            .range(valRange)

        const originXY = [catRange[0], valRange[0]];
        const catEndpointXY = this.options.vertical ? [catRange[1], valRange[0]] : [valRange[0], catRange[1]];
        // const valEndpointXY = vertical ? [catRange[0], valRange[1]] : [valRange[1], catRange[1]];

        // append the rectangles for the bar chart
        const barGroups = root
            .append('g')
            .classed('data-bars', true)
            .selectAll('g.bar-wrapper')
            .data(data)
            .join('g')
            .classed('bar-wrapper', true)
            .classed('uncat', d => d.category === '' || d.category === null)

        if (this.options.addHover) {
            if (this.options.vertical) {
                barGroups.append('rect')
                    .classed('hover-overlay', true)
                    .attr('x', d => (catAxis(d.category) as number))
                    .attr('y', 0)
                    .attr('width', catAxis.bandwidth())
                    .attr('height', graphHeight)
            } else {
                barGroups.append('rect')
                    .classed('hover-overlay', true)
                    .attr('x', valRange[0])
                    .attr('y', d => (catAxis(d.category) as number))
                    .attr('width', valRange[1] - valRange[0])
                    .attr('height', catAxis.bandwidth())
            }
        }

        const bars = barGroups
            .append('rect')
            .classed('bar', true)
            .attr('fill', d => {
                // return getColorByCat(d.category);
                return getColorMonotone(theme, d.category)
            })
        if (this.options.vertical) {
            bars.attr("x", d => (catAxis(d.category) as number) + BAR_SPACING)
                .attr("y", d => (valueAxis(d.value) as number))
                .attr("width", catAxis.bandwidth() * (1 - this.options.barPadding))
                .attr("height", d => graphHeight - valueAxis(d.value))
        } else {
            bars.attr("y", d => (catAxis(d.category) as number) + BAR_SPACING)
                .attr("width", d => valueAxis(d.value))
                .attr("height", catAxis.bandwidth() * (1 - this.options.barPadding))
        }

        if (this.options.onClicked) {
            const onClick = this.options.onClicked.bind(this);
            barGroups
                .classed('clickable', true)
                .on('click', function () {
                    const data = d3.select(this).datum() as BarDataPoint;
                    // console.log('barGroups.click', data);
                    onClick(data);
                })
        }

        // Add the catAxis
        const catAxisWrapper = root.append('g')
            .classed('cat-axis axis', true)
        const catAxisGroup = catAxisWrapper.append('g')
        const catAxisFormat = (ax: Axis<string>) => ax
            .tickFormat(d_name => catDomainLabels[d_name])
            .tickSize(this.options.hideAxis ? 0 : 6)
        if (this.options.vertical) {
            catAxisGroup.attr("transform", "translate(0," + graphHeight + ")")
            catAxisGroup.call(catAxisFormat(d3.axisBottom(catAxis)))
            if (this.options.axisLabelAngle) {
                catAxisGroup.selectAll('text')
                    .style("text-anchor", "end")
                    .attr("dx", "-.8em")
                    .attr("dy", this.options.axisLabelAngle === -90 ? '-.6em' : '.15em')
                    .attr("transform", `rotate(${this.options.axisLabelAngle})`);
            } else {
                // REMOVE THE FIRST ELEMENT: catAxisGroup.call(g => g.select('text').remove())
            }
        } else {
            if (this.options.hideAxis) {
                catAxisGroup.attr("transform", "translate(-12,0)")
            }
            catAxisGroup.call(catAxisFormat(d3.axisLeft(catAxis)))
        }
        catAxisGroup
            .call(g => g.selectAll('text').classed('MuiTypography-body1', true))
            .call(g => g.select('.domain').remove())

        if (!this.options.hideAxis) {
            // Add a line (without bars)
            if (this.options.vertical) {
                catAxisWrapper.append('line')
                    .attr('x1', originXY[0])
                    .attr('y1', originXY[1] + 0.5)
                    .attr('x2', catEndpointXY[0])
                    .attr('y2', catEndpointXY[1] + 0.5)
            } else {
                catAxisWrapper.append('line')
                    .attr('x1', originXY[0] + 0.5)
                    .attr('y1', originXY[1])
                    .attr('x2', catEndpointXY[0] + 0.5)
                    .attr('y2', catEndpointXY[1])
            }
        }

        // Add the title
        if (this.options.categoryAxisTitle) {
            if (this.options.vertical) {
                catAxisWrapper.append('text')
                    .style('text-anchor', 'middle')
                    .text(`${this.options.categoryAxisTitle}`)
                    .attr("x", (catRange[1] - catRange[0]) / 2)
                    .attr("y", valRange[0] + this.options.margin.bottom - this.options.axisTitleHeight)
                    .attr("dy", "1em")
            } else {
                catAxisWrapper.append('g')
                    .attr("transform", `translate(${-this.options.margin.left},${(catRange[1] - catRange[0]) / 2})`)
                    .append('text')
                    .attr("transform", `rotate(-90)`)
                    .attr("dy", "1em") // Beware, this is done before rotation!
                    .style('text-anchor', 'middle')
                    .text(`${this.options.categoryAxisTitle}`)
            }
        }

        if (!this.options.hideAxis) {
            // Add the value Axis
            const valAxisWrapper = root.append('g')
                .classed('val-axis axis', true)
            const valAxisGroup = valAxisWrapper.append('g')
            const valueAxisFormat = (ax: Axis<d3.NumberValue>) =>
                this.options.valueAxisPercentage
                    ? ax.ticks(5).tickFormat(v => `${(v as number) * 100}%`)
                    // ? ax.ticks(10).tickFormat(v => `${(v as number) * 100}%`)
                    : ax.ticks(5).tickFormat(v => d3.format("~s")(v))
            if (this.options.vertical) {
                valAxisGroup.call(valueAxisFormat(d3.axisLeft(valueAxis)))
            } else {
                valAxisGroup.attr("transform", "translate(0," + graphHeight + ")")
                valAxisGroup.call(valueAxisFormat(d3.axisBottom(valueAxis)))
            }
            valAxisGroup
                .call(g => g.selectAll('text').classed('MuiTypography-body1', true))

            // Add the title
            if (this.options.valueAxisTitle) {
                if (this.options.vertical) {
                    valAxisWrapper.append('g')
                        .attr("transform", `translate(${-this.options.margin.left},${(valRange[0] - valRange[1]) / 2})`)
                        .append('text')
                        .attr("transform", `rotate(-90)`)
                        .attr("dy", "1em") // Beware, this is done before rotation!
                        .style('text-anchor', 'middle')
                        .text(`${this.options.valueAxisTitle}`)
                } else {
                    valAxisWrapper.append('text')
                        .style('text-anchor', 'middle')
                        .text(`${this.options.valueAxisTitle}`)
                        .attr("x", (valRange[1] - valRange[0]) / 2)
                        .attr("y", graphHeight + this.options.margin.bottom - this.options.axisTitleHeight)
                        .attr("dy", "1em")
                }
            }
        }


        const FONT_SIZE = 12;
        const MAX_WORD_SIZE = FONT_SIZE * 10
        const SPACING = 4;
        const yOffset = 1;

        const showValueLabels = true;
        if (showValueLabels) {
            const valueLabels = barGroups.append('text')
                .classed('value-label', true)
                .text(d => d.valueLabel ? d.valueLabel : `${d.value}`)

            // const valueLabels = root.append("g")
            //     .attr("fill", "black")
            //     // .attr("font-family", "sans-serif")
            //     // .attr("font-size", FONT_SIZE)
            //     .attr("text-anchor", vertical ? 'middle' : 'start')
            //     .selectAll("text")
            //     .data(data)
            //     .join("text")
            //     // .attr("dy", "0.35em")
            //     .text(d => d.valueLabel ? d.valueLabel : `${d.value}`)
            if (this.options.vertical) {
                valueLabels
                    .attr('dominant-baseline', 'text-bottom')
                    .attr('text-anchor', 'middle')
                    .attr("x", d => (catAxis(d.category) as number) + catAxis.bandwidth() / 2)
                    .attr("y", d => valueAxis(d.value) - SPACING)
                    .call(text =>
                        text.filter(d => valueAxis(d.value) < FONT_SIZE * 2)
                            .attr("y", d => valueAxis(d.value) + SPACING)
                            .attr('dominant-baseline', 'hanging')
                    )
            } else {
                valueLabels
                    .attr('dominant-baseline', 'middle')
                    .attr("x", d => valueAxis(d.value) + SPACING)
                    .attr("y", d => (catAxis(d.category) as number) + catAxis.bandwidth() / 2 + yOffset)
                    .call(text => {
                            text.filter(d => valRange[1] - valueAxis(d.value) < MAX_WORD_SIZE)
                                .classed('value-in-bar', true)
                                .attr("text-anchor", 'end')
                                .attr("x", d => Math.min(
                                    valueAxis(d.value) - SPACING,
                                    this.options.redLine ? valueAxis(this.options.redLine) - SPACING : Number.MAX_VALUE,
                                ))
                        }
                    )
            }
        }

        type CurveType = {
            name: string;
            cut: number;
            value: number;
        }
        if (this.options.curve) {
            const curveData = data.map((d, i) => ({
                name: d.category,
                cut: i / data.length,
                value: (d as any).plot_value,
            }));
            // const curveData = data.map((d, i) => ({
            //     name: d.name,
            //     cut: i / data.length,
            //     value: curve(i / data.length),
            // }))
            // console.log('curveData', curveData);
            // Add the line
            const curveLine = this.options.vertical
                ? d3.line<CurveType>(
                    d => d.cut * graphWidth + catAxis.bandwidth() / 2,
                    // (d, i) => graphWidth / data.length * i + catAxis.bandwidth(),
                    d => valueAxis(d.value) as number,
                )
                : d3.line<CurveType>(
                    d => valueAxis(d.value) as number,
                    d => d.cut * graphHeight + catAxis.bandwidth() / 2,
                )

            // : d3.line<CurveType>()
            //     .x(d => catAxis(d.name))
            //     .y(d => valueAxis(d.value));
            root.append("path")
                .classed('curve', true)
                .datum(curveData)
                .attr("fill", "none")
                .attr("stroke", "steelblue")
                .attr("stroke-width", 1.5)
                .attr("d", curveLine)

            const p = getParetoFunction(80, max);
            const idealCurve = data.map((d, i) => ({
                name: d.category,
                cut: i / data.length,
                // cut: (i+1) / (data.length - 1),
                value: p(i / data.length),
            }));
            // const curveData = data.map((d, i) => ({
            //     name: d.name,
            //     cut: i / data.length,
            //     value: curve(i / data.length),
            // }))
            // console.log('idealCurve', idealCurve);
            // Add the line
            const line = this.options.vertical
                ? d3.line<CurveType>(
                    d => d.cut * graphWidth + catAxis.bandwidth() / 2,
                    // (d, i) => graphWidth / data.length * i + catAxis.bandwidth(),
                    d => valueAxis(d.value) as number,
                )
                : d3.line<CurveType>(
                    d => valueAxis(d.value) as number,
                    d => d.cut * graphHeight + catAxis.bandwidth() / 2,
                )

            root.append("path")
                .classed('curve', true)
                .datum(idealCurve)
                .attr("fill", "none")
                .attr("stroke", "purple")
                .attr("stroke-width", 1.5)
                .attr("d", line)
        }

        if (this.options.redLine !== undefined) {
            const lineValuePx = valueAxis(this.options.redLine) as number
            const lineOffset = 3;
            const lineHeight = graphHeight - lineOffset;
            const line = this.options.vertical
                ? appendLine(root, 0, lineValuePx, graphWidth, lineValuePx, 'highlight-line')
                : appendLine(root, lineValuePx, lineOffset, lineValuePx, lineHeight, 'highlight-line')
            // line.attr('stroke', theme.palette.warning.main)
            // line.attr('stroke', theme.palette.warning.light)

            if (this.options.redLineText1) {
                root.append('text')
                    .attr('x', lineValuePx + 3)
                    .attr('y', graphHeight + lineTextOffset)
                    .attr('dominant-baseline', 'hanging')
                    .classed('highlight-text text1', true)
                    .text(this.options.redLineText1)
            }
            if (this.options.redLineText2) {
                root.append('text')
                    .attr('x', lineValuePx + 3)
                    .attr('y', graphHeight + lineTextOffset + lineText2Offset)
                    .attr('dominant-baseline', 'hanging')
                    .classed('highlight-text text2', true)
                    .text(this.options.redLineText2)
            }
        }
    }
}

type AdvOptions = 'margin'

function deduceAdvancedOptions(options: Omit<Options, AdvOptions>): Options {
    /**
     * The margin is the margin of the bars only (excluding the labels)
     */
    const margin = {...DEFAULT_MARGIN};
    if (options.labelMargin) {
        if (options.vertical) {
            margin.bottom += options.labelMargin;
        } else {
            margin.left += options.labelMargin;
        }
    }
    if (options.vertical) {
        margin.left += options.valueLabelPxW + options.tickSize;
        margin.top += options.valueLabelPxH / 2;
    } else {
        margin.bottom += options.valueLabelPxH + options.tickSize;
        margin.right += options.valueLabelPxW / 2;
    }
    if (options.categoryAxisTitle) {
        if (options.vertical) {
            margin.bottom += options.axisTitleHeight + options.axisTitleMargin;
        } else {
            // noinspection JSSuspiciousNameCombination
            margin.left += options.axisTitleHeight + options.axisTitleMargin;
        }
    }
    if (options.valueAxisTitle) {
        if (options.vertical) {
            // noinspection JSSuspiciousNameCombination
            margin.left += options.axisTitleHeight + options.axisTitleMargin;
        } else {
            // Special case, when the title is just below the value axis, then some extra margin is needed
            margin.bottom += options.axisTitleHeight + 4 + options.axisTitleMargin;
        }
    }
    if (options.redLine) {
        const extra = (options.redLineText1 ? lineTextOffset : 0) + (options.redLineText2 ? lineText2Offset : 0);
        if (options.vertical) {
            margin.left += extra
        } else {
            margin.bottom += extra
        }
    }
    return {
        margin,
        ...options,
    };
}

