import ProfileStore from "../../../stores/ProfileStore";
import {ColSpec} from "../../../components/table/MithraTableHeadColumns";

export function getCatApprovalSubColumns(p: ProfileStore, bagId: number | undefined, singular: boolean): ColSpec[] {
    const arr: (ColSpec | undefined)[] = [
        {cls: 'col-selection', txt: '', align: 'center' as const} as ColSpec,
        (!singular) ? undefined : {cls: 'col-s_name', txt: p.supplierDataName},
        {cls: 'col-p_name', txt: p.p.p_name_col_name, width: 125},
        {cls: 'col-p_spend', txt: 'Amount', width: 90},
        p.p.p_description_col_name !== false ? {cls: 'col-p_description', txt: p.p.p_description_col_name} : undefined,
    ].concat(p.partContextColNames.map(({dataField, colName}) => (
        {cls: `col-${dataField}`, txt: colName}
    ))).concat([
        {cls: 'col-p_input_l', txt: 'Previous category'},
        {cls: 'col-p_reviewed_l', txt: 'Reviewed category'},
        p.showCategorizationScoreForBag(bagId) ? {cls: 'col-p_alg_conf', txt: 'Score'} : undefined,
        {cls: 'col-p_YN', txt: '', align: 'right' as const} as ColSpec,
    ])
    return arr.filter(c => c) as ColSpec[];
}
