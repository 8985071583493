import './CollapsibleIndentTree.scss';
import {D3GSelection} from '../../../utils/global';
import * as d3 from 'd3';
import {ScaleBand} from 'd3';
import React, {useEffect, useMemo, useRef, useState} from "react";
import Margin from "../../../utils/margin";
import {CDT_FILTERS} from "../../../jobs/koi/key-data-facts/KoiKeyDataFacts";
import {Observable, Subscription} from "rxjs";
import {UNCATEGORIZED_LABEL} from "../../../constants";
import {MatKpiTreeData} from "../../../services/ApiTypes";
import {getHardcodedLabel} from "./labelmapping";
import {ReviewStatusApiDataType} from "../../../services/ApiHelpers";

// TODO: This does not work for mixed characters, please check
const MAX_LABEL_SIZE = 40; // Tuned for LG

const ARROW_RIGHT = 'M7 4L1 8V0z';
const ARROW_LEFT = 'M0 4L6 8V0z';

type Column<V> = {
    columnTitle: string;
    getValue: (d: d3.HierarchyNode<TreeDataType<V>>, c: CollapsibleIndentTreeBuilder<V>) => string,
    xOffset: number
};

export type TreeDataType<V = { [key: string]: number }> = {
    id: number;
    label?: string;
    selected: boolean;
    childSelected: boolean;
    highlighted: boolean;
    children: TreeDataType<V>[]
    values: V;
    reviewStatus?: ReviewStatusApiDataType

}

type Tree<V> = d3.HierarchyNode<TreeDataType<V>>;

/**
 * This shouldn't be exported...
 */
export interface ExtendedTree<V> extends d3.HierarchyNode<TreeDataType<V>> {
    id: string
    collapsed: boolean
    filtered: boolean
    canOpen: boolean
    children: this[] // The current children to show
    _children: this[] // The original complete set of children that can be shown
}

/**
 * @deprecated TODO[integration] Filtering of the tree should not be in the pipe architecture
 */
export type FilterSpecification = {
    byLevel?: Observable<number>,
    byLabel?: Observable<string>,
};

export type CollapsibleIndentTreeData<C = any> = Tree<C>;

export type UpdateData = Partial<{
    filter: number
    hiddenRoot: boolean
    hiddenUncat: boolean
}>

export type Options<V> = {
    height: number
    width: number
    margin: Margin
    leftToRight: boolean
    $filter?: FilterSpecification
    overrideOnClick?: (d: ExtendedTree<V>) => void,
    onDataSelectionChange?: (old: TreeDataType<V> | undefined, newValue: TreeDataType<V> | undefined) => void
    columns: Column<V>[]
}

type Props = UpdateData & {
    data: CollapsibleIndentTreeData<MatKpiTreeData>
    options?: Partial<Options<MatKpiTreeData>>
}
export const CollapsibleIndentTree: React.FC<Props> = ({data, options, filter, hiddenRoot, hiddenUncat}) => {
    const svgRef = useRef<SVGSVGElement>(null)
    const o = useMemo<Options<MatKpiTreeData>>(() => ({
        width: 610,
        height: 0,
        margin: {
            left: 7,
            right: 0,
            top: 0,
            bottom: 0,
        },
        leftToRight: true,
        columns: [],
        ...options,
    }), [options]);

    const [controller, setController] = useState<CollapsibleIndentTreeBuilder<MatKpiTreeData> | undefined>();
    useEffect(() => {
        if (controller || !svgRef.current || !data) return;
        const svg = d3.select(svgRef.current as SVGElement)
        // svg.html('')
        const root = svg.append('g')
        const sortBy: (a: MatKpiTreeData, b: MatKpiTreeData) => number = (a, b) => b.spend - a.spend;
        setController(new CollapsibleIndentTreeBuilder<MatKpiTreeData>(root, o, data, sortBy as any))
    }, [data, svgRef, o, controller])

    useEffect(() => {
        if (!controller) return;
        controller.update({filter, hiddenRoot, hiddenUncat});
    }, [controller, filter, hiddenRoot, hiddenUncat])

    return <svg
        ref={svgRef}
        className="collapsible-indent-tree-viz"
        viewBox={`0 0 ${o.width} ${o.height}`}
        style={{width: '100%', height: 'auto'}}
    />;
}

export class CollapsibleIndentTreeBuilder<V> {
    private readonly data: ExtendedTree<V>;

    nodeSize = 20

    duration = 0;

    nodeGroup: D3GSelection<any>;
    linkGroup: D3GSelection<any>;
    iconGroup: D3GSelection<any>;
    headersContainer: D3GSelection;

    disabledClick = false;

    yAxis: ScaleBand<string> | undefined;
    private rootYOffset = 0;

    private readonly filterSubscription = new Subscription();
    private onRedraw: undefined | (() => void);
    private onClick: (d: ExtendedTree<V>) => void;

    constructor(
        private readonly root: D3GSelection<any>,
        private readonly options: Options<V>,
        data: Tree<V>,
        private readonly sortBy?: (a: V, b: V) => number,
        public hiddenRoot = false, // TODO: Duplicate defined
    ) {
        this.root
            .classed('collapsible-indent-tree', true)
            .attr("transform", "translate(" + options.margin.left + "," + options.margin.top + ")");

        this.nodeGroup = this.root.append('g').classed('nodes', true);
        this.linkGroup = this.root.append('g').classed('links', true);
        this.iconGroup = this.root.append('g').classed('icons', true)
        this.headersContainer = this.root.append('g').classed('headers', true)

        if (this.sortBy) {
            const sortBy = this.sortBy;
            data.sort((a, b) => sortBy(a.data.values, b.data.values))
        }

        this.data = this.initNodes(data)

        // Setup collapsible behavior based on level
        if (options.$filter) {
            if (options.$filter.byLevel) {
                this.filterSubscription.add(options.$filter.byLevel.subscribe(level => {
                    this.openToLevel(level)
                    this.redraw()
                }))
            }
            if (options.$filter.byLabel) {
                this.filterSubscription.add(options.$filter.byLabel.subscribe(label => {
                    this.filterByLabel(label)
                    this.redraw()
                }))
            }
        }

        if (options.overrideOnClick) {
            this.onClick = options.overrideOnClick;
        } else {
            this.onClick = d => {
                if (this.disabledClick) return;

                if (!d.canOpen) return;

                CollapsibleIndentTreeBuilder.setNodeCollapsed(d, !d.collapsed)
                // d.collapsed = !d.collapsed;
                // d.children = d.children ? null : d._children;

                this.redraw();
            }
        }

        // // identifyDescendants
        // this.data.descendants().forEach((d, i) => {
        //     if (d.id === undefined) {
        //         (d as any).id = String(i++);
        //     }
        //     this.initDescendantDefaultCollapseState(d);
        // })

        // // DEBUG: show draw area
        // root.append('rect')
        //     .attr('x', 0)
        //     .attr('y', 0)
        //     .attr('width', this.options.width)
        //     .attr('height', this.options.height);
    }

    update(u?: UpdateData) {
        if (u) {
            const hideUncat = Boolean(u.hiddenUncat);
            if (u.filter && u.filter >= CDT_FILTERS.length) {
                console.warn(`Cannot apply filter with index ${u.filter}`)
                applyFilter(this.data as any, hideUncat, undefined)
            } else {
                const hideFilter = u.filter ? CDT_FILTERS[u.filter] : undefined;
                applyFilter(this.data as any, hideUncat, hideFilter);
            }
        }
        this.redraw();
    }

    setRootYOffset(yOffset: number) {
        this.rootYOffset = yOffset;
        this.root
            .attr("transform", "translate("
                + this.options.margin.left + ","
                + (this.options.margin.top + this.rootYOffset) + ")")
    }

    redraw() {
        /**
         * Nodes are the rows in the tree
         */
        const nodeData = this.getNodes();
        const openNodeData = nodeData.filter(d => d.canOpen);

        /**
         * Links are the connections between the nodes
         */
        const linkData = this.data.links()
            .filter(d => !(d.source as ExtendedTree<V>).filtered && !(d.target as ExtendedTree<V>).filtered)
            .filter(d => !this.hiddenRoot || d.source.depth > 0)
        const S = this.options.leftToRight ? 1 : -1;

        const yAxis: (d: any) => number = this.yAxis !== undefined
            ? (d: any) => this.yAxis?.(String(d.data.id)) || 0
            : (d: any) => (d.index + (this.hiddenRoot ? +0.5 : 1.5)) * this.nodeSize;

        /**
         * left to right:
         *   x0             x1
         * |-*  bla bla     |
         *
         * right to left:
         * x0            x1
         * |     bla bla  *-|
         */
        let x0Axis: (d: ExtendedTree<V>) => number;
        let x1Axis: (d: ExtendedTree<V>) => number;
        const xAxisOffset = (this.hiddenRoot ? -this.nodeSize : 0) * S;
        if (this.options.leftToRight) {
            x0Axis = (d: ExtendedTree<V>) => d.depth * this.nodeSize + xAxisOffset;
            x1Axis = () => this.options.width;
        } else {
            x0Axis = () => 0;
            x1Axis = (d: ExtendedTree<V>) => this.options.width - (d.depth * this.nodeSize) + xAxisOffset;
        }
        /**
         * The axis where the dot should end up
         */
        const xAxis = this.options.leftToRight ? x0Axis : x1Axis

        const transition = this.root.transition()
            .duration(this.duration)

        // Update the nodes…
        const node = this.nodeGroup.selectAll<SVGGElement, ExtendedTree<V>>('g').data(nodeData, d => d.id);

        // Enter any new nodes at the parent's previous position.
        const nodeEnter = node.enter().append('g')
            .attr('transform', (d: any) => `translate(0,${yAxis(d)})`)
            .attr('fill-opacity', 0)
            .attr('stroke-opacity', 0)
            .attr('cursor', (d => !this.disabledClick && d.canOpen ? 'pointer' : null))
            .attr('pointer-events', 'all')
            .on('click', (event, d) => this.onClick(d))
        // .on('mouseover', function (d) {
        //     // when the bar is mouse-overed, we highlight the row.
        //     d3.select(this).classed('hover', true).style('opacity', 1);
        // })
        // .on('mouseout', function (d) {
        //     d3.select(this).classed('hover', false).style('opacity', 0.2)
        // });

        nodeEnter.append('circle')
            .attr('cx', d => xAxis(d))

        //rect for mouse motion indication
        nodeEnter.append('rect')
            .attr('dy', '0.32em')
            .attr('x', d => x0Axis(d) + 6 * S)
            .attr('y', '-0.62em')
            .attr('width', d => x1Axis(d) - x0Axis(d))
            .attr('height', this.nodeSize)
            .attr('fill', 'none')

        nodeEnter.append('text')
            .attr('dy', '0.32em')
            .attr('x', d => xAxis(d) + 8 * S)
            .attr('text-anchor', this.options.leftToRight ? 'start' : 'end')
            .text(d => {
                const label = getHardcodedLabel(d.data.label)
                const l = String(label)
                if (l.length > MAX_LABEL_SIZE) {
                    return l.substring(0, MAX_LABEL_SIZE) + ' (...)'
                }
                return l;
            })

        nodeEnter.append('title')
            .text(d => {
                const label = getHardcodedLabel(d.data.label)
                const l = String(label)
                if (l.length > MAX_LABEL_SIZE) {
                    return d.ancestors().reverse().slice(1).map(d => getHardcodedLabel(d.data.label)).join('\n')
                }
                return ''
            });

        this.updateColumns(nodeEnter)

        // Transition nodes to their new position.
        // nodeUpdate
        const nodeUpdate = node.merge((nodeEnter as any))
            .classed('highlighted', d => d.data.highlighted)
            .classed('selected', d => d.data.selected)
            .classed('child-selected', d => d.data.childSelected)
            .transition((transition as any))
            .attr('transform', d => `translate(0,${yAxis(d)})`)
            .attr('cursor', d => d.canOpen && !this.disabledClick ? 'pointer' : null)
            .attr('pointer-events', 'all')
            .attr('fill-opacity', 1)
            .attr('stroke-opacity', 1)

        nodeUpdate.select('circle')
            .attr('r', d => d.canOpen ? 0 : 2.5)
            .attr('fill', d => d.canOpen ? null : '#999')

        // Transition exiting nodes to the parent's new position.
        // nodeExit
        node.exit()
            .transition((transition as any))
            .remove()
            .attr('transform', d => `translate(0,${yAxis(d)})`)
            .attr('fill-opacity', 0)
            .attr('stroke-opacity', 0);

        // Update the links…
        const link = this.linkGroup.selectAll('path')
            .data(linkData, d => (d as any).target.id);

        // Enter any new links at the parent's previous position.
        const xOffset = -2, yOffset = 3;

        const drawPath = this.options.leftToRight
            ? (link) => `M${xAxis(link.source)},${yAxis(link.source) + yOffset}
            V${yAxis(link.target)}
            h${this.nodeSize + xOffset}`
            : (link) => `M${xAxis(link.source)},${yAxis(link.source) + yOffset}
            V${yAxis(link.target)}
            h${(this.nodeSize + xOffset) * S}`

        const linkEnter = link.enter().append('path')//.transition(transition)
            .attr('d', link => drawPath(link));

        // Transition links to their new position.
        link.merge((linkEnter as any))//.transition(transition)
            .attr('d', link => drawPath(link));

        // Transition exiting nodes to the parent's new position.
        link.exit().remove()
            .attr('d', link => drawPath(link));

        //triangle icons for expandable/collapsible objects
        const icon = this.iconGroup.selectAll<SVGPathElement, ExtendedTree<V>>('path').data(openNodeData, d => d.id);

        const iconPathData = d => d.canOpen ? (d.collapsed ? (this.options.leftToRight ? ARROW_RIGHT : ARROW_LEFT) : 'M4 7L0 1h8z') : ''
        const iconEnter = icon.enter().append('path')
            // .attr('transform', d => `translate(${xAxis(d) - 4},${yAxis(d) - 3})`)
            // .attr('fill', d => (d as any).collapsed ? '#999' : null)
            .attr('d', iconPathData);

        // Transition links to their new position.
        icon.merge(iconEnter)
            .attr('transform', d => `translate(${xAxis(d) - 4},${yAxis(d) - 4})`)
            .attr('fill', d => (d as any).collapsed ? '#999' : null)
            .attr('cursor', () => !this.disabledClick ? 'pointer' : '')
            .attr('pointer-events', 'all')
            .on('click', (event, d) => this.onClick(d))
            .attr('d', iconPathData);

        // Transition exiting nodes to the parent's new position.
        icon.exit().remove();

        if (this.onRedraw) {
            this.onRedraw();
        }
    }

    private updateColumns(nodeEnter) {
        const S = this.options.leftToRight ? 1 : -1;

        // Build the headers (once)
        this.headersContainer
            .selectAll<SVGTextElement, any>('text')
            .data(this.options.columns, d => d.columnTitle)
            .join(enter => enter
                .append('text')
                .attr('dy', '0.32em')
                .attr('y', 0.5 * this.nodeSize)  // Maybe this needs to change when using yAxis
                .attr('x', c => c.xOffset * S)
                .attr('text-anchor', this.options.leftToRight ? 'end' : 'start')
                .attr('font-weight', 'bold')
                .text(c => c.columnTitle)
            )

        // Build the values in the rows
        // Assert the node's values are never updated
        this.options.columns.forEach(({getValue, xOffset}) => {
            // Each node gets an extra text field
            nodeEnter.append('text')
                .attr('dy', '0.32em')
                .attr('x', xOffset * S)
                .attr('text-anchor', this.options.leftToRight ? 'end' : 'start')
                .text(d => getValue(d, this as any))
        })
    }

    public getNodesOrdered() {
        const ordered: ExtendedTree<V>[] = [];
        this.data.eachBefore(node => {
            if (!this.hiddenRoot || (node.depth > 0)) {
                ordered.push(node);
            }
        })
        // console.log(`getNodesOrdered() returns: ${ordered.length}/${this.data.descendants().length}`)
        return ordered;
    }

    public getNodes() {
        let nodes = this.data.descendants()
            .filter(d => !d.filtered)

        // Recalculate the correct y positioning
        // Note: Only used when yAxis is not used, but the nodes are distributed equally
        let i = 0;
        this.data.eachBefore(n => {
            if (n.filtered) {
                return;
            }
            return n['index'] = i++;
        })

        if (this.hiddenRoot) {
            nodes = nodes.filter(d => d.depth > 0)
        }

        return nodes;
    }

    private initNodes(data: Tree<V>) {
        data.descendants().forEach((d: Tree<V>, i) => {
            const ed = d as unknown as ExtendedTree<V>;

            // Each node should have a unique ID
            if (ed.id === undefined) {
                ed.id = String(i);
            }

            ed.filtered = false;

            if (ed.children) {
                ed.collapsed = false;
                ed.canOpen = true;
                ed._children = ed.children;
            } else {
                ed.collapsed = false;
                ed.canOpen = false;
            }
        })
        return data as ExtendedTree<V>
    }

    openOnlyNode(filter: (d: ExtendedTree<V>) => boolean) {
        // First open all
        this.openAll();
        // Then collect all the relevant nodes
        const openNodes = new Set<number>();
        this.data.descendants().forEach(d => {
            if (filter(d)) {
                d.ancestors().forEach(p => openNodes.add(p.data.id))
            }
        })
        // and open the relevant nodes
        this.closeAll()
        this.openSelectionOfNodes(openNodes);
    }

    openToLevel(filterLevel: number) {
        const targetDepth = filterLevel + 1;
        this.data.descendants()
            .forEach((d: any) => {
                if (d.depth === targetDepth && d.collapsed === false) {
                    // Collapse this node
                    CollapsibleIndentTreeBuilder.setNodeCollapsed(d, true)
                } else if (d.depth < targetDepth && d.collapsed === true) {
                    // Open this node
                    CollapsibleIndentTreeBuilder.setNodeCollapsed(d, false)
                }
            })

        // TODO[integration] Does this re-draw need to happen?
        this.redraw()
    }

    private openSelectionOfNodes(nodeIds: Set<number>, node?: ExtendedTree<V>) {
        if (!node) {
            node = this.data;
        }

        const mustBeOpen = nodeIds.has(node.data.id);
        if (mustBeOpen && node.collapsed) {
            // Open this node
            CollapsibleIndentTreeBuilder.setNodeCollapsed(node, false)
        } else if (!mustBeOpen && !node.collapsed) {
            // Collapse this node
            CollapsibleIndentTreeBuilder.setNodeCollapsed(node, true)
        }

        if (node._children)
            node._children.forEach(c => this.openSelectionOfNodes(nodeIds, c));
    }

    public beforeEach(func: (node: ExtendedTree<V>) => void, node?: ExtendedTree<V>) {
        if (!node) {
            node = this.data;
        }
        func(node);
        if (node._children)
            node._children.forEach(c => this.beforeEach(func, c));
    }

    public static getLeafs<D>(node: ExtendedTree<D>): ExtendedTree<D>[] {
        const nodes = [];
        CollapsibleIndentTreeBuilder.getLeafsImpl(node, nodes);
        return nodes;
    }

    private static getLeafsImpl<D>(node: ExtendedTree<D>, nodes: ExtendedTree<D>[]) {
        if (!node._children || node._children.length === 0) {
            nodes.push(node);
            return;
        }
        node._children.forEach(c => CollapsibleIndentTreeBuilder.getLeafsImpl(c, nodes));
    }

    filterByLabel(label: string) {
        const openNodes = new Set<number>();
        this.openAll();

        this.data.descendants().forEach(d => {
            CollapsibleIndentTreeBuilder.setNodeCollapsed(d, false);
        })
        label = label.toLowerCase();
        const nodes = this.data.descendants()
        const total = nodes.length;
        nodes.forEach(d => {
            const dLabel = d.data.label?.toLowerCase() || '';
            if (dLabel.includes(label)) {
                // Open this node and all it's parents
                d.ancestors().forEach(p => openNodes.add(p.data.id));
            }
        })
        console.log(`filterByLabel on ${openNodes.size} / ${total}`) // TODO: remove console log
        this.data.descendants().forEach(d => {
            const mustBeOpen = openNodes.has(d.data.id);
            if (mustBeOpen && d.collapsed) {
                // Open this node
                CollapsibleIndentTreeBuilder.setNodeCollapsed(d, false)
            } else if (!mustBeOpen && !d.collapsed) {
                // Collapse this node
                CollapsibleIndentTreeBuilder.setNodeCollapsed(d, true)
            }
        })
    }

    static setNodeCollapsed(node, collapsed: boolean) {
        node.collapsed = collapsed;
        if (collapsed) {
            node.children = null;
        } else {
            node.children = node._children
        }
    }

    openAll(node?: ExtendedTree<V>) {
        if (!node) {
            node = this.data;
        }
        CollapsibleIndentTreeBuilder.setNodeCollapsed(node, false);
        if (node._children)
            node._children.forEach(c => this.openAll(c));
    }

    private closeAll(node?: ExtendedTree<V>) {
        if (!node) {
            node = this.data;
        }
        CollapsibleIndentTreeBuilder.setNodeCollapsed(node, true);
        if (node._children)
            node._children.forEach(c => this.closeAll(c));
    }

    setYAxis(yAxis: ScaleBand<string>) {
        this.yAxis = yAxis;
    }

    stopFiltering() {
        this.filterSubscription.unsubscribe();
    }

    registerOnRedraw(func: () => void) {
        this.onRedraw = func;
    }
}

/**
 * Applies the filter, going down the tree
 */
function applyFilter<V>(node: ExtendedTree<V>, hideUncat: boolean, hideFilter: undefined | ((d: ExtendedTree<V>) => boolean), depth = 0): boolean {
    let hideThis = false;
    let canOpen = false;
    if (hideUncat) {
        hideThis = hideThis || (node.data.label === UNCATEGORIZED_LABEL);
    }
    if (hideFilter && !hideThis && depth > 0) {
        hideThis = hideThis || !hideFilter(node)
    }

    if (hideThis) {
        setHidden(node, true, depth + 1)
    } else {
        node.filtered = false;
        if (node._children) {
            let hasUnhiddenChildren = false;
            node._children.forEach(c => {
                if (!applyFilter(c, hideUncat, hideFilter, depth + 1)) {
                    hasUnhiddenChildren = true;
                }
            })
            if (hasUnhiddenChildren) {
                canOpen = true;
            }
        }
    }
    node.canOpen = canOpen;
    return hideThis;
}

function setHidden(d: ExtendedTree<any>, hidden: boolean, depth: number) {
    d.filtered = hidden;
    if (d.children) {
        d.children.forEach(c => setHidden(c, hidden, depth + 1));
    }
}
