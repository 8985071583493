import './DataTable.scss';
import {
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow
} from "@mui/material";
import React from "react";

export type MithraTableHeader = {
    headerText: string;
    className?: string;
    help?: string;
    rtl?: boolean;
}

type Props = {
    headers: MithraTableHeader[],
    footer?: JSX.Element
    className?: string
    loading?: boolean
    container?: boolean
}
export const MithraTable: React.FC<Props> = ({headers, children, footer, className, loading, container}) => {
    const containerComponent = container ? Paper : 'div';
    return <TableContainer component={containerComponent} className={
        'mithra-table'
        + (className ? ` ${className}` : ' x-design')
        + (loading ? ' loading' : '')
    }>
        <Table>
            <TableHead>
                <TableRow>
                    {headers.map((col, index) => {
                        const align = col.rtl ? 'right' : 'left';
                        let colClassName = ''
                            + (col.className ? ` ${col.className}` : '')
                            + (col.help ? ' col-help' : '')
                        return <TableCell
                            key={index}
                            className={colClassName}
                            align={align}
                            title={col.help} // Tooltip
                        >
                            {col.headerText}
                        </TableCell>
                    })}
                </TableRow>
                {loading && <div className={'progress' + (loading ? ' loading' : '')}>
                    <LinearProgress variant="indeterminate"/>
                </div>}
            </TableHead>
            <TableBody>
                {children}
            </TableBody>
            {footer &&
                <TableFooter>{footer}</TableFooter>
            }
        </Table>
    </TableContainer>
}
