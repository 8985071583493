import React from "react";
import {useStores} from "../../../stores";
import {Grid, Typography} from "@mui/material";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {DataColumn, DataTable} from "../../../components/data-table/DataTable";
import './KoiCashFlowOptimization.scss';
import CashFlowOptimizationHelp from "./KOICashFlowOptimizationHelp";
import {OpportunityStatusEnum} from "../../../services/classes/OpportunityClasses";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from "../../../routing/routes";
import {generateHomePath} from "../../../routing/routing.utils";

const cashFlowTableColumns: DataColumn[] = [
    {
        dataIndex: 's__id',
        headerText: 'Supplier name',
        ltr: true,
    },
    {
        dataIndex: 's__annual_spend',
        headerText: 'Annual spend',
        dataCategory: 'currency',
    },
    {
        dataIndex: 'p__payment_term__avg',
        headerText: 'Current Average Payment Term',
        dataCategory: 'decimal-1',
    },
    {
        dataIndex: 's__payment_term_avg',
        headerText: 'Benchmark payment term',
    },
    {
        dataIndex: 'p__payment_term_diff__avg',
        headerText: 'Days Extended',
    },
    {
        dataIndex: 'p__payment_term_opp__sum',
        headerText: 'Cashflow opportunity',
        dataCategory: 'currency|str',
    },
    {
        dataIndex: '_is_opportunity',
        headerText: 'Is opportunity',
        dataCategory: 'checkbox',
    },
]

type KeyValues = {
    totalSuppliers: number,
    nSelectedSuppliers: number,
    averageTermsExtension: number,
    totalSpend: number,
    cashFlowOpportunity: number,
};


export const KOICashFlowOptimization: React.FC = () => {
    const {rootAppStore, bagStore} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const bagId = bagStore.bagId;

    const [loading, setLoading] = React.useState(false);
    const [keyValues, setKeyValues] = React.useState<KeyValues | undefined>();
    const [cashFlowTable, setCashFlowTable] = React.useState<any[] | undefined>();

    // useEffect(() => {
    //     const sub = forkJoin([
    //         // from(jobStore.getJobStats()),
    //         from(jobStore.getCashFlowOpportunity()),
    //         from(jobStore.listCashFlowOpportunities())
    //     ])
    //         .pipe(map(([cf, os]) => {
    //             const opportunitySupplierIds = new Set(os.data.map(o => o.supplier_id));
    //             return [
    //                 cf.data.suppliers.map(sData => {
    //                     if (sData['s__payment_term_avg'] === 0) {
    //                         sData['s__payment_term_avg'] = '-'
    //                         sData['p__payment_term_diff__avg'] = '-'
    //                         sData['p__payment_term_opp__sum'] = '-'
    //                     }
    //                     if (sData['p__payment_term_opp__sum'] > 0) {
    //                         sData['_is_opportunity'] = opportunitySupplierIds.has(sData['s__id'])
    //                     } else {
    //                         sData['_is_opportunity'] = undefined;
    //                     }
    //                     return sData;
    //                 }),
    //
    //                 // TODO: Replace stats and keyValues with bagStore.kpi.common
    //                 undefined,
    //                 // {
    //                 //     totalSuppliers: cf.data.suppliers.length,
    //                 //     nSelectedSuppliers: cf.data.summary.n_hit_suppliers,
    //                 //     averageTermsExtension: cf.data.summary.total_avg_extended_days_supplier,
    //                 //     totalSpend: stats.data.total_spend,
    //                 //     cashFlowOpportunity: cf.data.summary.total_opportunity,
    //                 // }
    //
    //             ] as [any[], KeyValues | undefined];
    //         }))
    //         .subscribe(([cashFlowTableData, keyValues]) => {
    //             setCashFlowTable(cashFlowTableData);
    //             setKeyValues(keyValues)
    //         });
    //     return () => sub.unsubscribe();
    // }, [bagStore]);

    function updateTable(supplierId: string, isOpportunity: boolean) {
        if (!cashFlowTable) return false;
        const newCashFlowTable = [...cashFlowTable];
        const affectedI = newCashFlowTable.findIndex(r => r.s__id === supplierId);
        if (affectedI !== -1) {
            const newData = {...newCashFlowTable[affectedI]};
            newData._is_opportunity = isOpportunity;
            newCashFlowTable[affectedI] = newData;
            setCashFlowTable(newCashFlowTable);
            return true;
        }
        return false;
    }

    const handleDestroyOpportunity = (d: any) => {
        // setLoading(true);
        // const removeSupplier = d.s__id
        // jobStore.listCashFlowOpportunities()
        //     .then(resp => {
        //         const opportunity = resp.data.find(o => o.supplier_id === removeSupplier);
        //         if (opportunity) {
        //             return jobStore.destroyOpportunity(opportunity.id)
        //                 .then(() => {
        //                     if (!updateTable(removeSupplier, false))
        //                         console.warn('Could not remove opportunity for', removeSupplier);
        //                 });
        //         }
        //     })
        //     .finally(() => setLoading(false))
    }

    const handleCreateOpportunity = (d: any) => {
        setLoading(true);
        const data = {
            current_status: OpportunityStatusEnum.Identified,
            total_opportunity: d.p__payment_term_opp__sum,

            supplier_id: d.s__id, // Should this not be "supplier" instead of "supplier_id"?
            supplier_annual_spend: d.s__annual_spend,
            part_payment_term_diff_avg: d.p__payment_term_diff__avg,
        };
        // jobStore.createCashFlowOpportunity(data)
        //     .then(r => {
        //         if (r.status === 201 && cashFlowTable) {
        //             const newOpportunity = r.data;
        //             const isOpportunity = true;
        //             const supplierId = newOpportunity.supplier_id;
        //             if (!updateTable(supplierId, isOpportunity))
        //                 console.warn('Could not update', newOpportunity);
        //         }
        //     })
        //     .finally(() => setLoading(false))
    }

    return <KoiFacade
        title="Cash Flow Optimization"
        helpContent={<CashFlowOptimizationHelp/>}
        intro={<></>}
        back={generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)}
        className="koi-cfo-page"
    >
        <div className="page-item">

            <Grid container justifyContent="space-evenly">
                {/*<Grid item xs={2}/>*/}
                <Grid item xs={8}
                      container justifyContent="space-around" style={{alignContent: 'space-around'}}
                      alignItems="center">

                    <Grid className="key-value-container text-center font-bigger"
                          item xs={11}
                          container spacing={4}>
                        {/* Row 1 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-between"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    Total<br/>
                                    Suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.totalSuppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Number of <br/>
                                    selected suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.nSelectedSuppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Average terms<br/>
                                    extension
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${Math.round(keyValues.averageTermsExtension * 10) / 10}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* Row 2 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-evenly"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    &nbsp;<br/>
                                    Total spend
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? <CurrencyComponent v={keyValues.totalSpend} nDigits={3}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Cash flow<br/>
                                    opportunity
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? <CurrencyComponent v={keyValues.cashFlowOpportunity} nDigits={3}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>

        <div className="page-item">
            {/*<Grid container justifyContent="flex-end">*/}
            {/*    <Grid item xs={2} style={{textAlign: 'center', marginBottom: theme.spacing(2)}}>*/}
            {/*        <Button*/}
            {/*            style={{width: '13em'}}*/}
            {/*            variant="outlined"*/}
            {/*            color="primary"*/}
            {/*            // onClick={() => startKOI2Download()}*/}
            {/*            disabled={downloading}>*/}
            {/*            {downloading ? 'Downloading...' : 'Download all'}*/}
            {/*        </Button>*/}
            {/*    </Grid>*/}
            {/*    /!*<Grid item xs={2}/>*!/*/}
            {/*</Grid>*/}
            <Grid container justifyContent="space-evenly">
                <Grid item xs={12} style={{minHeight: '10em', maxWidth: '75em'}}>
                    <DataTable
                        className="cash-flow-table alt-rows"
                        tableColumns={cashFlowTableColumns}
                        data={cashFlowTable}
                        emptyCaption="Loading"
                        pageSize={12}
                        checkboxDisabled={loading}
                        onClickCheckbox={(d, checked) => {
                            if (!checked) {
                                handleCreateOpportunity(d);
                            } else {
                                handleDestroyOpportunity(d);
                            }
                        }}
                    />
                </Grid>
                {/*<Grid item xs={2}/>*/}
            </Grid>
        </div>
    </KoiFacade>
};
