import {
    ApprovalRequest,
    ApprovalStatusEnum,
    ApprovalTypeEnum,
    CategorizationApprovalRequest,
    TaxonomyApprovalRequest
} from "../services/classes/AiClasses";
import {BagStore} from "./BagStore";
import {match as Match} from "react-router";
import {History} from "history";
import {makeAutoObservable, runInAction, toJS} from "mobx";
import {ApprovalRouteMatch, MithraHistoryState, routes} from "../routing/routes";
import {forkJoin, from, mergeMap, Observable, tap} from "rxjs";
import {AxiosResponse} from "axios";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {PageResponseManager} from "./managers/PageResponseManager";
import {MatPartReviewRow,} from "../services/classes/MaterializedClasses";
import {CategorizationApprovalDelegate} from "./approval/CategorizationApprovalDelegate";
import {GenericRequestManager} from "./managers/RequestManager";
import ProfileStore from "./ProfileStore";
import AuthStore from "./AuthStore";

type AnyApprovalRequest = TaxonomyApprovalRequest | CategorizationApprovalRequest;

export type CategorizationApprovalPartPageManager = PageResponseManager<{ approvalId: number }, MatPartReviewRow>;

/**
 * Generic domain store for approvals
 */
export class ApprovalStore {
    public readonly cat = new CategorizationApprovalDelegate(this, this.auth, this.bagStore, this.profile, this.api);
    readonly approvalLoader = new GenericRequestManager<unknown>();

    allRequestsLoading = false;
    allRequests?: ApprovalRequest[]

    approval?: AnyApprovalRequest;

    isAcceptApprovalDialogOpen = false;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private auth: AuthStore,
        private bagStore: BagStore,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    fetchAll() {
        this.allRequestsLoading = true;
        return this.api.listApprovalRequests().then(r => {
            runInAction(() => {
                this.allRequests = r.data;
                this.allRequestsLoading = false;
            });
            return r;
        })
    }

    get pendingRequests(): number | undefined {
        if (this.allRequests === undefined) return undefined
        return this.allRequests.filter(r => ApprovalStatusEnum.userCanUpdate(r.current_status.status)).length
    }

    // noinspection JSUnusedLocalSymbols
    onLoadApprovalPage(type: ApprovalTypeEnum, match: Match<ApprovalRouteMatch>, history: History<MithraHistoryState>) {
        // Attempt to interpret approval from URL
        if (match.params.approvalId === undefined) {
            console.warn('Cannot load approval URL mismatch')
            return;
        }
        const approvalId = Number(match.params.approvalId);
        if (!Number.isInteger(approvalId)) {
            console.warn('Cannot load approval URL mismatch', approvalId)
            return;
        }
        if (this.approval && this.approval.id !== approvalId) {
            this.setApproval(undefined)
        }

        this.loadApproval(approvalId, type);
    }

    loadApproval(approvalId: number, type: ApprovalTypeEnum) {
        switch (type) {
            case ApprovalTypeEnum.TAXONOMY:
                // TODO: Make to a TaxonomyApproval delegate
                this.approvalLoader.request(
                    from(this.api.getTaxonomyApprovalRequest(approvalId)).pipe(
                        tap(r => this.setApproval(r.data))
                    )
                )
                return;
            case ApprovalTypeEnum.CATEGORIZATION:
                this.approvalLoader.request(this.cat.makeRetrieveApprovalRequest(approvalId))
                return;
        }
        throw new Error('Unknown approval type: ' + type);
    }

    setApproval(approval: AnyApprovalRequest | undefined) {
        this.approval = approval;
        if (approval) {
            const requestType: ApprovalTypeEnum = approval.request_type;
            switch (requestType) {
                case ApprovalTypeEnum.TAXONOMY:
                    // TODO-Architecture: apply correct delegation pattern for taxonomy approval
                    break;
                case ApprovalTypeEnum.CATEGORIZATION:
                    if (!approval.baseline_bag) {
                        throw new Error('Cannot load approval without a baseline bag: ' + JSON.stringify(toJS(approval)))
                    }
                    this.cat.data.reInitializeForApprovalV3(approval.id, approval.baseline_bag.id, approval.baseline_bag.taxonomy_size);
                    break;
                default:
                    throw new Error(`Unknown approval request type: ${requestType}`)
            }
        }
    }

    get taxonomyApproval(): TaxonomyApprovalRequest | undefined {
        if (this.approval && this.approval.request_type === ApprovalTypeEnum.TAXONOMY) {
            return this.approval
        }
        return undefined;
    }

    get categorizationApproval(): CategorizationApprovalRequest | undefined {
        if (this.approval && this.approval.request_type === ApprovalTypeEnum.CATEGORIZATION) {
            return this.approval
        }
        return undefined;
    }

    public static getBaseRoute(t: ApprovalTypeEnum): string {
        switch (t) {
            case ApprovalTypeEnum.TAXONOMY:
                return routes.approval_tax_detail
            case ApprovalTypeEnum.CATEGORIZATION:
                return routes.approval_cat_detail
        }
        throw new Error('Cannot create link for approval ' + t)
    }

    get request_type(): ApprovalTypeEnum | undefined {
        return this.approval?.request_type;
    }

    public static showRequestType(t: ApprovalTypeEnum): string {
        switch (t) {
            case ApprovalTypeEnum.TAXONOMY:
                return 'Taxonomy'
            case ApprovalTypeEnum.CATEGORIZATION:
                return 'Categorization'
            default:
                console.error('Not implemented')
                return String(t)
        }
    }

    applyApproval(history: History<MithraHistoryState>) {
        // TODO-Architecture: apply correct delegation pattern
        if (!this.approval) return

        // Note: The approval request is done in the same queue as the loading of approvals
        const approvalId = this.approval.id;
        const requestType: ApprovalTypeEnum = this.approval.request_type;

        if (requestType === ApprovalTypeEnum.CATEGORIZATION) {
            this.cat.applyApproval(history);
            return;
        }

        let oUpdate, oRetriev: Observable<AxiosResponse<AnyApprovalRequest>>;
        switch (requestType) {
            case ApprovalTypeEnum.TAXONOMY:
                oUpdate = from(this.api.applyTaxonomyApproval(approvalId, ApprovalStatusEnum.APPROVED, this.approval.feedback_notes))
                oRetriev = from(this.api.getTaxonomyApprovalRequest(approvalId))
                break;
            default:
                throw new Error();
        }

        const o = oUpdate.pipe(
            mergeMap(() => forkJoin([
                this.fetchAll(),
                oRetriev.pipe(
                    tap(r => this.setApproval(r.data))
                ),
            ])),
            tap(([_, r]) => {
                this.isAcceptApprovalDialogOpen = false;
                if (!ApprovalStatusEnum.userCanUpdate(r.data.current_status.status)) {
                    // If we cannot change the approval anymore, go back to the overview page
                    history.push(routes.approval);
                }
            }),
        )
        this.approvalLoader.request(o)
    }

    clickCancelApprovalDialog() {
        if (!this.approvalLoader.busy) {
            this.isAcceptApprovalDialogOpen = false;
            this.approvalLoader.cleanup()
            if (this.approval) {
                this.api.storeApprovalNotes(this.approval.id, this.approval.feedback_notes).then()
            }
        }
    }

    clickAwayApprovalDialog() {
        this.clickCancelApprovalDialog()
    }

    openSendApprovalDialog() {
        this.isAcceptApprovalDialogOpen = true;
        switch (this.request_type) {
            case ApprovalTypeEnum.CATEGORIZATION:
                this.cat.loadStats()
                break
        }
    }

    static getApprovalStatusName(status?: ApprovalStatusEnum): string {
        if (!status) return '';
        switch (status) {
            case ApprovalStatusEnum.PENDING:
                return '';
            case ApprovalStatusEnum.APPROVED:
                return 'Approved';
            case ApprovalStatusEnum.REJECTED:
                return 'Rejected';
            case ApprovalStatusEnum.BUSY:
                return 'Loading';
            case ApprovalStatusEnum.HALTED:
                return 'Waiting for Mithra';
            case ApprovalStatusEnum.ERROR:
                return 'Error';
        }
    }
}
