import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {Button, CircularProgress, Grid} from "@mui/material";
import {CloudDownload, Done} from "@mui/icons-material";
import {CategorizationReviewKeyValues} from "../review/CategorizationReviewKeyValues";

const filename = 'CategorizationReviewResult.xlsx';

export const CategorizationReviewResult: React.FC = observer(() => {
    const {materializedApi, categorizationStore} = useStores();

    const [isDownloading, setIsDownloading] = useState(false)
    const [hasDownloaded, setHasDownloaded] = useState(false)
    const bagId = categorizationStore.data.requestedBagId;

    const download = () => {
        if (!bagId) return;
        setIsDownloading(true)
        materializedApi.downloadReviewExcel(bagId, filename).then(resp => {
            const url = URL.createObjectURL(new Blob([resp.data]));
            const a = document.createElement('a');
            a.href = url;
            a.setAttribute('download', filename);
            document.body.appendChild(a);

            a.click();
            setIsDownloading(false)
            setHasDownloaded(true)

            a.parentNode?.removeChild(a);
            URL.revokeObjectURL(url);
        });
    };

    return <div className="categorization-review-result">

        <CategorizationReviewKeyValues/>

        <Grid container alignItems="center" direction="column">
            <Grid item className="download-btn">
                <Button
                    id="download-results-btn"
                    color="primary"
                    variant="outlined"
                    startIcon={isDownloading ? <CircularProgress size={14}/> : (hasDownloaded ? <Done/> :
                        <CloudDownload/>)}
                    endIcon={isDownloading ? undefined : (hasDownloaded ? undefined : undefined)}
                    disabled={isDownloading || !bagId}
                    style={{minWidth: '10em'}}
                    onClick={download}
                >
                    {isDownloading ? 'Downloading' : 'Download'}
                </Button>
            </Grid>
        </Grid>
    </div>
});
