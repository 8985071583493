/*@formatter:off*/
import {CommonSupplierData, SupplierComparePartData} from "./hardcoded_dpw_types";
// From https://mithraaisolutions.sharepoint.com/:x:/s/CAT-Product/EQrZQ5YgJ5ZJtxMr3_r0c5wBH9ZGf7z4TKm4q83OwALCbQ?e=PlmhDZ
type S = Omit<CommonSupplierData, 'parts_master_ds' | 'parts_cmp_ds'> & {
    parts_master_ds: Partial<SupplierComparePartData>[]
    parts_cmp_ds: Partial<SupplierComparePartData>[]
};
export const HARDCODED_DPW_COMMON_SUPPLIER_TABLE_RAW: S[] = [
    {
        name: 'MICROMECANIQUE',
        type: 'Strategic',
        spend_master_ds: 694168.35,
        spend_cmp_ds: 21431,
        l1_categories: ['Metals', 'Prof. Services'],
        parts_master_ds: [
            {name: 'BLOCKER AVIATOR', spend: 55202.5188, categories: ['Metals', 'Products', 'Machined Implants']},
            {name: 'BLOCKER AVIATOR', spend: 55166.832, categories: ['Metals', 'Products', 'Machined Implants']},
            {name: 'BLOCKER AVIATOR', spend: 52500.6648, categories: ['Metals', 'Products', 'Machined Implants']},
            {name: 'BLOCKER AVIATOR', spend: 27315.63, categories: ['Metals', 'Products', 'Machined Implants']},
            {name: 'BLOCKER AVIATOR', spend: 26818.8096, categories: ['Metals', 'Products', 'Machined Implants']},
            {name: 'BLOCKER AVIATOR', spend: 26587.476, categories: ['Metals', 'Products', 'Machined Implants']},
            {name: 'BLOCKER AVIATOR', spend: 26541.6, categories: ['Metals', 'Products', 'Machined Implants']},
            {name: 'CENTER BOLT', spend: 23771.475, categories: ['Metals', 'Products', 'Machined Implants']},
            {name: 'CENTER BOLT', spend: 15242.2788, categories: ['Metals', 'Products', 'Machined Implants']},
        ],
        parts_cmp_ds: [
            {name: 'Ostéos., clou plaque Lisse', spend: 14.755, categories: ['Implant', 'Micro Nail']},
            {name: 'Cup. Xpode Ø18 mm, 9 trous SF', spend: 2.841, categories: ['Implant', 'Implant']},
            {name: 'Cup. Xpode Ø18 mm, 6 trous SF', spend: 1.358, categories: ['Implant', 'Implant']},
            {name: 'Cup. Xpode Ø22 mm, 6 trous SF', spend: 992, categories: ['Implant', 'Implant']},
            {name: 'Cup. Xpode Ø18 mm, 4 trous SF', spend: 805, categories: ['Implant', 'Implant']},
            {name: 'Guide broche pr broches Ø0,7mm', spend: 680, categories: ['Instrument', 'Instrument - Complex']},
        ],
    },
    {
        name: 'ACNIS International',
        type: 'Strategic',
        spend_master_ds: 1975988,
        spend_cmp_ds: 25883638,
        l1_categories: ['Metals'],
        parts_master_ds: [
            {name: 'TR0030AAAAI00', spend: 32976.01931, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: 'TR0008AAABI00', spend: 24391.5, categories: ['METALS','RAW MATERIALS','TITANIUM']},
            {name: 'TR03.5AAABI00', spend: 22348.8125, categories: ['METALS','RAW MATERIALS','TITANIUM']},
            {name: 'TR0025AAABI00', spend: 21524.454, categories: ['METALS','RAW MATERIALS','TITANIUM']},
            {name: 'TR0020AAABI00', spend: 19972.554, categories: ['METALS','RAW MATERIALS','TITANIUM']},
            {name: 'TAR0.6TZZBI00', spend: 18758.94268, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: 'TR05.5AAABI00', spend: 18314.1, categories: ['METALS','RAW MATERIALS','TITANIUM']},
            {name: 'TR0006QAAB00', spend: 16996.6986, categories: ['METALS','RAW MATERIALS','TITANIUM']},
            {name: 'TR0016AAABI00', spend: 16968.99525, categories: ['METALS','RAW MATERIALS','TITANIUM']},
            {name: 'TR0020AAABI00', spend: 16198.614, categories: ['METALS','RAW MATERIALS','TITANIUM']},
            {name: 'TR0014AAABI00', spend: 15610.56, categories: ['METALS','RAW MATERIALS','TITANIUM']},
            {name: 'TR05.5AAABI00', spend: 14790.759, categories: ['METALS','RAW MATERIALS','TITANIUM']},
            {name: 'TP0018AAABI00', spend: 13714.02, categories: ['METALS','RAW MATERIALS','TITANIUM']},
            {name: 'IR0013GAEBI00', spend: 3660.5973, categories: ['METALS','RAW MATERIALS','STEEL']},
        ],
        parts_cmp_ds: [
            {name: 'TA6VELIM040', spend: 71.001, categories: ['Raw Material', 'TA6V ELI bar']},
            {name: 'TA6VELIM050', spend: 17.550, categories: ['Raw Material', 'TA6V ELI bar']},
            {name: 'TA6VELIM080', spend: 9.191, categories: ['Raw Material', 'TA6V ELI bar']},
            {name: 'TA6VELIM100', spend: 7.617, categories: ['Raw Material', 'TA6V ELI bar']},
            {name: 'TA6VELIM060', spend: 6.893, categories: ['Raw Material', 'TA6V ELI bar']},
            {name: 'TELI8', spend: 113.815, categories: ['Raw Material', 'Titanium rod']},
            {name: 'TELI45', spend: 66.867, categories: ['Raw Material', 'Titanium rod']},
            {name: 'V26035M', spend: 27.262, categories: ['Raw Material', 'Titanium rod']},
            {name: 'V26045M', spend: 14.963, categories: ['Raw Material', 'Titanium rod']},
            {name: 'TELI10', spend: 13.887, categories: ['Raw Material', 'Titanium rod']},
            {name: 'V26040M', spend: 9.945, categories: ['Raw Material', 'Titanium rod,']},
        ],
    },
    {
        name: 'AESCULAP',
        type: 'Strategic',
        spend_master_ds: 1969262,
        spend_cmp_ds: 35561,
        l1_categories: ['METALS' , 'PROF. SERV'],
        parts_master_ds: [
            {name: '7102550030', spend: 101934, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '7102550030', spend: 90608, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '7102550030', spend: 90608, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '7102550030', spend: 84945, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '7102550030', spend: 79282, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '7102552020', spend: 79112, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '7102552020', spend: 73699, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '7102550030', spend: 73619, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '7102552020', spend: 67662, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '7102552020', spend: 63706, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '7102652020', spend: 57460, categories: ['METALS','PRODUCTS','CASES AND TRAYS,']},
        ],
        parts_cmp_ds: [
            {name: 'JK401', spend: 27.185, categories: ['Instrument', 'Case & Tray - Metal']},
            {name: 'JK400', spend: 6.412, categories: ['Instrument', 'Case & Tray - Metal']},
            {name: 'JK100', spend: 1.964, categories: ['Instrument', 'Case & Tray - Metal,']},
        ],
    },
    {
        name: 'ALLOSOURCE',
        type: 'Strategic',
        spend_master_ds: 19815953,
        spend_cmp_ds: 6448657,
        l1_categories: ['METALS' , 'PROF. SERV'],
        parts_master_ds: [
            {name: '3102-2008', spend: 329738, categories: ['FG-OEM/BIO','BIOLOGICS','ALLOGRAFTS']},
            {name: '3102-2008', spend: 247860, categories: ['FG-OEM/BIO','BIOLOGICS','ALLOGRAFTS']},
            {name: '18717000', spend: 238572, categories: ['FG-OEM/BIO','BIOLOGICS','ALLOGRAFTS']},
            {name: '18717000', spend: 224613, categories: ['FG-OEM/BIO','BIOLOGICS','ALLOGRAFTS']},
            {name: '3102-2006', spend: 198196, categories: ['FG-OEM/BIO','BIOLOGICS','ALLOGRAFTS']},
            {name: '3102-2008', spend: 193500, categories: ['FG-OEM/BIO','BIOLOGICS','ALLOGRAFTS']},
            {name: '41517000', spend: 191619, categories: ['FG-OEM/BIO','BIOLOGICS','ALLOGRAFTS']},
            {name: '41517000', spend: 178929, categories: ['FG-OEM/BIO','BIOLOGICS','ALLOGRAFTS']},
            {name: '3102-2008', spend: 166388, categories: ['FG-OEM/BIO','BIOLOGICS','ALLOGRAFTS,']},
        ],
        parts_cmp_ds: [
            {name: '86304X08', spend: 740.960, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '130411', spend: 714.701, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86204X08', spend: 705.760, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86104X08', spend: 692.560, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '130411', spend: 522.327, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '127976', spend: 374.570, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86660800', spend: 259.475, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86661200', spend: 227.950, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86104X04', spend: 226.160, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86660006', spend: 223.155, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '130413', spend: 220.095, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86204X04', spend: 207.680, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86661000', spend: 207.095, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86304X04', spend: 166.760, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86102X04', spend: 154.440, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86082050', spend: 140.220, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '128680', spend: 124.726, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86202X04', spend: 107.800, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '130412', spend: 95.774, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86202X12', spend: 85.140, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '127976', spend: 84.930, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '130413', spend: 70.922, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '130412', spend: 39.973, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '86082020', spend: 36.225, categories: ['Biologics/Sports Med/Cement', 'OEM']},
            {name: '128680', spend: 19.259, categories: ['Biologics/Sports Med/Cement', 'OEM,']},
        ],
    },
    {
        name: 'AVALIGN',
        type: 'Strategic',
        spend_master_ds: 1975988,
        spend_cmp_ds: 25883638,
        l1_categories: ['METALS'],
        parts_master_ds: [
            {name: '48328550', spend: 65197.15, categories: ['METALS','PRODUCTS','HANDLED INSTRUMENTATION']},
            {name: '1440-0001300', spend: 49083, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '48328812', spend: 46026.24, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '48230012', spend: 34900, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '1440-0001300', spend: 31164, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '48230012', spend: 30320, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '6543-8-009300', spend: 29792, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '111550', spend: 29200, categories: ['METALS','COMPONENTS','PRECISION MACHINED COMPONENTS']},
            {name: '6543-8-103300', spend: 26989, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '6543-8-104300', spend: 26857, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '6543-8-003300', spend: 26259, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '1020-6000300', spend: 26245, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '1440-0001300', spend: 25866, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: 'MISSING', spend: 22539, categories: ['METALS','COMPONENTS','LASER PROCESSED PRODUCTS']},
            {name: '6543-8-004300', spend: 22442, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '6543-8-109300', spend: 21389, categories: ['METALS','PRODUCTS','CASES AND TRAYS']},
            {name: '6543-8-009300', spend: 20854, categories: ['METALS','PRODUCTS','CASES AND TRAYS,']},
        ],
        parts_cmp_ds: [
            {name: '33600030', spend: 589.866, categories: ['Instrument', 'Complex']},
            {name: '44132006', spend: 287.300, categories: ['Instrument', 'Case & Tray']},
            {name: '33620033', spend: 280.571, categories: ['Instrument', 'Simple']},
            {name: 'NBR10301', spend: 273.861, categories: ['Instrument', 'Case & Tray']},
            {name: 'MSD-070-40-COMP', spend: 219.011, categories: ['Instrument', 'Simple']},
            {name: '33620032', spend: 217.756, categories: ['Instrument', 'Simple']},
            {name: '33620034', spend: 215.998, categories: ['Instrument', 'Simple']},
            {name: '33680130', spend: 210.079, categories: ['Instrument', 'Handle']},
            {name: 'NBR10311', spend: 204.516, categories: ['Instrument', 'Case & Tray']},
            {name: 'MWH620', spend: 182.970, categories: ['Instrument', 'German']},
            {name: '33600530', spend: 180.295, categories: ['Instrument', 'Case & Tray']},
            {name: '33620035', spend: 179.853, categories: ['Instrument', 'Simple']},
            {name: 'YRAD10302', spend: 162.792, categories: ['Instrument', 'Case & Tray']},
            {name: '33600520', spend: 158.029, categories: ['Instrument', 'Case & Tray']},
            {name: '33600510', spend: 156.795, categories: ['Instrument', 'Case & Tray']},
            {name: 'YRAD261', spend: 144.800, categories: ['Instrument', 'Case & Tray']},
            {name: 'MWF107', spend: 141.445, categories: ['Instrument', 'Simple']},
            {name: 'YRAD10312', spend: 136.525, categories: ['Instrument', 'Case & Tray']},
            {name: 'NBR261', spend: 133.946, categories: ['Instrument', 'Case & Tray']},
            {name: 'YRAD264', spend: 129.970, categories: ['Instrument', 'Case & Tray']},
            {name: '33600200', spend: 125.341, categories: ['Instrument', 'Simple']},
            {name: '33680540', spend: 120.528, categories: ['Instrument', 'Case & Tray']},
            {name: 'NBR10321', spend: 106.680, categories: ['Instrument', 'Case & Tray']},
            {name: 'MWJ180', spend: 105.350, categories: ['Instrument', 'Case & Tray,']},
        ],
    },
    {
        name: 'BODYCOTE',
        type: 'Strategic',
        spend_master_ds: 235583,
        spend_cmp_ds: 286870,
        l1_categories: ['METALS' , 'PROF. SERV'],
        parts_master_ds: [
            {name: '5752', spend: 8014, categories: ['METALS','PROCESSES','OSP']},
            {name: '5752', spend: 7549, categories: ['METALS','PROCESSES','OSP']},
            {name: '5752', spend: 7487, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 6907, categories: ['METALS','PROCESSES','OSP']},
            {name: '5752', spend: 6723, categories: ['METALS','PROCESSES','OSP']},
            {name: '5752', spend: 6423, categories: ['METALS','PROCESSES','OSP']},
            {name: '5752', spend: 6139, categories: ['METALS','PROCESSES','OSP']},
            {name: '5752', spend: 5871, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.34.00', spend: 5302, categories: ['METALS','PROCESSES','OSP']},
            {name: 'MISSING', spend: 4860, categories: ['METALS','PROCESSES','OSP']},
            {name: '5751', spend: 4605, categories: ['METALS','PROCESSES','OSP']},
            {name: 'MISSING', spend: 3959, categories: ['METALS','PROCESSES','OSP']},
            {name: 'MISSING', spend: 3721, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 3465, categories: ['METALS','PROCESSES','OSP']},
            {name: '5752', spend: 3453, categories: ['METALS','PROCESSES','OSP']},
            {name: '5752', spend: 3196, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 3014, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 2983, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 2910, categories: ['METALS','PROCESSES','OSP']},
            {name: 'MISSING', spend: 2820, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 2759, categories: ['METALS','PROCESSES','OSP']},
            {name: '0940023000', spend: 2543, categories: ['METALS','PROCESSES','OSP']},
            {name: 'MISSING', spend: 2396, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 2339, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 2328, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 2328, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 2270, categories: ['METALS','PROCESSES','OSP']},
            {name: '5752', spend: 1923, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 1808, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 1764, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 1764, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 1755, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 1755, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.34.00', spend: 1755, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 1738, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 1700, categories: ['METALS','PROCESSES','OSP']},
            {name: '5752', spend: 1601, categories: ['METALS','PROCESSES','OSP']},
            {name: '67.36.01', spend: 1581, categories: ['METALS','PROCESSES','OSP,']},
        ],
        parts_cmp_ds: [
            {name: 'I-DWE860', spend: 55.507, categories: ['Heat Treatment', 'Sphere']},
            {name: 'I-13354/2TI', spend: 40.861, categories: ['Heat Treatment', 'Screw']},
            {name: 'I-DWJ1017207', spend: 13.132, categories: ['Heat Treatment', 'Head']},
            {name: 'I-13354/3TI', spend: 9.958, categories: ['Heat Treatment', 'Screw']},
            {name: 'I-DWE890', spend: 9.705, categories: ['Heat Treatment', 'Sphere']},
            {name: 'I-DWE880', spend: 9.425, categories: ['Heat Treatment', 'Sphere']},
            {name: 'I-X0748/', spend: 8.913, categories: ['Heat Treatment', 'Head']},
            {name: '154224*OP190', spend: 7.486, categories: ['Coating', 'Coating']},
            {name: 'I-X0745/', spend: 7.158, categories: ['Heat Treatment', 'Head']},
            {name: 'I-X0763/', spend: 6.414, categories: ['Heat Treatment', 'Head']},
            {name: 'I-X0766/', spend: 6.171, categories: ['Heat Treatment', 'Head']},
            {name: 'I-X0762/', spend: 5.983, categories: ['Heat Treatment', 'Head']},
            {name: 'I-X0760/', spend: 5.978, categories: ['Heat Treatment', 'Head']},
            {name: 'I-X0754/', spend: 5.840, categories: ['Heat Treatment', 'Head']},
            {name: 'I-X0759/', spend: 5.552, categories: ['Heat Treatment', 'Head']},
            {name: 'I-X0751/', spend: 5.382, categories: ['Heat Treatment', 'Head']},
            {name: 'I-X0765/', spend: 4.463, categories: ['Heat Treatment', 'Head']},
            {name: '154223*OP190', spend: 4.258, categories: ['Coating', 'Coating']},
            {name: 'I-X0757/', spend: 4.048, categories: ['Heat Treatment', 'Head']},
            {name: 'I-DWF243', spend: 3.966, categories: ['Heat Treatment', 'Cap']},
            {name: '154225*OP190', spend: 3.698, categories: ['Coating', 'Coating']},
            {name: '154222*OP190', spend: 2.957, categories: ['Coating', 'Coating']},
            {name: 'I-DWJ1017204', spend: 2.654, categories: ['Heat Treatment', 'Head']},
            {name: 'I-DWE891', spend: 2.600, categories: ['Heat Treatment', 'Head']},
            {name: 'I-DWJ1017601', spend: 2.565, categories: ['Heat Treatment', 'Head']},
            {name: '154210*OP190', spend: 2.420, categories: ['Coating', 'Coating']},
            {name: 'TD-U74054', spend: 2.176, categories: ['Coating', 'Talus ankle']},
            {name: 'I-DWJ1017201', spend: 2.123, categories: ['Heat Treatment', 'Head']},
            {name: 'I-DWF241', spend: 2.080, categories: ['Heat Treatment', 'Cap']},
            {name: 'I-DWF246', spend: 2.080, categories: ['Heat Treatment', 'Cap']},
            {name: '154207*OP190', spend: 1.980, categories: ['Coating', 'Coating']},
            {name: 'I-DWF343', spend: 1.973, categories: ['Heat Treatment', 'Cap']},
            {name: 'TD-U74052', spend: 1.957, categories: ['Coating', 'Talus ankle']},
            {name: '154209*OP190', spend: 1.819, categories: ['Coating', 'Coating']},
            {name: '154224*OP190', spend: 1.799, categories: ['Coating', 'Coating']},
            {name: '154202*OP190', spend: 1.759, categories: ['Coating', 'Coating']},
            {name: 'I-DWF253', spend: 1.733, categories: ['Heat Treatment', 'Head']},
            {name: 'TD-U74053', spend: 1.673, categories: ['Coating', 'Talus ankle']},
            {name: '154211*OP190', spend: 1.636, categories: ['Coating', 'Coating']},
            {name: 'I-DWA251', spend: 1.612, categories: ['Heat Treatment', 'Head']},
            {name: '154212*OP190', spend: 1.391, categories: ['Coating', 'Coating']},
            {name: '154204*OP190', spend: 1.363, categories: ['Coating', 'Coating']},
            {name: '154222*OP190', spend: 1.324, categories: ['Coating', 'Coating']},
            {name: '154213*OP190', spend: 1.227, categories: ['Coating', 'Coating']},
            {name: 'TD-U74051', spend: 1.182, categories: ['Coating', 'Talus ankle']},
            {name: '154208*OP190', spend: 1.180, categories: ['Coating', 'Coating']},
            {name: '154201*OP190', spend: 1.158, categories: ['Coating', 'Coating']},
            {name: '154206*OP190', spend: 1.067, categories: ['Coating', 'Coating,']},
        ],
    },
    {
        name: 'ELITE MEDICAL',
        type: 'Strategic',
        spend_master_ds: 996105,
        spend_cmp_ds: 120,
        l1_categories: ['ELECTRONICS', 'FG-OEM/BIO'],
        parts_master_ds: [
            {name: '3201-90429', spend: 31785, categories: ['METALS','PRODUCTS','Handled Instrumentation']},
            {name: '5101-90265', spend: 31605, categories: ['METALS','PRODUCTS','HANDLED INSTRUMENTATION']},
            {name: '801-90215', spend: 27680, categories: ['METALS','PRODUCTS','Benders']},
            {name: '7601-90171', spend: 25740, categories: ['METALS','PRODUCTS','GUIDES AND SLEEVES']},
            {name: '5001-90019', spend: 22040, categories: ['METALS','PRODUCTS','CUTTING AND AIMING GUIDES']},
            {name: '7601-90171', spend: 20585, categories: ['METALS','PRODUCTS','GUIDES AND SLEEVES']},
            {name: '3303-90384', spend: 16800, categories: []},
            {name: '5101-90195', spend: 16060, categories: []},
            {name: '7601-90085', spend: 15390, categories: ['METALS','PRODUCTS','GUIDES AND SLEEVES']},
            {name: '7501-90034', spend: 15260, categories: ['METALS','PRODUCTS','Trials,']},
        ],
        parts_cmp_ds: [
            {name: 'SMS00589*OP350', spend: 120, categories: ['Implant', 'Plate,']},
        ],
    },
    {
        name: 'Orchid',
        type: 'Strategic',
        spend_master_ds: 31384735,
        spend_cmp_ds: 439025,
        l1_categories: ['METALS' , 'PROF. SERV', 'PLASTICS/TEXTILES'],
        parts_master_ds: [
            {name: '164505-01', spend: 158507.21, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '164505-02', spend: 153838.31, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '164505-01', spend: 144704.72, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '702449.9', spend: 138274, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '164505-01', spend: 118467.15, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '164505-03', spend: 117548.8, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '702449.9', spend: 95290, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '702449.9', spend: 92404, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '48761330', spend: 82973.06, categories: ['METALS','PRODUCTS','HANDLED INSTRUMENTATION']},
            {name: '180604', spend: 82708, categories: ['METALS','PRODUCTS','MACHINED IMPLANTS']},
            {name: '702449.9', spend: 78598, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '164505-02', spend: 78083.24, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '702601.9', spend: 48404, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '5532-T-812Y300', spend: 46956, categories: ['PLASTICS/TEXTILES','MACHINING','INSTRUMENTS']},
            {name: '702449.9', spend: 46337, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '702449.9', spend: 46105, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '5532-T-719Y300', spend: 44614, categories: ['PLASTICS/TEXTILES','MACHINING','INSTRUMENTS']},
            {name: '180505', spend: 44265.5, categories: ['METALS','PROCESSES','CASTINGS']},
            {name: '702449.9', spend: 43986, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '702601.9', spend: 43549, categories: ['METALS','PRODUCTS','CUTTING TOOLS']},
            {name: '5532-T-714Y300', spend: 43513, categories: ['PLASTICS/TEXTILES','MACHINING','INSTRUMENTS,']},
        ],
        parts_cmp_ds: [
            {name: '9000381', spend: 31.831, categories: ['Instrument', 'Simple']},
            {name: '9000344', spend: 1.940, categories: ['Instrument', 'Simple']},
            {name: '152160', spend: 4.248, categories: ['Instrument', 'Cutting Tool']},
            {name: '152161', spend: 4.116, categories: ['Instrument', 'Cutting Tool']},
            {name: '46S51808*OP12', spend: 52.633, categories: ['Coating', 'Coating']},
            {name: '46S02065*OP35', spend: 51.479, categories: ['Coating', 'Coating']},
            {name: '46S01665*OP35', spend: 35.617, categories: ['Coating', 'Coating']},
            {name: '46S02475*OP35', spend: 21.774, categories: ['Coating', 'Coating']},
            {name: '46S01645*OP35', spend: 21.486, categories: ['Coating', 'Coating']},
            {name: '46S01655*OP35', spend: 20.476, categories: ['Coating', 'Coating']},
            {name: '46S52210*OP12', spend: 19.179, categories: ['Coating', 'Coating']},
            {name: '46S02045*OP35', spend: 17.881, categories: ['Coating', 'Coating']},
            {name: '46S52208*OP12', spend: 17.304, categories: ['Coating', 'Coating']},
            {name: '46S02055*OP35', spend: 17.160, categories: ['Coating', 'Coating']},
            {name: '46S01306*OP35', spend: 16.727, categories: ['Coating', 'Coating']},
            {name: '46S52012*OP12', spend: 16.583, categories: ['Coating', 'Coating']},
            {name: '46S51812*OP12', spend: 11.536, categories: ['Coating', 'Coating']},
            {name: 'SEF530HA*OP20', spend: 10.707, categories: ['Coating', 'Coating']},
            {name: '46S01309*OP35', spend: 10.382, categories: ['Coating', 'Coating']},
            {name: '46S02075*OP35', spend: 9.373, categories: ['Coating', 'Coating']},
            {name: 'SEF540HA*OP20', spend: 8.878, categories: ['Coating', 'Coating']},
            {name: '46S52008*OP12', spend: 8.796, categories: ['Coating', 'Coating']},
            {name: '46673720*OP12', spend: 8.545, categories: ['Coating', 'Coating']},
            {name: 'SEF525HA*OP20', spend: 8.528, categories: ['Coating', 'Coating']},
            {name: '46673015*OP12', spend: 7.589, categories: ['Coating', 'Coating,']},

        ],
    },
    {
        name: 'SIMAGEC',
        type: 'Strategic',
        spend_master_ds: 9450,
        spend_cmp_ds: 94358,
        l1_categories: ['METALS' , 'PROF. SERV'],
        parts_master_ds: [
            {name: 'TEST', spend: 799.47, categories: ['METALS','PROCESSES','OSP']},
            {name: 'MISSING', spend: 167.30448, categories: ['PROF. SERV / COMMODITIES / FREIGHT / OTHERS* ','FREIGHT','FREIGHT']},
            {name: 'MISSING', spend: 113.12, categories: ['METALS','PROCESSES','OSP']},
            {name: 'MISSING', spend: 18.6079722, categories: ['PROF. SERV / COMMODITIES / FREIGHT / OTHERS* ','FREIGHT','FREIGHT']},
            {name: 'MISSING', spend: 474.5474911, categories: ['PROF. SERV / COMMODITIES / FREIGHT / OTHERS* ','FREIGHT','FREIGHT']},
            {name: 'QO-P', spend: 2081.69505, categories: []},
            {name: 'QP', spend: 5412.40713, categories: []},
            {name: 'MISSING', spend: 153.4262968, categories: ['PROF. SERV / COMMODITIES / FREIGHT / OTHERS* ','FREIGHT','FREIGHT']},
            {name: 'MISSING', spend: 16.7034, categories: ['METALS','PROCESSES','OSP']},
            {name: 'MISSING', spend: 94.6526, categories: ['METALS','COMPONENTS','LASER PROCESSED PRODUCTS']},
            {name: 'TEST', spend: 0.01, categories: ['METALS','PROCESSES','OSP']},
            {name: 'MISSING', spend: 0.01, categories: ['METALS','COMPONENTS','LASER PROCESSED PRODUCTS']},
            {name: 'MISSING', spend: 20.945746, categories: ['PROF. SERV / COMMODITIES / FREIGHT / OTHERS* ','FREIGHT','FREIGHT,']},

        ],
        parts_cmp_ds: [
            {name: 'ST.P01.020.012', spend: 8.387, categories: ['Packaging Service', 'Screw']},
            {name: 'ST.P04.090.080', spend: 7.873, categories: ['Packaging Service', 'Stapple']},
            {name: 'ST.P01.020.011', spend: 6.280, categories: ['Packaging Service', 'Screw']},
            {name: 'VENPS2414', spend: 4.490, categories: ['Packaging Service', 'Screw']},
            {name: 'ST.P01.020.013', spend: 3.504, categories: ['Packaging Service', 'Screw']},
            {name: 'VENPS2412', spend: 3.069, categories: ['Packaging Service', 'Screw']},
            {name: 'VENPS2416', spend: 2.592, categories: ['Packaging Service', 'Screw']},
            {name: 'ST.P01.020.014', spend: 2.480, categories: ['Packaging Service', 'Screw']},
            {name: 'ST.P06.030.016', spend: 2.234, categories: ['Packaging Service', 'Screw']},
            {name: 'VS2320', spend: 2.185, categories: ['Packaging Service', 'Screw']},
            {name: 'ST.P06.030.018', spend: 1.752, categories: ['Packaging Service', 'Screw']},
            {name: 'VES2414', spend: 1.661, categories: ['Packaging Service', 'Screw']},
            {name: 'VS2318', spend: 1.601, categories: ['Packaging Service', 'Screw,']},
        ],
    },
    {
        name: 'TEGRA MEDICAL',
        type: 'Strategic',
        spend_master_ds: 1240813,
        spend_cmp_ds: 222862,
        l1_categories: ['METALS', 'FG-OEM/BIO'],
        parts_master_ds: [
            {name: 'SU01529-444', spend: 1199, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: 'SU01529-483', spend: 9719, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: 'SU01529-485', spend: 4374, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: 'SU01529-490', spend: 2983, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: 'RM50083-043',spend: 0, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: 'RM50083-005',spend: 0, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: 'SQ50162-024', spend: 2217, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: 'SQ50162-026', spend: 2046, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: 'SQ50162-058', spend: 5376, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: 'SU01529-73', spend: 494, categories: []},
            {name: 'SU01529-483', spend: 16175, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: '90105944-01', spend: 3113, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: 'MISSING', spend: 551, categories: ['METALS','COMPONENTS','LASER PROCESSED PRODUCTS']},
            {name: '90105945-01', spend: 1271, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: '90526202-19', spend: 3728, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
            {name: '90105945-01', spend: 2233, categories: ['METALS','COMPONENTS','FORMED COMPONENTS']},
        ],
        parts_cmp_ds: [
            {name: '49510137', spend: 35.902, categories: ['Instrument', 'Simple']},
            {name: '86PS1000', spend: 33.357, categories: ['Instrument', 'Simple']},
            {name: '49510134', spend: 32.581, categories: ['Instrument', 'Simple']},
            {name: '49510103', spend: 28.446, categories: ['Instrument', 'Simple']},
            {name: '49510122', spend: 24.238, categories: ['Instrument', 'Simple']},
            {name: '49510144', spend: 15.933, categories: ['Instrument', 'Simple']},
            {name: 'DSDS0001', spend: 15.636, categories: ['Instrument', 'Simple']},
            {name: '49510134', spend: 14.576, categories: ['Instrument', 'Simple']},
            {name: '49510102', spend: 12.320, categories: ['Instrument', 'Simple']},
            {name: '49510144', spend: 3.451, categories: ['Instrument', 'Simple']},
            {name: '42112005', spend: 2.992, categories: ['Instrument', 'Simple']},
            {name: '24872005', spend: 3.429, categories: ['Instrument', 'Cutting Tool']},
        ],
    },
]
