import '../../../../components/data-table/DataTable.scss';
import './CategorizationReviewTable.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {MithraTablePaginationActions} from '../../../../components/table-pagination/MithraTablePaginationActions';
import {CategorizationReviewMainRow} from "./CategorizationReviewMainRow";
import {
    CurrencyAbbreviation,
    findAbbreviationOfGroup,
    NO_ABBREVIATION
} from "../../../../components/currency-component/CurrencyComponent";
import {getSpend} from "../../../../services/classes/MatReviewClasses";
import {CurrencyAbbreviationContext} from '../../../../components/currency-component/CurrencyAbbreviationContext';

// TODO: Move to company specific settings
const COLUMNS = [
    {cls: 'col-dropdown', txt: ''},
    {cls: 'col-s_name', txt: 'Vendor name'},
    {cls: 'col-s_spend', txt: 'Total spend'},
    {cls: 'col-l1s', txt: 'L1(s) of vendor'},
    {cls: 'col-s_cats', txt: ''},
]
export const CAT_MAIN_REVIEW_TABLE_COL_SPAN = COLUMNS.length;

export const CategorizationReviewMainTable: React.FC = observer(() => {
    const {categorizationStore, p} = useStores()

    const supplierPages = categorizationStore.data.supplierPages;
    let abbreviation: CurrencyAbbreviation;
    if (p.p.hardcodedKPIAbbreviation) {
        abbreviation = p.p.hardcodedKPIAbbreviation;
    } else {
        abbreviation = NO_ABBREVIATION;
        if (supplierPages.data) {
            const spends = supplierPages.data.map(d => getSpend(d))
            if (spends.length > 0)
                abbreviation = findAbbreviationOfGroup(spends)
        }
    }
    // const data = undefined as any as any[];
    const isLoading = categorizationStore.data.isLoadingParts;
    const tableClass = 'mithra-table x-design categorization-report-table' + (isLoading ? ' loading' : '');
    return <TableContainer className={tableClass}>
        <Table cellPadding="0">
            <TableHead>
                <TableRow>
                    {COLUMNS.map((c, i) =>
                        <TableCell key={i} className={c.cls}>{c.txt}</TableCell>
                    )}
                </TableRow>
                <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
                    <td colSpan={CAT_MAIN_REVIEW_TABLE_COL_SPAN}>
                        {isLoading && <LinearProgress variant="indeterminate"/>}
                    </td>
                </tr>

            </TableHead>
            <TableBody className="categorization-report-table-body">
                {supplierPages.data ? <>
                        {supplierPages.data.map(row =>
                            <React.Fragment key={row.id}>
                                <CurrencyAbbreviationContext.Provider value={abbreviation}>
                                    <CategorizationReviewMainRow row={row}/>
                                </CurrencyAbbreviationContext.Provider>
                            </React.Fragment>
                        )}
                        {supplierPages.data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={CAT_MAIN_REVIEW_TABLE_COL_SPAN}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        //
                        rowsPerPageOptions={[]}
                        colSpan={CAT_MAIN_REVIEW_TABLE_COL_SPAN}
                        count={supplierPages.count}
                        rowsPerPage={supplierPages.pageSize}
                        page={supplierPages.page - 1}
                        // SelectProps={{
                        //     inputProps: {'aria-label': 'rows per page'},
                        //     native: true,
                        // }}
                        // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                        onPageChange={(e, page) => supplierPages.changePage(page + 1)}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
