import {AxoisRequestManager} from "../managers/RequestManager";
import {Categories, CategorizationApprovalStats} from "../../services/classes/AiClasses";
import {from} from "rxjs";
import {PipeManager} from "../managers/PipeManager";
import {
    AnyMatPartReviewRowState,
    StorePartFeedbackManySerializer,
    StorePartFeedbackSerializer
} from "../../services/classes/MatReviewClasses";
import {ReviewChoice} from "../../services/classes/MaterializedClasses";
import {setOnObj} from "../../services/ApiHelpers";
import {makeAutoObservable} from "mobx";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {CategorizationReviewDataDelegate} from "../categorization/CategorizationReviewDataDelegate";
import {ApprovalCategorization} from "../../services/classes/MatReviewHelpers";

type StorePartFeedbackPipe = { data: StorePartFeedbackSerializer, partId: number };

type FeedbackState = 'approval_applied' | 'approval_accept' | 'approval_reject' | 'open';

export type CombinedFeedbackState = {
    btn: 'open'
        | 'accept'
        | 'reject'
        | 'both'
        | 'hidden'
    extra: 'mixed_states'
        | ''
    editAllowed: boolean
    feedbackByOther: boolean
    cat: ApprovalCategorization
};

export type SingleFeedbackState = {
    btn: 'open'
        | 'accept'
        | 'reject'
        | 'both'
        | 'hidden'
    extra: 'mixed'
        | ''
    editAllowed: boolean
    feedbackByOther: boolean
    leftCat: ApprovalCategorization
    rightCat: ApprovalCategorization
};

export class CategorizationReviewFeedbackDelegate {
    readonly stats = new AxoisRequestManager<number, CategorizationApprovalStats>(
        approvalId => from(this.matApi.getCategorizationApprovalStatsRequest(approvalId))
    )
    readonly accRejPipe = new PipeManager<StorePartFeedbackPipe>(
        ({data, partId}) => from(this.matApi.storePartFeedback(partId, data))
    )
    readonly accRejMultiPipe = new PipeManager<StorePartFeedbackManySerializer>(
        data => from(this.matApi.storePartFeedbackMany(data))
    )
    readonly accRejAllPipe = new PipeManager<{ c: ReviewChoice, approvalId: number }>(
        ({c, approvalId}) => from(this.matApi.storePartFeedbackAll(approvalId, c))
    )

    constructor(
        private matApi: MithraMaterializedApi,
        private data: CategorizationReviewDataDelegate,
    ) {
        makeAutoObservable(this);
    }

    get numberOfBusyRequests(): number {
        return this.accRejPipe.nInPipe
            + this.accRejMultiPipe.nInPipe
            + this.accRejAllPipe.nInPipe
    }

    /**
     * Simplistic workaround implementation of the combined state in the approval screen
     * @see https://mithra-ai.atlassian.net/browse/CAT-548
     * @param parts
     */
    static calcCombinedFeedbackState(parts?: AnyMatPartReviewRowState[]): CombinedFeedbackState {
        if (parts === undefined || parts.length === 0)
            return {btn: 'hidden', extra: '', editAllowed: false, feedbackByOther: false, cat: 'none'};

        // Collect the states
        const part1 = parts[0];

        let state = CategorizationReviewFeedbackDelegate.getFeedbackState(part1);
        let allState: FeedbackState | 'mix' = state;
        let feedbackByOther = part1.feedback_user_id !== null && !part1.feedback_mine;
        for (const part of parts.slice(1)) {
            state = CategorizationReviewFeedbackDelegate.getFeedbackState(part);
            if (state !== allState) allState = 'mix';
            feedbackByOther = feedbackByOther || (part.feedback_user_id !== null && !part.feedback_mine);
        }

        let btn: CombinedFeedbackState['btn'];
        let extra: CombinedFeedbackState['extra'] = '';
        let cat: CombinedFeedbackState['cat'] = 'review|ai';
        let editAllowed = true;
        switch (allState) {
            case "approval_applied":
                btn = 'hidden';
                editAllowed = false;
                break;
            case "approval_accept":
                btn = 'accept';
                break;
            case "approval_reject":
                btn = 'reject';
                cat = 'input'; // This is the only case that also needs to be tested in the BE
                break;
            case "open":
                btn = 'open'
                break;
            case "mix":
                btn = 'both';
                extra = 'mixed_states';
                editAllowed = false; // Just to be sure...
                break;
            default:
                throw new Error(`Unknown state ${allState}`)
        }
        return {btn, extra, editAllowed, feedbackByOther, cat};
    }


    private static getFeedbackState(part: AnyMatPartReviewRowState): FeedbackState {
        if (part.review_choice === ReviewChoice.REJECT) {
            console.warn('Feedback state out of specification', {partRowId: part.id})
        }
        // If the part is approved and accepted the state of approved
        if (part.approval_applied) {
            return 'approval_applied';
        }
        if (part.feedback_choice === ReviewChoice.ACCEPT) {
            return 'approval_accept';
        }
        if (part.feedback_choice === ReviewChoice.REJECT) {
            return 'approval_reject';
        }
        return 'open'
    }

    static calcSingleState(part: AnyMatPartReviewRowState): SingleFeedbackState {
        let btn: SingleFeedbackState['btn'];
        let extra: SingleFeedbackState['extra'] = '';
        let leftCat: SingleFeedbackState['leftCat'] = 'input';
        let rightCat: SingleFeedbackState['rightCat'] = 'review|ai';
        let editAllowed = true;
        let feedbackByOther = part.feedback_user_id !== null && !part.feedback_mine;

        const state = CategorizationReviewFeedbackDelegate.getFeedbackState(part);
        console.log('state=', state)
        switch (state) {
            case "approval_applied":
                btn = 'hidden';
                editAllowed = false;
                break;
            case "approval_accept":
                btn = 'accept';
                break;
            case "approval_reject":
                btn = 'reject';
                rightCat = 'input'; // This is the only case that also needs to be tested in the BE
                break;
            case "open":
                btn = 'open'
                break;
        }
        return {btn, extra, editAllowed, feedbackByOther, leftCat, rightCat};
    }

    clickAcceptReject(part: AnyMatPartReviewRowState, feedback_choice: ReviewChoice) {
        // Update the view
        part.feedback_choice = feedback_choice;
        part.feedback_mine = true;

        // Update the API
        const key = part.id
        const data: StorePartFeedbackPipe = {
            data: {
                feedback_choice,
            },
            partId: part.id,
        }
        this.accRejPipe.process(key, data);
    }

    /**
     * @deprecated Feedback is not allowed to change the categories anymore
     */
    clickRecatPart(part: AnyMatPartReviewRowState, category_choice: Categories) {
        const feedback_choice = ReviewChoice.ACCEPT;
        // Update the view
        part.feedback_choice = feedback_choice;
        part.feedback_mine = true;
        setOnObj(part, 'p_feedback_cat', category_choice);

        // Update the API
        const key = part.id
        const data: StorePartFeedbackPipe = {
            data: {
                feedback_choice,
            },
            partId: part.id,
        }
        setOnObj(data.data as any, 'p_feedback_cat', category_choice);
        this.accRejPipe.process(key, data);
    }

    /**
     * @deprecated Feedback is not allowed to change the categories anymore
     */
    clickAcceptRejectParts(parts: AnyMatPartReviewRowState[], feedback_choice: ReviewChoice): void {
        // Update the view
        parts.forEach(p => {
            p.feedback_choice = feedback_choice;
            p.feedback_mine = true;
        });
        // Update the API
        const key = Number(parts[0].id)
        const data: StorePartFeedbackManySerializer = {
            parts: parts.map(p => p.id),
            feedback_choice: feedback_choice,
        }
        this.accRejMultiPipe.process(key, data);
    }

    clickSetAll(approvalId: number | undefined, feedback_choice: ReviewChoice) {
        console.log('clickSetAll', approvalId, feedback_choice);
        // Update the view
        this.data.supplierPages.data?.forEach(s => s.parts?.forEach(p => {
            p.feedback_choice = feedback_choice;
            p.review_mine = true;
        }))
        // Update the API
        const key = Number(approvalId)
        this.accRejAllPipe.process(key, {c: feedback_choice, approvalId: key})
    }

    /**
     * @deprecated Feedback is not allowed to change the categories anymore
     */
    clickReCatParts(parts: AnyMatPartReviewRowState[], category_choice: Categories) {
        console.assert(parts.length > 0)

        const feedback_choice = ReviewChoice.ACCEPT;
        // Update the view
        parts.forEach(p => {
            p.feedback_choice = feedback_choice;
            p.feedback_mine = true;
            setOnObj(p, 'p_feedback_cat', category_choice);
        });

        const key = Number(parts[0].id);
        const data: StorePartFeedbackManySerializer = {
            parts: parts.map(p => p.id),
            feedback_choice: feedback_choice,
        }
        setOnObj(data as any, 'p_feedback_cat', category_choice);
        this.accRejMultiPipe.process(key, data)
    }
}
