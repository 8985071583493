import {observer} from "mobx-react-lite";
import {HistoryButton} from "./HistoryButton";
import {Button, Grow, Popper} from "@mui/material";
import {
    ArrowRightAlt,
    CallMerge,
    Check,
    ChevronLeft,
    CloudDownload,
    DeleteOutline,
    Description,
    Done,
    Edit,
    Sync
} from "@mui/icons-material";
import React, {useState} from "react";
import {useStores} from "../../stores";
import {generatePath, useHistory} from "react-router";
import {routes} from "../../routing/routes";


export const TaxonomyEditorButtonBar = observer(() => {
    const {p, taxonomyEditorStore, taxonomyManagerStore, materializedApi, bagStore} = useStores();
    // const routeMatch = useRouteMatch<JobRouteMatch>();
    const bagId = bagStore.bagId;
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleCloseSettings = () => {
        setAnchorEl(null);
    };

    const [isDownloadingExcel, setIsDownloadingExcel] = useState(false)
    const [hasDownloadedExcel, setHasDownloadedExcel] = useState(false)

    const allButtonsDisabled = !taxonomyManagerStore.canEditTaxonomy;

    return <div id="taxonomy-editor-button-toolbar">


        {taxonomyEditorStore.isEditMode ? <HistoryButton/> : undefined}
        {p.p.useAiTaxonomySuggestion && <Button
            color="primary"
            variant="outlined"
            className="toolbar-btn large-icon-btn"
            onClick={() => {
                history.push(generatePath(routes.job_taxonomy_suggestor, {
                    id: bagId,
                    taxonomyId: 1,
                    taxonomySuggestorId: 1
                }))
            }}>
            Get AI suggestions
        </Button>}
        <Button
            color="primary"
            variant="outlined"
            className="toolbar-btn large-icon-btn"
            startIcon={<ChevronLeft/>}
            disabled={!taxonomyEditorStore.canGoUp}
            onClick={() => taxonomyEditorStore.goUpLevel()}>
            {taxonomyEditorStore.goBackButtonTxt}
        </Button>
        <Button
            className="toolbar-btn large-icon-btn btn-red"
            color="primary"
            variant="outlined"
            startIcon={<DeleteOutline/>}
            disabled={allButtonsDisabled || taxonomyEditorStore.selection.length === 0}
            style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
            onClick={() => taxonomyEditorStore.deleteSelection()}>
            Delete
        </Button>
        <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            startIcon={<CallMerge style={{transform: 'rotate(90deg)'}}/>}
            disabled={allButtonsDisabled || taxonomyEditorStore.selection.length !== 2}
            style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
            onClick={() => taxonomyEditorStore.setMergeCategoryMode(true)}>
            Merge
        </Button>
        <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            startIcon={<ArrowRightAlt/>}
            disabled={allButtonsDisabled || taxonomyEditorStore.selection.length === 0}
            style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
            onClick={() => taxonomyEditorStore.setMoveCategoryMode(true)}>
            Move
        </Button>
        <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            startIcon={<Description/>}
            disabled={allButtonsDisabled || taxonomyEditorStore.selection.length !== 1}
            style={taxonomyEditorStore.isEditMode ? {} : {display: 'none'}}
            onClick={() => taxonomyEditorStore.setUpdateCategoryMode(true)}>
            Rename
        </Button>
        <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            startIcon={<Edit/>}
            disabled={allButtonsDisabled}
            onClick={() => taxonomyEditorStore.toggleEditMode()}>
            {taxonomyEditorStore.isEditMode ? 'Finish editing' : 'Edit'}
        </Button>

        {/*<Button*/}
        {/*    disabled={!taxonomyManagerStore.taxonomy}*/}
        {/*    className="toolbar-btn large-icon-btn two-icon-btn"*/}
        {/*    color="primary"*/}
        {/*    variant="outlined"*/}
        {/*    startIcon={<SettingsApplicationsOutlined/>}*/}
        {/*    endIcon={open ? <ExpandLess/> : <ExpandMore/>}*/}
        {/*    onClick={e => handleClickSettings(e, open)}*/}
        {/*    aria-haspopup={true}*/}
        {/*    aria-describedby="taxonomy-editor-button-settings">*/}
        {/*    <div className="btn-text">*/}
        {/*        /!*{open ? 'Close settings' : 'View settings'}*!/*/}
        {/*        Settings*/}
        {/*    </div>*/}
        {/*</Button>*/}
        <Popper
            id="taxonomy-editor-button-settings-popper"
            open={open}
            anchorEl={anchorEl}
            placement="bottom"
            transition
            disablePortal
        >
            {({TransitionProps, placement}) =>
                // Collapse is not possible: https://github.com/mui/material-ui/issues/11337
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <div className="overlay-container">
                        <Button
                            disabled
                            className="toolbar-btn large-icon-btn"
                            color="primary"
                            variant="outlined"
                            startIcon={<Description/>}
                            onClick={handleCloseSettings}>
                            <div className="btn-text">
                                View<br/> change log
                            </div>
                        </Button>
                        <Button
                            disabled
                            className="toolbar-btn large-icon-btn"
                            color="primary"
                            variant="outlined"
                            startIcon={<Sync/>}
                            onClick={handleCloseSettings}>
                            <div className="btn-text">
                                Calculate<br/> new amounts
                            </div>
                        </Button>
                        <Button
                            disabled
                            className="toolbar-btn large-icon-btn"
                            color="primary"
                            variant="outlined"
                            startIcon={<Check/>}
                            onClick={handleCloseSettings}>
                            <div className="btn-text">
                                Submit for approval
                            </div>
                        </Button>
                    </div>
                </Grow>
            }
        </Popper>
        {/*<Button*/}
        {/*    disabled={!taxonomyManagerStore.taxonomy}*/}
        {/*    className="toolbar-btn large-icon-btn"*/}
        {/*    color="primary"*/}
        {/*    variant="outlined"*/}
        {/*    startIcon={<Edit/>}*/}
        {/*    onClick={() => taxonomyEditorStore.toggleEditMode()}>*/}
        {/*    {taxonomyEditorStore.isEditMode ? 'Finish editing' : 'Edit'}*/}
        {/*</Button>*/}
        <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            startIcon={isDownloadingExcel ? undefined : (hasDownloadedExcel ? <Done/> :
                <CloudDownload/>)}
            endIcon={isDownloadingExcel ? undefined : (hasDownloadedExcel ? undefined : undefined)}
            disabled={allButtonsDisabled || isDownloadingExcel}
            style={taxonomyEditorStore.isEditMode ? {display: 'none'} : {minWidth: '10em'}}
            onClick={() => {
                const taxonomyId = taxonomyManagerStore.taxonomy?.id;
                if (taxonomyId) {
                    setIsDownloadingExcel(true)
                    const filename = 'Taxonomy.xlsx';
                    materializedApi.downloadTaxonomyExcel(taxonomyId, filename)
                        .then(resp => {

                            const url = URL.createObjectURL(new Blob([resp.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', filename);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode?.removeChild(link);
                            setHasDownloadedExcel(true)
                        })
                        .finally(() => {
                            setIsDownloadingExcel(false)
                        })
                }
            }}>
            {isDownloadingExcel ? 'Downloading ...' : 'Download'}
        </Button>
        {!p.p.hideApprovalButtons && <Button
            className="toolbar-btn large-icon-btn"
            color="primary"
            variant="outlined"
            disabled={allButtonsDisabled || !taxonomyManagerStore.stateInSync || !taxonomyManagerStore.undoAllowed}
            style={taxonomyEditorStore.isEditMode ? {display: 'none'} : {}}
            onClick={() => taxonomyManagerStore.setSendForApprovalDialog(true)}
        >
            Send for approval
        </Button>}
    </div>
})
