import {DashboardSwitcher} from "./DashboardSwitcher";
import React from "react";
import {SelectDataset} from "../../../components/main/header/SelectDataset";
import {MultiDropdownMenu} from "../menu/MultiDropdownMenu";
import {environment} from "../../../env";
import {useStores} from "../../../stores";
import {observer} from "mobx-react-lite";

export const MergeXHeader: React.FC = observer(() => {
    const {rootAppStore} = useStores();

    let doNotShowDataset = false;
    if (environment.dashboardVariant === 'simple') {
        // For the simple variant we disable the switching of master datasets when the Merge is chosen
        if (rootAppStore.app.dashboardEnvironment === 'merge') {
            doNotShowDataset = true;
        }
    }
    return <>
        <div style={{flexGrow: 1}}/>
        <SelectDataset showEmpty={doNotShowDataset}/>
        <DashboardSwitcher/>
        <MultiDropdownMenu/>
    </>;
})
