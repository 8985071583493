import './dashboard/SynergyDashboardPage.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {Bag} from "../../services/classes/Bag";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {environment} from "../../env";
import {generateHomePath} from "../../routing/routing.utils";
import {ButtonBase, Grid, Typography} from "@mui/material";
import {
    CmpBreakdownSingleVisualization,
    Data
} from "../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";
import {CmpDataBagRouteMatch, JobRouteMatch, routes} from "../../routing/routes";
import {AnnotatedValueComponent} from "../../components/annotated-value-component/AnnotatedValueComponent";
import {CurrencyComponent, toCurrency} from "../../components/currency-component/CurrencyComponent";
import {generatePath, useHistory} from "react-router";
import {KeyboardArrowRight} from "@mui/icons-material";

export const SynergyOverviewPage: React.FC = observer(() => {
    const {rootAppStore, p, bagStore, supplierNormalizationStore} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const bagId = bagStore.bagId;
    const bag = bagStore.bag as Bag;
    const noLink = false

    const mockDataSpendLG: Data = {
        top: {
            id: "1",
            total: 731,
            value: 11,
            valueTitle: "11",
            valueTitleHover: "1",
        },
        bottom: {
            id: "2",
            total: 379682112,
            value: 3977402,
            valueTitle: toCurrency(p.currencySymbol, 3977402, 3),
            valueTitleHover: toCurrency(p.currencySymbol, 3977402, 3,),
        },
    };


    const mockDataSpendEgg: Data = {
        top: {
            id: "1",
            total: 141,
            value: 9,
            valueTitle: "9",
            valueTitleHover: "9",
        },
        bottom: {
            id: "2",
            total: 9057077,
            value: 113033,
            valueTitle: toCurrency(p.currencySymbol, 113033, 3),
            valueTitleHover: toCurrency(p.currencySymbol, 113033, 3,),
        },
    };

    const mockDataCatEgg: Data = {
        top: {
            id: "1",
            total: 182,
            value: 130,
            valueTitle: "130",
            valueTitleHover: "130",
        },
        bottom: {
            id: "2",
            total: 9057077,
            value: 8403536,
            valueTitle: toCurrency(p.currencySymbol, 8403536, 3),
            valueTitleHover: toCurrency(p.currencySymbol, 8403536, 3,),
        },
    };

    const mockDataCatLg: Data = {
        top: {
            id: "1",
            total: 638,
            value: 32,
            valueTitle: "32",
            valueTitleHover: "32",
        },
        bottom: {
            id: "2",
            total: 499480942,
            value: 73001067,
            valueTitle: toCurrency(p.currencySymbol, 73001067, 3),
            valueTitleHover: toCurrency(p.currencySymbol, 73001067, 3,),
        },
    };


    const optionsRight = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: false,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }

    const optionsLeft = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: true,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }

    useEffect(() => {
        if (!p.p.hardcodedCombinedBagId) throw new Error('No hardcodedCombinedBagId set');
        supplierNormalizationStore.init(p.p.hardcodedCombinedBagId, bag.taxonomy_size)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const activeState = supplierNormalizationStore.state;
    useEffect(() => {
        if (activeState === 'running') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('clean_parents');
            }, environment.aiResultFakeTimeout);
        }
        if (activeState === 'applying') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('done');
            }, environment.aiResultFakeTimeout);
        }
    }, [activeState, supplierNormalizationStore]);

    return <KoiFacade
        title="Syergy Overview"
        intro={<></>}
        back={generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)}
        className="synergy-dashboard-page">


        {/*TODO: CAT-877: We need to split off hardcoded data and move it to the profile store! (LG: CAT-880)*/}
        <Grid container justifyContent="space-evenly" className="dashboard-header" style={{marginTop: '4em'}}>
            <Grid item lg={4} md={5}>
                <Typography variant="h2" component="h1" className="title text-center">
                    {'Egg dataset'}
                </Typography>
            </Grid>

            <Grid item lg={3} md={2}/>

            <Grid item lg={4} md={5}>
                <Typography variant="h2" component="h1" className="title text-center">
                    {'Liberty Global (15 Jan)'}
                </Typography>
            </Grid>
        </Grid>

        <Grid container justifyContent="space-evenly" alignItems="stretch" className="page-breakdown-section">
            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={mockDataSpendEgg}
                    options={optionsLeft}
                />
            </Grid>

            <Grid item lg={3} md={3} className="breakdown-values"
                  container direction="column" justifyContent="space-between" alignContent="center">
                <Grid item className="top-breakdown">
                    <MyButtonWrapper noLink={noLink} isCommSup={true}>
                        <Typography variant="h4" className="title">
                            Common suppliers
                        </Typography>
                        <Typography variant="h4" className="value">
                            <AnnotatedValueComponent v={20}/>
                        </Typography>
                    </MyButtonWrapper>
                </Grid>

                <Grid item className="bottom-breakdown">
                    <Typography variant="h4" className="title">
                        Suppliers spend
                    </Typography>
                    <Typography variant="h4" className="value">
                        <CurrencyComponent
                            v={4090436}
                            nDigits={3}
                        />
                    </Typography>
                </Grid>
            </Grid>

            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={mockDataSpendLG}
                    options={optionsRight}
                />
            </Grid>
        </Grid>

        <Grid container justifyContent="space-evenly" alignItems="stretch" className="page-breakdown-section">
            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={mockDataCatEgg}
                    options={optionsLeft}
                />
            </Grid>

            <Grid item lg={3} md={3} className="breakdown-values"
                  container direction="column" justifyContent="space-between" alignContent="center">
                <Grid item className="top-breakdown">
                    <MyButtonWrapper noLink={noLink} isCommSup={false}>
                        <Typography variant="h4" className="title">
                            Similar Categories
                        </Typography>
                        <Typography variant="h4" className="value">
                            <AnnotatedValueComponent v={130}/>
                        </Typography>
                    </MyButtonWrapper>
                </Grid>

                <Grid item className="bottom-breakdown">
                    <Typography variant="h4" className="title">
                        Combined spend
                    </Typography>
                    <Typography variant="h4" className="value">
                        <CurrencyComponent
                            v={73197890}
                            nDigits={3}
                        />
                    </Typography>
                </Grid>
            </Grid>

            <Grid item lg={4} md={4}>
                <CmpBreakdownSingleVisualization
                    data={mockDataCatLg}
                    options={optionsRight}
                />
            </Grid>
        </Grid>
    </KoiFacade>
});

type Props = {
    noLink?: boolean,
    isCommSup?: boolean
}
const MyButtonWrapper: React.FC<Props> = ({noLink, isCommSup, children}) => {
    const history = useHistory();
    const routeMatch = useRouteMatch<CmpDataBagRouteMatch>();
    if (noLink) return <>{children}</>;
    return <div className="breakdown-button-wrapper">
        <ButtonBase
            onClick={() => {
                if (isCommSup) {
                    history.push(generatePath(routes.merge_x_merge_common_suppliers, routeMatch.params))
                } else {
                    history.push(generatePath(routes.merge_x_merge_common_categories, routeMatch.params))
                }
            }}
            className="breakdown-button"
        >
            <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                    {children}
                </Grid>
                <Grid item className="button-arrow">
                    <KeyboardArrowRight/>
                </Grid>
            </Grid>
        </ButtonBase>
    </div>
};

//////////////////////////////////////////////////////////
// TODO: Resolve merge CONFLICT CAT-877 CAT-880 CAT-878 //
//////////////////////////////////////////////////////////
// export const SynergyOverviewPage: React.FC = observer(() => {
//     const {rootAppStore, p, bagStore, supplierNormalizationStore} = useStores();
//     const routeMatch = useRouteMatch<JobRouteMatch>();
//     const bagId = bagStore.bagId;
//     const bag = bagStore.bag as Bag;
//
//     const mockData: Data = {
//         top: {
//             id: "1",
//             total: 1000,
//             value: 800,
//             valueTitle: "Value 1",
//             valueTitleHover: "Hover Value 1",
//         },
//         bottom: {
//             id: "2",
//             total: 2000,
//             value: 1500,
//             valueTitle: "Value 2",
//             valueTitleHover: "Hover Value 2",
//         },
//     };
//
//     const options = {
//         width: 1000,
//         height: 500,
//         margin: {top: 0, left: 0, bottom: 0, right: 0},
//         alignRight: true,
//         fixedWidth: false,
//         barPortion: 10,
//         flowStrokeWidth: 10,
//         hoverVisible: true,
//         defaultWideMargin: true,
//     }
//
//     useEffect(() => {
//         if (!p.p.hardcodedCombinedBagId) throw new Error('No hardcodedCombinedBagId set');
//         supplierNormalizationStore.init(p.p.hardcodedCombinedBagId, bag.taxonomy_size)
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [])
//
//     const activeState = supplierNormalizationStore.state;
//     useEffect(() => {
//         if (activeState === 'running') {
//             setTimeout(() => {
//                 supplierNormalizationStore.goToState('clean_parents');
//             }, environment.aiResultFakeTimeout);
//         }
//         if (activeState === 'applying') {
//             setTimeout(() => {
//                 supplierNormalizationStore.goToState('done');
//             }, environment.aiResultFakeTimeout);
//         }
//     }, [activeState, supplierNormalizationStore]);
//
//     return <KoiFacade
//         title="Syergy Overview"
//         intro={<></>}
//         back={generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)}
//         className="sup-norm-page">
//
//         {/*Paste the thing from SynergyDashboardPage*/}
//
//         <Grid container>
//             <Grid item lg={4} md={4}>
//                 <CmpBreakdownSingleVisualization
//                     data={mockData}
//                     options={options}
//                 />
//             </Grid>
//
//             <Grid item lg={4} md={4}>
//                 <CmpBreakdownSingleVisualization
//                     data={mockData}
//                     options={options}
//                 />
//             </Grid>
//         </Grid>
//
//
//     </KoiFacade>
// });
