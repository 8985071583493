export function arrCopyAdd<T>(array: T[], value: T): T[] {
    const copy = [...array];
    copy.push(value);
    return copy;
}

export function arrCopyRemoveIndex<T>(array: T[], index: number): T[] {
    const copy = [...array];
    copy.splice(index, 1);
    return copy;
}

export function sum(arr: number[]) {
    return arr.reduce((s, i) => s + i, 0);
}

export function normalize_arr(arr: number[]) {
    const sum = arr.reduce((s, i) => s + i, 0);
    return arr.map(i => i / sum);
}

export function cum_sum(arr: number[]) {
    const c = [] as number[];
    arr.forEach((value, index) => {
        if (index === 0)
            c.push(value)
        else
            c.push(c[index - 1] + value);
    })
    return c;
}

export function deepCopy<T>(obj: T): T {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        const _obj = obj as {}
        copy = {};
        for (let attr in _obj) {
            if (_obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}


export function treeClone<T extends { children: T[] }>(tree: T) {
    const clone = Object.create(tree) as T
    if (clone.children) {
        clone.children = clone.children.map(c => treeClone(c))
    }
    return clone
}

/**
 * cyrb128 hash function
 * @param str string to hash
 * @param seed seed value
 * @returns hash string
 * @see https://stackoverflow.com/a/52171480/104380
 */
export function cyrb53(str: string, seed = 0): number {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}


/**
 * Generate a random number btw min and max based on a random hash string
 */
export function randomHash(min: number, max: number, hash: string) {
    const hashInt = cyrb53(hash)
    const range = max - min
    return Math.floor(hashInt % range) + min
}
