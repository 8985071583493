import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {Collapse, IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {ExpandMore, KeyboardArrowRight} from "@mui/icons-material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {MarkOpportunityButton} from "../../../components/mark-opportunity-btn/MarkOpportunityButton";
import {CategoryChip} from "../../../components/category-chip/CategoryChip";
import {CommonSupplierData, SupplierComparePartData} from "../hardcoded/hardcoded_dpw_types";
import {useStores} from "../../../stores";
import {SynergySupplierOpportunityModal} from "./SynergySupplierOpportunityModal";

type Props = {
    data: CommonSupplierData,
};

export const SynergySupplierMainRow: React.FC<Props> = observer(({data}) => {
    const {opportunityStore} = useStores();

    const [open, setOpen] = React.useState(false);
    const [isOpportunityModelOpen, setOpportunityModalOpen] = useState(false);

    const partData = data.parts_cmp_ds.concat(data.parts_master_ds);
    partData.sort((a, b) => b.spend - a.spend);
    const opportunityId = 'synergy-supplier-' + data.name;
    const opportunityExists = opportunityStore.hasOpportunity(opportunityId);
    const combinedSpend = data.spend_master_ds + data.spend_cmp_ds;

    return <>
        <SynergySupplierOpportunityModal data={data} isOpen={isOpportunityModelOpen} setOpen={setOpportunityModalOpen}/>
        <TableRow>
            <TableCell className="col-dropdown" onClick={() => setOpen(!open)}>
                <IconButton aria-label="expand row" size="small">
                    {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            </TableCell>

            <TableCell className="col-s_name" component="th" scope="row">
                {data.name}
            </TableCell>
            <TableCell className="col-s_type">
                {data.type}
            </TableCell>
            <TableCell className="col-s_spend_combined">
                <CurrencyComponent v={combinedSpend}/>
            </TableCell>
            <TableCell className="col-s_spend_master_ds">
                <CurrencyComponent v={data.spend_master_ds}/>
            </TableCell>
            <TableCell className="col-s_spend_cmp_ds">
                <CurrencyComponent v={data.spend_cmp_ds}/>
            </TableCell>
            <TableCell className="col-s_categories">
                {data.l1_categories.join(', ')}
            </TableCell>
            <TableCell className="col-opportunity" align="right">
                <MarkOpportunityButton isMarked={opportunityExists} onMark={() => setOpportunityModalOpen(true)}/>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell className="cell-sub-table" colSpan={8}>
                {partData.length === 0
                    ? undefined
                    : <Collapse in={open} unmountOnExit>
                        <Table
                            className="sub-table"
                            size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="col-p_name" colSpan={6}>SKU</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {partData.map((part, i) =>
                                    <SynergySupplierTableSubRow key={i} part={part}/>
                                )}
                            </TableBody>
                        </Table>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})

const SynergySupplierTableSubRow: React.FC<{ part: SupplierComparePartData }> = ({part}) => {
    // const [isMarked, setMarked] = useState(false);
    return <TableRow>
        <TableCell className="col-p_name" component="th" scope="row">
            {part.name}
        </TableCell>
        <TableCell className="col-p_spend_combined">
            <CurrencyComponent v={part.spend}/>
        </TableCell>
        <TableCell className="col-p_spend_master_ds">
            {part.is_master && <CurrencyComponent v={part.spend}/>}
        </TableCell>
        <TableCell className="col-p_spend_cmp_ds">
            {!part.is_master && <CurrencyComponent v={part.spend}/>}
        </TableCell>
        <TableCell className="col-p_categories">
            <CategoryChip labels={part.categories}/>
        </TableCell>
        <TableCell className="col-opportunity" align="right">
            {/*<MarkOpportunityButton isMarked={isMarked} onMark={() => setMarked(!isMarked)}/>*/}
        </TableCell>
    </TableRow>
}
