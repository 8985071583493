import {
    MatPartReviewRow,
    MatSupplierBuL1ReviewRow,
    MatSupplierBuL2ReviewRow,
    MatSupplierBuL3ReviewRow,
    MatSupplierBuL4ReviewRow,
    MatSupplierBuL5ReviewRow,
    MatSupplierBuL6ReviewRow,
    MatSupplierBuL7ReviewRow,
    MatSupplierBuL8ReviewRow,
    MatSupplierBuReviewRow,
    MatSupplierL1ReviewRow,
    MatSupplierL2ReviewRow,
    MatSupplierL3ReviewRow,
    MatSupplierL4ReviewRow,
    MatSupplierL5ReviewRow,
    MatSupplierL6ReviewRow,
    MatSupplierL7ReviewRow,
    MatSupplierL8ReviewRow,
    MatSupplierReviewRow
} from "./MaterializedClasses";
import {PickAndOptional, Range} from "../../utils/ts-utils";
import {CombinedState} from "./MatReviewHelpers";
import {setParamsOrEmpty} from "../ApiHelpers";

export type MatSupplierFilterType = 'supplier'
    | LS
    | 'bu'
    | 'bu_l1' | 'bu_l2' | 'bu_l3' | 'bu_l4'
    | 'bu_l5' | 'bu_l6' | 'bu_l7' | 'bu_l8'

export type MatSearch = {
    supplier?: string
}

type Ls = `l${Range<1, 9>}` // Excludes end range
export type LevelSpecObject = Record<Ls, string>

/**
 Filter that retrieves a single node

 Can directly be used as a filter to the BE
 */
export type TreeNodeFilter = {
    type: 'node',
    databag: number
    level: number,
    l1: string, l2: string, l3: string, l4: string,
    l5: string, l6: string, l7: string, l8: string,
}
/**
 * Filter that retrieves all the children under a specific node
 *
 * Will send level + 1 as filter to the BE
 */
export type TreeChildrenFilter = {
    type: 'children',
    databag: number
    level: number,
    l1: string, l2: string, l3: string, l4: string,
    l5: string, l6: string, l7: string, l8: string,
}
export type AbsTreeNodeFilter = TreeNodeFilter | TreeChildrenFilter

export function filterOnRoot(databag: number) {
    return {type: 'node', level: 0, databag, l1: ''} as TreeNodeFilter
}

export type MatSupplierFilter = {
    databag: number
    business_unit: null | number | undefined
    search?: MatSearch
    level: number,
    l1: string, l2: string, l3: string, l4: string,
    l5: string, l6: string, l7: string, l8: string,
    /**
     * Only show suppliers that have been send for approval
     */
    approval?: number
}
export type MatPartFilter = {
    databag: number
    urlParams?: URLSearchParams
}
export type MatSupplierFilter_V2 = {
    databag: number
    business_unit: null | number | undefined
    search?: MatSearch
    /**
     * TODO: Doc required
     */
    filterLevel: number,

    /**
     * TODO: Doc required
     * Move elements below to a single class or array...
     */
    fixLevels?: number | undefined,
    l1: string, l2: string, l3: string, l4: string,
    l5: string, l6: string, l7: string, l8: string,
}

export function setLevelFilterParams<T extends object>(level: number, filter: LevelSpecObject, params: T): T {
    switch (level) {
        case 0:
            return params;
        case 1:
            return setParamsOrEmpty(params, {
                'l1': filter.l1
            });
        case 2:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2,
            });
        case 3:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3,
            });
        case 4:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3, 'l4': filter.l4,
            });
        case 5:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3, 'l4': filter.l4,
                'l5': filter.l5,
            });
        case 6:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3, 'l4': filter.l4,
                'l5': filter.l5, 'l6': filter.l6,
            });
        case 7:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3, 'l4': filter.l4,
                'l5': filter.l5, 'l6': filter.l6, 'l7': filter.l7,
            });
        case 8:
            return setParamsOrEmpty(params, {
                'l1': filter.l1, 'l2': filter.l2, 'l3': filter.l3, 'l4': filter.l4,
                'l5': filter.l5, 'l6': filter.l6, 'l7': filter.l7, 'l8': filter.l8,
            });
        default:
            throw new Error(`Filter ${JSON.stringify(filter)} is not supported!`);
    }
}

export function matGetFilterType(f: MatSupplierFilter): MatSupplierFilterType {
    const hasBu = f.business_unit !== undefined;
    const bu_ = hasBu ? 'bu_' as const : '' as const;
    if (f.level === 0) {
        if (!hasBu) return 'supplier';
        else return 'bu';
    }
    const level = f.level as Range<1, 3>; // TODO: Experimental
    return `${bu_}l${level}`;
}

export type MatPartReviewRowState = MatPartReviewRow & {
    // Helpers:
    review_mine: boolean
    feedback_mine: boolean
    selected: boolean
    isLastSelected: boolean
    parent_supplier_row: SomeMatSupplierReviewRowState
}
export type SingularMatPartReviewRowState = Omit<MatPartReviewRowState, 'parent_supplier_row'>
export type AnyMatPartReviewRowState = MatPartReviewRowState | SingularMatPartReviewRowState;

export type SomeMatSupplierReviewRow =
    MatSupplierReviewRow
    | MatSupplierL1ReviewRow
    | MatSupplierL2ReviewRow
    | MatSupplierL3ReviewRow
    | MatSupplierL4ReviewRow
    | MatSupplierL5ReviewRow
    | MatSupplierL6ReviewRow
    | MatSupplierL7ReviewRow
    | MatSupplierL8ReviewRow
    | MatSupplierBuReviewRow
    | MatSupplierBuL1ReviewRow
    | MatSupplierBuL2ReviewRow
    | MatSupplierBuL3ReviewRow
    | MatSupplierBuL4ReviewRow
    | MatSupplierBuL5ReviewRow
    | MatSupplierBuL6ReviewRow
    | MatSupplierBuL7ReviewRow
    | MatSupplierBuL8ReviewRow

type RowState = Partial<{
    parts: MatPartReviewRowState[]
    combined_state: CombinedState
    selected: boolean
}>

export type MatSupplierReviewRowState = MatSupplierReviewRow & RowState & { filter: 'supplier' }
export type MatSupplierL1ReviewRowState = MatSupplierL1ReviewRow & RowState & { filter: 'l1' }
export type MatSupplierL2ReviewRowState = MatSupplierL2ReviewRow & RowState & { filter: 'l2' }
export type MatSupplierL3ReviewRowState = MatSupplierL3ReviewRow & RowState & { filter: 'l3' }
export type MatSupplierL4ReviewRowState = MatSupplierL4ReviewRow & RowState & { filter: 'l4' }
export type MatSupplierL5ReviewRowState = MatSupplierL5ReviewRow & RowState & { filter: 'l5' }
export type MatSupplierL6ReviewRowState = MatSupplierL6ReviewRow & RowState & { filter: 'l6' }
export type MatSupplierL7ReviewRowState = MatSupplierL7ReviewRow & RowState & { filter: 'l7' }
export type MatSupplierL8ReviewRowState = MatSupplierL8ReviewRow & RowState & { filter: 'l8' }
export type MatSupplierBuReviewRowState = MatSupplierBuReviewRow & RowState & { filter: 'bu' }
export type MatSupplierBuL1ReviewRowState = MatSupplierBuL1ReviewRow & RowState & { filter: 'bu_l1' }
export type MatSupplierBuL2ReviewRowState = MatSupplierBuL2ReviewRow & RowState & { filter: 'bu_l2' }
export type MatSupplierBuL3ReviewRowState = MatSupplierBuL3ReviewRow & RowState & { filter: 'bu_l3' }
export type MatSupplierBuL4ReviewRowState = MatSupplierBuL4ReviewRow & RowState & { filter: 'bu_l4' }
export type MatSupplierBuL5ReviewRowState = MatSupplierBuL5ReviewRow & RowState & { filter: 'bu_l5' }
export type MatSupplierBuL6ReviewRowState = MatSupplierBuL6ReviewRow & RowState & { filter: 'bu_l6' }
export type MatSupplierBuL7ReviewRowState = MatSupplierBuL7ReviewRow & RowState & { filter: 'bu_l7' }
export type MatSupplierBuL8ReviewRowState = MatSupplierBuL8ReviewRow & RowState & { filter: 'bu_l8' }

export type SomeMatSupplierReviewRowState =
    MatSupplierReviewRowState
    | MatSupplierL1ReviewRowState
    | MatSupplierL2ReviewRowState
    | MatSupplierL3ReviewRowState
    | MatSupplierL4ReviewRowState
    | MatSupplierL5ReviewRowState
    | MatSupplierL6ReviewRowState
    | MatSupplierL7ReviewRowState
    | MatSupplierL8ReviewRowState
    | MatSupplierBuReviewRowState
    | MatSupplierBuL1ReviewRowState
    | MatSupplierBuL2ReviewRowState
    | MatSupplierBuL3ReviewRowState
    | MatSupplierBuL4ReviewRowState
    | MatSupplierBuL5ReviewRowState
    | MatSupplierBuL6ReviewRowState
    | MatSupplierBuL7ReviewRowState
    | MatSupplierBuL8ReviewRowState

type LS = 'l1' | 'l2' | 'l3' | 'l4' | 'l5' | 'l6' | 'l7' | 'l8';
export type StorePartReviewSerializer = PickAndOptional<MatPartReviewRow, 'review_choice', `p_review_cat_${LS}`>
export type StorePartReviewManySerializer = {
    parts: number[]
} & StorePartReviewSerializer
export type StorePartFeedbackSerializer = PickAndOptional<MatPartReviewRow, 'feedback_choice', `p_feedback_cat_${LS}`>
export type StorePartFeedbackManySerializer = {
    parts: number[]
} & StorePartFeedbackSerializer

export function getSpend(row: SomeMatSupplierReviewRowState) {
    switch (row.filter) {
        case "supplier":
            return row.s_total_spend;
        case "l1":
            return row.s_l1_spend;
        case "l2":
            return row.s_l2_spend;
        case "l3":
            return row.s_l3_spend;
        case "l4":
            return row.s_l4_spend;
        case "l5":
            return row.s_l5_spend;
        case "l6":
            return row.s_l6_spend;
        case "l7":
            return row.s_l7_spend;
        case "l8":
            return row.s_l8_spend;
        case "bu":
            return row.s_bu_spend;
        case "bu_l1":
            return row.s_bu_l1_spend;
        case "bu_l2":
            return row.s_bu_l2_spend;
        case "bu_l3":
            return row.s_bu_l3_spend;
        case "bu_l4":
            return row.s_bu_l4_spend;
        case "bu_l5":
            return row.s_bu_l5_spend;
        case "bu_l6":
            return row.s_bu_l6_spend;
        case "bu_l7":
            return row.s_bu_l7_spend;
        case "bu_l8":
            return row.s_bu_l8_spend;
    }
    console.warn('Could not retrieve spend');
    return 0;
}

export type DownloadTableRequest = {
    databag: number
    filename: string
    fields: string[]
    headers: string[]
    currency_symbol: string
    currency_fields: string[]
}
