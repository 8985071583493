// TODO: Remove customer specific modifications
// Move this to API
export const RESULT_DATA = [
    [
        "ANALOG DEVICES INTERN.",
        "ANALOG DEVICES INTERN"
    ],
    [
        "ANECTO LIMITED",
        "ANECTO"
    ],
    [
        "ARCAM CAD TO METAL",
        "ARCAM CAD METAL"
    ],
    [
        "ASSOCIATES OF CAPE COD",
        "ASSOCIATES OF CAPE COD"
    ],
    [
        "BEMOTEC GMBH",
        "BEMOTEC"
    ],
    [
        "BIOCOAT, INC. - HORSHAM 2",
        "BIOCOAT"
    ],
    [
        "BODYCOTE H.I.P. LTD",
        "BODYCOTE"
    ],
    [
        "CAM BIOCERAMICS",
        "CAM BIOCERAMICS"
    ],
    [
        "CARAGH PRECISION",
        "CARAGH PRECISION"
    ],
    [
        "CARL ZEISS INC",
        "CARL ZEISS"
    ],
    [
        "CARL ZEISS INDUSTRIELLE",
        "CARL ZEISS"
    ],
    [
        "CARL ZEISS UK LTD",
        "CARL ZEISS"
    ],
    [
        "CARL ZEISS, INC.",
        "CARL ZEISS"
    ],
    [
        "CARL ZEISS SAS",
        "CARL ZEISS"
    ],
    [
        "CARL ZEISS IMT CORP",
        "CARL ZEISS"
    ],
    [
        "COMPUTROL",
        "COMPUTROL"
    ],
    [
        "D & R PRODUCTS CO., INC.",
        "D & R PRODUCTS"
    ],
    [
        "DYMAX CORPORATION - TORRI",
        "DYMAX CORPORATION"
    ],
    [
        "EDEN SPINE",
        "EDEN SPINE"
    ],
    [
        "ENVIRONMENTAL LABS",
        "ENVIRONMENTAL LABS"
    ],
    [
        "FLEXTRONICS INTERNATIONAL EURO",
        "FLEX"
    ],
    [
        "HAUPTZOLLAMT KIEL",
        "HAUPTZOLLAMT KIEL"
    ],
    [
        "HIMED",
        "HIMED"
    ],
    [
        "HOWMEDICA INTERNATIONAL D.DE R.L.",
        "HOWMEDICA INTERNATIONAL D.DE R.L."
    ],
    [
        "JABIL SHANGHAI",
        "JABIL SHANGHAI"
    ],
    [
        "JOHNSON & JOHNSON MEDICAL",
        "JOHNSON & JOHNSON"
    ],
    [
        "KVINC",
        "KVINC"
    ],
    [
        "LISI MEDICAL ORTHOPAEDICS",
        "LISI MEDICAL ORTHOPAEDICS"
    ],
    [
        "VESTA - CORONA",
        "LUBRIZOL"
    ],
    [
        "VESTA - FRANKLIN",
        "LUBRIZOL"
    ],
    [
        "LUCIDEON",
        "LUCIDEON"
    ],
    [
        "LUCIDEON LTD",
        "LUCIDEON"
    ],
    [
        "MACK MOLDING CO INC",
        "MACK MOLDING"
    ],
    [
        "MACK MOLDING COMPANY INC",
        "MACK MOLDING"
    ],
    [
        "MEDICAL DEVICE SERVICES",
        "MEDICAL DEVICE SERVICES"
    ],
    [
        "MEDIPACK AG",
        "MEDIPACK"
    ],
    [
        "MKV GMBH",
        "MKV"
    ],
    [
        "NIUTEC AG",
        "NIUTEC"
    ],
    [
        "NULL",
        "NULL"
    ],
    [
        "NYPRO INC",
        "NYPRO"
    ],
    [
        "OERLIKON METCO EUROPE",
        "OERLIKON METCO"
    ],
    [
        "OERLIKON METCO US INC",
        "OERLIKON METCO"
    ],
    [
        "OLYMPUS AMERICA",
        "OLYMPUS"
    ],
    [
        "ORCHID ORTHOPEDIC SOLUTIONS",
        "ORCHID ORTHOPEDIC SOLUTIONS"
    ],
    [
        "ORCHID ORTHOPEDICS SOL",
        "ORCHID ORTHOPEDICS SOLUTIONS"
    ],
    [
        "PARAGON MEDICAL DEVICE",
        "PARAGON MEDICAL"
    ],
    [
        "PARAGON MEDICAL INC",
        "PARAGON MEDICAL"
    ],
    [
        "PARAGON MEDICAL INC.",
        "PARAGON MEDICAL"
    ],
    [
        "PARAGON MEDICAL, INC.",
        "PARAGON MEDICAL"
    ],
    [
        "PARAGON MEDICAL, INC. VAT",
        "PARAGON MEDICAL"
    ],
    [
        "PARAGON MEDICAL",
        "PARAGON MEDICAL"
    ],
    [
        "PARAGON SIECHNICE SP.Z.O.O.",
        "PARAGON SIECHNICE"
    ],
    [
        "PM GROUP",
        "PM GROUP"
    ],
    [
        "POLYONE PUERTO RICO",
        "POLYONE"
    ],
    [
        "R.H. CROSS ENTERPRISE'S INC",
        "R H CROSS ENTERPRISES"
    ],
    [
        "R.H. CROSS ENTERPRISES,IN",
        "R H CROSS ENTERPRISES"
    ],
    [
        "R.H. CROSS ENTREL000",
        "R H CROSS ENTERPRISES"
    ],
    [
        "REHATEC",
        "REHATEC"
    ],
    [
        "SECO TOOLS AG",
        "SECO TOOLS"
    ],
    [
        "SMITHSTOWN LIGHT ENGIN.",
        "SMITHSTOWN LIGHT ENGINEERING"
    ],
    [
        "SMITHSTOWN LIGHT ENGINEERING",
        "SMITHSTOWN LIGHT ENGINEERING"
    ],
    [
        "SMITHSTOWN LIGHT ENG.LTD.",
        "SMITHSTOWN LIGHT ENGINEERING"
    ],
    [
        "SPECIALTY COATING SYSTEMS LTD",
        "SPECIALTY COATING SYSTEMS"
    ],
    [
        "STAMA MASCHINENFABRIK GMBH",
        "STAMA MASCHINENFABRIK"
    ],
    [
        "STERIPACK MEDICAL POLAND",
        "STERIPACK CONTRACT MANUFACTURING"
    ],
    [
        "SYMMETRY MEDICAL HILLBURN",
        "SYMMETRY MEDICAL"
    ],
    [
        "SYMMETRY MEDICAL POLYVAC",
        "SYMMETRY MEDICAL"
    ],
    [
        "SYMMETRY MEDICAL SH(EUR)",
        "SYMMETRY MEDICAL"
    ],
    [
        "SYNERGY HEALTH RADEBERG",
        "SYNERGY HEALTH"
    ],
    [
        "T&T PERCISION",
        "T & T PRECISION"
    ],
    [
        "T AND T PRECISION LTD",
        "T & T PRECISION"
    ],
    [
        "T & T  PRECISION LIMITED",
        "T & T PRECISION"
    ],
    [
        "TAKUMI PRECISION ENG LTD",
        "TAKUMI PRECISION ENGINEERING"
    ],
    [
        "TFX MEDICAL WIRE PRODUCTS",
        "TFX MEDICAL WIRE PRODUCTS"
    ],
    [
        "TORNOS SA",
        "TORNOS"
    ],
    [
        "TÜV PRODUCT SERVICE GMBH",
        "TUV PRODUCT SERVICE"
    ],
    [
        "VARTA MICROBATTERY",
        "VARTA MICROBATTERY"
    ],
    [
        "VIANT MEDICAL INC",
        "VIANT MEDICAL"
    ],
    [
        "VITA NEEDLE",
        "VITA NEEDLE"
    ],
    [
        "VWR INTERNATIONAL S DE RL DE CV",
        "VWR INTERNATIONAL"
    ],
    [
        "VWR INTERNATIONAL",
        "VWR INTERNATIONAL"
    ],
    [
        "WYTECH - RAHWAY",
        "WYTECH RAHWAY"
    ],
    [
        "CHIRON WERKE GMBH & CO. KG",
        "CHIRON WERKE"
    ],
    [
        "INDEX-WERKE GMBH & CO. KG",
        "INDEX WERKE"
    ],
    [
        "INDEX-WERKE GMBH&CO.KG",
        "INDEX WERKE"
    ],
    [
        "APPLE RUBBER PREL000",
        "APPLE RUBBER"
    ],
    [
        "APPLE RUBBER PRODUCTS,INC",
        "APPLE RUBBER"
    ],
    [
        "DSP PRODUCTS INC",
        "DSP PRODUCTS"
    ],
    [
        "GRAINGER CARIBE INC",
        "GRAINGER"
    ],
    [
        "CROOM PRECISION MEDICAL",
        "CROOM PRECISION MEDICAL"
    ],
    [
        "PRECISE COMPONENTS & TOOL DESIGN INC",
        "PRECISE COMPONENTS & TOOL DESIGN"
    ],
    [
        "BENCHMARK ELECTRONICS",
        "BENCHMARK ELECTRONICS"
    ],
    [
        "BENCHMARK ELECTRONICS - W",
        "BENCHMARK ELECTRONICS"
    ],
    [
        "BENCHMARK ELECTRONICS INC",
        "BENCHMARK ELECTRONICS"
    ],
    [
        "ANGSTROM TECHNOLOGY",
        "ANGSTROM TECHNOLOGY"
    ],
    [
        "CONCARE, INC. *",
        "CONCARE"
    ],
    [
        "DUKE REALTY LIMITED PARTNERSHIP",
        "DUKE REALTY PARTNERSHIP"
    ],
    [
        "GUENTER MECKLENBURG",
        "GUENTER MECKLENBURG"
    ],
    [
        "HCH UMWELT GMBH",
        "HCH UMWELT"
    ],
    [
        "HERMANN DROEGEMUELLER",
        "HERMANN DROEGEMUELLER"
    ],
    [
        "SCHMIEDER. DAU. ARCHITEKTEN.",
        "SCHMIEDER DAU ARCHITEKTEN"
    ],
    [
        "WM. TONYAN AND SONS, INC.",
        "WM TONYAN AND SONS"
    ],
    [
        "BAUDLER GMBH",
        "BAUDLER"
    ],
    [
        "CAPITOL INDUSTRIES, INC.",
        "CAPITOL INDUSTRIES"
    ],
    [
        "CARRIG ELM CONSTRUCTION",
        "CARRIG ELM CONSTRUCTION"
    ],
    [
        "CONTRACT OFFICE GROUP",
        "CONTRACT OFFICE GROUP"
    ],
    [
        "CORNERSTONE CONST MGT INC",
        "CORNERSTONE CONST MGT"
    ],
    [
        "FRIEDRICH HELBIG STAHLBETON-",
        "FRIEDRICH HELBIG STAHLBETON"
    ],
    [
        "GRAFRIED BAUUNTERNEHMEN GMBH",
        "GRAFRIED BAUUNTERNEHMEN"
    ],
    [
        "MIKE FITZPATRICK & SON",
        "MIKE FITZPATRICK & SON"
    ],
    [
        "PROTECS",
        "PROTECS"
    ],
    [
        "RUD.OTTO MEYER TECHNIK",
        "RUD OTTO MEYER TECHNIK"
    ],
    [
        "TDINDUSTRIES INC",
        "TDINDUSTRIES"
    ],
    [
        "TEMEKA",
        "TEMEKA"
    ],
    [
        "W.BARGHOLZ TIEFBAU GMBH",
        "W.BARGHOLZ TIEFBAU"
    ],
    [
        "METALLBAU NORD GMBH",
        "METALLBAU NORD"
    ],
    [
        "METALLBAU ZEH",
        "METALLBAU ZEH"
    ],
    [
        "LIGHTSOURCE UNLIMITED INC *",
        "LIGHTSOURCE UNLIMITED"
    ],
    [
        "BKW ISP AG",
        "BKW ISP"
    ],
    [
        "EIMECKE ELEKTROTECHNIK GMBH",
        "EIMECKE ELEKTROTECHNIK"
    ],
    [
        "FEYEN ZYLSTRA LLC",
        "FEYEN ZYLSTRA"
    ],
    [
        "MSP ELECTRIC INC",
        "MSP ELECTRIC"
    ],
    [
        "ALTHOFF INDUSTRIES INC *",
        "ALTHOFF INDUSTRIES"
    ],
    [
        "HILL YORK SERVICE CORPORATION",
        "HILL YORK SERVICE"
    ],
    [
        "MECHANICAL SERVICE & SYST",
        "MECHANICAL SERVICE & SYST"
    ],
    [
        "SQ  MASTER TECH SERVICES",
        "SQ MASTER TECH SERVICES"
    ],
    [
        "BUSAM GMBH",
        "BUSAM"
    ],
    [
        "CHEMICLENE INC",
        "CHEMICLENE"
    ],
    [
        "HARTWIG PLUMBING",
        "HARTWIG PLUMBING"
    ],
    [
        "HURST INC",
        "HURST"
    ],
    [
        "INFINITE THERMAL *",
        "INFINITE THERMAL"
    ],
    [
        "KLEEN AIR SERVICE CORP. *",
        "KLEEN AIR SERVICE"
    ],
    [
        "MITSUBISHI",
        "MITSUBISHI"
    ],
    [
        "PERFORMANCE AIR FILTERS",
        "PERFORMANCE AIR FILTERS"
    ],
    [
        "SCHRAMM GMBH",
        "SCHRAMM"
    ],
    [
        "SHERMAN MECHANICAL, INC. *",
        "SHERMAN MECHANICAL"
    ],
    [
        "SUNSTATE AIRCONDITIONING INC",
        "SUNSTATE AIRCONDITIONING"
    ],
    [
        "THOMAS GRUBB SALES AND SE",
        "THOMAS GRUBB SALES AND SE"
    ],
    [
        "THOMAS L GRUBB SALES",
        "THOMAS L GRUBB SALES"
    ],
    [
        "TRANE PUERTO RICO INC",
        "TRANE"
    ],
    [
        "TRANE PUERTO RICO",
        "TRANE"
    ],
    [
        "FACILITY SOLUTIOINS GROUP",
        "FACILITY SOLUTIOINS GROUP"
    ],
    [
        "FORESITE ELECTRIC, INC. *",
        "FORESITE ELECTRIC"
    ],
    [
        "GBL CONSTRUCTION LTD",
        "GBL CONSTRUCTION"
    ],
    [
        "HENRY J LYONS ARCHITECTS",
        "HENRY J LYONS ARCHITECTS"
    ],
    [
        "ISOLATION TECHNOLOGY LTD.",
        "ISOLATION TECHNOLOGY"
    ],
    [
        "LET MESCHEDE",
        "LET MESCHEDE"
    ],
    [
        "PRO SERVICES INC",
        "PRO SERVICES"
    ],
    [
        "PRO SERVICES INC.",
        "PRO SERVICES"
    ],
    [
        "SCI AQUITAINE",
        "SCI AQUITAINE"
    ],
    [
        "ALLIED AUTOMATION",
        "ALLIED"
    ],
    [
        "ALLIED ELECTRONICS",
        "ALLIED"
    ],
    [
        "ALLIED ELECTRONICS *",
        "ALLIED"
    ],
    [
        "ALLIED ELECTRONICS INC",
        "ALLIED"
    ],
    [
        "ALLIED ELECTRONICS INC.",
        "ALLIED"
    ],
    [
        "COLIMATIC",
        "COLIMATIC"
    ],
    [
        "DORMKABA DEUTSCHLAND GMBH",
        "DORMKABA"
    ],
    [
        "PROCESS AUTOMATION SOLUTI",
        "PROCESS AUTOMATION SOLUTION"
    ],
    [
        "SIEMENS AG",
        "SIEMENS"
    ],
    [
        "SIEMENS SCHWEIZ AG",
        "SIEMENS"
    ],
    [
        "SILVERSTONE",
        "SILVERSTONE"
    ],
    [
        "CITY STAR SERVICES, INC",
        "CITY STAR SERVICES"
    ],
    [
        "EVOQUA WATER TECHNOLOGIES",
        "EVOQUA WATER TECHNOLOGIES"
    ],
    [
        "EVOQUA WATER TECHNOLOGIES LLC",
        "EVOQUA WATER TECHNOLOGIES"
    ],
    [
        "EVOQUA WATER TECHNOLOGIES *",
        "EVOQUA WATER TECHNOLOGIES"
    ],
    [
        "HOLLAND APPLIED *",
        "HOLIDAY STATIONTORE 425"
    ],
    [
        "HOLIDAY STATIONTORE 425",
        "HOLIDAY STATIONTORE 425"
    ],
    [
        "CITY OF PORTAGE",
        "CITY OF PORTAGE"
    ],
    [
        "MACH ENGINEERING PROJECT",
        "MACH ENGINEERING PROJECT"
    ],
    [
        "PUERTO RICO INDUSTRIAL",
        "PUERTO RICO INDUSTRIAL"
    ],
    [
        "WATT ENTERPRISES INC",
        "WATT ENTERPRISES"
    ],
    [
        "ARAMARK GMBH RS",
        "ARAMARK"
    ],
    [
        "FIDFUND MANAGEMENT SA",
        "FIDFUND MANAGEMENT"
    ],
    [
        "ICON NEWCO POOL 5 GA/FL LLC",
        "ICON NEWCO POOL 5 GA FL"
    ],
    [
        "PALSCHER LAKELAND LLC-FL RENT",
        "PALSCHER LAKELAND FL RENT"
    ],
    [
        "RUTHVEN BUSINESS PARK II,LLC",
        "RUTHVEN BUSINESS PARK II"
    ],
    [
        "ZSW AG (GLATZFELDER AG)",
        "ZSW AG (GLATZFELDER AG)"
    ],
    [
        "AIRFLOW SERVICES",
        "AIRFLOW SERVICES"
    ],
    [
        "AIRGAS USA, LLC",
        "AIRGAS"
    ],
    [
        "ALL AMERICAN PORTABLE AIR LLC",
        "ALL AMERICAN PORTABLE AIR"
    ],
    [
        "ALL FLORIDA WATER",
        "ALL FLORIDA WATER"
    ],
    [
        "AMT SCHREVENBORN FⁿR",
        "AMT SCHREVENBORN FⁿR"
    ],
    [
        "AQUA CHILL",
        "AQUA CHILL"
    ],
    [
        "ARAMARK PROPERTY SERVICES",
        "ARAMARK"
    ],
    [
        "AROMA COFFEE SERVICE, INC.",
        "AROMA COFFEE SERVICE"
    ],
    [
        "US BANK EQUIPMENT FINANCE",
        "BANK EQUIPMENT FINANCE"
    ],
    [
        "BASIS-KNOW-HOW AG",
        "BASIS KNOW HOW"
    ],
    [
        "BAY CORPORATION",
        "BAY CORPORATION"
    ],
    [
        "BERMAN BEDDING",
        "BERMAN BEDDING"
    ],
    [
        "BIOHITECH AMERICA LLC",
        "BIOHITECH"
    ],
    [
        "BMW (SCHWEIZ) AG",
        "BMW"
    ],
    [
        "BOSCH SICHERHEITSSYSTEME GMBH",
        "BOSCH SICHERHEITSSYSTEME"
    ],
    [
        "BUSINESS CENTER",
        "BUSINESS CENTER"
    ],
    [
        "BWT AQUA AG",
        "BWT AQUA"
    ],
    [
        "C AXIS PR INC",
        "C AXIS PR"
    ],
    [
        "C.A.V.S.",
        "C.A.V.S."
    ],
    [
        "CAPITOL PROPERTIES SIX LC",
        "CAPITOL PROPERTIES SIX"
    ],
    [
        "CASCINATION AG",
        "CASCINATION"
    ],
    [
        "CUNNANES FORKLIFT HIRE,",
        "CUNNANES FORK LIFT HIRE"
    ],
    [
        "CWS-BOCO SUISSE SA",
        "CWS BOCO"
    ],
    [
        "DIENER ELECTRONIC GMBH & CO.KG",
        "DIENER ELECTRONIC"
    ],
    [
        "DIGIKEY CORP",
        "DIGIKEY"
    ],
    [
        "DOKAL HOLDING AG",
        "DOKAL HOLDING"
    ],
    [
        "EINWOHNERGEMEINDE SELZACH",
        "EINWOHNERGEMEINDE SELZACH"
    ],
    [
        "EMKAY INC",
        "EMKAY"
    ],
    [
        "EMPIRE COOLER SERVICE INC",
        "EMPIRE COOLER SERVICE"
    ],
    [
        "EQUIPMENT DEPOT OF ILL. *",
        "EQUIPMENT DEPOT OF ILL"
    ],
    [
        "ERAPA (U.K) LTD.,",
        "ERAPA"
    ],
    [
        "EXTRASPACE SOLUTIONS LTD",
        "EXTRASPACE SOLUTIONS"
    ],
    [
        "FABRICAS Y BODEGAS SC",
        "FABRICAS Y BODEGAS"
    ],
    [
        "FALLER STAPLER GMBH",
        "FALLER STAPLER"
    ],
    [
        "FENWICK LEASE SA",
        "FENWICK LEASE"
    ],
    [
        "FENWICK LINDE",
        "FENWICK LINDE"
    ],
    [
        "FIRAC",
        "FIRAC"
    ],
    [
        "FLW OF PA INC",
        "FLW OF PA"
    ],
    [
        "FPR HOLDINGS, LP",
        "FPR HOLDINGS"
    ],
    [
        "G&S WAREHOUSING & STORAGE",
        "G&S WAREHOUSING & STORAGE"
    ],
    [
        "GALVANO WULLIMANN AG",
        "GALVANO WULLIMANN"
    ],
    [
        "GARDEN STATE OFFICE",
        "GARDEN STATE OFFICE"
    ],
    [
        "GAS ANALYSIS SERVICES",
        "GAS ANALYSIS SERVICES"
    ],
    [
        "UTZ GEORG AG",
        "GEORG UTZ"
    ],
    [
        "GRUNDSTUECKSGEMEINSCHAFT",
        "GRUNDSTUECKSGEMEINSCHAFT"
    ],
    [
        "HEIGHT FOR HIRE LTD.,",
        "HEIGHT FOR HIRE"
    ],
    [
        "HERMANN TITSCHKUS KG",
        "HERMANN TITSCHKUS"
    ],
    [
        "HIREBOTICS LLC",
        "HIREBOTICS"
    ],
    [
        "HOPATCONG RIGGING INC",
        "HOPATCONG RIGGING"
    ],
    [
        "HSS TOOL HIRE",
        "HSS TOOL HIRE"
    ],
    [
        "HW MACHINE TOOLS LTD.",
        "HW MACHINE TOOLS"
    ],
    [
        "IDA IRELAND",
        "IDA"
    ],
    [
        "IMAGEHOUSE DESIGN",
        "IMAGEHOUSE DESIGN"
    ],
    [
        "IMPACT BOREL SARL",
        "IMPACT BOREL"
    ],
    [
        "IRISH ESTATES MGT",
        "IRISH ESTATES MGT"
    ],
    [
        "IRISH MEDICAL DEVICES",
        "IRISH MEDICAL DEVICES"
    ],
    [
        "JA LOGISTICS *",
        "JA LOGISTICS"
    ],
    [
        "KENNEDYS",
        "KENNEDYS"
    ],
    [
        "LANDMARK 12, LLC",
        "LANDMARK 12"
    ],
    [
        "LEASEPLAN FLEET MANAGEMEN",
        "LEASEPLAN FLEET MANAGEMENT"
    ],
    [
        "LEASEWAY OF PR INC",
        "LEASEWAY OF PR INC"
    ],
    [
        "LIBRA INDUSTRIES INC",
        "LIBRA INDUSTRIES"
    ],
    [
        "LIFT TRUCK PARTS INC",
        "LIFT TRUCK PARTS"
    ],
    [
        "LIFT WORKS, INC.",
        "LIFT WORKS"
    ],
    [
        "LINDE LANSING",
        "LINDE LANSING"
    ],
    [
        "LUTTEROTH CAMOU SALVADOR ALBERTO",
        "LUTTEROTH CAMOU SALVADOR ALBERTO"
    ],
    [
        "MAIL FINANCE SAS",
        "MAIL FINANCE"
    ],
    [
        "MANUF. CARTIER HORLOGERIE",
        "MANUF CARTIER HORLOGERIE"
    ],
    [
        "MERITEC",
        "MERITEC"
    ],
    [
        "METALWORX INC",
        "METALWORX"
    ],
    [
        "MIDLETON PARK HOTEL",
        "MIDLETON PARK HOTEL"
    ],
    [
        "MIKE ALBERT LEASING *",
        "MIKE ALBERT LEASING"
    ],
    [
        "MOUNT OLYMPUS WATERS INC.",
        "MOUNT OLYMPUS WATERS"
    ],
    [
        "NOT ASSIGNED",
        "NOT ASSIGNED"
    ],
    [
        "NOVADAQ TECHNOLOGIES",
        "NOVADAQ TECHNOLOGIES"
    ],
    [
        "NURISSA SA",
        "NURISSA"
    ],
    [
        "NYPRO HLTHCR BAJA INC",
        "NYPRO HEALTHCARE BAJA"
    ],
    [
        "OBERLIN FILTER LTD",
        "OBERLIN FILTER"
    ],
    [
        "OMEGA ENGINEERING, INC",
        "OMEGA ENGINEERING"
    ],
    [
        "OXSO AG",
        "OXSO"
    ],
    [
        "PAPREC",
        "PAPREC"
    ],
    [
        "PORTAKABIN ALLSPACE LTD",
        "PORTAKABIN ALLSPACE"
    ],
    [
        "PORTOKABIN ALLSPACE LTD",
        "PORTOKABIN ALLSPACE"
    ],
    [
        "RAECO RENTS, LLC",
        "RAECO RENTS"
    ],
    [
        "ROSE PROPERTY SERVICES",
        "ROSE PROPERTY SERVICES"
    ],
    [
        "ROSS MIXING INC.",
        "ROSS MIXING"
    ],
    [
        "RUSH TRUCK LEASING, INC",
        "RUSH TRUCK LEASING"
    ],
    [
        "SAFESITE INC",
        "SAFESITE"
    ],
    [
        "SCHMID TEXTILREWASHING AG",
        "SCHMID TEXTILREWASHING"
    ],
    [
        "SILVIA SANDER-THOMAS",
        "SILVIA SANDER THOMAS"
    ],
    [
        "STADT MÜHLHEIM",
        "STADT MÜHLHEIM"
    ],
    [
        "STEKATO GMBH",
        "STEKATO"
    ],
    [
        "STILL GMBH",
        "STILL"
    ],
    [
        "STREIT LEASING GMBH & CO.",
        "STREIT LEASING"
    ],
    [
        "STWE",
        "STWE"
    ],
    [
        "S÷REN MEYER FRIESE",
        "S÷REN MEYER FRIESE"
    ],
    [
        "THE FENCE 4 RENT CO",
        "THE FENCE 4 RENT"
    ],
    [
        "TOI TOI & DIXI SANITAERSYT.",
        "TOI TOI & DIXI SANITAERSYT"
    ],
    [
        "TOWER COMPACTOR RENTALS LLC",
        "TOWER COMPACTOR RENTALS LLC"
    ],
    [
        "TQ-SYSTEMS GMBH",
        "TQ SYSTEMS"
    ],
    [
        "TREATY PLANT & TOOL HIRE",
        "TREATY PLANT & TOOL HIRE"
    ],
    [
        "TREVOR HARRIS SPEEDLINK",
        "TREVOR HARRIS SPEEDLINK"
    ],
    [
        "TYCO ELECTRONICS AMP",
        "TYCO ELECTRONICS AMP"
    ],
    [
        "UCC ASSERT FLAME LAB",
        "UCC ASSERT FLAME LAB"
    ],
    [
        "UNDERWRITERS LABORATORIES",
        "UNDERWRITERS LABORATORIES"
    ],
    [
        "UNITED RENTALS (NORTH AMERICAN) INC",
        "UNITED RENTALS"
    ],
    [
        "US BANK NATIONAL ASSOCIAT",
        "US BANK NATIONAL ASSOCIATION"
    ],
    [
        "VELCOR LEASING CORPORATION",
        "VELCOR LEASING"
    ],
    [
        "VISUAL WORK PLACE LLC",
        "VISUAL WORK PLACE"
    ],
    [
        "VOGELSANG AG",
        "VOGELSANG"
    ],
    [
        "WALKER'S TEAM AG",
        "WALKER'S TEAM AG"
    ],
    [
        "WELL-PACK AG",
        "WELL PACK"
    ],
    [
        "WESTFALEN AG",
        "WESTFALEN"
    ],
    [
        "WIELGUS PRODUCT MODELS INC.",
        "WIELGUS PRODUCT MODELS"
    ],
    [
        "PUERTO RICO WIRE COMPANY",
        "WIRE"
    ],
    [
        "WOEHLK CONTACTLINSEN GMBH",
        "WOEHLK CONTACTLINSEN"
    ],
    [
        "WÜRZBURGER GMBH",
        "WÜRZBURGER"
    ],
    [
        "XTRA LEASE, INC.",
        "XTRA LEASE"
    ],
    [
        "ARAMARK CORP",
        "ARAMARK"
    ],
    [
        "ARAMARK GMBH",
        "ARAMARK"
    ],
    [
        "CANTEEN REFRESHMENT SERVICES",
        "CANTEEN REFRESHMENT SERVICES"
    ],
    [
        "COMPASS GROUP",
        "COMPASS GROUP"
    ],
    [
        "COMPASS GROUP USA INC",
        "COMPASS GROUP"
    ],
    [
        "CREATIVE DINING SERVICE",
        "CREATIVE DINING SERVICE"
    ],
    [
        "MILLENNIUM REFRESHMENT SERVICES INC",
        "MILLENNIUM REFRESHMENT SERVICES"
    ],
    [
        "PGI SERVICES",
        "PGI SERVICES"
    ],
    [
        "SODEXO IRELAND",
        "SODEXO"
    ],
    [
        "SV (SCHWEIZ) AG",
        "SV (SCHWEIZ) AG"
    ],
    [
        "APLEONA HSG LIMITED",
        "APLEONA HSG"
    ],
    [
        "COLLINS INDUSTRIAL SERVICES IN",
        "COLLINS INDUSTRIAL SERVICES"
    ],
    [
        "FLEXIM INTERNATIONAL SA",
        "FLEXIM"
    ],
    [
        "FLEXIM DEUTSCHLAND GMBH",
        "FLEXIM"
    ],
    [
        "FLEXIM SAS",
        "FLEXIM"
    ],
    [
        "HONEGGER AG",
        "HONEGGER"
    ],
    [
        "ISS FACILITY MANAGEMENT",
        "ISS FACILITY SERVICES"
    ],
    [
        "ISS FACILITY SERVICES",
        "ISS FACILITY SERVICES"
    ],
    [
        "ISS FACILITY SERVICES, INC",
        "ISS FACILITY SERVICES"
    ],
    [
        "ABM FACILITY SERVICES INC",
        "ABM FACILITY SERVICES"
    ],
    [
        "BIO KLEEN PRODUCTS INC",
        "BIO KLEEN PRODUCTS"
    ],
    [
        "CINTAS FIRE LOC F32, INC.",
        "CINTAS"
    ],
    [
        "CINTAS FIRST AID & SAFETY, INC.-FL",
        "CINTAS"
    ],
    [
        "CINTAS",
        "CINTAS"
    ],
    [
        "CINTAS CORP",
        "CINTAS"
    ],
    [
        "CINTAS 1ST AID AND SAFETY",
        "CINTAS"
    ],
    [
        "CINTAS CORPORATION #180",
        "CINTAS"
    ],
    [
        "CINTAS FIRE PROTECTION",
        "CINTAS"
    ],
    [
        "CINTAS FIRST AID & SAFETY",
        "CINTAS"
    ],
    [
        "CINTAS FIRST AID & SAFETY 0343",
        "CINTAS"
    ],
    [
        "GEBÄUDEREINIGUNG T. ERMIS",
        "GEBÄUDEREINIGUNG T ERMIS"
    ],
    [
        "NOONAN SERVICES GROUP",
        "NOONAN SERVICES GROUP"
    ],
    [
        "NOONAN SERVICES GROUP LTD",
        "NOONAN SERVICES GROUP"
    ],
    [
        "0",
        "N/A"
    ],
    [
        "ABM JANITORIAL SERVICES",
        "ABM FACILITY SERVICES"
    ],
    [
        "ALSCO, INC. - SALT LAKE C",
        "ALSCO"
    ],
    [
        "BECKER GEBÄUDESERVICE GMBH",
        "BECKER GEBÄUDESERVICE"
    ],
    [
        "BENCHMARK PRODUCTS INC",
        "BENCHMARK PRODUCTS"
    ],
    [
        "BRILLIANT GENERAL MAINTENANCE",
        "BRILLIANT GENERAL MAINTENANCE"
    ],
    [
        "C. STODDARD & SONS, INC",
        "C STODDARD & SONS"
    ],
    [
        "CARBON CHEMICALS GROUP",
        "CARBON CHEMICALS GROUP"
    ],
    [
        "CELTIC WATERCARE SYSTEMS",
        "CELTIC WATERCARE SYSTEMS"
    ],
    [
        "CLEAN EARTH",
        "CLEAN EARTH"
    ],
    [
        "COMPUCAL CALIBRATION SOL",
        "COMPUCAL CALIBRATION SOL"
    ],
    [
        "DASTEX GMBH",
        "DASTEX"
    ],
    [
        "DYCEM",
        "DYCEM"
    ],
    [
        "ENVIRO-CLEAN SERVICES INC",
        "ENVIRO CLEAN SERVICES"
    ],
    [
        "HADAWAY AND SONS FACILITY",
        "HADAWAY AND SONS FACILITY"
    ],
    [
        "HOSPITAL NETWORK HEALTHCARE SE",
        "HOSPITAL NETWORK HEALTHCARE"
    ],
    [
        "IMPULSORA COMERCIAL Y SERVICIOS INDUSTRIALES SA DE CV",
        "IMPULSORA COMERCIAL Y SERVICIOS INDUSTRIALES"
    ],
    [
        "INITIAL TEXTIL SERVICE GMB&CO KG",
        "INITIAL TEXTIL SERVICE"
    ],
    [
        "INSUMOS",
        "INSUMOS"
    ],
    [
        "IRISH LAMP RECYCLING",
        "IRISH LAMP RECYCLING"
    ],
    [
        "J AND M DEPOT INC",
        "J AND M DEPOT"
    ],
    [
        "NEW YORK WIPING & INDUSTRIAL PRODUCTS",
        "NEW YORK WIPING & INDUSTRIAL PRODUCTS"
    ],
    [
        "NICHOLS",
        "NICHOLS"
    ],
    [
        "POWER MAINTENANCE SERVICE *",
        "POWER MAINTENANCE SERVICE"
    ],
    [
        "PRIME JANITORIAL SERVICES CORP",
        "PRIME JANITORIAL SERVICES"
    ],
    [
        "RECOLECTORA",
        "RECOLECTORA"
    ],
    [
        "RECOLECTORA DE DESECHOS Y RESIDUOS KING KONG",
        "RECOLECTORA DESECHOS Y RESIDUOS KING KONG"
    ],
    [
        "RECOLECTORA DE DESECHOS Y RESIDUOS KING KONG SA DE CV",
        "RECOLECTORA DESECHOS Y RESIDUOS KING KONG"
    ],
    [
        "RECYCLING AND COMPACTING",
        "RECYCLING AND COMPACTING"
    ],
    [
        "REHAB RECYCLE",
        "REHAB RECYCLE"
    ],
    [
        "RESTO JANITOR SERVICE",
        "RESTO JANITOR SERVICE"
    ],
    [
        "RILTA ENVIRONMENTAL LTD",
        "RILTA ENVIRONMENTAL"
    ],
    [
        "SRCL LTD T/A TRANSAFE",
        "SRCL T A TRANSAFE"
    ],
    [
        "TAC",
        "TAC INSUMOS INDUSTRIALES"
    ],
    [
        "THE FAIR BANK CORPORATION",
        "THE FAIR BANK"
    ],
    [
        "TOGATEX",
        "TOGATEX"
    ],
    [
        "UNIVERSAL PROTECTION SERVICE LP GUARDSMARK",
        "UNIVERSAL PROTECTION SERVICE LP GUARDSMARK"
    ],
    [
        "VAUPELL MOLDING & TOOLING",
        "VAUPELL MOLDING & TOOLING"
    ],
    [
        "WAEMSA",
        "WAEMSA"
    ],
    [
        "EIDERHEIM",
        "EIDERHEIM"
    ],
    [
        "S & T LAWN SERVICE",
        "S & T LAWN SERVICE"
    ],
    [
        "S & T LAWN SERVICE, INC",
        "S & T LAWN SERVICE"
    ],
    [
        "SEBERT LANDSCAPING CO.",
        "SEBERT LANDSCAPING"
    ],
    [
        "TEAM GRUEN FURTNER",
        "TEAM GRUEN FURTNER"
    ],
    [
        "VANDER VEEN EXCAVATING",
        "VANDER VEEN EXCAVATING"
    ],
    [
        "CBI SECURITY SERVICES",
        "CBI SECURITY SERVICES"
    ],
    [
        "FOTA ISLAND SERVICES LTD,",
        "FOTA SERVICES"
    ],
    [
        "KNIGHT WATCH INC",
        "KNIGHT WATCH"
    ],
    [
        "KNIGHT WATCH, INC.",
        "KNIGHT WATCH"
    ],
    [
        "KWS KIEL",
        "KWS KIEL"
    ],
    [
        "SECURITAS SECURITY",
        "SECURITAS SECURITY"
    ],
    [
        "SECURITY TACTICAL FORCES",
        "SECURITY TACTICAL FORCES"
    ],
    [
        "ZIEMANN SICHERHEIT GMBH",
        "ZIEMANN SICHERHEIT"
    ],
    [
        "TOVAR SNOW PROFESSIONALS, INC. *",
        "TOVAR SNOW PROFESSIONALS"
    ],
    [
        "3 D PCI",
        "3 D PCI"
    ],
    [
        "3D SYSTEMS FRANCE",
        "3D SYSTEMS"
    ],
    [
        "3D SYSTEMS GMBH",
        "3D SYSTEMS"
    ],
    [
        "A.D.S. (ADS GUILLEUX)",
        "A D S"
    ],
    [
        "A2SI",
        "A2SI"
    ],
    [
        "ACSYS LASERTECHNIK GMBH",
        "ACSYS LASERTECHNIK"
    ],
    [
        "NILFISK ADVANCE SAS",
        "ADVANCE BY NILFISK"
    ],
    [
        "AIRCOM",
        "AIRCOM"
    ],
    [
        "ALLEMAND FRÈRES SA",
        "ALLEMAND FRÈRES"
    ],
    [
        "UNITED STATES ALLIANCE",
        "ALLIANCE"
    ],
    [
        "ALPIQ INTEC SCHWEIZ AG",
        "ALPIQ INTEC"
    ],
    [
        "ALTISOL",
        "ALTISOL"
    ],
    [
        "AMCOR FLEXIBLES",
        "AMCOR"
    ],
    [
        "AMCOR FLEXIBLES SPS",
        "AMCOR"
    ],
    [
        "AMIS SAS",
        "AMIS"
    ],
    [
        "AMPERE ALOYS SA",
        "AMPERE ALOYS"
    ],
    [
        "APS FRANCE",
        "APS"
    ],
    [
        "ARBURG LTD",
        "ARBURG"
    ],
    [
        "ARBURG GMBH + CO KG",
        "ARBURG"
    ],
    [
        "ARBURG SAS",
        "ARBURG"
    ],
    [
        "ARBURG INC",
        "ARBURG"
    ],
    [
        "ARBURG, INC.",
        "ARBURG"
    ],
    [
        "ARO TECHNOLOGIES AG",
        "ARO TECHNOLOGIES"
    ],
    [
        "ARP METAL WORKS",
        "ARP"
    ],
    [
        "ARP SCHWEIZ AG",
        "ARP"
    ],
    [
        "ASIC ROBOTICS AG",
        "ASIC ROBOTICS"
    ],
    [
        "ASUPREL",
        "ASUPREL"
    ],
    [
        "ATEC-CYL",
        "ATEC CYL"
    ],
    [
        "ATLAS COPCO TOOLS",
        "ATLAS COPCO"
    ],
    [
        "ATLAS COPCO  IRL  LTD.,",
        "ATLAS COPCO"
    ],
    [
        "ATLAS COPCO(IRELAND)LTD",
        "ATLAS COPCO"
    ],
    [
        "ATLAS COPCO TOOL CENTRAL",
        "ATLAS COPCO"
    ],
    [
        "AXICON FRANCE",
        "AXICON"
    ],
    [
        "AXIMA CONCEPT",
        "AXIMA CONCEPT"
    ],
    [
        "BÄR UDO + PARTNER AG",
        "BAR UDO PARTNER"
    ],
    [
        "BECKHOFF AUTOMATION AG",
        "BECKHOFF AUTOMATION"
    ],
    [
        "BIO-KLEEN PRODUCTS",
        "BIO KLEEN PRODUCTS"
    ],
    [
        "BLASER SWISSLUBE",
        "BLASER SWISSLUBE"
    ],
    [
        "BM EMBALLAGE",
        "BM EMBALLAGE"
    ],
    [
        "BORDEAUX SOUDURE",
        "BORDEAUX SOUDURE"
    ],
    [
        "BORER CHEMIE AG",
        "BORER CHEMIE"
    ],
    [
        "BOSCH REXROTH AG",
        "BOSCH REXROTH"
    ],
    [
        "BOSSARD AG",
        "BOSSARD"
    ],
    [
        "BROREP AG",
        "BROREP"
    ],
    [
        "BROTSCHI DÉCOLLETAGE AG",
        "BROTSCHI DÉCOLLETAGE"
    ],
    [
        "BRUNO EGGIMANN",
        "BRUNO EGGIMANN"
    ],
    [
        "BRÄNDLE AIR-CLEAN AG",
        "BRÄNDLE AIR CLEAN"
    ],
    [
        "BRÄNDLE AIRCLEAN AG",
        "BRÄNDLE AIRCLEAN"
    ],
    [
        "BUCCI INDUSTRIES",
        "BUCCI INDUSTRIES"
    ],
    [
        "BUREAU VERITAS",
        "BUREAU VERITAS"
    ],
    [
        "BURKHALTER BIEMBACH AG",
        "BURKHALTER BIEMBACH"
    ],
    [
        "BÜTTNER-SCHMITZ-GESTELLEGMBH",
        "BUTTNER SCHMITZ GESTELLE"
    ],
    [
        "BÜTTIKOFER AG",
        "BÜTTIKOFER"
    ],
    [
        "C3S",
        "C3S"
    ],
    [
        "CAREVENT",
        "CAREVENT"
    ],
    [
        "CDO MEDICAL",
        "CDO MEDICAL"
    ],
    [
        "CERCLAGE SERVICE",
        "CERCLAGE SERVICE"
    ],
    [
        "CGC ENERGIE SA",
        "CGC ENERGIE"
    ],
    [
        "CHILWORTH FRANCE SAS",
        "CHILWORTH"
    ],
    [
        "CHIMIREC DARGELOS",
        "CHIMIREC DARGELOS"
    ],
    [
        "CHRIST DACH + FASSADEN AG",
        "CHRIST DACH FASSADEN"
    ],
    [
        "CLEAN-TEK REINRAUM",
        "CLEAN TEK REINRAUM"
    ],
    [
        "COMAINTEF - CONTROLE MAINTEN",
        "COMAINTEF CONTROLE MAINTEN"
    ],
    [
        "CONDITIONAIR",
        "CONDITIONAIR"
    ],
    [
        "CONRAD ELECTRONIC AG",
        "CONRAD ELECTRONIC"
    ],
    [
        "14001 CONSEIL",
        "CONSEIL"
    ],
    [
        "COSANUM AG",
        "COSANUM"
    ],
    [
        "DELAHAYE INDUSTRIES",
        "DELAHAYE INDUSTRIES"
    ],
    [
        "DELTA NEU",
        "DELTA NEU"
    ],
    [
        "DETECTOR FRANCE SA",
        "DETECTOR"
    ],
    [
        "DIGITEC GALAXUS AG",
        "DIGITEC GALAXUS"
    ],
    [
        "DISTRELEC AG",
        "DISTRELEC"
    ],
    [
        "DOMICALIS SAS",
        "DOMICALIS"
    ],
    [
        "DR. MED. ASTRID PAWLISZ",
        "DR MED ASTRID PAWLISZ"
    ],
    [
        "DRUTEL",
        "DRUTEL"
    ],
    [
        "DV GROUPE",
        "DV GROUPE"
    ],
    [
        "ELBARON SA",
        "ELBARON"
    ],
    [
        "EP ELECTRICITE SA",
        "ELECTRICITE"
    ],
    [
        "ELECTROLAN SA",
        "ELECTROLAN"
    ],
    [
        "ELECTROLUX AG",
        "ELECTROLUX"
    ],
    [
        "ELGA PROCESS WATER",
        "ELGA PROCESS WATER"
    ],
    [
        "ELMA SCHMIDBAUER GMBH",
        "ELMA SCHMIDBAUER"
    ],
    [
        "ELMA SCHMIDBAUER",
        "ELMA SCHMIDBAUER"
    ],
    [
        "ELPOCHEM AG",
        "ELPOCHEM"
    ],
    [
        "ELPRO-BUCHS AG",
        "ELPRO BUCHS"
    ],
    [
        "EMPA",
        "EMPA"
    ],
    [
        "ENIP AG",
        "ENIP"
    ],
    [
        "ENVIRO FALK AG",
        "ENVIRO FALK"
    ],
    [
        "ERNE SURFACE AG",
        "ERNE SURFACE"
    ],
    [
        "EROWA SARL",
        "EROWA"
    ],
    [
        "ESA",
        "ESA"
    ],
    [
        "ESPS B.V.",
        "ESPS B V"
    ],
    [
        "ESVE SAS",
        "ESVE"
    ],
    [
        "ETA SA CHEMIE",
        "ETA CHEMIE"
    ],
    [
        "ETABL.CANT.ASS.ET PREVENTION",
        "ETABL CANT ASS PREVENTION"
    ],
    [
        "ETS FOSSARD ACCUS SERVICE",
        "ETS FOSSARD ACCUS SERVICE"
    ],
    [
        "EUCHNER GMBH & CO. KG",
        "EUCHNER"
    ],
    [
        "EUCHNER SARL",
        "EUCHNER"
    ],
    [
        "EUGEN BUOB AG",
        "EUGEN BUOB"
    ],
    [
        "EUROFEU SERVICES",
        "EUROFEU SERVICES"
    ],
    [
        "EUROPE QUALITE",
        "EUROPE QUALITE"
    ],
    [
        "FASE SAS",
        "FASE SAS"
    ],
    [
        "FAUST LABORBEDARF AG",
        "FAUST LABORBEDARF"
    ],
    [
        "FEHR LAGERLOGISTIK AG",
        "FEHR LAGERLOGISTIK"
    ],
    [
        "FELDMANN AG",
        "FELDMANN"
    ],
    [
        "FELITON AG",
        "FELITON"
    ],
    [
        "FELIX INFORMATIQUE",
        "FELIX INFORMATIQUE"
    ],
    [
        "FESTO CORPORATION",
        "FESTO"
    ],
    [
        "FESTO AG",
        "FESTO"
    ],
    [
        "FILTRACON GMBH",
        "FILTRACON"
    ],
    [
        "FIMECOR-ALPROFIL AG",
        "FIMECOR ALPROFIL"
    ],
    [
        "FISA",
        "FISA"
    ],
    [
        "FISA FILTRATION",
        "FISA FILTRATION"
    ],
    [
        "FISHER BIOBLOCK SCIENTIFIC",
        "FISHER SCIENTIFIC"
    ],
    [
        "FLÜMA KLIMA AG",
        "FLÜMA KLIMA"
    ],
    [
        "FORPLAN AG",
        "FORPLAN"
    ],
    [
        "FOURS INDUSTRIELS B.M.I.",
        "FOURS INDUSTRIELS B M I"
    ],
    [
        "FRANCO PACK SAS",
        "FRANCO PACK"
    ],
    [
        "FREI AQUASERVICE AG",
        "FREI AQUASERVICE"
    ],
    [
        "FRIEDRICH GLOOR AG",
        "FRIEDRICH GLOOR"
    ],
    [
        "FUCHS LUBRIFIANTS FRANCE SA",
        "FUCHS LUBRIFIANTS"
    ],
    [
        "G-GROUP AG - G-EXPERT",
        "G GROUP G EXPERT"
    ],
    [
        "GACHES CHIMIE SAS",
        "GACHES CHIMIE"
    ],
    [
        "GALLI HOCH-UND TIEFBAU AG",
        "GALLI HOCH UND TIEFBAU"
    ],
    [
        "GARAGE ITURRI SARL",
        "GARAGE ITURRI"
    ],
    [
        "GEIGER SA",
        "GEIGER"
    ],
    [
        "GL IMPORT SA",
        "GL IMPORT"
    ],
    [
        "GLASSOLUTIONS",
        "GLASSOLUTIONS"
    ],
    [
        "GOMETROLOGIE",
        "GOMETROLOGIE"
    ],
    [
        "GROUPE TVA, LNS SA",
        "GROUPE TVA"
    ],
    [
        "GRÜTTER + WILLI AG",
        "GRUTTER WILLI"
    ],
    [
        "GYGI AG",
        "GYGI"
    ],
    [
        "GÜHRING (SCHWEIZ) AG",
        "GÜHRING"
    ],
    [
        "HAENCHEN HYDRAULIQUE",
        "HAENCHEN HYDRAULIQUE"
    ],
    [
        "HAGMANN TEC AG",
        "HAGMANN TEC"
    ],
    [
        "HANEL ET CO",
        "HANEL"
    ],
    [
        "HAUSER FEUERSCHUTZ AG",
        "HAUSER FEUERSCHUTZ"
    ],
    [
        "HEINZ BURKHALTER SA",
        "HEINZ BURKHALTER"
    ],
    [
        "HEIZMANN SA",
        "HEIZMANN"
    ],
    [
        "HELFINA AG",
        "HELFINA"
    ],
    [
        "HEMO WERKZEUGBAU",
        "HEMO WERKZEUGBAU"
    ],
    [
        "HENKEL AG",
        "HENKEL"
    ],
    [
        "HENKEL CORPORATION",
        "HENKEL"
    ],
    [
        "HERVE THERMIQUE SAS",
        "HERVE THERMIQUE"
    ],
    [
        "HG3",
        "HG3"
    ],
    [
        "HIRSCHI FILTRES",
        "HIRSCHI FILTRES"
    ],
    [
        "HMP HEINRICH MULLER MASCH",
        "HMP HEINRICH MULLER MASCH"
    ],
    [
        "HN + M GMBH",
        "HN+M GMBH"
    ],
    [
        "HOROQUARTZ",
        "HOROQUARTZ"
    ],
    [
        "HOTEC SYSTEMS GMBH",
        "HOTEC SYSTEMS"
    ],
    [
        "HOVAL  AG",
        "HOVAL"
    ],
    [
        "HOWMEDICA INTERNATIONAL INC",
        "HOWMEDICA INTERNATIONAL"
    ],
    [
        "HTDS",
        "HTDS"
    ],
    [
        "HUMARD AUTOMATION S.A.",
        "HUMARD AUTOMATION S A"
    ],
    [
        "HYGIATECH",
        "HYGIATECH"
    ],
    [
        "IBAG HSC TECHNOLOGY",
        "IBAG"
    ],
    [
        "IBAG SWITZERLAND AG",
        "IBAG"
    ],
    [
        "IBZ INDUSTRIE AG",
        "IBZ INDUSTRIE"
    ],
    [
        "ICS IDENTCODE SYSTEME AG",
        "ICS IDENTCODE SYSTEME"
    ],
    [
        "IEPCO AG",
        "IEPCO"
    ],
    [
        "IES GROUP",
        "IES GROUP"
    ],
    [
        "IFAM FRANCE SAS",
        "IFAM"
    ],
    [
        "INDEX MACHINES OUTILS SUISSE",
        "INDEX MACHINES OUTILS"
    ],
    [
        "INMAC WSTORE",
        "INMAC WSTORE"
    ],
    [
        "INSYS INDUSTRIESYSTEME AG",
        "INSYS INDUSTRIESYSTEME"
    ],
    [
        "INTERFLEX",
        "INTERFLEX"
    ],
    [
        "ISATOOL AG",
        "ISATOOL"
    ],
    [
        "ISCAR HARTMETALL AG",
        "ISCAR HARTMETALL"
    ],
    [
        "ISOMA GMBH",
        "ISOMA"
    ],
    [
        "J.SCHMALZ GMBH",
        "J SCHMALZ"
    ],
    [
        "JOHNSON & JOHNSON(IRE)LTD",
        "JOHNSON & JOHNSON"
    ],
    [
        "JOSEF BINKERT AG",
        "JOSEF BINKERT"
    ],
    [
        "JOURDANIÈRE NATURE",
        "JOURDANIÈRE NATURE"
    ],
    [
        "JTC",
        "JTC"
    ],
    [
        "KAESER KOMPRESSOREN AG",
        "KAESER KOMPRESSOREN"
    ],
    [
        "KAISER + KRAFT AG",
        "KAISER KRAFT"
    ],
    [
        "KALLFASS",
        "KALLFASS"
    ],
    [
        "KALTBRUNNER AG",
        "KALTBRUNNER"
    ],
    [
        "KASTO FRANCE",
        "KASTO"
    ],
    [
        "KASTO MASCHINENBAU GMBH",
        "KASTO"
    ],
    [
        "KAUFMANN QUINCAILLERIE",
        "KAUFMANN QUINCAILLERIE"
    ],
    [
        "KEYENCE FRANCE SA",
        "KEYENCE"
    ],
    [
        "KIMO INSTRUMENTS",
        "KIMO INSTRUMENTS"
    ],
    [
        "KLEBE-TECHNIK AG",
        "KLEBE TECHNIK"
    ],
    [
        "KLOCKNER DESMA ELASTO GMBH",
        "KLOCKNER DESMA ELASTO"
    ],
    [
        "KNÖRR HYDRAULIK SERVICE",
        "KNÖRR HYDRAULIK SERVICE"
    ],
    [
        "KOPP TANKREVISIONEN GMBH",
        "KOPP TANKREVISIONEN"
    ],
    [
        "KOWAP AG",
        "KOWAP"
    ],
    [
        "KREUZWERK AG",
        "KREUZWERK"
    ],
    [
        "KUKA CEE GMBH, LINZ",
        "KUKA"
    ],
    [
        "KUKA",
        "KUKA"
    ],
    [
        "KUKA DEUTSCHLAND GMBH",
        "KUKA"
    ],
    [
        "KUNDERT AG",
        "KUNDERT"
    ],
    [
        "KURT BREUNING IRCO",
        "KURT BREUNING IRCO"
    ],
    [
        "KÄLTETECHNIK AG ZOLLIKOFEN",
        "KÄLTETECHNIK ZOLLIKOFEN"
    ],
    [
        "LABOR TEAM W AG",
        "LABOR TEAM W"
    ],
    [
        "LABORATOIRE ANIOS",
        "LABORATOIRE ANIOS"
    ],
    [
        "LAFOURCADE EQUIPEMENTS",
        "LAFOURCADE EQUIPEMENTS"
    ],
    [
        "LANDI RESO",
        "LANDI RESO"
    ],
    [
        "ES LASER",
        "LASER"
    ],
    [
        "LISTA AG",
        "LISTA"
    ],
    [
        "LITHOPOL AG",
        "LITHOPOL"
    ],
    [
        "LOSMA GMBH",
        "LOSMA"
    ],
    [
        "LOSMA INC",
        "LOSMA"
    ],
    [
        "LOSS ROLF-DIETER",
        "LOSS ROLF DIETER"
    ],
    [
        "MAAGTECHNIC AG",
        "MAAGTECHNIC"
    ],
    [
        "MABEO INDUSTRIES",
        "MABEO INDUSTRIES"
    ],
    [
        "MAG45.BV",
        "MAG45"
    ],
    [
        "MAHR U.K. PLC",
        "MAHR"
    ],
    [
        "MAHR FRANCE",
        "MAHR"
    ],
    [
        "MAHR AG SCHWEIZ",
        "MAHR"
    ],
    [
        "MANUTAN SA",
        "MANUTAN"
    ],
    [
        "MARCEL AUBERT SA",
        "MARCEL AUBERT"
    ],
    [
        "MARTIPLAST",
        "MARTIPLAST"
    ],
    [
        "MAZAK SAS",
        "MAZAK"
    ],
    [
        "MCE TECHNOLOLGIES SAS",
        "MCE TECHNOLOLGIES"
    ],
    [
        "MECA DIFFUSION",
        "MECA DIFFUSION"
    ],
    [
        "MECASEM SARL",
        "MECASEM"
    ],
    [
        "MEDICS LABOR",
        "MEDICS LABOR"
    ],
    [
        "MEKAPHARM",
        "MEKAPHARM"
    ],
    [
        "MEKAPHARM-APOTEKA",
        "MEKAPHARM APOTEKA"
    ],
    [
        "MERCK & CIE",
        "MERCK & CIE"
    ],
    [
        "METTLER-TOLEDO AG",
        "METTLER-TOLEDO"
    ],
    [
        "MEYRAT SA",
        "MEYRAT"
    ],
    [
        "MGIORGIO SARL",
        "MGIORGIO SARL"
    ],
    [
        "MICHAEL WARD GAS SERVICES",
        "MICHAEL WARD GAS SERVICES"
    ],
    [
        "MIELE AG",
        "MIELE"
    ],
    [
        "MILLIPORE  UK  LTD.",
        "MILLIPORE"
    ],
    [
        "MILLIPORE",
        "MILLIPORE"
    ],
    [
        "MILLIPORE CORPORATION",
        "MILLIPORE"
    ],
    [
        "MILLIPORE SAS - MERCK MILLIPORE",
        "MILLIPORE"
    ],
    [
        "MILTON SERVICES",
        "MILTON SERVICES"
    ],
    [
        "MITUTOYO (SCHWEIZ) AG",
        "MITUTOYO"
    ],
    [
        "MMM STERILISATOREN AG",
        "MMM STERILISATOREN"
    ],
    [
        "MOBILE MINI",
        "MOBILE MINI"
    ],
    [
        "MOBILE MINI LLC",
        "MOBILE MINI"
    ],
    [
        "MOBIT AG",
        "MOBIT"
    ],
    [
        "MOTUL SA",
        "MOTUL"
    ],
    [
        "MTE MALLET TRANSPORTS EXPRES",
        "MTE MALLET TRANSPORTS EXPRES"
    ],
    [
        "NEBCNC SERVICE",
        "NEBCNC SERVICE"
    ],
    [
        "NEOCLASS EQUIPEMENT",
        "NEOCLASS EQUIPEMENT"
    ],
    [
        "OCTA SARL",
        "OCTA SARL"
    ],
    [
        "OGP FRANCE SAS",
        "OGP"
    ],
    [
        "OPAL ASSOCIATES SA",
        "OPAL ASSOCIATES"
    ],
    [
        "ORANGE BUSINESS MONTAUBAN",
        "ORANGE BUSINESS"
    ],
    [
        "OREXAD",
        "OREXAD"
    ],
    [
        "P. HIRSCHI FILTRES SA",
        "P HIRSCHI FILTRES"
    ],
    [
        "PALL EUROPE LTD",
        "PALL"
    ],
    [
        "PAUL BÜETIGER AG",
        "PAUL BUETIGER"
    ],
    [
        "PAUL HOFER SA",
        "PAUL HOFER"
    ],
    [
        "PECAUT ENTREPRISE TECHNIQUE",
        "PECAUT ENTREPRISE TECHNIQUE"
    ],
    [
        "PERFORMER CNC",
        "PERFORMER CNC"
    ],
    [
        "PERMI AUTOMATISME",
        "PERMI AUTOMATISME"
    ],
    [
        "PEYER GRAPHIC AG",
        "PEYER GRAPHIC"
    ],
    [
        "PHILIPPE LUBRIFIANTS SAS",
        "PHILIPPE LUBRIFIANTS"
    ],
    [
        "PIEPENBROCK DIENSTLEISTUNGEN",
        "PIEPENBROCK FACILITY MANAGEMENT"
    ],
    [
        "PIEPENBROCK INSTANDHALTUNG",
        "PIEPENBROCK FACILITY MANAGEMENT"
    ],
    [
        "PLASTIFIL SA",
        "PLASTIFIL"
    ],
    [
        "POLYSERVICE AG",
        "POLYSERVICE"
    ],
    [
        "PROCHILAB",
        "PROCHILAB"
    ],
    [
        "PURINOX GMBH",
        "PURINOX"
    ],
    [
        "R. NUSSBAUM AG",
        "R NUSSBAUM"
    ],
    [
        "REBER SYSTEMATIC GMBH AND CO",
        "REBER SYSTEMATIC"
    ],
    [
        "REIMMANN",
        "REIMMANN"
    ],
    [
        "RENE LEDOUX SA",
        "RENE LEDOUX"
    ],
    [
        "RESTEC GMBH",
        "RESTEC"
    ],
    [
        "RETRAG TRANSPORTGERÄTE AG",
        "RETRAG TRANSPORTGERÄTE"
    ],
    [
        "REXEL",
        "REXEL"
    ],
    [
        "RF CNC SERVICES SARL",
        "RF CNC SERVICES"
    ],
    [
        "ROFIN-LASAG AG",
        "ROFIN LASAG"
    ],
    [
        "ROSLER FRANCE",
        "ROSLER"
    ],
    [
        "RS",
        "RS"
    ],
    [
        "SAER",
        "SAER"
    ],
    [
        "SAFIA GARAGE AG",
        "SAFIA GARAGE"
    ],
    [
        "SANDMASTER AG",
        "SANDMASTER"
    ],
    [
        "SAROPACK AG",
        "SAROPACK"
    ],
    [
        "SCHÄFER SHOP AG",
        "SCHAFER SHOP"
    ],
    [
        "SCHAFER WÜTHRICH",
        "SCHAFER WUTHRICH"
    ],
    [
        "SCHENKER STOREN AG",
        "SCHENKER"
    ],
    [
        "SCHINDLER AUFZÜGE AG",
        "SCHINDLER"
    ],
    [
        "SCHINDLER",
        "SCHINDLER"
    ],
    [
        "SCHINDLER ELEVATOR CORP",
        "SCHINDLER"
    ],
    [
        "SCHINDLER ELEVATOR CORPOR",
        "SCHINDLER"
    ],
    [
        "DZ SCHLIESSTECHNIK GMBH",
        "SCHLIESSTECHNIK"
    ],
    [
        "SCHMALZ",
        "SCHMALZ"
    ],
    [
        "SCHMALZ GMBH",
        "SCHMALZ"
    ],
    [
        "SCHMETZ GMBH",
        "SCHMETZ"
    ],
    [
        "SCHNEGG TOOLS AG",
        "SCHNEGG TOOLS"
    ],
    [
        "SEFILTEC AG",
        "SEFILTEC"
    ],
    [
        "SERVICE ENERGIE & ENVIRONNEM",
        "SERVICE ENERGIE & ENVIRONNEM"
    ],
    [
        "SETARAM",
        "SETARAM"
    ],
    [
        "SETON",
        "SETON"
    ],
    [
        "SETON GMBH",
        "SETON"
    ],
    [
        "SICK VERTRIEBS-GMBH",
        "SICK"
    ],
    [
        "SICK AG",
        "SICK"
    ],
    [
        "SICK INC",
        "SICK"
    ],
    [
        "SICLI",
        "SICLI"
    ],
    [
        "SIEBER SARL",
        "SIEBER"
    ],
    [
        "SIEMENS",
        "SIEMENS"
    ],
    [
        "SIEMENS INDUSTRY SOFWARE SAS",
        "SIEMENS"
    ],
    [
        "SIGNER TITANIUM AG",
        "SIGNER TITANIUM"
    ],
    [
        "SMC PNEUMATIQUE SA",
        "SMC PNEUMATIQUE"
    ],
    [
        "SMCOM SOFTWARE",
        "SMCOM SOFTWARE"
    ],
    [
        "SMPA",
        "SMPA"
    ],
    [
        "SNDI (SUISSE) SA",
        "SNDI SUISSE SA"
    ],
    [
        "SOFRANEL",
        "SOFRANEL"
    ],
    [
        "SOFRAPER",
        "SOFRAPER"
    ],
    [
        "SOLIDCAM GMBH",
        "SOLIDCAM"
    ],
    [
        "SOMMER SA",
        "SOMMER"
    ],
    [
        "SSI SCHÄFER AG",
        "SSI SCHÄFER"
    ],
    [
        "SSI SCHÄFER",
        "SSI SCHÄFER"
    ],
    [
        "STAMPFLI WILLI",
        "STAMPFLI WILLI"
    ],
    [
        "STANLEY SECURITY FRANCE",
        "STANLEY SECURITY"
    ],
    [
        "STAR MACHINE TOOL",
        "STAR MACHINE TOOL"
    ],
    [
        "STARTECH SAS",
        "STARTECH"
    ],
    [
        "STREAMLINE AG",
        "STREAMLINE"
    ],
    [
        "SUD OUEST CAOUTCHOUC",
        "SUD OUEST CAOUTCHOUC"
    ],
    [
        "SURY AG",
        "SURY AG"
    ],
    [
        "SYK ORTHOPAEDICS IRELAND",
        "SYK ORTHOPAEDICS"
    ],
    [
        "TALENDO AG",
        "TALENDO"
    ],
    [
        "TANNER & CO. AG",
        "TANNER & CO. AG"
    ],
    [
        "TCPOS SA",
        "TCPOS"
    ],
    [
        "TECHNOFILTRES",
        "TECHNOFILTRES"
    ],
    [
        "TECNIMODERN AUTOMATION",
        "TECNIMODERN AUTOMATION"
    ],
    [
        "THEMIS",
        "THEMIS"
    ],
    [
        "TOLERIE SERRURERIE VERNETTI",
        "TOLERIE SERRURERIE VERNETTI"
    ],
    [
        "TONI LEUENBERGER AG",
        "TONI LEUENBERGER"
    ],
    [
        "TRESCAL SA",
        "TRESCAL"
    ],
    [
        "TRIMOS SYLVAC",
        "TRIMOS SYLVAC"
    ],
    [
        "TUS",
        "TUS"
    ],
    [
        "TYCO INTEGRATED FIRE &",
        "TYCO INTEGRATED FIRE"
    ],
    [
        "UNIFILAG FILTERTECHNIK",
        "UNIFILAG FILTERTECHNIK"
    ],
    [
        "USICAD",
        "USICAD"
    ],
    [
        "UTILIS AG",
        "UTILIS"
    ],
    [
        "VEBICODE AG",
        "VEBICODE"
    ],
    [
        "VEBO WERKSTATT",
        "VEBO WERKSTATT"
    ],
    [
        "VEOLIA EAU",
        "VEOLIA EAU"
    ],
    [
        "VEOLIA PROPRETE SEVIA",
        "VEOLIA PROPRETE SEVIA"
    ],
    [
        "VEOLIA WATER STI",
        "VEOLIA WATER"
    ],
    [
        "VITRERIE JOST",
        "VITRERIE JOST"
    ],
    [
        "VWR INTERNATIONAL AG",
        "VWR INTERNATIONAL"
    ],
    [
        "WALTER MEIER AG",
        "WALTER MEIER"
    ],
    [
        "WASSERMANN",
        "WASSERMANN"
    ],
    [
        "WEBCRAFT AG",
        "WEBCRAFT"
    ],
    [
        "WEBER",
        "WEBER"
    ],
    [
        "WERTH MESSTECHNIK GMBH",
        "WERTH MESSTECHNIK"
    ],
    [
        "WIELAND & OERTLI AG",
        "WIELAND & OERTLI"
    ],
    [
        "WINKENBACH",
        "WINKENBACH"
    ],
    [
        "WOLFISBERG TOR-TECHNIK AG",
        "WOLFISBERG TOR TECHNIK"
    ],
    [
        "WYSS SAMEN UND PFLANZEN AG",
        "WYSS SAMEN UND PFLANZEN"
    ],
    [
        "ZOLLER GMBH & CO. KG, E.",
        "ZOLLER"
    ],
    [
        "ZOLLER INC",
        "ZOLLER"
    ],
    [
        "ZWICK GMBH & CO. KG",
        "ZWICK"
    ],
    [
        "ZWICK GMBH & CO.KG",
        "ZWICK"
    ],
    [
        "ÄRZTENZENTRUM BETTLACH",
        "ÄRZTENZENTRUM BETTLACH"
    ],
    [
        "ADVANCE SERVICES RECYCLIN",
        "ADVANCE SERVICES RECYCLIN"
    ],
    [
        "ADVANCED SERVICES RECYCLE",
        "ADVANCED SERVICES RECYCLE"
    ],
    [
        "ALLIED WASTE OF PONCE INC",
        "ALLIED WASTE OF PONCE"
    ],
    [
        "AMS ENVIRONMENTAL, LLC *",
        "AMS ENVIRONMENTAL"
    ],
    [
        "CARIBE RECYCLING CORP.",
        "CARIBE RECYCLING"
    ],
    [
        "CLEAN HARBORS",
        "CLEAN HARBORS"
    ],
    [
        "DALLAS RECYCLING",
        "DALLAS RECYCLING"
    ],
    [
        "ENVA IRELAND LTD",
        "ENVA"
    ],
    [
        "IFCO RECYCLING INC",
        "IFCO RECYCLING"
    ],
    [
        "INDAVER IRELAND LTD.,",
        "INDAVER"
    ],
    [
        "INDAVER IRELAND LTD",
        "INDAVER"
    ],
    [
        "IWS OF NEW JERSEY",
        "IWS OF NEW JERSEY"
    ],
    [
        "KRANICH ENTSORGUNG GMBH",
        "KRANICH ENTSORGUNG"
    ],
    [
        "LEHANE ENVIRONMENTAL SERV",
        "LEHANE ENVIRONMENTAL SERV"
    ],
    [
        "RECICLADORA AB S DE RL DE CV",
        "RECICLADORA"
    ],
    [
        "S AND S RECYCLING (ADVANCE SERVICES LTD)",
        "S AND S RECYCLING"
    ],
    [
        "STERICYCLE, INC. 8226892",
        "STERICYCLE"
    ],
    [
        "STERICYCLE",
        "STERICYCLE"
    ],
    [
        "STERICYCLE, INC.",
        "STERICYCLE"
    ],
    [
        "VEOLIA ENVIRONMENTAL SERVICES",
        "VEOLIA ENVIRONMENTAL SERVICES"
    ],
    [
        "VEOLIA ENVIRONMENTAL SERVICES LLC",
        "VEOLIA ENVIRONMENTAL SERVICES"
    ],
    [
        "WASTE MANAGEMENT",
        "WASTE MANAGEMENT"
    ],
    [
        "WASTE MANAGEMENT NORTH *",
        "WASTE MANAGEMENT NORTH"
    ],
    [
        "WASTE MANAGEMENT OF FLORIDA",
        "WASTE MANAGEMENT OF FLORIDA"
    ],
    [
        "AEK ENERGIE AG",
        "AEK ENERGIE"
    ],
    [
        "ALPIQ ENERGIE FRANCE SAS",
        "ALPIQ ENERGIE"
    ],
    [
        "AUTORIDAD DE ENERGIA ELECTRICA",
        "AUTORIDAD ENERGIA ELECTRICA"
    ],
    [
        "BORD GAIS",
        "BORD GAIS ENERGY"
    ],
    [
        "BORD GAIS ENERGY",
        "BORD GAIS ENERGY"
    ],
    [
        "COMISION FEDERAL DE ELECTRICIDAD",
        "COMISION FEDERAL DE ELECTRICIDAD"
    ],
    [
        "CONSUMERS ENERGY",
        "CONSUMERS ENERGY"
    ],
    [
        "CONSUMERS ENERGY COMPANY",
        "CONSUMERS ENERGY"
    ],
    [
        "DIRECT ENERGY BUSINESS MARKETING LLC",
        "DIRECT ENERGY BUSINESS MARKETING"
    ],
    [
        "EDF",
        "EDF"
    ],
    [
        "ELECTRIC ISLAND",
        "ELECTRIC IRELAND"
    ],
    [
        "ELEKTRO SCHILLINGER GMBH",
        "ELEKTRO SCHILLINGER"
    ],
    [
        "ESB INNOVATION (ROI) LTD",
        "ESB"
    ],
    [
        "ESB IE",
        "ESB"
    ],
    [
        "ESB INDEPENDENT ENERGY",
        "ESB"
    ],
    [
        "ESB NETWORKS",
        "ESB"
    ],
    [
        "ESB NETWORKS LTD",
        "ESB"
    ],
    [
        "FLORIDA POWER & LIGHT CO",
        "FLORIDA POWER & LIGHT"
    ],
    [
        "HENDRICKS COUNTY RURAL",
        "HENDRICKS COUNTY RURAL"
    ],
    [
        "LAKELAND ELECTRIC INC",
        "LAKELAND ELECTRIC"
    ],
    [
        "MVV ENERGYSOLUTIONS GMBH",
        "MVV"
    ],
    [
        "MVV (MVV ENERGIE AG)",
        "MVV"
    ],
    [
        "PSE&G",
        "PSE&G"
    ],
    [
        "REVERE ELECTRIC SUPPLY *",
        "REVERE ELECTRIC SUPPLY"
    ],
    [
        "ROCKLAND ELECTRIC",
        "ROCKLAND ELECTRIC"
    ],
    [
        "ROCKY MOUNTAIN POWER",
        "ROCKY MOUNTAIN POWER"
    ],
    [
        "VISTRA ENERGY CORP",
        "VISTRA ENERGY"
    ],
    [
        "VITEOS SA",
        "VITEOS"
    ],
    [
        "AUTORIDAD DE ACUEDUCTOS",
        "AUTORIDAD ACUEDUCTOS"
    ],
    [
        "FLIER'S QUALITY WATER",
        "FLIER S QUALITY WATER"
    ],
    [
        "IRISH WATER",
        "IRISH WATER"
    ],
    [
        "CINTRON DIESEL",
        "CINTRON DIESEL"
    ],
    [
        "CONSTELLATION NEW ENERGY INC",
        "CONSTELLATION NEWENERGY"
    ],
    [
        "CONSTELLATION NEWENERGY",
        "CONSTELLATION NEWENERGY"
    ],
    [
        "CONSTELLATION NEWENERGY INC",
        "CONSTELLATION NEWENERGY"
    ],
    [
        "ANGLESHELF OF PR",
        "ANGLESHELF OF PR"
    ],
    [
        "BONILLA ELECTRIC",
        "BONILLA ELECTRIC"
    ],
    [
        "BURKE BUILDING SERVICES",
        "BURKE BUILDING SERVICES"
    ],
    [
        "CARIBE FIRE GROUP SPECIALIST, INC",
        "CARIBE FIRE GROUP SPECIALIST"
    ],
    [
        "COMISION ESTATAL",
        "COMISION ESTATAL"
    ],
    [
        "COMISION ESTATAL DE SERVICIOS PUBLICOS DE TIJUANA",
        "COMISION ESTATAL DE SERVICIOS PUBLICOS DE TIJUANA"
    ],
    [
        "CONSTELLATION NEWENERGY - GAS DIVISION, LLC",
        "CONSTELLATION NEWENERGY GAS DIVISION"
    ],
    [
        "CORTES INDUSTRIAL",
        "CORTES INDUSTRIAL"
    ],
    [
        "EDF - GDF SERVICE ILLE & VIL",
        "EDF GDF SERVICE ILLE & VIL"
    ],
    [
        "FAERBER GAS GMBH",
        "FAERBER GAS"
    ],
    [
        "GEMEINDEWERKE SCHOENK.GMBH",
        "GEMEINDEWERKE SCHOENK"
    ],
    [
        "GRANGER-HUNTER IMPROVEMEN",
        "GRANGER HUNTER IMPROVEMEN"
    ],
    [
        "IFU-GEWERBLICHES INSTITUT FÜR",
        "IFU GEWERBLICHES INSTITUT FÜR"
    ],
    [
        "INSTITUT PROF. DR. JÄGER GMBH",
        "INSTITUT PROF DR JÄGER"
    ],
    [
        "IRISH WIRE PRODUCTS LTD.",
        "IRISH WIRE PRODUCTS"
    ],
    [
        "JZJ INDUSTRIAL SOLUTION CORP",
        "JZJ INDUSTRIAL SOLUTION"
    ],
    [
        "LIMERICK COUNTY COUNCIL",
        "LIMERICK COUNTY COUNCIL"
    ],
    [
        "NICOR GAS",
        "NICOR GAS"
    ],
    [
        "PACE ANALYTICAL SERVICES LLC",
        "PACE ANALYTICAL SERVICES"
    ],
    [
        "QUESTAR GAS",
        "QUESTAR GAS"
    ],
    [
        "REGIOENERGIE SOLOTHURN",
        "REGIOENERGIE SOLOTHURN"
    ],
    [
        "STADTWERKE KIEL AG",
        "STADTWERKE KIEL"
    ],
    [
        "STERICYCLE ULC",
        "STERICYCLE ULC"
    ],
    [
        "TOWNSHIP OF MAHWAH MUNICIPAL OFFICES",
        "TOWNSHIP OF MAHWAH MUNICIPAL OFFICES"
    ],
    [
        "VILLAGE OF CARY",
        "VILLAGE OF CARY"
    ],
    [
        "VOLLAN AG",
        "VOLLAN"
    ],
    [
        "WASTE MANAGEMENT, INC.",
        "WASTE MANAGEMENT, INC."
    ],
    [
        "WATER TECHNOLOGY",
        "WATER TECHNOLOGY"
    ],
    [
        "WELLNESS AT WORK",
        "WELLNESS WORK"
    ],
    [
        "WILHELM HOYER GMBH & CO. KG",
        "WILHELM HOYER"
    ],
    [
        "SUEZ WTS ANALYTICAL INSTRUMENTS, INC *",
        "SUEZ WTS ANALYTICAL INSTRUMENTS"
    ],
    [
        "4ALLPROMOS",
        "4ALLPROMOS"
    ],
    [
        "A R T I F E X",
        "A R T I F E X"
    ],
    [
        "A3 MULTIMEDIA",
        "A3 MULTIMEDIA"
    ],
    [
        "ACE REPROGRAPHIC SERVICES",
        "ACE REPROGRAPHIC SERVICES"
    ],
    [
        "ADVANCED NETWORK INSTALLA",
        "ADVANCED NETWORK INSTALLA"
    ],
    [
        "AEROSTAR AIRPORT - PARKIN",
        "AEROSTAR AIRPORT PARKIN"
    ],
    [
        "AESCHLIMANN LG LASER &",
        "AESCHLIMANN LG LASER"
    ],
    [
        "AGIO IMAGING",
        "AGIO IMAGING"
    ],
    [
        "AGIO IMAGING INC",
        "AGIO IMAGING"
    ],
    [
        "AJR ENTERPRISES, LLC *",
        "AJR ENTERPRISES"
    ],
    [
        "ALAN PLACE PHOTOGRAPHY",
        "ALAN PLACE PHOTOGRAPHY"
    ],
    [
        "ALMARK TOOL & MACHINING CO INC",
        "ALMARK TOOL & MACHINING"
    ],
    [
        "AMAZON",
        "AMAZON"
    ],
    [
        "AMAZON BUSINESS",
        "AMAZON"
    ],
    [
        "AMAZON MARKEPLACE NA - PA",
        "AMAZON"
    ],
    [
        "AMAZON MKTPLACE PMTS",
        "AMAZON"
    ],
    [
        "AMAZON MKTPLACE PMTS WWW.",
        "AMAZON"
    ],
    [
        "AMAZON PRIME",
        "AMAZON"
    ],
    [
        "AMAZON.COM",
        "AMAZON"
    ],
    [
        "AMAZON.COM AMZN.COM/BILL",
        "AMAZON"
    ],
    [
        "AMAZON.COM LLC",
        "AMAZON"
    ],
    [
        "AMAZON.COM M84GL7VJ1",
        "AMAZON"
    ],
    [
        "AMAZON.COM M88201ZG0",
        "AMAZON"
    ],
    [
        "AMZN MKTP US",
        "AMAZON"
    ],
    [
        "AMZN MKTP US AMZN.COM/BIL",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M020T1OQ1",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M02GF1510",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M03NN1H62",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M04XQ2O10",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M08F68C30",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M81S53NA1",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M81WV58E2",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M83IQ6G41",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M84HW4HH2",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M84RL7882",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M857O9ZG2",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M862N5J11",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M86X23PB0",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M87X145K0",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M88R73850",
        "AMAZON"
    ],
    [
        "AMZN MKTP US M89AM3GL0",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT12A5Y00",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT17O8P50",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT33D8AT1",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT46G5UI2",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT4772YM2",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT4PF0V11",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT5J338K1",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT7NW2KO2",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT7Z29DZ2",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT89U5GI1",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT9CV0A41",
        "AMAZON"
    ],
    [
        "AMZN MKTP US MT9ID6VW0",
        "AMAZON"
    ],
    [
        "AMS-BW NEWARK JV",
        "AMS NEWARK JV"
    ],
    [
        "AMSTERDAM PRINTING LITHO",
        "AMSTERDAM PRINTING LITHO"
    ],
    [
        "ANDERCO SAFETY IRL LTD.,",
        "ANDERCO SAFETY"
    ],
    [
        "ANDRE, MARC",
        "ANDRE MARC"
    ],
    [
        "ANTHONY DUGAL PHOTOGRAPHY",
        "ANTHONY DUGAL PHOTOGRAPHY"
    ],
    [
        "ANTHONY J DUGAL",
        "ANTHONY J DUGAL"
    ],
    [
        "ARBEE ASSOCIATES",
        "ARBEE ASSOCIATES"
    ],
    [
        "ARROWGRASS LIMITED",
        "ARROWGRASS"
    ],
    [
        "ASDEE.COM",
        "ASDEE.COM"
    ],
    [
        "ASSETWARE TECHNOLOGY LTD.",
        "ASSETWARE TECHNOLOGY"
    ],
    [
        "AUDION ELEKTRO GMBH",
        "AUDION ELEKTRO"
    ],
    [
        "AVID GRAPHIC DESIGN",
        "AVID GRAPHIC DESIGN"
    ],
    [
        "AZ PRINTERS",
        "AZ PRINTERS"
    ],
    [
        "BANDON OFFICESUPPLIES",
        "BANDON OFFICESUPPLIES"
    ],
    [
        "BANHOLZER GMBH KARTENVERLAG",
        "BANHOLZER KARTENVERLAG"
    ],
    [
        "BARBARA'S BOOKSTORE O'HAR",
        "BARBARA'S BOOKSTORE O'HAR"
    ],
    [
        "BARRERAS VERDUGO MARIA CRISTINA",
        "BARRERAS VERDUGO MARIA CRISTINA"
    ],
    [
        "BAUDVILLE",
        "BAUDVILLE"
    ],
    [
        "BENTOUNSI BRAHIM",
        "BENTOUNSI BRAHIM"
    ],
    [
        "BERKSHIRE INTERNATONAL LTD",
        "BERKSHIRE INTERNATONAL"
    ],
    [
        "BES BRUNOLD HOLZ UND DESIGN",
        "BES BRUNOLD HOLZ UND DESIGN"
    ],
    [
        "BEST BUY",
        "BEST BUY"
    ],
    [
        "BEST BUY 1118",
        "BEST BUY"
    ],
    [
        "BEST BUY 178",
        "BEST BUY"
    ],
    [
        "BEST BUY 413",
        "BEST BUY"
    ],
    [
        "BEST BUY 691",
        "BEST BUY"
    ],
    [
        "BESTEC - ETP FREIBURG GMBH",
        "BESTEC ETP FREIBURG"
    ],
    [
        "BOLLE SAFETY",
        "BOLLE SAFETY"
    ],
    [
        "BOTTOMLINE TECHNOLOGIES",
        "BOTTOMLINE TECHNOLOGIES"
    ],
    [
        "LA BOUTIQUE DU PROGRES",
        "BOUTIQUE DU PROGRES"
    ],
    [
        "BRADY GMBH",
        "BRADY"
    ],
    [
        "BRONSON METHODIST",
        "BRONSON METHODIST"
    ],
    [
        "BRUNO KUMMER GMBH",
        "BRUNO KUMMER"
    ],
    [
        "BRÜTSCH/RÜEGGER TOOLS GMBH",
        "BRÜTSCH RÜEGGER TOOLS"
    ],
    [
        "BULEZ DEZIGN LLC",
        "BULEZ DEZIGN"
    ],
    [
        "C.D.W. COMPUTER CENTER *",
        "C.D.W. COMPUTER CENTER"
    ],
    [
        "CAL SIERRA TECHNOLOGIES INCORPORATED",
        "CAL SIERRA TECHNOLOGIES INCORPORATED"
    ],
    [
        "CALL ONE INC",
        "CALL ONE"
    ],
    [
        "CANCOM GMBH",
        "CANCOM"
    ],
    [
        "CANTEEN CORPORATION INC",
        "CANTEEN CORPORATION INC"
    ],
    [
        "CARDUNAL OFFICE SUPPLY",
        "CARDUNAL OFFICE SUPPLY"
    ],
    [
        "CARL ROTH GMBH&CO.KG",
        "CARL ROTH"
    ],
    [
        "CARRAIG PRINT LITHO PRESS",
        "CARRAIG PRINT LITHO PRESS"
    ],
    [
        "CATHAL O'DOHERTY",
        "CATHAL O'DOHERTY"
    ],
    [
        "CAULFIELD INDUSTRIAL LTD,",
        "CAULFIELD INDUSTRIAL"
    ],
    [
        "CDW COMPUTER CENTERS INC",
        "CDW"
    ],
    [
        "CDW COMPUTER CTRS INC",
        "CDW"
    ],
    [
        "CDW DIRECT LLC",
        "CDW"
    ],
    [
        "CHARTERED ACCOUNTANTS",
        "CHARTERED ACCOUNTANTS"
    ],
    [
        "CHICAGO SUBURBAN EXPRESS *",
        "CHICAGO SUBURBAN EXPRESS"
    ],
    [
        "CHRISTOPH BAUER",
        "CHRISTOPH BAUER"
    ],
    [
        "CODEX LTD",
        "CODEX"
    ],
    [
        "COLE-PARMER INSTRUMENT CO",
        "COLE PARMER"
    ],
    [
        "COLE PARMER INSTRUMENT CO",
        "COLE PARMER INSTRUMENT"
    ],
    [
        "COMMERCIAL DESIGN SERVICES INC",
        "COMMERCIAL DESIGN SERVICES"
    ],
    [
        "CONVERGEONE INC",
        "CONVERGEONE"
    ],
    [
        "COOL GREY",
        "COOL GREY"
    ],
    [
        "COPYTREND SOLOTHURN",
        "COPYTREND SOLOTHURN"
    ],
    [
        "CUBE PRINTING LTD",
        "CUBE PRINTING"
    ],
    [
        "D&K ID GROUP",
        "D&K GROUP"
    ],
    [
        "DANIEL O'CALLAGHAN",
        "DANIEL O CALLAGHAN"
    ],
    [
        "DAVID KEANE PHOTOGRAPHY",
        "DAVID KEANE PHOTOGRAPHY"
    ],
    [
        "DAVIS PRINTERS LTD.,",
        "DAVIS PRINTERS"
    ],
    [
        "DEDE INDUSTRIEAUSTATTUNG",
        "DEDE INDUSTRIEAUSTATTUNG"
    ],
    [
        "DEERFIELD BEACH FIRE EQUIPMENT INC",
        "DEERFIELD BEACH FIRE EQUIPMENT"
    ],
    [
        "DICKSON UNIGAGE INC",
        "DICKSON UNIGAGE"
    ],
    [
        "DIGIPRODUCT S DE RL DE CV",
        "DIGIPRODUCT"
    ],
    [
        "DIGISTOR DISTRIBUTION",
        "DIGISTOR DISTRIBUTION"
    ],
    [
        "DIGITAL LIZARD LLC",
        "DIGITAL LIZARD"
    ],
    [
        "DIRECTV",
        "DIRECTV"
    ],
    [
        "DKC DIGI KEY CORP",
        "DKC DIGI KEY"
    ],
    [
        "DOLLAR TREE",
        "DOLLAR TREE"
    ],
    [
        "DOLLAR TREE # 00678",
        "DOLLAR TREE"
    ],
    [
        "DOORS COMPUTER SA",
        "DOORS COMPUTER"
    ],
    [
        "EFECTOR INC",
        "EFECTOR"
    ],
    [
        "ELGIN KEY & LOCK CO. INC.",
        "ELGIN KEY & LOCK"
    ],
    [
        "EMMETT FARRELL",
        "EMMETT FARRELL"
    ],
    [
        "ENGELBERT STRAUSS",
        "ENGELBERT STRAUSS"
    ],
    [
        "ERWIN REINFANDT",
        "ERWIN QUARDER"
    ],
    [
        "EXECUTIVE ADVERTISING",
        "EXECUTIVE ADVERTISING"
    ],
    [
        "FAST PRINT AND MARKETING",
        "FAST PRINT AND MARKETING"
    ],
    [
        "FASTSIGNS",
        "FASTSIGNS"
    ],
    [
        "FERD. LEHNARD VERTRIEB",
        "FERD LEHNARD VERTRIEB"
    ],
    [
        "FESTO VERTRIEB GMBH & CO.KG",
        "FESTO VERTRIEB"
    ],
    [
        "FINCK GUILLAUME",
        "FINCK GUILLAUME"
    ],
    [
        "FLORAL DECOR LTD",
        "FLORAL DECOR"
    ],
    [
        "FLOWERS FOREVER",
        "FLOWERS FOREVER"
    ],
    [
        "FORUM VERLAG HERKERT GMBH",
        "FORUM VERLAG HERKERT"
    ],
    [
        "FURRER SEVERINE",
        "FURRER SEVERINE"
    ],
    [
        "G KEOHANE PALLET RECYCLIN",
        "G KEOHANE PALLET RECYCLIN"
    ],
    [
        "GFS MARKET PLACE #492",
        "GFS STORE"
    ],
    [
        "GFS STORE 0492",
        "GFS STORE"
    ],
    [
        "GIH GLOBALINDUSTRIALEQ",
        "GIH GLOBALINDUSTRIALEQ"
    ],
    [
        "GLASSFIBRE&RESIN SUPPLIES",
        "GLASSFIBRE&RESIN SUPPLIES"
    ],
    [
        "GLOBAL EQUIPMENT COMPANY INDUSTRIAL",
        "GLOBAL INDUSTRIAL"
    ],
    [
        "GLOBAL EQUIPMENT",
        "GLOBAL INDUSTRIAL"
    ],
    [
        "GLOBAL EQUIPMENT CO.",
        "GLOBAL INDUSTRIAL"
    ],
    [
        "GOTTSCHALK OLIVER",
        "GOTTSCHALK OLIVER"
    ],
    [
        "GRAPHAX AG",
        "GRAPHAX"
    ],
    [
        "GROUP 4 SECURITAS IRL LTD",
        "GROUP 4 SECURITAS"
    ],
    [
        "GRÖGER, MARTIN",
        "GRÖGER MARTIN"
    ],
    [
        "GÜNTER SCHNEIDER GMBH",
        "GÜNTER SCHNEIDER"
    ],
    [
        "H & W IT SOLUTION  GMBH",
        "H & W SOLUTION"
    ],
    [
        "HACKETT REPROGRAPHICS",
        "HACKETT REPROGRAPHICS"
    ],
    [
        "HAINARD NICOLAS",
        "HAINARD NICOLAS"
    ],
    [
        "HANG ON GMBH",
        "HANG ON"
    ],
    [
        "HAPPY OR NOT",
        "HAPPY OR NOT"
    ],
    [
        "HARBOR FRGHT TLS #265",
        "HARBOR FRGHT TLS"
    ],
    [
        "HARDY DIAGNOSTICS",
        "HARDY DIAGNOSTICS"
    ],
    [
        "HASCO HASENCLEVER GMBH & CO KG",
        "HASCO HASENCLEVER"
    ],
    [
        "HASLER  AG",
        "HASLER CO AG"
    ],
    [
        "HI TECH OPTICAL",
        "HI TECH OPTICAL"
    ],
    [
        "WWW.HIPOT.COM",
        "HIPOT"
    ],
    [
        "HOBBY LOBBY 536",
        "HOBBY LOBBY 536"
    ],
    [
        "HOLLENSTEIN, JUAN CARLOS",
        "HOLLENSTEIN"
    ],
    [
        "HOME DEPOT 2728",
        "HOME DEPOT 2728"
    ],
    [
        "HÖÖTMANN THOMAS",
        "HOOTMANN THOMAS"
    ],
    [
        "HOROTEC SA",
        "HOROTEC"
    ],
    [
        "HUDSON NEWS O'HARE JV",
        "HUDSON NEWS O'HARE JV"
    ],
    [
        "ID WHOLESALER",
        "ID WHOLESALER"
    ],
    [
        "IKONIX USA LLC",
        "IKONIX"
    ],
    [
        "IMRO",
        "IMRO"
    ],
    [
        "IMS (N.A.) SERVICES, LLC *",
        "IMS SERVICES"
    ],
    [
        "INDIGO INSTRUMENTS",
        "INDIGO INSTRUMENTS"
    ],
    [
        "INKUTEC GMBH",
        "INKUTEC"
    ],
    [
        "INSTANT SHREDDING, INC",
        "INSTANT SHREDDING"
    ],
    [
        "INTERACTIVE INTERIORS LTD",
        "INTERACTIVE INTERIORS"
    ],
    [
        "ITEM GMBH",
        "ITEM"
    ],
    [
        "ITUNES.COM/BILL",
        "ITUNES COM BILL"
    ],
    [
        "JACOBSEEN",
        "JACOBSEEN"
    ],
    [
        "JENNIFER HANSEN",
        "JENNIFER HANSEN"
    ],
    [
        "JOKE TECHNOLOGY GMBH",
        "JOKE TECHNOLOGY"
    ],
    [
        "JT CONSULTING",
        "JT CONSULTING"
    ],
    [
        "JT EQUIPMENT SERVICES",
        "JT EQUIPMENT SERVICES"
    ],
    [
        "JURA NEON SA",
        "JURA NEON"
    ],
    [
        "JUST A THOUGHT",
        "JUST A THOUGHT"
    ],
    [
        "J÷RG BRENNWALD",
        "J÷RG BRENNWALD"
    ],
    [
        "KAMAN & LIEBHERR ARBEITSSCHUTZ GMBH",
        "KAMAN & LIEBHERR ARBEITSSCHUTZ"
    ],
    [
        "KARL GMBH & CO.KG",
        "KARL"
    ],
    [
        "KARLE THOMAS",
        "KARLE THOMAS"
    ],
    [
        "KAUFFELDT PRINT + DATA SERV.",
        "KAUFFELDT PRINT + DATA SERV."
    ],
    [
        "KEDINGTON(MUNSTER)LTD",
        "KEDINGTON"
    ],
    [
        "KELLY, EOGHAN",
        "KELLY EOGHAN"
    ],
    [
        "KETTERER + LIEBHERR GMBH",
        "KETTERER LIEBHERR"
    ],
    [
        "KEY SURGICAL",
        "KEY SURGICAL"
    ],
    [
        "KEY SURGICAL INC",
        "KEY SURGICAL"
    ],
    [
        "KLETTLAND.DE BERND LINDEMANN",
        "KLETTLAND BERND LINDEMANN"
    ],
    [
        "KOS ERGONOMICS",
        "KOS ERGONOMICS"
    ],
    [
        "KRUMMEL-MIHAJLOVIC, SABINE",
        "KRUMMEL MIHAJLOVIC SABINE"
    ],
    [
        "LEIDEL-RUCKENBROD GMBH",
        "LEIDEL RUCKENBROD"
    ],
    [
        "LEON ALMADA MARLENE",
        "LEON ALMADA MARLENE"
    ],
    [
        "LIMERICK PRINTING LTD.,",
        "LIMERICK PRINTING"
    ],
    [
        "LRD SYSTEMS & FORMS",
        "LRD SYSTEMS & FORMS"
    ],
    [
        "LYRECO SWITZERLAND AG",
        "LYRECO"
    ],
    [
        "MANUTAN",
        "MANUTAN"
    ],
    [
        "MANUTAN GMBH",
        "MANUTAN"
    ],
    [
        "MANZ EDELSTAHL GMBH",
        "MANZ EDELSTAHL"
    ],
    [
        "MARGUERITE CHARMAINE GOH",
        "MARGUERITE CHARMAINE GOH"
    ],
    [
        "MARIA CRISTINA",
        "MARIA CRISTINA"
    ],
    [
        "MAX F. KELLER GMBH",
        "MAX F KELLER"
    ],
    [
        "MC MASTER-CARR SUPPLY",
        "MC MASTER-CARR SUPPLY"
    ],
    [
        "MEDICON E.G",
        "MEDICON E G"
    ],
    [
        "MEDIENHAUS RETE OHG",
        "MEDIENHAUS RETE"
    ],
    [
        "MEIJER  THREE RIVERSMI  #",
        "MEIJER THREE RIVERSMI"
    ],
    [
        "MEWA TEXTIL-MIETSERVICE",
        "MEWA TEXTIL MIETSERVICE"
    ],
    [
        "MICCO",
        "MICCO"
    ],
    [
        "MICHAELS",
        "MICHAELS"
    ],
    [
        "MICHAELS STORES 9849",
        "MICHAELS STORES"
    ],
    [
        "MICHIGAN OFFICE ENVIRON.",
        "MICHIGAN OFFICE ENVIRONMENTS"
    ],
    [
        "MICHIGAN OFFICE ENVIRONME",
        "MICHIGAN OFFICE ENVIRONMENTS"
    ],
    [
        "MICHIGAN OFFICE ENVIRONMENTS",
        "MICHIGAN OFFICE ENVIRONMENTS"
    ],
    [
        "MICROSOFT CORP",
        "MICROSOFT"
    ],
    [
        "MICROSOFT CORPORATION",
        "MICROSOFT"
    ],
    [
        "MINER FLORIDA LTD",
        "MINER FLORIDA"
    ],
    [
        "MINUTEMAN PRESS *",
        "MINUTEMAN PRESS"
    ],
    [
        "MJ FLOOD",
        "MJ FLOOD"
    ],
    [
        "MJ SCANNELL SAFETY",
        "MJ SCANNELL SAFETY"
    ],
    [
        "MKS GRAPHICS INC",
        "MKS GRAPHICS"
    ],
    [
        "MOSQUEDA SUAREZ FELIPE",
        "MOSQUEDA SUAREZ FELIPE"
    ],
    [
        "MR GS KEYS AND MORE",
        "MR GS KEYS AND MORE"
    ],
    [
        "MULTI COLOR CORPORATION",
        "MULTI COLOR CORPORATION"
    ],
    [
        "MÜLLER & WELTE",
        "MÜLLER & WELTE"
    ],
    [
        "NAGEL WERKZEUG-MASCHINEN GMBH",
        "NAGEL WERKZEUG MASCHINEN"
    ],
    [
        "NAPA AUTO PARTS",
        "NAPA AUTO PARTS"
    ],
    [
        "NATHANIEL MILLER *",
        "NATHANIEL MILLER"
    ],
    [
        "NELSON HARDWARE OF PORTAG",
        "NELSON HARDWARE OF PORTAG"
    ],
    [
        "NEWS AND GIFT SHOPS INTER",
        "NEWS AND GIFT SHOPS INTER"
    ],
    [
        "NEXT DESIGN + PRODUKTION GMBH",
        "NEXT DESIGN PRODUKTION"
    ],
    [
        "NORDSON DEUTSCHLAND GMBH",
        "NORDSON"
    ],
    [
        "NORTHERN PRINTING NETWORK",
        "NORTHERN PRINTING NETWORK"
    ],
    [
        "NRS PRINTING SOLUTIONS AG",
        "NRS PRINTING SOLUTIONS"
    ],
    [
        "O BRIEN DIARIES",
        "O BRIEN DIARIES"
    ],
    [
        "O NEILL INDUSTRIAL LTD",
        "O NEILL INDUSTRIAL"
    ],
    [
        "OBERG MEDICAL",
        "OBERG MEDICAL"
    ],
    [
        "OCON CHEMICALS LTD.,",
        "OCON CHEMICALS"
    ],
    [
        "OFFICE DEPOT, INC",
        "OFFICE DEPOT"
    ],
    [
        "OFFICE DEPOT",
        "OFFICE DEPOT"
    ],
    [
        "OFFICE DEPOT #235",
        "OFFICE DEPOT"
    ],
    [
        "OFFICE DEPOT #6869",
        "OFFICE DEPOT"
    ],
    [
        "OFFICE DEPOT 2346",
        "OFFICE DEPOT"
    ],
    [
        "OFFICE DEPOT DE MEXICO SA DE CV",
        "OFFICE DEPOT"
    ],
    [
        "OFFICE MAX/DEPOT #6078",
        "OFFICE MAX"
    ],
    [
        "OFFICE MAX/DEPOT #6529",
        "OFFICE MAX"
    ],
    [
        "OFFICE MAX/DEPOT #6667",
        "OFFICE MAX"
    ],
    [
        "OFFICE SCENE SOLUTIONS",
        "OFFICE SCENE SOLUTIONS"
    ],
    [
        "OFFICE TIMELINE",
        "OFFICE TIMELINE"
    ],
    [
        "OFFICEMAX A BOISE CO",
        "OFFICEMAX DEPOT"
    ],
    [
        "OFFICEMAX/DEPOT 6078",
        "OFFICEMAX DEPOT"
    ],
    [
        "OFFICEMAX/DEPOT 6116",
        "OFFICEMAX DEPOT"
    ],
    [
        "OFFICEMAX/DEPOT 6869",
        "OFFICEMAX DEPOT"
    ],
    [
        "OFFICEMAX/OFFICE DEPOT607",
        "OFFICEMAX DEPOT"
    ],
    [
        "OFFICEMAX/OFFICEDEPT#6869",
        "OFFICEMAX DEPOT"
    ],
    [
        "OMEGA   ENGINEERING",
        "OMEGA ENGINEERING"
    ],
    [
        "OMNIPRINT GMBH",
        "OMNIPRINT"
    ],
    [
        "ORDAZ LEON MARIO",
        "ORDAZ LEON MARIO"
    ],
    [
        "ORGATEX GMBH & CO.KG",
        "ORGATEX"
    ],
    [
        "O`BRIEN OFFICE SYSTEMS",
        "O`BRIEN OFFICE SYSTEMS"
    ],
    [
        "PARADISE PALM INC",
        "PARADISE PALM"
    ],
    [
        "PARTY CITY",
        "PARTY CITY"
    ],
    [
        "PAT PATAGONIADIRECTINC",
        "PAT PATAGONIADIRECTINC"
    ],
    [
        "PAUL LEIBINGER GMBH & CO.KG",
        "PAUL LEIBINGER"
    ],
    [
        "PAYPAL  ATILEKT INC",
        "PAYPAL"
    ],
    [
        "PAYPAL  JETS INC",
        "PAYPAL"
    ],
    [
        "PCM SALES INC",
        "PCM SALES"
    ],
    [
        "PCM SALES, INC.",
        "PCM SALES"
    ],
    [
        "PERFORMANCE COMMUNICATION",
        "PERFORMANCE COMMUNICATION"
    ],
    [
        "PERFORMANCE COMMUNICATIONS",
        "PERFORMANCE COMMUNICATIONS"
    ],
    [
        "PIXELRAUSCH DESIGN& PRODUKTION E.K.",
        "PIXELRAUSCH DESIGN& PRODUKTION E K"
    ],
    [
        "PLANT DESIGN",
        "PLANT DESIGN"
    ],
    [
        "PLASTIKA BALUMAG AG",
        "PLASTIKA BALUMAG"
    ],
    [
        "POMEROY IT SOLUTIONS",
        "POMEROY IT SOLUTIONS"
    ],
    [
        "POMEROY IT SOLUTIONS SALES COMPANY INC",
        "POMEROY IT SOLUTIONS SALES COMPANY"
    ],
    [
        "POWERTECH CONTROLS CO.",
        "POWERTECH CONTROLS"
    ],
    [
        "PRECISION DYNAMIC CORPORATION INC",
        "PRECISION DYNAMIC"
    ],
    [
        "PRINT PROFILE",
        "PRINT PROFILE"
    ],
    [
        "PRINTING SERVICES",
        "PRINTING SERVICES"
    ],
    [
        "PRINTING SERVICES INC",
        "PRINTING SERVICES"
    ],
    [
        "PRINTING SERVICES, INC.",
        "PRINTING SERVICES"
    ],
    [
        "PRISM ENGINEERING LTD.",
        "PRISM ENGINEERING"
    ],
    [
        "PRO-TECH METAL SPECIALTIES, INC.",
        "PRO TECH METAL SPECIALTIES"
    ],
    [
        "PROCASE MANUFAKTUR GMBH",
        "PROCASE MANUFAKTUR"
    ],
    [
        "PROFESSIONAL SECURITY",
        "PROFESSIONAL SECURITY"
    ],
    [
        "PROJECT SIGN",
        "PROJECT SIGN"
    ],
    [
        "PROLINE PRINTING&COPYING",
        "PROLINE PRINTING&COPYING"
    ],
    [
        "PRONOL GMBH",
        "PRONOL"
    ],
    [
        "R. SABEE CO *",
        "R SABEE"
    ],
    [
        "RADIONICS",
        "RADIONICS"
    ],
    [
        "READYREFRESH",
        "READYREFRESH"
    ],
    [
        "REBILL AMAZON MKTPLACE PM - 0101",
        "REBILL AMAZON MKTPLACE PM"
    ],
    [
        "REPROGRAPHICS",
        "REPROGRAPHICS"
    ],
    [
        "RESIN GMBH&CO.KG",
        "RESIN"
    ],
    [
        "RESTEK CORPORATION",
        "RESTEK"
    ],
    [
        "RETTENBERGER",
        "RETTENBERGER"
    ],
    [
        "RX OPTICAL LABORATORIES INC",
        "RX OPTICAL LABORATORIES"
    ],
    [
        "SABINE KOSMA",
        "SABINE KOSMA"
    ],
    [
        "SAMS CLUB #6661",
        "SAMS CLUB"
    ],
    [
        "SANCHEZ VICTOR",
        "SANCHEZ VICTOR"
    ],
    [
        "SARTORIUS LAB INSTRUMENTS",
        "SARTORIUS LAB INSTRUMENTS"
    ],
    [
        "SCHÄFER SHOP GMBH",
        "SCHAFER SHOP"
    ],
    [
        "SCHAFFERER & CO. KG",
        "SCHAFFERER"
    ],
    [
        "SCHLUP AG",
        "SCHLUP"
    ],
    [
        "SCHWARZ AG",
        "SCHWARZ"
    ],
    [
        "SCHÜLKE & MAYR GMBH",
        "SCHÜLKE & MAYR"
    ],
    [
        "SECURITY 101",
        "SECURITY"
    ],
    [
        "SELK, JAN",
        "SELK JAN"
    ],
    [
        "SHL AG",
        "SHL"
    ],
    [
        "SHOP VAC CORPORATION",
        "SHOP VAC"
    ],
    [
        "SHRED-IT IRELAND",
        "SHRED-IT IRELAND"
    ],
    [
        "SIG WORKPLACE",
        "SIG WORKPLACE"
    ],
    [
        "SIGG STRAHLTECHNIK GMBH",
        "SIGG STRAHLTECHNIK"
    ],
    [
        "SIGMA-ALDRICH, INC.",
        "SIGMA ALDRICH"
    ],
    [
        "SIGN STUDIO INC",
        "SIGN STUDIO"
    ],
    [
        "SMARTPRESS.COM",
        "SMARTPRESS COM"
    ],
    [
        "SNAP PRINTING",
        "SNAP PRINTING"
    ],
    [
        "SONSTIGE",
        "SONSTIGE"
    ],
    [
        "SP   CLEVERMADE, LLC.",
        "SP CLEVERMADE"
    ],
    [
        "SP   SOLDERTRAINING.CO",
        "SP SOLDERTRAINING"
    ],
    [
        "SPARKS BELTING COMPANY *",
        "SPARKS BELTING"
    ],
    [
        "SPORT BUTSCH GMBH & CO.KG",
        "SPORT BUTSCH"
    ],
    [
        "SQ  FIRST CLASS PRODUCTIO",
        "SQ FIRST CLASS PRODUCTIO"
    ],
    [
        "SSI SCHÄFER SHOP GMBH",
        "SSI SCHÄFER SHOP"
    ],
    [
        "STAND UP DESK STORE",
        "STAND UP DESK STORE"
    ],
    [
        "STAPLES",
        "STAPLES"
    ],
    [
        "STAPLES DIRECT",
        "STAPLES"
    ],
    [
        "WWW.STAPLES.COM 472",
        "STAPLES"
    ],
    [
        "STAPLES ADVANTAGE IRELAND LTD",
        "STAPLES ADVANTAGE"
    ],
    [
        "STAPLES ADVANTAGE",
        "STAPLES ADVANTAGE"
    ],
    [
        "STAPLES BUSINESS ADVANTAGE",
        "STAPLES ADVANTAGE"
    ],
    [
        "STAPLES CONTRACT & COMMER",
        "STAPLES ADVANTAGE"
    ],
    [
        "STAPLES CONTRACT & COMMERCIAL INC",
        "STAPLES ADVANTAGE"
    ],
    [
        "STAPLES BUSINESS ADVANTAG",
        "STAPLES BUSINESS ADVANTAG"
    ],
    [
        "STAPLS3146257783000",
        "STAPLS3146257783000"
    ],
    [
        "STAPLS7188226112000001",
        "STAPLS7188226112000001"
    ],
    [
        "STAPLS7188226183000001",
        "STAPLS7188226183000001"
    ],
    [
        "STAPLS7188306430000001",
        "STAPLS7188306430000001"
    ],
    [
        "STAPLS7188342843000001",
        "STAPLS7188342843000001"
    ],
    [
        "STAPLS7188497278000001",
        "STAPLS7188497278000001"
    ],
    [
        "STAPLS7188506001000001",
        "STAPLS7188506001000001"
    ],
    [
        "STAPLS7188953450000001",
        "STAPLS7188953450000001"
    ],
    [
        "STAPLS7189546319000001",
        "STAPLS7189546319000001"
    ],
    [
        "STAPLS7189546319000002",
        "STAPLS7189546319000002"
    ],
    [
        "STAPLS7189546319001002",
        "STAPLS7189546319001002"
    ],
    [
        "STAPLS7189546319002001",
        "STAPLS7189546319002001"
    ],
    [
        "STAPLS7189609964000001",
        "STAPLS7189609964000001"
    ],
    [
        "STAPLS7189616011000001",
        "STAPLS7189616011000001"
    ],
    [
        "STAPLS7190343347000001",
        "STAPLS7190343347000001"
    ],
    [
        "STAPLS7190619989000001",
        "STAPLS7190619989000001"
    ],
    [
        "STAPLS7197230496000001",
        "STAPLS7197230496000001"
    ],
    [
        "STAPLS7197607065000001",
        "STAPLS7197607065000001"
    ],
    [
        "STAPLS7197607065000002",
        "STAPLS7197607065000002"
    ],
    [
        "STAPLS7198004205000001",
        "STAPLS7198004205000001"
    ],
    [
        "STAPLS7198004205000002",
        "STAPLS7198004205000002"
    ],
    [
        "STAPLS7198004205000003",
        "STAPLS7198004205000003"
    ],
    [
        "STAPLS7199408936000001",
        "STAPLS7199408936000001"
    ],
    [
        "STAPLS7199859905000001",
        "STAPLS7199859905000001"
    ],
    [
        "STAPLS7201752108000001",
        "STAPLS7201752108000001"
    ],
    [
        "STAPLS7201752108001001",
        "STAPLS7201752108001001"
    ],
    [
        "STAPLS7201752108002001",
        "STAPLS7201752108002001"
    ],
    [
        "STAPLS7201752108003001",
        "STAPLS7201752108003001"
    ],
    [
        "STAPLS7201752108004001",
        "STAPLS7201752108004001"
    ],
    [
        "STAPLS7203279372000001",
        "STAPLS7203279372000001"
    ],
    [
        "STAPLS7203845500000001",
        "STAPLS7203845500000001"
    ],
    [
        "STAPLS7203845500000002",
        "STAPLS7203845500000002"
    ],
    [
        "STAPLS7203951914000001",
        "STAPLS7203951914000001"
    ],
    [
        "STAPLS7203951914000002",
        "STAPLS7203951914000002"
    ],
    [
        "STAPLS7204745438000001",
        "STAPLS7204745438000001"
    ],
    [
        "STAPLS7205045205000001",
        "STAPLS7205045205000001"
    ],
    [
        "STAPLS7205940197000001",
        "STAPLS7205940197000001"
    ],
    [
        "STAPLS7208457091000001",
        "STAPLS7208457091000001"
    ],
    [
        "STAPLS7208457091000002",
        "STAPLS7208457091000002"
    ],
    [
        "STAPLS7208457091000003",
        "STAPLS7208457091000003"
    ],
    [
        "STAPLS7208457091000004",
        "STAPLS7208457091000004"
    ],
    [
        "STAPLS7208483550000001",
        "STAPLS7208483550000001"
    ],
    [
        "STEINWALD DATENTECHNIK GMBH",
        "STEINWALD DATENTECHNIK"
    ],
    [
        "STEMPEL.CH",
        "STEMPEL"
    ],
    [
        "STEMPEL-POTZ",
        "STEMPEL POTZ"
    ],
    [
        "STEMPEL STÜDELI GMBH",
        "STEMPEL STUDELI"
    ],
    [
        "STERIPACK MEDICAL POLAND SP. ZO.O.",
        "STERIPACK MEDICAL ZO O"
    ],
    [
        "STERN MONTAGEBAU",
        "STERN MONTAGEBAU"
    ],
    [
        "STOP PAINTING.COM/INSITE",
        "STOP PAINTING.COM/INSITE"
    ],
    [
        "STRATASYS",
        "STRATASYS"
    ],
    [
        "STREIT SERVICE&SOLUTION  GMBH&CO.",
        "STREIT SERVICE&SOLUTION"
    ],
    [
        "SWEETWATERS DONUT MILL",
        "SWEETWATERS DONUT MILL"
    ],
    [
        "SYSTRONICS",
        "SYSTRONICS"
    ],
    [
        "T.D.S.(TIME DATE SECURITY",
        "T.D.S.(TIME DATE SECURITY)"
    ],
    [
        "TARGET T1241",
        "TARGET T1241"
    ],
    [
        "TARGET.COM",
        "TARGET.COM"
    ],
    [
        "TAXIUNTERNEHMEN REINHART",
        "TAXIUNTERNEHMEN REINHART"
    ],
    [
        "TDS LTD",
        "TDS LTD"
    ],
    [
        "TED KELLEHER",
        "TED KELLEHER"
    ],
    [
        "TELE-COMMUNICATION INC",
        "TELE COMMUNICATION"
    ],
    [
        "TELE-COMMUNICATION, INC.",
        "TELE COMMUNICATION"
    ],
    [
        "TELE-COMMUNICATIONS, INC",
        "TELE COMMUNICATION"
    ],
    [
        "THE HOME DEPOT #2728",
        "THE HOME DEPOT"
    ],
    [
        "THE LOCK PEOPLE",
        "THE LOCK PEOPLE"
    ],
    [
        "THE SIGN SHOP",
        "THE SIGN SHOP"
    ],
    [
        "THE UPS STORE #0991",
        "THE UPS STORE"
    ],
    [
        "THRAN, DANIEL",
        "THRAN, DANIEL"
    ],
    [
        "TIGER DIRECT, INC.",
        "TIGER DIRECT"
    ],
    [
        "TRANSCAT INC",
        "TRANSCAT"
    ],
    [
        "TRIANGLE MANUFACTURING CO",
        "TRIANGLE MANUFACTURING"
    ],
    [
        "UNIFIED COMMUNICATIONS",
        "UNIFIED COMMUNICATIONS"
    ],
    [
        "UNIVAR USA INC",
        "UNIVAR USA INC"
    ],
    [
        "UVEX ARBEITSSCHUTZ GMBH",
        "UVEX ARBEITSSCHUTZ"
    ],
    [
        "VARIDESK LLC",
        "VARIDESK"
    ],
    [
        "VARIDESK",
        "VARIDESK"
    ],
    [
        "W.E. GUT GEDRUCKT GMBH",
        "W.E. GUT GEDRUCKT"
    ],
    [
        "WAHL, THOMAS",
        "WAHL, THOMAS"
    ],
    [
        "WALTER PAPKE",
        "WALTER PAPKE"
    ],
    [
        "WALTER VÖGELE GMBH",
        "WALTER VÖGELE"
    ],
    [
        "WAREHOUSE SUPPLIES ONLINE, LLC",
        "WAREHOUSE SUPPLIES ONLINE"
    ],
    [
        "WERO GMBH & CO.KG",
        "WERO"
    ],
    [
        "WEST MICHIGAN SCALES CO., INC.",
        "WEST MICHIGAN SCALES"
    ],
    [
        "WESTPHAL, DIRK",
        "WESTPHAL DIRK"
    ],
    [
        "WOLF BESCHICHTUNGSTECHNOLOGIE GMBH",
        "WOLF BESCHICHTUNGSTECHNOLOGIE"
    ],
    [
        "WOLK AG",
        "WOLK"
    ],
    [
        "WSA OFFICE PROJECT",
        "WSA OFFICE PROJECT"
    ],
    [
        "WYSS MAIK",
        "WYSS MAIK"
    ],
    [
        "WÜRTH INDUSTRIE SERVICE GMBH",
        "WÜRTH INDUSTRIE SERVICE"
    ],
    [
        "XEROX CORPORATION",
        "XEROX"
    ],
    [
        "ZETES IRELAND",
        "ZETES"
    ],
    [
        "AMERICAN PAPER CORP",
        "AMERICAN PAPER"
    ],
    [
        "STAPLES, INC.",
        "STAPLES"
    ],
    [
        "AIRMATIC COMPRESSOR SALES & SERVICES INC",
        "AIRMATIC COMPRESSOR SALES & SERVICES"
    ],
    [
        "ARROW ELEVATOR INC",
        "ARROW ELEVATOR"
    ],
    [
        "AS AUFZÜGE AG",
        "AS AUFZUGE"
    ],
    [
        "BHL LANDSCAPE GROUP LTD",
        "BHL LANDSCAPE GROUP"
    ],
    [
        "CD MEASUREMENTS LTD",
        "CD MEASUREMENTS"
    ],
    [
        "CHRONO 35 SERVICES (EXCHRONO",
        "CHRONO 35 SERVICES"
    ],
    [
        "CONCEPTION LAB INC",
        "CONCEPTION LAB"
    ],
    [
        "CONCEPTION LAB INC.",
        "CONCEPTION LAB"
    ],
    [
        "CRV CONTROL SYSTEMS LTD",
        "CRV CONTROL SYSTEMS"
    ],
    [
        "DANNY RODRIGUEZ DIAZ",
        "DANNY RODRIGUEZ DIAZ"
    ],
    [
        "EASTERN LIFT TRUCK CO",
        "EASTERN LIFT TRUCK"
    ],
    [
        "ELECTRICAL INSTALLATION AND SERVICE CORP",
        "ELECTRICAL INSTALLATION AND SERVICE"
    ],
    [
        "ENERGY CONSTRUCTION SERVICES INC",
        "ENERGY CONSTRUCTION SERVICES"
    ],
    [
        "ENGIE AXIMA",
        "ENGIE AXIMA"
    ],
    [
        "GENSERVE INC",
        "GENSERVE"
    ],
    [
        "GLEBAR COMPANY",
        "GLEBAR"
    ],
    [
        "GRAINGER INC",
        "GRAINGER"
    ],
    [
        "GRAINGER 931",
        "GRAINGER"
    ],
    [
        "GRAINGER INCORPORATED",
        "GRAINGER"
    ],
    [
        "GRAINGER INDUSTRIAL SUPP",
        "GRAINGER"
    ],
    [
        "GRAINGER, INC.",
        "GRAINGER"
    ],
    [
        "W.W. GRAINGER, INC.",
        "GRAINGER"
    ],
    [
        "WW GRAINGER",
        "GRAINGER"
    ],
    [
        "INDUCTOTHERM CORP",
        "INDUCTOTHERM"
    ],
    [
        "INNOVMETRIC SOFTWARE INC",
        "INNOVMETRIC SOFTWARE"
    ],
    [
        "INSTRUMED SERVICES CO",
        "INSTRUMED SERVICES"
    ],
    [
        "ISLA LAB PRODUCTS LLC",
        "ISLA LAB PRODUCTS"
    ],
    [
        "JONES & SHIPMAN",
        "JONES & SHIPMAN"
    ],
    [
        "KWALYTI TOOLING AND *",
        "KWALYTI TOOLING AND MACHINERY REBUILDING"
    ],
    [
        "LBBC TECHNOLOGIES",
        "LBBC TECHNOLOGIES"
    ],
    [
        "LES ATELIERS MODERNES SA",
        "LES ATELIERS MODERNES"
    ],
    [
        "LOGWAY INFORMATIK AG",
        "LOGWAY INFORMATIK"
    ],
    [
        "LONZA WALKERSVILLE INC",
        "LONZA WALKERSVILLE"
    ],
    [
        "MAQUINARTE SA DE CV",
        "MAQUINARTE"
    ],
    [
        "MIDLAND IND.MAINTENANCE",
        "MIDLAND MAINTENANCE"
    ],
    [
        "MTS SYSTEMS CORPORATION",
        "MTS SYSTEMS"
    ],
    [
        "PARTICLE MEASURING SYST",
        "PARTICLE MEASURING SYST"
    ],
    [
        "PEAK MACHINE TOOLS INC",
        "PEAK MACHINE TOOLS"
    ],
    [
        "PRECISION OVERHEAD DOOR,",
        "PRECISION OVERHEAD DOOR"
    ],
    [
        "PROWATECH AG",
        "PROWATECH"
    ],
    [
        "QUALITY VISION SERVICES INC",
        "QUALITY VISION SERVICES"
    ],
    [
        "ROGERS PUMP SALES & SERV. *",
        "ROGERS PUMP SALES & SERV"
    ],
    [
        "SCHWEIFLTECHNIK KIEL",
        "SCHWEI TECHNIK KIEL"
    ],
    [
        "SUPPRESSION SYSTEMS INC",
        "SUPPRESSION SYSTEMS"
    ],
    [
        "TARIMAS GORZELL",
        "TARIMAS GORZELL"
    ],
    [
        "TIMCO LLC *",
        "TIMCO"
    ],
    [
        "TECHNICAL MAINTENANCE, INC.",
        "TMI CALIBRATION"
    ],
    [
        "TURTLE & HUGHES INTEGRATED SUPPLY INC",
        "TURTLE & HUGHES INTEGRATED SUPPLY"
    ],
    [
        "ELLSWORTH ADHESIVE",
        "ELLSWORTH ADHESIVES"
    ],
    [
        "ELLSWORTH ADHESIVE S",
        "ELLSWORTH ADHESIVES"
    ],
    [
        "ELLSWORTH ADHESIVES",
        "ELLSWORTH ADHESIVES"
    ],
    [
        "ELLSWORTH ADHEREL000",
        "ELLSWORTH ADHESIVES"
    ],
    [
        "ELLSWORTH ADHESIVE SYSTEM",
        "ELLSWORTH ADHESIVES"
    ],
    [
        "TEASTECH LTD",
        "TEASTECH"
    ],
    [
        "BUNTROCK INDUSTRIES INC",
        "BUNTROCK INDUSTRIES"
    ],
    [
        "HI-TEMP PRODUCTS CORP",
        "HI TEMP PRODUCTS"
    ],
    [
        "HI TEMP PRODUCTS CORP",
        "HI TEMP PRODUCTS"
    ],
    [
        "CARL BECHEM SWITZERLAND AG",
        "BECHEM LUBRICATION TECHNOLOGY"
    ],
    [
        "MOTOREX AG",
        "MOTOREX"
    ],
    [
        "THOMMEN-FURLER AG",
        "THOMMEN-FURLER AG"
    ],
    [
        "WARREN FASTENINGS SOUTH",
        "WARREN FASTENINGS SOUTH"
    ],
    [
        "ACCUTOOL LTD.",
        "ACCUTOOL"
    ],
    [
        "ACCUTOOL PRECISION ENGINEERING LTD.",
        "ACCUTOOL"
    ],
    [
        "WALSH VMI (ENG) LTD.",
        "WALSH VMI"
    ],
    [
        "WALSH VMI LTD",
        "WALSH VMI"
    ],
    [
        "2 WAY RADIO SERVICE",
        "2 WAY RADIO SERVICE"
    ],
    [
        "5S SUPPLIES LLC",
        "5S SUPPLIES"
    ],
    [
        "8020 INC",
        "8020 INC"
    ],
    [
        "A FOURNIER AND ASSOC",
        "A FOURNIER AND ASSOC"
    ],
    [
        "A. GRAMER PAINTING AND",
        "A GRAMER PAINTING AND"
    ],
    [
        "A.H. OFFICE COFFEE *",
        "A H OFFICE COFFEE"
    ],
    [
        "AA ELECTRIC INC",
        "AA ELECTRIC"
    ],
    [
        "AB SALES",
        "AB SALES"
    ],
    [
        "ABACUS CATERING LTD",
        "ABACUS CATERING"
    ],
    [
        "ABC SWISSTECH",
        "ABC SWISSTECH"
    ],
    [
        "ABH-NORD GMBH",
        "ABH NORD"
    ],
    [
        "ABLE FIRST AID, INC. *",
        "ABLE FIRST AID"
    ],
    [
        "ABLE INSTRUMENTS &CONTROL",
        "ABLE INSTRUMENTS &CONTROL"
    ],
    [
        "ABRAMS PREMIUM STAHL",
        "ABRAMS PREMIUM STAHL"
    ],
    [
        "ABT ELECTRONICS",
        "ABT ELECTRONICS"
    ],
    [
        "ACCU TRAK TOOL CORP",
        "ACCU TRAK TOOL"
    ],
    [
        "ACCUBILT INC",
        "ACCUBILT"
    ],
    [
        "ACCURATE SOLUTIONS & DESIGNS INC",
        "ACCURATE SOLUTIONS & DESIGNS"
    ],
    [
        "ACCURATE TEMPERATURE CONTROL CORP.",
        "ACCURATE TEMPERATURE CONTROL"
    ],
    [
        "ACCUTEC BLADES",
        "ACCUTEC BLADES"
    ],
    [
        "ACCUTECH",
        "ACCUTECH"
    ],
    [
        "ACORN PROCESSES LTD.,",
        "ACORN PROCESSES"
    ],
    [
        "ACROFAB INC",
        "ACROFAB"
    ],
    [
        "ACS AUXILIARIES GROUP, INC. *",
        "ACS AUXILIARIES GROUP"
    ],
    [
        "ACTEGA NORTH AMERICA, INC. *",
        "ACTEGA NORTH"
    ],
    [
        "ADAMS STEEL SERVICE & SUPPLY, INC.",
        "ADAMS STEEL SERVICE & SUPPLY"
    ],
    [
        "ADBO WERBUNG & DESIGN GMBH",
        "ADBO WERBUNG & DESIGN"
    ],
    [
        "ADEFI",
        "ADEFI"
    ],
    [
        "ADMIRAL ENVIRONMENTAL *",
        "ADMIRAL ENVIRONMENTAL"
    ],
    [
        "ADOLF RICHTER",
        "ADOLF RICHTER"
    ],
    [
        "ADVANCE AUTO PARTS",
        "ADVANCE AUTO PARTS"
    ],
    [
        "ADVANCE TURNING & MFG.,IN",
        "ADVANCE TURNING & MFG"
    ],
    [
        "ADVANCED AIR PRODUCTS",
        "ADVANCED AIR PRODUCTS"
    ],
    [
        "ADVANCED CONCEPTS & SOLUTIONS LLC",
        "ADVANCED CONCEPTS & SOLUTIONS"
    ],
    [
        "ADV SAFETY",
        "ADVANCED SAFETY & INDUSTRIAL SUPPLY"
    ],
    [
        "ADVANCED TESTING LAB *",
        "ADVANCED TESTING LAB"
    ],
    [
        "AERDALE FIRE SECURITY LTD",
        "AERDALE FIRE SECURITY"
    ],
    [
        "AERODINE MACHINE",
        "AERODINE MACHINE"
    ],
    [
        "AFFOLTER HAUSHALTAPPARATE",
        "AFFOLTER HAUSHALTAPPARATE"
    ],
    [
        "AFTR",
        "AFTR"
    ],
    [
        "AIO",
        "AIO"
    ],
    [
        "AIR COMPONENTS INC",
        "AIR COMPONENTS"
    ],
    [
        "AIR COMPONENTS INC.",
        "AIR COMPONENTS"
    ],
    [
        "AIR LIQUIDE FRANCE INDUSTRIE",
        "AIR LIQUIDE"
    ],
    [
        "AIR RITE SERVICE SUPPLY",
        "AIR RITE SERVICE SUPPLY"
    ],
    [
        "AIRMES AG",
        "AIRMES"
    ],
    [
        "ALCO SA",
        "ALCO"
    ],
    [
        "3505 ALL PHASE",
        "ALL PHASE"
    ],
    [
        "ALL-PHASE ELECTRIC SUPPLY",
        "ALL PHASE ELECTRIC SUPPLY"
    ],
    [
        "ALLEGA GMBH",
        "ALLEGA"
    ],
    [
        "ALLIANCE TECHNOLOGIES",
        "ALLIANCE TECHNOLOGIES"
    ],
    [
        "ALLOY STEEL TREATING COMPANY",
        "ALLOY STEEL TREATING"
    ],
    [
        "ALLTECH SUPPLY, INC. *",
        "ALLTECH SUPPLY"
    ],
    [
        "ALMETA AG",
        "ALMETA"
    ],
    [
        "ALOXYD AG",
        "ALOXYD"
    ],
    [
        "ALPHACAM GMBH",
        "ALPHACAM"
    ],
    [
        "ALPHAGRAPHICS US252, INC.",
        "ALPHAGRAPHICS US252"
    ],
    [
        "ALPHASET DESIGN LTD",
        "ALPHASET DESIGN"
    ],
    [
        "ALRO INDUSTRIAL SUPPLY CO",
        "ALRO"
    ],
    [
        "ALRO INDUSTRIAL SUPPLY CORP",
        "ALRO"
    ],
    [
        "ALRO STEEL CORP",
        "ALRO"
    ],
    [
        "ALRO STEEL CORPORATION",
        "ALRO"
    ],
    [
        "ALTA ENTERPRISES INC",
        "ALTA ENTERPRISES"
    ],
    [
        "AMADA AMERICA, INC",
        "AMADA"
    ],
    [
        "AMADA MIYACHI AMERICA INC",
        "AMADA"
    ],
    [
        "AMADA MIYACHI REL000",
        "AMADA"
    ],
    [
        "AMBIUS",
        "AMBIUS"
    ],
    [
        "AMERICAN HAKKO",
        "AMERICAN HAKKO"
    ],
    [
        "AMERICAN TRUETZSCHLER INC",
        "AMERICAN TRUETZSCHLER"
    ],
    [
        "AMERISOURCE INDUSTRIAL",
        "AMERISOURCE"
    ],
    [
        "AMERISOURCE INDUSTRIAL SUPPLY",
        "AMERISOURCE"
    ],
    [
        "AMERISOURCE INDUSTRIAL *",
        "AMERISOURCE"
    ],
    [
        "ANDEA CORP.",
        "ANDEA"
    ],
    [
        "ANDEC STAINLESS",
        "ANDEC STAINLESS"
    ],
    [
        "ANDREW FELLER",
        "ANDREW FELLER"
    ],
    [
        "ANOPOL LIMITED",
        "ANOPOL"
    ],
    [
        "ANZY SUPPLY INC. *",
        "ANZY SUPPLY"
    ],
    [
        "AOP TECHNOLOGIES, INC.",
        "AOP TECHNOLOGIES"
    ],
    [
        "API AIRPURIFIERS INC",
        "API AIRPURIFIERS"
    ],
    [
        "APPLIED IMAGING",
        "APPLIED IMAGING"
    ],
    [
        "APPLIED IND TECH 2760",
        "APPLIED IND TECH"
    ],
    [
        "APS MATERIALS,INC.",
        "APS MATERIALS"
    ],
    [
        "ARAMSCO",
        "ARAMSCO"
    ],
    [
        "ARCADIA COMPONENTS, LLC",
        "ARCADIA COMPONENTS"
    ],
    [
        "ARMCHEM INTERNATIONAL",
        "ARMCHEM INTERNATIONAL"
    ],
    [
        "ARMOR PRODUCTS, LLC.",
        "ARMOR PRODUCTS"
    ],
    [
        "ARROYO TRABUCO GOLF CLUB",
        "ARROYO TRABUCO GOLF CLUB"
    ],
    [
        "ARTECHEMIS AG",
        "ARTECHEMIS"
    ],
    [
        "ARTHUR KRUEGER GMBH",
        "ARTHUR KRUEGER"
    ],
    [
        "ASG SERVICES, LLC *",
        "ASG SERVICES"
    ],
    [
        "ASGAARD PACKAGING",
        "ASGAARD PACKAGING"
    ],
    [
        "ASSOCIATED BAG CO",
        "ASSOCIATED BAG"
    ],
    [
        "ASSOCIATED BAG COMPANY",
        "ASSOCIATED BAG"
    ],
    [
        "ASTUTE ELECTRONICS INC",
        "ASTUTE ELECTRONICS"
    ],
    [
        "AT SAFETY",
        "AT SAFETY"
    ],
    [
        "ATLANTIC SCALE COMPANY",
        "ATLANTIC SCALE"
    ],
    [
        "ATLAS RIGGING & TRANSFER,",
        "ATLAS RIGGING & TRANSFER"
    ],
    [
        "ATLAS TOYOTA MATERIAL HANDLING",
        "ATLAS TOYOTA MATERIAL HANDLING"
    ],
    [
        "ATS LTD",
        "ATS"
    ],
    [
        "ATS SYSTEMS",
        "ATS SYSTEMS"
    ],
    [
        "AUGUST KRAEMER GMBH",
        "AUGUST KRAEMER GMBH"
    ],
    [
        "AUSGLEICHSKASSE",
        "AUSGLEICHSKASSE"
    ],
    [
        "AUTOMATIC SUPPRESSION & ALARM SYSTEMS INC",
        "AUTOMATIC SUPPRESSION & ALARM SYSTEMS"
    ],
    [
        "AUTOMATION DIRECT",
        "AUTOMATION DIRECT"
    ],
    [
        "AUTOMATION SALES INC",
        "AUTOMATION SALES"
    ],
    [
        "AUTOMATIONDIRECT.COM",
        "AUTOMATIONDIRECT.COM"
    ],
    [
        "AVENIR MECANIQUE",
        "AVENIR MECANIQUE"
    ],
    [
        "AWS ULRICH STEINER",
        "AWS ULRICH STEINER"
    ],
    [
        "AWT LABELS AND PACKAGING *",
        "AWT LABELS AND PACKAGING"
    ],
    [
        "B.C. AMES, INC.",
        "B C AMES"
    ],
    [
        "B. BRAUN MEDICAL AG",
        "B. BRAUN MEDICAL"
    ],
    [
        "B2",
        "B2 INSTRUMENTS"
    ],
    [
        "B2-INSTRUMENTS",
        "B2 INSTRUMENTS"
    ],
    [
        "B4",
        "B4"
    ],
    [
        "B4-AO",
        "B4"
    ],
    [
        "BAFA SAS",
        "BAFA"
    ],
    [
        "BALERS, INC.",
        "BALERS"
    ],
    [
        "BALOSSI MARGUET S.A.S",
        "BALOSSI MARGUET S A S"
    ],
    [
        "BARCODESINC",
        "BARCODESINC"
    ],
    [
        "BARDUSCH GMBH&CO.KG",
        "BARDUSCH"
    ],
    [
        "BARGER PACKAGING",
        "BARGER PACKAGING"
    ],
    [
        "BARRINGTON AUTOMATION *",
        "BARRINGTON AUTOMATION"
    ],
    [
        "BATTERIES + BULBS",
        "BATTERIES + BULBS"
    ],
    [
        "BATTERIES + BULBS-#0385",
        "BATTERIES + BULBS"
    ],
    [
        "BATTERIES PLUS #38",
        "BATTERIES + BULBS"
    ],
    [
        "BATTERIES PLUS LLC",
        "BATTERIES + BULBS"
    ],
    [
        "BAUER FACTORY SUPPLY INC",
        "BAUER FACTORY SUPPLY"
    ],
    [
        "BEACON MEDAES",
        "BEACON MEDAES"
    ],
    [
        "BEAHM DESIGNS",
        "BEAHM DESIGNS"
    ],
    [
        "BERGEON SA",
        "BERGEON"
    ],
    [
        "BERNIER MACHINE SHOP",
        "BERNIER MACHINE SHOP"
    ],
    [
        "BIOMEDICAL STRUCTURES, INC., LLC",
        "BIOMEDICAL STRUCTURES"
    ],
    [
        "BIRFOOD GMBH +CO. KG",
        "BIRFOOD"
    ],
    [
        "BITO - LAGERTECHNIK",
        "BITO LAGERTECHNIK"
    ],
    [
        "BK MACHINE",
        "BK MACHINE"
    ],
    [
        "BLACK BOX NETWORK SERVICES, CORP.",
        "BLACK BOX NETWORK SERVICES"
    ],
    [
        "BLASER SWISSLUBE GMBH",
        "BLASER SWISSLUBE"
    ],
    [
        "BLASER SWISSLUBE AG",
        "BLASER SWISSLUBE"
    ],
    [
        "BLICKLE RÄDER + ROLLEN GMBH",
        "BLICKLE RÄDER ROLLEN"
    ],
    [
        "BLITZ TEXTILVEREDELUNG,",
        "BLITZ TEXTILVEREDELUNG"
    ],
    [
        "BOCKS SA",
        "BOCKS SA"
    ],
    [
        "DE BOER SYSTEMS",
        "BOER SYSTEMS"
    ],
    [
        "BOLLIGER + CO. AG",
        "BOLLIGER"
    ],
    [
        "BONNEFOND AFFUTAGE SERVICES",
        "BONNEFOND AFFUTAGE SERVICES"
    ],
    [
        "BRACK.CH AG",
        "BRACK"
    ],
    [
        "BRADY GMBH BEREICH SETON",
        "BRADY BEREICH SETON"
    ],
    [
        "BRANSON ULTRASONICS CORP",
        "BRANSON ULTRASONICS"
    ],
    [
        "BRENNTAG SCHWEIZERHALL AG",
        "BRENNTAG GROUP"
    ],
    [
        "BROCK HANSON",
        "BROCK HANSON"
    ],
    [
        "BROWN CHEMICAL CO",
        "BROWN CHEMICAL"
    ],
    [
        "AR BROWNLOW LTD,",
        "BROWNLOW"
    ],
    [
        "BRUECKNER BUERO-SYST.GMBH",
        "BRUECKNER BUERO SYST"
    ],
    [
        "BUREAUTECH ALLEMANN AG",
        "BUREAUTECH ALLEMANN"
    ],
    [
        "BWAY CORPORATION AKA ROPAK PACKAGING",
        "BWAY ORATION AKA ROPAK PACKAGING"
    ],
    [
        "C. IDEAS, INC.*",
        "C. IDEAS"
    ],
    [
        "CAL OPEX INC",
        "CAL OPEX"
    ],
    [
        "CALGONATE CORP.",
        "CALGONATE"
    ],
    [
        "CALOR KOSANGAS,",
        "CALOR KOSANGAS"
    ],
    [
        "CANNON MUSKEGON CORP",
        "CANNON MUSKEGON"
    ],
    [
        "CANNON MUSKEGON-EURO ACC",
        "CANNON MUSKEGON"
    ],
    [
        "CAPESAL SA",
        "CAPESAL"
    ],
    [
        "CAPLUGS",
        "CAPLUGS"
    ],
    [
        "CAPLUGS, LLC.",
        "CAPLUGS"
    ],
    [
        "CARIBBEAN FLUID POWER INC",
        "CARIBBEAN FLUID POWER"
    ],
    [
        "CARIBE WATER TECHNOLOGY INC",
        "CARIBE WATER TECHNOLOGY"
    ],
    [
        "CARL STUART LIMITED",
        "CARL STUART"
    ],
    [
        "CARLTON BATES REL000",
        "CARLTON BATES"
    ],
    [
        "CARPENTER MFG CO INC",
        "CARPENTER MFG"
    ],
    [
        "CARRAIG PRINT LTD.",
        "CARRAIG PRINT"
    ],
    [
        "CARY CLEANERS 2",
        "CARY CLEANERS 2"
    ],
    [
        "CAULFIELD INDUSTRIAL LTD",
        "CAULFIELD INDUSTRIAL"
    ],
    [
        "CAULFIELD INDUSTRIAL LTD.",
        "CAULFIELD INDUSTRIAL"
    ],
    [
        "CD3D",
        "CD3D"
    ],
    [
        "CDF EMBALLAGE SA",
        "CDF EMBALLAGE"
    ],
    [
        "CDW DIR #MRR2526",
        "CDW"
    ],
    [
        "CDW DIR #NDR4985",
        "CDW"
    ],
    [
        "CEDRIC SPRING AND ASSOC. *",
        "CEDRIC SPRING AND ASSOC"
    ],
    [
        "CELLULE IBD",
        "CELLULE"
    ],
    [
        "CELLULE INST",
        "CELLULE"
    ],
    [
        "CELSIS A CHARLES RIVER CO. *",
        "CELSIS A CHARLES RIVER"
    ],
    [
        "CEM",
        "CEM"
    ],
    [
        "CENCO GRINDING CORP",
        "CENCO GRINDING"
    ],
    [
        "CENPAC MIDI PYRENNEES",
        "CENPAC"
    ],
    [
        "CENTEGRA OCCUPATIONAL MED",
        "CENTEGRA OCCUPATIONAL MED"
    ],
    [
        "CENTRO INC",
        "CENTRO"
    ],
    [
        "CENTRO, INC.",
        "CENTRO"
    ],
    [
        "CENTURY SPRINGS *",
        "CENTURY SPRINGS"
    ],
    [
        "CERTECH, INC",
        "CERTECH"
    ],
    [
        "CHAMPION CONTAINER CORPORATION",
        "CHAMPION CONTAINER"
    ],
    [
        "CHAMPION FIRE SAFETY",
        "CHAMPION FIRE SAFETY"
    ],
    [
        "CHEM QUEST INC.",
        "CHEM QUEST"
    ],
    [
        "CHEMISAL SAFETY",
        "CHEMISAL SAFETY"
    ],
    [
        "CHEMSTORE ENGINEERING LTD",
        "CHEMSTORE ENGINEERING"
    ],
    [
        "CHIPBLASTER",
        "CHIPBLASTER"
    ],
    [
        "CHIRON AMERICA",
        "CHIRON"
    ],
    [
        "CICADA ENGINEERING LIMITED",
        "CICADA ENGINEERING"
    ],
    [
        "CINTAS CORPORATION  LOC 355 *",
        "CINTAS LOC 355"
    ],
    [
        "CIRO PRODUCTS",
        "CIRO PRODUCTS"
    ],
    [
        "CITIZEN MACHINERY EUROPE",
        "CITIZEN MACHINERY"
    ],
    [
        "CITTI-GROSSMARKT",
        "CITTI GROSSMARKT"
    ],
    [
        "CLARK FOAM PRODUCTS *",
        "CLARK FOAM PRODUCTS"
    ],
    [
        "CLAYTON HOTEL LIMERICK",
        "CLAYTON HOTEL"
    ],
    [
        "CLAYTON INDUSTRIES *",
        "CLAYTON INDUSTRIES"
    ],
    [
        "CLEMENT COMMUNICATIONS",
        "CLEMENT COMMUNICATIONS"
    ],
    [
        "CLIENTLINK, INC.",
        "CLIENTLINK"
    ],
    [
        "CLM SYSTEMS",
        "CLM SYSTEMS"
    ],
    [
        "CNC SPECIALTY STORE LL",
        "CNC SPECIALTY STORE LL"
    ],
    [
        "COAST PNEUMATICS",
        "COAST PNEUMATICS"
    ],
    [
        "COLE-PARMER",
        "COLE PARMER"
    ],
    [
        "COLE-PARMER INSTRUMENT CO., INC.",
        "COLE PARMER INSTRUMENT"
    ],
    [
        "COLON INDUSTRIAL CONTROL",
        "COLON INDUSTRIAL CONTROL"
    ],
    [
        "COLORMAN IRELAND LIMITED",
        "COLORMAN"
    ],
    [
        "COMBINED FLUID PRODUCTS *",
        "COMBINED FLUID PRODUCTS"
    ],
    [
        "COMBIPACK GMBH",
        "COMBIPACK"
    ],
    [
        "COMCO INC. - BURBANK",
        "COMCO"
    ],
    [
        "COMED",
        "COMED"
    ],
    [
        "COMFORT COOLING LTD.,",
        "COMFORT COOLING"
    ],
    [
        "COMPLIANCE PLUS MEDICAL S",
        "COMPLIANCE PLUS"
    ],
    [
        "CONFLUENT MEDICAL TECHNOL",
        "CONFLUENT MEDICAL TECHNOL"
    ],
    [
        "CONNEY SAFETY PRODUCTS *",
        "CONNEY SAFETY"
    ],
    [
        "CONRAD ELECTRONIC GMBH",
        "CONRAD ELECTRONIC"
    ],
    [
        "CONSERV FS",
        "CONSERV FS"
    ],
    [
        "CONSOLIDATED ELECTRICAL *",
        "CONSOLIDATED ELECTRICAL"
    ],
    [
        "CONSOLIDATED LABEL CO., INC.",
        "CONSOLIDATED LABEL"
    ],
    [
        "CONTECH MEDICAL INC - PRO",
        "CONTECH MEDICAL"
    ],
    [
        "CONVERSOURCE *",
        "CONVERSOURCE"
    ],
    [
        "CORE TECH INC",
        "CORE TECH"
    ],
    [
        "CORIDIAN TECHNOLOGIES INC *",
        "CORIDIAN TECHNOLOGIES"
    ],
    [
        "CORK FIRST AID SUPPLIES",
        "CORK FIRST AID SUPPLIES"
    ],
    [
        "CORPORATE EXPRESS",
        "CORPORATE EXPRESS"
    ],
    [
        "CORROSION FLUID PRODUCTS *",
        "CORROSION FLUID PRODUCTS"
    ],
    [
        "COUCH SALES LLC",
        "COUCH SALES"
    ],
    [
        "COUNTRYSIDE FLOWER SHOP",
        "COUNTRYSIDE FLOWER SHOP"
    ],
    [
        "CREATIVE-TRADE",
        "CREATIVE TRADE"
    ],
    [
        "CREFORM TECHNIK GMBH",
        "CREFORM TECHNIK"
    ],
    [
        "CREGANNA MEDICAL - CAMPBE",
        "CREGANNA MEDICAL"
    ],
    [
        "CREGANNA MEDICAL - TUALAT",
        "CREGANNA MEDICAL"
    ],
    [
        "CRESILAS",
        "CRESILAS"
    ],
    [
        "CROWLEY ENGINEERING",
        "CROWLEY ENGINEERING"
    ],
    [
        "CSI MEDICAL",
        "CSI MEDICAL"
    ],
    [
        "CSI MEDICAL INC (FKA CONVERTING SOL)",
        "CSI MEDICAL"
    ],
    [
        "CUMMINS LABEL COMPANY",
        "CUMMINS LABEL"
    ],
    [
        "CUMMINS LABEL REL000",
        "CUMMINS LABEL"
    ],
    [
        "CYBERMETRICS CORPORATION",
        "CYBERMETRICS"
    ],
    [
        "DAI CERAMICS INC",
        "DAI CERAMICS"
    ],
    [
        "DAN SCHLABACH",
        "DAN SCHLABACH"
    ],
    [
        "DANNELL HANNA AND",
        "DANNELL HANNA"
    ],
    [
        "DATA WEIGHING SYSTEMS *",
        "DATA WEIGHING SYSTEMS"
    ],
    [
        "DCM COMPLIANCE LTD",
        "DCM COMPLIANCE"
    ],
    [
        "DELTA INDUSTRIETECHNIK GMBH",
        "DELTA INDUSTRIETECHNIK"
    ],
    [
        "DENIOS AG",
        "DENIOS"
    ],
    [
        "DEPATIE FLUID POWER CO",
        "DEPATIE FLUID POWER"
    ],
    [
        "DEPATIE FLUID POWER INC.",
        "DEPATIE FLUID POWER"
    ],
    [
        "DERBY INC",
        "DERBY"
    ],
    [
        "DESIGN CONCEPTS & ENTERPRISES",
        "DESIGN CONCEPTS & ENTERPRISES"
    ],
    [
        "DGI SUPPLY  A DO ALL COMPANY",
        "DGI SUPPLY"
    ],
    [
        "DIAGRAPH-NORWOOD *",
        "DIAGRAPH NORWOOD"
    ],
    [
        "DICKEY MANUFACTURING CO.",
        "DICKEY MANUFACTURING"
    ],
    [
        "DICKSON BEARINGS LTD.,",
        "DICKSON BEARINGS"
    ],
    [
        "DICKSON COMPANY, INC. (THE)",
        "DICKSON COMPANY"
    ],
    [
        "DIDCO INC",
        "DIDCO"
    ],
    [
        "DIELECTRICS INC",
        "DIELECTRICS"
    ],
    [
        "DIGI KEY CORP",
        "DIGI KEY"
    ],
    [
        "DIGI KEY CORP.",
        "DIGI KEY"
    ],
    [
        "DIGI-KEY CORPORATION",
        "DIGI KEY"
    ],
    [
        "DIGITAL HOME TECHNOLOGIES",
        "DIGITAL HOME TECHNOLOGIES"
    ],
    [
        "DIMPROTEC GMBH",
        "DIMPROTEC"
    ],
    [
        "DINERS CLUB - CV",
        "DINERS CLUB"
    ],
    [
        "DINERS CLUB - MR",
        "DINERS CLUB"
    ],
    [
        "DINERS CLUB- EW",
        "DINERS CLUB EW"
    ],
    [
        "DIRECTLY YOURS",
        "DIRECTLY YOURS"
    ],
    [
        "DIVERSIFIED BIOTECH, INC.",
        "DIVERSIFIED BIOTECH"
    ],
    [
        "DOBER CHEMICAL CORP. *",
        "DOBER CHEMICAL"
    ],
    [
        "DOGA",
        "DOGA"
    ],
    [
        "DOMINO AMJET",
        "DOMINO AMJET"
    ],
    [
        "DONALD ENGINEERING",
        "DONALD ENGINEERING"
    ],
    [
        "DPM DIFFUSION PROD.MECANIQUE",
        "DPM DIFFUSION PROD MECANIQUE"
    ],
    [
        "DR.-ING. P. CHRISTIANI GMBH",
        "DR ING P CHRISTIANI"
    ],
    [
        "DR. WEIGERT (SCHWEIZ) AG",
        "DR WEIGERT"
    ],
    [
        "DREISILKER ELECTRIC",
        "DREISILKER ELECTRIC"
    ],
    [
        "DRUCKSYSTEME JANZ & RASCHKE",
        "DRUCKSYSTEME JANZ & RASCHKE"
    ],
    [
        "DST-CHEMICALS SARL",
        "DST CHEMICALS"
    ],
    [
        "DUKANE CORPORATION",
        "DUKANE"
    ],
    [
        "DUKANE IAS, LLC.",
        "DUKANE IAS"
    ],
    [
        "DUNHAM TOOL COMPANY",
        "DUNHAM TOOL"
    ],
    [
        "DWIGHT CALIBRATION & INSTRUMENT",
        "DWIGHT CALIBRATION & INSTRUMENT"
    ],
    [
        "DYCEM INC",
        "DYCEM"
    ],
    [
        "DYNAMIC PLASTICS INC",
        "DYNAMIC PLASTICS"
    ],
    [
        "DYNO AG",
        "DYNO"
    ],
    [
        "E R S",
        "E R S"
    ],
    [
        "EAG LABORATORIES",
        "EAG LABORATORIES"
    ],
    [
        "EAGLE ROCK",
        "EAGLE ROCK"
    ],
    [
        "EARTHWISE ENVIRONMENTAL",
        "EARTHWISE ENVIRONMENTAL"
    ],
    [
        "ECK SUPPLY CO",
        "ECK SUPPLY"
    ],
    [
        "ECO TDS",
        "ECO TDS"
    ],
    [
        "ECOLAB GMBH",
        "ECOLAB"
    ],
    [
        "ECOLAB MANUFACTURING",
        "ECOLAB"
    ],
    [
        "ECP",
        "ECP"
    ],
    [
        "EDWARDS INDUSTRIAL",
        "EDWARDS INDUSTRIAL"
    ],
    [
        "EHS MANAGEMENT GROUP INC",
        "EHS MANAGEMENT GROUP"
    ],
    [
        "EIS INC",
        "EIS"
    ],
    [
        "EIS INC  COMKYL CIRCUIT",
        "EIS"
    ],
    [
        "EIS, INC.",
        "EIS"
    ],
    [
        "EIS, INC.     REL000",
        "EIS"
    ],
    [
        "ELECTRO GLO DIST II",
        "ELECTRO GLO DIST II"
    ],
    [
        "ELEMENTEC LTD",
        "ELEMENTEC"
    ],
    [
        "ELGA VEOLIA *",
        "ELGA VEOLIA"
    ],
    [
        "ELITE TAPE",
        "ELITE TAPE"
    ],
    [
        "ELNIK SYSTEMS LLC",
        "ELNIK SYSTEMS"
    ],
    [
        "ELNIK SYSTEMS, LLC.",
        "ELNIK SYSTEMS"
    ],
    [
        "EM-TOOLS GMBH",
        "EM TOOLS"
    ],
    [
        "EMD MILLIPORE CORPORATION",
        "EMD MILLIPORE"
    ],
    [
        "EMEDCO",
        "EMEDCO"
    ],
    [
        "EMEDCO INC. *",
        "EMEDCO"
    ],
    [
        "EMERGENT SAFETY SUPPLY",
        "EMERGENT SAFETY SUPPLY"
    ],
    [
        "EMPIRE WEST CORP.",
        "EMPIRE WEST"
    ],
    [
        "EMPTMEYER GMBH",
        "EMPTMEYER"
    ],
    [
        "EMSCO INC",
        "EMSCO"
    ],
    [
        "EMWERK GMBH",
        "EMWERK"
    ],
    [
        "ENDISYS",
        "ENDISYS"
    ],
    [
        "ENDRESS & HAUSER",
        "ENDRESS & HAUSER"
    ],
    [
        "ENDRESS + HAUSER MESSTECHNIK",
        "ENDRESS & HAUSER"
    ],
    [
        "ENDRESS + HAUSER, INC.",
        "ENDRESS & HAUSER"
    ],
    [
        "ENERGY & ENVIRONMENTAL CONSULTING SERVICES",
        "ENERGY & ENVIRONMENTAL CONSULTING SERVICES"
    ],
    [
        "ENERSYS, INC.",
        "ENERSYS"
    ],
    [
        "ENGEL MACHINERY INC",
        "ENGEL MACHINERY"
    ],
    [
        "ENGINE CREATIVE",
        "ENGINE CREATIVE"
    ],
    [
        "ENGINEERING STEELS & MTLS",
        "ENGINEERING STEELS & MTLS"
    ],
    [
        "ENTEGRIS",
        "ENTEGRIS"
    ],
    [
        "ENVIRO TECH INTERNATIONAL",
        "ENVIRO TECH INTERNATIONAL"
    ],
    [
        "EPI PRINTERS INC",
        "EPI PRINTERS"
    ],
    [
        "EPSON   AMERICA",
        "EPSON"
    ],
    [
        "EQUIPMENT MGMT SERV & REPAIR I",
        "EQUIPMENT MGMT SERV & REPAIR I"
    ],
    [
        "ERIC GALLEGOS",
        "ERIC GALLEGOS"
    ],
    [
        "ERICH EYDAM KG",
        "ERICH EYDAM"
    ],
    [
        "ESTACION PIRU SA DE CV",
        "ESTACION PIRU"
    ],
    [
        "EUROWATER GMBH",
        "EUROTHERM"
    ],
    [
        "EUROTHERM IRELAND LTD.,",
        "EUROTHERM"
    ],
    [
        "EVOLVE TECHNOLOGIES LTD",
        "EVOLVE TECHNOLOGIES"
    ],
    [
        "EXALT PRINTING SOLUTIONS LLC",
        "EXALT PRINTING SOLUTIONS"
    ],
    [
        "EXCEL PACKAGING SYSTEMS INC",
        "EXCEL PACKAGING SYSTEMS"
    ],
    [
        "EXTENDATA",
        "EXTENDATA"
    ],
    [
        "EXTRUDE HONE GMBH",
        "EXTRUDE HONE"
    ],
    [
        "FABINNOV",
        "FABINNOV"
    ],
    [
        "FANNIN LTD",
        "FANNIN HEALTHCARE"
    ],
    [
        "FASTENAL COMPANY",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPANY01",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL000",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL001",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL002",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL003",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL004",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL005",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL006",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL007",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL008",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL009",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL010",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL011",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL012",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL013",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL014",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL015",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL016",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL017",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL018",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL019",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL020",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL021",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL022",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL023",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL024",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL025",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL026",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL027",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL028",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL029",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL030",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL031",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL032",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL033",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL034",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL035",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL036",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL037",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL038",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL039",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL040",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL041",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL042",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL043",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL045",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL046",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL047",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL048",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL051",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL054",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL056",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL059",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL477",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL482",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL483",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL731",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL732",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL735",
        "FASTENAL"
    ],
    [
        "FASTENAL COMPAREL736",
        "FASTENAL"
    ],
    [
        "FASTEST INC",
        "FASTEST INC"
    ],
    [
        "FEDERNTECHNIK KNOERZER GMBH",
        "FEDERNTECHNIK KNOERZER"
    ],
    [
        "FELIX BÜHLER AG",
        "FELIX BUHLER"
    ],
    [
        "FERGUSON PLUMBING",
        "FERGUSON PLUMBING"
    ],
    [
        "FESTO VERTRIEB GMBH& CO.KG",
        "FESTO VERTRIEB"
    ],
    [
        "FIELMANN AG",
        "FIELMANN"
    ],
    [
        "FIELMANN GMBH + CO. OHG",
        "FIELMANN"
    ],
    [
        "FIELMANN AG + CO. OHG",
        "FIELMANN"
    ],
    [
        "FIERO FLUID POWER INC",
        "FIERO FLUID POWER"
    ],
    [
        "FIERO FLUID POWER INC.",
        "FIERO FLUID POWER"
    ],
    [
        "FILMSOURCE INC",
        "FILMSOURCE"
    ],
    [
        "FILTER SERVICES INC",
        "FILTER SERVICES"
    ],
    [
        "FILTER SOURCE.COM, INC. *",
        "FILTER SOURCE COM"
    ],
    [
        "FIRE CONTROL CORP",
        "FIRE CONTROL"
    ],
    [
        "THERMOFISHER LLC",
        "FISHER SCIENTIFIC"
    ],
    [
        "FISHER SCIENTIFIC IRL LTD",
        "FISHER SCIENTIFIC"
    ],
    [
        "FIST LTD FIRE INDUSTRIAL",
        "FIST FIRE INDUSTRIAL"
    ],
    [
        "FISVCI",
        "FISVCI"
    ],
    [
        "FLEXTRONICS INTERNATIONAL USA",
        "FLEX"
    ],
    [
        "FLUIDX EQUIPMENT INC",
        "FLUIDX EQUIPMENT"
    ],
    [
        "FLYCO,",
        "FLYCO"
    ],
    [
        "FMB MASCHINENBAUGESELLSCHAFT",
        "FMB MASCHINENBAUGESELLSCHAFT"
    ],
    [
        "FMPB AQUITAINE",
        "FMPB AQUITAINE"
    ],
    [
        "FMS-TECHNIK AG",
        "FMS TECHNIK"
    ],
    [
        "FOAM FACTORY, INC.",
        "FOAM FACTORY"
    ],
    [
        "FORMLABS INC",
        "FORMLABS"
    ],
    [
        "FORRER BUSINESS INTERIORS, INC. *",
        "FORRER BUSINESS INTERIORS"
    ],
    [
        "FORREST COMPANY",
        "FORREST"
    ],
    [
        "FORT WAYNE METALS RESEARC",
        "FORT WAYNE METALS RESEARCH"
    ],
    [
        "FPE AUTOMATION, INC *",
        "FPE AUTOMATION"
    ],
    [
        "FPIA SUD OUEST",
        "FPIA SUD OUEST"
    ],
    [
        "FPS WERKZEUGMASCHINEN GMBH",
        "FPS WERKZEUGMASCHINEN"
    ],
    [
        "FRANKEL SAS",
        "FRANKEL"
    ],
    [
        "FRANZ SCHOPPE INDUSTRIE-",
        "FRANZ SCHOPPE INDUSTRIE"
    ],
    [
        "FREEMAN MFG. & SUPPLY CO. *",
        "FREEMAN MFG & SUPPLY"
    ],
    [
        "FREUDENBERG MEDICAL",
        "FREUDENBERG MEDICAL"
    ],
    [
        "FRITZ SCHAEFER GMBH",
        "FRITZ SCHAEFER"
    ],
    [
        "FUCHS SCHMIERSTOFFE GMBH",
        "FUCHS LUBRIFIANTS"
    ],
    [
        "FURTER + CO. AG",
        "FURTER"
    ],
    [
        "FUTURE ELECTRONICS CORP",
        "FUTURE ELECTRONICS"
    ],
    [
        "G P REEVES INC",
        "G P REEVES"
    ],
    [
        "GAERNER GMBH",
        "GAERNER"
    ],
    [
        "GAERNER AG",
        "GAERNER"
    ],
    [
        "GAGE-LAB PRODUCTS",
        "GAGE LAB PRODUCTS"
    ],
    [
        "GALSON LABORATORIES",
        "GALSON LABORATORIES"
    ],
    [
        "GEBHARD BALLUFF GMBH & CO.",
        "GEBHARD BALLUFF"
    ],
    [
        "GEBRUEDER BARTH GMBH & CO.KG",
        "GEBRUEDER BARTH"
    ],
    [
        "GEL-PAK - HAYWARD",
        "GEL HAYWARD"
    ],
    [
        "GEM ELECTRICAL CONTRACTING LLC",
        "GEM ELECTRICAL CONTRACTING"
    ],
    [
        "GENERAL TOOL,  INC.",
        "GENERAL TOOL"
    ],
    [
        "GENIUS CPR CELL PHONE REP",
        "GENIUS CPR CELL PHONE REP"
    ],
    [
        "GENSUITE LLC *",
        "GENSERVE"
    ],
    [
        "GEROTECH INC",
        "GEROTECH"
    ],
    [
        "GETAZ MIAUTON SA",
        "GETAZ MIAUTON"
    ],
    [
        "GETINGE USA SALES, LLC",
        "GETINGE"
    ],
    [
        "GETINGE DEUTSCHLAND GMBH",
        "GETINGE"
    ],
    [
        "GETINGE FRANCE",
        "GETINGE"
    ],
    [
        "GEWAPUR GMBH & CO. KG",
        "GEWAPUR"
    ],
    [
        "GLATT AIR TECHNIQUES INC",
        "GLATT AIR TECHNIQUES"
    ],
    [
        "GLEICH ALUMINIUM SERVICE",
        "GLEICH ALUMINIUM SERVICE"
    ],
    [
        "GLIDDEN COMPANY",
        "GLIDDEN"
    ],
    [
        "GLOBAL INDUSTRIAL EQUIPME",
        "GLOBAL INDUSTRIAL"
    ],
    [
        "--DO NOT USE--- GLOBAL INDUSTRIAL",
        "GLOBAL INDUSTRIAL"
    ],
    [
        "GLOBAL INDUSTRIAL",
        "GLOBAL INDUSTRIAL"
    ],
    [
        "GLUCO INC",
        "GLUCO INC"
    ],
    [
        "GMP LABELING, INC.",
        "GMP LABELING"
    ],
    [
        "GORDON RUBBER CO",
        "GORDON RUBBER"
    ],
    [
        "GORDON WATER SYSTEMS",
        "GORDON WATER SYSTEMS"
    ],
    [
        "GRADIENT LENS CORPORATION",
        "GRADIENT LENS"
    ],
    [
        "GRAF METALLIC OF *",
        "GRAF METALLIC OF AMERICA"
    ],
    [
        "GRAINGER",
        "GRAINGER"
    ],
    [
        "GRAND NORTHERN PRODUCTS",
        "GRAND NORTHERN PRODUCTS"
    ],
    [
        "GRAND NORTHERN PRODUCTS, LTD",
        "GRAND NORTHERN PRODUCTS"
    ],
    [
        "GRAND RAPIDS FOAM",
        "GRAND RAPIDS FOAM"
    ],
    [
        "GRAND RAPIDS FOAM TECHNOLOGIES",
        "GRAND RAPIDS FOAM"
    ],
    [
        "GREAT LAKES AUTOMATION",
        "GREAT LAKES AUTOMATION"
    ],
    [
        "GREAT LAKES AUTOMATION SU",
        "GREAT LAKES AUTOMATION"
    ],
    [
        "GREAT LAKES FINISHING",
        "GREAT LAKES FINISHING"
    ],
    [
        "GREAT LAKES INDUSTRIAL SE",
        "GREAT LAKES INDUSTRIAL"
    ],
    [
        "GREEN GMBH",
        "GREEN"
    ],
    [
        "GROOVEYARD CO.LTD",
        "GROOVEYARD"
    ],
    [
        "GROZ-BECKERT USA",
        "GROZ BECKERT"
    ],
    [
        "GUEHRING OHG",
        "GUEHRING"
    ],
    [
        "GUENTHER ROENNAU",
        "GUENTHER ROENNAU"
    ],
    [
        "GUMMISCHWARZ AG",
        "GUMMISCHWARZ"
    ],
    [
        "GURLEY PRECISION INST",
        "GURLEY PRECISION INST"
    ],
    [
        "GUTEKUNST & CO. KG",
        "GUTEKUNST"
    ],
    [
        "GUYSON CORPORATION",
        "GUYSON"
    ],
    [
        "H.-D. SCHUNK GMBH + CO.",
        "H D SCHUNK"
    ],
    [
        "H.W. MACHINE TOOLS LTD",
        "H W MACHINE TOOLS"
    ],
    [
        "HAAS PRECISION SERVICES I",
        "HAAS PRECISION SERVICES I"
    ],
    [
        "HABA AG",
        "HABA"
    ],
    [
        "HACH COMPANY",
        "HACH"
    ],
    [
        "HAHN+KOLB WERKZEUGE GMBH",
        "HAHN KOLB WERKZEUGE"
    ],
    [
        "HAINBUCH AMERICA CORP",
        "HAINBUCH"
    ],
    [
        "HAINBUCH PRAEZISONSWERKZEUGE",
        "HAINBUCH PRAEZISONSWERKZEUGE"
    ],
    [
        "HALOSIL INTERNATIONAL, INC.",
        "HALOSIL INTERNATIONAL"
    ],
    [
        "HANDKE KONZEPT UG",
        "HANDKE KONZEPT"
    ],
    [
        "HANS HUELLMANN",
        "HANS HUELLMANN"
    ],
    [
        "HANSEN BALK",
        "HANSEN/BALK"
    ],
    [
        "HARD METAL MACHINE TOOLS",
        "HARD METAL MACHINE TOOLS"
    ],
    [
        "HARD METAL MACHINE TOOLS,",
        "HARD METAL MACHINE TOOLS"
    ],
    [
        "HARDINGE INC",
        "HARDINGE"
    ],
    [
        "HARDINGE INC.",
        "HARDINGE"
    ],
    [
        "HARDINGE GRINDING GROUP",
        "HARDINGE GRINDING GROUP"
    ],
    [
        "HARMONIC DRIVE LLC",
        "HARMONIC DRIVE"
    ],
    [
        "HARVARD FACTORY",
        "HARVARD FACTORY"
    ],
    [
        "HAVEN MANUFACTURING CO",
        "HAVEN MANUFACTURING"
    ],
    [
        "HAZMATPAC, INC.",
        "HAZMATPAC"
    ],
    [
        "HEALTHMARK INDUSTRIES CO INC",
        "HEALTHMARK INDUSTRIES"
    ],
    [
        "HEARTLAND COMPUTERS",
        "HEARTLAND COMPUTERS"
    ],
    [
        "HEBUMEDICAL GMBH",
        "HEBUMEDICAL"
    ],
    [
        "HECHT TECHNOLOGIE GMBH",
        "HECHT TECHNOLOGIE"
    ],
    [
        "HEGA SYSTEMS GMBH",
        "HEGA SYSTEMS"
    ],
    [
        "HEISLER'S BOOTERY",
        "HEISLER S BOOTERY"
    ],
    [
        "HEKU-PACK",
        "HEKU PACK"
    ],
    [
        "HELIX RESEARCH SOLUTIONS INC",
        "HELIX RESEARCH SOLUTIONS"
    ],
    [
        "HEMOSTAT LABORATORIES, INC.",
        "HEMOSTAT LABORATORIES"
    ],
    [
        "HEMPEL SPECIAL METALS AG",
        "HEMPEL SPECIAL METALS"
    ],
    [
        "HENRY KRUSE GMBH & CO. KG",
        "HENRY KRUSE"
    ],
    [
        "HERAEUS ELECTRO NITE CO",
        "HERAEUS ELECTRO NITE"
    ],
    [
        "HERAEUS MEDICAL COMPONENT",
        "HERAEUS MEDICAL COMPONENTS"
    ],
    [
        "HERBERT KOELSCH GMBH & CO. K",
        "HERBERT KOELSCH & K"
    ],
    [
        "HERMANN BANTLEON GMBH",
        "HERMANN BANTLEON"
    ],
    [
        "HERRMANN- ROWEDDER GMBH",
        "HERRMANN ROWEDDER"
    ],
    [
        "HERRMANN ULTRASONICS, INC",
        "HERRMANN ULTRASONICS"
    ],
    [
        "HETA CHOKSHI",
        "HETA CHOKSHI"
    ],
    [
        "HGH VERTRIEBS GMBH",
        "HGH VERTRIEBS"
    ],
    [
        "HH BARNUM COMPANY",
        "HH BARNUM"
    ],
    [
        "HI LIFE TOOLS",
        "HI LIFE TOOLS"
    ],
    [
        "HIGH PROFILE INCORPORATED",
        "HIGH PROFILE INCORPORATED"
    ],
    [
        "HILCO INDUSTRIAL PLASTICS LLC",
        "HILCO INDUSTRIAL PLASTICS"
    ],
    [
        "HIRSCHLER OBERFLAECH.TECHNIK",
        "HIRSCHLER OBERFLAECH TECHNIK"
    ],
    [
        "HISCO, INC. *",
        "HISCO"
    ],
    [
        "HLD CLEAN CONSULT SA",
        "HLD CLEAN CONSULT"
    ],
    [
        "HOENGER AG",
        "HOENGER"
    ],
    [
        "HOERBIGER MICRO FLUID GMBH",
        "HOERBIGER MICRO FLUID"
    ],
    [
        "HOFFMANN GMBH",
        "HOFFMANN"
    ],
    [
        "HOFFMANN GÖPPINGEN",
        "HOFFMANN"
    ],
    [
        "HOME DEPOT",
        "HOME DEPOT"
    ],
    [
        "HOMEDEPOT.COM",
        "HOMEDEPOT.COM"
    ],
    [
        "HONEY BAKED HAM COMPANY",
        "HONEY BAKED HAM"
    ],
    [
        "HOSE TECH LTD",
        "HOSE TECH"
    ],
    [
        "HOUGHTON INTERNATIONAL, INC",
        "HOUGHTON INTERNATIONAL"
    ],
    [
        "HOUGHTON OIL &CHEMICALLTD",
        "HOUGHTON OIL &CHEMICALLTD"
    ],
    [
        "HOYA VISION CARE",
        "HOYA VISION CARE"
    ],
    [
        "HP PRODUCTS CORPORATION *",
        "HP PRODUCTS"
    ],
    [
        "HPI PROCESSES INC",
        "HPI PROCESSES"
    ],
    [
        "HWR  SPANNTECHNIK GMBH",
        "HWR SPANNTECHNIK"
    ],
    [
        "HYDRITE CHEMICAL COMPANY",
        "HYDRITE CHEMICAL"
    ],
    [
        "HYDROFLEX OHG",
        "HYDROFLEX"
    ],
    [
        "IBEC MACHINE KNIFE LTD",
        "IBEC MACHINE KNIFE"
    ],
    [
        "ICBE",
        "ICBE"
    ],
    [
        "ICO RALLY - PALO ALTO",
        "ICO RALLY PALO ALTO"
    ],
    [
        "ID TECHNOLOGY",
        "ID TECHNOLOGY"
    ],
    [
        "ID TECHNOLOGY LIMITED",
        "ID TECHNOLOGY"
    ],
    [
        "IMADA INC.",
        "IMADA"
    ],
    [
        "IMAGINEERING INC.",
        "IMAGINEERING INC."
    ],
    [
        "IMPACT LABEL CORP",
        "IMPACT LABEL"
    ],
    [
        "IMPACT LABEL CORP.",
        "IMPACT LABEL"
    ],
    [
        "IMPACT LABEL CREL000",
        "IMPACT LABEL CREL000"
    ],
    [
        "IMPREGLON-FAIRBURN",
        "IMPREGLON FAIRBURN"
    ],
    [
        "IMPRENTAS PAGAN",
        "IMPRENTAS PAGAN"
    ],
    [
        "IMPRINT ENTERPRISES *",
        "IMPRINT ENTERPRISES"
    ],
    [
        "IN  E.G.O. STUDIOS",
        "IN  E.G.O. STUDIOS"
    ],
    [
        "IN  GREAT LAKES INDUSTRIA",
        "IN  GREAT LAKES INDUSTRIA"
    ],
    [
        "IN  SAUTER NORTH AMERICA",
        "IN  SAUTER NORTH AMERICA"
    ],
    [
        "INDIUM CORPORATION OF AME",
        "INDIUM CORPORATION OF AME"
    ],
    [
        "INDUSTORE LIMITED",
        "INDUSTORE"
    ],
    [
        "INDUSTRIAL MACHINERY MOVERS INC",
        "INDUSTRIAL MACHINERY MOVERS"
    ],
    [
        "INDUSTRIAL PRESSURE TESTI",
        "INDUSTRIAL PRESSURE TESTI"
    ],
    [
        "INDUSTRIAL RUBBER AND MECHANICS",
        "INDUSTRIAL RUBBER AND MECHANICS"
    ],
    [
        "INDUSTRIAL SAFETY",
        "INDUSTRIAL SAFETY"
    ],
    [
        "INDUSTRIAL TECHNOLOGIES INC",
        "INDUSTRIAL TECHNOLOGIES"
    ],
    [
        "INDUSTRIAL WOODWORKING CORP",
        "INDUSTRIAL WOODWORKING"
    ],
    [
        "INDUSTRIE-SERVICE STADE",
        "INDUSTRIE SERVICE STADE"
    ],
    [
        "HK INDUSTRIEVERTRETUNGEN",
        "INDUSTRIEVERTRETUNGEN"
    ],
    [
        "INGERSOL RAND COMPANY",
        "INGERSOL RAND"
    ],
    [
        "INNOVATIVE PRODUCTS AND E",
        "INNOVATIVE PRODUCTS AND E"
    ],
    [
        "INNOVATIVE RESEARCH",
        "INNOVATIVE RESEARCH"
    ],
    [
        "INNOVECTION LTD",
        "INNOVECTION"
    ],
    [
        "INPRO CORPORATION",
        "INPRO CORPORATION"
    ],
    [
        "INSEKTOL AG PEST CONTROL",
        "INSEKTOL PEST CONTROL"
    ],
    [
        "INSITE SOLUTIONS, LLC",
        "INSITE SOLUTIONS"
    ],
    [
        "INSPECTION EQUIPMENT COMPANY LTD",
        "INSPECTION EQUIPMENT"
    ],
    [
        "INSPECTION EQUIPMENT CO",
        "INSPECTION EQUIPMENT"
    ],
    [
        "INSPECTION EQUIPMENT LTD,",
        "INSPECTION EQUIPMENT"
    ],
    [
        "INSTITUTE OF PROJECT MGT",
        "INSTITUTE OF PROJECT MGT"
    ],
    [
        "INTER-CITY SUPPLY CO. INC.",
        "INTER CITY SUPPLY"
    ],
    [
        "INTERNATIONAL ABRASIVE JE",
        "INTERNATIONAL ABRASIVE JE"
    ],
    [
        "INTERNATIONAL CONFIGURATI",
        "INTERNATIONAL CONFIGURATI"
    ],
    [
        "INTERSTATE ALL BATTERY CENTER",
        "INTERSTATE ALL BATTERY CENTER"
    ],
    [
        "INTOOL AG",
        "INTOOL"
    ],
    [
        "IPROMOTION MITTELLAND GMBH",
        "IPROMOTION MITTELLAND"
    ],
    [
        "IPSEN INC",
        "IPSEN"
    ],
    [
        "IRIS CHEMICAL SA DE CV",
        "IRIS CHEMICAL"
    ],
    [
        "IRONBASED BY DAVID NYDEGGER",
        "IRONBASED DAVID NYDEGGER"
    ],
    [
        "ITP GMBH",
        "ITP"
    ],
    [
        "ITW TRANSTECH, INC.",
        "ITW TRANSTECH"
    ],
    [
        "IVA SCHMETZ GMBH",
        "IVA SCHMETZ"
    ],
    [
        "IVF HARTMANN AG",
        "IVF HARTMANN"
    ],
    [
        "IVK BUHR & KETZER GMBH",
        "IVK BUHR & KETZER"
    ],
    [
        "J.J.O'TOOLE LTD.,",
        "J J O TOOLE"
    ],
    [
        "J STERLING INDUSTRIES LLC",
        "J STERLING INDUSTRIES"
    ],
    [
        "JA FRATE INC. *",
        "JA FRATE"
    ],
    [
        "JACOBSEN .LTD",
        "JACOBSEN .LTD"
    ],
    [
        "JAMECO",
        "JAMECO"
    ],
    [
        "JBS SYSTEM GMBH",
        "JBS SYSTEM"
    ],
    [
        "JET-CUTTING RALF FISCHER",
        "JET CUTTING RALF FISCHER"
    ],
    [
        "JETSCHKE INDUSTRIEFAHRZEUGE",
        "JETSCHKE INDUSTRIEFAHRZEUGE"
    ],
    [
        "JOHN GODRICH LTD",
        "JOHN GODRICH"
    ],
    [
        "JOHNNY RULLAN AND CO INC",
        "JOHNNY RULLAN AND"
    ],
    [
        "JS SAFETY LTD",
        "JS SAFETY"
    ],
    [
        "JT&M/JANESVILLE TOOL INC.",
        "JT&M JANESVILLE TOOL"
    ],
    [
        "JX PETERBILT KLMZOO",
        "JX PETERBILT KLMZOO"
    ],
    [
        "KAISER & STUCKERT",
        "KAISER & STUCKERT"
    ],
    [
        "KAISER + KRAFT GMBH",
        "KAISER KRAFT"
    ],
    [
        "KAISER ET KRAFT AG",
        "KAISER KRAFT"
    ],
    [
        "KAISER U. KRAFT GMBH",
        "KAISER U KRAFT"
    ],
    [
        "KARDEX SAS",
        "KARDEX"
    ],
    [
        "KARDEX DEUTSCHLAND GMBH",
        "KARDEX"
    ],
    [
        "KEM-TRON, INC.",
        "KEM TRON"
    ],
    [
        "KENDALL ELECTRIC INC",
        "KENDALL ELECTRIC"
    ],
    [
        "KENTWOOD PACKAGING CORP",
        "KENTWOOD PACKAGING"
    ],
    [
        "KEY SURGICAL INC.",
        "KEY SURGICAL"
    ],
    [
        "KEYENCE CORP OF AMERICA",
        "KEYENCE"
    ],
    [
        "KEYENCE DEUTSCHLAND GMBH",
        "KEYENCE"
    ],
    [
        "KEYSTONE FOLDING BOX CO",
        "KEYSTONE FOLDING BOX"
    ],
    [
        "KIELTEXTIL",
        "KIELTEXTIL"
    ],
    [
        "KIMBALL ELECTRONICS",
        "KIMBALL ELECTRONICS"
    ],
    [
        "KINCAID PLASTICS ,INC.",
        "KINCAID PLASTICS"
    ],
    [
        "KINGDON SHEET METAL",
        "KINGDON SHEET METAL"
    ],
    [
        "GEO KINGSBURY MACHINETOOL",
        "KINGSBURY MACHINETOOL"
    ],
    [
        "KINNARPS GMBH",
        "KINNARPS"
    ],
    [
        "KISTEN KEIL KIEL GMBH",
        "KISTEN KEIL KIEL"
    ],
    [
        "KKS ULTRASCHALL AG",
        "KKS ULTRASCHALL"
    ],
    [
        "KLEIN L",
        "KLEIN L"
    ],
    [
        "KLEIN L.AG/SA",
        "KLEIN L"
    ],
    [
        "KL¸VER & KOHBERG GMBH",
        "KL¸VER & KOHBERG"
    ],
    [
        "KLⁿVER & KOHBERG GMBH",
        "KLⁿVER & KOHBERG"
    ],
    [
        "KM INDUSTRIAL MACHINER",
        "KM INDUSTRIAL MACHINER"
    ],
    [
        "KODEX INC",
        "KODEX"
    ],
    [
        "KOLB & BAUMANN GMBH & CO. KG",
        "KOLB & BAUMANN"
    ],
    [
        "KORPACK, INC.",
        "KORPACK"
    ],
    [
        "KOS ERGONOMIC SOLUTIONS LTD",
        "KOS ERGONOMIC SOLUTIONS"
    ],
    [
        "KREYENBERG GMBH",
        "KREYENBERG"
    ],
    [
        "KS&C INDUSTRIES",
        "KS&C INDUSTRIES"
    ],
    [
        "KUHMICHEL ABRASIV GMBH",
        "KUHMICHEL ABRASIV"
    ],
    [
        "KURARAY AMERICA, INC",
        "KURARAY"
    ],
    [
        "KVT-FASTENING",
        "KVT FASTENING"
    ],
    [
        "LAB EXPRESS INTERNATIONAL *",
        "LAB EXPRESS INTERNATIONAL"
    ],
    [
        "LAB-PRO, INC.",
        "LAB PRO"
    ],
    [
        "LABJACK CORPORATION",
        "LABJACK"
    ],
    [
        "LABORATORY INSTRUMENTS",
        "LABORATORY INSTRUMENTS"
    ],
    [
        "LABQUIP (IRELAND) LTD.,",
        "LABQUIP"
    ],
    [
        "LAKE REGION MANUFACTURING",
        "LAKE REGION MANUFACTURING"
    ],
    [
        "LAKEVIEW EQUIPMENT",
        "LAKEVIEW EQUIPMENT"
    ],
    [
        "LANCASTER MOVING AND STORAGE I",
        "LANCASTER MOVING AND STORAGE I"
    ],
    [
        "LANCER SALES USA, INC. *",
        "LANCER SALES"
    ],
    [
        "LANDAUER EUROPE LTD",
        "LANDAUER"
    ],
    [
        "LANDI GRENCHEN",
        "LANDI GRENCHEN"
    ],
    [
        "LANDSBERG",
        "LANDSBERG ORORA"
    ],
    [
        "LASZLO CSISZAR",
        "LASZLO CSISZAR"
    ],
    [
        "LEACH ENTERPRISES INC.",
        "LEACH ENTERPRISES"
    ],
    [
        "LEGACY LABEL INC.",
        "LEGACY LABEL"
    ],
    [
        "LEHIGH OUTFITTERS",
        "LEHIGH OUTFITTERS"
    ],
    [
        "LENANE PRECISION",
        "LENANE PRECISION"
    ],
    [
        "LENNOX CHEMICALS LTD.,",
        "LENNOX LABORATORY SUPPLIES"
    ],
    [
        "LERBS AG",
        "LERBS"
    ],
    [
        "LETTERTEC IRELAND LTD",
        "LETTERTEC"
    ],
    [
        "LEXAMED, LTD",
        "LEXAMED"
    ],
    [
        "LICEFA GMBH & CO. KG",
        "LICEFA"
    ],
    [
        "LIFE SCIENCE TECHNOLOGIES",
        "LIFE SCIENCE TECHNOLOGIES"
    ],
    [
        "LIFE TECHNOLOGIES (WAS",
        "LIFE TECHNOLOGIES WAS"
    ],
    [
        "LIFT TRUCK SALES LTD",
        "LIFT TRUCK SALES"
    ],
    [
        "LIGHTING AND CONTROL AUTOMA",
        "LIGHTING AND CONTROL AUTOMA"
    ],
    [
        "LILLY ENGINEERING CO.,INC",
        "LILLY ENGINEERING"
    ],
    [
        "LINAK U.S. INC.",
        "LINAK U S"
    ],
    [
        "LINDBERG-MPH",
        "LINDBERG MPH"
    ],
    [
        "LIVE WIRE",
        "LIVE WIRE"
    ],
    [
        "LONG RANGE SYSTEMS PUERTO RICO",
        "LONG RANGE SYSTEMS"
    ],
    [
        "LORENSCHEIT AUTOMATISIERUNGS",
        "LORENSCHEIT AUTOMATISIERUNGS"
    ],
    [
        "LORTON SAS ET CIE",
        "LORTON CIE"
    ],
    [
        "LOWRY COMPUTER PRODUCTS",
        "LOWRY COMPUTER PRODUCTS"
    ],
    [
        "LOWRY HOLDING COMPANY",
        "LOWRY HOLDING COMPANY"
    ],
    [
        "LUHA LUTTMANN & HAGEMANN",
        "LUHA LUTTMANN & HAGEMANN"
    ],
    [
        "LUIS A DE ALBA GARCIA",
        "LUIS A ALBA GARCIA"
    ],
    [
        "LUIS CARLOS COLON",
        "LUIS CARLOS COLON"
    ],
    [
        "LUMEN DYNAMICS GROUP, INC",
        "LUMEN DYNAMICS GROUP"
    ],
    [
        "M2 OFFICE SUPPLIES",
        "M2 OFFICE SUPPLIES"
    ],
    [
        "MACHEREY-NAGEL GMBH & CO. KG",
        "MACHEREY NAGEL"
    ],
    [
        "MACHINE BUILDING SYSTEMS LIMITED",
        "MACHINE BUILDING SYSTEMS"
    ],
    [
        "MACHINE CONTROL",
        "MACHINE CONTROL"
    ],
    [
        "MACHINE TOOL TECH.,",
        "MACHINE TOOL TECHNOLOGIES"
    ],
    [
        "MAG45 SWITZERLAND SARL",
        "MAG45"
    ],
    [
        "MAG45 GMBH",
        "MAG45"
    ],
    [
        "MAHR FEDERAL INC.,",
        "MAHR FEDERAL"
    ],
    [
        "MAILBOX INTERNATIONAL LTD",
        "MAILBOX INTERNATIONAL"
    ],
    [
        "MAILLEFER INSTRUMENTS",
        "MAILLEFER INSTRUMENTS"
    ],
    [
        "MAINSITE GMBH & CO.KG",
        "MAINSITE"
    ],
    [
        "MAK FIONTAIR LTD",
        "MAK FIONTAIR"
    ],
    [
        "MALL CITY CONTAINERS INC",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTAINERS INC.",
        "MALL CITY CONTAINERS"
    ],
    [
        "MANGAR INDUSTRIES INC",
        "MANGAR INDUSTRIES"
    ],
    [
        "MAQUINADOS PEREZ",
        "MAQUINADOS PEREZ"
    ],
    [
        "MARCOR PURIFICATION *",
        "MARCOR PURIFICATION"
    ],
    [
        "MARK ANDY, INC.",
        "MARK ANDY"
    ],
    [
        "MARKEM-IMAJE CORPORATION *",
        "MARKEM-IMAJE CORPORATION"
    ],
    [
        "MARSHALL WOLF AUTOMATION *",
        "MARSHALL WOLF AUTOMATION"
    ],
    [
        "MARTOR USA *",
        "MARTOR"
    ],
    [
        "MASON TECHNOLOGY LTD.,",
        "MASON TECHNOLOGY"
    ],
    [
        "MASON TECHNOLOGY",
        "MASON TECHNOLOGY"
    ],
    [
        "MASTER BOND INC. - HACKEN",
        "MASTER BOND"
    ],
    [
        "MATERIANT, INC. - AKRON",
        "MATERIANT AKRON"
    ],
    [
        "MATSUI AMERICA INC",
        "MATSUI"
    ],
    [
        "MAW WERKZEUGMASCHINEN GMBH",
        "MAW WERKZEUGMASCHINEN"
    ],
    [
        "MAXIM MEDICAL SERVICES",
        "MAXIM MEDICAL SERVICES"
    ],
    [
        "MAXON PRECISION MOTORS",
        "MAXON PRECISION MOTORS"
    ],
    [
        "MAYS OCHOA",
        "MAYS OCHOA"
    ],
    [
        "MAZAK CORPORATION",
        "MAZAK"
    ],
    [
        "MBC AND AFFILIATES INC",
        "MBC AND AFFILIATES"
    ],
    [
        "MC MACHINERY SYSTEM INC",
        "MC MACHINERY SYSTEM INC"
    ],
    [
        "MCCRONE ASSOCIATES, INC.",
        "MCCRONE ASSOCIATES"
    ],
    [
        "MCELLIGOTT COACHES",
        "MCELLIGOTT COACHES"
    ],
    [
        "MCKERNS PRINTING LTD.,",
        "MCKERNS PRINTING"
    ],
    [
        "MCKESSON MEDICAL-SURGICAL INC",
        "MCKESSON MEDICAL SURGICAL"
    ],
    [
        "MCMASTER CARR",
        "MCMASTER CARR"
    ],
    [
        "MC MASTER CARR",
        "MCMASTER CARR"
    ],
    [
        "MCMASTER-CARR",
        "MCMASTER CARR"
    ],
    [
        "MCMASTER-CARR *",
        "MCMASTER CARR"
    ],
    [
        "MCMASTER-CARR SUPPLY",
        "MCMASTER CARR"
    ],
    [
        "MCMASTER-CARR,",
        "MCMASTER CARR"
    ],
    [
        "MCMASTERCARR SUPPLY",
        "MCMASTER CARR"
    ],
    [
        "MCMASTER CARR SUPPLY",
        "MCMASTER-CARR"
    ],
    [
        "MCMASTER CARR SUPPLY CO",
        "MCMASTER-CARR"
    ],
    [
        "MCMASTER-CARR INC",
        "MCMASTER-CARR"
    ],
    [
        "MCNEIL INC",
        "MCNEIL INC"
    ],
    [
        "MDC MAX DAETWYLER AG",
        "MDC MAX DAETWYLER"
    ],
    [
        "MED PLUS INTERNET",
        "MED PLUS INTERNET"
    ],
    [
        "MEDCO",
        "MEDCO"
    ],
    [
        "MEDICAL DEVICES SERVICES",
        "MEDICAL DEVICES SERVICES"
    ],
    [
        "MEDPLAST SERVICES INC &",
        "MEDPLAST SERVICES"
    ],
    [
        "MEMRY CORPORATION - MENLO",
        "MEMRY"
    ],
    [
        "MERCATEO  DEUTSCHLAND AG",
        "MERCATEO"
    ],
    [
        "MERCK CHEMICALS LIMITED",
        "MERCK CHEMICALS"
    ],
    [
        "MERCK MILLIPORE LTD",
        "MERCK MILLIPORE"
    ],
    [
        "MERIT MEDICAL SYSTEMS INC",
        "MERIT MEDICAL SYSTEMS"
    ],
    [
        "MESA LABORATORIES, INC. *",
        "MESA LABORATORIES"
    ],
    [
        "METER GROUP, INC USA",
        "METER GROUP"
    ],
    [
        "METROCAL INC",
        "METROCAL"
    ],
    [
        "METSCH REFRACTORIES INC",
        "METSCH REFRACTORIES"
    ],
    [
        "MICRO PRECISION",
        "MICRO PRECISION"
    ],
    [
        "MICRO TECHNOLOGY",
        "MICRO TECHNOLOGY"
    ],
    [
        "MICROBIOLOGICS *",
        "MICROBIOLOGICS"
    ],
    [
        "MICROCENTRIC CORP",
        "MICROCENTRIC"
    ],
    [
        "MICROCHALLENGE LLC *",
        "MICROCHALLENGE"
    ],
    [
        "MICROMERITICS INSTRUMENTS CORP",
        "MICROMERITICS INSTRUMENTS"
    ],
    [
        "MICRORIDGE SYSTEM INC",
        "MICRORIDGE SYSTEM"
    ],
    [
        "MICRORIDGE SYSTEMS INC",
        "MICRORIDGE SYSTEM"
    ],
    [
        "MICROWEST SOFTWARE",
        "MICROWEST SOFTWARE"
    ],
    [
        "MIDWEST SAFETY PRODUCT",
        "MIDWEST SAFETY PRODUCTS"
    ],
    [
        "MIDWEST SAFETY PRODUCTS",
        "MIDWEST SAFETY PRODUCTS"
    ],
    [
        "MILLENNIUM MACHINERY",
        "MILLENNIUM MACHINERY"
    ],
    [
        "MILLER STEPHENSON",
        "MILLER STEPHENSON"
    ],
    [
        "MINK-B¸RSTEN",
        "MINK B¸RSTEN"
    ],
    [
        "MINK-BⁿRSTEN",
        "MINK BⁿRSTEN"
    ],
    [
        "MISUMI USA INCORPORATED",
        "MISUMI"
    ],
    [
        "MISUMI USA INC",
        "MISUMI USA"
    ],
    [
        "MITSUBISHI MMC METAL FR",
        "MITSUBISHI MMC METAL"
    ],
    [
        "MOCAP LLC",
        "MOCAP"
    ],
    [
        "MODERN INDUSTRIES",
        "MODERN INDUSTRIES"
    ],
    [
        "MODULAR AUTOMATION LTD",
        "MODULAR AUTOMATION"
    ],
    [
        "MODULAR AUTOMATION (MOLEX",
        "MODULAR AUTOMATION MOLEX"
    ],
    [
        "MOL-SON INC.",
        "MOL SON"
    ],
    [
        "MOLL ENGINEERING GMBH",
        "MOLL ENGINEERING"
    ],
    [
        "MONNIER + ZAHNER AG",
        "MONNIER ZAHNER"
    ],
    [
        "MONODE MARKING PRODUCTS I",
        "MONODE MARKING PRODUCTS"
    ],
    [
        "MOORE ELECTRICAL SERV INC",
        "MOORE ELECTRICAL SERV"
    ],
    [
        "MOORE WALLACE-AN RR DONNELLEY CO",
        "MOORE WALLACEAN RR DONNELLEY"
    ],
    [
        "MORRIS PLAINS SHOES",
        "MORRIS PLAINS SHOES"
    ],
    [
        "MOS PLASTICS - SAN JOSE",
        "MOS PLASTICS"
    ],
    [
        "MOUSER ELECTRONICS INC",
        "MOUSER ELECTRONICS"
    ],
    [
        "MOUSER ELECTRONICS",
        "MOUSER ELECTRONICS"
    ],
    [
        "MPI INCORPORATED",
        "MPI INCORPORATED"
    ],
    [
        "MR COMP GMBH *",
        "MR COMP"
    ],
    [
        "MSC",
        "MSC"
    ],
    [
        "MSC INDUSTRIAL SUPPLY",
        "MSC INDUSTRIAL SUPPLY"
    ],
    [
        "MSC INDUSTRIAL SUPPLY CO",
        "MSC INDUSTRIAL SUPPLY"
    ],
    [
        "MSC INDUSTRIAL SUPPLY CO INC",
        "MSC INDUSTRIAL SUPPLY"
    ],
    [
        "MSC INDUSTRIAL SUPPLY CO.",
        "MSC INDUSTRIAL SUPPLY"
    ],
    [
        "MSDSONLINE INCORPORATED",
        "MSDSONLINE INCORPORATED"
    ],
    [
        "MSLI, GP",
        "MSLI"
    ],
    [
        "MUHLACK KIEL GMBH",
        "MUHLACK KIEL"
    ],
    [
        "MULTIPLEX ENG LTD",
        "MULTIPLEX ENGINEERING LTD"
    ],
    [
        "MUNSTER PRECISION ENG",
        "MUNSTER PRECISION ENG"
    ],
    [
        "MUNSTER TOOL & DIE LTD.,",
        "MUNSTER TOOL & DIE"
    ],
    [
        "MUNSTER TOOL + DIE LTD",
        "MUNSTER TOOL & DIE"
    ],
    [
        "NABELL USA",
        "NABELL"
    ],
    [
        "NORTH AMERICA SCIENCE ASS",
        "NAMSA"
    ],
    [
        "NATIONAL PRECISION BEARIN",
        "NATIONAL PRECISION"
    ],
    [
        "NDC TECHNOLOGIES, INC.",
        "NDC TECHNOLOGIES"
    ],
    [
        "NEFF ENGINEERING 2",
        "NEFF ENGINEERING"
    ],
    [
        "NELSON LABORATORIES INC -",
        "NELSON LABS"
    ],
    [
        "NETZTECH HANDELS AG",
        "NETZTECH HANDELS"
    ],
    [
        "NEUPACK PRODUKTE AG",
        "NEUPACK PRODUKTE"
    ],
    [
        "NEW ANO-KAL CO.",
        "NEW ANO KAL"
    ],
    [
        "NEW DARMON ETIQUETTES",
        "NEW DARMON ETIQUETTES"
    ],
    [
        "NEWARK IN ONE",
        "NEWARK"
    ],
    [
        "NEWARK",
        "NEWARK"
    ],
    [
        "NEWARK ELECTRONICS",
        "NEWARK"
    ],
    [
        "NEWARK ELEMENT14 *",
        "NEWARK"
    ],
    [
        "NEXEO SOLUTIONS, LLC *",
        "NEXEO SOLUTIONS"
    ],
    [
        "NICOR GAS-E",
        "NICOR GAS"
    ],
    [
        "NIKE.COM",
        "NIKE.COM"
    ],
    [
        "NILFISK ADVANCE AMERICA",
        "NILFISK"
    ],
    [
        "NORDSON CORPORATION",
        "NORDSON"
    ],
    [
        "NORDSON ASYMTEK",
        "NORDSON ASYMTEK"
    ],
    [
        "NORDSON EFD LLC",
        "NORDSON EFD"
    ],
    [
        "NORDSON LLC (EFD)",
        "NORDSON LLC EFD"
    ],
    [
        "NORDSON MEDICAL",
        "NORDSON MEDICAL"
    ],
    [
        "NORELEM NORMELEMENTE KG",
        "NORELEM NORMELEMENTE"
    ],
    [
        "NOREX GROUP, LLC",
        "NOREX GROUP"
    ],
    [
        "NORGREN INC",
        "NORGREN"
    ],
    [
        "NORTHLAKE ENGINEERING",
        "NORTHLAKE ENGINEERING"
    ],
    [
        "NPC NEW PIG CORP",
        "NPC NEW PIG"
    ],
    [
        "OBRIEN OFFICE SYSTEMS",
        "OBRIEN OFFICE SYSTEMS"
    ],
    [
        "OCON CHEMICALS LTD.",
        "OCON CHEMICALS"
    ],
    [
        "OCON CHEMICALS LTD",
        "OCON CHEMICALS"
    ],
    [
        "OELHELD UK LTD",
        "OELHELD"
    ],
    [
        "OES CONSULTING",
        "OES CONSULTING"
    ],
    [
        "OFFICE MAX",
        "OFFICE MAX"
    ],
    [
        "OK INTERNATIONAL INC",
        "OK INTERNATIONAL"
    ],
    [
        "OLYMPIC GLOVE & SAFETY CO INC",
        "OLYMPIC GLOVE & SAFETY"
    ],
    [
        "OMEGA ASSOCIATES",
        "OMEGA ASSOCIATES"
    ],
    [
        "OMEGA ENGINEERING INC",
        "OMEGA ENGINEERING"
    ],
    [
        "OMNI FINISHING",
        "OMNI FINISHING"
    ],
    [
        "OMNI-TECH SALES, INC.",
        "OMNI TECH SALES"
    ],
    [
        "ONDAL",
        "ONDAL"
    ],
    [
        "ONLINE FURNACE SERVICES",
        "ONLINE FURNACE SERVICES"
    ],
    [
        "OPS LIMITED",
        "OPS LIMITED"
    ],
    [
        "OPTI TEMP INC",
        "OPTI TEMP"
    ],
    [
        "OPTIMAL TEHNOLOGIES LTD",
        "OPTIMAL TEHNOLOGIES"
    ],
    [
        "OPTINOVA-MLE, INC. - PLYM",
        "OPTINOVA"
    ],
    [
        "ORANGE COAST PNEUMATICS",
        "ORANGE COAST PNEUMATICS"
    ],
    [
        "ORBIS",
        "ORBIS"
    ],
    [
        "ORGATEX GMBH & CO. KG",
        "ORGATEX"
    ],
    [
        "OTTO JˆRGENSEN & SOHN GMBH",
        "OTTO JˆRGENSEN & SOHN"
    ],
    [
        "OWIBA AG",
        "OWIBA"
    ],
    [
        "P.J. BRENNAN & CO. LTD.,",
        "P J BRENNAN"
    ],
    [
        "PABLO MIRANDA SCALE",
        "PABLO MIRANDA SCALE"
    ],
    [
        "PACK SECURE",
        "PACK SECURE"
    ],
    [
        "PACKWORLD USA",
        "PACKWORLD"
    ],
    [
        "PALLADIAM",
        "PALLADIAM"
    ],
    [
        "PALLET EXPRESS",
        "PALLET EXPRESS"
    ],
    [
        "PANDA PRODUCTS",
        "PANDA PRODUCTS"
    ],
    [
        "PAPER CONVERTING MACH.CO. *",
        "PAPER CONVERTING MACH"
    ],
    [
        "PAPERLESS SOLUTIONS LLC",
        "PAPERLESS SOLUTIONS"
    ],
    [
        "PAPRITZ AG",
        "PAPRITZ"
    ],
    [
        "PARAMELT ARGUESO KINDT",
        "PARAMELT"
    ],
    [
        "PARATRONIX, INC. - ATTLEB",
        "PARATRONIX"
    ],
    [
        "PARKER HANNIFIN CORPORATION",
        "PARKER HANNIFIN"
    ],
    [
        "PARTICULAR SCIENCES LTD",
        "PARTICULAR SCIENCES"
    ],
    [
        "PAT MANNING",
        "PAT MANNING"
    ],
    [
        "PAUL JOHNSON & ASSOCIATES",
        "PAUL JOHNSON & ASSOCIATES"
    ],
    [
        "PAYPAL  PROENDOSCOP",
        "PAYPAL"
    ],
    [
        "PAYPAL  SAN",
        "PAYPAL"
    ],
    [
        "PDI TOOLING 2000",
        "PDI TOOLING"
    ],
    [
        "PELLETREAU EMBALLAGES",
        "PELLETREAU EMBALLAGES"
    ],
    [
        "PEMRO",
        "PEMRO"
    ],
    [
        "PERFORMANCE SYSTEMATIX",
        "PERFORMANCE SYSTEMATIX"
    ],
    [
        "PERFORMANCE VALIDATION",
        "PERFORMANCE VALIDATION"
    ],
    [
        "PERKINELMER HEALTH SCIENCES, INC.",
        "PERKINELMER"
    ],
    [
        "PERKUTE MASCHINENBAU GMBH",
        "PERKUTE MASCHINENBAU"
    ],
    [
        "PETROCHOICE HOLDINGS, INC. *",
        "PETROCHOICE HOLDINGS"
    ],
    [
        "PGF TECHNOLOGY GROUP, INC",
        "PGF TECHNOLOGY"
    ],
    [
        "PHACON GMBH",
        "PHACON"
    ],
    [
        "PHARMA SERVICES",
        "PHARMA SERVICES"
    ],
    [
        "PHELLY MATERIALS INC",
        "PHELLY MATERIALS"
    ],
    [
        "PHENOMENEX",
        "PHENOMENEX"
    ],
    [
        "PIERCAN SAS",
        "PIERCAN"
    ],
    [
        "PIKE TECHNOLOGIES",
        "PIKE TECHNOLOGIES"
    ],
    [
        "PIPETTE COM",
        "PIPETTE COM"
    ],
    [
        "PITTSBURG PAINTS",
        "PITTSBURG PAINTS"
    ],
    [
        "PLANIT SOFTWARE LTD",
        "PLANIT SOFTWARE"
    ],
    [
        "PLASTEX CORPORATION",
        "PLASTEX"
    ],
    [
        "PLASTIC FABRICATING",
        "PLASTIC FABRICATING"
    ],
    [
        "PLASTIC PROCESS EQUIPMENT *",
        "PLASTIC PROCESS EQUIPMENT"
    ],
    [
        "PLEXPACK",
        "PLEXPACK"
    ],
    [
        "PMS BECUS METROLOGIE",
        "PMS BECUS METROLOGIE"
    ],
    [
        "POLYFORM KUNSTSTOFFTECHNIK",
        "POLYFORM KUNSTSTOFFTECHNIK"
    ],
    [
        "POLYSCIENCE AG",
        "POLYSCIENCE"
    ],
    [
        "POLYTECHNA AG",
        "POLYTECHNA"
    ],
    [
        "POMEROY IT SOLUTIONS SALES COMPANY *",
        "POMEROY SOLUTIONS SALES"
    ],
    [
        "POREX CLEANROOM PRODUCTS INC",
        "POREX CLEANROOM PRODUCTS"
    ],
    [
        "POREX TECHNOLOGIES",
        "POREX TECHNOLOGIES"
    ],
    [
        "POW R SAVE INC",
        "POW R SAVE"
    ],
    [
        "POWDER SYSTEMS LIMITED",
        "POWDER SYSTEMS"
    ],
    [
        "PP LLC",
        "PP LLC"
    ],
    [
        "PRECISION COATING CO",
        "PRECISION COATING"
    ],
    [
        "PRECISION COATING COMPANY",
        "PRECISION COATING"
    ],
    [
        "PRECISION DYNAMIC CORPORATE",
        "PRECISION DYNAMIC ORATE"
    ],
    [
        "PRECISION METROLOGY",
        "PRECISION METROLOGY"
    ],
    [
        "PRECISION VALVE AND AUTOMATION INC",
        "PRECISION VALVE AND AUTOMATION"
    ],
    [
        "PRECISION WATERJET, INC. *",
        "PRECISION WATERJET"
    ],
    [
        "PREMIER MACHINE TOOLS,",
        "PREMIER MACHINE TOOL"
    ],
    [
        "PREMIER MACHINE TOOLS",
        "PREMIER MACHINE TOOLS"
    ],
    [
        "PRESTIGE GRAPHICS INC",
        "PRESTIGE GRAPHICS"
    ],
    [
        "PREVOR GMBH",
        "PREVOR"
    ],
    [
        "PRIMAGAZ",
        "PRIMAGAZ"
    ],
    [
        "PRINT 1 SOLUTIONS",
        "PRINT 1 SOLUTIONS"
    ],
    [
        "PRISMA COMPUTER",
        "PRISMA COMPUTER"
    ],
    [
        "PRO SOURCE IRELAND LTD",
        "PRO SOURCE"
    ],
    [
        "PROCESS TECHNOLOGY INC",
        "PROCESS TECHNOLOGY"
    ],
    [
        "PROCESS WELDING SYSTEMS",
        "PROCESS WELDING SYSTEMS"
    ],
    [
        "PRODUCTION EQUIPMENT LTD",
        "PRODUCTION EQUIPMENT"
    ],
    [
        "PRODUCTION TOOL SUPPLY CO LLC",
        "PRODUCTION TOOL SUPPLY"
    ],
    [
        "PROLIFT HANDLING LTD",
        "PROLIFT HANDLING"
    ],
    [
        "PROMETALL HANDEL AG",
        "PROMETALL HANDEL"
    ],
    [
        "PROTO LABS INC",
        "PROTO LABS"
    ],
    [
        "PROTOLABS INC",
        "PROTO LABS"
    ],
    [
        "PROTOTYPE & PLASTIC MOLD",
        "PROTOTYPE & PLASTIC MOLD"
    ],
    [
        "PRUEFTECHNIK E. KOCH",
        "PRUEFTECHNIK E KOCH"
    ],
    [
        "PRÉTAT",
        "PRÉTAT"
    ],
    [
        "PURITY CYLINDER GASES INC",
        "PURITY CYLINDER GASES"
    ],
    [
        "PVA TEPLA AMERICA, INC.",
        "PVA TEPLA AMERICA"
    ],
    [
        "PVSR CORPORATION",
        "PVSR"
    ],
    [
        "PYROMATRIX",
        "PYROMATRIX"
    ],
    [
        "Q DESIGN",
        "Q DESIGN"
    ],
    [
        "Q-MARK MANUFACTURING INC",
        "Q MARK MANUFACTURING"
    ],
    [
        "Q SOURCE INC",
        "Q SOURCE"
    ],
    [
        "QOSINA CORP",
        "QOSINA"
    ],
    [
        "QUALITY VISION SERVICES",
        "QUALITY VISION SERVICES"
    ],
    [
        "QUINTUS TECHNOLOGIES LLC",
        "QUINTUS TECHNOLOGIES"
    ],
    [
        "QUIRKE&SHAW CLEANING SUPP",
        "QUIRKE&SHAW CLEANING SUPP"
    ],
    [
        "R.H. HUGHES CO., INC",
        "R H HUGHES"
    ],
    [
        "RADIONICS LTD.,",
        "RADIONICS"
    ],
    [
        "RAFAEL BENITEZ CARILLO INC",
        "RAFAEL BENITEZ CARILLO"
    ],
    [
        "RAHMEN VERSAND.COM",
        "RAHMEN VERSAND COM"
    ],
    [
        "RAJAPACK GMBH",
        "RAJAPACK"
    ],
    [
        "RAJA",
        "RAJA Group"
    ],
    [
        "RAPID EDGE, INC.",
        "RAPID EDGE"
    ],
    [
        "RAPID SHEET METAL LLC",
        "RAPID SHEET METAL"
    ],
    [
        "RAWTEC INDUSTIESERVICE",
        "RAWTEC INDUSTIESERVICE"
    ],
    [
        "READY-MADE PLASTIC",
        "READY MADE PLASTIC"
    ],
    [
        "READY-MADE PLASTIC TRAY",
        "READY MADE PLASTIC"
    ],
    [
        "READY MADE PLASTIC TRAYS",
        "READY MADE PLASTIC"
    ],
    [
        "REAGECON DIAGNOSTICS LTD.",
        "REAGECON DIAGNOSTICS"
    ],
    [
        "REAGECON DIAGNOSTICS LTD",
        "REAGECON DIAGNOSTICS"
    ],
    [
        "RECYCLE REVOLUTION LLC",
        "RECYCLE REVOLUTION"
    ],
    [
        "RED ROBIN AMERICA'S GOURMET BURGERS",
        "RED ROBIN AMERICA'S GOURMET BURGERS"
    ],
    [
        "RED WING BUSINESS ADVANTAGE ACCOUNT *",
        "RED WING BUSINESS ADVANTAGE ACCOUNT"
    ],
    [
        "REGAL DIAMOND PRODUCTS",
        "REGAL DIAMOND PRODUCTS"
    ],
    [
        "REMONDIS GMBH + CO. KG",
        "REMONDIS"
    ],
    [
        "RENFER JOHANN GMBH",
        "RENFER JOHANN"
    ],
    [
        "RESPRO LIMITED,",
        "RESPRO"
    ],
    [
        "RHEOSENSE, INC.*",
        "RHEOSENSE"
    ],
    [
        "RICE LAKE WEIGHING SYSTEM *",
        "RICE LAKE WEIGHING SYSTEM"
    ],
    [
        "RIGAKU USA INC",
        "RIGAKU"
    ],
    [
        "RIMANN AG",
        "RIMANN"
    ],
    [
        "RIMCO INC",
        "RIMCO"
    ],
    [
        "RISCHE U. HERFURTH",
        "RISCHE U. HERFURTH"
    ],
    [
        "RITCHIE INDUSTRIAL SALES",
        "RITCHIE INDUSTRIAL SALES"
    ],
    [
        "RL SUPPLY DE MEXICO",
        "RL SUPPLY"
    ],
    [
        "ROBSAM LIMITED",
        "ROBSAM"
    ],
    [
        "ROCK RIVER SERVICE *",
        "ROCK RIVER SERVICE"
    ],
    [
        "ROCKFORD SYSTEMS, INC.",
        "ROCKFORD SYSTEMS"
    ],
    [
        "ROCKWELL AUTOMATION",
        "ROCKWELL AUTOMATION"
    ],
    [
        "ROCKWELL AUTOMATION CORK",
        "ROCKWELL AUTOMATION"
    ],
    [
        "RODIX, INC. *",
        "RODIX"
    ],
    [
        "ROESLER GLEITSCHLIFFTECHNIK",
        "ROESLER GLEITSCHLIFFTECHNIK"
    ],
    [
        "ROFIN-SINAR LASER GMBH",
        "ROFIN SINAR LASER"
    ],
    [
        "ROGER BURGESS DR",
        "ROGER BURGESS DR"
    ],
    [
        "ROGERS FOAM CORP",
        "ROGERS FOAM"
    ],
    [
        "ROHR AG HANDEL",
        "ROHR HANDEL"
    ],
    [
        "ROHR AG REINIGUNGEN",
        "ROHR REINIGUNGEN"
    ],
    [
        "ROSS A FINNER",
        "ROSS A FINNER"
    ],
    [
        "ROTGER INDUST SERVICES I",
        "ROTGER INDUST SERVICES"
    ],
    [
        "ROTH AG GERLAFINGEN/",
        "ROTH GERLAFINGEN"
    ],
    [
        "ROYAL MASTERS GRINDERS",
        "ROYAL MASTERS GRINDERS"
    ],
    [
        "R.S. HUGHES COMPANY, INC. FL",
        "RS HUGHES"
    ],
    [
        "R S  HUGHES CO  INC",
        "RS HUGHES"
    ],
    [
        "R.S. HUGHES COMPANY - SUN",
        "RS HUGHES"
    ],
    [
        "R.S. HUGHES COMPANY INC. *",
        "RS HUGHES"
    ],
    [
        "R S  HUGHES",
        "RS HUGHES"
    ],
    [
        "R.S. HUGHES CO., INC.",
        "RS HUGHES"
    ],
    [
        "RSI MEXICO S DE RL DE CV",
        "RSI"
    ],
    [
        "RSI CRATING AND PACKAGING",
        "RSI CRATING AND PACKAGING"
    ],
    [
        "RUWAC GMBH",
        "RUWAC"
    ],
    [
        "S & B METAL PRODUCTS, INC.",
        "S & B METAL PRODUCTS"
    ],
    [
        "S & S INDUSTRIAL SUPPLY,",
        "S&S INDUSTRIAL SUPPLY"
    ],
    [
        "S & S INDUSTRIAL SUPPLY",
        "S&S INDUSTRIAL SUPPLY"
    ],
    [
        "SAF GARD",
        "SAF GARD"
    ],
    [
        "SAFEAIR AG",
        "SAFEAIR"
    ],
    [
        "SAFETY GLASSES USA INC",
        "SAFETY GLASSES"
    ],
    [
        "SAFETY KLEEN INC",
        "SAFETY KLEEN"
    ],
    [
        "SAFETY ZONE",
        "SAFETY ZONE"
    ],
    [
        "SAINT GOBAIN",
        "SAINT GOBAIN"
    ],
    [
        "SALZDEPOT ZÜRICH AG",
        "SALZDEPOT ZÜRICH"
    ],
    [
        "SAMATEC",
        "SAMATEC"
    ],
    [
        "SAMPSON ENTERPRISE INC",
        "SAMPSON ENTERPRISE"
    ],
    [
        "SANDVIK HIGH PRECISION TUBE",
        "SANDVIK"
    ],
    [
        "SANTIE'S WHOLESALE OIL C",
        "SANTIE S WHOLESALE OIL C"
    ],
    [
        "SAWBONES EUROPE",
        "SAWBONES"
    ],
    [
        "SCHAEFER SHOP GMBH",
        "SCHAEFER SHOP"
    ],
    [
        "SCHAEFFER MFG. COMPANY",
        "SCHAEFFER MFG. COMPANY"
    ],
    [
        "SCHIVO MEDICAL LIMITED",
        "SCHIVO MEDICAL"
    ],
    [
        "SCHLENKER SPANNWERKZEUGE",
        "SCHLENKER SPANNWERKZEUGE"
    ],
    [
        "SCHLENKER SPANNWERKZEUGE GMBH&CO.KG",
        "SCHLENKER SPANNWERKZEUGE"
    ],
    [
        "SCHREINEREI GLUTZ AG",
        "SCHREINEREI GLUTZ"
    ],
    [
        "SCHUPAN & SONS INC.",
        "SCHUPAN & SONS"
    ],
    [
        "SCHUPAN ALUMINUM SALES",
        "SCHUPAN ALUMINUM SALES"
    ],
    [
        "SCHWEI▀TECHNIK KIEL",
        "SCHWEI TECHNIK KIEL"
    ],
    [
        "SCREW MACHINE SVCS",
        "SCREW MACHINE SVCS"
    ],
    [
        "SCT INC",
        "SCT"
    ],
    [
        "SEC CR NIKE.COM - 0174",
        "SEC CR NIKE.COM - 0174"
    ],
    [
        "SEC CR XFINITY - 0174",
        "SEC CR XFINITY - 0174"
    ],
    [
        "SECRETARIO DE HACIENDA",
        "SECRETARIO HACIENDA"
    ],
    [
        "SECURIMED SARL",
        "SECURIMED"
    ],
    [
        "SELLOS TITAN",
        "SELLOS TITAN"
    ],
    [
        "SEMADENI AG",
        "SEMADENI"
    ],
    [
        "SENCORP INC",
        "SENCORP"
    ],
    [
        "SENCORP INC.",
        "SENCORP"
    ],
    [
        "SENSOR PRODUCTS, INC.",
        "SENSOR PRODUCTS"
    ],
    [
        "SEPR-SAINT-GOBAIN",
        "SEPR SAINT GOBAIN"
    ],
    [
        "SEQUENCE, INC.",
        "SEQUENCE"
    ],
    [
        "SERIDAN",
        "SERIDAN"
    ],
    [
        "SESA SYSTEMS",
        "SESA SYSTEMS"
    ],
    [
        "SETAM",
        "SETAM"
    ],
    [
        "SETON INC",
        "SETON"
    ],
    [
        "SETON IDENTIFICATION *",
        "SETON IDENTIFICATION"
    ],
    [
        "SHEPARD MEDICAL PRODUCTS",
        "SHEPARD MEDICAL PRODUCTS"
    ],
    [
        "SHIFTNOTE, LLC",
        "SHIFTNOTE"
    ],
    [
        "SIGMA-ALDRICH,INC./FLUKA",
        "SIGMAALDRICHINC/FLUKA"
    ],
    [
        "SIGN-SYSTEMS",
        "SIGN SYSTEMS"
    ],
    [
        "SIGNAL AG",
        "SIGNAL AG"
    ],
    [
        "SILVENT NORTH AMERICA",
        "SILVENT NORTH"
    ],
    [
        "SINGLE SOURCE TECHNOLOGIES",
        "SINGLE SOURCE TECHNOLOGIES"
    ],
    [
        "SKARSHAUG TESTING",
        "SKARSHAUG TESTING"
    ],
    [
        "SMMA",
        "SMMA"
    ],
    [
        "SO CLEAN SOLUTIONS LLC -",
        "SO CLEAN SOLUTIONS"
    ],
    [
        "SOLAR ATMOSPHERES INC",
        "SOLAR ATMOSPHERES"
    ],
    [
        "SOLAR IMPIANTI SRL",
        "SOLAR IMPIANTI"
    ],
    [
        "SOLSTICE TECHNOLOGIES INC",
        "SOLSTICE TECHNOLOGIES"
    ],
    [
        "SOLVAY ADVANCED POLYMERS",
        "SOLVAY ADVANCED POLYMERS"
    ],
    [
        "SOMEX LIMITED",
        "SOMEX"
    ],
    [
        "SONEPAR DEUTSCHLAND REGION",
        "SONEPAR DEUTSCHLAND REGION"
    ],
    [
        "SONICS & MATERIALS, IN",
        "SONICS & MATERIALS"
    ],
    [
        "SONOCO PLASTICS",
        "SONOCO PLASTICS"
    ],
    [
        "SOS TECHNOLOGIES",
        "SOS TECHNOLOGIES"
    ],
    [
        "SOURCE EQUIPMENT COMPANY",
        "SOURCE EQUIPMENT"
    ],
    [
        "SOURCE LUBRICATION SOLUTI",
        "SOURCE LUBRICATION SOLUTI"
    ],
    [
        "SOUTHERN DOCK PRODUCTS",
        "SOUTHERN DOCK PRODUCTS"
    ],
    [
        "SPAIGHT TOOL&CUTTER GRIND",
        "SPAIGHT TOOL&CUTTER GRIND"
    ],
    [
        "SPECIALTY PACKAGING",
        "SPECIALTY PACKAGING"
    ],
    [
        "SPECTRUM PLASTICS HOLDING",
        "SPECTRUM PLASTICS HOLDING"
    ],
    [
        "SPEEDY METALS LLC",
        "SPEEDY METALS"
    ],
    [
        "SPEMA",
        "SPEMA"
    ],
    [
        "SPRD THEIX INOX",
        "SPRD THEIX INOX"
    ],
    [
        "SRCL LIMITED",
        "SRCL"
    ],
    [
        "SSI SCHAEFER SHOP GMBH",
        "SSI SCHAEFER SHOP"
    ],
    [
        "STAN'S OFF. TECHNOLOGIES *",
        "STAN S OFF TECHNOLOGIES"
    ],
    [
        "STANLEY VIDMAR",
        "STANLEY VIDMAR"
    ],
    [
        "STAPLS7188257356000001",
        "STAPLS7188257356000001"
    ],
    [
        "STAPLS7189458656000001",
        "STAPLS7189458656000001"
    ],
    [
        "STAPLS7189458656000002",
        "STAPLS7189458656000002"
    ],
    [
        "STAPLS7189464416000001",
        "STAPLS7189464416000001"
    ],
    [
        "STAPLS7189685568000002",
        "STAPLS7189685568000002"
    ],
    [
        "STAPLS7189685568001001",
        "STAPLS7189685568001001"
    ],
    [
        "STAPLS7190342238000001",
        "STAPLS7190342238000001"
    ],
    [
        "STAPLS7190983296000001",
        "STAPLS7190983296000001"
    ],
    [
        "STAPLS7192688739000001",
        "STAPLS7192688739000001"
    ],
    [
        "STAPLS7192688739000002",
        "STAPLS7192688739000002"
    ],
    [
        "STAPLS7194451156000001",
        "STAPLS7194451156000001"
    ],
    [
        "STAPLS7196089626000001",
        "STAPLS7196089626000001"
    ],
    [
        "STAPLS7196098610000001",
        "STAPLS7196098610000001"
    ],
    [
        "STAPLS7198829779000001",
        "STAPLS7198829779000001"
    ],
    [
        "STAPLS7199064256000001",
        "STAPLS7199064256000001"
    ],
    [
        "STAPLS7199462665000001",
        "STAPLS7199462665000001"
    ],
    [
        "STAPLS7199462665000002",
        "STAPLS7199462665000002"
    ],
    [
        "STAPLS7199462665000003",
        "STAPLS7199462665000003"
    ],
    [
        "STAPLS7199546655000001",
        "STAPLS7199546655000001"
    ],
    [
        "STAPLS7199927702000001",
        "STAPLS7199927702000001"
    ],
    [
        "STAPLS7200297763000001",
        "STAPLS7200297763000001"
    ],
    [
        "STAPLS7200297763000002",
        "STAPLS7200297763000002"
    ],
    [
        "STAPLS7200948926000001",
        "STAPLS7200948926000001"
    ],
    [
        "STAPLS7203073025000001",
        "STAPLS7203073025000001"
    ],
    [
        "STAPLS7203483467000001",
        "STAPLS7203483467000001"
    ],
    [
        "STAPLS7204148486000001",
        "STAPLS7204148486000001"
    ],
    [
        "STAPLS7205514591000001",
        "STAPLS7205514591000001"
    ],
    [
        "STAPLS7206692722000001",
        "STAPLS7206692722000001"
    ],
    [
        "STAPLS7206692722000002",
        "STAPLS7206692722000002"
    ],
    [
        "STAPLS7206902939000001",
        "STAPLS7206902939000001"
    ],
    [
        "STAPLS7207530049000001",
        "STAPLS7207530049000001"
    ],
    [
        "STAPLS7209476007000001",
        "STAPLS7209476007000001"
    ],
    [
        "STEFFEN GASTRO AG",
        "STEFFEN GASTRO"
    ],
    [
        "STEINER ELECTRIC *",
        "STEINER ELECTRIC"
    ],
    [
        "STEPHEN GOULD CORPORATION",
        "STEPHEN GOULD"
    ],
    [
        "STEPLIFT LTD",
        "STEPLIFT"
    ],
    [
        "STEPP EQUIPMENT COMPANY *",
        "STEPP EQUIPMENT"
    ],
    [
        "STEREOGRAPH MEDIA INC",
        "STEREOGRAPH MEDIA"
    ],
    [
        "STERIS LABORATORIES, INC.",
        "STERIS LABORATORIES"
    ],
    [
        "STERLING SCREEN PRINTING",
        "STERLING SCREEN PRINTING"
    ],
    [
        "STOCK CAP - ARNOLD",
        "STOCK CAP"
    ],
    [
        "STOCKDRIVE PRODUCTS",
        "STOCKDRIVE PRODUCTS"
    ],
    [
        "STONER INC",
        "STONER INC"
    ],
    [
        "STORAGE SYSTEMS",
        "STORAGE SYSTEMS"
    ],
    [
        "STORJOHANN GMBH",
        "STORJOHANN"
    ],
    [
        "STRATASYS INC",
        "STRATASYS"
    ],
    [
        "STRUB + CO. AG",
        "STRUB"
    ],
    [
        "STUCO AG",
        "STUCO"
    ],
    [
        "STUDENROTH PR‰ZISIONSTECHNIK",
        "STUDENROTH PRΣZISIONSTECHNIK"
    ],
    [
        "STUECKER",
        "STUECKER"
    ],
    [
        "SUBURBAN PROPANE, L.P.",
        "SUBURBAN PROPANE"
    ],
    [
        "SUDPACK MEDICA SAS",
        "SUDPACK MEDICA"
    ],
    [
        "SUEZ WTZ ANALYTICAL INSTRUMENTS INC",
        "SUEZ WATER TECHNOLOGIES"
    ],
    [
        "SUMMIT SALES & MARKETING",
        "SUMMIT SALES & MARKETING"
    ],
    [
        "SUPERFINE",
        "SUPERFINE"
    ],
    [
        "SUPERIOR INDUSTRIAL",
        "SUPERIOR INDUSTRIAL"
    ],
    [
        "SUPERMETAL",
        "SUPERMETAL"
    ],
    [
        "SURFACE PREP SUPPLY, INC.",
        "SURFACE PREP SUPPLY"
    ],
    [
        "SUSPA, INC",
        "SUSPA, INC"
    ],
    [
        "SUVA",
        "SUVA"
    ],
    [
        "SVS INDUSTRIAL SUPPLIES",
        "SVS INDUSTRIAL SUPPLIES"
    ],
    [
        "SWECO INC",
        "SWECO"
    ],
    [
        "SWISS-TECH, LLC",
        "SWISS TECH"
    ],
    [
        "SWISS WAAGEN DC GMBH",
        "SWISS WAAGEN DC"
    ],
    [
        "SYNERGETICS WEST",
        "SYNERGETICS"
    ],
    [
        "T.E LABS LTD.",
        "T E LABS"
    ],
    [
        "TAC INSUMO",
        "TAC INSUMO"
    ],
    [
        "TAM METAL PRODUCTS,INC",
        "TAM METAL PRODUCTS"
    ],
    [
        "TAMANET",
        "TAMANET"
    ],
    [
        "TE.I.AM TRAINING",
        "TE.I.AM TRAINING"
    ],
    [
        "TEAM INTERNATIONAL INC",
        "TEAM INTERNATIONAL INC"
    ],
    [
        "TECHNI TOOL",
        "TECHNI TOOL"
    ],
    [
        "TECHNI-TOOL",
        "TECHNI TOOL"
    ],
    [
        "TECHNI-TOOL INC",
        "TECHNI TOOL"
    ],
    [
        "TECHNI-TOOL INC.",
        "TECHNI TOOL"
    ],
    [
        "TECHNO SOLUTIONS WORLD, INC",
        "TECHNO SOLUTIONS WORLD, INC"
    ],
    [
        "TECHNOMAG AG",
        "TECHNOM AG"
    ],
    [
        "TECHNOMED INC",
        "TECHNOMED"
    ],
    [
        "TECHSIGN SARL",
        "TECHSIGN"
    ],
    [
        "TEGRA MEDICAL LLC - FRANK",
        "TEGRA MEDICAL"
    ],
    [
        "TEGRANT CORP",
        "TEGRANT"
    ],
    [
        "TERRA UNIVERSAL INC",
        "TERRA UNIVERSAL"
    ],
    [
        "TERRA UNIVERSAL.COM, INC. *",
        "TERRA UNIVERSAL"
    ],
    [
        "TEST EQUIPMENT DISTRIBUTORS LLC",
        "TEST EQUIPMENT DISTRIBUTORS"
    ],
    [
        "TESTO AG",
        "TESTO"
    ],
    [
        "TESTO SE &  CO. KGAA",
        "TESTO"
    ],
    [
        "THE BREWER COMPANY",
        "THE BREWER COMPANY"
    ],
    [
        "THE GARRYVOE HOTEL",
        "THE GARRYVOE HOTEL"
    ],
    [
        "THE HOME DEPOT #2771",
        "THE HOME DEPOT"
    ],
    [
        "THE HOME DEPOT 2728",
        "THE HOME DEPOT"
    ],
    [
        "THE SHERWIN-WILLIAMS CO.",
        "THE SHERWIN WILLIAMS"
    ],
    [
        "THE SIGN SHOP OF WESTERN MICHIGAN",
        "THE SIGN SHOP OF WESTERN MICHIGAN"
    ],
    [
        "THE SUPERIOR GROUP INC",
        "THE SUPERIOR GROUP"
    ],
    [
        "THE WEBSTAURANT STORE",
        "THE WEBSTAURANT STORE"
    ],
    [
        "THEILEN GMBH",
        "THEILEN"
    ],
    [
        "THERMAL-TECH SYSTEMS, INC",
        "THERMAL TECH SYSTEMS"
    ],
    [
        "THERMO-RAMSEY",
        "THERMO RAMSEY"
    ],
    [
        "THOMAS BERNSEE",
        "THOMAS BERNSEE"
    ],
    [
        "THOMI + CO. AG",
        "THOMI + CO. AG"
    ],
    [
        "THYSSENKRUPP (EURO)",
        "THYSSENKRUPP"
    ],
    [
        "TIGER VAC",
        "TIGER VAC"
    ],
    [
        "TIMCOD SUD-OUEST",
        "TIMCOD SUD OUEST"
    ],
    [
        "TIMOTHY BOOCHER",
        "TIMOTHY BOOCHER"
    ],
    [
        "TISSUE SEAL LLC",
        "TISSUE SEAL"
    ],
    [
        "TMMI, TECHNICAL MARKETING",
        "TMMI TECHNICAL MARKETING"
    ],
    [
        "TODD TITUS",
        "TODD TITUS"
    ],
    [
        "TOEL RECYCLING AG",
        "TOEL RECYCLING"
    ],
    [
        "TOOL KRIB SUPPLY CO",
        "TOOL KRIB SUPPLY"
    ],
    [
        "TOTAL PLASTICS RESOURCES",
        "TOTAL PLASTICS RESOURCES"
    ],
    [
        "TOTAL SOURCE LTD",
        "TOTAL SOURCE"
    ],
    [
        "TOTAL TEMPERATURE",
        "TOTAL TEMPERATURE"
    ],
    [
        "TOWN CLEANERS",
        "TOWN CLEANERS"
    ],
    [
        "TRAVERS TOOL CO INC",
        "TRAVERS TOOL"
    ],
    [
        "TREASURER STATE OF NJ",
        "TREASURER STATE OF NJ"
    ],
    [
        "TRELLEBORG SEALING SOLUTI",
        "TRELLEBORG SEALING SOLUTI"
    ],
    [
        "TRESTON DEUTSCHLAND GMBH",
        "TRESTON"
    ],
    [
        "TRI STATE BEARING",
        "TRI STATE BEARING"
    ],
    [
        "TRIAG AG",
        "TRIAG AG"
    ],
    [
        "TRIDENT SAFETY",
        "TRIDENT SAFETY"
    ],
    [
        "TRINITY GRAPHIC & PACKAGING SOLUTIONS, LLC",
        "TRINITY GRAPHIC & PACKAGING SOLUTIONS"
    ],
    [
        "TRYAD SOLUTIONS, INC.",
        "TRYAD SOLUTIONS"
    ],
    [
        "TWR SERVICE CORPORATION",
        "TWR SERVICE"
    ],
    [
        "TYLER SUPPLY COMPANY",
        "TYLER SUPPLY"
    ],
    [
        "U.S. PHARMACOPEIA",
        "U.S. PHARMACOPEIA"
    ],
    [
        "UDO BAR AND PARTNER AG",
        "UDO BAR AND PARTNER"
    ],
    [
        "ULTRADEX AG",
        "ULTRADEX"
    ],
    [
        "UMANO MEDICAL INC",
        "UMANO MEDICAL"
    ],
    [
        "UNISON CORPORATION",
        "UNISON"
    ],
    [
        "UNISOURCE, INC.",
        "UNISOURCE"
    ],
    [
        "UNITED BIOLOGICS INC",
        "UNITED BIOLOGICS"
    ],
    [
        "UNITED FOAM, INC.",
        "UNITED FOAM"
    ],
    [
        "UNITED STATES PLASTIC",
        "UNITED STATES PLASTIC"
    ],
    [
        "UNIVAC PRECISION",
        "UNIVAC PRECISION"
    ],
    [
        "UNIVERSAL FITNESS EQUIPMENT",
        "UNIVERSAL FITNESS EQUIPMENT"
    ],
    [
        "UNIVERSAL HOSPITAL",
        "UNIVERSAL HOSPITAL"
    ],
    [
        "UNIVERSAL PHOTONICS INC",
        "UNIVERSAL PHOTONICS"
    ],
    [
        "UNIVERSAL PUNCH CORP",
        "UNIVERSAL PUNCH CORP"
    ],
    [
        "USCONNECT K&SVN VEND S",
        "USCONNECT K& VEND S"
    ],
    [
        "USF HOLLAND,INC. *",
        "USF HOLLAND"
    ],
    [
        "VACUUM-DIRECT.COM",
        "VACUUM-DIRECT.COM"
    ],
    [
        "VANGUARD FIRE & RESCUE",
        "VANGUARD FIRE & RESCUE"
    ],
    [
        "VEITH GMBH & CO.KG",
        "VEITH"
    ],
    [
        "VERACITY MEDICAL SOLUTIONS",
        "VERACITY MEDICAL SOLUTIONS"
    ],
    [
        "VERDIER SARL",
        "VERDIER"
    ],
    [
        "VESCO OIL CORP.",
        "VESCO OIL CORP."
    ],
    [
        "VIKASS TOWERS SUPPLIER",
        "VIKASS TOWERS SUPPLIER"
    ],
    [
        "VINATORU ENTERPRICE INC",
        "VINATORU ENTERPRICE"
    ],
    [
        "VISION ID",
        "VISION"
    ],
    [
        "VISTAMED LIMITED - CARRIC",
        "VISTAMED"
    ],
    [
        "VISUAL WORKPLACE OF BY",
        "VISUAL WORKPLACE OF BY"
    ],
    [
        "VITREK CORPORATION",
        "VITREK"
    ],
    [
        "VIZACON GMBH",
        "VIZACON"
    ],
    [
        "VO-TECH, INC. *",
        "VO TECH"
    ],
    [
        "VOH SA",
        "VOH"
    ],
    [
        "VORNE INDUSTRIES",
        "VORNE INDUSTRIES"
    ],
    [
        "VOSS BELTING & SPECIALTY CO.",
        "VOSS BELTING & SPECIALTY"
    ],
    [
        "VTS VERBINDUNGSTECHNISCHER",
        "VTS VERBINDUNGSTECHNISCHER"
    ],
    [
        "VTT AG",
        "VTT AG"
    ],
    [
        "VWR FUNDING INC",
        "VWR FUNDING"
    ],
    [
        "VWR FUNDING INC - AP ONLY",
        "VWR FUNDING  AP ONLY"
    ],
    [
        "VWR SCIENTIFIC",
        "VWR SCIENTIFIC"
    ],
    [
        "W. SMITH CARTAGE CO.*",
        "W. SMITH CARTAGE CO."
    ],
    [
        "W.E. CARLSON CORP.*",
        "W.E. CARLSON CORP."
    ],
    [
        "WALMART HEALTH AND WELLNESS DIVISION",
        "WAL-MART"
    ],
    [
        "WAL-MART 5280",
        "WAL-MART"
    ],
    [
        "WAL-MART.COM",
        "WAL-MART"
    ],
    [
        "WALMART GAS",
        "WAL-MART"
    ],
    [
        "WALMART.COM 8009666546",
        "WAL-MART"
    ],
    [
        "WALMART.COM",
        "WALMART"
    ],
    [
        "WALTER ALARM SERVICE, INC *",
        "WALTER ALARM SERVICE"
    ],
    [
        "WALTER-CMP GMBH & CO. KG",
        "WALTER CMP"
    ],
    [
        "WANZL METALLWARENFABRIK GMBH",
        "WANZL METALLWARENFABRIK"
    ],
    [
        "WASATCH CONTAINER",
        "WASATCH CONTAINER"
    ],
    [
        "WASSERMANN DENTAL MASCH.",
        "WASSERMANN DENTAL MASCH"
    ],
    [
        "WAYNE TOOL & SUPPLY",
        "WAYNE TOOL & SUPPLY"
    ],
    [
        "WERMA SIGNALTECHNIK GMBH",
        "WERMA SIGNALTECHNIK"
    ],
    [
        "WESTCLIFFE INC.",
        "WESTCLIFFE"
    ],
    [
        "WESTERN CONTAINER CORPORATION",
        "WESTERN CONTAINER"
    ],
    [
        "WETROK AG",
        "WETROK"
    ],
    [
        "WHEELABRATOR GROUP GMBH",
        "WHEELABRATOR GROUP"
    ],
    [
        "WHEELABRATOR GROUP INC",
        "WHEELABRATOR GROUP"
    ],
    [
        "WHEELABRATOR GROUP",
        "WHEELABRATOR GROUP"
    ],
    [
        "WIESE-BEHRING GMBH",
        "WIESE BEHRING"
    ],
    [
        "WILMINGTON PRECISION MACHINING",
        "WILMINGTON PRECISION MACHINING"
    ],
    [
        "WINKA AG",
        "WINKA"
    ],
    [
        "WIZ 79 DESIGN, INC.",
        "WIZ 79 DESIGN"
    ],
    [
        "WKS ELECTRICAL CONTRACT",
        "WKS ELECTRICAL CONTRACT"
    ],
    [
        "WM BERG DIVISION",
        "WM BERG DIVISION"
    ],
    [
        "WM RECYCLE AMERICA",
        "WM RECYCLE"
    ],
    [
        "WORKFASHION.COM AG",
        "WORKFASHION COM"
    ],
    [
        "WUERTH GMBH",
        "WUERTH"
    ],
    [
        "XFINITY MOBILE",
        "XFINITY MOBILE"
    ],
    [
        "XPO LOGISTICS FREIGHT, INC.",
        "XPO LOGISTICS FREIGHT"
    ],
    [
        "YOUNGBLOOD AUTOMATION",
        "YOUNGBLOOD AUTOMATION"
    ],
    [
        "YOUNGBLOOD AUTOMATION LLC",
        "YOUNGBLOOD AUTOMATION"
    ],
    [
        "YRC *",
        "YRC"
    ],
    [
        "ZAGAR INC",
        "ZAGAR"
    ],
    [
        "ZB ZERSPANUNGSTECHNIK",
        "ZB ZERSPANUNGSTECHNIK"
    ],
    [
        "ZERO CHECK LLC",
        "ZERO CHECK"
    ],
    [
        "ZERO MANUFACTURING",
        "ZERO MANUFACTURING"
    ],
    [
        "ZETES AKA BLACKBIRD",
        "ZETES AKA BLACKBIRD"
    ],
    [
        "ZEUS INDUSTRIAL PRODUCTS",
        "ZEUS INDUSTRIAL PRODUCTS"
    ],
    [
        "ZIEGLER'S ACE HARDWARE",
        "ZIEGLER'S ACE HARDWARE"
    ],
    [
        "ZIGG DESIGN LLC",
        "ZIGG DESIGN"
    ],
    [
        "ZIMMER GMBH",
        "ZIMMER"
    ],
    [
        "ZIRCAR REFRACTORY COMPOSITES INC",
        "ZIRCAR REFRACTORY COMPOSITES"
    ],
    [
        "ZIRCOA INC",
        "ZIRCOA"
    ],
    [
        "ZVG ZELLSTOFF-VERARBEITUNG",
        "ZVG ZELLSTOFF VERARBEITUNG"
    ],
    [
        "ZZ_COMMANDE INTERNE",
        "ZZ COMMANDE INTERNE"
    ],
    [
        "ACTION TECHNOLOGY*",
        "ACTION TECHNOLOGY"
    ],
    [
        "ELEMENT MATERIALS TECHNOLOGY DALEVILLE LLC",
        "ELEMENT MATERIALS TECHNOLOGY DALEVILLE"
    ],
    [
        "FISHER SCIENTIFIC",
        "FISHER SCIENTIFIC"
    ],
    [
        "ITW TRANS TECH",
        "ITW TRANS TECH"
    ],
    [
        "JOHNSON CONTROLS INC",
        "JOHNSON CONTROLS"
    ],
    [
        "JOHNSON CONTROLS SECURITY SOLUTIONS LLC",
        "JOHNSON CONTROLS"
    ],
    [
        "JOHNSON CONTROLS IRL LTD",
        "JOHNSON CONTROLS"
    ],
    [
        "JOHNSON MATTHEY",
        "JOHNSON MATTHEY"
    ],
    [
        "JOHNSON MATTHEY INC.",
        "JOHNSON MATTHEY"
    ],
    [
        "KNOCKROE FABRICATION LTD",
        "KNOCKROE FABRICATION"
    ],
    [
        "LANCASTER LABORATORIES",
        "LANCASTER LABORATORIES"
    ],
    [
        "LEHMANN U. VOSS U. CO.",
        "LEHMANN U VOSS U"
    ],
    [
        "METTLER-TOLEDO SAFELINE",
        "METTLER-TOLEDO"
    ],
    [
        "METTLER-TOLEDO THORNTON",
        "METTLER-TOLEDO"
    ],
    [
        "MG SCIENTIFIC *",
        "MG SCIENTIFIC"
    ],
    [
        "MICROCHEM CORP",
        "MICROCHEM"
    ],
    [
        "NSL ANALYTICAL SERVICES",
        "NSL ANALYTICAL SERVICES"
    ],
    [
        "PLASTIFIL",
        "PLASTIFIL"
    ],
    [
        "SISKIYOU CORPORATION",
        "SISKIYOU"
    ],
    [
        "THERMO ELECTRON NORTH",
        "THERMO ELECTRON NORTH"
    ],
    [
        "TYCO INTEGRATED SECURITY LLC",
        "TYCO INTEGRATED SECURITY"
    ],
    [
        "VWR ADVANCED INSTRUMENTS",
        "VWR INTERNATIONAL"
    ],
    [
        "VWR INTERNATIONAL INC",
        "VWR INTERNATIONAL"
    ],
    [
        "VWR INTERNATIONAL LIMITED",
        "VWR INTERNATIONAL"
    ],
    [
        "VWR INTERNATIONAL - AUROR",
        "VWR INTERNATIONAL"
    ],
    [
        "VWR INTERNATIONAL GMBH",
        "VWR INTERNATIONAL"
    ],
    [
        "VWR INTERNATIONAL LLC *",
        "VWR INTERNATIONAL"
    ],
    [
        "VWR INTERNATIONAL LTD",
        "VWR INTERNATIONAL"
    ],
    [
        "VWR INTERNATIONAL, INC.",
        "VWR INTERNATIONAL"
    ],
    [
        "W&H DENTALWERK BÜRMOOS GMBH",
        "W&H GROUP"
    ],
    [
        "HG - TECHNIK GYGI",
        "HG TECHNIK GYGI"
    ],
    [
        "MENSING GMBH &  CO.KG",
        "MENSING"
    ],
    [
        "MESSER RÄUMTECHNIK GMBH & CO",
        "MESSER RAUMTECHNIK"
    ],
    [
        "GEORG UTZ",
        "UTZ GROUP"
    ],
    [
        "A PLUS INTERNATIONAL INC",
        "A PLUS INTERNATIONAL"
    ],
    [
        "BLUEMED MEDICAL SUPPLIES INC",
        "BLUEMED MEDICAL SUPPLIES"
    ],
    [
        "MAGID GLOVE & SAFETY MFG. *",
        "MAGID GLOVE & SAFETY MFG"
    ],
    [
        "SAFE WORKPLACE INC",
        "SAFE WORKPLACE"
    ],
    [
        "SHOE INN LLC",
        "SHOE INN LLC"
    ],
    [
        "TAC INSUMOS INDUSTRIALES S DE RL DE CV",
        "TAC INSUMOS INDUSTRIALES"
    ],
    [
        "UNITECH SERVICES GROUP INC",
        "UNITECH SERVICES GROUP"
    ],
    [
        "DELTA INDUSTRIES *",
        "DELTA INDUSTRIES"
    ],
    [
        "ARAMARK CLEANROOM SERVICES",
        "ARAMARK"
    ],
    [
        "BECKS SHOES INC",
        "BECKS SHOES"
    ],
    [
        "MAQTEX",
        "MAQTEX"
    ],
    [
        "MAQTEX SA DE CV",
        "MAQTEX"
    ],
    [
        "UNIFIRST CORP",
        "UNIFIRST"
    ],
    [
        "UNIFIRST CORPORATION",
        "UNIFIRST"
    ],
    [
        "VARTEST LABORATORIES",
        "VARTEST LABORATORIES"
    ],
    [
        "WERK5 AG",
        "WERK5 AG"
    ],
    [
        "WORK WEAR SAFETY SHOES",
        "WORK WEAR SAFETY SHOES"
    ],
    [
        "WULFF TEXTILSERVICE",
        "WULFF TEXTILSERVICE"
    ],
    [
        "NORTRAIL TRANSPORT GMBH",
        "NORTRAIL TRANSPORT"
    ],
    [
        "ZOLLAGENTUR SCHNITZLER GMBH",
        "ZOLLAGENTUR SCHNITZLER"
    ],
    [
        "AALBERTS SURFACE TREATMENT",
        "AALBERTS SURFACE TREATMENT"
    ],
    [
        "AGENCIA ADUANAL JORGE DIAZ SC",
        "AGENCIA ADUANAL JORGE DIAZ"
    ],
    [
        "AGENCIA ADUANAL JORGE DIAZ, S.C.",
        "AGENCIA ADUANAL JORGE DIAZ"
    ],
    [
        "AM PM LOGISTIC INC",
        "AM PM LOGISTIC"
    ],
    [
        "ANDERSEN & ASSOC. INC",
        "ANDERSEN & ASSOC"
    ],
    [
        "AP&C ADVANCED POWDERS",
        "AP&C ADVANCED POWDERS"
    ],
    [
        "APS MATERIALS INC.,",
        "APS MATERIALS"
    ],
    [
        "ARCAM AB",
        "ARCAM"
    ],
    [
        "ASTIER MARIE",
        "ASTIER MARIE"
    ],
    [
        "AXELENT GMBH",
        "AXELENT"
    ],
    [
        "BAILEYS MOVING & STORAGE",
        "BAILEYS MOVING & STORAGE"
    ],
    [
        "BEUTH VERLAG GMBH",
        "BEUTH VERLAG"
    ],
    [
        "BGS BETA-GAMMA-SERVICE",
        "BGS BETA GAMMA SERVICE"
    ],
    [
        "BODYCOTE HARDIFF B.V",
        "BODYCOTE"
    ],
    [
        "BONATRANS",
        "BONATRANS"
    ],
    [
        "BYOK GMBH",
        "BYOK"
    ],
    [
        "CADS GMBH",
        "CADS"
    ],
    [
        "CASS INFORMATION SYSTEMS",
        "CASS INFORMATION SYSTEMS"
    ],
    [
        "CASS INFORMATION SYSTEMS INC",
        "CASS INFORMATION SYSTEMS"
    ],
    [
        "CHARPIOT",
        "CHARPIOT"
    ],
    [
        "CIBLEX EXPRESS",
        "CIBLEX EXPRESS"
    ],
    [
        "CITIZEN MACHINERY UK LTD",
        "CITIZEN MACHINERY"
    ],
    [
        "COMETRA",
        "COMETRA"
    ],
    [
        "COMPLETE STORAGE SOLUTIONS LTD",
        "COMPLETE STORAGE SOLUTIONS"
    ],
    [
        "CUNNANES FORK LIFT HIRE",
        "CUNNANES FORK LIFT HIRE"
    ],
    [
        "DACHSER AG",
        "DACHSER"
    ],
    [
        "DB SCHENKER",
        "DB SCHENKER"
    ],
    [
        "DEUTSCHE POST AG",
        "DEUTSCHE POST"
    ],
    [
        "DHL EXPRESS",
        "DHL EXPRESS"
    ],
    [
        "DHL LOGISTICS SCHWEIZ SA",
        "DHL EXPRESS"
    ],
    [
        "DHL EXPRESS GERMANY GMBH",
        "DHL EXPRESS"
    ],
    [
        "DHL EXPRESS SAS",
        "DHL EXPRESS"
    ],
    [
        "DHL-VOIGT INTERNATIONAL GMBH",
        "DHL EXPRESS"
    ],
    [
        "DHL GLOBAL FORWARDING",
        "DHL EXPRESS"
    ],
    [
        "DHL EXPRESS IRELAND",
        "DHL EXPRESS"
    ],
    [
        "DHL EXPRESS IRELAND LTD",
        "DHL EXPRESS"
    ],
    [
        "DHL FREIGHT",
        "DHL EXPRESS"
    ],
    [
        "DIETMAR ROGGE TRANSPORTE",
        "DIETMAR ROGGE TRANSPORTE"
    ],
    [
        "DMH MOEBELTRANSP.GMBH+CO.KG",
        "DMH MOEBELTRANSP"
    ],
    [
        "DPD SCHWEIZ AG",
        "DPD"
    ],
    [
        "DSV SPEDITIONSLOGISTIK AG",
        "DSV SPEDITIONSLOGISTIK"
    ],
    [
        "EASTWAY GLOBAL FORWARDING",
        "EASTWAY GLOBAL FORWARDING"
    ],
    [
        "EASTWAY GLOBAL FORWARDING LTD",
        "EASTWAY GLOBAL FORWARDING"
    ],
    [
        "EASTWAY LOGISTICS LIMITED",
        "EASTWAY GLOBAL FORWARDING"
    ],
    [
        "ELPRO MESSTECHNIK GMBH",
        "ELPRO MESSTECHNIK"
    ],
    [
        "ERGOTRADING GMBH",
        "ERGOTRADING"
    ],
    [
        "EXPEDITORS INTERNATIONAL FR",
        "EXPEDITORS"
    ],
    [
        "EXPEDITORS",
        "EXPEDITORS"
    ],
    [
        "EXPEDITORS INTERNATIONAL DE MEXICO, S.A. DE C.V. (NOGALES)",
        "EXPEDITORS"
    ],
    [
        "EXPEDITORS INTERNATIONAL OF WASHINGTON INC.",
        "EXPEDITORS"
    ],
    [
        "EXPEDITORS IRELAND LTD",
        "EXPEDITORS"
    ],
    [
        "EXPEDITORS INTERNATIONAL",
        "EXPEDITORS"
    ],
    [
        "EXPEDITORS INTL",
        "EXPEDITORS"
    ],
    [
        "EXPEDITORS INTL/CHICAGO *",
        "EXPEDITORS"
    ],
    [
        "EXPEDITORS SEA SKY",
        "EXPEDITORS"
    ],
    [
        "EXPEDITORS TRADEWIN LLC",
        "EXPEDITORS"
    ],
    [
        "TR EXPRESS SARL",
        "EXPRESS"
    ],
    [
        "FACHSPEDITION KARL DISCHINGER GMBH",
        "FACHSPEDITION KARL DISCHINGER"
    ],
    [
        "FEDEX",
        "FED EX"
    ],
    [
        "FEDERAL EXPRESS EUROPE INC.",
        "FED EX"
    ],
    [
        "FEDEX EXPRESS INTERNATIONAL",
        "FED EX"
    ],
    [
        "FEDERAL EXPRESS",
        "FED EX"
    ],
    [
        "FED EX",
        "FED EX"
    ],
    [
        "FED EX FREIGHT",
        "FED EX"
    ],
    [
        "FEDERAL EXPRESS CORP",
        "FED EX"
    ],
    [
        "FEDERAL EXPRESS CORP.",
        "FED EX"
    ],
    [
        "FEDERAL EXPRESS EUROPE IN",
        "FED EX"
    ],
    [
        "FEDERAL EXPRESS EUROPE INC",
        "FED EX"
    ],
    [
        "FEDEX  GROUND POS",
        "FED EX"
    ],
    [
        "FEDEX EXPRESS POS",
        "FED EX"
    ],
    [
        "FEDEX FREIGHT",
        "FED EX"
    ],
    [
        "FEDEX OFFICE #0474",
        "FED EX"
    ],
    [
        "FEDEX POS NON DISCOUNTABL",
        "FED EX"
    ],
    [
        "FEDEX SUPPLY CHAIN INC",
        "FED EX"
    ],
    [
        "FEDEX TRADE NETWORKS CAN INC.",
        "FED EX"
    ],
    [
        "FORT WAYNE METALS",
        "FORT WAYNE METALS"
    ],
    [
        "FOX FLOWERS",
        "FOX FLOWERS"
    ],
    [
        "FRANCOTYP POSTALIA VS GMBH",
        "FRANCOTYP POSTALIA VS"
    ],
    [
        "FRIMA-PAC AG",
        "FRIMA PAC"
    ],
    [
        "FRUCHTL KRONOS GMBH & CO",
        "FRUCHTL KRONOS"
    ],
    [
        "GBE - GROUPE TRANSALLIANCE",
        "GBE GROUPE TRANSALLIANCE"
    ],
    [
        "GE INSPECTION TECHNOLOGIES",
        "GE INSPECTION TECHNOLOGIES"
    ],
    [
        "GO",
        "GO"
    ],
    [
        "H.B. ELECTRONICS LTD",
        "H B ELECTRONICS"
    ],
    [
        "HAENEL GMBH & CO. KG",
        "HAENEL"
    ],
    [
        "HAMILTON HOUSE",
        "HAMILTON HOUSE"
    ],
    [
        "HANS WEBER",
        "HANS WEBER"
    ],
    [
        "HARDMETAL TECH LTD",
        "HARDMETAL TECH"
    ],
    [
        "HASENAUER + KOCH,",
        "HASENAUER KOCH"
    ],
    [
        "HD LOGISTICS LLC",
        "HD LOGISTICS"
    ],
    [
        "HENRIOT SAS",
        "HENRIOT"
    ],
    [
        "HEXAGON METROLOGY GMBH",
        "HEXAGON METROLOGY"
    ],
    [
        "HID GLOBAL",
        "HID GLOBAL"
    ],
    [
        "HS LUFTFILTERBAU GMBH",
        "HS LUFTFILTERBAU"
    ],
    [
        "HUB GROUP INC",
        "HUB GROUP"
    ],
    [
        "HUETHIG GMBH",
        "HUETHIG"
    ],
    [
        "HUSS-VERLAG GMBH",
        "HUSS VERLAG"
    ],
    [
        "ID TECHNOLOGY LIMITED,",
        "ID TECHNOLOGY"
    ],
    [
        "IMSTEC GMBH",
        "IMSTEC"
    ],
    [
        "INVIBIO LTD",
        "INVIBIO"
    ],
    [
        "IRISH EXPRESS CARGO",
        "IRISH EXPRESS CARGO"
    ],
    [
        "IRISH EXPRESS CARGO LTD",
        "IRISH EXPRESS CARGO"
    ],
    [
        "ISS IND.& SCIENT. SERVICE",
        "ISS & SCIENT SERVICE"
    ],
    [
        "IWA GMBH",
        "IWA"
    ],
    [
        "JAMES CULLINANE",
        "JAMES CULLINANE"
    ],
    [
        "JEANMAIRE SA",
        "JEANMAIRE"
    ],
    [
        "JUNGHEINRICH LIFT TRUCK",
        "JUNGHEINRICH"
    ],
    [
        "JUNGHEINRICH AG",
        "JUNGHEINRICH"
    ],
    [
        "KALFANY SÜSSE WERBUNG GMBH & CO. KG",
        "KALFANY SÜSSE WERBUNG"
    ],
    [
        "KENCO LOGISTIC SERVICES",
        "KENCO LOGISTIC SERVICES"
    ],
    [
        "KENCO LOGISTIC SERVICES LLC",
        "KENCO LOGISTIC SERVICES"
    ],
    [
        "KENCO LOGISTICS SERVICES LLC",
        "KENCO LOGISTICS SERVICES"
    ],
    [
        "KENCO LOGISTICS SVCS LLC",
        "KENCO LOGISTICS SVCS"
    ],
    [
        "KROSCHKE SIGN-INTERNATIONAL GMBH",
        "KROSCHKE SIGN INTERNATIONAL"
    ],
    [
        "KUEHNE & HAGEL IRELAND LIMITED",
        "KUEHNE & HAGEL"
    ],
    [
        "KUEHNE & NAGEL",
        "KUEHNE & NAGEL"
    ],
    [
        "KUEHNE & NAGLE(IRE) LTD",
        "KUEHNE & NAGLE"
    ],
    [
        "KUEHNE +  NAGEL AG & CO. KG",
        "KUEHNE NAGEL"
    ],
    [
        "KÖGEL WERKSTOFF UND",
        "KÖGEL WERKSTOFF UND"
    ],
    [
        "KÜHNE + NAGEL AG",
        "KÜHNE NAGEL"
    ],
    [
        "LABELPACK AG",
        "LABELPACK"
    ],
    [
        "LABQUIP IRELAND LTD",
        "LABQUIP"
    ],
    [
        "LDS EUROPE GMBH",
        "LDS"
    ],
    [
        "LILA GMBH",
        "LILA"
    ],
    [
        "LOGISTICS IT LIMITED",
        "LOGISTICS IT LIMITED"
    ],
    [
        "M.A. JULIA & DR.MATTHIAS",
        "M A JULIA & DR MATTHIAS"
    ],
    [
        "MAIER SPEDITION GMBH",
        "MAIER SPEDITION"
    ],
    [
        "MAIER WERKZEUGMASCHINEN",
        "MAIER WERKZEUGMASCHINEN"
    ],
    [
        "MARK IV TRANSPORTATION",
        "MARK IV TRANSPORTATION"
    ],
    [
        "MATERIALPRÜFUNGSAMT NORDRHEIN-",
        "MATERIALPRÜFUNGSAMT NORDRHEIN"
    ],
    [
        "MATERION IRELAND LTD",
        "MATERION"
    ],
    [
        "MEIEREXPRESS SARL",
        "MEIEREXPRESS"
    ],
    [
        "MEIJER  PORTAGEMI  #022",
        "MEIJER PORTAGEMI 022"
    ],
    [
        "METLOG DEUTSCHLAND GMBH",
        "METLOG"
    ],
    [
        "METPREP LIMITED",
        "METPREP"
    ],
    [
        "MINEBEA INTEC BOVENDEN GMBH & CO.KG",
        "MINEBEA INTEC BOVENDEN"
    ],
    [
        "MMC HARTMETALL GMBH",
        "MMC HARTMETALL"
    ],
    [
        "NAUTA SA",
        "NAUTA"
    ],
    [
        "NUTECH GMBH",
        "NUTECH"
    ],
    [
        "OBERNDORFER PRÄZISIONS-WERK GMBH&CO",
        "OBERNDORFER PRÄZISIONS WERK"
    ],
    [
        "OLECO INC",
        "OLECO"
    ],
    [
        "OUTSOURCE TECHNICAL CON.",
        "OUTSOURCE TECH CONCEPTS"
    ],
    [
        "PENSKE TRUCK LEASING CO., L.P.",
        "PENSKE TRUCK LEASING CO."
    ],
    [
        "PITNEY BOWES PUERTO RICO",
        "PITNEY BOWES"
    ],
    [
        "PLYMOVENT GMBH",
        "PLYMOVENT"
    ],
    [
        "UNITED STATES POSTAL SERV",
        "POSTAL SERV"
    ],
    [
        "LA POSTE SUISSE",
        "POSTE"
    ],
    [
        "LA POSTE",
        "POSTE"
    ],
    [
        "PRECISION METAL PRODUCTS",
        "PRECISION METAL PRODUCTS"
    ],
    [
        "PREDATOR SOFTWARE INC",
        "PREDATOR SOFTWARE"
    ],
    [
        "PREMIERE CONFERENCING LTD.",
        "PREMIERE CONFERENCING"
    ],
    [
        "PRESSURE TECHNOLOGY INC",
        "PRESSURE TECHNOLOGY"
    ],
    [
        "PROTOLABS LTD",
        "PROTO LABS"
    ],
    [
        "QUINTENZ HANDELSGESELLSCHAFT",
        "QUINTENZ HANDELSGESELLSCHAFT"
    ],
    [
        "RADECK S KURIERSERVICE",
        "RADECK S KURIERSERVICE"
    ],
    [
        "RADIANT GLOBAL LOGISTICS INC",
        "RADIANT GLOBAL LOGISTICS"
    ],
    [
        "RAPIDES SAVOYARDS SARL",
        "RAPIDES SAVOYARDS"
    ],
    [
        "ROBBINSON",
        "ROBBINSON"
    ],
    [
        "CH ROBINSON COMPANY",
        "ROBINSON"
    ],
    [
        "CH ROBINSON COMPANY,INC. *",
        "ROBINSON"
    ],
    [
        "CH ROBINSON WORLDWIDE INC",
        "ROBINSON"
    ],
    [
        "SCHENKER SCHWEIZ AG",
        "SCHENKER"
    ],
    [
        "SCHENKER DEUTSCHLAND AG  NL COBURG",
        "SCHENKER COBURG"
    ],
    [
        "SCHNEIDER TRANSPORTS SA",
        "SCHNEIDER TRANSPORTS"
    ],
    [
        "SEDIMEX EVA SEDLAKOVA",
        "SEDIMEX EVA SEDLAKOVA"
    ],
    [
        "SEMAQ",
        "SEMAQ"
    ],
    [
        "SFM MEDICAL DEVICES GMBH",
        "SFM MEDICAL DEVICES"
    ],
    [
        "SHANNON COILED SPRINGS LT",
        "SHANNON COILED SPRINGS"
    ],
    [
        "SIEVGEN LTD",
        "SIEVGEN"
    ],
    [
        "SIGMA ALDRICH",
        "SIGMA ALDRICH"
    ],
    [
        "SINTAVIA LLC",
        "SINTAVIA"
    ],
    [
        "SIXT GMBH & CO.",
        "SIXT"
    ],
    [
        "SOMATRA SA",
        "SOMATRA"
    ],
    [
        "SPEDITION DIEZ GMBH",
        "SPEDITION DIEZ"
    ],
    [
        "SPRACHENSERVICE NORD",
        "SPRACHENSERVICE NORD"
    ],
    [
        "STRECK TRANSPORT AG",
        "STRECK TRANSPORT"
    ],
    [
        "STRUERS LTD",
        "STRUERS"
    ],
    [
        "STUDENROTH PRΣZISIONSTECHNIK",
        "STUDENROTH PRΣZISIONSTECHNIK"
    ],
    [
        "SWISSCONNECT AG",
        "SWISSCONNECT"
    ],
    [
        "TELEKOM DEUTSCHLAND GMBH",
        "TELEKOM"
    ],
    [
        "TESTO SE & CO.KGAA",
        "TESTO"
    ],
    [
        "THOR LABS LTD",
        "THOR LABS"
    ],
    [
        "TNT-EXPRESS GMBH",
        "TNT EXPRESS"
    ],
    [
        "TNT EXPRESS INTERNATIONAL",
        "TNT EXPRESS"
    ],
    [
        "TNT NATIONAL",
        "TNT EXPRESS"
    ],
    [
        "TRUTECH SOLUTIONS",
        "TRUTECH SOLUTIONS"
    ],
    [
        "UNITED PARCEL SERVICE",
        "UNITED PARCEL SERVICE"
    ],
    [
        "UNITED PARTY AND EVENT SERVICE",
        "UNITED PARTY AND EVENT SERVICE"
    ],
    [
        "UPS SUPPLY CHAIN SOLUT.INC.",
        "UPS"
    ],
    [
        "UPS SWITZERLAND",
        "UPS"
    ],
    [
        "UPS FRANCE SNC",
        "UPS"
    ],
    [
        "UPS FREIGHT",
        "UPS"
    ],
    [
        "UPS SUPPLY CHAIN",
        "UPS"
    ],
    [
        "UPS SCS",
        "UPS"
    ],
    [
        "USPS PO 2576500035",
        "USPS PO"
    ],
    [
        "VERITIV OPERATING COMPANY",
        "VERITIV"
    ],
    [
        "VERITIV CORPORATION",
        "VERITIV"
    ],
    [
        "VERITIV",
        "VERITIV"
    ],
    [
        "VERITIV OPERATING",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL000",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL001",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL002",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL004",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL005",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL006",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL008",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL010",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL012",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL015",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL016",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL020",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL021",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL022",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL023",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL024",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL025",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL026",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL027",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL028",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL032",
        "VERITIV"
    ],
    [
        "VERITIV OPERATREL038",
        "VERITIV"
    ],
    [
        "VERLAG FⁿR DIE DEUTSCHE WIRT",
        "VERLAG FⁿR DIE DEUTSCHE WIRT"
    ],
    [
        "VON BERGEN",
        "VON BERGEN"
    ],
    [
        "WEILER WERKZEUGMASCHINEN",
        "WEILER WERKZEUGMASCHINEN"
    ],
    [
        "XYLEM ANALYTICS GERMANY",
        "XYLEM ANALYTICS"
    ],
    [
        "ZAPP PRECISION METALS GMBH",
        "ZAPP PRECISION METALS"
    ],
    [
        "AIRFREIGHT SECURITY ASSOC",
        "AIRFREIGHT SECURITY ASSOC"
    ],
    [
        "CASS",
        "CASS"
    ],
    [
        "FREIGHT TRAIN LTD",
        "FREIGHT TRAIN"
    ],
    [
        "HOT-LINE FREIGHT SYSTEM, INC.",
        "HOT LINE FREIGHT SYSTEM"
    ],
    [
        "J P PATTI TECTA AMERICA LLC",
        "J P PATTI TECTA"
    ],
    [
        "KURIERDIENST PETER SIARA E.K.",
        "KURIERDIENST PETER SIARA E K"
    ],
    [
        "KURIERDIENST THOMAS KUHN",
        "KURIERDIENST THOMAS KUHN"
    ],
    [
        "NATIONAL AIR FILTER SERVICE",
        "NATIONAL AIR FILTER SERVICE"
    ],
    [
        "PRENT THERMOFORMING",
        "PRENT THERMOFORMING"
    ],
    [
        "UPS",
        "UPS"
    ],
    [
        "UPS LIMITED",
        "UPS"
    ],
    [
        "K.R. MONTAGEN & KLEINTRANSPORTE",
        "K R MONTAGEN & KLEINTRANSPORTE"
    ],
    [
        "KRUG TRANSPORTE GMBH&CO.KG",
        "KRUG TRANSPORTE"
    ],
    [
        "2R COULEUR",
        "2R COULEUR"
    ],
    [
        "3D MEDICAL MANUFACTURING",
        "3D MEDICAL MANUFACTURING"
    ],
    [
        "3D MICROPRINT GMBH",
        "3D MICROPRINT"
    ],
    [
        "3D SYSTEMS DBA QUICKPARTS",
        "3D SYSTEMS"
    ],
    [
        "A & M ANDREAS FEY",
        "A & M ANDREAS FEY"
    ],
    [
        "A.M. METAL FINISHING INC",
        "A M METAL FINISHING"
    ],
    [
        "ABLE ELECTRO-POLISHING CO",
        "ABLE ELECTROPOLISHING"
    ],
    [
        "ACNIS INTERNATIONAL",
        "ACNIS INTERNATIONAL"
    ],
    [
        "ADDY MACHINERY CO.",
        "ADDY MACHINERY"
    ],
    [
        "ADOLF WÜRTH GMBH&CO.KG",
        "ADOLF WÜRTH"
    ],
    [
        "ADVANCED ASSEMBLY, LLC",
        "ADVANCED ASSEMBLY"
    ],
    [
        "ADVANCED FLUID SYSTEMS",
        "ADVANCED FLUID SYSTEMS"
    ],
    [
        "ADVANCED TEST EQUIPMENT",
        "ADVANCED TEST EQUIPMENT"
    ],
    [
        "ADVANCED TOOL AND SUPPLY",
        "ADVANCED TOOL AND SUPPLY"
    ],
    [
        "AEC INC",
        "AEC"
    ],
    [
        "AERONET *",
        "AERONET"
    ],
    [
        "AHB TOOLING & MACHINERY",
        "AHB TOOLING & MACHINERY"
    ],
    [
        "AID EQUIPMENT LLC",
        "AID EQUIPMENT"
    ],
    [
        "AIR TECHNOLOGIES",
        "AIR TECHNOLOGIES"
    ],
    [
        "AIRPOT CORPORATION",
        "AIRPOT"
    ],
    [
        "AIRTECH VACUUM",
        "AIRTECH VACUUM"
    ],
    [
        "AIT WORLDWIDE LOGISTICS, INC. *",
        "AIT WORLDWIDE LOGISTICS"
    ],
    [
        "ALBANY DOOR SYSTEMS GMBH",
        "ALBANY DOOR SYSTEMS"
    ],
    [
        "ALFRED KONRAD VEITH GMBH",
        "ALFRED KONRAD VEITH"
    ],
    [
        "ALL FILL INT. LTD.,",
        "ALL FILL INT"
    ],
    [
        "ALL-FORMING GMBH",
        "ALL FORMING"
    ],
    [
        "ALL INCLUSIVE",
        "ALL INCLUSIVE"
    ],
    [
        "ALLENDALE MACHINERY SYSTEMS",
        "ALLENDALE MACHINERY SYSTEMS"
    ],
    [
        "ALLOSOURCE",
        "ALLOSOURCE"
    ],
    [
        "ALPHA METAL FINISHING CO.",
        "ALPHA METAL FINISHING"
    ],
    [
        "ALPINE ART",
        "ALPINE ART"
    ],
    [
        "ALTHAUS VERBINDUNGSTECHNIK",
        "ALTHAUS VERBINDUNGSTECHNIK"
    ],
    [
        "ALUMILITE CORPORATION",
        "ALUMILITE"
    ],
    [
        "AMATECH CORPORATION",
        "AMATECH"
    ],
    [
        "AMERICAN HOSE & HYDRAULICS",
        "AMERICAN HOSE & HYDRAULICS"
    ],
    [
        "AMERICAN INT'L *",
        "AMERICAN INTERNATIONAL"
    ],
    [
        "AMERICAN LAUBSCHER CORP D/B/A PRECIPART",
        "AMERICAN LAUBSCHER D B A PRECIPART"
    ],
    [
        "AMERICAN TOOL & MFG CORP",
        "AMERICAN TOOL & MFG"
    ],
    [
        "AMERITHERM INC",
        "AMERITHERM"
    ],
    [
        "AMETEK TECHNICAL &",
        "AMETEK TECHNICAL"
    ],
    [
        "AMF SUPPORT SURFACES",
        "AMF SUPPORT SURFACES"
    ],
    [
        "AN POST",
        "AN POST"
    ],
    [
        "ANHOLT TECH",
        "ANHOLT"
    ],
    [
        "ANODIZING & METAL COATING",
        "ANODIZING & METAL COATING"
    ],
    [
        "ANTRON ENGINEERING",
        "ANTRON ENGINEERING"
    ],
    [
        "APFEL GMBH",
        "APFEL"
    ],
    [
        "ARMIN HAMMA UMWELTTECHNIK",
        "ARMIN HAMMA UMWELTTECHNIK"
    ],
    [
        "ARNOLD SCHRÖDER INDUSTRIEÖFEN GMBH",
        "ARNOLD SCHRÖDER INDUSTRIEÖFEN"
    ],
    [
        "ARROW ELECTRONICS, INC.",
        "ARROW ELECTRONICS"
    ],
    [
        "ARTEX INC",
        "ARTEX"
    ],
    [
        "ASSOCIATED SALES AND BAG",
        "ASSOCIATED SALES AND BAG"
    ],
    [
        "ATI INDUSTRIAL AUTOMATION",
        "ATI INDUSTRIAL AUTOMATION"
    ],
    [
        "ATL TECHNOLOGY USA",
        "ATL TECHNOLOGY"
    ],
    [
        "ATLANTIC ADVANCED SAFETY",
        "ATLANTIC ADVANCED SAFETY"
    ],
    [
        "ATM TRANSPORT SA",
        "ATM TRANSPORT"
    ],
    [
        "ATM",
        "ATM TRANSPORT"
    ],
    [
        "AUTOMATION CONTROL",
        "AUTOMATION CONTROL"
    ],
    [
        "AUTOMATION TECHNOLOGY SYMMETRIES",
        "AUTOMATION TECHNOLOGY SYMMETRIES"
    ],
    [
        "B&O BÜRO UND OBJEKT GMBH",
        "B&O BÜRO UND OBJEKT"
    ],
    [
        "BANDELIN ELECTRONIC GMBH & CO.KG",
        "BANDELIN ELECTRONIC"
    ],
    [
        "BARCODE SOLUTIONS LTD",
        "BARCODE SOLUTIONS"
    ],
    [
        "BAUMGARTEN GMBH",
        "BAUMGARTEN"
    ],
    [
        "BAY VIEW INDUSTRIES INC",
        "BAY VIEW INDUSTRIES"
    ],
    [
        "BEAHM DESIGNS INC",
        "BEAHM DESIGNS"
    ],
    [
        "BERCHTOLD GMBH & CO. KG",
        "BERCHTOLD"
    ],
    [
        "BERKEL AHK ALKOHOLHANDEL GMBH & CO.",
        "BERKEL AHK ALKOHOLHANDEL"
    ],
    [
        "BERND KÖSSLER",
        "BERND KÖSSLER"
    ],
    [
        "BERND OLSCHNER GMBH",
        "BERND OLSCHNER"
    ],
    [
        "BERNICIA",
        "BERNICIA"
    ],
    [
        "BIEN-AIR SURGERY SA",
        "BIEN AIR SURGERY"
    ],
    [
        "BIESTERFELD",
        "BIESTERFELD"
    ],
    [
        "BIESTERFELD FRANCE SARL",
        "BIESTERFELD"
    ],
    [
        "BIG KAISER PRÄZISIONSWERK-",
        "BIG KAISER PRÄZISIONSWERK"
    ],
    [
        "BILZ VIBRATION TECHNOLOGY AG",
        "BILZ VIBRATION TECHNOLOGY"
    ],
    [
        "BIMU S.A.",
        "BIMU S A"
    ],
    [
        "BIOMEDIS LABORSERVICE GMBH",
        "BIOMEDIS LABORSERVICE"
    ],
    [
        "BIOSERV ANALYTIK",
        "BIOSERV ANALYTIK"
    ],
    [
        "BITO-LAGERTECHNIK",
        "BITO LAGERTECHNIK"
    ],
    [
        "BJZ GMBH & CO. KG",
        "BJZ"
    ],
    [
        "BKW  WÄRME UND VERSORGUNGSTECHNIK",
        "BKW WÄRME UND VERSORGUNGSTECHNIK"
    ],
    [
        "BLACKSTONE NYE UNTRASONIC",
        "BLACKSTONE NYE UNTRASONIC"
    ],
    [
        "BODYCOTE HARDINGSCENTRUM BV",
        "BODYCOTE"
    ],
    [
        "BODYCOTE",
        "BODYCOTE"
    ],
    [
        "BOEDEKER PLASTICS INC.",
        "BOEDEKER PLASTICS"
    ],
    [
        "BOKERS INC",
        "BOKERS"
    ],
    [
        "BORGOGNON OUTILLAGES SA",
        "BORGOGNON OUTILLAGES"
    ],
    [
        "BOTT LTD",
        "BOTT"
    ],
    [
        "BOURSIER SOGREG SARL",
        "BOURSIER SOGREG"
    ],
    [
        "BRACCO DIAGNOSTICS INC.",
        "BRACCO DIAGNOSTICS"
    ],
    [
        "BRANSON ULTRASONICS CORPORATION",
        "BRANSON ULTRASONICS"
    ],
    [
        "BREMOR SA",
        "BREMOR"
    ],
    [
        "BRENNAN AND COMPANY",
        "BRENNAN AND COMPANY"
    ],
    [
        "BRENNTAG GREAT LAKES",
        "BRENNTAG GREAT LAKES"
    ],
    [
        "BRG PRECISION PRODUCTS IN",
        "BRG PRECISION PRODUCTS"
    ],
    [
        "BRINKMANN PUMPEN",
        "BRINKMANN PUMPEN"
    ],
    [
        "FR. BRITSCH GMBH & CO. KG",
        "BRITSCH"
    ],
    [
        "BRUTSCH/RUEGGER OUTILS SA",
        "BRUTSCH RUEGGER"
    ],
    [
        "BUCK GMBH",
        "BUCK"
    ],
    [
        "BUFFALO FILTER",
        "BUFFALO FILTER"
    ],
    [
        "BUFFALO SUPPLY INC",
        "BUFFALO SUPPLY"
    ],
    [
        "BUND-VERLAG GMBH",
        "BUND VERLAG"
    ],
    [
        "BUSCH INC",
        "BUSCH"
    ],
    [
        "BUSER OBERFLÄCHENTECHNIK AG",
        "BUSER OBERFLÄCHENTECHNIK"
    ],
    [
        "BUSINESS CATERING FREIBURG GMBH",
        "BUSINESS CATERING FREIBURG"
    ],
    [
        "BVM BRUNNER GMBH + CO.KG",
        "BVM BRUNNER"
    ],
    [
        "BVS VERPACKUNGS-SYSTEME AG",
        "BVS VERPACKUNGS SYSTEME"
    ],
    [
        "BVS VERPACKUNGS-SYSTEME GMBH",
        "BVS VERPACKUNGS SYSTEME"
    ],
    [
        "BÖLLHOFF VERBINDUNGS-",
        "BÖLLHOFF VERBINDUNGS"
    ],
    [
        "BÜRKLIN GMBH & CO.KG",
        "BÜRKLIN"
    ],
    [
        "C AXIS INC MINNESOTA",
        "C AXIS MINNESOTA"
    ],
    [
        "C.E.NOERPEL GMBH & CO. KG",
        "C E NOERPEL"
    ],
    [
        "CADVISION",
        "CADVISION"
    ],
    [
        "CAL-SIERRA TECHNOLOGIES,",
        "CAL SIERRA"
    ],
    [
        "CAMFIL AG",
        "CAMFIL"
    ],
    [
        "CAMFIL FARR APC",
        "CAMFIL"
    ],
    [
        "CAMFIL USA, INC DBA DP SYSTEMS",
        "CAMFIL"
    ],
    [
        "CARIBBEAN CRANE INSPECTIO",
        "CARIBBEAN CRANE INSPECTIO"
    ],
    [
        "CARIBEX WORLDWIDE",
        "CARIBEX WORLDWIDE"
    ],
    [
        "CARIBEX WORLDWIDE INC",
        "CARIBEX WORLDWIDE"
    ],
    [
        "CARL DITTMANN GMBH&CO.KG",
        "CARL DITTMANN"
    ],
    [
        "CARL ZEISS 3D AUTOMATION GMBH",
        "CARL ZEISS 3D AUTOMATION"
    ],
    [
        "CARLTON BATES COMPANY",
        "CARLTON BATES"
    ],
    [
        "CARPENTER TECHNOLOGY",
        "CARPENTER TECHNOLOGY"
    ],
    [
        "CARPENTER TECHNOLOGY EURO",
        "CARPENTER TECHNOLOGY"
    ],
    [
        "CARTOLUX",
        "CARTOLUX"
    ],
    [
        "CARTONCRAFT *",
        "CARTONCRAFT"
    ],
    [
        "CATE INDUSTRIAL SOLUTIONS",
        "CATE INDUSTRIAL SOLUTIONS"
    ],
    [
        "CDW",
        "CDW"
    ],
    [
        "CDW DIRECT",
        "CDW"
    ],
    [
        "CECA",
        "CECA"
    ],
    [
        "CG AUTOMATION & FIXTURE INC",
        "CG AUTOMATION & FIXTURE"
    ],
    [
        "CG TEC GMBH",
        "CG TEC"
    ],
    [
        "CHEMISAL SAFETY LTD",
        "CHEMISAL SAFETY"
    ],
    [
        "CHICAGO GLUE MACHINE *",
        "CHICAGO GLUE MACHINE"
    ],
    [
        "CHIPBLASTER, INC.",
        "CHIPBLASTER"
    ],
    [
        "CINCINNATI TEST SYSTEMS, INC.",
        "CINCINNATI TEST SYSTEMS"
    ],
    [
        "CLARKE COMMERC.SOLUTION",
        "CLARKE COMMERC SOLUTION"
    ],
    [
        "CLASSIC TECHNOLOGY LIMITED",
        "CLASSIC TECHNOLOGY"
    ],
    [
        "CNC MACHINE SOLUTIONS LLC",
        "CNC MACHINE SOLUTIONS"
    ],
    [
        "COEUR INCORPORATED",
        "COEUR INCORPORATED"
    ],
    [
        "COHERENT MUNICH GMBH & CO.KG",
        "COHERENT"
    ],
    [
        "COLE-PARMER INSTR CO.",
        "COLE PARMER"
    ],
    [
        "COLUMBUS CHEMICAL",
        "COLUMBUS CHEMICAL"
    ],
    [
        "COLUSA SCIENCE",
        "COLUSA SCIENCE"
    ],
    [
        "COMCO INC",
        "COMCO"
    ],
    [
        "COMMERCIAL CONTRACT GROUP",
        "COMMERCIAL CONTRACT GROUP"
    ],
    [
        "COMPLEX FABRICATORS",
        "COMPLEX FABRICATORS"
    ],
    [
        "COMPONENT SUPPLY",
        "COMPONENT SUPPLY"
    ],
    [
        "RS COMPONENTS GMBH",
        "COMPONENTS"
    ],
    [
        "CONFLUENT MEDICAL TECHNOLOGIES, INC.",
        "CONFLUENT MEDICAL TECHNOLOGIES"
    ],
    [
        "CONTAINER SUPPLY, INC.",
        "CONTAINER SUPPLY"
    ],
    [
        "CONTECH MEDICAL INC",
        "CONTECH MEDICAL"
    ],
    [
        "COVESTRO DEUTSCHLAND AG",
        "COVESTRO"
    ],
    [
        "CREGANNA MEDICAL",
        "CREGANNA MEDICAL"
    ],
    [
        "CREST ULTRASONICS CORP",
        "CREST ULTRASONICS"
    ],
    [
        "CROSSTEX",
        "CROSSTEX"
    ],
    [
        "CROSSTEX INTERNATIONAL",
        "CROSSTEX"
    ],
    [
        "CSP",
        "CSP"
    ],
    [
        "CURBELL MEDICAL PRODUCTS INC",
        "CURBELL MEDICAL PRODUCTS"
    ],
    [
        "CUSTER",
        "CUSTER"
    ],
    [
        "CUSTER WORKPLACE INTERIOR",
        "CUSTER WORKPLACE INTERIOR"
    ],
    [
        "D/C TRANSPORT, INC.",
        "D C TRANSPORT"
    ],
    [
        "DAIGGER SCIENTIFIC INC",
        "DAIGGER SCIENTIFIC"
    ],
    [
        "DALEX SCHWEISSMASCHINEN",
        "DALEX SCHWEISSMASCHINEN"
    ],
    [
        "DANCO METAL SURFACING",
        "DANCO"
    ],
    [
        "DARTRONICS INC",
        "DARTRONICS"
    ],
    [
        "DATA IMAGING",
        "DATA IMAGING"
    ],
    [
        "DATA SUPPORT INC",
        "DATA SUPPORT"
    ],
    [
        "DAVE BARRY PLASTICS LTD",
        "DAVE BARRY PLASTICS"
    ],
    [
        "DB-ELEKTRONIK24",
        "DB ELEKTRONIK24"
    ],
    [
        "DC SWISS SA",
        "DC SWISS"
    ],
    [
        "DEL-TRON PRECISION, INC.",
        "DEL TRON PRECISION"
    ],
    [
        "DELTECH",
        "DELTECH"
    ],
    [
        "DEMPSEY INDUSTRIES INC",
        "DEMPSEY INDUSTRIES"
    ],
    [
        "DENIOS",
        "DENIOS"
    ],
    [
        "DEPARTMENT OF TRANSPORTAT",
        "DEPARTMENT OF TRANSPORTAT"
    ],
    [
        "DEPATIE FLUID POWER COMPANY",
        "DEPATIE FLUID POWER"
    ],
    [
        "DESIGN PRO LTD",
        "DESIGN PRO"
    ],
    [
        "DESIGN YOUR CUBE",
        "DESIGN YOUR CUBE"
    ],
    [
        "DEXTER MAGNETIC MATERIALS",
        "DEXTER MAGNETIC MATERIALS"
    ],
    [
        "DIAMETAL",
        "DIAMETAL"
    ],
    [
        "DIAMETAL AG",
        "DIAMETAL"
    ],
    [
        "DICKSON",
        "DICKSON"
    ],
    [
        "DIETER SCHÄTZLE GMBH + CO. KG",
        "DIETER SCHÄTZLE"
    ],
    [
        "DIMENSION WORKS",
        "DIMENSION WORKS"
    ],
    [
        "DIRECTED LIGHT INC",
        "DIRECTED LIGHT"
    ],
    [
        "DONALDSON SCHWEIZ GMBH",
        "DONALDSON"
    ],
    [
        "DONALDSON CO INC",
        "DONALDSON"
    ],
    [
        "DOT",
        "DOT"
    ],
    [
        "DOT GMBH",
        "DOT"
    ],
    [
        "DR. HEINRICH SCHNEIDER MESSTECHNIK",
        "DR HEINRICH SCHNEIDER MESSTECHNIK"
    ],
    [
        "DR.-ING.P.CHRISTIANI GMBH&CO.KG",
        "DR ING P CHRISTIANI"
    ],
    [
        "DRAGO",
        "DRAGO"
    ],
    [
        "DRUCKFLEX-KUCHENBROD GMBH",
        "DRUCKFLEX KUCHENBROD"
    ],
    [
        "DUNNER SA",
        "DUNNER"
    ],
    [
        "E.P.S.",
        "E P S"
    ],
    [
        "E.ZOLLER GMBH & CO. KG",
        "E ZOLLER"
    ],
    [
        "EAGLE STAINLESS TUBE CORP",
        "EAGLE STAINLESS TUBE"
    ],
    [
        "EARSONICS SAS",
        "EARSONICS"
    ],
    [
        "EASTWAY TECHNICAL CONSULT",
        "EASTWAY TECHNICAL CONSULT"
    ],
    [
        "ECKHARDT GMBH",
        "ECKHARDT"
    ],
    [
        "EDENRED FRANCE",
        "EDENRED"
    ],
    [
        "EDITIONS FRANCIS LEFEBVRE",
        "EDITIONS FRANCIS LEFEBVRE"
    ],
    [
        "EDMOND OPTICS",
        "EDMOND OPTICS"
    ],
    [
        "EDWARDS IND. SALES INC.",
        "EDWARDS SALES"
    ],
    [
        "EGLI FEDERNFABRIK AG",
        "EGLI FEDERNFABRIK"
    ],
    [
        "ELASTO TEC",
        "ELASTO TEC"
    ],
    [
        "ELDER RUBBER INC",
        "ELDER RUBBER"
    ],
    [
        "ELECTRICAL EQUIPMENT COMPANY(SAP)",
        "ELECTRICAL EQUIPMENT COMPANY"
    ],
    [
        "ELECTRO RENT CORPORATION",
        "ELECTRO RENT"
    ],
    [
        "ELPE GMBH",
        "ELPE"
    ],
    [
        "EMPIRE AMERICAN RIGGING &",
        "EMPIRE AMERICAN RIGGING"
    ],
    [
        "ENCISION INC",
        "ENCISION"
    ],
    [
        "ENGINEERED PRODUCTS &",
        "ENGINEERED PRODUCTS"
    ],
    [
        "ENGIWORKS CORPORATION",
        "ENGIWORKS"
    ],
    [
        "ENVIROFALK",
        "ENVIROFALK"
    ],
    [
        "ENVIROFALK AG",
        "ENVIROFALK"
    ],
    [
        "ENVIROFALK GMBH",
        "ENVIROFALK"
    ],
    [
        "ENVIRONMENT HEALTH&SAFETY",
        "ENVIRONMENT HEALTH&SAFETY"
    ],
    [
        "ERGOSYSTEM AG",
        "ERGOSYSTEM"
    ],
    [
        "ESKENAZI SA",
        "ESKENAZI"
    ],
    [
        "ESPICORP INC DBA ESPI",
        "ESPICORP ESPI"
    ],
    [
        "ESSENTRA PACKAGING US INC",
        "ESSENTRA PACKAGING"
    ],
    [
        "ESSILOR DEPISTAGE FRANCE SA",
        "ESSILOR DEPISTAGE"
    ],
    [
        "EUROFINS LANCASTER LABORATORIES",
        "EUROFINS CORK"
    ],
    [
        "EUROPHARMA(EURO ACCOUNT)",
        "EUROPHARMA"
    ],
    [
        "EXAIR",
        "EXAIR"
    ],
    [
        "EXPEDITOS INTERNATIONAL",
        "EXPEDITOS INTERNATIONAL"
    ],
    [
        "EXPEDITROS",
        "EXPEDITROS"
    ],
    [
        "FABRICATED COMPONENTS AND ASSE",
        "FABRICATED COMPONENTS AND ASSE"
    ],
    [
        "FANUC FRANCE",
        "FANUC"
    ],
    [
        "FASPRO TECHNOLOGIES",
        "FASPRO TECHNOLOGIES"
    ],
    [
        "FATHOM",
        "FATHOM"
    ],
    [
        "FEIG ELECTRONICS INC",
        "FEIG ELECTRONICS"
    ],
    [
        "FEINWERKTECHNIK RITZENTHALER GMBH",
        "FEINWERKTECHNIK RITZENTHALER"
    ],
    [
        "FESTO SA",
        "FESTO"
    ],
    [
        "FKM SINTERTECHNIK GMBH",
        "FKM SINTERTECHNIK"
    ],
    [
        "FLATLEY ENGINEERING",
        "FLATLEY ENGINEERING"
    ],
    [
        "FLEXFACTORY",
        "FLEXFACTORY"
    ],
    [
        "FLUID TECHNOLOGY CORP",
        "FLUID TECHNOLOGY"
    ],
    [
        "FMB MASCHINENBAU-",
        "FMB MASCHINENBAU"
    ],
    [
        "FORECREU",
        "FORECREU"
    ],
    [
        "FORMAMED",
        "FORMAMED"
    ],
    [
        "FORT WAYNE METALS RESEARCH PRODUCTS CORP",
        "FORT WAYNE METALS RESEARCH PRODUCTS"
    ],
    [
        "FORT WAYNE WIRE DIE INC",
        "FORT WAYNE WIRE DIE"
    ],
    [
        "FREYER GMBH & CO. KG",
        "FREYER"
    ],
    [
        "FUTEK ADVANCED SENSOR",
        "FUTEK ADVANCED SENSOR"
    ],
    [
        "GAERNER GMBH & CO. KG",
        "GAERNER"
    ],
    [
        "GAGE CRIB WORLDWIDE INC",
        "GAGE CRIB WORLDWIDE"
    ],
    [
        "GAST MANUFACTURING, INC.",
        "GAST MANUFACTURING"
    ],
    [
        "GAUTHIER BIOMEDICAL INC",
        "GAUTHIER BIOMEDICAL"
    ],
    [
        "GEBR. HELD",
        "GEBR HELD"
    ],
    [
        "GEMA",
        "GEMA"
    ],
    [
        "GENERAL BUSINESS MCHNS.,",
        "GENERAL BUSINESS MCHNS"
    ],
    [
        "GENERAL DATA CO",
        "GENERAL DATA"
    ],
    [
        "GEODIS CALBERSON AQUITAINE",
        "GEODIS CALBERSON AQUITAINE"
    ],
    [
        "GERARD POLY MOULDINGS",
        "GERARD POLY MOULDINGS"
    ],
    [
        "GERLACH",
        "GERLACH"
    ],
    [
        "GESELLSCHAFT FÜR KOOPERATIVE",
        "GESELLSCHAFT FÜR KOOPERATIVE"
    ],
    [
        "GLOBAL CONCEPTS ENTERPRISE, IN",
        "GLOBAL CONCEPTS ENTERPRISE"
    ],
    [
        "GLOBE MOTORS",
        "GLOBE MOTORS"
    ],
    [
        "GO ENGINEER",
        "GO ENGINEER"
    ],
    [
        "GO! GENERAL OVERNIGHT",
        "GO! GENERAL OVERNIGHT"
    ],
    [
        "GOODIE-D",
        "GOODIE-D"
    ],
    [
        "GOODWILL IND OF SW MICH",
        "GOODWILL IND OF SW MICH"
    ],
    [
        "GOSIGER SE",
        "GOSIGER"
    ],
    [
        "GRAHAM ENGINEERING CORPOR",
        "GRAHAM ENGINEERING"
    ],
    [
        "GREATBATCH MEDICAL",
        "GREATBATCH MEDICAL"
    ],
    [
        "GREISINGER",
        "GREISINGER"
    ],
    [
        "GREUB MACHINES SA",
        "GREUB MACHINES"
    ],
    [
        "GRIFFIN ENG.SUPPLIES",
        "GRIFFIN ENG SUPPLIES"
    ],
    [
        "GSELL MEDICAL PLASTICS AG",
        "GSELL MEDICAL PLASTICS"
    ],
    [
        "GWS TOOL HOLDINGS LLC",
        "GWS TOOL"
    ],
    [
        "H.WALDMANN GMBH&CO.",
        "H WALDMANN"
    ],
    [
        "HAAS FACTORY OUTLET",
        "HAAS FACTORY OUTLET"
    ],
    [
        "HAAS WERKZEUGMASCHINEN GMBH",
        "HAAS WERKZEUGMASCHINEN"
    ],
    [
        "HAIMER GMBH",
        "HAIMER"
    ],
    [
        "HAM FRANCE MEDICAL",
        "HAM MEDICAL"
    ],
    [
        "HAMACHER GMBH",
        "HAMACHER"
    ],
    [
        "HANNA INSTRUMENTS LTD",
        "HANNA INSTRUMENTS"
    ],
    [
        "HANS BUSCHLE",
        "HANS BUSCHLE"
    ],
    [
        "HANS-PAUL ARNDT",
        "HANS PAUL ARNDT"
    ],
    [
        "HANSER DR. C. AG",
        "HANSER DR. C."
    ],
    [
        "HARDWARE SPECIALTY CO INC",
        "HARDWARE SPECIALTY"
    ],
    [
        "HARLAND MEDICAL SYSTEMS INC.",
        "HARLAND MEDICAL SYSTEMS"
    ],
    [
        "HARLOR",
        "HARLOR"
    ],
    [
        "HAYDON KERK MOTION",
        "HAYDON KERK MOTION"
    ],
    [
        "HBS BOLZENSCHWEI▀SYSTEME",
        "HBS BOLZENSCHWEI SYSTEME"
    ],
    [
        "HEALTHMARK INDUSTRIES CO.",
        "HEALTHMARK INDUSTRIES"
    ],
    [
        "HEICK'S CABINET SHOP, INC",
        "HEICK S CABINET SHOP"
    ],
    [
        "HEINZ WOLFFGRAMM AG",
        "HEINZ WOLFFGRAMM"
    ],
    [
        "HELP-TEC AUTOMATION AG",
        "HELP TEC AUTOMATION"
    ],
    [
        "HEROLD PRECISION METALS,",
        "HEROLD PRECISION METALS"
    ],
    [
        "HESTIKA FRANCE",
        "HESTIKA"
    ],
    [
        "HEULE WERKZEUG AG",
        "HEULE WERKZEUG"
    ],
    [
        "HI TECH PRODUCTS INC",
        "HI TECH PRODUCTS"
    ],
    [
        "HIELSCHER ULTRASONICS GMBH",
        "HIELSCHER ULTRASONICS"
    ],
    [
        "HISCO",
        "HISCO"
    ],
    [
        "HOF MACHINING INC",
        "HOF MACHINING"
    ],
    [
        "HOLZLAND AUFEROTH",
        "HOLZLAND AUFEROTH"
    ],
    [
        "HORST BROZIAT GMBH",
        "HORST BROZIAT"
    ],
    [
        "HORST WITTE",
        "HORST WITTE"
    ],
    [
        "HTI TECHNOLOGIES",
        "HTI TECHNOLOGIES"
    ],
    [
        "HYDRACORE INC",
        "HYDRACORE"
    ],
    [
        "HYTECH SPRING & MACHINE",
        "HYTECH SPRING & MACHINE"
    ],
    [
        "IAC INDUSTRIES",
        "IAC INDUSTRIES"
    ],
    [
        "IDENTI-GRAPHICS, INC.",
        "IDENTI GRAPHICS"
    ],
    [
        "IDM MEDICAL GAS CO",
        "IDM MEDICAL GAS"
    ],
    [
        "IDT PAYMENT SERVICES",
        "IDT CORPORATION"
    ],
    [
        "IFANGER AG",
        "IFANGER"
    ],
    [
        "IFB KG",
        "IFB"
    ],
    [
        "IGUS",
        "IGUS"
    ],
    [
        "IHK-BILDUNGSZENTRUM",
        "IHK BILDUNGSZENTRUM"
    ],
    [
        "ILLIG MASCHINENBAU GMBH & CO KG",
        "ILLIG"
    ],
    [
        "ILLIG UK LTD",
        "ILLIG"
    ],
    [
        "IMPLANT RESOURCE",
        "IMPLANT RESOURCE"
    ],
    [
        "IMPRINT MARKETING CONCEPT",
        "IMPRINT MARKETING"
    ],
    [
        "IMPRINT MARKETING CONCEPTS",
        "IMPRINT MARKETING CONCEPTS"
    ],
    [
        "INDUSO",
        "INDUSO"
    ],
    [
        "INDUSTORE LTD.,",
        "INDUSTORE"
    ],
    [
        "INDUSTRIAL CONTROLS",
        "INDUSTRIAL CONTROLS"
    ],
    [
        "INDUSTRIAL LOGIC CONTROLS",
        "INDUSTRIAL LOGIC CONTROLS"
    ],
    [
        "INNOTEC",
        "INNOTEC"
    ],
    [
        "INNOVATIVE TOTAL SOLUTION",
        "INNOVATIVE TOTAL SOLUTION"
    ],
    [
        "INSECO INCORPORATED",
        "INSECO"
    ],
    [
        "INSTRON LIMITED",
        "INSTRON"
    ],
    [
        "INTEC ROBOTIC SOLUTIONS",
        "INTEC ROBOTIC SOLUTIONS"
    ],
    [
        "INTEGRATED MECHANIZATION SOLUTIONS",
        "INTEGRATED MECHANIZATION SOLUTIONS"
    ],
    [
        "INTERTECH SUPPLIES INC",
        "INTERTECH SUPPLIES"
    ],
    [
        "INVENTEC PERFORMANCE",
        "INVENTEC PERFORMANCE"
    ],
    [
        "INVENTITIVES",
        "INVENTITIVES"
    ],
    [
        "INVESTMENT CASTING EQUIPMENT LTD",
        "INVESTMENT CASTING EQUIPMENT"
    ],
    [
        "IONBOND",
        "IONBOND"
    ],
    [
        "IRPD AG",
        "IRPD"
    ],
    [
        "ITS INDUSTRIE-TEAM-SERVICE GMBH",
        "ITS INDUSTRIE TEAM SERVICE"
    ],
    [
        "ITS INNOVATIVE TOTAL SOL",
        "ITS INNOVATIVE TOTAL SOL"
    ],
    [
        "J.W.ZANDER GMBH & CO",
        "J W ZANDER"
    ],
    [
        "JA NATIONWIDE, INC. *",
        "JA NATIONWIDE"
    ],
    [
        "JAC MANUFACTURING INC",
        "JAC MANUFACTURING"
    ],
    [
        "JAKOB L÷WER",
        "JAKOB L÷WER"
    ],
    [
        "JEM TOOL SUPPLY",
        "JEM TOOL SUPPLY"
    ],
    [
        "JENTON INTERNATIONAL",
        "JENTON INTERNATIONAL"
    ],
    [
        "JK MACHINING INC",
        "JK MACHINING"
    ],
    [
        "JOERNS HEALTHCARE INC",
        "JOERNS HEALTHCARE"
    ],
    [
        "JOHN P KUMMER LIMITED",
        "JOHN P KUMMER"
    ],
    [
        "JOLA SPEZIALSCHALTER GMBH",
        "JOLA SPEZIALSCHALTER"
    ],
    [
        "JORGENSON COMPANIES",
        "JORGENSON COMPANIES"
    ],
    [
        "JSJ FURNITURE CORPORATION",
        "JSJ FURNITURE"
    ],
    [
        "JUMO GMBH & CO. KG",
        "JUMO"
    ],
    [
        "KAMAN FLUID POWER LLC",
        "KAMAN FLUID POWER"
    ],
    [
        "KASTO SUISSE",
        "KASTO"
    ],
    [
        "KBS INDUSTRIEELEKTRONIK GMBH",
        "KBS INDUSTRIEELEKTRONIK"
    ],
    [
        "KELCH UK",
        "KELCH"
    ],
    [
        "KELLER POLYESTER SA",
        "KELLER POLYESTER"
    ],
    [
        "KEMPF GMBH",
        "KEMPF"
    ],
    [
        "KENNAMETAL STELLITE LP",
        "KENNAMETAL STELLITE"
    ],
    [
        "KEY INDUSTRIAL EQUIPTMENT",
        "KEY INDUSTRIAL EQUIPTMENT"
    ],
    [
        "KEY SURGICAL LLC",
        "KEY SURGICAL"
    ],
    [
        "KEY2COMPLIANCE",
        "KEY2COMPLIANCE"
    ],
    [
        "KHW KONZMANN",
        "KHW KONZMANN"
    ],
    [
        "KIELER WΣGETECHNIK GMBH",
        "KIELER W‰GETECHNIK"
    ],
    [
        "KIENER+WITTLIN AG",
        "KIENER WITTLIN"
    ],
    [
        "KIMCO",
        "KIMCO"
    ],
    [
        "KINTETSU WORLD EXPRESS",
        "KINTETSU WORLD EXPRESS"
    ],
    [
        "KINTETSU WORLD EXPRESS,",
        "KINTETSU WORLD EXPRESS"
    ],
    [
        "KLEYLING",
        "KLEYLING"
    ],
    [
        "KLONTECH MEASUREMENT SOLU",
        "KLONTECH MEASUREMENT SOLU"
    ],
    [
        "KLÜBER LUBRICATION AG",
        "KLÜBER LUBRICATION"
    ],
    [
        "KNIGHT LLC",
        "KNIGHT"
    ],
    [
        "KNOLL MASCHINENBAU GMBH",
        "KNOLL MASCHINENBAU"
    ],
    [
        "KOCH+NAGY GMBH",
        "KOCH NAGY"
    ],
    [
        "KOMAX CORPORATION",
        "KOMAX"
    ],
    [
        "KOMEG INDUSTRIELLE",
        "KOMEG INDUSTRIELLE"
    ],
    [
        "KOOPS INC",
        "KOOPS"
    ],
    [
        "KOS",
        "KOS"
    ],
    [
        "KOSCHER & WURTZ GMBH",
        "KOSCHER & WURTZ"
    ],
    [
        "KRAFT & BAUER BRANDSCHUTZSYSTEME GM",
        "KRAFT & BAUER BRANDSCHUTZSYSTEME"
    ],
    [
        "KREUTZBERGER ARBEITSBUEHNEN",
        "KREUTZBERGER ARBEITSBUEHNEN"
    ],
    [
        "KRIWAT GMBH",
        "KRIWAT"
    ],
    [
        "KÖGEL GMBH",
        "KÖGEL"
    ],
    [
        "L-COM",
        "L-COM"
    ],
    [
        "LABORATORY INSTRUMENTS AND SUPPLIES",
        "LABORATORY INSTRUMENTS AND SUPPLIES"
    ],
    [
        "LAKE REGION MEDICAL",
        "LAKE REGION MEDICAL"
    ],
    [
        "LAKEVIEW EQUIPMENT INC",
        "LAKEVIEW EQUIPMENT"
    ],
    [
        "LANCO INTEGRATED",
        "LANCO INTEGRATED"
    ],
    [
        "LANG TECHNIK GMBH",
        "LANG TECHNIK"
    ],
    [
        "LANSMONT CORPORATION",
        "LANSMONT"
    ],
    [
        "LAPRINTA DER WERBEARTIKEL SHOP",
        "LAPRINTA DER WERBEARTIKEL SHOP"
    ],
    [
        "LAUDA DR. R. WOBSER GMBH & CO KG",
        "LAUDA DR R WOBSER"
    ],
    [
        "LAWSON PRODUCTS INC",
        "LAWSON PRODUCTS"
    ],
    [
        "LAYERED MANUFACTURING",
        "LAYERED MANUFACTURING"
    ],
    [
        "LDT LEVEL DOSIERTECHNIK GMBH",
        "LDT LEVEL DOSIERTECHNIK"
    ],
    [
        "LEBERT AG",
        "LEBERT"
    ],
    [
        "LEE SPRING LIMITED",
        "LEE SPRING"
    ],
    [
        "LEE SPRING",
        "LEE SPRING"
    ],
    [
        "LEE SPRING CO. *",
        "LEE SPRING"
    ],
    [
        "LEE SPRING COMPANY",
        "LEE SPRING"
    ],
    [
        "LEE SPRING COMPANY INC",
        "LEE SPRING"
    ],
    [
        "LEHMANN METZGEREI-PARTYSERVICE-CATE",
        "LEHMANN METZGEREI PARTYSERVICE CATE"
    ],
    [
        "LEICA MIKROSYSTEME VERTRIEB GMBH",
        "LEICA MIKROSYSTEME VERTRIEB"
    ],
    [
        "LEMO USA INC",
        "LEMO"
    ],
    [
        "LEMO ELEKTRONIK GMBH",
        "LEMO ELEKTRONIK"
    ],
    [
        "LENANE PRECISION LTD",
        "LENANE PRECISION"
    ],
    [
        "LENNON FLUID SYSTEMS LLC",
        "LENNON FLUID SYSTEMS"
    ],
    [
        "LENNOX LABORATORY SUPPLIES LTD",
        "LENNOX LABORATORY SUPPLIES"
    ],
    [
        "LES ATELIERS MODERNES",
        "LES ATELIERS MODERNES"
    ],
    [
        "LINDQUIST STEELS INC",
        "LINDQUIST STEELS"
    ],
    [
        "LINK TOOLING LTD",
        "LINK TOOLING"
    ],
    [
        "LINX LLLP",
        "LINX"
    ],
    [
        "LISSMAC MASCHINENBAU GMBH",
        "LISSMAC MASCHINENBAU"
    ],
    [
        "LISTA INTL CORP",
        "LISTA INTL"
    ],
    [
        "LITHO CIRCUITS LTD",
        "LITHO CIRCUITS"
    ],
    [
        "LNS DEUTSCHLAND GMBH",
        "LNS"
    ],
    [
        "LOMAR MACHINE & TOOL COMPANY",
        "LOMAR MACHINE & TOOL"
    ],
    [
        "LOOK SHARP! MARKETING",
        "LOOK SHARP MARKETING"
    ],
    [
        "LOSMA",
        "LOSMA"
    ],
    [
        "LOUIS BELET SA",
        "LOUIS BELET"
    ],
    [
        "LOUIS BELET SA INAKTIV",
        "LOUIS BELET"
    ],
    [
        "LOW VOLUME POWDER LLC",
        "LOW VOLUME POWDER"
    ],
    [
        "LUK CORPORATION",
        "LUK"
    ],
    [
        "LUTZ-PUMPEN GMBH",
        "LUTZ PUMPEN"
    ],
    [
        "MAAS",
        "MAAS"
    ],
    [
        "MACRO SYSTEMS INC",
        "MACRO SYSTEMS"
    ],
    [
        "MADXEL TOOLS INC",
        "MADXEL TOOLS"
    ],
    [
        "MAFRA  MAILE + HASS GMBH",
        "MAFRA MAILE HASS"
    ],
    [
        "MAISON GINESTET",
        "MAISON GINESTET"
    ],
    [
        "MARK-10 CORPORATION",
        "MARK 10"
    ],
    [
        "MARK PRECISION TOOL & ENG",
        "MARK PRECISION TOOL & ENG"
    ],
    [
        "MARKSA",
        "MARKSA"
    ],
    [
        "MARKSA SA",
        "MARKSA"
    ],
    [
        "MASCHINEN BADER-RITTER",
        "MASCHINEN BADER RITTER"
    ],
    [
        "MASTERCRAFT MACHINE INC",
        "MASTERCRAFT MACHINE"
    ],
    [
        "MATRIX TECHNOLOGIES GMBH",
        "MATRIX TECHNOLOGIES"
    ],
    [
        "MATTES + GRAF GMBH & CO. KG",
        "MATTES GRAF"
    ],
    [
        "MAUTH WERKZEUG-SCHLEIFTECHNIK GMBH",
        "MAUTH WERKZEUG SCHLEIFTECHNIK"
    ],
    [
        "MAZAK CORP",
        "MAZAK"
    ],
    [
        "MC MACHINERY SYSTEMS, INC",
        "MC MACHINERY SYSTEMS, INC"
    ],
    [
        "MCE SWISS SARL",
        "MCE SWISS"
    ],
    [
        "MED CONTACT GMBH",
        "MED CONTACT"
    ],
    [
        "MEDICAL EXTRUSION TECHNOLOGY, INC",
        "MEDICAL EXTRUSION TECHNOLOGY"
    ],
    [
        "MEDICAL EXTRUSIONS TECHNOLOGY INC",
        "MEDICAL EXTRUSIONS TECHNOLOGY"
    ],
    [
        "MEDIN CORPORATION",
        "MEDIN CORPORATION"
    ],
    [
        "MEDIN TECHNOLOGIES INC",
        "MEDIN TECHNOLOGIES"
    ],
    [
        "MEISTER SERGE SA",
        "MEISTER SERGE"
    ],
    [
        "MEMMERT GMBH + CO. KG",
        "MEMMERT"
    ],
    [
        "MERIT MEDICAL SYSTEMS, IN",
        "MERIT MEDICAL SYSTEMS"
    ],
    [
        "MERITEC PRESENTATION PROD",
        "MERITEC PRESENTATION PROD"
    ],
    [
        "METAL COMPONENTS LLC",
        "METAL COMPONENTS"
    ],
    [
        "METALL-DISCH KG",
        "METALL DISCH"
    ],
    [
        "METALLFORM WΣCHTER GMBH",
        "METALLFORM WΣCHTER"
    ],
    [
        "METALLTEC - DANIEL ALBRECHT",
        "METALLTEC DANIEL ALBRECHT"
    ],
    [
        "METHODS MACHINE TOOLS",
        "METHODS MACHINE TOOLS"
    ],
    [
        "METROLOGY & TOOLING, DIME",
        "METROLOGY & TOOLING DIME"
    ],
    [
        "MICHAEL C. FINA",
        "MICHAEL C. FINA"
    ],
    [
        "MICHAEL DAMASCHKE",
        "MICHAEL DAMASCHKE"
    ],
    [
        "MICROLUMEN INC",
        "MICROLUMEN"
    ],
    [
        "MICROMERITICS GMBH",
        "MICROMERITICS"
    ],
    [
        "MICROPHOTO INC",
        "MICROPHOTO"
    ],
    [
        "MICROSCOPE WORLD",
        "MICROSCOPE WORLD"
    ],
    [
        "MICROVAL",
        "MICROVAL"
    ],
    [
        "MIDWEST PRINTED CIRC INC.",
        "MIDWEST PRINTED CIRC"
    ],
    [
        "MINNESOTA RUBBER &",
        "MINNESOTA RUBBER"
    ],
    [
        "MISUMI EUROPA GMBH",
        "MISUMI"
    ],
    [
        "MMC HITACHI TOOL ENGINEERING GMBH",
        "MMC HITACHI TOOL ENGINEERING"
    ],
    [
        "MOCAP",
        "MOCAP"
    ],
    [
        "MOLD CRAFT INC",
        "MOLD CRAFT"
    ],
    [
        "MOMAR MANUFACTURING",
        "MOMAR MANUFACTURING"
    ],
    [
        "MONBLANC",
        "MONBLANC"
    ],
    [
        "MONODE MARKING PROD. INC.",
        "MONODE MARKING PRODUCTS"
    ],
    [
        "MONODE MARKING PRODUCTS, INC",
        "MONODE MARKING PRODUCTS"
    ],
    [
        "MPI",
        "MPI INCORPORATED"
    ],
    [
        "MTF TECHNIK HARDY SCHUERFELD",
        "MTF TECHNIK HARDY SCHUERFELD"
    ],
    [
        "MW1 LOGISTIKZENTRUM GMBH",
        "MW1 LOGISTIKZENTRUM"
    ],
    [
        "MWS WIRE INDUSTRIES",
        "MWS WIRE INDUSTRIES"
    ],
    [
        "MÄDLER GMBH",
        "MÄDLER"
    ],
    [
        "NATIONAL MOLDING LLC *",
        "NATIONAL MOLDING"
    ],
    [
        "NCH CORPORATION",
        "NCH"
    ],
    [
        "NEB CNC",
        "NEB CNC"
    ],
    [
        "NEFF ENGINEERING INC",
        "NEFF ENGINEERING"
    ],
    [
        "NELIPAK HEALTHCARE PACKAGING",
        "NELIPAK HEALTHCARE PACKAGING"
    ],
    [
        "NEOPOST AG",
        "NEOPOST"
    ],
    [
        "NEOPOST",
        "NEOPOST"
    ],
    [
        "NEOPOST GMBH & CO. KG",
        "NEOPOST"
    ],
    [
        "NEOPOST FRANCE",
        "NEOPOST"
    ],
    [
        "NEW HAMPSHIRE BALL",
        "NEW HAMPSHIRE BALL"
    ],
    [
        "NEW JERSEY DIAMOND PRODS",
        "NEW JERSEY DIAMOND PRODS"
    ],
    [
        "NEWMEDIC SYSTEMS, INC.",
        "NEWMEDIC SYSTEMS"
    ],
    [
        "NEXXSPAN HEALTHCARE",
        "NEXXSPAN HEALTHCARE"
    ],
    [
        "NGL CLEANING TECHNOLOGY SA",
        "NGL CLEANING TECHNOLOGY"
    ],
    [
        "NORELEM NORMELELEMENTE AG",
        "NORELEM NORMELEMENTE"
    ],
    [
        "NORMAFIL",
        "NORMAFIL"
    ],
    [
        "NORMAN ROQUETTE",
        "NORMAN ROQUETTE"
    ],
    [
        "NORTHERN DIGITAL INC",
        "NORTHERN DIGITAL"
    ],
    [
        "NUBIUS GROUP PRAEZISIONS-",
        "NUBIUS GROUP PRAEZISIONS"
    ],
    [
        "NYFFENEGGER HEINZ",
        "NYFFENEGGER HEINZ"
    ],
    [
        "NÄPFLIN SCHLEIFTECHNIK AG",
        "NÄPFLIN SCHLEIFTECHNIK"
    ],
    [
        "O'D CLEANING SUPPLIES LTD",
        "O'D CLEANING SUPPLIES"
    ],
    [
        "OAKWADE LTD",
        "OAKWADE"
    ],
    [
        "OESTLING MARKING SYSTEMS",
        "OESTLING MARKING SYSTEMS"
    ],
    [
        "OFFICE PLUS ERHARDT GMBH",
        "OFFICE PLUS ERHARDT"
    ],
    [
        "OMEGA ENGINEERING",
        "OMEGA ENGINEERING"
    ],
    [
        "OMNI-X USA INC",
        "OMNI X"
    ],
    [
        "ONYX MEDICAL LLC",
        "ONYX MEDICAL"
    ],
    [
        "OPHIR SPIRICON EUROPE GMBH",
        "OPHIR SPIRICON"
    ],
    [
        "ORICS INDUSTRIES INC",
        "ORICS INDUSTRIES"
    ],
    [
        "OSKAR BURGER GMBH&CO.KG",
        "OSKAR BURGER"
    ],
    [
        "OTO METRICS PR INC",
        "OTO METRICS"
    ],
    [
        "OTP INDUSTRIAL SOLUTIONS",
        "OTP INDUSTRIAL SOLUTIONS"
    ],
    [
        "OTTO GANTER & CO.KG",
        "OTTO GANTER"
    ],
    [
        "OURY GUYE",
        "OURY GUYE"
    ],
    [
        "PACIFIC INTERGATED HANDLI",
        "PACIFIC INTERGATED HANDLIING"
    ],
    [
        "PAK AUTOMATION LTD.,",
        "PAK AUTOMATION"
    ],
    [
        "PALL FRANCE SA",
        "PALL"
    ],
    [
        "PAMATECH SYSTEM AG",
        "PAMATECH SYSTEM"
    ],
    [
        "PANACOL USA",
        "PANACOL"
    ],
    [
        "PCM WILLEN SA",
        "PCM WILLEN"
    ],
    [
        "PENN TOOL CO",
        "PENN TOOL"
    ],
    [
        "PHILLIPS PRECISION INC",
        "PHILLIPS PRECISION"
    ],
    [
        "PIBOMULTI",
        "PIBOMULTI"
    ],
    [
        "PINTA MEDICAL, INC. *",
        "PINTA MEDICAL"
    ],
    [
        "PITNEY BOWES IRELAND LTD.",
        "PITNEY BOWES"
    ],
    [
        "PITT OHIO EXPRESS INC",
        "PITT OHIO EXPRESS"
    ],
    [
        "PLASTECH GROUP LTD",
        "PLASTECH GROUP"
    ],
    [
        "PLASTIC PROCESS EQUIPMENT",
        "PLASTIC PROCESS EQUIPMENT"
    ],
    [
        "PMMI",
        "PMMI"
    ],
    [
        "PMS METROLOGIE BECUS",
        "PMS METROLOGIE BECUS"
    ],
    [
        "DK PRAEZISIONSTECHNIK",
        "PRAEZISIONSTECHNIK"
    ],
    [
        "PRECISE-FRANCE SA",
        "PRECISE-FRANCE"
    ],
    [
        "PRECISION AUTOMATED LASER SYSTEMS INC",
        "PRECISION AUTOMATED LASER SYSTEMS"
    ],
    [
        "PRECISION FORMS INC",
        "PRECISION FORMS"
    ],
    [
        "PRECISION INTERCONNECT",
        "PRECISION INTERCONNECT"
    ],
    [
        "PRECISION TOOL DIST INC",
        "PRECISION TOOL DIST"
    ],
    [
        "PRECXIS",
        "PRECXIS"
    ],
    [
        "PRESTIGE METAL PRODUCTS, INC.",
        "PRESTIGE METAL PRODUCTS"
    ],
    [
        "PRIMAFORM AG",
        "PRIMAFORM"
    ],
    [
        "PRIME MANAGEMENT SERV LTD",
        "PRIME MANAGEMENT SERV"
    ],
    [
        "PRODUCTION TEST SYSTEMS LTD",
        "PRODUCTION TEST SYSTEMS"
    ],
    [
        "PRODUCTION TOOL SUPPLY CO",
        "PRODUCTION TOOL SUPPLY"
    ],
    [
        "PRODUCTS FOR MEDICINE",
        "PRODUCTS FOR MEDICINE"
    ],
    [
        "PROFESSIONAL COMUNICATION",
        "PROFESSIONAL COMUNICATION"
    ],
    [
        "PROLINK SOFTWARE CORPORAT",
        "PROLINK SOFTWARE"
    ],
    [
        "PRUDENTIAL OVERALL SUPPLY",
        "PRUDENTIAL OVERALL SUPPLY"
    ],
    [
        "PTI ENGINEERED PLASTICS",
        "PTI ENGINEERED PLASTICS"
    ],
    [
        "PX TOOLS SA",
        "PXTOOLS"
    ],
    [
        "PYRAMID PACKAGING, INC *",
        "PYRAMID PACKAGING"
    ],
    [
        "QUALITY ELECTROPLATING",
        "QUALITY ELECTROPLATING"
    ],
    [
        "QUANTUM3 ALUMINIUM LTD",
        "QUANTUM3 ALUMINIUM"
    ],
    [
        "QUICK TRANSPORTATION",
        "QUICK TRANSPORTATION"
    ],
    [
        "R.R. DONNELLEY",
        "R R DONNELLEY"
    ],
    [
        "R&D GLASS",
        "R&D GLASS"
    ],
    [
        "RAPID SHEET METAL, LLC.",
        "RAPID SHEET METAL"
    ],
    [
        "RAY INDUSTRIES  INC",
        "RAY INDUSTRIES"
    ],
    [
        "RAY PRECISION THREADROLLING LL",
        "RAY PRECISION THREADROLLING LL"
    ],
    [
        "RAYS DIES AND TUBING",
        "RAYS DIES AND TUBING"
    ],
    [
        "REALTOOLS AG",
        "REALTOOLS"
    ],
    [
        "RELIANCE BEARING & GEAR",
        "RELIANCE BEARING & GEAR"
    ],
    [
        "RESONETICS LLC",
        "RESONETICS"
    ],
    [
        "RG ARBEITSSCHUTZ GMBH",
        "RG ARBEITSSCHUTZ"
    ],
    [
        "RIEGER GMBH",
        "RIEGER"
    ],
    [
        "RITEWAY ENGINEERING LTD",
        "RITEWAY ENGINEERING"
    ],
    [
        "RIWAG",
        "RIWAG"
    ],
    [
        "RMS SURGICAL",
        "RMS SURGICAL"
    ],
    [
        "ROBERT VON ENGELBRECHTEN",
        "ROBERT VON ENGELBRECHTEN"
    ],
    [
        "ROBOPOD LTD",
        "ROBOPOD"
    ],
    [
        "ROBOVENT SOLUTIONS GROUP, INC",
        "ROBOVENT SOLUTIONS GROUP"
    ],
    [
        "ROSE PLASTIC FRANCE",
        "ROSE PLASTIC"
    ],
    [
        "ROSE PLASTIC FRANCE SARL",
        "ROSE PLASTIC"
    ],
    [
        "RÖSLER SCHWEIZ AG",
        "ROSLER OBERFLACHENTECHNIK"
    ],
    [
        "ROSS CABLING SYSTEMS, INC.",
        "ROSS CABLING SYSTEMS"
    ],
    [
        "ROTE RADLER",
        "ROTE RADLER"
    ],
    [
        "ROTHGREAVES & ASSOCIATES INC.",
        "ROTHGREAVES & ASSOCIATES"
    ],
    [
        "RUBBER & GASKET",
        "RUBBER & GASKET"
    ],
    [
        "RUDOLF FLUME",
        "RUDOLF FLUME"
    ],
    [
        "RUDOLF MANZ GMBH & CO. KG",
        "RUDOLF MANZ"
    ],
    [
        "RYF AG",
        "RYF"
    ],
    [
        "RÖCHLING MEDICAL WALDACHTAL AG",
        "RÖCHLING MEDICAL WALDACHTAL"
    ],
    [
        "RÖHM SPANNTECHNIK AG",
        "RÖHM SPANNTECHNIK"
    ],
    [
        "S. DUNKES GMBH",
        "S DUNKES"
    ],
    [
        "S4J MANUFACTURING",
        "S4J MANUFACTURING"
    ],
    [
        "SALT CITY COURIERS",
        "SALT CITY COURIERS"
    ],
    [
        "SANDVIK COROMANT",
        "SANDVIK"
    ],
    [
        "SANDVIK AG COROMANT",
        "SANDVIK"
    ],
    [
        "SANITÄTSHAUS H.G.SCHAUB E. K.",
        "SANITÄTSHAUS H G SCHAUB E K"
    ],
    [
        "SCHILLING ENGINEERING GMBH",
        "SCHILLING ENGINEERING"
    ],
    [
        "SCHILLING MARKING SYST.GMBH",
        "SCHILLING MARKING SYST"
    ],
    [
        "SCHLENKER MASCHINENBAU GMBH",
        "SCHLENKER MASCHINENBAU"
    ],
    [
        "SCHMUTZ + SOHN GMBH & CO. KG",
        "SCHMUTZ SOHN"
    ],
    [
        "SCHNYDER + MINDER AG",
        "SCHNYDER MINDER"
    ],
    [
        "SCHOLZ MECHANIK",
        "SCHOLZ MECHANIK"
    ],
    [
        "SCHWANOG",
        "SCHWANOG"
    ],
    [
        "SCHWANOG SIEGFRIED GUENTERT",
        "SCHWANOG SIEGFRIED GUENTERT"
    ],
    [
        "SCHWEIZ. MASCHINEN IMPORT AG",
        "SCHWEIZ. MASCHINEN IMPORT"
    ],
    [
        "SECURITAS AG",
        "SECURITAS"
    ],
    [
        "SECURITAS",
        "SECURITAS"
    ],
    [
        "SECURITY CONTROL INTEGRATIONS INC",
        "SECURITY CONTROL INTEGRATIONS"
    ],
    [
        "SELENIUM",
        "SELENIUM"
    ],
    [
        "SEMITORR ASSOCIATES",
        "SEMITORR ASSOCIATES"
    ],
    [
        "SETTERSTIX, INC *",
        "SETTERSTIX"
    ],
    [
        "SFS UNIMARKET",
        "SFS UNIMARKET"
    ],
    [
        "SHANNON WHOLESALE LTD.,",
        "SHANNON WHOLESALE"
    ],
    [
        "SHEFFIELD PHARMACEUTICALS*",
        "SHEFFIELD PHARMACEUTICALS"
    ],
    [
        "SHERWIN INC",
        "SHERWIN"
    ],
    [
        "SHIMADZU SCIENTIFIC",
        "SHIMADZU SCIENTIFIC"
    ],
    [
        "SIEMAC SPEZIALMASCHINEN VERTRIEBS",
        "SIEMAC SPEZIALMASCHINEN VERTRIEBS"
    ],
    [
        "SIGMA-ALDRICH",
        "SIGMA ALDRICH"
    ],
    [
        "SIGMA-ALDRICH INC",
        "SIGMA ALDRICH"
    ],
    [
        "SIGMA-ALDRICH - SHEBOYGAN",
        "SIGMA ALDRICH  SHEBOYGAN"
    ],
    [
        "SIMAGEC",
        "SIMAGEC"
    ],
    [
        "SINDELE SCHWEISSTECHNIK",
        "SINDELE SCHWEISSTECHNIK"
    ],
    [
        "SIX SIGMA TOOLS AG",
        "SIX SIGMA TOOLS"
    ],
    [
        "SLAUGHTER INC.",
        "SLAUGHTER"
    ],
    [
        "SMITHS MEDICAL ASD INC",
        "SMITHS MEDICAL"
    ],
    [
        "SMOC INDUSTRIES",
        "SMOC INDUSTRIES"
    ],
    [
        "SMP",
        "SMP"
    ],
    [
        "SMP: MANUAL RECHARGES",
        "SMP"
    ],
    [
        "SMW-AUTOBLOK CORPORATION",
        "SMW AUTOBLOK"
    ],
    [
        "SNAP-ON INC",
        "SNAP ON"
    ],
    [
        "SOLVAY USA INC.",
        "SOLVAY"
    ],
    [
        "SONICWISE ULTRASONICS",
        "SONICWISE ULTRASONICS"
    ],
    [
        "SPECTRUM CHEMICAL MFG. CORP",
        "SPECTRUM CHEMICAL MFG"
    ],
    [
        "SPEEDY AWARDS AND ENGRAVING, INC.",
        "SPEEDY AWARDS AND ENGRAVING"
    ],
    [
        "SPG USA INC",
        "SPG"
    ],
    [
        "SPHINX WERKZEUGE AG",
        "SPHINX WERKZEUGE"
    ],
    [
        "SPIROL INTERNATIONAL CORPORATION",
        "SPIROL INTERNATIONAL"
    ],
    [
        "SPORER PCS GMBH",
        "SPORER PCS"
    ],
    [
        "SQUIRE SANDERS",
        "SQUIRE SANDERS"
    ],
    [
        "STAR CRANE AND HOIST INC",
        "STAR CRANE AND HOIST"
    ],
    [
        "STAR MICRONICS AG",
        "STAR MICRONICS"
    ],
    [
        "STARRAG GMBH",
        "STARRAG"
    ],
    [
        "STEAM EDUCATION LTD",
        "STEAM EDUCATION"
    ],
    [
        "STEEC SA",
        "STEEC"
    ],
    [
        "STEEGER USA",
        "STEEGER"
    ],
    [
        "STRYKER EMEA SUPPLY CHAIN",
        "STRYKER"
    ],
    [
        "SUMMARY UPDATE",
        "SUMMARY UPDATE"
    ],
    [
        "SUMMIT FILTRATION TECHNOLOGY LLC",
        "SUMMIT FILTRATION TECHNOLOGY"
    ],
    [
        "SUMTER PACKAGING",
        "SUMTER PACKAGING"
    ],
    [
        "SUN PLASTECH INC",
        "SUN PLASTECH"
    ],
    [
        "SUNLITE PLASTICS",
        "SUNLITE PLASTICS"
    ],
    [
        "SURECAL METROLOGY",
        "SURECAL METROLOGY"
    ],
    [
        "SURGICAL PRODUCT",
        "SURGICAL PRODUCT"
    ],
    [
        "SVEA FINANS AG",
        "SVEA FINANS"
    ],
    [
        "SWISSPRO",
        "SWISSPRO"
    ],
    [
        "SYMMETRY MEDICAL",
        "SYMMETRY MEDICAL"
    ],
    [
        "SYNBONE AG",
        "SYNBONE"
    ],
    [
        "T&A TRENNTECHNIK&",
        "T&A TRENNTECHNIK"
    ],
    [
        "TAKUMI PRECISION ENGINEERING LTD",
        "TAKUMI PRECISION ENGINEERING"
    ],
    [
        "TDM SYSTEMS GMBH",
        "TDM SYSTEMS"
    ],
    [
        "TEAM TECHNOLOGIES, INC.*",
        "TEAM TECHNOLOGIES"
    ],
    [
        "IN'TECH MEDICAL",
        "TECH MEDICAL"
    ],
    [
        "TECHNO SUPPORT + SERVICE SA",
        "TECHNO SUPPORT + SERVICE SA"
    ],
    [
        "TEGRA MEDICAL",
        "TEGRA MEDICAL"
    ],
    [
        "TEGRA MEDICAL LLC",
        "TEGRA MEDICAL"
    ],
    [
        "TEKNA",
        "TEKNA"
    ],
    [
        "TELE COMMUNICATION INC",
        "TELE COMMUNICATION"
    ],
    [
        "TELEFLEX MEDICAL OEM",
        "TELEFLEX MEDICAL"
    ],
    [
        "TEMA GMBH TRANSPORT UND LOGISTIK",
        "TEMA TRANSPORT UND LOGISTIK"
    ],
    [
        "TEMSCO N C INC",
        "TEMSCO NC INC"
    ],
    [
        "TETON MACHINE COMPANY",
        "TETON MACHINE COMPANY"
    ],
    [
        "THE DRAUGHTING CENTRE LTD",
        "THE DRAUGHTING CENTRE"
    ],
    [
        "THE EXONE COMPANY",
        "THE EXONE"
    ],
    [
        "THE HITT COMPANIES",
        "THE HITT COMPANIES"
    ],
    [
        "THE KNOTTS CO INC",
        "THE KNOTTS"
    ],
    [
        "THE SOUTH WEST SCHOOL NDT",
        "THE SOUTH WEST SCHOOL NDT"
    ],
    [
        "THE VERNON COMPANY",
        "THE VERNON"
    ],
    [
        "THERMORAT GMBH",
        "THERMORAT"
    ],
    [
        "THERMOTRON INDUSTRIES INC",
        "THERMOTRON INDUSTRIES"
    ],
    [
        "THOMSON LAMINATION CO",
        "THOMSON LAMINATION CO"
    ],
    [
        "THOR FRÄSWERKZEUGE",
        "THOR FRÄSWERKZEUGE"
    ],
    [
        "THORLABS",
        "THORLABS"
    ],
    [
        "TIERRA TECH FRANCE",
        "TIERRA TECH"
    ],
    [
        "TITANIUM INDUSTRIES INC",
        "TITANIUM INDUSTRIES"
    ],
    [
        "TNT EXPRESS  IRL  LTD.,",
        "TNT EXPRESS"
    ],
    [
        "TNT EXPRESS IRELAND LTD",
        "TNT EXPRESS"
    ],
    [
        "TNT EXPRESS WORLDWIDE",
        "TNT EXPRESS"
    ],
    [
        "TOBLER PROTECTA SA",
        "TOBLER PROTECTA"
    ],
    [
        "TOOL TECHNOLOGY",
        "TOOL TECHNOLOGY"
    ],
    [
        "TOSS GMBH & CO. KG",
        "TOSS"
    ],
    [
        "TOURNEAU LLC",
        "TOURNEAU"
    ],
    [
        "TRAUB DREHMASCHINEN",
        "TRAUB DREHMASCHINEN"
    ],
    [
        "TRIANGLE MANUFACTURING CO INC",
        "TRIANGLE MANUFACTURING"
    ],
    [
        "TRICORBRAUN *",
        "TRICORBRAUN"
    ],
    [
        "TRILOGIQ USA",
        "TRILOGIQ"
    ],
    [
        "MAC TROPHEES ZONE ACTIPOLE",
        "TROPHEES ZONE ACTIPOLE"
    ],
    [
        "TRU TECH SYSTEMS",
        "TRU TECH SYSTEMS"
    ],
    [
        "TRUMPCARD INC",
        "TRUMPCARD"
    ],
    [
        "TSI INCORPORATED",
        "TSI INCORPORATED"
    ],
    [
        "TSS FOAM IND CORP",
        "TSS FOAM"
    ],
    [
        "UFP TECHNOLOGIES INC",
        "UFP TECHNOLOGIES"
    ],
    [
        "ULTIMAKER 3DGBIRE",
        "ULTIMAKER 3DGBIRE"
    ],
    [
        "UNIGLOBE KISCO, INC.",
        "UNIGLOBE KISCO"
    ],
    [
        "UNITED PARCEL SERVICES",
        "UNITED PARCEL SERVICES"
    ],
    [
        "UNIVERSAL ELECTRIC",
        "UNIVERSAL ELECTRIC"
    ],
    [
        "UNIVERSAL MACHINE SHOP CORP",
        "UNIVERSAL MACHINE SHOP"
    ],
    [
        "UPF SOUTHWEST LLC",
        "UPF SOUTHWEST"
    ],
    [
        "USC TECHNOLOGIES LLC",
        "USC TECHNOLOGIES"
    ],
    [
        "USF HEALTH PROFESSIONS",
        "USF HEALTH PROFESSIONS"
    ],
    [
        "V.A.TECHNOLOGY LTD.,",
        "V A TECHNOLOGY"
    ],
    [
        "VARGUS",
        "VARGUS"
    ],
    [
        "VENTION MEDICAL ADVANCED COMPONENTS (TN) LLC",
        "VENTION MEDICAL ADVANCED COMPONENTS"
    ],
    [
        "VESTER ELEKTRONIK GMBH",
        "VESTER ELEKTRONIK"
    ],
    [
        "VINTEC GMBH",
        "VINTEC"
    ],
    [
        "VISCHER & BOLLI AG",
        "VISCHER & BOLLI"
    ],
    [
        "VISION ENGINEERING",
        "VISION ENGINEERING"
    ],
    [
        "VISION ENGINEERING INC",
        "VISION ENGINEERING"
    ],
    [
        "VISION ID LTD",
        "VISION ID"
    ],
    [
        "VISIX INC",
        "VISIX"
    ],
    [
        "VISUAL MARKING SYSTEMS, INC",
        "VISUAL MARKING SYSTEMS"
    ],
    [
        "VOEGELE IMMOBILIENGESELLSCHAFT LTD.",
        "VOEGELE IMMOBILIENGESELLSCHAFT"
    ],
    [
        "VORNE",
        "VORNE"
    ],
    [
        "WAGNER TOOLING SYSTEMS",
        "WAGNER TOOLING SYSTEMS"
    ],
    [
        "WALTER (SCHWEIZ) AG",
        "WALTER (SCHWEIZ) AG"
    ],
    [
        "WALTER DUNCKER CNC-SERVICE",
        "WALTER DUNCKER CNC-SERVICE"
    ],
    [
        "WALTER UHL",
        "WALTER UHL"
    ],
    [
        "WARREN DEL CARIBE",
        "WARREN DEL CARIBE"
    ],
    [
        "WATERFORD INSTITUTE OF TECHNOLOGY",
        "WATERFORD INSTITUTE OF TECHNOLOGY"
    ],
    [
        "WB INDUSTRIAL FURNITURE",
        "WB INDUSTRIAL FURNITURE"
    ],
    [
        "WDC PUERTO RICO INC",
        "WDC PUERTO RICO INC"
    ],
    [
        "WEBER INSTRUMENTE GMBH & CO",
        "WEBER INSTRUMENTE"
    ],
    [
        "WEISS UMWELTTECHNIK GMBH",
        "WEISS UMWELTTECHNIK"
    ],
    [
        "WEKA VERLAG AG",
        "WEKA VERLAG"
    ],
    [
        "WENK AG",
        "WENK"
    ],
    [
        "WERMA INTERNATIONAL AG",
        "WERMA INTERNATIONAL"
    ],
    [
        "WERMA SIGNALTECHNIK GMBH & CO.KG",
        "WERMA SIGNALTECHNIK"
    ],
    [
        "WESTERN TOOL AND SUPPLY CO",
        "WESTERN TOOL AND SUPPLY"
    ],
    [
        "WIELAND+OERTLI AG",
        "WIELAND & OERTLI"
    ],
    [
        "WILHELM FELDMANN DRUCKLUFT",
        "WILHELM FELDMANN DRUCKLUFT"
    ],
    [
        "WILSON TOOL INTERNATIONAL",
        "WILSON TOOL INTERNATIONAL"
    ],
    [
        "WINTERHALTER GMBH",
        "WINTERHALTER"
    ],
    [
        "WINTERHALTER STAHL-UND",
        "WINTERHALTER STAHL UND"
    ],
    [
        "WOHLHAUPTER GMBH",
        "WOHLHAUPTER"
    ],
    [
        "WYMED AG",
        "WYMED"
    ],
    [
        "XACT WIRE EDM CORP",
        "XACTWIRE EDM OF ILLINOIS"
    ],
    [
        "XFMRS, INC.",
        "XFMRS"
    ],
    [
        "XYLEM WATER SOLUTIONS",
        "XYLEM WATER SOLUTIONS"
    ],
    [
        "ZAHORANSKY USA, INC. *",
        "ZAHORANSKY"
    ],
    [
        "ZESTRON",
        "ZESTRON"
    ],
    [
        "ZIEGLER & CIE AG",
        "ZIEGLER & CIE"
    ],
    [
        "ZOLLER SARL",
        "ZOLLER"
    ],
    [
        "ZWICK ROELL LTD",
        "ZWICK ROELL"
    ],
    [
        "4 IMPRINT",
        "4 IMPRINT"
    ],
    [
        "ABAKA",
        "ABAKA"
    ],
    [
        "ABC DRUCKEREI AG",
        "ABC DRUCKEREI"
    ],
    [
        "ACCARDA AG - MYONE SERVICE",
        "ACCARDA MYONE SERVICE"
    ],
    [
        "ACE VENDING INC",
        "ACE VENDING INC"
    ],
    [
        "ACTIVE LEISURE ENTERTAINMENT LTD",
        "ACTIVE LEISURE ENTERTAINMENT"
    ],
    [
        "ADARA MIGUEL LAGARES",
        "ADARA MIGUEL LAGARES"
    ],
    [
        "AFFINITY FINANCIAL NETWORK LTD T/A GROUPSCHEMES.COM",
        "AFFINITY FINANCIAL NETWORK"
    ],
    [
        "AHISKA, ALPARSLAN",
        "AHISKA ALPARSLAN"
    ],
    [
        "AILEEN STEINAU",
        "AILEEN STEINAU"
    ],
    [
        "AIR LIQUIDE SANTE FRANCE",
        "AIR LIQUIDE"
    ],
    [
        "AIRPORTHOTEL GRENCHEN",
        "AIRPORTHOTEL GRENCHEN"
    ],
    [
        "ALEAUQUITTIC",
        "ALEAUQUITTIC"
    ],
    [
        "ALEX BLATZ",
        "ALEX BLATZ"
    ],
    [
        "ALGUILERES DHANEL",
        "ALGUILERES DHANEL"
    ],
    [
        "ALPHA OMEGA EVENT",
        "ALPHA OMEGA EVENT"
    ],
    [
        "ANITA VON STADEN",
        "ANITA VON STADEN"
    ],
    [
        "ASOCIASION DE OFICILES DE MESA DE PUERTO RICO",
        "ASOCIASION DE OFICILES DE MESA DE PUERTO RICO"
    ],
    [
        "ASSMANN GMBH",
        "ASSMANN"
    ],
    [
        "ASSOC HUMAN RESOURCE SERV",
        "ASSOC HUMAN RESOURCE SERV"
    ],
    [
        "AZEME AYOB",
        "AZEME AYOB"
    ],
    [
        "BÄCKERFORUM AESCHLIMANN",
        "BACKERFORUM AESCHLIMANN"
    ],
    [
        "BARKASSEN-MEYER",
        "BARKASSEN MEYER"
    ],
    [
        "BARMER GEK",
        "BARMER GEK"
    ],
    [
        "BERNARD MAGREZ LUXURY WINE",
        "BERNARD MAGREZ LUXURY WINE"
    ],
    [
        "BETRIEBSSPORTVERBAND KIEL",
        "BETRIEBSSPORTVERBAND KIEL"
    ],
    [
        "BIELERSEE-GASTRO AG",
        "BIELERSEE GASTRO"
    ],
    [
        "BIELERSEE-SCHIFFFAHRT AG",
        "BIELERSEE SCHIFFFAHRT"
    ],
    [
        "BIKETEC AG",
        "BIKETEC"
    ],
    [
        "BLOOM FLOWER BOUTIQUE",
        "BLOOM FLOWER BOUTIQUE"
    ],
    [
        "BLUMENHAUSE PUCK",
        "BLUMENHAUSE PUCK"
    ],
    [
        "BONTIQUE",
        "BONTIQUE"
    ],
    [
        "BRC.COM 4063",
        "BRC COM"
    ],
    [
        "BRITT KAREN LERBS",
        "BRITT KAREN LERBS"
    ],
    [
        "CASINO DIAMOND",
        "CASINO DIAMOND"
    ],
    [
        "CASTLEMARTYR RESORT",
        "CASTLEMARTYR RESORT"
    ],
    [
        "CATER HIRE LIMERICK LTD",
        "CATER HIRE LIMERICK"
    ],
    [
        "CAYO LINDO",
        "CAYO LINDO"
    ],
    [
        "CHALLENGE ERLEBNISAGENTUR",
        "CHALLENGE ERLEBNISAGENTUR"
    ],
    [
        "CHEN YITIN",
        "CHEN YITIN"
    ],
    [
        "CHOCOLATE FOUNTAINS",
        "CHOCOLATE FOUNTAINS"
    ],
    [
        "CHRISTIAN ARCE RODRIGUEZ",
        "CHRISTIAN ARCE RODRIGUEZ"
    ],
    [
        "CLASS CROUTE",
        "CLASS CROUTE"
    ],
    [
        "COEUR DE BLE BECQUEREL",
        "COEUR BLE BECQUEREL"
    ],
    [
        "COMPAGNIE DES CHEMINS DE",
        "COMPAGNIE DES CHEMINS"
    ],
    [
        "CONCENTRA MEDICAL CENTERS, INC.-PHX",
        "CONCENTRA MEDICAL CENTERS"
    ],
    [
        "CONFIDENT SPEAK",
        "CONFIDENTSPEAK"
    ],
    [
        "CONTINENTAL PASTRIES",
        "CONTINENTAL PASTRIES"
    ],
    [
        "CORK TROPHY CENTRE",
        "CORK TROPHY CENTRE"
    ],
    [
        "COUGHLANS BAR",
        "COUGHLANS BAR"
    ],
    [
        "LA COURONNE HOTEL RESTAURANT",
        "COURONNE HOTEL RESTAURANT"
    ],
    [
        "CREATIVE DINING/STRYKER I",
        "CREATIVE DINING"
    ],
    [
        "CRESPO",
        "CRESPO"
    ],
    [
        "CROWN TROPHY",
        "CROWN TROPHY"
    ],
    [
        "CUCINA ARTE GMBH",
        "CUCINA ARTE"
    ],
    [
        "D'LATINO URBANO GROUP",
        "D LATINO URBANO GROUP"
    ],
    [
        "DAMARIS CORDERO MORALES",
        "DAMARIS CORDERO MORALES"
    ],
    [
        "DAVITT CORPORATE PARTNERS",
        "DAVITT CORPORATE PARTNERS"
    ],
    [
        "DAVITT CORPORATE PARTNERS LTD",
        "DAVITT CORPORATE PARTNERS"
    ],
    [
        "DAYBUS GMBH",
        "DAYBUS"
    ],
    [
        "DIRECT TV LLC",
        "DIRECT TV"
    ],
    [
        "DOLLAR GENERAL #18543",
        "DOLLAR GENERAL"
    ],
    [
        "DREISTADT BEATRICE",
        "DREISTADT BEATRICE"
    ],
    [
        "EL DEPORTIVO RENTAL",
        "EL DEPORTIVO RENTAL"
    ],
    [
        "ELLERBEKER TURNVEREIN",
        "ELLERBEKER TURNVEREIN"
    ],
    [
        "EMLYN AVILES DBA MIS RECUERDOS",
        "EMLYN AVILES MIS RECUERDOS"
    ],
    [
        "ETS PECHE DECOUVERTE BASSIN",
        "ETS PECHE DECOUVERTE BASSIN"
    ],
    [
        "ETUKTUK SCHWEIZ GMBH",
        "ETUKTUK"
    ],
    [
        "EUROBUS WELTI-FURRER AG",
        "EUROBUS WELTI FURRER"
    ],
    [
        "EVENTSPIELE SCHWEIZ AG",
        "EVENTSPIELE"
    ],
    [
        "EXALAB",
        "EXALAB"
    ],
    [
        "EXTREME GRAPHICS INC",
        "EXTREME GRAPHICS"
    ],
    [
        "F&S GIFT CARD",
        "F&S GIFT CARD"
    ],
    [
        "FELDHUS",
        "FELDHUS"
    ],
    [
        "FIZ FITNESSTUDIO KIEL",
        "FIZ FITNESSTUDIO KIEL"
    ],
    [
        "FLAUGER, GERRIT",
        "FLAUGER GERRIT"
    ],
    [
        "FLEUROP-INTERFLORA",
        "FLEUROP INTERFLORA"
    ],
    [
        "FLOWER POWER FLORIST",
        "FLOWER POWER FLORIST"
    ],
    [
        "FORSTBETRIEB LEBERBERG",
        "FORSTBETRIEB LEBERBERG"
    ],
    [
        "FOTRA GMBH",
        "FOTRA"
    ],
    [
        "FRIDAS MEXICAN RESTAURANT",
        "FRIDAS MEXICAN RESTAURANT"
    ],
    [
        "FULL MOON HOTELS, INC",
        "FULL MOON HOTELS"
    ],
    [
        "FZ NETWORK PROMOTION",
        "FZ NETWORK PROMOTION"
    ],
    [
        "GABRIEL ENGRAVING",
        "GABRIEL ENGRAVING"
    ],
    [
        "GASTRONOMIA CARIBE INC/DBA ZAFRA DEL CARIBE",
        "GASTRONOMIA CARIBE ZAFRA DEL CARIBE"
    ],
    [
        "GELATERIA BAR ITALIA GMBH",
        "GELATERIA BAR"
    ],
    [
        "GIL, FERNANDO",
        "GIL FERNANDO"
    ],
    [
        "GLANMIRE ICES",
        "GLANMIRE ICES"
    ],
    [
        "GMB EXECUTIVE SEARCH",
        "GMB EXECUTIVE SEARCH"
    ],
    [
        "GOLF & LANDCLUB GUT",
        "GOLF & LANDCLUB GUT"
    ],
    [
        "AU GRE DES SAVEURS",
        "GRE DES SAVEURS"
    ],
    [
        "GROWA MARKT",
        "GROWA MARKT"
    ],
    [
        "GRUFENEDER BELINDA",
        "GRUFENEDER BELINDA"
    ],
    [
        "HACER",
        "HACER"
    ],
    [
        "HAFLINGER ZENTRUM AG",
        "HAFLINGER ZENTRUM"
    ],
    [
        "HB PRÄSENTATIONSSYSTEME",
        "HB PRASENTATIONSSYSTEME"
    ],
    [
        "HENRIK KRΣLING",
        "HENRIK KRΣLING"
    ],
    [
        "HENRY SCHEIN PUERTO RICO INC",
        "HENRY SCHEIN"
    ],
    [
        "HERGISWILER GLAS AG",
        "HERGISWILER GLAS"
    ],
    [
        "HOLGER FISCHER",
        "HOLGER FISCHER"
    ],
    [
        "IBEC - SFA",
        "IBEC SFA"
    ],
    [
        "IMPRINT MARKETING",
        "IMPRINT MARKETING"
    ],
    [
        "IN SITU",
        "IN SITU"
    ],
    [
        "INCOMM PUERTO RICO",
        "INCOMM PUERTO RICO"
    ],
    [
        "INDUSTRIAL ACOUSTICSL LTD",
        "INDUSTRIAL ACOUSTICSL"
    ],
    [
        "INTELISPEND PREPAID SOL 2",
        "INTELISPEND PREPAID SOL 2"
    ],
    [
        "INTERTRADE DEVELOPMENT GROUP INC",
        "INTERTRADE DEVELOPMENT GROUP"
    ],
    [
        "IRIS L MORALES SANTIAGO",
        "IRIS L MORALES SANTIAGO"
    ],
    [
        "IRISH JOBS.IE",
        "IRISH JOBS"
    ],
    [
        "IRISH RED CROSS",
        "IRISH RED CROSS"
    ],
    [
        "IRMA PETRA JAPPE",
        "IRMA PETRA JAPPE"
    ],
    [
        "JACOBSEN, INGRID",
        "JACOBSEN INGRID"
    ],
    [
        "JOSCELYN INC",
        "JOSCELYN"
    ],
    [
        "JOSE L. CRUZ CURZ",
        "JOSE L CRUZ CURZ"
    ],
    [
        "JOSE O RODRIGUEZ SOTO",
        "JOSE O RODRIGUEZ SOTO"
    ],
    [
        "JUNIOR ACHIEVEMENT IRELAND",
        "JUNIOR ACHIEVEMENT"
    ],
    [
        "JUNIOR ACHIEVEMENT",
        "JUNIOR ACHIEVEMENT"
    ],
    [
        "KALAMAZOO BASEBALL LLC",
        "KALAMAZOO BASEBALL"
    ],
    [
        "KÄSEREI EYWEID AG",
        "KASEREI EYWEID"
    ],
    [
        "KISTENMACHER, H.",
        "KISTENMACHER, H."
    ],
    [
        "KM-WERBEMITTEL GMBH",
        "KM WERBEMITTEL"
    ],
    [
        "KOCHER, FABIAN",
        "KOCHER FABIAN"
    ],
    [
        "KOLIC, EDIN",
        "KOLIC EDIN"
    ],
    [
        "KONRAD VOGT",
        "KONRAD VOGT"
    ],
    [
        "KRAMPFER OLAF",
        "KRAMPFER OLAF"
    ],
    [
        "KVG KIELER VERKEHRS-",
        "KVG KIELER VERKEHRS"
    ],
    [
        "L37 CREATIVE",
        "L37 CREATIVE"
    ],
    [
        "LANDAUER",
        "LANDAUER"
    ],
    [
        "LARS HAMM",
        "LARS HAMM"
    ],
    [
        "LAURA RODRIGUEZ",
        "LAURA RODRIGUEZ"
    ],
    [
        "LAURIE LIZ PHOTOGRAPHY",
        "LAURIE LIZ PHOTOGRAPHY"
    ],
    [
        "LAYA HEALTHCARE",
        "LAYA HEALTHCARE"
    ],
    [
        "LE TEMPS DU REVE",
        "LE TEMPS DU REVE"
    ],
    [
        "LEANCOM GMBH",
        "LEANCOM"
    ],
    [
        "LEGAL ISLAND",
        "LEGAL"
    ],
    [
        "LIECAU, ADAM",
        "LIECAU ADAM"
    ],
    [
        "LOCKLEY, SIMON",
        "LOCKLEY, SIMON"
    ],
    [
        "MADELINE GUILFU DIAZ",
        "MADELINE GUILFU DIAZ"
    ],
    [
        "PUERTO RICO MANUFACTURERS",
        "MANUFACTURERS"
    ],
    [
        "MARCEL PELKA",
        "MARCEL PELKA"
    ],
    [
        "MARCO TAUTZ",
        "MARCO TAUTZ"
    ],
    [
        "MARIOS PIZZA",
        "MARIOS PIZZA"
    ],
    [
        "MARTIN DOMMEL",
        "MARTIN DOMMEL"
    ],
    [
        "MAYO CLINIC",
        "MAYO CLINIC"
    ],
    [
        "MEDLAB PATHOLOGY",
        "MEDLAB PATHOLOGY"
    ],
    [
        "MENZE MANFRED",
        "MENZE MANFRED"
    ],
    [
        "METRO BOWL, INC.",
        "METRO BOWL"
    ],
    [
        "MEYTA CASA PRODUCTORA",
        "MEYTA CASA PRODUCTORA"
    ],
    [
        "MICHAEL SEEMANN",
        "MICHAEL SEEMANN"
    ],
    [
        "MISCHLER STEFAN",
        "MISCHLER STEFAN"
    ],
    [
        "MULTI SERVICE TECHNOLOGY SOLUTIONC INC",
        "MULTI SERVICE TECHNOLOGY SOLUTION"
    ],
    [
        "MURAT BADEM",
        "MURAT BADEM"
    ],
    [
        "MUSGRAVE CASH & CARRY",
        "MUSGRAVE CASH & CARRY"
    ],
    [
        "MUSGRAVE LTD.,CASH & CAR",
        "MUSGRAVE LTD.,CASH & CAR"
    ],
    [
        "NETWORK PROMOTION",
        "NETWORK PROMOTION"
    ],
    [
        "NICOLE ROELL",
        "NICOLE ROELL"
    ],
    [
        "NORMA GOMEZ RODRIGUEZ",
        "NORMA GOMEZ RODRIGUEZ"
    ],
    [
        "SUDDATH VAN LINES, INC. *",
        "NULL"
    ],
    [
        "OCEAN DRIVE",
        "OCEAN DRIVE"
    ],
    [
        "OMNIPLEX CORK LIMITED",
        "OMNIPLEX CORK"
    ],
    [
        "ON THE BORDER",
        "ON THE BORDER"
    ],
    [
        "ORGANIZACION DE ARBITROS DE BALONCESTO",
        "ORGANIZACION DE ARBITROS DE BALONCESTO"
    ],
    [
        "OTTO, FRANK",
        "OTTO, FRANK"
    ],
    [
        "PALAIS BESENVAL",
        "PALAIS BESENVAL"
    ],
    [
        "LA PALETERIA LLC",
        "PALETERIA"
    ],
    [
        "PANADERIA ALMEDA",
        "PANADERIA ALMEDA"
    ],
    [
        "PARTY DESIGN",
        "PARTY DESIGN"
    ],
    [
        "PAT MCDONNELL LTD",
        "PAT MCDONNELL"
    ],
    [
        "PATRICK POLST",
        "PATRICK POLST"
    ],
    [
        "PAUSTIAN, DIRK",
        "PAUSTIAN DIRK"
    ],
    [
        "PAVEL HˆRGER‰TE GMBH",
        "PAVEL HˆRGER‰TE"
    ],
    [
        "PAVEL H÷RGERΣTE GMBH",
        "PAVEL HˆRGER‰TE"
    ],
    [
        "PELLE HANSEN",
        "PELLE HANSEN"
    ],
    [
        "PETROLSTATION GASTRO GMBH",
        "PETROLSTATION GASTRO"
    ],
    [
        "PFARREIZENTRUM",
        "PFARREIZENTRUM"
    ],
    [
        "PHARMACIE DE CESTAS",
        "PHARMACIE DE CESTAS"
    ],
    [
        "PHARMACIE DE LA GARE",
        "PHARMACIE DE LA GARE"
    ],
    [
        "PIETRZAK",
        "PIETRZAK"
    ],
    [
        "PISKE, AXEL",
        "PISKE AXEL"
    ],
    [
        "PIZZERIA FANTASIA",
        "PIZZERIA FANTASIA"
    ],
    [
        "PLECHATY IVANA",
        "PLECHATY IVANA"
    ],
    [
        "PONCE HILTON",
        "PONCE HILTON"
    ],
    [
        "PRALINO AG",
        "PRALINO"
    ],
    [
        "PRESSURE HYDRAULICS CORK",
        "PRESSURE HYDRAULICS CORK"
    ],
    [
        "PRINT ZONE PR",
        "PRINT ZONE"
    ],
    [
        "PRO VELO SCHWEIZ",
        "PRO VELO"
    ],
    [
        "PUERTO RICO IND HEALTH",
        "PUERTO RICO IND HEALTH"
    ],
    [
        "QUEST DIAGNOSTIC",
        "QUEST DIAGNOSTIC"
    ],
    [
        "RADISSON BLU HOTEL",
        "RADISSON BLU HOTEL"
    ],
    [
        "RAFAEL A LECUMBERRI BURGOS",
        "RAFAEL A LECUMBERRI BURGOS"
    ],
    [
        "RAHEEN PHYSIOTHERAPY",
        "RAHEEN PHYSIOTHERAPY"
    ],
    [
        "RAMONA JENSEN",
        "RAMONA JENSEN"
    ],
    [
        "RED WING SHOES",
        "RED WING SHOES"
    ],
    [
        "REMIEN, HANS-JOACHIM",
        "REMIEN HANS JOACHIM"
    ],
    [
        "RESTAURANT GOLFCLUB",
        "RESTAURANT GOLFCLUB"
    ],
    [
        "RESTAURANTE HECHO EN CASA",
        "RESTAURANTE HECHO EN CASA"
    ],
    [
        "RETIREMENT PLANNING COUN.",
        "RETIREMENT PLANNING COUN"
    ],
    [
        "RICHMOND TWP FIRE PROTECTION DISTRICT",
        "RICHMOND TWP FIRE PROTECTION DISTRICT"
    ],
    [
        "RISTORANTE AL CANTUCCIO",
        "RISTORANTE CANTUCCIO"
    ],
    [
        "ROTH BEAT",
        "ROTH BEAT"
    ],
    [
        "RÖTHLISBERGER MARC",
        "ROTHLISBERGER"
    ],
    [
        "RÖTHLISBERGER GMBH",
        "ROTHLISBERGER"
    ],
    [
        "SANDBERGEN SIMONA",
        "SANDBERGEN SIMONA"
    ],
    [
        "SCHAFFER, KATJA",
        "SCHAEFFER MFG. COMPANY"
    ],
    [
        "SCHLEMMER-MARKT FREUND",
        "SCHLEMMER MARKT FREUND"
    ],
    [
        "SCHMIDTS TIVOLI THEATER",
        "SCHMIDTS TIVOLI THEATER"
    ],
    [
        "SCHMITT, CHRISTOPH",
        "SCHMITT CHRISTOPH"
    ],
    [
        "SCHNEIDER REISEN",
        "SCHNEIDER REISEN"
    ],
    [
        "SEILBAHN WEISSENSTEIN AG",
        "SEILBAHN WEISSENSTEIN"
    ],
    [
        "SERIGRAPHIE ULDRY AG",
        "SERIGRAPHIE ULDRY"
    ],
    [
        "SHANNON HERITAGE",
        "SHANNON HERITAGE"
    ],
    [
        "SHANNON HOTEL SUPPLIES,",
        "SHANNON HOTEL SUPPLIES"
    ],
    [
        "SIGNZ INC.",
        "SIGNZ SALT LAKE CITY"
    ],
    [
        "SOHO BAR & RESTAURANT",
        "SOHO BAR & RESTAURANT"
    ],
    [
        "SPECSAVERS OPTICAL SUPER",
        "SPECSAVERS OPTICAL SUPER"
    ],
    [
        "SPECTRUM AV",
        "SPECTRUM AV"
    ],
    [
        "SPRINKLE ROAD TAPHOUSE",
        "SPRINKLE ROAD TAPHOUSE"
    ],
    [
        "STADT- UND GEWERBEVER-",
        "STADT UND GEWERBEVER"
    ],
    [
        "STARDRINKS AG/HEINEKEN",
        "STARDRINKS HEINEKEN"
    ],
    [
        "STEFFEN DAMKE",
        "STEFFEN DAMKE"
    ],
    [
        "STEPHAN KUX",
        "STEPHAN KUX"
    ],
    [
        "STETTLER ERLACH",
        "STETTLER ERLACH"
    ],
    [
        "SUCCESSORIES",
        "SUCCESSORIES"
    ],
    [
        "SUTERIA CHOCOLATA AG",
        "SUTERIA CHOCOLATA"
    ],
    [
        "SVEN SCHADWALD",
        "SVEN SCHADWALD"
    ],
    [
        "TALINGO EAP GMBH",
        "TALINGO EAP"
    ],
    [
        "TARGET T0604",
        "TARGET T0604"
    ],
    [
        "TAXI SEBASTIEN",
        "TAXI SEBASTIEN"
    ],
    [
        "TAYLOR CORP",
        "TAYLOR"
    ],
    [
        "TAYLOR RENTAL CENTER",
        "TAYLOR RENTAL CENTER"
    ],
    [
        "TENNISPARK WELLSEE",
        "TENNISPARK WELLSEE"
    ],
    [
        "THE EVENT PLANNER",
        "THE EVENT PLANNER"
    ],
    [
        "THE HAIR CLINIC SALON",
        "THE HAIR CLINIC SALON"
    ],
    [
        "THE KINGSLEY HOTEL",
        "THE KINGSLEY HOTEL"
    ],
    [
        "THE LOUNGE EVENTS FURNITURE RENTAL INC",
        "THE LOUNGE EVENTS FURNITURE RENTAL"
    ],
    [
        "THE OPEN COLLEGE",
        "THE OPEN COLLEGE"
    ],
    [
        "TINA FREYER",
        "TINA FREYER"
    ],
    [
        "TIPPERARY MINERAL WATER",
        "TIPPERARY MINERAL WATER"
    ],
    [
        "TLF VANDERSALMS FLOWER SH",
        "TLF VANDERSALMS FLOWER SH"
    ],
    [
        "TSCHEPPACH'S EVENT-GASTHOF",
        "TSCHEPPACH'S EVENT-GASTHOF"
    ],
    [
        "TURBEN GARAGE",
        "TURBEN GARAGE"
    ],
    [
        "UNION DES BATELIERS",
        "UNION DES BATELIERS"
    ],
    [
        "URBAN SCHIESS",
        "URBAN SCHIESS"
    ],
    [
        "VANDERSALMS FLOWER SHOP",
        "VANDERSALMS FLOWER SHOP"
    ],
    [
        "VDU VERBAND DEUTSCHER",
        "VDU VERBAND DEUTSCHER"
    ],
    [
        "VFZ SELZACH VEREINIGTEN",
        "VFZ SELZACH VEREINIGTEN"
    ],
    [
        "VIOLA UNITED METHODIST CHURCH, INC.",
        "VIOLA UNITED METHODIST CHURCH"
    ],
    [
        "WALGREENS 10080",
        "WALGREENS"
    ],
    [
        "WALGREENS 10081",
        "WALGREENS"
    ],
    [
        "WALKER HOTELLERIE AG",
        "WALKER HOTELLERIE AG"
    ],
    [
        "WEBER ROMAN",
        "WEBER ROMAN"
    ],
    [
        "WERO-MEDICAL",
        "WERO MEDICAL"
    ],
    [
        "WESTERN MICHIGAN",
        "WESTERN MICHIGAN"
    ],
    [
        "WISER PRODUCTIONS, INC.",
        "WISER PRODUCTIONS"
    ],
    [
        "WISLER MARC",
        "WISLER MARC"
    ],
    [
        "WORK LOSS MANAGEMENT, INC.",
        "WORK LOSS MANAGEMENT"
    ],
    [
        "YOUR YOGA HEALTH",
        "YOUR YOGA HEALTH"
    ],
    [
        "ZESTE ET PAPILLES",
        "ZESTE PAPILLES"
    ],
    [
        "ZIPPEL┤S LAEUFERWELT",
        "ZIPPEL┤S LAEUFERWELT"
    ],
    [
        "SODEXO IRELAND LTD.",
        "SODEXO"
    ],
    [
        "CRITT",
        "CRITT"
    ],
    [
        "CRITT CTRE RÉG. INOOV TRANSF",
        "CRITT"
    ],
    [
        "CRITT NOGENT",
        "CRITT"
    ],
    [
        "GEOMETRICS RESULTS INC",
        "GEOMETRICS RESULTS"
    ],
    [
        "MEDIAL GROUP INC",
        "MEDIAL GROUP"
    ],
    [
        "ULTIMATE SOLUTIONS CORP",
        "ULTIMATE SOLUTIONS"
    ],
    [
        "AIR LIQUIDE DEUTSCHLAND GMBH",
        "AIR LIQUIDE"
    ],
    [
        "AIRGAS SOUTH, INC.",
        "AIRGAS"
    ],
    [
        "AIRGAS GREAT LAKES",
        "AIRGAS"
    ],
    [
        "AIRGAS NORTH CENTRAL-",
        "AIRGAS"
    ],
    [
        "AIRGAS SOUTHWEST",
        "AIRGAS"
    ],
    [
        "AIRGAS, INC. - SALT LAKE",
        "AIRGAS"
    ],
    [
        "BOC GASES IRELAND LTD.",
        "BOC GASES"
    ],
    [
        "BOC GASES IRELAND LTD.,",
        "BOC GASES"
    ],
    [
        "CARBAGAS",
        "CARBAGAS"
    ],
    [
        "COSERV GAS LTD.",
        "COSERV GAS"
    ],
    [
        "INDIANA GAS COMPANY INC",
        "INDIANA GAS"
    ],
    [
        "INFINITE ENERGY INC",
        "INFINITE ENERGY"
    ],
    [
        "TECO PEOPLE'S GAS, INC.",
        "TECO PEOPLE S GAS"
    ],
    [
        "PRAXAIR DISTRIBUTION INC",
        "PRAXAIR"
    ],
    [
        "PRAXAIR SURFACE TECHNOLOGIES INC",
        "PRAXAIR"
    ],
    [
        "PRAXAIR",
        "PRAXAIR"
    ],
    [
        "PRAXAIR DIST INC 70709",
        "PRAXAIR"
    ],
    [
        "PRAXAIR DIST US 70709",
        "PRAXAIR"
    ],
    [
        "PRAXAIR PUERTO RICO INC",
        "PRAXAIR"
    ],
    [
        "ATLANTIC TOMORROWS OFFICE",
        "ATLANTIC TOMORROWS OFFICE"
    ],
    [
        "AUTOMATED SYSTEMS, INC.",
        "AUTOMATED SYSTEMS, INC."
    ],
    [
        "AUTOMATIC DATA PROCESSING",
        "AUTOMATIC DATA PROCESSING"
    ],
    [
        "COMPUTER INTEGRATION",
        "COMPUTER INTEGRATION"
    ],
    [
        "COMPUTER PLACEMENT LTD",
        "COMPUTER PLACEMENT"
    ],
    [
        "COMPUTER PLACEMENT.LTD",
        "COMPUTER PLACEMENT"
    ],
    [
        "DIGITAL DOCUMENTS LTD",
        "DIGITAL DOCUMENTS"
    ],
    [
        "DOCSTREAM GMBH",
        "DOCSTREAM"
    ],
    [
        "ERGO SERVICES LTD",
        "ERGO SERVICES"
    ],
    [
        "HEWLETT PACKARD INTE.PLC",
        "HEWLETT PACKARD INTE"
    ],
    [
        "HUGO HAMANN GMBH & CO. KG",
        "HUGO HAMANN"
    ],
    [
        "IBM IRELAND LTD",
        "IBM"
    ],
    [
        "MANUTEC LTD.,",
        "MANUTEC"
    ],
    [
        "MULTI SYSTEMS",
        "MULTI SYSTEMS"
    ],
    [
        "PFH COMPUTERS,",
        "PFH TECHNOLOGY GROUP"
    ],
    [
        "PFH TECHNOLOGY GROUP",
        "PFH TECHNOLOGY GROUP"
    ],
    [
        "PFH COMPUTERS",
        "PFH TECHNOLOGY GROUP IE"
    ],
    [
        "QUINX AG",
        "QUINX"
    ],
    [
        "SAFARI CIRCUITS INC",
        "SAFARI CIRCUITS"
    ],
    [
        "SAFARI CIRCUITS, INC",
        "SAFARI CIRCUITS"
    ],
    [
        "SAFARI CIRCUITS, INC.",
        "SAFARI CIRCUITS"
    ],
    [
        "SL CONTROLS LTD",
        "SL CONTROLS"
    ],
    [
        "SWISSCOM (SCHWEIZ) AG",
        "SWISSCOM"
    ],
    [
        "SWISSCOM SUISSE SA CORPO",
        "SWISSCOM"
    ],
    [
        "SWISSCOM SUISSE SA MOBIL",
        "SWISSCOM"
    ],
    [
        "SWISSCOM SUISSE SA",
        "SWISSCOM"
    ],
    [
        "SWISSCOM (SCHWEIZ) AG USD",
        "SWISSCOM"
    ],
    [
        "TRIANGLE COMPUTER SERVICE",
        "TRIANGLE COMPUTER SERVICE"
    ],
    [
        "WUXI APPTEC",
        "WUXI APPTEC"
    ],
    [
        "WUXI APPTEC SALES, LLC",
        "WUXI APPTEC"
    ],
    [
        "WUXI APPTEC, INC",
        "WUXI APPTEC"
    ],
    [
        "WUXI",
        "WUXI APPTEC"
    ],
    [
        "VIA DIRECT",
        "VIA DIRECT"
    ],
    [
        "DELL WORD TRADE L P",
        "DELL"
    ],
    [
        "DELL IRELAND",
        "DELL"
    ],
    [
        "DELL INCORPORATED",
        "DELL"
    ],
    [
        "DELL",
        "DELL"
    ],
    [
        "DELL MARKETING LP",
        "DELL"
    ],
    [
        "DELL COMPUTER CORP.",
        "DELL"
    ],
    [
        "DELL GMBH",
        "DELL"
    ],
    [
        "DELL (IRELAND) LTD",
        "DELL"
    ],
    [
        "DELL COMPUTER CORP",
        "DELL"
    ],
    [
        "DELL MARKETING L.P.",
        "DELL"
    ],
    [
        "DELL SA",
        "DELL"
    ],
    [
        "SANMINA CORP",
        "SANMINA"
    ],
    [
        "SANMINA CORPORATION",
        "SANMINA"
    ],
    [
        "BELL MARK",
        "BELL MARK"
    ],
    [
        "CANON (SCHWEIZ) AG",
        "CANON"
    ],
    [
        "IMPRESSION PRODUCTS INC",
        "IMPRESSION PRODUCTS"
    ],
    [
        "MARKJET INC.",
        "MARKJET"
    ],
    [
        "RICOH DEUTSCHLAND GMBH",
        "RICOH"
    ],
    [
        "RICOH SCHWEIZ AG",
        "RICOH"
    ],
    [
        "RICOH USA, INC",
        "RICOH"
    ],
    [
        "IRON MOUNTAIN (SCHWEIZ) AG",
        "IRON MOUNTAIN"
    ],
    [
        "IRON MOUNTAIN",
        "IRON MOUNTAIN"
    ],
    [
        "IRON MOUNTAIN FRANCE",
        "IRON MOUNTAIN"
    ],
    [
        "IRON MOUNTAIN (WAS RECORD",
        "IRON MOUNTAIN WAS RECORD"
    ],
    [
        "OFFSITE RECORD MGT LLC",
        "OFFSITE RECORD MGT"
    ],
    [
        "ALLGEIER MIDMARKET SERVICES GMBH",
        "ALLGEIER MIDMARKET SERVICES"
    ],
    [
        "FERCHAU GMBH",
        "FERCHAU"
    ],
    [
        "ALPHABET",
        "ALPHABET"
    ],
    [
        "CF-TOOLS",
        "CF TOOLS"
    ],
    [
        "APTEAN, INC.",
        "APTEAN"
    ],
    [
        "APTEAN INC.",
        "APTEAN"
    ],
    [
        "APTEAN INC",
        "APTEAN"
    ],
    [
        "BABTEC INFORMATIONSSYSTEME",
        "BABTEC INFORM.SYSTEME"
    ],
    [
        "BABTEC INFORM.SYSTEME GMBH",
        "BABTEC INFORM.SYSTEME"
    ],
    [
        "BLUJAY SOLUTIONS INC",
        "BLUJAY SOLUTIONS"
    ],
    [
        "CADENCE INC",
        "CADENCE"
    ],
    [
        "CADENCE, INC.*",
        "CADENCE"
    ],
    [
        "COMPUTER INTEGRATION OF QUALITY ASSURANCE",
        "COMPUTER INTEGRATION OF QUALITY ASSURANCE"
    ],
    [
        "COREHR",
        "COREHR"
    ],
    [
        "ESI UK LTD",
        "ESI"
    ],
    [
        "FINESOLUTIONS AG",
        "FINE SOLUTIONS"
    ],
    [
        "FINE SOLUTIONS AG",
        "FINE SOLUTIONS"
    ],
    [
        "GERBER TECHNOLOGY, LLC",
        "GERBER TECHNOLOGY"
    ],
    [
        "INFOR GLOBAL SOLUTIONS INC",
        "INFOR"
    ],
    [
        "INFOR (US) INC",
        "INFOR"
    ],
    [
        "INFOR (FRANCE) SAS",
        "INFOR"
    ],
    [
        "INNEO SOLUTIONS GMBH",
        "INNEO SOLUTIONS"
    ],
    [
        "INNOVATIVE CAM SA",
        "INNOVATIVE CAM"
    ],
    [
        "LABVANTAGE SOLUTIONS *",
        "LABVANTAGE SOLUTIONS"
    ],
    [
        "MATERIALISE NV",
        "MATERIALISE"
    ],
    [
        "MATERIALISE USA",
        "MATERIALISE"
    ],
    [
        "MATERIALISE UK LTD",
        "MATERIALISE"
    ],
    [
        "MPDV MIKROLAB GMBH",
        "MPDV MIKROLAB"
    ],
    [
        "QUANTUM CONCEPTS INC",
        "QUANTUM CONCEPTS"
    ],
    [
        "FA. SCHÜBER INGENIEURE GMBH",
        "SCHÜBER INGENIEURE"
    ],
    [
        "SIMCO ELECTRINOCS",
        "SIMCO ELECTRINOCS"
    ],
    [
        "SIMCO ELECTRONICS",
        "SIMCO ELECTRONICS"
    ],
    [
        "SIMCO ELECTRONICS - DRAPE",
        "SIMCO ELECTRONICS"
    ],
    [
        "TEAM SUPPORT SERVICES LLC",
        "TEAM SUPPORT SERVICES LLC"
    ],
    [
        "APPLE DISTRIBUTION",
        "APPLE"
    ],
    [
        "APPLE RETAIL FRANCE E.U.R.L",
        "APPLE"
    ],
    [
        "ATANDT",
        "ATANDT"
    ],
    [
        "ORANGE BUSINESS BLOIS",
        "ORANGE BUSINESS"
    ],
    [
        "ORANGE BUSINESS TOULOUSE",
        "ORANGE BUSINESS"
    ],
    [
        "SFR",
        "SFR"
    ],
    [
        "TELEKOM DEUTSCHLAND  GMBH",
        "TELEKOM DEUTSCHLAND"
    ],
    [
        "VODAFONE",
        "VODAFONE"
    ],
    [
        "B1",
        "B1"
    ],
    [
        "BERCHTOLD GBMH & CO. KG",
        "BERCHTOLD GBMH"
    ],
    [
        "BUNDESKASSE TRIER",
        "BUNDESKASSE TRIER"
    ],
    [
        "STRYKER EUROPEAN OPERATIONS",
        "STRYKER"
    ],
    [
        "STRYKER CORPORATION",
        "STRYKER"
    ],
    [
        "STRYKER GMBH",
        "STRYKER"
    ],
    [
        "STRYKER WEST VALLEY",
        "STRYKER"
    ],
    [
        "CLUB DES LOISIRS / STRYKER",
        "STRYKER"
    ],
    [
        "STRYKER MEDICAL TECH. CO.LTD",
        "STRYKER"
    ],
    [
        "STRYKER SUZHOU MEDICAL TECH.",
        "STRYKER"
    ],
    [
        "STRYKER CORPORATE",
        "STRYKER"
    ],
    [
        "STRYKER EMEA SUPPLY CHAIN SERVICES B.V.",
        "STRYKER"
    ],
    [
        "STRYKER GLOBAL QUALITY & OPS HQ",
        "STRYKER"
    ],
    [
        "STRYKER GLOBAL TECHNOLOGY CENTER PRIVATE LTD INDIA STC",
        "STRYKER"
    ],
    [
        "STRYKER GMBH & CO. KG",
        "STRYKER"
    ],
    [
        "STRYKER GQO CDC",
        "STRYKER"
    ],
    [
        "STRYKER HOWMEDICA OSTEONI",
        "STRYKER"
    ],
    [
        "STRYKER HOWMEDICA, MAHWAH",
        "STRYKER"
    ],
    [
        "STRYKER INSTRUMENTS",
        "STRYKER"
    ],
    [
        "STRYKER INSTRUMENTS KZOO",
        "STRYKER"
    ],
    [
        "STRYKER IRELAND LTD",
        "STRYKER"
    ],
    [
        "STRYKER LEIBINGER NEEDLE",
        "STRYKER"
    ],
    [
        "STRYKER LIMERICK",
        "STRYKER"
    ],
    [
        "STRYKER SPINE SAS",
        "STRYKER"
    ],
    [
        "STRYKER EUROPEAN OP. BV",
        "STRYKER"
    ],
    [
        "STRYKER SPINE S.A.S",
        "STRYKER"
    ],
    [
        "STRYKER SPINE",
        "STRYKER"
    ],
    [
        "STRYKER",
        "STRYKER"
    ],
    [
        "STRYKER GRUNDST¸CKS",
        "STRYKER"
    ],
    [
        "STRYKER GRUNDSTⁿCKS",
        "STRYKER"
    ],
    [
        "STRYKER EUROPEAN OPS LTD",
        "STRYKER"
    ],
    [
        "STRYKER HEALTHCARE PDTS",
        "STRYKER"
    ],
    [
        "STRYKER NV OPERATIONS",
        "STRYKER NV OPERATIONS"
    ],
    [
        "US CUSTOMS & BORDER",
        "CUSTOMS & BORDER PROTECTION"
    ],
    [
        "US CUSTOMS & BORDER PROTECTION",
        "CUSTOMS & BORDER PROTECTION"
    ],
    [
        "AIRSPEED TELECOM",
        "AIRSPEED TELECOM"
    ],
    [
        "AMAZONPRIME MEMBERSHIP",
        "AMAZON"
    ],
    [
        "CESPT",
        "CESPT"
    ],
    [
        "CHAMBER OF COMMERCE LIMK",
        "CHAMBER OF COMMERCE LIMK"
    ],
    [
        "CRISTO RAY SAN JOSE WORK STUDY PROGRAM",
        "CRISTO RAY SAN JOSE"
    ],
    [
        "HYDRACAL",
        "HYDRACAL"
    ],
    [
        "HYDRACAL LTD",
        "HYDRACAL"
    ],
    [
        "IBEC",
        "IBEC"
    ],
    [
        "MID WEST TYRES.",
        "MID WEST TYRES"
    ],
    [
        "MISCO",
        "MISCO"
    ],
    [
        "MISCO IRELAND",
        "MISCO"
    ],
    [
        "ORTHOPLASTICS",
        "ORTHOPLASTICS"
    ],
    [
        "ORTHOPLASTICS LTD.",
        "ORTHOPLASTICS"
    ],
    [
        "TOWN & COUNTRY OFFICE CLE",
        "TOWN & COUNTRY OFFICE CLE"
    ],
    [
        "TUV RHEINLAND",
        "TUV RHEINLAND"
    ],
    [
        "TUV RHEINLAND INC",
        "TUV RHEINLAND"
    ],
    [
        "TUV RHEINLAND OF NORTH",
        "TUV RHEINLAND"
    ],
    [
        "ALD AUTOMOTIVE",
        "ALD AUTOMOTIVE"
    ],
    [
        "ALPHABET FUHRPARKMANAGEMENT",
        "ALPHABET FLEET MGMT"
    ],
    [
        "ALPHABET FRANCE FLEET MGMT",
        "ALPHABET FLEET MGMT"
    ],
    [
        "ARVAL",
        "ARVAL"
    ],
    [
        "ATHLON GERMANY GMBH",
        "ATHLON"
    ],
    [
        "LEASE PLAN",
        "LEASE PLAN"
    ],
    [
        "AEBERHARD CHRISTOPH",
        "AEBERHARD CHRISTOPH"
    ],
    [
        "ALTERMATT MARCEL",
        "ALTERMATT MARCEL"
    ],
    [
        "AMAG LEASING AG",
        "AMAG"
    ],
    [
        "AMAG AUTOMOBIL- UND",
        "AMAG"
    ],
    [
        "ARIC, GÖKCE",
        "ARIC, GÖKCE"
    ],
    [
        "AUTO-BERGER",
        "AUTO BERGER"
    ],
    [
        "BECAK, LUKAS",
        "BECAK LUKAS"
    ],
    [
        "BORN RETO",
        "BORN RETO"
    ],
    [
        "BÜYÜKÖZCÜ, RÜYA",
        "BÜYÜKÖZCÜ RÜYA"
    ],
    [
        "CALANI, GIANLUCA",
        "CALANI GIANLUCA"
    ],
    [
        "DELLA CASA MARIO",
        "DELLA CASA MARIO"
    ],
    [
        "FLURI ALEXANDRA",
        "FLURI ALEXANDRA"
    ],
    [
        "GANZ ULRICH",
        "GANZ ULRICH"
    ],
    [
        "HESS AG",
        "HESS"
    ],
    [
        "HISCH ELISABETH",
        "HISCH ELISABETH"
    ],
    [
        "HUBER, ALAIN",
        "HUBER, ALAIN"
    ],
    [
        "KASPAR, SUSANNE",
        "KASPAR SUSANNE"
    ],
    [
        "KOSTIC, VALENTINA (EX MITUCI",
        "KOSTIC VALENTINA EX MITUCI"
    ],
    [
        "LAGLER, MANUEL",
        "LAGLER MANUEL"
    ],
    [
        "LIECHTI, JANNIS",
        "LIECHTI JANNIS"
    ],
    [
        "LÜDI ADRIAN",
        "LÜDI ADRIAN"
    ],
    [
        "MARINO,DANIELA",
        "MARINO,DANIELA"
    ],
    [
        "MESSINGER, PATRICK",
        "MESSINGER, PATRICK"
    ],
    [
        "MILIC, MILOMIR",
        "MILIC, MILOMIR"
    ],
    [
        "MOTORFAHRZEUGKONTROLLE",
        "MOTORFAHRZEUGKONTROLLE"
    ],
    [
        "PFAU, ALEXANDER",
        "PFAU ALEXANDER"
    ],
    [
        "RITTER, MARCEL",
        "RITTER MARCEL"
    ],
    [
        "SCHNIDER CLAUDIA",
        "SCHNIDER CLAUDIA"
    ],
    [
        "SCHOMANN BJOERN",
        "SCHOMANN"
    ],
    [
        "SOCAR CARD CENTER",
        "SOCAR CARD CENTER"
    ],
    [
        "STANISLAV GÁBOR",
        "STANISLAV GÁBOR"
    ],
    [
        "WEBER, MARC",
        "WEBER, MARC"
    ],
    [
        "YENNI, QUENTIN",
        "YENNI QUENTIN"
    ],
    [
        "GREENLEAF HOSPITALITY GRP",
        "GREENLEAF HOSPITALITY GRP"
    ],
    [
        "AMERICAN EXPRESS SERVICES EUROPE LTD",
        "AMERICAN EXPRESS"
    ],
    [
        "AMERICAN EXPRESS CARTE - FRA",
        "AMERICAN EXPRESS"
    ],
    [
        "AMERICAN EXPRESS",
        "AMERICAN EXPRESS"
    ],
    [
        "AMERICAN EXPRESS CO",
        "AMERICAN EXPRESS"
    ],
    [
        "AMERICAN EXPRESS INITIATED ACTIVITY",
        "AMERICAN EXPRESS"
    ],
    [
        "AMERICAN EXPRESS SERV.",
        "AMERICAN EXPRESS"
    ],
    [
        "THERMECH",
        "THERMECH"
    ],
    [
        "JABIL",
        "JABIL"
    ],
    [
        "JABIL CIRCUIT (SHANGHAI) LTD",
        "JABIL"
    ],
    [
        "AAP BIOMATERIALSGMBH",
        "OSARTIS"
    ],
    [
        "41MEDICAL AG",
        "41MEDICAL"
    ],
    [
        "ADVANCED SURGICAL SERVICES LLC",
        "ADVANCED SURGICAL SERVICES"
    ],
    [
        "ALPINE LOCK",
        "ALPINE LOCK UTAH"
    ],
    [
        "APS MATERIALS, INC.",
        "APS MATERIALS"
    ],
    [
        "BIO TEK INC",
        "BIOTEK INSTRUMENTS"
    ],
    [
        "CAPITAL MEDICAL RESOURCES LLC",
        "CAPITAL MEDICAL RESOURCES"
    ],
    [
        "CHARLES RIVER LABS LTD",
        "CHARLES RIVER LABS"
    ],
    [
        "COMPOSITES BUSCH SA",
        "COMPOSITES BUSCH"
    ],
    [
        "D&R PRODUCTS CO INC",
        "D&R PRODUCTS"
    ],
    [
        "DECOMICRO",
        "DECOMICRO"
    ],
    [
        "EMPLOYMENT HEALTH ADVISERS LIMITED",
        "EMPLOYMENT HEALTH ADVISORS"
    ],
    [
        "K.R. WOLFE INC",
        "KR WOLFE"
    ],
    [
        "LUCITE INT SPECIALITY",
        "LUCITE INTERNATIONAL"
    ],
    [
        "MAWATEC AG",
        "MAWATEC"
    ],
    [
        "ME-92 OPERATIONS, INC.",
        "ME-92 OPERATIONS"
    ],
    [
        "MEDELA AG",
        "MEDELA AG"
    ],
    [
        "MESA LABORATORIES INC",
        "MESA LABS"
    ],
    [
        "MICROWELD SARL",
        "MICROWELD"
    ],
    [
        "MIDCOM DATA TECHNOLOGIES",
        "MIDCOM DATA TECHNOLOGIES"
    ],
    [
        "EMD MILLPORE CORPORATION",
        "MILLIPORE SIGMA"
    ],
    [
        "MITUTOYO (U.K.) LTD.,",
        "MITUTOYO"
    ],
    [
        "NELSON LABS",
        "NELSON LABS"
    ],
    [
        "NELSON LABORATORIES INC",
        "NELSON LABS"
    ],
    [
        "OKEY AG",
        "OKEY AG"
    ],
    [
        "OUTSOURCE TECH. CONCEPTS",
        "OUTSOURCE TECHICAL CONCEPTS"
    ],
    [
        "PERNAT SMJ",
        "PERNAT INDUSTRIE"
    ],
    [
        "PHILLIPS MEDISIZE",
        "PHILLIPS-MEDISIZE"
    ],
    [
        "PIONEER METAL FINISHING",
        "PIONEER METAL FINISHING"
    ],
    [
        "RR DONNELLEY - REYNOSA",
        "RRD"
    ],
    [
        "SCHMIDBAUER GMBH",
        "SCHMIDBAUER GRUPPE"
    ],
    [
        "SPECIALTY COATING SYSTEMS",
        "SPECIALTY COATING SYSTEMS"
    ],
    [
        "STERILITY ASSURANCE LABORATORIES",
        "STERILITY ASSURANCE LABORATORIES"
    ],
    [
        "SYMMETRY MEDICAL,INC",
        "TECOMET"
    ],
    [
        "SYMMETRY MEDICAL IRELAND",
        "TECOMET"
    ],
    [
        "TECOMET LANSING",
        "TECOMET"
    ],
    [
        "TECOMET",
        "TECOMET"
    ],
    [
        "SYMMETRY MEDICAL INC",
        "TECOMET"
    ],
    [
        "TECOMET INC",
        "TECOMET"
    ],
    [
        "TECOMET INC.",
        "TECOMET"
    ],
    [
        "TELEFLEX MEDICAL INC",
        "TELEFLEX"
    ],
    [
        "TELEFLEX MEDICAL INC - LI",
        "TELEFLEX"
    ],
    [
        "COPPER & BRASS SALES",
        "THYSSENKRUPP"
    ],
    [
        "COPPER & BRASS SALES INC.",
        "THYSSENKRUPP"
    ],
    [
        "COPPER & BRASSREL001",
        "THYSSENKRUPP"
    ],
    [
        "COPPER & BRASSREL002",
        "THYSSENKRUPP"
    ],
    [
        "COPPER & BRASSREL003",
        "THYSSENKRUPP"
    ],
    [
        "COPPER & BRASSREL004",
        "THYSSENKRUPP"
    ],
    [
        "COPPER & BRASSREL005",
        "THYSSENKRUPP"
    ],
    [
        "TRACE ANALYTICS LLC",
        "TRACE ANALYTICS"
    ],
    [
        "WESTPAK, INC.",
        "WESTPAK"
    ],
    [
        "WHIMET INC.",
        "WHIMET"
    ],
    [
        "XELLIA",
        "XELLIA"
    ],
    [
        "CLEANCUT TECHNOLOGIES LLC",
        "CLEANCUT TECHNOLOGIES"
    ],
    [
        "MILLSTONE MEDICAL OUTSOURCING INC",
        "MILLSTONE MEDICAL OUTSOURCING"
    ],
    [
        "MILLSTONE MEDICAL",
        "MILLSTONE MEDICAL OUTSOURCING"
    ],
    [
        "MILLSTONE MEDICAL OUTSOURCIN",
        "MILLSTONE MEDICAL OUTSOURCING"
    ],
    [
        "NOVA LABORATORIES LTD",
        "NOVA LABORATORIES"
    ],
    [
        "PM FLEXIBLES GMBH",
        "PM FLEXIBLES"
    ],
    [
        "BAXTER HEALTHCARE CORP",
        "BAXTER"
    ],
    [
        "COSMED OF NJ",
        "COSMED GROUP"
    ],
    [
        "EDWARDS LIFESCIENCE TECHNOLOGY",
        "EDWARDS LIFESCIENCES"
    ],
    [
        "STERI TECH INC",
        "STERI-TECH INC"
    ],
    [
        "STERIGENICS",
        "STERIGENICS"
    ],
    [
        "STERIGENICS GERMANY GMBH",
        "STERIGENICS"
    ],
    [
        "STERIGENICS S. DE R.L.",
        "STERIGENICS"
    ],
    [
        "STERIGENICS U.S., LLC",
        "STERIGENICS"
    ],
    [
        "STERIGENICS U.S., LLC - S",
        "STERIGENICS"
    ],
    [
        "STERIGENICS US, LLC - WIL",
        "STERIGENICS"
    ],
    [
        "STERIGENCIS",
        "STERIGENICS"
    ],
    [
        "STERIGENICS COSTA RICA",
        "STERIGENICS"
    ],
    [
        "STERIGENICS ISOMEDIX OPERATIONS",
        "STERIGENICS"
    ],
    [
        "STERIGENICS S. DE R.L. DE C.V.",
        "STERIGENICS"
    ],
    [
        "STERIGENICS, S. DE R.L. DE C.V.",
        "STERIGENICS"
    ],
    [
        "SYNERGY HEALTH IRELAND LT",
        "STERIS"
    ],
    [
        "STERIS LTD.,",
        "STERIS"
    ],
    [
        "SYNERGY HEALTH DÄNIKEN AG",
        "STERIS"
    ],
    [
        "STERIS CORPORATION *",
        "STERIS"
    ],
    [
        "SYNERGY HEALTH IRELAND LTD",
        "STERIS"
    ],
    [
        "SYNERGY HEALTH WESTPORT L",
        "STERIS"
    ],
    [
        "STERIS CORPORATION",
        "STERIS"
    ],
    [
        "STERIS CORP",
        "STERIS"
    ],
    [
        "STERIS ISOMEDIX SERVICES",
        "STERIS"
    ],
    [
        "SYNERGY HEALTH IRELAND",
        "STERIS"
    ],
    [
        "SYNERGY HEALTH WESTPORT",
        "STERIS"
    ],
    [
        "STERIS",
        "STERIS"
    ],
    [
        "STERIS APPLIED STERILIZATION TECH - GP",
        "STERIS"
    ],
    [
        "1011AR 130518A",
        "130518A"
    ],
    [
        "008028 6720-0330-1",
        "6720 0330 1"
    ],
    [
        "008028 6720-0435-1",
        "6720 0435 1"
    ],
    [
        "008028 6720-0535-1",
        "6720 0535 1"
    ],
    [
        "008028 6720-0635-1",
        "6720 0635 1"
    ],
    [
        "008028 6720-0737-1",
        "6720 0737 1"
    ],
    [
        "008028 6721-0330-1",
        "6721 0330 1"
    ],
    [
        "008028 6721-0435-1",
        "6721 0435 1"
    ],
    [
        "008028 6721-0535-1",
        "6721 0535 1"
    ],
    [
        "008028 6721-0635-1",
        "6721 0635 1"
    ],
    [
        "008028 6721-0737-1",
        "6721 0737 1"
    ],
    [
        "004237 702-04-42A-21",
        "702 04 42A 21"
    ],
    [
        "004238 702-04-42A-21",
        "702 04 42A 21"
    ],
    [
        "004233 702-04-44B-21",
        "702 04 44B 21"
    ],
    [
        "005979 702-04-46C",
        "702 04 46C"
    ],
    [
        "004236 702-04-46C-21",
        "702 04 46C 21"
    ],
    [
        "005948 702-04-46C-21",
        "702 04 46C 21"
    ],
    [
        "004231 702-04-48D-21",
        "702 04 48D 21"
    ],
    [
        "004240 702-04-48D-21",
        "702 04 48D 21"
    ],
    [
        "004250 702-04-48D-21",
        "702 04 48D 21"
    ],
    [
        "005904 702-04-48D-21",
        "702 04 48D 21"
    ],
    [
        "005957 702-04-48D-21",
        "702 04 48D 21"
    ],
    [
        "000000 702-04-50D-21",
        "702 04 50D 21"
    ],
    [
        "004236 702-04-50D-21",
        "702 04 50D 21"
    ],
    [
        "004250 702-04-50D-21",
        "702 04 50D 21"
    ],
    [
        "005923 702-04-50D-21",
        "702 04 50D 21"
    ],
    [
        "004236 702-04-52E-21",
        "702 04 52E 21"
    ],
    [
        "004240 702-04-52E-21",
        "702 04 52E 21"
    ],
    [
        "004250 702-04-52E-21",
        "702 04 52E 21"
    ],
    [
        "005906 702-04-52E-21",
        "702 04 52E 21"
    ],
    [
        "005974 702-04-52E-21",
        "702 04 52E 21"
    ],
    [
        "004241 702-04-54E-2",
        "702 04 54E 2"
    ],
    [
        "004236 702-04-54E-21",
        "702 04 54E 21"
    ],
    [
        "004250 702-04-54E-21",
        "702 04 54E 21"
    ],
    [
        "005904 702-04-54E-21",
        "702 04 54E 21"
    ],
    [
        "005918 702-04-54E-21",
        "702 04 54E 21"
    ],
    [
        "005974 702-04-54E-21",
        "702 04 54E 21"
    ],
    [
        "004236 702-04-56F-21",
        "702 04 56F 21"
    ],
    [
        "004250 702-04-56F-21",
        "702 04 56F 21"
    ],
    [
        "005912 702-04-56F-21",
        "702 04 56F 21"
    ],
    [
        "005918 702-04-56F-21",
        "702 04 56F 21"
    ],
    [
        "005922 702-04-56F-21",
        "702 04 56F 21"
    ],
    [
        "005923 702-04-56F-21",
        "702 04 56F 21"
    ],
    [
        "005958 702-04-56F-21",
        "702 04 56F 21"
    ],
    [
        "004230 702-04-58F-21",
        "702 04 58F 21"
    ],
    [
        "004236 702-04-58F-21",
        "702 04 58F 21"
    ],
    [
        "005918 702-04-58F-21",
        "702 04 58F 21"
    ],
    [
        "004231 702-04-60G-21",
        "702 04 60G 21"
    ],
    [
        "004236 702-04-60G-21",
        "702 04 60G 21"
    ],
    [
        "005904 702-04-60G-21",
        "702 04 60G 21"
    ],
    [
        "005974 702-04-60G-21",
        "702 04 60G 21"
    ],
    [
        "004236 702-04-62G-21",
        "702 04 62G 21"
    ],
    [
        "004246 702-04-62G-21",
        "702 04 62G 21"
    ],
    [
        "004250 702-04-62G-21",
        "702 04 62G 21"
    ],
    [
        "004236 702-04-64H-21",
        "702 04 64H 21"
    ],
    [
        "004240 702-04-66H-2",
        "702 04 66H 2"
    ],
    [
        "004236 702-04-66H-21",
        "702 04 66H 21"
    ],
    [
        "004238 702-04-66H-21",
        "702 04 66H 21"
    ],
    [
        "004240 702-04-66H-21",
        "702 04 66H 21"
    ],
    [
        "005974 702-04-66H-21",
        "702 04 66H 21"
    ],
    [
        "005983 709-04-42A-21",
        "709 04 42A 21"
    ],
    [
        "005983 709-04-44B-21",
        "709 04 44B 21"
    ],
    [
        "005983 709-04-46C-21",
        "709 04 46C 21"
    ],
    [
        "005983 709-04-48D-21",
        "709 04 48D 21"
    ],
    [
        "005983 709-04-50D-21",
        "709 04 50D 21"
    ],
    [
        "005983 709-04-52E-21",
        "709 04 52E 21"
    ],
    [
        "005985 709-04-52E-21",
        "709 04 52E 21"
    ],
    [
        "005983 709-04-54E-21",
        "709 04 54E 21"
    ],
    [
        "005983 709-04-56F-21",
        "709 04 56F 21"
    ],
    [
        "005983 709-04-58F-21",
        "709 04 58F 21"
    ],
    [
        "005983 709-04-60G-21",
        "709 04 60G 21"
    ],
    [
        "005983 709-04-62G-21",
        "709 04 62G 21"
    ],
    [
        "005983 709-04-66H-21",
        "709 04 66H 21"
    ],
    [
        "004234 709-04-68I-21",
        "709 04 68I 21"
    ],
    [
        "005983 709-04-68I-21",
        "709 04 68I 21"
    ],
    [
        "005983 709-04-70I-21",
        "709 04 70I 21"
    ],
    [
        "005983 709-04-72J-21",
        "709 04 72J 21"
    ],
    [
        "005940 9536-B-101",
        "9536 B 101"
    ],
    [
        "005944 9536-B-101",
        "9536 B 101"
    ],
    [
        "005968 9536-B-101",
        "9536 B 101"
    ],
    [
        "005942 9536-B-201",
        "9536 B 201"
    ],
    [
        "005944 9536-B-201",
        "9536 B 201"
    ],
    [
        "005968 9536-B-201",
        "9536 B 201"
    ],
    [
        "005971 9536-B-201",
        "9536 B 201"
    ],
    [
        "005987 9536-B-201",
        "9536 B 201"
    ],
    [
        "005942 9536-B-301",
        "9536 B 301"
    ],
    [
        "005946 9536-B-301",
        "9536 B 301"
    ],
    [
        "005968 9536-B-301",
        "9536 B 301"
    ],
    [
        "005904 9536-B-401",
        "9536 B 401"
    ],
    [
        "005942 9536-B-401",
        "9536 B 401"
    ],
    [
        "005946 9536-B-401",
        "9536 B 401"
    ],
    [
        "005968 9536-B-401",
        "9536 B 401"
    ],
    [
        "005942 9536-B-501",
        "9536 B 501"
    ],
    [
        "005946 9536-B-501",
        "9536 B 501"
    ],
    [
        "005968 9536-B-501",
        "9536 B 501"
    ],
    [
        "005942 9536-B-601",
        "9536 B 601"
    ],
    [
        "005946 9536-B-601",
        "9536 B 601"
    ],
    [
        "005967 9536-B-601",
        "9536 B 601"
    ],
    [
        "005968 9536-B-601",
        "9536 B 601"
    ],
    [
        "004249 9536-B-701",
        "9536 B 701"
    ],
    [
        "005942 9536-B-701",
        "9536 B 701"
    ],
    [
        "005946 9536-B-701",
        "9536 B 701"
    ],
    [
        "005968 9536-B-701",
        "9536 B 701"
    ],
    [
        "004238 9536-B-801",
        "9536 B 801"
    ],
    [
        "005940 9536-B-801",
        "9536 B 801"
    ],
    [
        "005944 9536-B-801",
        "9536 B 801"
    ],
    [
        "005961 9536-B-801",
        "9536 B 801"
    ],
    [
        "005962 9536-B-801",
        "9536 B 801"
    ],
    [
        "005968 9536-B-801",
        "9536 B 801"
    ],
    [
        "000329 9536-B-200",
        "9536-B-200"
    ],
    [
        "000329 9536-B-400",
        "9536-B-400"
    ],
    [
        "000329 9536-B-500",
        "9536-B-500"
    ],
    [
        "000329 9536-B-600",
        "9536-B-600"
    ],
    [
        "000329 9536-B-700",
        "9536-B-700"
    ],
    [
        "000329 9536-B-800",
        "9536-B-800"
    ],
    [
        "ADJ - SAMPLES ACCRUAL",
        "ADJ SAMPLES ACCRUAL"
    ],
    [
        "ADJ. TESTING",
        "ADJ TESTING"
    ],
    [
        "ADVANCED PRECISION LLC",
        "ADVANCED PRECISION"
    ],
    [
        "AMT PTE LTD",
        "AMT"
    ],
    [
        "000000 AN-4802XXXXCF",
        "AN 4802XXXXCF"
    ],
    [
        "005923 AN-48933146",
        "AN 48933146"
    ],
    [
        "004240 AN48987061",
        "AN48987061"
    ],
    [
        "000071 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000076 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000101 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000107 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000121 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000123 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000128 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000131 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000133 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000143 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000155 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000163 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000166 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000168 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000172 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000181 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000193 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000197 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000200 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000214 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000221 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000224 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000231 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000235 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000261 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000275 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000278 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000359 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000368 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000405 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000415 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000427 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000435 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000449 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000456 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000465 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000468 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000521 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000527 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000531 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000533 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000566 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000578 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000584 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000595 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000600 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000624 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000637 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000760 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000768 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000783 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000786 AN9536-B-100",
        "AN9536 B 100"
    ],
    [
        "000069 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000071 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000074 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000076 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000078 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000079 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000081 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000090 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000092 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000101 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000102 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000105 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000107 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000111 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000121 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000123 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000128 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000144 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000151 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000155 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000156 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000159 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000163 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000164 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000166 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000172 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000178 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000181 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000185 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000191 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000193 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000194 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000197 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000200 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000210 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000217 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000221 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000224 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000231 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000235 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000236 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000238 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000244 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000247 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000252 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000261 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000266 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000275 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000281 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000282 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000289 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000290 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000299 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000306 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000318 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000339 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000372 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000379 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000392 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000396 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000404 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000405 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000415 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000428 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000447 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000449 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000456 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000465 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000468 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000505 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000513 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000521 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000527 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000531 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000533 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000541 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000549 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000562 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000566 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000578 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000584 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000595 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000600 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000624 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000639 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000648 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000670 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000688 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000697 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000728 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000744 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000750 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000760 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000768 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000783 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000786 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "005906 AN9536-B-200",
        "AN9536 B 200"
    ],
    [
        "000069 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000071 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000074 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000076 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000078 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000079 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000081 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000082 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000086 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000090 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000092 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000101 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000102 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000105 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000107 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000111 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000119 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000121 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000123 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000128 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000131 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000133 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000135 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000143 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000144 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000145 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000151 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000155 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000159 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000164 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000166 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000168 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000172 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000174 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000176 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000178 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000181 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000183 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000185 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000189 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000193 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000194 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000197 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000200 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000205 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000210 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000214 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000217 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000224 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000231 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000235 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000238 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000244 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000247 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000252 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000261 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000264 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000266 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000275 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000281 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000282 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000289 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000290 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000299 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000301 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000303 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000312 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000318 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000320 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000324 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000339 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000359 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000368 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000372 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000379 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000382 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000392 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000396 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000404 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000405 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000412 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000415 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000420 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000427 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000428 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000435 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000447 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000449 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000456 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000465 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000468 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000469 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000505 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000513 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000521 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000527 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000531 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000533 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000541 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000549 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000553 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000562 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000566 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000578 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000584 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000600 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000624 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000637 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000639 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000648 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000661 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000665 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000670 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000675 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000680 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000688 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000697 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000728 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000740 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000744 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000750 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000760 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000768 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000783 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000786 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "004248 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "005934 AN9536-B-300",
        "AN9536 B 300"
    ],
    [
        "000069 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000071 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000074 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000076 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000078 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000079 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000081 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000082 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000086 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000090 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000092 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000101 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000102 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000105 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000107 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000111 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000119 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000121 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000123 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000128 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000131 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000133 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000135 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000136 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000143 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000144 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000145 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000151 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000155 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000156 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000159 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000163 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000164 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000166 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000168 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000172 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000174 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000176 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000178 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000183 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000185 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000189 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000191 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000193 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000194 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000197 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000200 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000205 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000210 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000214 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000217 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000221 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000224 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000231 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000235 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000236 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000238 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000244 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000247 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000252 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000261 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000264 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000266 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000270 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000275 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000278 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000281 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000282 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000289 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000290 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000299 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000301 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000303 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000306 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000312 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000318 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000320 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000324 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000336 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000339 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000359 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000368 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000372 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000379 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000382 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000392 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000396 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000404 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000405 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000412 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000415 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000420 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000427 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000428 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000435 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000447 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000449 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000456 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000465 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000468 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000469 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000505 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000513 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000521 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000527 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000531 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000533 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000541 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000549 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000553 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000562 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000566 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000578 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000584 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000595 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000624 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000637 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000639 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000648 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000661 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000665 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000670 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000675 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000680 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000688 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000697 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000728 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000740 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000744 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000750 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000760 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000768 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000783 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000786 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "004234 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "004242 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "004248 AN9536-B-400",
        "AN9536 B 400"
    ],
    [
        "000069 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000074 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000076 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000078 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000079 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000081 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000082 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000086 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000090 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000092 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000101 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000102 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000105 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000107 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000111 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000119 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000121 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000123 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000128 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000131 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000133 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000135 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000136 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000138 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000144 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000145 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000151 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000155 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000156 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000159 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000163 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000164 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000166 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000168 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000172 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000174 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000176 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000178 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000181 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000183 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000185 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000189 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000191 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000193 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000194 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000197 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000200 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000205 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000210 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000217 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000221 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000224 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000231 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000235 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000236 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000238 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000244 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000247 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000252 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000261 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000264 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000266 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000275 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000278 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000281 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000282 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000289 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000290 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000299 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000301 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000303 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000306 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000312 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000318 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000320 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000324 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000336 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000339 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000359 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000368 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000372 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000379 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000382 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000388 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000392 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000396 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000404 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000405 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000412 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000415 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000420 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000427 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000428 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000435 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000447 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000449 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000456 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000465 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000468 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000469 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000505 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000513 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000521 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000527 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000531 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000533 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000541 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000549 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000553 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000562 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000566 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000578 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000584 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000595 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000600 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000624 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000637 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000639 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000648 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000661 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000665 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000670 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000675 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000680 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000688 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000697 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000728 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000740 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000744 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000750 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000760 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000768 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000783 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000786 AN9536-B-500",
        "AN9536 B 500"
    ],
    [
        "000069 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000074 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000076 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000078 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000079 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000081 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000082 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000090 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000092 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000101 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000107 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000119 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000121 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000123 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000128 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000131 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000133 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000135 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000136 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000138 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000144 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000145 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000151 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000155 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000156 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000159 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000163 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000166 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000168 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000172 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000174 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000176 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000178 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000181 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000183 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000185 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000189 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000191 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000193 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000194 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000197 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000200 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000210 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000214 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000217 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000221 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000224 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000231 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000235 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000236 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000238 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000244 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000247 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000252 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000261 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000264 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000266 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000270 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000275 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000278 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000281 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000282 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000289 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000290 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000299 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000301 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000303 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000306 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000312 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000318 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000320 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000324 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000336 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000339 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000359 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000368 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000372 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000379 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000382 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000388 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000392 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000396 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000404 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000405 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000412 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000415 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000420 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000427 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000428 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000435 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000447 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000456 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000465 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000468 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000469 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000505 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000513 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000521 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000527 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000531 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000533 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000541 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000549 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000553 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000562 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000566 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000578 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000584 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000595 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000600 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000624 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000637 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000639 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000648 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000661 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000665 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000670 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000675 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000680 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000688 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000697 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000728 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000740 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000744 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000750 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000760 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000768 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000783 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000786 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "004233 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "004237 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "004248 AN9536-B-600",
        "AN9536 B 600"
    ],
    [
        "000069 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000071 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000074 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000076 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000079 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000081 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000082 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000086 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000090 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000092 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000101 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000102 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000105 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000107 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000111 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000119 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000121 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000123 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000128 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000131 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000133 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000135 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000138 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000143 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000144 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000145 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000151 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000155 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000156 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000159 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000163 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000164 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000166 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000168 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000172 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000174 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000176 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000178 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000181 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000183 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000185 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000189 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000191 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000193 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000194 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000197 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000200 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000210 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000214 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000217 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000221 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000224 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000231 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000235 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000236 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000238 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000244 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000247 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000252 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000261 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000264 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000266 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000270 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000275 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000278 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000281 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000282 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000289 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000290 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000299 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000303 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000306 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000312 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000318 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000320 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000324 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000336 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000339 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000359 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000368 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000372 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000379 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000382 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000388 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000392 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000396 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000404 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000405 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000412 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000415 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000420 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000427 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000428 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000435 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000447 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000449 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000456 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000465 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000468 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000469 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000505 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000513 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000521 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000527 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000531 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000533 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000541 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000549 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000553 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000562 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000566 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000578 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000584 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000595 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000600 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000624 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000637 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000639 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000648 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000661 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000665 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000670 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000675 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000680 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000688 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000697 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000728 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000740 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000744 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000750 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000760 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000768 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000783 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000786 AN9536-B-700",
        "AN9536 B 700"
    ],
    [
        "000069 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000071 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000074 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000076 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000078 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000081 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000090 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000102 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000105 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000107 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000111 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000119 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000121 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000123 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000143 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000145 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000163 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000164 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000166 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000168 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000172 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000174 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000178 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000181 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000183 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000185 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000191 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000194 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000210 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000214 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000221 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000224 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000231 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000235 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000244 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000247 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000252 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000261 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000264 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000270 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000278 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000281 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000289 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000290 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000301 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000306 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000312 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000339 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000368 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000382 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000388 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000396 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000404 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000405 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000415 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000428 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000435 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000465 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000468 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000469 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000505 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000513 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000531 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000533 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000541 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000549 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000562 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000639 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000648 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000661 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000665 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000675 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000786 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "004241 AN9536-B-800",
        "AN9536 B 800"
    ],
    [
        "000252 ANFR9536-B-200",
        "ANFR9536 B 200"
    ],
    [
        "000135 ANFR9536-B-400",
        "ANFR9536 B 400"
    ],
    [
        "000172 ANFR9536-B-400",
        "ANFR9536 B 400"
    ],
    [
        "000339 ANFR9536-B-400",
        "ANFR9536 B 400"
    ],
    [
        "000368 ANFR9536-B-400",
        "ANFR9536 B 400"
    ],
    [
        "000102 ANFR9536-B-500",
        "ANFR9536 B 500"
    ],
    [
        "000172 ANFR9536-B-500",
        "ANFR9536 B 500"
    ],
    [
        "000224 ANFR9536-B-500",
        "ANFR9536 B 500"
    ],
    [
        "000231 ANFR9536-B-500",
        "ANFR9536 B 500"
    ],
    [
        "000261 ANFR9536-B-500",
        "ANFR9536 B 500"
    ],
    [
        "000143 ANFR9536-B-600",
        "ANFR9536 B 600"
    ],
    [
        "000224 ANFR9536-B-600",
        "ANFR9536 B 600"
    ],
    [
        "000412 ANFR9536-B-600",
        "ANFR9536 B 600"
    ],
    [
        "005951 ANOV9536-B-400",
        "ANOV9536 B 400"
    ],
    [
        "AO  SAMPLES",
        "AO  SAMPLES"
    ],
    [
        "APOLLO MEDICAL EXTRUSION TECHNOLOGIES, INC.",
        "APOLLO MEDICAL EXTRUSION TECHNOLOGIES"
    ],
    [
        "APS MATERIALS INC",
        "APS MATERIALS"
    ],
    [
        "ATHLONE INSTITUTE OF TECHNOLOGY",
        "ATHLONE INSTITUTE OF TECHNOLOGY"
    ],
    [
        "ATI WAH CHANG",
        "ATI WAH CHANG"
    ],
    [
        "AZURITE CONSULTING",
        "AZURITE CONSULTING"
    ],
    [
        "BASEPLATES",
        "BASEPLATES"
    ],
    [
        "BERND VIEREGGE",
        "BERND VIEREGGE"
    ],
    [
        "CNC SPEEDFORM AG",
        "CNC SPEEDFORM"
    ],
    [
        "CODETEC",
        "CODETEC"
    ],
    [
        "CONFARMA FRANCE S.A.R.L.",
        "CONFARMA S A R L"
    ],
    [
        "CORRECTION",
        "CORRECTION"
    ],
    [
        "DEANELX",
        "DEANELX"
    ],
    [
        "DR.CURT HAEFNER VERLAG GMBH",
        "DR CURT HAEFNER VERLAG"
    ],
    [
        "DYNAMET INC",
        "DYNAMET"
    ],
    [
        "ELEMENT MATERIALS TECH",
        "ELEMENT MATERIALS TECH"
    ],
    [
        "EMOTION MEDIA",
        "EMOTION MEDIA"
    ],
    [
        "FANNIN HEALTHCARE,",
        "FANNIN HEALTHCARE"
    ],
    [
        "FERRY MACHINE CORPORATION",
        "FERRY MACHINE"
    ],
    [
        "G + C CREATIONS",
        "G C CREATIONS"
    ],
    [
        "GAS ANALYSIS SERVICES LTD",
        "GAS ANALYSIS SERVICES"
    ],
    [
        "GEBR BRASSELER GMBH & CO KG",
        "GEBR BRASSELER"
    ],
    [
        "GLANTREO LTD",
        "GLANTREO"
    ],
    [
        "HERAEUS MEDICAL COMPONENTS LLC",
        "HERAEUS MEDICAL COMPONENTS"
    ],
    [
        "HIGH TECH ALLOYS",
        "HIGH TECH ALLOYS"
    ],
    [
        "IMR TEST LABS",
        "IMR TEST LABS"
    ],
    [
        "INDO-MIM PVT LTD",
        "INDO MIM PVT"
    ],
    [
        "INTERLABOR BELP AG",
        "INTERLABOR BELP"
    ],
    [
        "IRISH MED DEVICE ASOC",
        "IRISH MED DEVICE ASOC"
    ],
    [
        "LABPLAN LTD",
        "LABPLAN"
    ],
    [
        "LIFTRITE LTD",
        "LIFTRITE"
    ],
    [
        "LIMERICK BASEPLATES",
        "LIMERICK BASEPLATES"
    ],
    [
        "LOTUS",
        "LOTUS"
    ],
    [
        "000000 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "004230 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "004235 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "004240 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "004241 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005901 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005910 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005913 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005914 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005915 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005916 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005920 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005921 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005922 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005923 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005928 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005930 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005931 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005932 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005933 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005935 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005938 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005941 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005943 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005946 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005959 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005962 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005963 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005967 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005968 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005971 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005977 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005986 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "005987 LRMCPTI1",
        "LRMCPTI1"
    ],
    [
        "000000 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "002205 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004229 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004230 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004231 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004232 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004233 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004234 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004235 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004236 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004237 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004238 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004239 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004240 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004241 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004242 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004244 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004245 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004246 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004247 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004248 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004249 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "004250 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005170 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005901 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005903 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005904 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005905 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005906 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005907 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005908 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005909 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005911 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005912 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005913 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005914 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005915 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005916 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005917 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005918 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005919 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005920 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005921 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005922 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005923 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005924 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005925 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005926 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005927 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005928 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005929 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005930 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005931 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005932 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005933 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005934 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005935 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005936 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005937 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005938 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005939 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005940 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005941 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005942 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005943 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005944 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005946 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005947 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005948 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005949 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005950 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005951 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005952 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005953 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005954 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005956 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005958 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005959 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005960 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005961 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005962 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005963 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005964 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005965 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005966 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005967 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005969 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005970 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005971 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005972 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005973 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005974 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005975 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005976 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005977 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005978 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005979 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005980 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005981 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005982 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005984 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005985 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005986 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005987 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005988 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "005989 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "059333 LRMTI6AL4VGR23",
        "LRMTI6AL4VGR23"
    ],
    [
        "MASTER METAL ENG., INC.",
        "MASTER METAL ENG"
    ],
    [
        "MERCK CHEMICALS LTD",
        "MERCK CHEMICALS"
    ],
    [
        "METROHM IRELAND LTD",
        "METROHM"
    ],
    [
        "MOLLET PRÄZISIONSMECHANIK AG",
        "MOLLET PRÄZISIONSMECHANIK"
    ],
    [
        "MV O'HALLORAN LTD",
        "MV O'HALLORAN"
    ],
    [
        "O&Y PRECISION",
        "O&Y PRECISION"
    ],
    [
        "PAJUNK GMBH",
        "PAJUNK"
    ],
    [
        "PAUL JOHNSON",
        "PAUL JOHNSON"
    ],
    [
        "PRECIPART CORP.",
        "PRECIPART"
    ],
    [
        "RADISSON SAS HOTEL",
        "RADISSON SAS HOTEL"
    ],
    [
        "RAKEM LTD",
        "RAKEM"
    ],
    [
        "RECLASS",
        "RECLASS"
    ],
    [
        "SAMPLES",
        "SAMPLES"
    ],
    [
        "SAMPLES CLASSIFY",
        "SAMPLES CLASSIFY"
    ],
    [
        "SAMPLES RECLASS",
        "SAMPLES RECLASS"
    ],
    [
        "SIGMA ALDRICH IRELAND LTD",
        "SIGMA ALDRICH"
    ],
    [
        "SYMMETRY MEDICAL WARSAW",
        "SYMMETRY MEDICAL WARSAW"
    ],
    [
        "000329 T9536-B-700",
        "T9536 B 700"
    ],
    [
        "TYNDALL NATIONAL INSTITUT",
        "TYNDALL NATIONAL INSTITUT"
    ],
    [
        "UNIVERSITY OF LIMERICK",
        "UNIVERSITY OF LIMERICK"
    ],
    [
        "VALIDATION SAMPLES",
        "VALIDATION SAMPLES"
    ],
    [
        "ZATKOFF SEALS",
        "ZATKOFF SEALS"
    ],
    [
        "ZERSPANUNGSTECHNOLOGIE POGGENGERD G",
        "ZERSPANUNGSTECHNOLOGIE POGGENGERD G"
    ],
    [
        "CHARLES RIVER *",
        "CHARLES RIVER LABORATORIES"
    ],
    [
        "CHARLES RIVERLABORATORIES",
        "CHARLES RIVER LABORATORIES"
    ],
    [
        "CHARLES RIVER MICROBIAL INTL",
        "CHARLES RIVER LABORATORIES"
    ],
    [
        "CHARLES RIVER LABORATORIES",
        "CHARLES RIVER LABORATORIES"
    ],
    [
        "CHARLES RIVER",
        "CHARLES RIVER LABORATORIES"
    ],
    [
        "CHARLES RIVER MICROBIAL SOL.",
        "CHARLES RIVER LABORATORIES"
    ],
    [
        "ADVANCED MOLDING CONCEPTS",
        "ADVANCED MOLDING CONCEPTS"
    ],
    [
        "AGILENT TECHNOLOGIES",
        "AGILENT TECHNOLOGIES"
    ],
    [
        "AGILENT TECHNOLOGIES,INC.",
        "AGILENT TECHNOLOGIES"
    ],
    [
        "AGILENT TECHNOLOGIES LTD",
        "AGILENT TECHNOLOGIES"
    ],
    [
        "B.H.P LABORATORIES,",
        "B.H.P LABORATORIES"
    ],
    [
        "BBF  STERILISATION SERVICE GMBH",
        "BBF STERILISATION SERVICE"
    ],
    [
        "BEA METROLOGIE",
        "BEA METROLOGIE"
    ],
    [
        "BEA METROLOGIE CH",
        "BEA METROLOGIE"
    ],
    [
        "BIOMERIEUX INC",
        "BIOMERIEUX"
    ],
    [
        "BIOMERIEUX UK LTD",
        "BIOMERIEUX"
    ],
    [
        "BIOMÉRIEUX (SUISSE) SA",
        "BIOMERIEUX"
    ],
    [
        "BIOMERIEUX DEUTSCHLAND GMBH",
        "BIOMERIEUX"
    ],
    [
        "BIOMERIEUX, INC.",
        "BIOMERIEUX"
    ],
    [
        "CONSUMER PRODUCT TESTING *",
        "CONSUMER PRODUCT TESTING"
    ],
    [
        "CONSUMER PRODUCT TESTING CO INC",
        "CONSUMER PRODUCT TESTING"
    ],
    [
        "DECOM PRUEFLABOR GMBH",
        "DECOM PRUEFLABOR"
    ],
    [
        "EMSAR *",
        "EMSAR"
    ],
    [
        "EURANORD GMBH",
        "EURANORD"
    ],
    [
        "JOHNSON & JOHNSON HEALTH CARE SYSTEMS",
        "JOHNSON & JOHNSON"
    ],
    [
        "KIWA GMBH",
        "KIWA"
    ],
    [
        "MEDITECH MEDICAL POLYMERS",
        "MEDITECH MEDICAL POLYMERS"
    ],
    [
        "MICRO QUALITY LABS, INC. *",
        "MICRO QUALITY LABS"
    ],
    [
        "MRL",
        "MRL"
    ],
    [
        "REMET PIC INC",
        "REMET PIC"
    ],
    [
        "STERILITY ASSURANCE LABORATORIES INC.",
        "STERILITY ASSURANCE LABORATORIES"
    ],
    [
        "TESTO INDUSTRIAL SERVICES",
        "TESTO INDUSTRIAL SERVICES"
    ],
    [
        "TESTO INDUSTRIAL SERVICES AG",
        "TESTO INDUSTRIAL SERVICES"
    ],
    [
        "THE ROBERT E MORRIS COMPANY",
        "THE ROBERT E MORRIS"
    ],
    [
        "UL INTERNATIONAL GMBH",
        "UL INTERNATIONAL"
    ],
    [
        "WATERS CORPORATION",
        "WATERS CORPORATION"
    ],
    [
        "PERSON 1",
        "PERSON 1"
    ],
    [
        "PERSON 10",
        "PERSON 10"
    ],
    [
        "PERSON 100",
        "PERSON 100"
    ],
    [
        "PERSON 101",
        "PERSON 101"
    ],
    [
        "PERSON 102",
        "PERSON 102"
    ],
    [
        "PERSON 103",
        "PERSON 103"
    ],
    [
        "PERSON 104",
        "PERSON 104"
    ],
    [
        "PERSON 105",
        "PERSON 105"
    ],
    [
        "PERSON 106",
        "PERSON 106"
    ],
    [
        "PERSON 107",
        "PERSON 107"
    ],
    [
        "PERSON 108",
        "PERSON 108"
    ],
    [
        "PERSON 109",
        "PERSON 109"
    ],
    [
        "PERSON 11",
        "PERSON 11"
    ],
    [
        "PERSON 110",
        "PERSON 110"
    ],
    [
        "PERSON 111",
        "PERSON 111"
    ],
    [
        "PERSON 112",
        "PERSON 112"
    ],
    [
        "PERSON 113",
        "PERSON 113"
    ],
    [
        "PERSON 114",
        "PERSON 114"
    ],
    [
        "PERSON 115",
        "PERSON 115"
    ],
    [
        "PERSON 116",
        "PERSON 116"
    ],
    [
        "PERSON 117",
        "PERSON 117"
    ],
    [
        "PERSON 118",
        "PERSON 118"
    ],
    [
        "PERSON 119",
        "PERSON 119"
    ],
    [
        "PERSON 12",
        "PERSON 12"
    ],
    [
        "PERSON 120",
        "PERSON 120"
    ],
    [
        "PERSON 121",
        "PERSON 121"
    ],
    [
        "PERSON 122",
        "PERSON 122"
    ],
    [
        "PERSON 123",
        "PERSON 123"
    ],
    [
        "PERSON 124",
        "PERSON 124"
    ],
    [
        "PERSON 125",
        "PERSON 125"
    ],
    [
        "PERSON 126",
        "PERSON 126"
    ],
    [
        "PERSON 127",
        "PERSON 127"
    ],
    [
        "PERSON 128",
        "PERSON 128"
    ],
    [
        "PERSON 129",
        "PERSON 129"
    ],
    [
        "PERSON 13",
        "PERSON 13"
    ],
    [
        "PERSON 130",
        "PERSON 130"
    ],
    [
        "PERSON 131",
        "PERSON 131"
    ],
    [
        "PERSON 132",
        "PERSON 132"
    ],
    [
        "PERSON 133",
        "PERSON 133"
    ],
    [
        "PERSON 134",
        "PERSON 134"
    ],
    [
        "PERSON 135",
        "PERSON 135"
    ],
    [
        "PERSON 136",
        "PERSON 136"
    ],
    [
        "PERSON 137",
        "PERSON 137"
    ],
    [
        "PERSON 138",
        "PERSON 138"
    ],
    [
        "PERSON 139",
        "PERSON 139"
    ],
    [
        "PERSON 14",
        "PERSON 14"
    ],
    [
        "PERSON 140",
        "PERSON 140"
    ],
    [
        "PERSON 141",
        "PERSON 141"
    ],
    [
        "PERSON 142",
        "PERSON 142"
    ],
    [
        "PERSON 143",
        "PERSON 143"
    ],
    [
        "PERSON 144",
        "PERSON 144"
    ],
    [
        "PERSON 145",
        "PERSON 145"
    ],
    [
        "PERSON 146",
        "PERSON 146"
    ],
    [
        "PERSON 147",
        "PERSON 147"
    ],
    [
        "PERSON 148",
        "PERSON 148"
    ],
    [
        "PERSON 149",
        "PERSON 149"
    ],
    [
        "PERSON 15",
        "PERSON 15"
    ],
    [
        "PERSON 150",
        "PERSON 150"
    ],
    [
        "PERSON 151",
        "PERSON 151"
    ],
    [
        "PERSON 152",
        "PERSON 152"
    ],
    [
        "PERSON 153",
        "PERSON 153"
    ],
    [
        "PERSON 154",
        "PERSON 154"
    ],
    [
        "PERSON 155",
        "PERSON 155"
    ],
    [
        "PERSON 156",
        "PERSON 156"
    ],
    [
        "PERSON 157",
        "PERSON 157"
    ],
    [
        "PERSON 158",
        "PERSON 158"
    ],
    [
        "PERSON 159",
        "PERSON 159"
    ],
    [
        "PERSON 16",
        "PERSON 16"
    ],
    [
        "PERSON 160",
        "PERSON 160"
    ],
    [
        "PERSON 161",
        "PERSON 161"
    ],
    [
        "PERSON 162",
        "PERSON 162"
    ],
    [
        "PERSON 163",
        "PERSON 163"
    ],
    [
        "PERSON 164",
        "PERSON 164"
    ],
    [
        "PERSON 165",
        "PERSON 165"
    ],
    [
        "PERSON 166",
        "PERSON 166"
    ],
    [
        "PERSON 167",
        "PERSON 167"
    ],
    [
        "PERSON 169",
        "PERSON 169"
    ],
    [
        "PERSON 17",
        "PERSON 17"
    ],
    [
        "PERSON 170",
        "PERSON 170"
    ],
    [
        "PERSON 172",
        "PERSON 172"
    ],
    [
        "PERSON 173",
        "PERSON 173"
    ],
    [
        "PERSON 174",
        "PERSON 174"
    ],
    [
        "PERSON 175",
        "PERSON 175"
    ],
    [
        "PERSON 176",
        "PERSON 176"
    ],
    [
        "PERSON 177",
        "PERSON 177"
    ],
    [
        "PERSON 178",
        "PERSON 178"
    ],
    [
        "PERSON 179",
        "PERSON 179"
    ],
    [
        "PERSON 18",
        "PERSON 18"
    ],
    [
        "PERSON 180",
        "PERSON 180"
    ],
    [
        "PERSON 181",
        "PERSON 181"
    ],
    [
        "PERSON 182",
        "PERSON 182"
    ],
    [
        "PERSON 183",
        "PERSON 183"
    ],
    [
        "PERSON 184",
        "PERSON 184"
    ],
    [
        "PERSON 186",
        "PERSON 186"
    ],
    [
        "PERSON 187",
        "PERSON 187"
    ],
    [
        "PERSON 188",
        "PERSON 188"
    ],
    [
        "PERSON 189",
        "PERSON 189"
    ],
    [
        "PERSON 19",
        "PERSON 19"
    ],
    [
        "PERSON 190",
        "PERSON 190"
    ],
    [
        "PERSON 192",
        "PERSON 192"
    ],
    [
        "PERSON 193",
        "PERSON 193"
    ],
    [
        "PERSON 195",
        "PERSON 195"
    ],
    [
        "PERSON 196",
        "PERSON 196"
    ],
    [
        "PERSON 197",
        "PERSON 197"
    ],
    [
        "PERSON 198",
        "PERSON 198"
    ],
    [
        "PERSON 199",
        "PERSON 199"
    ],
    [
        "PERSON 2",
        "PERSON 2"
    ],
    [
        "PERSON 20",
        "PERSON 20"
    ],
    [
        "PERSON 200",
        "PERSON 200"
    ],
    [
        "PERSON 201",
        "PERSON 201"
    ],
    [
        "PERSON 202",
        "PERSON 202"
    ],
    [
        "PERSON 203",
        "PERSON 203"
    ],
    [
        "PERSON 204",
        "PERSON 204"
    ],
    [
        "PERSON 205",
        "PERSON 205"
    ],
    [
        "PERSON 206",
        "PERSON 206"
    ],
    [
        "PERSON 207",
        "PERSON 207"
    ],
    [
        "PERSON 208",
        "PERSON 208"
    ],
    [
        "PERSON 209",
        "PERSON 209"
    ],
    [
        "PERSON 21",
        "PERSON 21"
    ],
    [
        "PERSON 211",
        "PERSON 211"
    ],
    [
        "PERSON 212",
        "PERSON 212"
    ],
    [
        "PERSON 213",
        "PERSON 213"
    ],
    [
        "PERSON 214",
        "PERSON 214"
    ],
    [
        "PERSON 215",
        "PERSON 215"
    ],
    [
        "PERSON 216",
        "PERSON 216"
    ],
    [
        "PERSON 218",
        "PERSON 218"
    ],
    [
        "PERSON 219",
        "PERSON 219"
    ],
    [
        "PERSON 22",
        "PERSON 22"
    ],
    [
        "PERSON 221",
        "PERSON 221"
    ],
    [
        "PERSON 222",
        "PERSON 222"
    ],
    [
        "PERSON 223",
        "PERSON 223"
    ],
    [
        "PERSON 224",
        "PERSON 224"
    ],
    [
        "PERSON 225",
        "PERSON 225"
    ],
    [
        "PERSON 226",
        "PERSON 226"
    ],
    [
        "PERSON 227",
        "PERSON 227"
    ],
    [
        "PERSON 228",
        "PERSON 228"
    ],
    [
        "PERSON 229",
        "PERSON 229"
    ],
    [
        "PERSON 23",
        "PERSON 23"
    ],
    [
        "PERSON 230",
        "PERSON 230"
    ],
    [
        "PERSON 231",
        "PERSON 231"
    ],
    [
        "PERSON 232",
        "PERSON 232"
    ],
    [
        "PERSON 233",
        "PERSON 233"
    ],
    [
        "PERSON 235",
        "PERSON 235"
    ],
    [
        "PERSON 236",
        "PERSON 236"
    ],
    [
        "PERSON 237",
        "PERSON 237"
    ],
    [
        "PERSON 238",
        "PERSON 238"
    ],
    [
        "PERSON 239",
        "PERSON 239"
    ],
    [
        "PERSON 24",
        "PERSON 24"
    ],
    [
        "PERSON 240",
        "PERSON 240"
    ],
    [
        "PERSON 241",
        "PERSON 241"
    ],
    [
        "PERSON 242",
        "PERSON 242"
    ],
    [
        "PERSON 243",
        "PERSON 243"
    ],
    [
        "PERSON 245",
        "PERSON 245"
    ],
    [
        "PERSON 247",
        "PERSON 247"
    ],
    [
        "PERSON 248",
        "PERSON 248"
    ],
    [
        "PERSON 249",
        "PERSON 249"
    ],
    [
        "PERSON 25",
        "PERSON 25"
    ],
    [
        "PERSON 250",
        "PERSON 250"
    ],
    [
        "PERSON 251",
        "PERSON 251"
    ],
    [
        "PERSON 252",
        "PERSON 252"
    ],
    [
        "PERSON 253",
        "PERSON 253"
    ],
    [
        "PERSON 255",
        "PERSON 255"
    ],
    [
        "PERSON 256",
        "PERSON 256"
    ],
    [
        "PERSON 257",
        "PERSON 257"
    ],
    [
        "PERSON 258",
        "PERSON 258"
    ],
    [
        "PERSON 259",
        "PERSON 259"
    ],
    [
        "PERSON 26",
        "PERSON 26"
    ],
    [
        "PERSON 260",
        "PERSON 260"
    ],
    [
        "PERSON 261",
        "PERSON 261"
    ],
    [
        "PERSON 264",
        "PERSON 264"
    ],
    [
        "PERSON 265",
        "PERSON 265"
    ],
    [
        "PERSON 266",
        "PERSON 266"
    ],
    [
        "PERSON 267",
        "PERSON 267"
    ],
    [
        "PERSON 268",
        "PERSON 268"
    ],
    [
        "PERSON 27",
        "PERSON 27"
    ],
    [
        "PERSON 270",
        "PERSON 270"
    ],
    [
        "PERSON 271",
        "PERSON 271"
    ],
    [
        "PERSON 272",
        "PERSON 272"
    ],
    [
        "PERSON 273",
        "PERSON 273"
    ],
    [
        "PERSON 274",
        "PERSON 274"
    ],
    [
        "PERSON 275",
        "PERSON 275"
    ],
    [
        "PERSON 276",
        "PERSON 276"
    ],
    [
        "PERSON 277",
        "PERSON 277"
    ],
    [
        "PERSON 278",
        "PERSON 278"
    ],
    [
        "PERSON 28",
        "PERSON 28"
    ],
    [
        "PERSON 280",
        "PERSON 280"
    ],
    [
        "PERSON 281",
        "PERSON 281"
    ],
    [
        "PERSON 283",
        "PERSON 283"
    ],
    [
        "PERSON 284",
        "PERSON 284"
    ],
    [
        "PERSON 285",
        "PERSON 285"
    ],
    [
        "PERSON 286",
        "PERSON 286"
    ],
    [
        "PERSON 287",
        "PERSON 287"
    ],
    [
        "PERSON 288",
        "PERSON 288"
    ],
    [
        "PERSON 289",
        "PERSON 289"
    ],
    [
        "PERSON 29",
        "PERSON 29"
    ],
    [
        "PERSON 290",
        "PERSON 290"
    ],
    [
        "PERSON 291",
        "PERSON 291"
    ],
    [
        "PERSON 292",
        "PERSON 292"
    ],
    [
        "PERSON 294",
        "PERSON 294"
    ],
    [
        "PERSON 295",
        "PERSON 295"
    ],
    [
        "PERSON 296",
        "PERSON 296"
    ],
    [
        "PERSON 298",
        "PERSON 298"
    ],
    [
        "PERSON 299",
        "PERSON 299"
    ],
    [
        "PERSON 3",
        "PERSON 3"
    ],
    [
        "PERSON 30",
        "PERSON 30"
    ],
    [
        "PERSON 300",
        "PERSON 300"
    ],
    [
        "PERSON 301",
        "PERSON 301"
    ],
    [
        "PERSON 302",
        "PERSON 302"
    ],
    [
        "PERSON 303",
        "PERSON 303"
    ],
    [
        "PERSON 304",
        "PERSON 304"
    ],
    [
        "PERSON 306",
        "PERSON 306"
    ],
    [
        "PERSON 307",
        "PERSON 307"
    ],
    [
        "PERSON 308",
        "PERSON 308"
    ],
    [
        "PERSON 309",
        "PERSON 309"
    ],
    [
        "PERSON 31",
        "PERSON 31"
    ],
    [
        "PERSON 310",
        "PERSON 310"
    ],
    [
        "PERSON 311",
        "PERSON 311"
    ],
    [
        "PERSON 312",
        "PERSON 312"
    ],
    [
        "PERSON 313",
        "PERSON 313"
    ],
    [
        "PERSON 314",
        "PERSON 314"
    ],
    [
        "PERSON 315",
        "PERSON 315"
    ],
    [
        "PERSON 316",
        "PERSON 316"
    ],
    [
        "PERSON 317",
        "PERSON 317"
    ],
    [
        "PERSON 318",
        "PERSON 318"
    ],
    [
        "PERSON 319",
        "PERSON 319"
    ],
    [
        "PERSON 32",
        "PERSON 32"
    ],
    [
        "PERSON 321",
        "PERSON 321"
    ],
    [
        "PERSON 322",
        "PERSON 322"
    ],
    [
        "PERSON 323",
        "PERSON 323"
    ],
    [
        "PERSON 324",
        "PERSON 324"
    ],
    [
        "PERSON 325",
        "PERSON 325"
    ],
    [
        "PERSON 326",
        "PERSON 326"
    ],
    [
        "PERSON 327",
        "PERSON 327"
    ],
    [
        "PERSON 328",
        "PERSON 328"
    ],
    [
        "PERSON 329",
        "PERSON 329"
    ],
    [
        "PERSON 33",
        "PERSON 33"
    ],
    [
        "PERSON 330",
        "PERSON 330"
    ],
    [
        "PERSON 332",
        "PERSON 332"
    ],
    [
        "PERSON 333",
        "PERSON 333"
    ],
    [
        "PERSON 334",
        "PERSON 334"
    ],
    [
        "PERSON 335",
        "PERSON 335"
    ],
    [
        "PERSON 336",
        "PERSON 336"
    ],
    [
        "PERSON 337",
        "PERSON 337"
    ],
    [
        "PERSON 338",
        "PERSON 338"
    ],
    [
        "PERSON 339",
        "PERSON 339"
    ],
    [
        "PERSON 34",
        "PERSON 34"
    ],
    [
        "PERSON 340",
        "PERSON 340"
    ],
    [
        "PERSON 341",
        "PERSON 341"
    ],
    [
        "PERSON 342",
        "PERSON 342"
    ],
    [
        "PERSON 343",
        "PERSON 343"
    ],
    [
        "PERSON 344",
        "PERSON 344"
    ],
    [
        "PERSON 345",
        "PERSON 345"
    ],
    [
        "PERSON 346",
        "PERSON 346"
    ],
    [
        "PERSON 347",
        "PERSON 347"
    ],
    [
        "PERSON 348",
        "PERSON 348"
    ],
    [
        "PERSON 349",
        "PERSON 349"
    ],
    [
        "PERSON 35",
        "PERSON 35"
    ],
    [
        "PERSON 350",
        "PERSON 350"
    ],
    [
        "PERSON 351",
        "PERSON 351"
    ],
    [
        "PERSON 352",
        "PERSON 352"
    ],
    [
        "PERSON 353",
        "PERSON 353"
    ],
    [
        "PERSON 36",
        "PERSON 36"
    ],
    [
        "PERSON 37",
        "PERSON 37"
    ],
    [
        "PERSON 38",
        "PERSON 38"
    ],
    [
        "PERSON 39",
        "PERSON 39"
    ],
    [
        "PERSON 4",
        "PERSON 4"
    ],
    [
        "PERSON 40",
        "PERSON 40"
    ],
    [
        "PERSON 41",
        "PERSON 41"
    ],
    [
        "PERSON 42",
        "PERSON 42"
    ],
    [
        "PERSON 43",
        "PERSON 43"
    ],
    [
        "PERSON 44",
        "PERSON 44"
    ],
    [
        "PERSON 45",
        "PERSON 45"
    ],
    [
        "PERSON 46",
        "PERSON 46"
    ],
    [
        "PERSON 47",
        "PERSON 47"
    ],
    [
        "PERSON 48",
        "PERSON 48"
    ],
    [
        "PERSON 49",
        "PERSON 49"
    ],
    [
        "PERSON 5",
        "PERSON 5"
    ],
    [
        "PERSON 50",
        "PERSON 50"
    ],
    [
        "PERSON 51",
        "PERSON 51"
    ],
    [
        "PERSON 52",
        "PERSON 52"
    ],
    [
        "PERSON 53",
        "PERSON 53"
    ],
    [
        "PERSON 54",
        "PERSON 54"
    ],
    [
        "PERSON 55",
        "PERSON 55"
    ],
    [
        "PERSON 56",
        "PERSON 56"
    ],
    [
        "PERSON 57",
        "PERSON 57"
    ],
    [
        "PERSON 58",
        "PERSON 58"
    ],
    [
        "PERSON 59",
        "PERSON 59"
    ],
    [
        "PERSON 6",
        "PERSON 6"
    ],
    [
        "PERSON 60",
        "PERSON 60"
    ],
    [
        "PERSON 61",
        "PERSON 61"
    ],
    [
        "PERSON 62",
        "PERSON 62"
    ],
    [
        "PERSON 63",
        "PERSON 63"
    ],
    [
        "PERSON 64",
        "PERSON 64"
    ],
    [
        "PERSON 65",
        "PERSON 65"
    ],
    [
        "PERSON 66",
        "PERSON 66"
    ],
    [
        "PERSON 67",
        "PERSON 67"
    ],
    [
        "PERSON 68",
        "PERSON 68"
    ],
    [
        "PERSON 69",
        "PERSON 69"
    ],
    [
        "PERSON 7",
        "PERSON 7"
    ],
    [
        "PERSON 70",
        "PERSON 70"
    ],
    [
        "PERSON 71",
        "PERSON 71"
    ],
    [
        "PERSON 72",
        "PERSON 72"
    ],
    [
        "PERSON 73",
        "PERSON 73"
    ],
    [
        "PERSON 74",
        "PERSON 74"
    ],
    [
        "PERSON 75",
        "PERSON 75"
    ],
    [
        "PERSON 76",
        "PERSON 76"
    ],
    [
        "PERSON 77",
        "PERSON 77"
    ],
    [
        "PERSON 78",
        "PERSON 78"
    ],
    [
        "PERSON 79",
        "PERSON 79"
    ],
    [
        "PERSON 8",
        "PERSON 8"
    ],
    [
        "PERSON 80",
        "PERSON 80"
    ],
    [
        "PERSON 81",
        "PERSON 81"
    ],
    [
        "PERSON 82",
        "PERSON 82"
    ],
    [
        "PERSON 83",
        "PERSON 83"
    ],
    [
        "PERSON 84",
        "PERSON 84"
    ],
    [
        "PERSON 85",
        "PERSON 85"
    ],
    [
        "PERSON 86",
        "PERSON 86"
    ],
    [
        "PERSON 87",
        "PERSON 87"
    ],
    [
        "PERSON 88",
        "PERSON 88"
    ],
    [
        "PERSON 89",
        "PERSON 89"
    ],
    [
        "PERSON 9",
        "PERSON 9"
    ],
    [
        "PERSON 90",
        "PERSON 90"
    ],
    [
        "PERSON 91",
        "PERSON 91"
    ],
    [
        "PERSON 92",
        "PERSON 92"
    ],
    [
        "PERSON 93",
        "PERSON 93"
    ],
    [
        "PERSON 94",
        "PERSON 94"
    ],
    [
        "PERSON 95",
        "PERSON 95"
    ],
    [
        "PERSON 96",
        "PERSON 96"
    ],
    [
        "PERSON 97",
        "PERSON 97"
    ],
    [
        "PERSON 98",
        "PERSON 98"
    ],
    [
        "PERSON 99",
        "PERSON 99"
    ],
    [
        "RGR LTD.",
        "RGR LTD"
    ],
    [
        "R G R LTD",
        "RGR LTD"
    ],
    [
        "BORINQUEN CONTAINER CORP",
        "BORINQUEN CONTAINER"
    ],
    [
        "LXBXH GAUCH AG",
        "LXBXH GAUCH"
    ],
    [
        "REMPAC LLC",
        "REMPAC"
    ],
    [
        "AMERICAN PRINTPAK INC",
        "AMERICAN PRINTPAK"
    ],
    [
        "BEMIS HEALTHCARE PACKAGING INC",
        "BEMIS HEALTHCARE PACKAGING"
    ],
    [
        "BEMIS",
        "BEMIS HEALTHCARE PACKAGING"
    ],
    [
        "BEMIS HEALTHCARE IRL LTD",
        "BEMIS HEALTHCARE PACKAGING"
    ],
    [
        "BEMIS HEALTHCARE PACKAGING I",
        "BEMIS HEALTHCARE PACKAGING"
    ],
    [
        "BEMIS HEALTHCARE PACKAGING, INC. *",
        "BEMIS HEALTHCARE PACKAGING"
    ],
    [
        "BEMIS HEALTHCARE PACKAGING IRELAND LIMITED",
        "BEMIS HEALTHCARE PACKAGING"
    ],
    [
        "BEMIS HEALTHCARE PAC IRE",
        "BEMIS HEALTHCARE PACKAGING"
    ],
    [
        "BEMIS HEALTHCARE PACKAGING",
        "BEMIS HEALTHCARE PACKAGING"
    ],
    [
        "BEMIS HEALTHCARE PACKAGING INC MANKATO",
        "BEMIS HEALTHCARE PACKAGING"
    ],
    [
        "BEMIS LABORATORY SERVICES",
        "BEMIS HEALTHCARE PACKAGING"
    ],
    [
        "GENERAL CONTAINER CORP",
        "GENERAL CONTAINER"
    ],
    [
        "KENT H  LANDSBERG CO",
        "KENT H LANDSBERG"
    ],
    [
        "KENT H LANDSBERG CO",
        "KENT H LANDSBERG"
    ],
    [
        "KOMAR ALLIANCE",
        "KOMAR ALLIANCE"
    ],
    [
        "MULTIVAC INC",
        "MULTIVAC"
    ],
    [
        "MULTIVAC IRELAND LTD",
        "MULTIVAC"
    ],
    [
        "MULTIVAC",
        "MULTIVAC"
    ],
    [
        "U LINE",
        "ULINE"
    ],
    [
        "ULINE, INC.  *",
        "ULINE"
    ],
    [
        "ULINE INC",
        "ULINE"
    ],
    [
        "ULINE",
        "ULINE"
    ],
    [
        "ULINE   SHIP SUPPLIES",
        "ULINE"
    ],
    [
        "ULINE SHIPPING SUPPLIES, S DE RL DE CV",
        "ULINE"
    ],
    [
        "ULINSE SHIPPIING SUPPLIES",
        "ULINE"
    ],
    [
        "ALCO HIGH TECH PLASTIC INC",
        "ALCO"
    ],
    [
        "AMBS + MORSCH GMBH",
        "AMBS MORSCH"
    ],
    [
        "AMERICAN EAGLE PKG. *",
        "AMERICAN EAGLE PKG"
    ],
    [
        "ARMAND MANUFACTURING INC.",
        "ARMAND MANUFACTURING"
    ],
    [
        "ASSOCIATED BAGREL000",
        "ASSOCIATED BAG"
    ],
    [
        "ATLAS BOX & CRATING CO",
        "ATLAS BOX"
    ],
    [
        "ATLAS BOX",
        "ATLAS BOX"
    ],
    [
        "AXPEL ONE FOR ALL AG",
        "AXPEL ONE FOR ALL"
    ],
    [
        "BERCHTOLD GMBH & CO. KG - 1723",
        "BERCHTOLD"
    ],
    [
        "BOSSAR PACKAGING, S.A",
        "BOSSAR PACKAGING"
    ],
    [
        "CARIBE INDUSTRIAL SYSTEM",
        "CARIBE INDUSTRIAL SYSTEM"
    ],
    [
        "CIM MED GMBH",
        "CIM MED"
    ],
    [
        "CONNAUGHT PACKAGING,",
        "CONNAUGHT PACKAGING"
    ],
    [
        "DS SMITH WIRTH",
        "DS SMITH WIRTH"
    ],
    [
        "DÖSSEL & RADEMACHER",
        "DÖSSEL & RADEMACHER"
    ],
    [
        "DM FOLIEN GMBH",
        "FOLIEN"
    ],
    [
        "GEBR. DÜRRBECK KUNSTSTOFFE GMBH",
        "GEBR DÜRRBECK KUNSTSTOFFE"
    ],
    [
        "GLT TOTAL OFFICE, INC.",
        "GLT TOTAL OFFICE"
    ],
    [
        "GOTTHOLD MÜLLER",
        "GOTTHOLD MÜLLER"
    ],
    [
        "GUSTAV SCHRAMM GMBH",
        "GUSTAV SCHRAMM"
    ],
    [
        "HANS SENN AG",
        "HANS SENN"
    ],
    [
        "HEIZMANN PACKAGING",
        "HEIZMANN PACKAGING"
    ],
    [
        "ICONIX INC",
        "ICONIX"
    ],
    [
        "INDUSTRIAL PACKAGING SUPPLIES",
        "INDUSTRIAL PACKAGING SUPPLIES"
    ],
    [
        "JOHANN RENFER GMBH",
        "JOHANN RENFER"
    ],
    [
        "K.+ C. WEISS GMBH",
        "K C WEISS"
    ],
    [
        "KERN TORANLAGEN",
        "KERN TORANLAGEN"
    ],
    [
        "LANDSBERG ORORA",
        "LANDSBERG ORORA"
    ],
    [
        "LEITWERK AG",
        "LEITWERK"
    ],
    [
        "LEVINE INDUSTRIES INC",
        "LEVINE INDUSTRIES"
    ],
    [
        "MAILLEFER INSTRUMENTS TRADING SÀRL",
        "MAILLEFER INSTRUMENTS TRADING SÀRL"
    ],
    [
        "MALL CITY CONTREL104",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL105",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL107",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL108",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL111",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL112",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL113",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL114",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL115",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL116",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL117",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL118",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL120",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL121",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL122",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL123",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL124",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL125",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL127",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL128",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL129",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL130",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL131",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL132",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL133",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL135",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL136",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL137",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL138",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL140",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL144",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL145",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL148",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL149",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL150",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL153",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL154",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL155",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL156",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL158",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL159",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL160",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL161",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL162",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL163",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL164",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL165",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL166",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL167",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL168",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL169",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL170",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL171",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL172",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL174",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL175",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL176",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL177",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL179",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL180",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL181",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL182",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL183",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL185",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL186",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL187",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL188",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL190",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL191",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL193",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL194",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL195",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL196",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL197",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL198",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL200",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL202",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL203",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL205",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL206",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL209",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL216",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL219",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL221",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL222",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL223",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL224",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL225",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL229",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL241",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL246",
        "MALL CITY CONTAINERS"
    ],
    [
        "MALL CITY CONTREL247",
        "MALL CITY CONTAINERS"
    ],
    [
        "MATRIX WIRE INC.",
        "MATRIX WIRE"
    ],
    [
        "MOTION INDUSTRIES",
        "MOTION INDUSTRIES"
    ],
    [
        "MOTION INDUSTRIES, INC.",
        "MOTION INDUSTRIES"
    ],
    [
        "MOTION INDUSTRIES MI05",
        "MOTION INDUSTRIES"
    ],
    [
        "NORTH AMERICAN *",
        "NORTH AMERICAN"
    ],
    [
        "NYPRO HEALTHCARE BAJA",
        "NYPRO HEALTHCARE BAJA"
    ],
    [
        "PAXXUS",
        "PAXXUS"
    ],
    [
        "PAXXUS, INC. *",
        "PAXXUS"
    ],
    [
        "ROLLPRINT PACKAGING,",
        "PAXXUS"
    ],
    [
        "PCI PROCOATERS INC",
        "PCI PROCOATERS"
    ],
    [
        "PHM PACKAGING SALES, LTD. *",
        "PHM PACKAGING SALES"
    ],
    [
        "PLASTIC ENGINEERING, INC.",
        "PLASTIC ENGINEERING"
    ],
    [
        "PPC INDUSTRIES, INC.",
        "PPC INDUSTRIES"
    ],
    [
        "PRECISION SCALE CO INC",
        "PRECISION SCALE"
    ],
    [
        "PRODINGER OHG",
        "PRODINGER"
    ],
    [
        "ROTHO KUNSTSTOFF AG",
        "ROTHO KUNSTSTOFF"
    ],
    [
        "SAICA PACK",
        "SAICA PACK"
    ],
    [
        "SAICA PACK BESANCON",
        "SAICA PACK"
    ],
    [
        "SHORR PACKAGING CORP. *",
        "SHORR PACKAGING"
    ],
    [
        "SHORR PACKAGING CORP",
        "SHORR PACKAGING"
    ],
    [
        "SNYDER INDUSTRIES INC",
        "SNYDER INDUSTRIES"
    ],
    [
        "SONOCO ELK GROVE, INC  *",
        "SONOCO ELK GROVE"
    ],
    [
        "SUPPLYONE NEW YORK",
        "SUPPLYONE NEW YORK"
    ],
    [
        "SUREWAY SYSTEMS, INC.",
        "SUREWAY SYSTEMS"
    ],
    [
        "TACK PACKAGING",
        "TACK PACKAGING"
    ],
    [
        "TDM JACK-LINE SA",
        "TDM JACK LINE"
    ],
    [
        "GEORG UTZ GMBH",
        "UTZ GROUP"
    ],
    [
        "VALLEN DISTRIBUTION INC",
        "VALLEN DISTRIBUTION"
    ],
    [
        "VLIESSTOFF KASPER",
        "VLIESSTOFF KASPER"
    ],
    [
        "WARNEKE PAPER BOX CO INC",
        "WARNEKE PAPER BOXINC"
    ],
    [
        "WEZ KUNSTSTOFFWERK AG",
        "WEZ KUNSTSTOFFWERK"
    ],
    [
        "WILSON WIRE PRODUCTS, INC.",
        "WILSON WIRE PRODUCTS"
    ],
    [
        "CALCO *",
        "CALCO"
    ],
    [
        "CHICAGO PALLET SERVICE II INC. *",
        "CHICAGO PALLET SERVICE II"
    ],
    [
        "CJ SHEERAN",
        "CJ SHEERAN"
    ],
    [
        "PALETERAS UNIDAS INC",
        "PALETERAS UNIDAS"
    ],
    [
        "TAMANET USA INC.",
        "TAMANET"
    ],
    [
        "INPAC MEDIZINTECHNIK GMBH",
        "INPAC MEDIZINTECHNIK"
    ],
    [
        "SEALED AIR LTD",
        "SEALED AIR"
    ],
    [
        "SEALED AIR CORP",
        "SEALED AIR"
    ],
    [
        "SEALED AIR NELIPAK",
        "SEALED AIR"
    ],
    [
        "CARTOLUX-THIERS",
        "CARTOLUX THIERS"
    ],
    [
        "NELIPAK THERMOFORMIMG",
        "NELIPAK HEALTHCARE PACKAGING"
    ],
    [
        "NELIPAK CORPORATION",
        "NELIPAK HEALTHCARE PACKAGING"
    ],
    [
        "NELIPAK BV",
        "NELIPAK HEALTHCARE PACKAGING"
    ],
    [
        "NELIPAK",
        "NELIPAK HEALTHCARE PACKAGING"
    ],
    [
        "NELIPAK PUERTO RICO",
        "NELIPAK HEALTHCARE PACKAGING"
    ],
    [
        "PAGO AG",
        "PAGO"
    ],
    [
        "TEKTRONIX INC",
        "TEKTRONIX"
    ],
    [
        "ANGST & PFISTER AG",
        "ANGST & PFISTER"
    ],
    [
        "B.LANSADE",
        "B.LANSADE"
    ],
    [
        "BLUM NOVOTEST SARL",
        "BLUM NOVOTEST"
    ],
    [
        "BLUM-NOVOTEST",
        "BLUM NOVOTEST"
    ],
    [
        "COLISAGE",
        "COLISAGE"
    ],
    [
        "COLLINI AG",
        "COLLINI"
    ],
    [
        "DESTRUCTION",
        "DESTRUCTION"
    ],
    [
        "DISCO",
        "DISCO"
    ],
    [
        "ECART PRODUCTION",
        "ECART PRODUCTION"
    ],
    [
        "HONER MEDIZIN-TECHNIK",
        "HONER MEDIZIN TECHNIK"
    ],
    [
        "JH METROLOGY CO., INC. *",
        "JH METROLOGY"
    ],
    [
        "KENT MACHINE INC",
        "KENT MACHINE"
    ],
    [
        "LABOR ZOLLINGER AG",
        "LABOR ZOLLINGER"
    ],
    [
        "LEITNER AG",
        "LEITNER"
    ],
    [
        "LOTUS AUTOMATION (IRELAND) LIMITED",
        "LOTUS AUTOMATION"
    ],
    [
        "LOTUS TECHNICAL SERVICES",
        "LOTUS TECHNICAL SERVICES"
    ],
    [
        "MANUEL GONCALVES",
        "MANUEL GONCALVES"
    ],
    [
        "MATECHPLAST",
        "MATECHPLAST"
    ],
    [
        "MEDEWO AG",
        "MEDEWO"
    ],
    [
        "MEDICAL GROUP CORP",
        "MEDICAL GROUP"
    ],
    [
        "MEDICON INSTRUMENTE",
        "MEDICON INSTRUMENTE"
    ],
    [
        "MESSTECHNIK BLATTER AG",
        "MESSTECHNIK BLATTER"
    ],
    [
        "POLIGRAT FRANCE",
        "POLIGRAT"
    ],
    [
        "PROBLEME ANODISATION VERT",
        "PROBLEME ANODISATION VERT"
    ],
    [
        "PROFESSIONAL FORKLIFT SERVICE INC",
        "PROFESSIONAL FORKLIFT SERVICE"
    ],
    [
        "RMS COMPANY",
        "RMS"
    ],
    [
        "RMS PRECISION",
        "RMS"
    ],
    [
        "SCRAP SUITE NC",
        "SCRAP SUITE NC"
    ],
    [
        "SWISS SAFETY CENTER AG",
        "SWISS SAFETY CENTER"
    ],
    [
        "TEKTRONIX INC. - AMERICAN",
        "TEKTRONIX"
    ],
    [
        "TEKTRONIX SERVICE",
        "TEKTRONIX"
    ],
    [
        "TROIMECA-MEDICA-",
        "TROIMECA MEDICA"
    ],
    [
        "WILLRICH PRECISION INSTRU",
        "WILLRICH PRECISION INST."
    ],
    [
        "WILLRICH PRECISION INST.",
        "WILLRICH PRECISION INST."
    ],
    [
        "METTLER-TOLEDO LLC *",
        "METTLER TOLEDO"
    ],
    [
        "METTLER TOLEDO SA",
        "METTLER TOLEDO"
    ],
    [
        "METTLER-TOLEDO INTERNATIONAL INC",
        "METTLER TOLEDO"
    ],
    [
        "METTLER TOLEDO LTD",
        "METTLER TOLEDO"
    ],
    [
        "METTLER TOLEDO-HI SPEED",
        "METTLER TOLEDO"
    ],
    [
        "ABB LTD",
        "ABB"
    ],
    [
        "ABB LIMITED",
        "ABB"
    ],
    [
        "ABB INC",
        "ABB"
    ],
    [
        "TRUMPF WERKZEUGMASCHINEN",
        "TRUMPF"
    ],
    [
        "TRUMPF LTD",
        "TRUMPF"
    ],
    [
        "TRUMPF",
        "TRUMPF"
    ],
    [
        "TRUMPF SCHWEIZ AG",
        "TRUMPF"
    ],
    [
        "TRUMPF LASER &SYSTEM GMBH",
        "TRUMPF"
    ],
    [
        "TRUMPF INC",
        "TRUMPF"
    ],
    [
        "TRUMPF LIMITED",
        "TRUMPF"
    ],
    [
        "TRUMPF LASER GMBH",
        "TRUMPF"
    ],
    [
        "ACOES",
        "ACOES"
    ],
    [
        "ALPROBOTIC SARL",
        "ALPROBOTIC"
    ],
    [
        "ALPROBOTIC",
        "ALPROBOTIC"
    ],
    [
        "ALPROBOTIC SARL SUISSE",
        "ALPROBOTIC"
    ],
    [
        "AMSONIC AG",
        "AMSONIC"
    ],
    [
        "AMSONIC FRANCE SAS",
        "AMSONIC"
    ],
    [
        "AMSONIC DEUTSCHLAND GMBH",
        "AMSONIC"
    ],
    [
        "AMSONIC SA",
        "AMSONIC"
    ],
    [
        "CELADA FRANCE",
        "CELADA"
    ],
    [
        "DMG MORI FINANCE GMBH",
        "DMG MORI"
    ],
    [
        "DMG MORI SEIKI UK LTD",
        "DMG MORI"
    ],
    [
        "DMG MORI SEIKI FRANCE SAS",
        "DMG MORI"
    ],
    [
        "DMG MICROSET GMBH",
        "DMG MORI"
    ],
    [
        "DMG MORI BERLIN HAMBURG",
        "DMG MORI"
    ],
    [
        "DMG MORI SEIKI HAMBURG",
        "DMG MORI"
    ],
    [
        "DMG MORI EUR",
        "DMG MORI"
    ],
    [
        "DMG MORI SEIKI SCHWEIZ AG",
        "DMG MORI"
    ],
    [
        "DMG MORI SEIKI USA",
        "DMG MORI"
    ],
    [
        "DMG MORI SEIKI USA INC",
        "DMG MORI"
    ],
    [
        "DMG STUTTGART",
        "DMG MORI"
    ],
    [
        "DMG MORI STUTTGART GMBH",
        "DMG MORI"
    ],
    [
        "DMG MORI",
        "DMG MORI"
    ],
    [
        "FRIEDRICH NIEMANN",
        "FRIEDRICH NIEMANN"
    ],
    [
        "GF MACHINING SOLUTIONS",
        "GF MACHINING SOLUTIONS"
    ],
    [
        "GF MACHINING SOLUTIONS LT",
        "GF MACHINING SOLUTIONS"
    ],
    [
        "INDEX FRANCE SARL",
        "INDEX"
    ],
    [
        "KRAFT & BAUER SCHWEIZ AG",
        "KRAFT & BAUER"
    ],
    [
        "KRAFT & BAUER",
        "KRAFT & BAUER"
    ],
    [
        "KRAFT & BAUER SCHWEIZ GMBH",
        "KRAFT & BAUER"
    ],
    [
        "REALMECA",
        "REALMECA"
    ],
    [
        "ROLLOMATIC SA",
        "ROLLOMATIC"
    ],
    [
        "ROLLOMATIC INC",
        "ROLLOMATIC"
    ],
    [
        "SCHAUBLIN MACHINES SA",
        "SCHAUBLIN MACHINES"
    ],
    [
        "SCHNEITTER AG",
        "SCHNEITTER"
    ],
    [
        "SCHUNK GMBH & CO. KG",
        "SCHUNK"
    ],
    [
        "SCHUNK INTEC SARL",
        "SCHUNK"
    ],
    [
        "SCHUNK INTEC AG",
        "SCHUNK"
    ],
    [
        "SCHUNK GMBH & CO.KG",
        "SCHUNK"
    ],
    [
        "SCHUNK UK LTD",
        "SCHUNK"
    ],
    [
        "STAUBLI FAVERGES SCA",
        "STAUBLI"
    ],
    [
        "STAUBLI AG",
        "STAUBLI"
    ],
    [
        "SUVEMA AG",
        "SUVEMA"
    ],
    [
        "VA TECHNOLOGY LTD",
        "VA TECHNOLOGY"
    ],
    [
        "WENK AG WERKZEUGMASCHINEN",
        "WENK AG"
    ],
    [
        "WILLEMIN-MACODEL S.A.",
        "WILLEMIN MACODEL"
    ],
    [
        "WILLEMIN MACODEL",
        "WILLEMIN MACODEL"
    ],
    [
        "1000SHAPES GMBH",
        "1000SHAPES"
    ],
    [
        "A & B PROCESS SYSTEMS CO. *",
        "A & B PROCESS SYSTEMS"
    ],
    [
        "A-1 REFRIGERATION SALES &",
        "A 1 REFRIGERATION SALES"
    ],
    [
        "A BOILER TECH",
        "A BOILER TECH"
    ],
    [
        "A-KORN ROLLER, INC.",
        "A KORN ROLLER"
    ],
    [
        "A&O ELECTRIC",
        "A&O ELECTRIC"
    ],
    [
        "ABSAUGTECHNIK WILD AG",
        "ABSAUGTECHNIK WILD"
    ],
    [
        "ACCESS COMMERCIAL DOOR COMPANY",
        "ACCESS COMMERCIAL DOOR"
    ],
    [
        "ACCRAPLY, INC. *",
        "ACCRAPLY"
    ],
    [
        "ACCU-SHRED INC",
        "ACCU SHRED"
    ],
    [
        "ACCURATE FABRICATORS LLC",
        "ACCURATE FABRICATORS"
    ],
    [
        "ACCURATE SECURITY OF RAMSEY INC",
        "ACCURATE SECURITY OF RAMSEY"
    ],
    [
        "ACCUWEB, INC *",
        "ACCUWEB"
    ],
    [
        "ADAMS AIR & HYDRAULICS INC",
        "ADAMS AIR & HYDRAULICS"
    ],
    [
        "ADAM'S AIR & HYDRAULICS, INC.",
        "ADAMS AIR & HYDRAULICS"
    ],
    [
        "ADCOM WORLDWIDE MIA",
        "ADCOM WORLDWIDE MIA"
    ],
    [
        "ADDITIVE MANUFACTURING",
        "ADDITIVE MANUFACTURING"
    ],
    [
        "ADVANCED DYNAMICS LTD",
        "ADVANCED DYNAMICS"
    ],
    [
        "ADVANCED GAS SYSTEMS LTD",
        "ADVANCED GAS SYSTEMS"
    ],
    [
        "ADVANCED TECH.CONCEPTS,",
        "ADVANCED TECH CONCEPTS"
    ],
    [
        "AE APPLIED ENGINEERING SOLUTIONS",
        "AE APPLIED ENGINEERING SOLUTIONS"
    ],
    [
        "AEC, INC. *",
        "AEC"
    ],
    [
        "AES CLEAN TECHNOLOGY INC",
        "AES CLEAN TECHNOLOGY"
    ],
    [
        "AES FIRE LLC",
        "AES FIRE"
    ],
    [
        "AFFILIATED CONTROLS *",
        "AFFILIATED CONTROLS"
    ],
    [
        "AGL WELDING SUPPLY CO INC",
        "AGL WELDING SUPPLY"
    ],
    [
        "AICON 3D SYSTEMS GMBH",
        "AICON 3D SYSTEMS"
    ],
    [
        "AIR COMPRESSOR WORKS INC",
        "AIR COMPRESSOR WORKS"
    ],
    [
        "AIREQUIPO INC",
        "AIREQUIPO"
    ],
    [
        "AIRFLOW SERVICES LTD",
        "AIRFLOW SERVICES"
    ],
    [
        "AIRLINE HYDRAULICS CORP",
        "AIRLINE HYDRAULICS"
    ],
    [
        "AISWARE PRODUCTS",
        "AISWARE PRODUCTS"
    ],
    [
        "AJ'S PAINTING",
        "AJ S PAINTING"
    ],
    [
        "AJM COMPORT",
        "AJM COMPORT"
    ],
    [
        "ALFRED GABRIEL GMBH &CO.KG",
        "ALFRED GABRIEL"
    ],
    [
        "ALL CONTROL *",
        "ALL CONTROL"
    ],
    [
        "ALL FIVE CONCRETE CUTTING",
        "ALL FIVE CONCRETE CUTTING"
    ],
    [
        "ALMEIDA SEWING MACHINE",
        "ALMEIDA SEWING MACHINE"
    ],
    [
        "ALOS GMBH",
        "ALOS"
    ],
    [
        "ALRO METALS SERVICE CENTER",
        "ALRO"
    ],
    [
        "ALRO STEEL (PLASTICS) CORPORATION",
        "ALRO STEEL"
    ],
    [
        "ALTERNATIVE CUSTOM CRATING",
        "ALTERNATIVE CUSTOM CRATING"
    ],
    [
        "ALUGYP LTD.,",
        "ALUGYP"
    ],
    [
        "ALVISA HOLDING AG",
        "ALVISA HOLDING"
    ],
    [
        "AMADA MIYACHI EUROPE GMBH",
        "AMADA"
    ],
    [
        "AMERICOAT CORP, INC.",
        "AMERICOAT"
    ],
    [
        "AMI MACHINING INC",
        "AMI MACHINING"
    ],
    [
        "ANDERSON PEST CONTROL *",
        "ANDERSON PEST CONTROL"
    ],
    [
        "ANGEL J GONZALEZ",
        "ANGEL J GONZALEZ"
    ],
    [
        "ANIMAL REMOVAL SERVICE LLC",
        "ANIMAL REMOVAL SERVICE"
    ],
    [
        "ANTONIUS KÖSTER GMBH & CO. KG",
        "ANTONIUS KÖSTER"
    ],
    [
        "APICS",
        "APICS"
    ],
    [
        "APIKAL DRUCKLUFTTECHNIK GMBH",
        "APIKAL DRUCKLUFTTECHNIK"
    ],
    [
        "APIS-INFO-TECHNOLOGIE GMBH",
        "APIS INFO TECHNOLOGIE"
    ],
    [
        "ARBON EQUIPMENT CORP",
        "ARBON EQUIPMENT"
    ],
    [
        "ARGONAG AG",
        "ARGONAG"
    ],
    [
        "ARION WATER INC *",
        "ARION WATER"
    ],
    [
        "ARTCRAFT WELDING INC.",
        "ARTCRAFT WELDING"
    ],
    [
        "ASH EQUIPMENT CO. INC.",
        "ASH EQUIPMENT"
    ],
    [
        "ASSOCIATED RESEARCH, INC.",
        "ASSOCIATED RESEARCH"
    ],
    [
        "ASTRO PAK CORPORATION",
        "ASTRO PAK"
    ],
    [
        "ATLAS COPCO COMPRESSORS LLC",
        "ATLAS COPCO COMPRESSORS"
    ],
    [
        "ATLAS FIRST ACCESS LLC",
        "ATLAS FIRST ACCESS"
    ],
    [
        "AURORA AIR PRODUCTS,INC.",
        "AURORA AIR PRODUCTS"
    ],
    [
        "AUTEFA SOLUTIONS NORTH AMERICA *",
        "AUTEFA SOLUTIONS NORTH"
    ],
    [
        "AUTO DOOR ENGINEERING LTD",
        "AUTO DOOR ENGINEERING"
    ],
    [
        "AUTOMATED PACKAGING SYSTEMS INC",
        "AUTOMATED PACKAGING SYSTEMS"
    ],
    [
        "AUTOMATIC EQUIPMENT SALES & SE",
        "AUTOMATIC EQUIPMENT SALES"
    ],
    [
        "AUTOMATIC FEEDER CO. INC.",
        "AUTOMATIC FEEDER"
    ],
    [
        "AUTOMATIC SPRINKLER CO",
        "AUTOMATIC SPRINKLER"
    ],
    [
        "AV TECHSOURCE, INC. *",
        "AV TECHSOURCE"
    ],
    [
        "AVONMORE ELECTRICAL LTD",
        "AVONMORE ELECTRICAL"
    ],
    [
        "B & H TECHNICAL SERVICES",
        "B & H TECHNICAL SERVICES"
    ],
    [
        "B.H.C.CODING SYSTEMS LTD.",
        "B.H.C.CODING SYSTEMS"
    ],
    [
        "B.L. HARROUN & SON, INC.",
        "B.L. HARROUN & SON"
    ],
    [
        "BAJA",
        "BAJA"
    ],
    [
        "BALANCE TECHNOLOGIES",
        "BALANCE TECHNOLOGIES"
    ],
    [
        "BALLUFF SENSORTECHNIK AG",
        "BALLUFF SENSORTECHNIK"
    ],
    [
        "BARR MECHANICAL SALES, INC.",
        "BARR MECHANICAL SALES"
    ],
    [
        "BASX SOLUTIONS, LLC",
        "BASX SOLUTIONS"
    ],
    [
        "BATES WATER SOLUTIONS, INC*",
        "BATES WATER SOLUTIONS"
    ],
    [
        "BAUSCH & STROBEL",
        "BAUSCH & STROBEL"
    ],
    [
        "BAY CITY SCALE INCORPORATED",
        "BAY CITY SCALE INCORPORATED"
    ],
    [
        "BDI - BEARING DIST. INC.",
        "BDI - BEARING DIST."
    ],
    [
        "BECKMAN COULTER INC",
        "BECKMAN COULTER"
    ],
    [
        "BENCHMARK AUTOMATION,LLC *",
        "BENCHMARK AUTOMATION"
    ],
    [
        "BERCAR WELDING LTD.,",
        "BERCAR WELDING"
    ],
    [
        "BERFIELD AUTOMATION",
        "BERFIELD AUTOMATION"
    ],
    [
        "BERTHOLD HERMLE",
        "BERTHOLD HERMLE"
    ],
    [
        "BEST CUTTING DIE COMPANY",
        "BEST CUTTING DIE COMPANY"
    ],
    [
        "BILL'S DECORATING *",
        "BILL S DECORATING"
    ],
    [
        "BILL'S LOCK SHOP INC",
        "BILL S LOCK SHOP"
    ],
    [
        "BLACKMAN PLUMBING SUPPLY",
        "BLACKMAN PLUMBING SUPPLY"
    ],
    [
        "BLM GROUP USA CORP",
        "BLM GROUP"
    ],
    [
        "BLUM BETONBOHRSERVICE",
        "BLUM BETONBOHRSERVICE"
    ],
    [
        "BMF REINRAUMTECHNIK GMBH",
        "BMF REINRAUMTECHNIK"
    ],
    [
        "BNNETZE GMBH",
        "BNNETZE"
    ],
    [
        "BOC EDWARDS GMBH",
        "BOC EDWARDS"
    ],
    [
        "BOILER ONE INC",
        "BOILER ONE"
    ],
    [
        "BOLKEMA FUEL COMPANY",
        "BOLKEMA FUEL"
    ],
    [
        "BOMMERSCHEIM WINDOW &",
        "BOMMERSCHEIM WINDOW"
    ],
    [
        "BORNSCHEIN + S÷RENSEN",
        "BORNSCHEIN S÷RENSEN"
    ],
    [
        "BOSCH PACKAGING SERVICES, INC. *",
        "BOSCH PACKAGING SERVICES"
    ],
    [
        "BP INVESTORS LLC",
        "BP INVESTORS"
    ],
    [
        "BPI MEDICAL INC",
        "BPI MEDICAL"
    ],
    [
        "BRAAS COMPANY, INC.",
        "BRAAS COMPANY"
    ],
    [
        "BRECOFLEX CO., L.L.C.*",
        "BRECOFLEX L L C"
    ],
    [
        "BRIAN A FLYNN LTD",
        "BRIAN A FLYNN"
    ],
    [
        "BRICE INDUSTRIES, INC.",
        "BRICE INDUSTRIES"
    ],
    [
        "BRIGEL AG",
        "BRIGEL"
    ],
    [
        "BRISTOL COMMERCIAL",
        "BRISTOL COMMERCIAL"
    ],
    [
        "BRODERICK BROS.LTD",
        "BRODERICK BROS"
    ],
    [
        "BRUKER UK LTD",
        "BRUKER"
    ],
    [
        "BSC BRANDSCHUTZ SERVICE CENTER",
        "BSC BRANDSCHUTZ SERVICE CENTER"
    ],
    [
        "BTTECH PEITA GIAN-RETO",
        "BTTECH PEITA GIAN RETO"
    ],
    [
        "BUG DOCTOR",
        "BUG DOCTOR"
    ],
    [
        "BUIST ELECTRIC, INC.",
        "BUIST ELECTRIC"
    ],
    [
        "BURGOLARM SECURITY PRODS",
        "BURGOLARM SECURITY PRODS"
    ],
    [
        "BUSCH IRELAND LTD",
        "BUSCH"
    ],
    [
        "BUSCH LLC.",
        "BUSCH"
    ],
    [
        "BVK US II HOLDING LLC",
        "BVK US II HOLDING"
    ],
    [
        "BVS",
        "BVS"
    ],
    [
        "BWT WASSERTECHNIK GMBH",
        "BWT WASSERTECHNIK"
    ],
    [
        "C. STODDARD & SONS INC",
        "C STODDARD & SONS"
    ],
    [
        "C3 COPORATION *",
        "C3 COPORATION"
    ],
    [
        "CAB PRODUKTTECHNIK",
        "CAB PRODUKTTECHNIK"
    ],
    [
        "CAMBRIDGE VACUUM ENGINEER",
        "CAMBRIDGE VACUUM ENGINEER"
    ],
    [
        "CAMDEN TOOL",
        "CAMDEN TOOL"
    ],
    [
        "CAMPBELL FIRE PROTECTION INC",
        "CAMPBELL FIRE PROTECTION"
    ],
    [
        "CARESTREAM HEALTH INC",
        "CARESTREAM HEALTH"
    ],
    [
        "CARL STAHL NORD GMBH",
        "CARL STAHL NORD"
    ],
    [
        "CARLETON TECHNOLOGIES INC",
        "CARLETON TECHNOLOGIES"
    ],
    [
        "CARPENTER ENVIRONMENTAL ASSOCIATES INC",
        "CARPENTER ENVIRONMENTAL ASSOCIATES"
    ],
    [
        "CARR LANE MANUFACTURING CO.",
        "CARR LANE MANUFACTURING"
    ],
    [
        "CARRIAGE GLASS & MIRROR",
        "CARRIAGE GLASS & MIRROR"
    ],
    [
        "CAS CLEAN-AIR-SERVICE AG",
        "CAS CLEAN AIR SERVICE"
    ],
    [
        "CASA DEL CAFE",
        "CASA DEL CAFE"
    ],
    [
        "CATERING ENVIRONMENTAL",
        "CATERING ENVIRONMENTAL"
    ],
    [
        "CCI MECHANICAL - SALT LAK",
        "CCI MECHANICAL"
    ],
    [
        "CDS PLASTICS",
        "CDS PLASTICS"
    ],
    [
        "CDW DIR #PSR7743",
        "CDW"
    ],
    [
        "CEM TECHNOLOGY (IRELAND)",
        "CEM TECHNOLOGY"
    ],
    [
        "CENTRAL TILE AND TERRAZZO CO",
        "CENTRAL TILE AND TERRAZZO"
    ],
    [
        "CG AUTOMATION & FIXTURE,",
        "CG AUTOMATION & FIXTURE"
    ],
    [
        "CHASE",
        "CHASE"
    ],
    [
        "CHASE MACHINE & ENGINEERING INC.",
        "CHASE MACHINE & ENGINEERING"
    ],
    [
        "CHEM AQUA",
        "CHEM AQUA"
    ],
    [
        "CHEMICAL SYSTEMS CONTROL",
        "CHEMICAL SYSTEMS CONTROL"
    ],
    [
        "CHEP CONTAINER AND POOLING SOLUTIONS INC.",
        "CHEP CONTAINER AND POOLING SOLUTIONS"
    ],
    [
        "CHICAGO HOLLOW METAL,INC.*",
        "CHICAGO HOLLOW METAL"
    ],
    [
        "CHICAGO TIRE",
        "CHICAGO TIRE"
    ],
    [
        "CHIRON-WERKE GMBH&CO.KG",
        "CHIRON WERKE"
    ],
    [
        "CINCINNATI TEST SYSTEMS",
        "CINCINNATI TEST SYSTEMS"
    ],
    [
        "CINCINNATI TEST SYSTEMS INC",
        "CINCINNATI TEST SYSTEMS"
    ],
    [
        "CIRCUIT BREAKER SALES NE INC",
        "CIRCUIT BREAKER SALES"
    ],
    [
        "CISCO INC",
        "CISCO"
    ],
    [
        "CLASS 1 AIR, INC.",
        "CLASS 1 AIR"
    ],
    [
        "CLEAN HARBORS ENVIRONMENTAL SERVICES,INC",
        "CLEAN HARBORS ENVIRONMENTAL SERVICES"
    ],
    [
        "PH-CLEANTEC GMBH",
        "CLEANTEC"
    ],
    [
        "CLEANWELL IND. LTD.,",
        "CLEANWELL"
    ],
    [
        "CLEANZONE TECHNOLOGY LTD",
        "CLEANZONE TECHNOLOGY"
    ],
    [
        "CLEVELAND VIBRATOR CO.",
        "CLEVELAND VIBRATOR"
    ],
    [
        "CLICKCONCEPTS GMBH",
        "CLICKCONCEPTS"
    ],
    [
        "CLINICAL DYNAMICS CORPORATION",
        "CLINICAL DYNAMICS"
    ],
    [
        "CMP ANODIZING",
        "CMP ANODIZING"
    ],
    [
        "CMS-GMBH",
        "CMS"
    ],
    [
        "CODICO DISTRIBUTORS",
        "CODICO DISTRIBUTORS"
    ],
    [
        "CODICO DISTRIBUTORS LTD",
        "CODICO DISTRIBUTORS"
    ],
    [
        "COFESSCO FIRE PROTECTION LLC",
        "COFESSCO FIRE PROTECTION"
    ],
    [
        "COGNEX CORPORATION",
        "COGNEX"
    ],
    [
        "COHERENT INC",
        "COHERENT"
    ],
    [
        "COHERENT MUNICH GMBH & CO. KG",
        "COHERENT MUNICH"
    ],
    [
        "COLTER PRECISION, INC.",
        "COLTER PRECISION"
    ],
    [
        "COLUMBIA PIPE & SUPPLY CO",
        "COLUMBIA PIPE & SUPPLY"
    ],
    [
        "COMCO, INC",
        "COMCO"
    ],
    [
        "COMPUCAL CALIBRATION",
        "COMPUCAL CALIBRATION"
    ],
    [
        "CONACK CONSTRUCTION LTD",
        "CONACK CONSTRUCTION"
    ],
    [
        "CONCORD BOILER ENG. LTD",
        "CONCORD BOILER ENG"
    ],
    [
        "CONCRETE WORKS OF MICHIGAN, IN",
        "CONCRETE WORKS OF MICHIGAN"
    ],
    [
        "CONDAIR GMBH",
        "CONDAIR"
    ],
    [
        "CONSERV BUILDING SERVICES INC",
        "CONSERV BUILDING SERVICES"
    ],
    [
        "CONTRACT MEDICAL MFG",
        "CONTRACT MEDICAL MFG"
    ],
    [
        "CONTRACTORS SERVICES, INC.",
        "CONTRACTORS SERVICES"
    ],
    [
        "CONTROLLED ENVIRONMENT SO",
        "CONTROLLED ENVIRONMENT"
    ],
    [
        "CONVEYOR SOLUTIONS, INC. *",
        "CONVEYOR SOLUTIONS"
    ],
    [
        "CORTINAS",
        "CORTINAS"
    ],
    [
        "CORTINAS DE ACERO FRONTERIZA SA DE CV",
        "CORTINAS DE ACERO FRONTERIZA"
    ],
    [
        "COVAL VACUUM TECHNOLOGY",
        "COVAL VACUUM TECHNOLOGY"
    ],
    [
        "CQL",
        "CQL"
    ],
    [
        "CREATIVE DINING SERVICES, INC.",
        "CREATIVE DINING SERVICES"
    ],
    [
        "CREST ULTRASONICS, INC.",
        "CREST ULTRASONICS"
    ],
    [
        "CRT CLEANROOM-TECHNOLOGY",
        "CRT CLEANROOM TECHNOLOGY"
    ],
    [
        "CSE",
        "CSE"
    ],
    [
        "CUBICLE KEYS",
        "CUBICLE KEYS"
    ],
    [
        "CULLIGAN WATER",
        "CULLIGAN WATER"
    ],
    [
        "CUMMINS ALLISON CORP",
        "CUMMINS ALLISON"
    ],
    [
        "CUMMINS BRIDGEWAY, LLC.",
        "CUMMINS BRIDGEWAY"
    ],
    [
        "CUMMINS NPOWER",
        "CUMMINS NPOWER"
    ],
    [
        "CUMMINS-WAGNER FL LLC",
        "CUMMINSWAGNER FL"
    ],
    [
        "CYNERGY ERGONOMICS",
        "CYNERGY ERGONOMICS"
    ],
    [
        "CYPRESS DOOR AND LOCKSMITH",
        "CYPRESS DOOR AND LOCKSMITH"
    ],
    [
        "D.T.S LTD.,",
        "D T S"
    ],
    [
        "DALE W HUBBARD",
        "DALE W HUBBARD"
    ],
    [
        "DALE W HUBBARD INC",
        "DALE W HUBBARD"
    ],
    [
        "DALE W. HUBBARD, INC.",
        "DALE W HUBBARD"
    ],
    [
        "DALMEC, INC.",
        "DALMEC"
    ],
    [
        "DAP",
        "DAP"
    ],
    [
        "DASI SOLUTIONS",
        "DASI SOLUTIONS"
    ],
    [
        "DAVID MEADE FIT FURNITURE",
        "DAVID MEADE FIT FURNITURE"
    ],
    [
        "DEKRA AUTOMOBIL GMBH",
        "DEKRA INDUSTRIAL"
    ],
    [
        "DELTA DELIVERY",
        "DELTA DELIVERY"
    ],
    [
        "DEMONTAGE DAMBROWSKI",
        "DEMONTAGE DAMBROWSKI"
    ],
    [
        "DENGEL ASSOCIATES",
        "DENGEL ASSOCIATES"
    ],
    [
        "DEUTSCHE METROHM GMBH & CO. KG",
        "DEUTSCHE METROHM"
    ],
    [
        "DG SYSTEMS LTD",
        "DG SYSTEMS"
    ],
    [
        "DIAMANTTECHNIK DAHER, INH.",
        "DIAMANTTECHNIK DAHER INH"
    ],
    [
        "DIFFLEY-WRIGHT CORPORATION",
        "DIFFLEY WRIGHT"
    ],
    [
        "DIGI-KEY CORPORATION *",
        "DIGI-KEY"
    ],
    [
        "DIGI-KEY CORPORATION, INC.",
        "DIGIKEY"
    ],
    [
        "DILLON TOYOTA LIFT",
        "DILLON TOYOTA LIFT"
    ],
    [
        "DILO INC. *",
        "DILO"
    ],
    [
        "DIPL. ING. EGBERT MⁿCKE",
        "DIPL ING EGBERT MⁿCKE"
    ],
    [
        "DIRECT FITNESS *",
        "DIRECT FITNESS"
    ],
    [
        "DME COMPANY",
        "DME"
    ],
    [
        "DOMINIK ERHART GARTENGESTALTUNG",
        "DOMINIK ERHART GARTENGESTALTUNG"
    ],
    [
        "DOOR SYSTEMS *",
        "DOOR SYSTEMS"
    ],
    [
        "DORANIX LLC",
        "DORANIX LLC"
    ],
    [
        "DOUGLAS MACHINES CORP.",
        "DOUGLAS MACHINES"
    ],
    [
        "DR. EILEBRECHT SSE GMBH & CO. KG",
        "DR EILEBRECHT SSE"
    ],
    [
        "DR.ING.MANFRID DREHER GMBH&CO.KG",
        "DR ING MANFRID DREHER"
    ],
    [
        "DR. MED. BEAT FLURY",
        "DR MED BEAT FLURY"
    ],
    [
        "DR. MED. KRYSTYNA JÄGGI",
        "DR MED KRYSTYNA JÄGGI"
    ],
    [
        "DR.O.K.WACK CHEMIE GMBH",
        "DR O K WACK CHEMIE"
    ],
    [
        "DR.WEHNER, JUNGMANN & PARTNER",
        "DR WEHNER JUNGMANN & PARTNER"
    ],
    [
        "DRAINBUSTERS INC",
        "DRAINBUSTERS"
    ],
    [
        "DRUCKLUFT EVERS NORD GMBH",
        "DRUCKLUFT EVERS NORD"
    ],
    [
        "DUROVIS AG",
        "DUNNER"
    ],
    [
        "DUWE-3D AG",
        "DUWE 3D"
    ],
    [
        "DVK SÄGETECHNIK GMBH",
        "DVK SÄGETECHNIK"
    ],
    [
        "DYCEM CORPORATION *",
        "DYCEM"
    ],
    [
        "DYNAMIC ELECTRONICS, INC.",
        "DYNAMIC ELECTRONICS"
    ],
    [
        "DYNO GROUP SERVICES LTD.,",
        "DYNO GROUP SERVICES"
    ],
    [
        "E B MANUFACTURING CO INC",
        "E B MANUFACTURING"
    ],
    [
        "E-CYCLING PUERTO RICO INC",
        "E CYCLING"
    ],
    [
        "E. LINK GMBH & CO.",
        "E LINK"
    ],
    [
        "E. ZOLLER GMBH & CO. KG",
        "E ZOLLER"
    ],
    [
        "EAST POLK LLC",
        "EAST POLK LLC"
    ],
    [
        "EASTMAN",
        "EASTMAN"
    ],
    [
        "ECOJET DRAIN SERVICES",
        "ECOJET DRAIN SERVICES"
    ],
    [
        "EDWARDS VACUUM, INC.",
        "EDWARDS VACUUM"
    ],
    [
        "EFT ENERGY",
        "EFT ENERGY"
    ],
    [
        "ELECTRONIC DRIVES & CONTROLS INC",
        "ELECTRONIC DRIVES & CONTROLS"
    ],
    [
        "ELECTROTORQUE INC",
        "ELECTROTORQUE"
    ],
    [
        "ELLAB INC",
        "ELLAB"
    ],
    [
        "ELPRO SERVICES, INC. - MA",
        "ELPRO SERVICES"
    ],
    [
        "EM SCHWEIZ AG",
        "EM SCHWEIZ"
    ],
    [
        "EMIL EGGER AG",
        "EMIL EGGER"
    ],
    [
        "EMMET O'MAHONY LANDSCAPIN",
        "EMMET O'MAHONY LANDSCAPIN"
    ],
    [
        "EMOTEC AG",
        "EMOTEC"
    ],
    [
        "EMT INTERNATIONAL, INC. *",
        "EMT INTERNATIONAL"
    ],
    [
        "GS ENERGIETECHNIK",
        "ENERGIETECHNIK"
    ],
    [
        "ENGEL DEUTSCHLAND GMBH",
        "ENGEL"
    ],
    [
        "ENGINEERED CORROSION SOL",
        "ENGINEERED CORROSION SOL"
    ],
    [
        "ENVISION TECHNOLOGIES",
        "ENVISION TECHNOLOGIES"
    ],
    [
        "EQLAB",
        "EQLAB"
    ],
    [
        "ERIC LYONS",
        "ERIC LYONS"
    ],
    [
        "ERWIN QUARDER",
        "ERWIN QUARDER"
    ],
    [
        "ETL ELECTRONIC TECHN LTD",
        "ETL ELECTRONIC TECHN"
    ],
    [
        "EVENTTONE WIRELESS",
        "EVENTTONE WIRELESS"
    ],
    [
        "EXCEED FLOORING",
        "EXCEED FLOORING"
    ],
    [
        "FABRIK INDUSTRIES, INC. *",
        "FABRIK INDUSTRIES"
    ],
    [
        "FALCRO",
        "FALCRO"
    ],
    [
        "FAMES AG",
        "FAMES"
    ],
    [
        "FECHT HYDRAULIK GMBH",
        "FECHT HYDRAULIK"
    ],
    [
        "FENSTERBAU SICHLER GMBH & CO. KG",
        "FENSTERBAU SICHLER"
    ],
    [
        "FENTRA ASSOCIATES LTD",
        "FENTRA ASSOCIATES"
    ],
    [
        "FETAXID AG",
        "FETAXID"
    ],
    [
        "FEUERSCHUTZ TEC STUTTGART GMBH",
        "FEUERSCHUTZ TEC STUTTGART"
    ],
    [
        "FIKE CORPORATION *",
        "FIKE"
    ],
    [
        "FINLEY PRECISION SPINDLES *",
        "FINLEY PRECISION SPINDLES"
    ],
    [
        "FLANDERS ELECTRIC MOTOR SERVICE INC",
        "FLANDERS ELECTRIC MOTOR SERVICE"
    ],
    [
        "FLEMING MEDICAL LTD.",
        "FLEMING MEDICAL"
    ],
    [
        "FLIR COMMERCIAL SYSTEMS INC.",
        "FLIR COMMERCIAL SYSTEMS"
    ],
    [
        "FLODYNE, INC.",
        "FLODYNE"
    ],
    [
        "FLURY TOOLS AG",
        "FLURY TOOLS"
    ],
    [
        "FLÜCK FÖRDERTECHNIK AG",
        "FLÜCK FÖRDERTECHNIK"
    ],
    [
        "FOG CREEK SOFTWARE",
        "FOG CREEK SOFTWARE"
    ],
    [
        "FOGG",
        "FOGG"
    ],
    [
        "FOLIUM",
        "FOLIUM"
    ],
    [
        "FOOD EQUIPMENT SOLUTIONS INC",
        "FOOD EQUIPMENT SOLUTIONS"
    ],
    [
        "FORCK F÷RDE HYDRAULIK GMBH",
        "FORCK F÷RDE HYDRAULIK"
    ],
    [
        "FORMAT SOFTWARE SERVICE GMBH",
        "FORMAT SOFTWARE SERVICE"
    ],
    [
        "FORMLABS GMBH",
        "FORMLABS"
    ],
    [
        "FRANKE + PAHL GMBH",
        "FRANKE PAHL"
    ],
    [
        "FROGLOGIC GMBH",
        "FROGLOGIC"
    ],
    [
        "FUCHS UMWELTTECHNIK",
        "FUCHS UMWELTTECHNIK"
    ],
    [
        "FUMEX, INC.",
        "FUMEX"
    ],
    [
        "G & O THERMAL SUPPLY COMPANY",
        "G & O THERMAL SUPPLY"
    ],
    [
        "GAERTNER HAUSTECHNIK GMBH",
        "GAERTNER HAUSTECHNIK"
    ],
    [
        "GALLMANN CO INC",
        "GALLMANN"
    ],
    [
        "GATECH",
        "GATECH"
    ],
    [
        "GCA SERVICES GROUP OF NORTH CAROLINA INC",
        "GCA SERVICES GROUP OF NORTH CAROLINA"
    ],
    [
        "GEA FOOD SOLUTIONS NORTH",
        "GEA FOOD"
    ],
    [
        "GEHRIG GROUP AG",
        "GEHRIG GROUP"
    ],
    [
        "GENESIS AUTOMATION, INC.*",
        "GENESIS AUTOMATION"
    ],
    [
        "GERSTEL, INC. *",
        "GERSTEL"
    ],
    [
        "GERUESTBAU VOGEL GMBH",
        "GERUESTBAU VOGEL"
    ],
    [
        "GEW (EC) LIMITED",
        "GEW (EC)"
    ],
    [
        "GIBRALTAR LABS INC",
        "GIBRALTAR LABS"
    ],
    [
        "GLASS TECH LTD",
        "GLASS TECH"
    ],
    [
        "GLOBAL CONSTRUCTION ASSOC",
        "GLOBAL CONSTRUCTION ASSOC"
    ],
    [
        "GOSIGER INCORPORATED",
        "GOSIGER"
    ],
    [
        "GRIBI MESSTECHNIK AG",
        "GRIBI MESSTECHNIK"
    ],
    [
        "GTI GRAPHIC TECH. INC.",
        "GTI GRAPHIC TECH"
    ],
    [
        "GUARDIAN MANUFACTURING INC",
        "GUARDIAN MANUFACTURING"
    ],
    [
        "GUARDIAN POWER CLEANING INC",
        "GUARDIAN POWER CLEANING"
    ],
    [
        "GULF CONTROLS COMPANY INC",
        "GULF CONTROLS COMPANY"
    ],
    [
        "GULF ELECTRIC COMPANY",
        "GULF ELECTRIC COMPANY"
    ],
    [
        "GULGREN'S TV & APPLIANCES",
        "GULGREN S TV & APPLIANCES"
    ],
    [
        "GUYSON",
        "GUYSON"
    ],
    [
        "GVE GLOBAL VISION INC",
        "GVE GLOBAL VISION"
    ],
    [
        "H.R. STEWART,INC.",
        "H R STEWART"
    ],
    [
        "H. STREULI AG",
        "H STREULI"
    ],
    [
        "H. THORMΣHLEN GMBH & CO. KG",
        "H THORMΣHLEN"
    ],
    [
        "H. THORM‰HLEN GMBH & CO. KG",
        "H THORM‰HLEN"
    ],
    [
        "H&MV ENGINEERING LTD",
        "H& ENGINEERING"
    ],
    [
        "HABICH DECORATING & *",
        "HABICH DECORATING"
    ],
    [
        "HACH LANGE GMBH",
        "HACH"
    ],
    [
        "HANLEY AUTOMATION NETWORK",
        "HANLEY AUTOMATION NETWORK"
    ],
    [
        "HANLY'S GARDEN MACHINERY",
        "HANLY'S GARDEN MACHINERY"
    ],
    [
        "HARDY PROCESS SOLUTIONS",
        "HARDY PROCESS SOLUTIONS"
    ],
    [
        "HARRINGTON INDUSTRIAL",
        "HARRINGTON INDUSTRIAL"
    ],
    [
        "HASSLER BLITZSCHUTZ + ELEKTRO",
        "HASSLER BLITZSCHUTZ ELEKTRO"
    ],
    [
        "HAWO GERÄTEBAU GMBH",
        "HAWO GERÄTEBAU"
    ],
    [
        "HAYES PUMP INC",
        "HAYES PUMP"
    ],
    [
        "HAYSSEN SANDIACRE *",
        "HAYSSEN SANDIACRE"
    ],
    [
        "HEF TECHNICAL SERVICES",
        "HEF TECHNICAL SERVICES"
    ],
    [
        "HEINRICH SCHMID GMBH & CO. KG",
        "HEINRICH SCHMID"
    ],
    [
        "HEINZ MORITZ GMBH",
        "HEINZ MORITZ"
    ],
    [
        "HEITON BUCKLEY.",
        "HEITON BUCKLEY"
    ],
    [
        "HELLA STOREN AG",
        "HELLA STOREN"
    ],
    [
        "HELMUT FISCHER AG",
        "HELMUT FISCHER"
    ],
    [
        "HENRICHSEN AG",
        "HENRICHSEN"
    ],
    [
        "HERMIS FLORES TORRES",
        "HERMIS FLORES TORRES"
    ],
    [
        "HERRMANN ULTRASONICS,INC.",
        "HERRMANN ULTRASONICS"
    ],
    [
        "HEXAGON METROLOGY INC",
        "HEXAGON METROLOGY"
    ],
    [
        "HIBAR SYSTEMS LTD. *",
        "HIBAR SYSTEMS"
    ],
    [
        "HILTI DEUTSCHLAND GMBH",
        "HILTI"
    ],
    [
        "HISS FACHMARKT GMBH",
        "HISS FACHMARKT"
    ],
    [
        "HORAN AUTOMATION",
        "HORAN AUTOMATION"
    ],
    [
        "HORIBA INSTRUMENTS INC",
        "HORIBA INSTRUMENTS"
    ],
    [
        "HORIZON PEST CONTROL",
        "HORIZON PEST CONTROL"
    ],
    [
        "HOTSY OF ORLANDO LLC",
        "HOTSY OF ORLANDO"
    ],
    [
        "HP PRODUCTS CORPORATION",
        "HP PRODUCTS"
    ],
    [
        "HPE AUTOMATION CORP",
        "HPE AUTOMATION"
    ],
    [
        "HUFFMAN CORPORATION INC",
        "HUFFMAN CORPORATION"
    ],
    [
        "HYGIA HEALTH SERVICES INC (3PL STRYKER)",
        "HYGIA HEALTH SERVICES 3PL STRYKER"
    ],
    [
        "HÖPFL & HARFMANN GBR",
        "HÖPFL & HARFMANN"
    ],
    [
        "HÖRMANN SCHWEIZ AG",
        "HÖRMANN"
    ],
    [
        "IFM EFECTOR, INC. *",
        "IFM ELECTRONIC"
    ],
    [
        "ILAPAK, INC. *",
        "ILAPAK"
    ],
    [
        "ILLIG UK",
        "ILLIG"
    ],
    [
        "ILLINOIS OFFICE OF STATE",
        "ILLINOIS OFFICE OF STATE"
    ],
    [
        "ILLINOIS PROCESS EQUIPMENT *",
        "ILLINOIS PROCESS EQUIPMENT"
    ],
    [
        "ILLINOIS TOOL WORKS INC",
        "ILLINOIS TOOL WORKS"
    ],
    [
        "IMPREGLON INC",
        "IMPREGLON"
    ],
    [
        "IMPULSORA",
        "IMPULSORA"
    ],
    [
        "IMPULSORA COMERCIAL",
        "IMPULSORA COMERCIAL"
    ],
    [
        "IMS INDUSTRIAL MACHINE",
        "IMS INDUSTRIAL MACHINE"
    ],
    [
        "IN  SERVICE ONE",
        "IN  SERVICE ONE"
    ],
    [
        "INDEPENDENT MACHINE WORKS LLC",
        "INDEPENDENT MACHINE WORKS"
    ],
    [
        "INDUSTRIAL AUTOMATION CONTROL, LLC",
        "INDUSTRIAL AUTOMATION CONTROL"
    ],
    [
        "INDUSTRIAL COOLING CORP",
        "INDUSTRIAL COOLING"
    ],
    [
        "INFINITY QS INTERNATIONAL",
        "INFINITY QS INTERNATIONAL"
    ],
    [
        "ING.-BⁿRO BRAASE",
        "ING BⁿRO BRAASE"
    ],
    [
        "INGENIEURBUERO FUER ARBEITS-",
        "INGENIEURBUERO FUER ARBEITS"
    ],
    [
        "INSTRON - DIVISION OF ITW LTD",
        "INSTRON DIVISION OF ITW"
    ],
    [
        "INTEGRATED FIRE & SECURITY SOLUTIONS INC",
        "INTEGRATED FIRE & SECURITY SOLUTIONS"
    ],
    [
        "INTEGRATED MEHANIZATION",
        "INTEGRATED MEHANIZATION"
    ],
    [
        "INTEGRATED SCALE SYSTEMS INC",
        "INTEGRATED SCALE SYSTEMS"
    ],
    [
        "INTERPORT TRADING CORP",
        "INTERPORT TRADING"
    ],
    [
        "INTERSTATE POWER SYSTEMS, INC.",
        "INTERSTATE POWER SYSTEMS"
    ],
    [
        "INTER-TECH SUPPLIES, INC.",
        "INTERTECH SUPPLIES"
    ],
    [
        "IRON MOUNTAIN INCORPORATE",
        "IRON MOUNTAIN INCORPORATE"
    ],
    [
        "J & J DECORATING",
        "J & J DECORATING"
    ],
    [
        "J & J PAINT & GLASS",
        "J & J PAINT & GLASS"
    ],
    [
        "J & M MCBRIDE GAS AND OIL",
        "J & M MCBRIDE GAS AND OIL"
    ],
    [
        "J NEWTON ENTERPRISES INC",
        "J NEWTON ENTERPRISES"
    ],
    [
        "J.RYAN & SONS LTD.,",
        "J RYAN & SONS"
    ],
    [
        "JACOBS FAHRZEUGTEILE GMBH",
        "JACOBS FAHRZEUGTEILE"
    ],
    [
        "JAEGER HAUSTECHNIK",
        "JAEGER HAUSTECHNIK"
    ],
    [
        "JC RESTORATION",
        "JC RESTORATION"
    ],
    [
        "JET STEINBRECHER GMBH",
        "JET STEINBRECHER"
    ],
    [
        "JIM WILLIAMS FENCE COMPANY, INC.",
        "JIM WILLIAMS FENCE COMPANY"
    ],
    [
        "JOBROUTER AG",
        "JOBROUTER"
    ],
    [
        "JOE MCKENNA (LIMERICK)LTD",
        "JOE MCKENNA"
    ],
    [
        "JOEL SAVELLANO",
        "JOEL SAVELLANO"
    ],
    [
        "JOHN ANDREATAS",
        "JOHN ANDREATAS"
    ],
    [
        "JOHN DUGGAN & SONS LTD.,",
        "JOHN DUGGAN & SONS"
    ],
    [
        "JOHNSON GAGE",
        "JOHNSON GAGE"
    ],
    [
        "JOSE L MALDONADO",
        "JOSE L MALDONADO"
    ],
    [
        "JUDO WASSER-AUFBEREITUNG GMBH",
        "JUDO WASSER AUFBEREITUNG"
    ],
    [
        "JUNO PACIFIC *",
        "JUNO PACIFIC"
    ],
    [
        "K & J MECHANICAL INC.",
        "K & J MECHANICAL"
    ],
    [
        "K R PAINTING",
        "K R PAINTING"
    ],
    [
        "KABA GMBH",
        "KABA"
    ],
    [
        "KAEFER CONSTRUCTION GMBH",
        "KAEFER CONSTRUCTION"
    ],
    [
        "KAESER",
        "KAESER"
    ],
    [
        "KAISER OPTICAL SYSTEMS, INC",
        "KAISER OPTICAL SYSTEMS"
    ],
    [
        "KAMAN INDUSTRIAL TECHNOLOGIES",
        "KAMAN INDUSTRIAL TECHNOLOGIES"
    ],
    [
        "KASTALON INC. *",
        "KASTALON"
    ],
    [
        "KATERBERG CO INC",
        "KATERBERG"
    ],
    [
        "KEDINGTON LTD (CORK)",
        "KEDINGTON"
    ],
    [
        "KELLER MECHANICAL&ENGINEERING, INC.",
        "KELLER MECHANICAL&ENGINEERING"
    ],
    [
        "KEMMLIT BAUELEMENTE GMBH",
        "KEMMLIT BAUELEMENTE"
    ],
    [
        "KEMPER VERTRIEBSGES. MBH",
        "KEMPER VERTRIEBSGES MBH"
    ],
    [
        "KENDALL ELECTRIC, INC.",
        "KENDALL ELECTRIC"
    ],
    [
        "KEVIN RIMES",
        "KEVIN RIMES"
    ],
    [
        "KEYENCE CORP. OF AMERICA",
        "KEYENCE"
    ],
    [
        "KEYTECH SÜD GMBH",
        "KEYTECH SÜD"
    ],
    [
        "KIELER LADENBAU GMBH",
        "KIELER LADENBAU"
    ],
    [
        "KISER CONTROLS CO. *",
        "KISER CONTROLS"
    ],
    [
        "KLEINE-ALBERS GMBH",
        "KLEINE ALBERS"
    ],
    [
        "KLN ULTRASCHALL GMBH",
        "KLN ULTRASCHALL"
    ],
    [
        "KMH SYSTEMS",
        "KMH SYSTEMS"
    ],
    [
        "KOELLIKER BÜROAUTOMATION",
        "KOELLIKER BÜROAUTOMATION"
    ],
    [
        "KOHLER BETRIEBSEINRICHTUNGEN",
        "KOHLER BETRIEBSEINRICHTUNGEN"
    ],
    [
        "KONE GMBH",
        "KONE"
    ],
    [
        "KONE INC.",
        "KONE"
    ],
    [
        "KRAFT AND BAUER UK",
        "KRAFT AND BAUER"
    ],
    [
        "KROESCHELL ENGINEERING CO",
        "KROESCHELL ENGINEERING"
    ],
    [
        "KTS BAUTECH. BRANDSCHUTZ",
        "KTS BAUTECH BRANDSCHUTZ"
    ],
    [
        "KUNZ PRECISION",
        "KUNZ PRECISION"
    ],
    [
        "KURT MÜLLER",
        "KURT MÜLLER"
    ],
    [
        "KÄLTE-KLIMA-ENGINEERING GMBH",
        "KÄLTE KLIMA ENGINEERING"
    ],
    [
        "L&M KEATING",
        "L&M KEATING"
    ],
    [
        "LAKELAND GLASS AND MIRROR",
        "LAKELAND GLASS AND MIRROR"
    ],
    [
        "LAKO TOOL AND MANUFACTURING, INC.",
        "LAKO TOOL AND MANUFACTURING"
    ],
    [
        "LANGENPAC.NV.",
        "LANGENPAC"
    ],
    [
        "HK LASER SERVICES LTD",
        "LASER SERVICES"
    ],
    [
        "LAYTON TECHNOLOGIES LTD.,",
        "LAYTON TECHNOLOGIES"
    ],
    [
        "LEDFORD GAGE LAB",
        "LEDFORD GAGE LAB"
    ],
    [
        "LEECHEM SERVICES",
        "LEECHEM SERVICES"
    ],
    [
        "LESMAN INSTRUMENT CO.",
        "LESMAN INSTRUMENT"
    ],
    [
        "LIMERICK HYDRAULICS",
        "LIMERICK HYDRAULICS"
    ],
    [
        "LNS AMERICA INC",
        "LNS"
    ],
    [
        "LNS AMERICA, INC.",
        "LNS"
    ],
    [
        "LONG DISTRIBUTORS LIMITED",
        "LONG DISTRIBUTORS"
    ],
    [
        "LONGO ELECTRICAL MECHANICAL INC",
        "LONGO ELECTRICAL MECHANICAL"
    ],
    [
        "LTA LUFTTECHNIK GMBH",
        "LTA LUFTTECHNIK"
    ],
    [
        "LUDER TECHNIK AG",
        "LUDER TECHNIK"
    ],
    [
        "LUMINITE",
        "LUMINITE"
    ],
    [
        "LUXURY DESIGN FLOORS",
        "LUXURY DESIGN FLOORS"
    ],
    [
        "LWB WELDTECH AG",
        "LWB WELDTECH"
    ],
    [
        "LYSTER CONTRACTING INC",
        "LYSTER CONTRACTING"
    ],
    [
        "M&G SWEN EWALD",
        "M&G SWEN EWALD"
    ],
    [
        "MACHINE TOOL TECHNOLOGIES",
        "MACHINE TOOL TECHNOLOGIES"
    ],
    [
        "MACK ENGINEERING",
        "MACK ENGINEERING"
    ],
    [
        "MAHLO AMERICA, INC.",
        "MAHLO"
    ],
    [
        "MALERBETRIEB DZIASEK",
        "MALERBETRIEB DZIASEK"
    ],
    [
        "MAL¸ BEDACHUNGEN",
        "MAL¸ BEDACHUNGEN"
    ],
    [
        "MALⁿ BEDACHUNGEN",
        "MALⁿ BEDACHUNGEN"
    ],
    [
        "MAPLE HILL SPRINKLING INC",
        "MAPLE HILL SPRINKLING"
    ],
    [
        "MAQUINARIA DE ELEVACION",
        "MAQUINARIA DE ELEVACION"
    ],
    [
        "MARIAN CHICAGO INC *",
        "MARIAN"
    ],
    [
        "MARTEL TOOL CORP",
        "MARTEL TOOL"
    ],
    [
        "MARTIN PLUMBING LLC",
        "MARTIN PLUMBING"
    ],
    [
        "MATRIX PACKAGING MACHINERY, LLC",
        "MATRIX PACKAGING MACHINERY"
    ],
    [
        "MAX WEISHAUPT GMBH",
        "MAX WEISHAUPT"
    ],
    [
        "MAXCESS AMERICAS, INC",
        "MAXCESS AMERICAS"
    ],
    [
        "MC MACHINERY SYSTEMS INC",
        "MC MACHINERY SYSTEMS INC"
    ],
    [
        "MCFLUSION, INC. *",
        "MCFLUSION"
    ],
    [
        "MEADOWLANDS FIRE PROTECTION CORP",
        "MEADOWLANDS FIRE PROTECTION"
    ],
    [
        "MECALUX",
        "MECALUX"
    ],
    [
        "MECMESIN CORPORATION *",
        "MECMESIN"
    ],
    [
        "MEDICA",
        "MEDICA"
    ],
    [
        "MEECH STATIC ELIMINATORS",
        "MEECH STATIC ELIMINATORS"
    ],
    [
        "MERCOTAC, INC.",
        "MERCOTAC"
    ],
    [
        "MERRYWEATHER FOAM INC. *",
        "MERRYWEATHER FOAM"
    ],
    [
        "MERZ VERPACKUNGSMASCHINEN",
        "MERZ VERPACKUNGSMASCHINEN"
    ],
    [
        "MESSER EUTECTIC CASTOLIN",
        "MESSER EUTECTIC CASTOLIN"
    ],
    [
        "MESSTEC GMBH",
        "MESSTEC"
    ],
    [
        "PS METALLBAU",
        "METALLBAU"
    ],
    [
        "METRO HYDRAULIC JACK COMPANY",
        "METRO HYDRAULIC JACK"
    ],
    [
        "MEZA MURRIETA MARISELA",
        "MEZA MURRIETA MARISELA"
    ],
    [
        "MICHAEL MEYERING",
        "MICHAEL MEYERING"
    ],
    [
        "MIDWEST FINISHING SYSTEMS INC",
        "MIDWEST FINISHING SYSTEMS"
    ],
    [
        "MIELE INCORPORATED",
        "MIELE INORATED"
    ],
    [
        "MILLENIUM CONTROLS LTD.,",
        "MILLENIUM CONTROLS"
    ],
    [
        "MILLENIUM DIE GROUP, LLC",
        "MILLENIUM DIE GROUP"
    ],
    [
        "MIN INDUSTRIAL S DE RL DE CV",
        "MIN INDUSTRIAL"
    ],
    [
        "MINIMAX GMBH",
        "MINIMAX"
    ],
    [
        "MINIMAX GMBH & CO.KG",
        "MINIMAX"
    ],
    [
        "MINIMAX AG",
        "MINIMAX"
    ],
    [
        "MIRACLEAN CHAUTAUQUA METAL FINISHIN",
        "MIRACLEAN CHAUTAUQUA METAL FINISHIN"
    ],
    [
        "MISUMI USA, INC.",
        "MISUMI"
    ],
    [
        "MITCHELL MACHINE TOOL SER",
        "MITCHELL MACHINE TOOL SER"
    ],
    [
        "MITE-E EXTERMINATING",
        "MITE E EXTERMINATING"
    ],
    [
        "MITSUBISHI CHEMICAL *",
        "MITSUBISHI CHEMICAL"
    ],
    [
        "MITSUBISHI ELECTRIC",
        "MITSUBISHI ELECTRIC"
    ],
    [
        "MKS INSTRUMENTS, INC.",
        "MKS INSTRUMENTS"
    ],
    [
        "MORDHORST",
        "MORDHORST"
    ],
    [
        "MORRISON INDUSTRIAL",
        "MORRISON INDUSTRIAL"
    ],
    [
        "MOUSER ELECTRONICS, INC.",
        "MOUSER ELECTRONICS"
    ],
    [
        "M-PACK PACKAGING SOLUTIONS, INC",
        "MPACK PACKAGING SOLUTIONS"
    ],
    [
        "MPC CREATIVE LLC",
        "MPC CREATIVE LLC"
    ],
    [
        "MPI MORHEAT INC.",
        "MPI MORHEAT"
    ],
    [
        "MTS SYSTEMS CORP",
        "MTS SYSTEMS"
    ],
    [
        "MULTIVAC SEPP HAGGENMÜLLER",
        "MULTIVAC SEPP HAGGENMÜLLER"
    ],
    [
        "MV O'HALLORAN",
        "MV O'HALLORAN"
    ],
    [
        "MYLABELS",
        "MYLABELS"
    ],
    [
        "NABCO ENTRANCES, INC.",
        "NABCO ENTRANCES"
    ],
    [
        "NABERTHERM GMBH",
        "NABERTHERM"
    ],
    [
        "NALCO COMPANY",
        "NALCO"
    ],
    [
        "NAMEPLATE ENGRAVERS,",
        "NAMEPLATE ENGRAVERS"
    ],
    [
        "NATIONAL INSTRUMENTS CORP",
        "NATIONAL INSTRUMENTS"
    ],
    [
        "NAUTILUS SYSTEMS INC",
        "NAUTILUS SYSTEMS"
    ],
    [
        "NEWARK ELEMENT 14",
        "NEWARK"
    ],
    [
        "NEWARK INC",
        "NEWARK"
    ],
    [
        "NICOFORM",
        "NICOFORM"
    ],
    [
        "NIDEC INDUSTRIAL AUTOMATION USA, LLC *",
        "NIDEC INDUSTRIAL AUTOMATION"
    ],
    [
        "NIKKEN EUROPE",
        "NIKKEN"
    ],
    [
        "NILFISK-CFM",
        "NILFISK CFM"
    ],
    [
        "NILLIUS GMBH",
        "NILLIUS"
    ],
    [
        "NK FABRICATIONS LTD",
        "NK FABRICATIONS"
    ],
    [
        "NOMACO COMPONENTS GMBH",
        "NOMACO COMPONENTS"
    ],
    [
        "NORDEN MACHINERY AB",
        "NORDEN MACHINERY"
    ],
    [
        "NORDON LANDSCAPES",
        "NORDON LANDSCAPES"
    ],
    [
        "NORMAN EQUIPMENT CO.",
        "NORMAN EQUIPMENT"
    ],
    [
        "NSP LABORATORY SERVICES",
        "NSP LABORATORY SERVICES"
    ],
    [
        "NTRON",
        "NTRON"
    ],
    [
        "NTS PR, LLC",
        "NTS PR"
    ],
    [
        "O'LEARY VENTILATION",
        "O LEARY VENTILATION"
    ],
    [
        "O.M.C TECHNOLOGIES",
        "O M C TECHNOLOGIES"
    ],
    [
        "O'BRIEN FACILITIES LTD",
        "O'BRIEN FACILITIES"
    ],
    [
        "O/K INTERNATIONAL CORP. *",
        "O/K INTERNATIONAL"
    ],
    [
        "OBERLIN FILTER LIMITED",
        "OBERLIN FILTER"
    ],
    [
        "OCH FACILITIES LTD",
        "OCH FACILITIES"
    ],
    [
        "OERLIKON LEYBOLD VACUUM GMBH",
        "OERLIKON LEYBOLD VACUUM"
    ],
    [
        "OERLIKON METCO",
        "OERLIKON METCO"
    ],
    [
        "OHIO TRANSMISSION CORP",
        "OHIO TRANSMISSION"
    ],
    [
        "OKAMOTO CORPORATION",
        "OKAMOTO"
    ],
    [
        "OLAF CHRISTIAN",
        "OLAF CHRISTIAN"
    ],
    [
        "OLIVER-TOLAS HEALTHCARE PACKAGING (PA)",
        "OLIVERTOLAS HEALTHCARE PACKAGING PA"
    ],
    [
        "OLVERA DIAZ MARIELA",
        "OLVERA DIAZ MARIELA"
    ],
    [
        "OLYMPUS AMERICA INC",
        "OLYMPUS"
    ],
    [
        "OMEGA ENGINEERING, INC.",
        "OMEGA ENGINEERING"
    ],
    [
        "OML VENTILATION LTD",
        "OML VENTILATION"
    ],
    [
        "OMRON ELECTRONICS LLC. *",
        "OMRON ELECTRONICS"
    ],
    [
        "ON TIME MESSENGER SERVICE *",
        "ON TIME MESSENGER SERVICE"
    ],
    [
        "OPEN MIND TECHNOLOGIES AG",
        "OPEN MIND TECHNOLOGIES"
    ],
    [
        "OPHIR SPIRICON",
        "OPHIR SPIRICON"
    ],
    [
        "OPHIR-SPIRICON LLC",
        "OPHIR SPIRICON"
    ],
    [
        "OPS-INGERSOLL FUNKEN-",
        "OPS-INGERSOLL FUNKEN-"
    ],
    [
        "OPTISCHE MESSTECHNIK STEIN",
        "OPTISCHE MESSTECHNIK STEIN"
    ],
    [
        "ORANGE & ROCKLAND UTIL",
        "ORANGE & ROCKLAND UTIL"
    ],
    [
        "ORBITFORM",
        "ORBITFORM"
    ],
    [
        "ORBITFORM GROUP LLC",
        "ORBITFORM GROUP"
    ],
    [
        "ORKIN PEST CONTROL",
        "ORKIN PEST CONTROL"
    ],
    [
        "OS-BODENBELAEGE GMBH",
        "OS BODENBELAEGE"
    ],
    [
        "OVERHEAD DOOR COMPANY",
        "OVERHEAD DOOR"
    ],
    [
        "OZONE WATER SYSTEMS, INC",
        "OZONE WATER SYSTEMS"
    ],
    [
        "PACIFIC OZONE *",
        "PACIFIC OZONE"
    ],
    [
        "PACIFIC TREATMENT ENVIRONMENTAL SERVICES SA DE CV",
        "PACIFIC TREATMENT ENVIRONMENTAL SERVICES"
    ],
    [
        "PACKWORLD USA LTD, INC.",
        "PACKWORLD"
    ],
    [
        "PALLETONE OF FLORIDA INC",
        "PALLETONE OF FLORIDA"
    ],
    [
        "PANTRON AUTOMATION",
        "PANTRON AUTOMATION"
    ],
    [
        "PARAGON MEDICAL SMITHFIELD",
        "PARAGON MEDICAL SMITHFIELD"
    ],
    [
        "PARAGON SIECHNICE",
        "PARAGON SIECHNICE"
    ],
    [
        "PARTSWAREHOUSE.COM",
        "PARTSWAREHOUSE"
    ],
    [
        "PAUL BECKER GMBH",
        "PAUL BECKER"
    ],
    [
        "PEAK RYZEX INC",
        "PEAK RYZEX"
    ],
    [
        "PEAK-RYZEX INC",
        "PEAKRYZEX"
    ],
    [
        "PEARSON PACKAGING *",
        "PEARSON PACKAGING"
    ],
    [
        "PEERLESS ENTERPRISES, INC.",
        "PEERLESS ENTERPRISES"
    ],
    [
        "PEP BOYS",
        "PEP BOYS"
    ],
    [
        "PEREZ RODRIGUEZ MARCELINO",
        "PEREZ RODRIGUEZ MARCELINO"
    ],
    [
        "PERKINELMER INFORMATICS INC",
        "PERKINELMER INFORMATICS"
    ],
    [
        "PFEIFFER VACUUM GMBH",
        "PFEIFFER VACUUM"
    ],
    [
        "PILZ AUTOMATION SAFETY LP",
        "PILZ AUTOMATION SAFETY"
    ],
    [
        "PINNACLE TECHNOLOGIES INC",
        "PINNACLE TECHNOLOGIES"
    ],
    [
        "PIRTEK",
        "PIRTEK"
    ],
    [
        "PLANT CITY LOCK & KEY, INC.",
        "PLANT CITY LOCK & KEY"
    ],
    [
        "PLATING ELECTRONIC GMBH",
        "PLATING ELECTRONIC"
    ],
    [
        "PLAVIS GMBH",
        "PLAVIS"
    ],
    [
        "PMI SOFTWARE LTD.,",
        "PMI SOFTWARE"
    ],
    [
        "PMI SOFTWARE LTD",
        "PMI SOFTWARE"
    ],
    [
        "PNEUMATIC TECHNOLOGY, INC.",
        "PNEUMATIC TECHNOLOGY"
    ],
    [
        "POWER/MATION DIVISION INC.",
        "POWER MATION DIVISION"
    ],
    [
        "PRAXIS GRUPPE WANGEN",
        "PRAXIS GRUPPE WANGEN"
    ],
    [
        "PRECISION BODY WORKS,INC.",
        "PRECISION BODY WORKS"
    ],
    [
        "PRECISION POWDER",
        "PRECISION POWDER"
    ],
    [
        "PRECITEC GMBH & CO. KG",
        "PRECITEC"
    ],
    [
        "PRESTIGE DETECTION SYST",
        "PRESTIGE DETECTION SYST"
    ],
    [
        "PRESTIGE ENGRAVERS LTD",
        "PRESTIGE ENGRAVERS"
    ],
    [
        "PRIME PEST MANAGEMENT",
        "PRIME PEST MANAGEMENT"
    ],
    [
        "PRO BALER SERVICES INC",
        "PRO BALER SERVICES"
    ],
    [
        "PRO-DEX *",
        "PRO DEX"
    ],
    [
        "PROCAD TECHNOLOGY LTD.,",
        "PROCAD TECHNOLOGY"
    ],
    [
        "PRODUCT SERVICE CENTER",
        "PRODUCT SERVICE CENTER"
    ],
    [
        "PROGRESSIVE HYDRAULICS INC",
        "PROGRESSIVE HYDRAULICS"
    ],
    [
        "PROJECT SPECIALIST OF PR",
        "PROJECT SPECIALIST OF PR"
    ],
    [
        "PROMPT ELECTRICAL SERVICE",
        "PROMPT ELECTRICAL SERVICE"
    ],
    [
        "PROVAC LTD",
        "PROVAC"
    ],
    [
        "PROVAC LTD.,",
        "PROVAC"
    ],
    [
        "PROWAY AM GMBH",
        "PROWAY"
    ],
    [
        "PRUEFDIENST KAPRAUN",
        "PRUEFDIENST KAPRAUN"
    ],
    [
        "PSNC INC.",
        "PSNC"
    ],
    [
        "PUBLIC SEWER SERVICE",
        "PUBLIC SEWER SERVICE"
    ],
    [
        "PUMP SOLUTIONS INC.",
        "PUMP SOLUTIONS"
    ],
    [
        "PUMP SOLUTIONS 1 CORP.",
        "PUMP SOLUTIONS 1"
    ],
    [
        "PUMPING SOLUTIONS, INC. *",
        "PUMPING SOLUTIONS"
    ],
    [
        "PURITAN WATER",
        "PURITAN WATER"
    ],
    [
        "PYKA'S COMPLETE FURNITURE",
        "PYKA COMPLETE FURNITURE"
    ],
    [
        "PYRAMID TECHNOLOGIES",
        "PYRAMID TECHNOLOGIES"
    ],
    [
        "Q-DAS GMBH & CO. KG",
        "Q DAS"
    ],
    [
        "QUADRIGA INFORMATIK GMBH",
        "QUADRIGA INFORMATIK"
    ],
    [
        "QUALITY ASSURANCE RES.LTD",
        "QUALITY ASSURANCE RES"
    ],
    [
        "QUALITY SERVICE & SOLUTIONS",
        "QUALITY SERVICE & SOLUTIONS"
    ],
    [
        "R-CAP PROCESS EQUIPMENT *",
        "R CAP PROCESS EQUIPMENT"
    ],
    [
        "R FREUND BUILDER & REMODELER INC",
        "R FREUND BUILDER & REMODELER"
    ],
    [
        "R. M. SELLERGREN & ASSOCIATES, INC.",
        "R M SELLERGREN & ASSOCIATES"
    ],
    [
        "R.A. JONES AND COMPANY *",
        "R.A. JONES AND COMPANY"
    ],
    [
        "RACH + REHER",
        "RACH REHER"
    ],
    [
        "RACK AND SHELVING DEPOT, LLC",
        "RACK AND SHELVING DEPOT"
    ],
    [
        "RACKS CONCEPTS SARL",
        "RACKS CONCEPTS"
    ],
    [
        "RADWELL INTERNATIONAL",
        "RADWELL INTERNATIONAL"
    ],
    [
        "RADWELL INTERNATIONAL, INC",
        "RADWELL INTERNATIONAL"
    ],
    [
        "RALPH HELM INC.",
        "RALPH HELM"
    ],
    [
        "RAMIREZ SANTIAGO ENRIQUE",
        "RAMIREZ SANTIAGO ENRIQUE"
    ],
    [
        "RANDALL INDUSTRIES",
        "RANDALL INDUSTRIES"
    ],
    [
        "RANSOM & RANDOLPH",
        "RANSOM & RANDOLPH"
    ],
    [
        "RCC IRELAND LTD",
        "RCC"
    ],
    [
        "RCC IRELAND LIMITED",
        "RCC"
    ],
    [
        "REAL TIME TECHNOLOGY GROUP",
        "REAL TIME TECHNOLOGY GROUP"
    ],
    [
        "REFRITEC DE TIJUANA",
        "REFRITEC DE TIJUANA"
    ],
    [
        "REIZNER ELEKTRO GMH & CO.KG",
        "REIZNER ELEKTRO"
    ],
    [
        "RELIABLE FIRE EQUIP. CO.*",
        "RELIABLE FIRE EQUIP"
    ],
    [
        "RENTOKIL INITIAL LTD.",
        "RENTOKIL INITIAL"
    ],
    [
        "RENTOKIL INITIAL LTD",
        "RENTOKIL INITIAL"
    ],
    [
        "RENTOKIL INITIAL GMBH",
        "RENTOKIL INITIAL"
    ],
    [
        "RENTOKIL INITIAL LTD.,",
        "RENTOKIL INITIAL"
    ],
    [
        "REPUBLIC SERVICES OF",
        "REPUBLIC SERVICES OF"
    ],
    [
        "REXEL USA INC",
        "REXEL"
    ],
    [
        "RICHARD RAHN BAUUNTERNEH-",
        "RICHARD RAHN BAUUNTERNEH"
    ],
    [
        "RIVERSIDE INTEGRATED SYSTEMS,",
        "RIVERSIDE INTEGRATED SYSTEMS"
    ],
    [
        "RLB ENGINEERING",
        "RLB ENGINEERING"
    ],
    [
        "RND AUTOMATION-ENGINEERING",
        "RND AUTOMATION ENGINEERING"
    ],
    [
        "ROBO-TECHNOLOGY GMBH",
        "ROBO-TECHNOLOGY"
    ],
    [
        "RODRIGUEZ DOMINGUEZ JOSE ALBERTO",
        "RODRIGUEZ DOMINGUEZ JOSE ALBERTO"
    ],
    [
        "ROEBI",
        "ROEBI"
    ],
    [
        "ROHDE & SCHWARZ GMBH",
        "ROHDE & SCHWARZ"
    ],
    [
        "ROHRREINIGUNG STEPHAN KUSTER",
        "ROHRREINIGUNG STEPHAN KUSTER"
    ],
    [
        "ROLF KLUEVER",
        "ROLF KLUEVER"
    ],
    [
        "ROLLADENBAU SIEKER GMBH",
        "ROLLADENBAU SIEKER"
    ],
    [
        "ROLLIE WILLIAMS PAINT",
        "ROLLIE WILLIAMS PAINT"
    ],
    [
        "ROSS CABLING SYSTEMS INC",
        "ROSS CABLING SYSTEMS"
    ],
    [
        "ROSS OIL CO LTD",
        "ROSS OIL"
    ],
    [
        "ROTOMETRICS, INC.",
        "ROTOMETRICS"
    ],
    [
        "RPMI PACKAGING, INC. *",
        "RPMI PACKAGING"
    ],
    [
        "RRD COURIER SERVICES *",
        "RRD COURIER SERVICES"
    ],
    [
        "RUESCHER HARM",
        "RUESCHER HARM"
    ],
    [
        "RYAN HERCO PRODUCTS CORP.",
        "RYAN HERCO PRODUCTS"
    ],
    [
        "RYANS TIMBER MACHINISTS",
        "RYANS TIMBER MACHINISTS"
    ],
    [
        "S & K TRADING GMBH & CO. KG",
        "S & K TRADING"
    ],
    [
        "SALAS LOPEZ LAURA",
        "SALAS LOPEZ LAURA"
    ],
    [
        "SANCHEZ ROMERO ALEJANDRO",
        "SANCHEZ ROMERO ALEJANDRO"
    ],
    [
        "SANIERUNG & RENOVIERUNG OSTOJIC",
        "SANIERUNG & RENOVIERUNG OSTOJIC"
    ],
    [
        "SAPLING INC",
        "SAPLING"
    ],
    [
        "SAUER GMBH LASERTEC",
        "SAUER LASERTEC"
    ],
    [
        "SB-BODEN, SAMIR SABANI",
        "SB BODEN SAMIR SABANI"
    ],
    [
        "SCALEMEN OF FLORIDA, INC.",
        "SCALEMEN"
    ],
    [
        "SCHMIDT TECHNOLOGY GMBH",
        "SCHMIDT TECHNOLOGY"
    ],
    [
        "SCHUTTE",
        "SCHUTTE"
    ],
    [
        "SCHWEIZ. VEREIN FÜR",
        "SCHWEIZ. VEREIN FÜR"
    ],
    [
        "SCI-CHEM WATER TREATMENT,LLC",
        "SCICHEM WATER TREATMENT"
    ],
    [
        "SCIENTIFIC PROCESS DEV.S.",
        "SCIENTIFIC PROCESS DEV S"
    ],
    [
        "SEAN CURTIN",
        "SEAN CURTIN"
    ],
    [
        "SEC CR WALMART.COM - 0174",
        "SEC CR WALMART.COM - 0174"
    ],
    [
        "SEC CR WALMART.COM 80 - 0174",
        "SEC CR WALMART.COM 80 - 0174"
    ],
    [
        "SECURITY CONTROL INTEGRATORS",
        "SECURITY CONTROL INTEGRATORS"
    ],
    [
        "SENN AG",
        "SENN"
    ],
    [
        "SENSOR PRODUCTS INC",
        "SENSOR PRODUCTS"
    ],
    [
        "SENTRY AIR SYSTEMS, INC.",
        "SENTRY AIR SYSTEMS"
    ],
    [
        "SENCORPWHITE INC",
        "SENWHITE"
    ],
    [
        "SERVICE BUILDING",
        "SERVICE BUILDING"
    ],
    [
        "SERVTEKS INC",
        "SERVTEKS"
    ],
    [
        "SETCO SALES CO",
        "SETCO SALES"
    ],
    [
        "SEVERN ENGINEERING COMPANY",
        "SEVERN ENGINEERING"
    ],
    [
        "SHANNON DEVELOPMENT",
        "SHANNON DEVELOPMENT"
    ],
    [
        "SHANNONSIDE OIL LTD.,",
        "SHANNONSIDE OIL"
    ],
    [
        "SHARPER AUTOMATION, INC.",
        "SHARPER AUTOMATION"
    ],
    [
        "SHERWIN WILLIAMS 701778",
        "SHERWIN WILLIAMS"
    ],
    [
        "SHRED-IT USA",
        "SHRED"
    ],
    [
        "ES SICHERHEIT",
        "SICHERHEIT"
    ],
    [
        "SICOTEC AG",
        "SICOTEC"
    ],
    [
        "SIEVERT ELECTRIC SERVICE *",
        "SIEVERT ELECTRIC SERVICE"
    ],
    [
        "SIGN IT LTD.,",
        "SIGN IT"
    ],
    [
        "SIGN ZONE LIMITED",
        "SIGN ZONE"
    ],
    [
        "SIKORA AUTOMATIONS",
        "SIKORA AUTOMATIONS"
    ],
    [
        "SILVER REFRIGERATION LTD",
        "SILVER REFRIGERATION"
    ],
    [
        "SIMCO-ION",
        "SIMCO ION"
    ],
    [
        "SISTEMAS AMBIENTALES",
        "SISTEMAS AMBIENTALES"
    ],
    [
        "SOLAR GARD",
        "SOLAR GARD"
    ],
    [
        "SOLARIUS DEVELOPMENT INC",
        "SOLARIUS DEVELOPMENT"
    ],
    [
        "SOTEX ADVANTAGE CORPORATION",
        "SOTEX ADVANTAGE"
    ],
    [
        "SOUTHERN EQUIPMENT CORP",
        "SOUTHERN EQUIPMENT"
    ],
    [
        "SPECIALTY ACCESSORY SALES",
        "SPECIALTY ACCESSORY SALES"
    ],
    [
        "SPEED WRENCH INC",
        "SPEED WRENCH"
    ],
    [
        "SPEEDWRENCH, INC.",
        "SPEEDWRENCH"
    ],
    [
        "SPIGRAPH AG",
        "SPIGRAPH"
    ],
    [
        "SPREITZER GMBH&COKG",
        "SPREITZER"
    ],
    [
        "SPRING TECHNOLOGIES",
        "SPRING TECHNOLOGIES"
    ],
    [
        "SPRING TECHNOLOGIES GMBH",
        "SPRING TECHNOLOGIES"
    ],
    [
        "SQ  MASTER TECH SER",
        "SQ MASTER TECH SER"
    ],
    [
        "STANDARD VACUUM PUMP SERVICE LTD",
        "STANDARD VACUUM PUMP SERVICE"
    ],
    [
        "STAR CNC MACHINE TOOL CORP",
        "STAR CNC MACHINE TOOL"
    ],
    [
        "STAR-LO COMMUNICATIONS",
        "STAR COMMUNICATIONS"
    ],
    [
        "STATIC CLEAN INTERNATIONAL",
        "STATIC CLEAN INTERNATIONAL"
    ],
    [
        "STEINER PARTNER GMBH",
        "STEINER PARTNER"
    ],
    [
        "STEINER-ATLANTIC CORP.",
        "STEINERATLANTIC"
    ],
    [
        "STERLING, INC.",
        "STERLING"
    ],
    [
        "SUEZ TREATMENT SOLUTIONS",
        "SUEZ WTS ANALYTICAL INSTRUMENTS"
    ],
    [
        "SUNRISE SERVICE INC",
        "SUNRISE SERVICE"
    ],
    [
        "SURFACE FX INC",
        "SURFACE FX"
    ],
    [
        "SVEN SCHUSTER BAU GMBH",
        "SVEN SCHUSTER BAU"
    ],
    [
        "SWISS KNIFE SHOP",
        "SWISS KNIFE SHOP"
    ],
    [
        "SYNLAB SUISSE SA",
        "SYNLAB"
    ],
    [
        "SYSCAM GMBH",
        "SYSCAM"
    ],
    [
        "SYSTEC & SERVICES GMBH",
        "SYSTEC & SERVICES"
    ],
    [
        "SÜDALARM CZERULLA GMBH",
        "SÜDALARM CZERULLA"
    ],
    [
        "TANOS GMBH",
        "TANOS"
    ],
    [
        "TANTEC EST INC. *",
        "TANTEC EST INC."
    ],
    [
        "TAPER LINE, INC.*",
        "TAPER LINE"
    ],
    [
        "TARDANI INSTALLATIONS LLC",
        "TARDANI INSTALLATIONS"
    ],
    [
        "TE CONNECTIVITY CORPORATION",
        "TE CONNECTIVITY"
    ],
    [
        "BK TEACHOUT INVESTIGATIONS INC",
        "TEACHOUT INVESTIGATIONS"
    ],
    [
        "TECHMASTER",
        "TECHMASTER"
    ],
    [
        "TECHNICAL SAFETY SERVICES LLC",
        "TECHNICAL SAFETY SERVICES"
    ],
    [
        "TECNI SERVICIOS",
        "TECNI SERVICIOS"
    ],
    [
        "TECNICENTRO ROYAL",
        "TECNICENTRO ROYAL"
    ],
    [
        "TEL SOLUTIONS, LLC",
        "TEL SOLUTIONS, LLC"
    ],
    [
        "TELEDYNE INSTRUMENTS, INC.",
        "TELEDYNE INSTRUMENTS"
    ],
    [
        "TENNANT COMP.",
        "TENNANT COMP"
    ],
    [
        "TGW INTERNATIONAL, INC.",
        "TGW INTERNATIONAL"
    ],
    [
        "TH PUMPS",
        "TH PUMPS"
    ],
    [
        "THE DRAUGHTING CENTRE",
        "THE DRAUGHTING CENTRE"
    ],
    [
        "THE FLOLO CORP.",
        "THE FLOLO"
    ],
    [
        "THE GETTIG CORPORATION",
        "THE GETTIG CORPORATION"
    ],
    [
        "THERMO FISHER SCIENTIFIC",
        "THERMO FISHER SCIENTIFIC"
    ],
    [
        "THIELENHAUS MICROFINISH CORP",
        "THIELENHAUS MICROFINISH"
    ],
    [
        "THOMAS SYS TECH GMBH",
        "THOMAS SYS TECH"
    ],
    [
        "THYSSENKRUPP ELEVATOR",
        "THYSSENKRUPP ELEVATOR"
    ],
    [
        "TIPTON PLUMBING",
        "TIPTON PLUMBING"
    ],
    [
        "TMC TECHNOLOGY",
        "TMC TECHNOLOGY"
    ],
    [
        "TMK MANUFACTURING INC",
        "TMK MANUFACTURING"
    ],
    [
        "TOM DAUBER'S DENTAL EQUIP REPAIR SERVICES",
        "TOM DAUBER S DENTAL EQUIP REPAIR SERVICES"
    ],
    [
        "TOPCOAT SYSTEMS LTD,",
        "TOPCOAT SYSTEMS"
    ],
    [
        "TORNOS S.A.",
        "TORNOS S A"
    ],
    [
        "TOSAG ENGINEERING AG",
        "TOSAG ENGINEERING"
    ],
    [
        "TOSS MACHINE COMPONENTS,*",
        "TOSS MACHINE COMPONENTS"
    ],
    [
        "TOTAL CLEANING SUPPLIES,",
        "TOTAL CLEANING SUPPLIES"
    ],
    [
        "TOTAL WALTHER GMBH",
        "TOTAL WALTHER"
    ],
    [
        "TPS, LLC - SPX THERMAN PRODUCT SOLUTIONS",
        "TPS SPX THERMAN PRODUCT SOLUTIONS"
    ],
    [
        "TRANS CONTINENTAL",
        "TRANS CONTINENTAL"
    ],
    [
        "TRI STATE WATER MAIN TAPS LLC",
        "TRI STATE WATER MAIN TAPS"
    ],
    [
        "TRINAMICS INCORPORATED",
        "TRINAMICS INCORPORATED"
    ],
    [
        "TRISTATE INDUSTRIAL",
        "TRISTATE INDUSTRIAL"
    ],
    [
        "TRITECH FALL PROTECTION SYSTEMS INC",
        "TRITECH FALL PROTECTION SYSTEMS"
    ],
    [
        "TRITSCHLER MASCHINEN UND WERKZEUGE",
        "TRITSCHLER MASCHINEN UND WERKZEUGE"
    ],
    [
        "TRU CUT",
        "TRU CUT"
    ],
    [
        "TRULY NOLEN OF AMERICA, INC.",
        "TRULY NOLEN OF"
    ],
    [
        "TS TOR & SERVICE AG",
        "TS TOR & SERVICE"
    ],
    [
        "TSA COMMUNICATIONS INC.",
        "TSA COMMUNICATIONS"
    ],
    [
        "TYCO / ADT FIRE & SECURIT",
        "TYCO ADT FIRE & SECURIT"
    ],
    [
        "TÜV SÜD INDUSTRIE SERVICE GMBH",
        "TÜV SÜD INDUSTRIE SERVICE"
    ],
    [
        "T‹V NORD SYSTEMS GMBH&CO.KG",
        "T‹V NORD SYSTEMS"
    ],
    [
        "UDO KIENÖL",
        "UDO KIENÖL"
    ],
    [
        "UFIRST  LAUNDRY SVCS",
        "UFIRST LAUNDRY SVCS"
    ],
    [
        "ULMATEC GMBH",
        "ULMATEC"
    ],
    [
        "ULTRATEC-CNC-GMBH",
        "ULTRATEC CNC"
    ],
    [
        "ULTRAWAVE LTD",
        "ULTRAWAVE"
    ],
    [
        "UNILABS",
        "UNILABS"
    ],
    [
        "UNITED GRINDING TECH.",
        "UNITED GRINDING TECH"
    ],
    [
        "UNITED RENTALS",
        "UNITED RENTALS"
    ],
    [
        "UNITY CONSTRUCTION SERVICES INC",
        "UNITY CONSTRUCTION SERVICES"
    ],
    [
        "US AUTOMATION INC",
        "US AUTOMATION"
    ],
    [
        "USON CORP",
        "USON CORP"
    ],
    [
        "USP TECHNOLOGIES",
        "USP TECHNOLOGIES"
    ],
    [
        "V1 VELOCITY PROJECTS LTD.",
        "V1 VELOCITY PROJECTS"
    ],
    [
        "VACUUM FURNACE ENG. LTD.,",
        "VACUUM FURNACE ENG"
    ],
    [
        "VANGUARD MEDICAL",
        "VANGUARD MEDICAL"
    ],
    [
        "VATER KNS ENERGY GMBH",
        "VATER KNS ENERGY"
    ],
    [
        "VEBICODE ETIKETTEN DRUCKER & SOFTWA",
        "VEBICODE ETIKETTEN DRUCKER & SOFTWA"
    ],
    [
        "VEEARC, LLC",
        "VEEARC"
    ],
    [
        "VEOLIA ES TECHNICAL SOLUTIONS LLC",
        "VEOLIA ES TECHNICAL SOLUTIONS LLC"
    ],
    [
        "VEOLIA ES TECHNICAL SOLUTIONS INC",
        "VEOLIA TECHNICAL SOLUTIONS"
    ],
    [
        "VEOLIA WATER TECHNOLOGIES",
        "VEOLIA WATER TECHNOLOGIES"
    ],
    [
        "VIDEOJET TECHNOLOGIES, INC",
        "VIDEOJET TECHNOLOGIES"
    ],
    [
        "VINATORU ENTERPRISES INC",
        "VINATORU ENTERPRISES"
    ],
    [
        "VIOLLIER AG FINANZEN",
        "VIOLLIER FINANZEN"
    ],
    [
        "VISSEL GMBH",
        "VISSEL"
    ],
    [
        "VORTEX COLORADO INC",
        "VORTEX COLORADO"
    ],
    [
        "W. SOULE & CO",
        "W SOULE"
    ],
    [
        "W.SCHMIDT SONNENSCHUTZ E.K.",
        "W.SCHMIDT SONNENSCHUTZ E.K."
    ],
    [
        "WALDMANN LICHTTECHNIK GMBH",
        "WALDMANN LICHTTECHNIK"
    ],
    [
        "WASATCH ELECTRIC",
        "WASATCH ELECTRIC"
    ],
    [
        "WATER CONSULTING *",
        "WATER CONSULTING"
    ],
    [
        "WAUKEGAN ROOFING CO. INC.",
        "WAUKEGAN ROOFING"
    ],
    [
        "WEBAPPS BURGDORF GMBH",
        "WEBAPPS BURGDORF"
    ],
    [
        "WEST FLORIDA PRECISION MACHINE, LLC",
        "WEST FLORIDA PRECISION MACHINE LLC"
    ],
    [
        "WHITE & SHAUGER INC",
        "WHITE & SHAUGER"
    ],
    [
        "WIGINTON FIRE SYSTEMS, INC.",
        "WIGINTON FIRE SYSTEMS"
    ],
    [
        "WINTOOL AG",
        "WINTOOL"
    ],
    [
        "WINZ U. MINK DRUCKLUFTTECHNIK GBR",
        "WINZ U MINK DRUCKLUFTTECHNIK"
    ],
    [
        "WIPOTEC-OCS, INC. *",
        "WIPOTEC-OCS, INC."
    ],
    [
        "WISAG PRODUKTIONSSERVICE GMBH",
        "WISAG PRODUKTIONSSERVICE"
    ],
    [
        "WISCONSIN OVEN CORPORATION",
        "WISCONSIN OVEN CORPORATION"
    ],
    [
        "WORLD MANUFACTURING INC",
        "WORLD MANUFACTURING"
    ],
    [
        "WRIGHT EXPRESS FSC",
        "WRIGHT EXPRESS FSC"
    ],
    [
        "XACTWIRE EDM OF ILLINOIS",
        "XACTWIRE EDM OF ILLINOIS"
    ],
    [
        "XTTRIUM LABORATORIES *",
        "XTTRIUM LABORATORIES"
    ],
    [
        "ZAHORANSKY AG *",
        "ZAHORANSKY"
    ],
    [
        "CONCEPT LASER GMBH",
        "CONCEPT LASER"
    ],
    [
        "FANUC DEUTSCHLAND GMBH",
        "FANUC"
    ],
    [
        "FANUC AMERICA CORP",
        "FANUC"
    ],
    [
        "FANUC SWITZERLAND GMBH",
        "FANUC"
    ],
    [
        "FANUC AMERICA CORP *",
        "FANUC"
    ],
    [
        "FANUC ROBOTICS",
        "FANUC"
    ],
    [
        "FANUC GE CNC SWITZERLAND GMB",
        "FANUC"
    ],
    [
        "FANUC UK LIMITED",
        "FANUC"
    ],
    [
        "HERMLE (SCHWEIZ) AG",
        "HERMLE"
    ],
    [
        "HTG- HIGH TECH GERAETEBAU",
        "HTG HIGH TECH GERAETEBAU"
    ],
    [
        "JBM TECHNOLOGIES",
        "JBM TECHNOLOGIES"
    ],
    [
        "MITUTOYO (UK) LTD.",
        "MITUTOYO"
    ],
    [
        "MITUTOYO DEUTSCHLAND GMBH",
        "MITUTOYO"
    ],
    [
        "MITUTOYO AMERICA CORPORATION",
        "MITUTOYO"
    ],
    [
        "MITUTOYO AMERICA CORPORAT",
        "MITUTOYO"
    ],
    [
        "MITUTOYO MESSGERÄTE",
        "MITUTOYO"
    ],
    [
        "MITUTOYO SUISSE AG",
        "MITUTOYO"
    ],
    [
        "NEWEMAG AG",
        "NEWEMAG"
    ],
    [
        "SIEMERS INSPECTION SERVICE INC",
        "SIEMENS"
    ],
    [
        "SIEMENS INDUSTRY",
        "SIEMENS"
    ],
    [
        "SIEMENS INDUSTRY INC",
        "SIEMENS"
    ],
    [
        "SIEMENS PRODUCT LIFECYCLE MANA",
        "SIEMENS"
    ],
    [
        "STAR MICRONICS GMBH",
        "STAR MICRONICS"
    ],
    [
        "STAR MICRONICS GB LTD",
        "STAR MICRONICS"
    ],
    [
        "TBT TIEFBOHRTECHNIK GMBH&CO",
        "TBT TIEFBOHRTECHNIK"
    ],
    [
        "YAMAZAKI MAZAK UK LTD",
        "YAMAZAKI MAZAK"
    ],
    [
        "YAMAZAKI MAZAK GMBH",
        "YAMAZAKI MAZAK"
    ],
    [
        "1ST EMERGENCY RESPONSE TR",
        "1ST EMERGENCY RESPONSE"
    ],
    [
        "A&D VERPACKUNGSMACHINENBAU",
        "A&D VERPACKUNGSMACHINENBAU"
    ],
    [
        "ACCREATE EXECUTIVE SEARCH",
        "ACCREATE EXECUTIVE SEARCH"
    ],
    [
        "ACTEMIUM BORDEAUX PROCESS",
        "ACTEMIUM BORDEAUX PROCESS"
    ],
    [
        "ACUMEN DATA SYSTEM INC",
        "ACUMEN DATA SYSTEM"
    ],
    [
        "ADDQUAL LIMITED",
        "ADDQUAL"
    ],
    [
        "ADECCO PERSONALDIENSTLEIST.",
        "ADECCO PERSONALDIENSTLEIST"
    ],
    [
        "ADN",
        "ADN"
    ],
    [
        "ADS-TEC GMBH",
        "ADS TEC"
    ],
    [
        "ADVANTECH CORPORATION",
        "ADVANTECH"
    ],
    [
        "ADVENTURE WORLD TOURS",
        "ADVENTURE WORLD TOURS"
    ],
    [
        "AFFINITY FINANCIAL NETW",
        "AFFINITY FINANCIAL NETWORK"
    ],
    [
        "AFNOR",
        "AFNOR"
    ],
    [
        "AFT MICROMECANIQUE",
        "AFT MICROMECANIQUE"
    ],
    [
        "AGENCE DE L'EAU ADOUR-GARONN",
        "AGENCE L EAU ADOUR GARONN"
    ],
    [
        "AGILE JOURNEY",
        "AGILE JOURNEY"
    ],
    [
        "AGILITY KNOWLEDGE MANAGE",
        "AGILITY KNOWLEDGE MANAGE"
    ],
    [
        "AGROLAB AGRAR &UMWELT GMBH",
        "AGROLAB AGRAR &UMWELT"
    ],
    [
        "ALLIED UNIVERSAL SECURITY SERVICES",
        "ALLIED UNIVERSAL SECURITY SERVICES"
    ],
    [
        "ALPHA ONE SECURITY SOLUTIONS",
        "ALPHA ONE SECURITY SOLUTIONS"
    ],
    [
        "ALPHASET DESIGN",
        "ALPHASET DESIGN"
    ],
    [
        "ALTA FIRE PROTECTION COMP",
        "ALTA FIRE PROTECTION COMP"
    ],
    [
        "ALTAIR CONSEIL",
        "ALTAIR CONSEIL"
    ],
    [
        "AMERICAN CHAMBER OF COMM",
        "AMERICAN CHAMBER OF COMM"
    ],
    [
        "AMETEK CTS EUROPE GMBH",
        "AMETEK CTS"
    ],
    [
        "AMIET AGRO-SERVICE GMBH",
        "AMIET AGRO SERVICE"
    ],
    [
        "ANA T MERCADO",
        "ANA T MERCADO"
    ],
    [
        "ANCS INC",
        "ANCS"
    ],
    [
        "ANSERMET VERONIQUE",
        "ANSERMET VERONIQUE"
    ],
    [
        "AON SUISSE SA",
        "AON"
    ],
    [
        "AON",
        "AON"
    ],
    [
        "AON JAUCH & HⁿBENER GMBH",
        "AON"
    ],
    [
        "AON JAUCH & H¸BENER GMBH",
        "AON"
    ],
    [
        "APEX MEDICAL CORP",
        "APEX MEDICAL"
    ],
    [
        "APPLIED SIX SIGMA TECHNOLOGY LTD",
        "APPLIED SIX SIGMA TECHNOLOGY"
    ],
    [
        "APPLIED TECHNOLOGY",
        "APPLIED TECHNOLOGY"
    ],
    [
        "AQTO GMBH",
        "AQTO"
    ],
    [
        "AQUATIC ANALYSTS INC",
        "AQUATIC ANALYSTS"
    ],
    [
        "ARBEIT UND LEBEN",
        "ARBEIT UND LEBEN"
    ],
    [
        "ARCHIVECO",
        "ARCHIVECO"
    ],
    [
        "ARQUM GMBH",
        "ARQUM"
    ],
    [
        "ARTHUR COX",
        "ARTHUR COX"
    ],
    [
        "ASDEE LTD",
        "ASDEE"
    ],
    [
        "ASFA",
        "ASFA"
    ],
    [
        "ASSOC HUMAN RESOURCES",
        "ASSOC HUMAN RESOURCES"
    ],
    [
        "ASSOCIATED FIRE PROTECTIO",
        "ASSOCIATED FIRE PROTECTIO"
    ],
    [
        "ASSOCIATED RESOURCE SERVICE LTD",
        "ASSOCIATED RESOURCE SERVICE"
    ],
    [
        "ASYRIL SA",
        "ASYRIL"
    ],
    [
        "ATHLETIC MENTORS LLC",
        "ATHLETIC MENTORS"
    ],
    [
        "ATI ALLEGHENY TECHNOLOGIES",
        "ATI ALLEGHENY TECHNOLOGIES"
    ],
    [
        "ATLANTIC HOTEL KIEL",
        "ATLANTIC HOTEL KIEL"
    ],
    [
        "AURIGA POLYMERS,INC. *",
        "AURIGA POLYMERS"
    ],
    [
        "AUTOCAM MEDICAL DEVICES LLC",
        "AUTOCAM MEDICAL DEVICES"
    ],
    [
        "AVID DESIGN",
        "AVID DESIGN"
    ],
    [
        "AWS-WERBEAGENTUR GMBH",
        "AWS WERBEAGENTUR"
    ],
    [
        "AXEON CORPORATION",
        "AXEON"
    ],
    [
        "AXIANS EWASTE GMBH",
        "AXIANS EWASTE"
    ],
    [
        "B7 INGENIEURBUERO",
        "B7 INGENIEURBUERO"
    ],
    [
        "BAD GESUNDHEITSVORSORGE UND",
        "BAD GESUNDHEITSVORSORGE UND"
    ],
    [
        "BADEN TAX MANAGEMENT LLC",
        "BADEN TAX MANAGEMENT"
    ],
    [
        "BAM BUNDESANSTALT",
        "BAM BUNDESANSTALT"
    ],
    [
        "BANTEL UND KOLLEGEN",
        "BANTEL UND KOLLEGEN"
    ],
    [
        "BARCODE RE-SOURCING, INC.",
        "BARCODE RE-SOURCING"
    ],
    [
        "BARLAG WERBE- & MESSEAGENTUR",
        "BARLAG WERBE & MESSEAGENTUR"
    ],
    [
        "BAU-DIENST-KIEL GMBH",
        "BAU DIENST KIEL"
    ],
    [
        "BDO",
        "BDO"
    ],
    [
        "BELLS GENERAL STORE",
        "BELLS GENERAL STORE"
    ],
    [
        "BERKLEY RECRUITMENT",
        "BERKLEY RECRUITMENT"
    ],
    [
        "BERLAC AG",
        "BERLAC"
    ],
    [
        "BIGDELI ISSAZADEH,SABINE",
        "BIGDELI ISSAZADEH SABINE"
    ],
    [
        "BINDAN GMBH",
        "BINDAN"
    ],
    [
        "BINDER GMBH",
        "BINDER"
    ],
    [
        "BIOMATECH NAMSA",
        "BIOMATECH NAMSA"
    ],
    [
        "BOCKHOLDT KG",
        "BOCKHOLDT"
    ],
    [
        "BOCKHOLDT GEBAEUDEDIENSTE",
        "BOCKHOLDT"
    ],
    [
        "BOCKSTAHLER BODENBESCHICHTUNGEN GMB",
        "BOCKSTAHLER BODENBESCHICHTUNGEN"
    ],
    [
        "BOREAS DE JESUS ARQUITECTOS",
        "BOREAS JESUS ARQUITECTOS"
    ],
    [
        "VAT BOX",
        "BOX"
    ],
    [
        "BRUNEL CAR SYNERGIES GMBH",
        "BRUNEL CAR SYNERGIES"
    ],
    [
        "BRÜTSCH / RÜEGGER AG",
        "BRUTSCH RUEGGER"
    ],
    [
        "BSI GROUP AMERICA INC",
        "BSI GROUP"
    ],
    [
        "BSI MANAGEMENT SYSTEMS AMERICA INC",
        "BSI MANAGEMENT SYSTEMS"
    ],
    [
        "BTC - SALT LAKE CITY",
        "BTC"
    ],
    [
        "BUNDESDRUCKEREI GMBH",
        "BUNDESDRUCKEREI"
    ],
    [
        "BUSINESS SOUND AND MUSIC INC",
        "BUSINESS SOUND AND MUSIC"
    ],
    [
        "C. KUEHL STAPLER-TECHNIK",
        "C. KUEHL STAPLER-TECHNIK"
    ],
    [
        "C.T.DEC",
        "C.T.DEC"
    ],
    [
        "CADFEM IRELAND LIMITED",
        "CADFEM"
    ],
    [
        "CAMBRIDGE POLYMER GROUP",
        "CAMBRIDGE POLYMER GROUP"
    ],
    [
        "CANVYS - VISUAL TECHNOLOGY SOLUTION",
        "CANVYS VISUAL TECHNOLOGY"
    ],
    [
        "CARAT ROBOTIC INNOVATION",
        "CARAT ROBOTIC INNOVATION"
    ],
    [
        "CARIBE HYDROBLASTING CORP",
        "CARIBE HYDROBLASTING"
    ],
    [
        "CARITASVERBAND FREIBURG-STADT E.V.",
        "CARITASVERBAND FREIBURG STADT E V"
    ],
    [
        "CARL-SCHURZ-HAUS",
        "CARL SCHURZ HAUS"
    ],
    [
        "CARLTON B. CRAIGHEAD JR.",
        "CARLTON B CRAIGHEAD JR"
    ],
    [
        "CAROLINE CURRID",
        "CAROLINE CURRID"
    ],
    [
        "CATAPULT",
        "CATAPULT"
    ],
    [
        "CATHERINE MORALES MAURAS",
        "CATHERINE MORALES MAURAS"
    ],
    [
        "CEGEDIM SRH",
        "CEGEDIM SRH"
    ],
    [
        "CELF CREATIVE DESIGNS LTD",
        "CELF CREATIVE DESIGNS"
    ],
    [
        "CFPS",
        "CFPS"
    ],
    [
        "CG CONTROLS LTD",
        "CG CONTROLS"
    ],
    [
        "CHEMTECH",
        "CHEMTECH"
    ],
    [
        "CHEMTREC",
        "CHEMTREC"
    ],
    [
        "CHEMTREC CUSTOMER SERVICE",
        "CHEMTREC CUSTOMER SERVICE"
    ],
    [
        "CHILLER TECHNOLOGY SERVICES INC",
        "CHILLER TECHNOLOGY SERVICES"
    ],
    [
        "CHRISTIAN BEYER",
        "CHRISTIAN BEYER"
    ],
    [
        "CHRISTINA KLOODT FOTOGRAFIE",
        "CHRISTINA KLOODT FOTOGRAFIE"
    ],
    [
        "CIBT VISA SERVICES",
        "CIBT VISA SERVICES"
    ],
    [
        "CIMQUEST INC",
        "CIMQUEST"
    ],
    [
        "CIOYO LTD (T/A INSIDE OUT",
        "CIOYO"
    ],
    [
        "CIT CORK INST. TECH.",
        "CIT CORK INST TECH"
    ],
    [
        "CLAYTON HOTEL CORK CITY",
        "CLAYTON HOTEL"
    ],
    [
        "CLYDE & CO",
        "CLYDE & CO"
    ],
    [
        "COACHING DEVELOPEMENT LTD",
        "COACHING DEVELOPEMENT"
    ],
    [
        "COMPLIANCE RESOURCES",
        "COMPLIANCE RESOURCES"
    ],
    [
        "COMPOSITE MOTORS, INC.",
        "COMPOSITE MOTORS"
    ],
    [
        "COMPREHENSIVE TEST",
        "COMPREHENSIVE TEST"
    ],
    [
        "LA CONCIERGERIE SOLIDAIRE",
        "CONCIERGERIE SOLIDAIRE"
    ],
    [
        "CONFARMA FRANCE SARL",
        "CONFARMA"
    ],
    [
        "CONFARMA",
        "CONFARMA"
    ],
    [
        "CONVERGINT TECHNOLOGIES",
        "CONVERGINT TECHNOLOGIES"
    ],
    [
        "COOK & LIVE",
        "COOK & LIVE"
    ],
    [
        "COORSTEK MEDICAL LLC VANDALI",
        "COORSTEK MEDICAL"
    ],
    [
        "IT@CORK",
        "CORK"
    ],
    [
        "CORPORATE CARE RELOCATION",
        "CORPORATE CARE RELOCATION"
    ],
    [
        "CORREDURIA PUBLICA",
        "CORREDURIA PUBLICA"
    ],
    [
        "CORRIENTE VERDE INC",
        "CORRIENTE VERDE"
    ],
    [
        "CPL SOLUTIONS LTD T/A KEN",
        "CPL SOLUTIONS"
    ],
    [
        "CQL INCORPORATED",
        "CQL"
    ],
    [
        "CR PAYROLL SOLUTIONS LTD",
        "CR PAYROLL SOLUTIONS"
    ],
    [
        "CREST SOLUTIONS",
        "CREST SOLUTIONS"
    ],
    [
        "CREST SOLUTIONS LIMITED",
        "CREST SOLUTIONS"
    ],
    [
        "CRONINS COACHES",
        "CRONINS COACHES"
    ],
    [
        "CROWN GABELSTAPLER GMBH",
        "CROWN GABELSTAPLER"
    ],
    [
        "CSA AMERICA INC",
        "CSA"
    ],
    [
        "CSA INTERNATIONAL",
        "CSA"
    ],
    [
        "CUBICLE CONTRACTORS, LLC",
        "CUBICLE CONTRACTORS"
    ],
    [
        "CUMMINS SOUTHERN PLAINS LLC",
        "CUMMINS SOUTHERN PLAINS"
    ],
    [
        "CYRUS INDUSTRIE",
        "CYRUS INDUSTRIE"
    ],
    [
        "D&B",
        "D&B"
    ],
    [
        "DACHSER GMBH&COKG",
        "DACHSER"
    ],
    [
        "DASSAULT SYSTEMES",
        "DASSAULT SYSTEMES"
    ],
    [
        "DAT DOCUMENTATION ASSISTANCE",
        "DAT DOCUMENTATION ASSISTANCE"
    ],
    [
        "DAVID KEANE PHOTOGRAP",
        "DAVID KEANE PHOTOGRAPHY"
    ],
    [
        "DDM MARKETING & COMMUNICATIONS",
        "DDM MARKETING & COMMUNICATIONS"
    ],
    [
        "DECOM PRÜFLABOR GMBH",
        "DECOM PRÜFLABOR"
    ],
    [
        "DEKRA INDUSTRIAL SAS",
        "DEKRA INDUSTRIAL"
    ],
    [
        "DELTA MU CONSEIL",
        "DELTA CONSEIL"
    ],
    [
        "DEMAND SOLUTIONS LTD",
        "DEMAND SOLUTIONS"
    ],
    [
        "DETLEF HOFMANN GMBH",
        "DETLEF HOFMANN"
    ],
    [
        "DEUTSCHES ANWALTSINSTITUT",
        "DEUTSCHES ANWALTSINSTITUT"
    ],
    [
        "DIAMOND RIGGING CORP.",
        "DIAMOND RIGGING"
    ],
    [
        "DIMENSIONAL COMMUNICATIONS INC",
        "DIMENSIONAL COMMUNICATIONS"
    ],
    [
        "DITA MODERNA",
        "DITA MODERNA"
    ],
    [
        "DON RAFA CAR RENTAL",
        "DON RAFA CAR RENTAL"
    ],
    [
        "DONALD GUTHRIE FOUNDATION FOR EDUCATION AND RESEARCH",
        "DONALD GUTHRIE FOUNDATION FOR EDUCATION AND RESEARCH"
    ],
    [
        "DOUGLAS CONTROL & AUTOMAT",
        "DOUGLAS CONTROL & AUTOMATION"
    ],
    [
        "DPS ENGINEERING & CONSTRU",
        "DPS ENGINEERING & CONSTRUCTION"
    ],
    [
        "DR.A.JOIST",
        "DR A JOIST"
    ],
    [
        "DR.HEINRICH SCHNEIDER",
        "DR HEINRICH SCHNEIDER"
    ],
    [
        "DR. ING. J.VOGT",
        "DR ING J VOGT"
    ],
    [
        "EC SERVICE CORPORATION *",
        "EC SERVICE CORPORATION"
    ],
    [
        "ECOLAB PEST FRANCE SAS",
        "ECOLAB"
    ],
    [
        "ECON SOLUTIONS GMBH",
        "ECON SOLUTIONS"
    ],
    [
        "EDMUND OPTICS LTD",
        "EDMUND OPTICS"
    ],
    [
        "EDWARD COTTER PARTNERSHIP",
        "EDWARD COTTER PARTNERSHIP"
    ],
    [
        "ELB-SCHLIFF WERKZEUGMASCHINEN GMBH",
        "ELB SCHLIFF WERKZEUGMASCHINEN"
    ],
    [
        "ELECTRA LINK INC",
        "ELECTRA LINK"
    ],
    [
        "MV ELEKTRONIK RECYCLING GMBH",
        "ELEKTRONIK RECYCLING"
    ],
    [
        "ELITE TOOLING LLC",
        "ELITE TOOLING"
    ],
    [
        "ELMSTONE SYSTEMS",
        "ELMSTONE SYSTEMS"
    ],
    [
        "ELOS MEDTECH AB",
        "ELOS MEDTECH"
    ],
    [
        "EMPLOYMENT HEALTH ADVISER",
        "EMPLOYMENT HEALTH ADVISER"
    ],
    [
        "ENTERPRISE ENVIRONMENTAL",
        "ENTERPRISE ENVIRONMENTAL"
    ],
    [
        "ERM CERTIFICATION AND VERIFICATION SERVICES LIMITED",
        "ERM CERTIFICATION AND VERIFICATION SERVICES"
    ],
    [
        "ERNESTO LOPEZ AND ASSO",
        "ERNESTO LOPEZ AND ASSO"
    ],
    [
        "ERNST & YOUNG",
        "ERNST & YOUNG"
    ],
    [
        "ERNST & YOUNG LIMERICK",
        "ERNST & YOUNG"
    ],
    [
        "ERNST PFAFF GMBH",
        "ERNST PFAFF"
    ],
    [
        "ESCOTECH INC",
        "ESCOTECH"
    ],
    [
        "ETL PRÜFTECHNIK GMBH",
        "ETL PRÜFTECHNIK"
    ],
    [
        "EUROFINS CORK LTD",
        "EUROFINS CORK"
    ],
    [
        "EUROFINS FOOD TESTING IRL",
        "EUROFINS CORK"
    ],
    [
        "EUROPTEC POLYMER",
        "EUROPTEC POLYMER"
    ],
    [
        "EWIS GMBH",
        "EWIS"
    ],
    [
        "EXCELLENT PHARMA CONSULTING",
        "EXCELLENT PHARMA CONSULTING"
    ],
    [
        "EXCO CONSULTING GMBH",
        "EXCO CONSULTING"
    ],
    [
        "EXOVA CATALYST IRL LTD",
        "EXOVA"
    ],
    [
        "FAMILA HANDELSMARKT KIEL GM",
        "FAMILA HANDELSMARKT KIEL"
    ],
    [
        "FEN-LAB GMBH",
        "FEN LAB"
    ],
    [
        "FENETRA ASSOCIATES LTD",
        "FENETRA ASSOCIATES"
    ],
    [
        "FIBEROPTICS COMPONENTS",
        "FIBEROPTICS COMPONENTS"
    ],
    [
        "FINAL ESCAPE 2.2 GMBH",
        "FINAL ESCAPE 2 2"
    ],
    [
        "FIONA LANE GUIDING SERV",
        "FIONA LANE GUIDING SERV"
    ],
    [
        "FIRE ENGINEERING COMPANY,",
        "FIRE ENGINEERING"
    ],
    [
        "FLOWVISION LLC",
        "FLOWVISION"
    ],
    [
        "FLUIRSE LTD",
        "FLUIRSE"
    ],
    [
        "FPWEB.NET LC",
        "FPWEB NET"
    ],
    [
        "FRANCES RIOS COMMUNICATIONS INC",
        "FRANCES RIOS COMMUNICATIONS"
    ],
    [
        "FRÜH VERPACKUNGSTECHNIK AG",
        "FRÜH VERPACKUNGSTECHNIK"
    ],
    [
        "FÖRDERVEREIN SCIENCE & TECHNOLOGIE",
        "FÖRDERVEREIN SCIENCE & TECHNOLOGIE"
    ],
    [
        "G-MED NORTH AMERICA, INC.",
        "G-MED"
    ],
    [
        "GEBR. POTTHAST GMBH & CO. KG",
        "GEBR POTTHAST"
    ],
    [
        "GEISLER-FEUERSCHUTZ GMBH",
        "GEISLER FEUERSCHUTZ"
    ],
    [
        "GENFA GS1 FRANCE",
        "GENFA GS1"
    ],
    [
        "GEOMETRICS LEARNING SYSTEMS",
        "GEOMETRICS LEARNING SYSTEMS"
    ],
    [
        "GESTFORM",
        "GESTFORM"
    ],
    [
        "GIB GESUNDHEIT IN BEWEGUNG",
        "GIB GESUNDHEIT BEWEGUNG"
    ],
    [
        "GLOBAL SOURCING GROUP INC",
        "GLOBAL SOURCING GROUP"
    ],
    [
        "GOEKELER MESSTECHNIK GMBH",
        "GOEKELER MESSTECHNIK"
    ],
    [
        "GREATLAND",
        "GREATLAND"
    ],
    [
        "GRM INFORMATION MANAGEMENT SERVICES",
        "GRM INFORMATION MANAGEMENT SERVICES"
    ],
    [
        "GUAYAMA RESTAURANT SUPPLIES",
        "GUAYAMA RESTAURANT SUPPLIES"
    ],
    [
        "GUTEKUNST+CO",
        "GUTEKUNST"
    ],
    [
        "HAGERMAN & CO INC",
        "HAGERMAN"
    ],
    [
        "HAMMA FOTOFACHGESCHÄFT",
        "HAMMA FOTOFACHGESCHÄFT"
    ],
    [
        "HANNES BAHRS",
        "HANNES BAHRS"
    ],
    [
        "HARDING ENERGY INC.",
        "HARDING ENERGY"
    ],
    [
        "HARING + PARTNER",
        "HARING PARTNER"
    ],
    [
        "HARLOR INDUSTRIES",
        "HARLOR INDUSTRIES"
    ],
    [
        "HAUFE SERVICE GMBH",
        "HAUFE SERVICE"
    ],
    [
        "HAUPTZOLLAMT LÖRRACH",
        "HAUPTZOLLAMT LÖRRACH"
    ],
    [
        "HAZARDOUS WASTE EXPERTS",
        "HAZARDOUS WASTE EXPERTS"
    ],
    [
        "HEALTH PRODUCTS REG AUTH",
        "HEALTH PRODUCTS REG AUTH"
    ],
    [
        "HELGE",
        "HELGE"
    ],
    [
        "HENNING CARSTENS",
        "HENNING CARSTENS"
    ],
    [
        "HERBERT SCHILLING",
        "HERBERT SCHILLING"
    ],
    [
        "HERBSTRITT LAGER UND LOGISTIK GMBH",
        "HERBSTRITT LAGER UND LOGISTIK"
    ],
    [
        "HEREL PAIE & PERSONNEL",
        "HEREL PAIE & PERSONNEL"
    ],
    [
        "HERMANN",
        "HERMANN"
    ],
    [
        "HERTZ AUTOVERMIETUNG GMBH",
        "HERTZ"
    ],
    [
        "HFJ GALVANO GMBH",
        "HFJ GALVANO"
    ],
    [
        "HOLSTEINTANNE",
        "HOLSTEINTANNE"
    ],
    [
        "HUEGIN-TRIETEX GMBH",
        "HUEGIN TRIETEX"
    ],
    [
        "HURT AKTENVERNICHTUNG GMBH &CO. KG",
        "HURT AKTENVERNICHTUNG"
    ],
    [
        "IDEFIX GMBH",
        "IDEFIX"
    ],
    [
        "IHK AKADEMIE SCHWABEN",
        "IHK AKADEMIE SCHWABEN"
    ],
    [
        "ILLINOIS EMERGENCY",
        "ILLINOIS EMERGENCY"
    ],
    [
        "ILLINOIS EPA",
        "ILLINOIS EPA"
    ],
    [
        "ILU-LUFTANALYTIK GMBH",
        "ILU LUFTANALYTIK"
    ],
    [
        "IMPERIAL HOTEL CORK LTD",
        "IMPERIAL HOTEL CORK"
    ],
    [
        "IMR KHA PORTLAND LLC",
        "IMR KHA PORTLAND"
    ],
    [
        "INDOOR ENVIRONMENTAL CONSULTANTS",
        "INDOOR ENVIRONMENTAL CONSULTANTS"
    ],
    [
        "INDUSTRIAL HEARING HEALTH ASSOC",
        "INDUSTRIAL HEARING HEALTH ASSOC"
    ],
    [
        "INFIELD SAFETY GMBH",
        "INFIELD SAFETY"
    ],
    [
        "INFINITYQS",
        "INFINITYQS"
    ],
    [
        "INFLORESCENCE",
        "INFLORESCENCE"
    ],
    [
        "ING.BUERO THOMAS ZINDEL",
        "ING BUERO THOMAS ZINDEL"
    ],
    [
        "INGA C/O BETTER ONE GMBH",
        "INGA C O BETTER ONE"
    ],
    [
        "INGENIEURBⁿRO FⁿR",
        "INGENIEURB¸RO F¸R"
    ],
    [
        "INGENIEURB¸RO F¸R",
        "INGENIEURB¸RO F¸R"
    ],
    [
        "INITIAL CLEANROOMS",
        "INITIAL CLEANROOMS"
    ],
    [
        "INNOVATION AND LEAN SIGMA FEDERATION T/A INNOVATION AND LEAN SIGMA SKILLNET",
        "INNOVATION AND LEAN SIGMA FEDERATION T A INNOVATION AND LEAN SIGMA SKILLNET"
    ],
    [
        "INTERAP GMBH & CO. KG",
        "INTERAP"
    ],
    [
        "INTERTEK TESTING SERVICES",
        "INTERTEK"
    ],
    [
        "INTERTEK",
        "INTERTEK"
    ],
    [
        "INTERTEK TESTING SERVICES INC",
        "INTERTEK"
    ],
    [
        "INTERTEK TESTING SVC NA,",
        "INTERTEK"
    ],
    [
        "INTERTEK FRANCE",
        "INTERTEK"
    ],
    [
        "IONISOS",
        "IONISOS"
    ],
    [
        "IRACON GMBH (CHF)",
        "IRACON"
    ],
    [
        "IRISH EXPORTERS ASSOC",
        "IRISH EXPORTERS ASSOCIATION"
    ],
    [
        "IRISH MANAGEMENT INSTITUT",
        "IRISH MANAGEMENT INSTITUT"
    ],
    [
        "IRISH MANAGEMENT INSTITUTE",
        "IRISH MANAGEMENT INSTITUTE"
    ],
    [
        "IRISH PAYROLL ASSOCIATION",
        "IRISH PAYROLL ASSOCIATION"
    ],
    [
        "IRON MOUNTAIN OFFSITE",
        "IRON MOUNTAIN OFFSITE"
    ],
    [
        "ISVB DIPL.-ING. K. EBELING",
        "ISVB DIPL ING K EBELING"
    ],
    [
        "ITEM INDUSTRIETECHNIK GMBH",
        "ITEM INDUSTRIETECHNIK"
    ],
    [
        "ITEM INDUSTRIETECHNIK",
        "ITEM INDUSTRIETECHNIK"
    ],
    [
        "J&M DEPOT",
        "J&M DEPOT"
    ],
    [
        "JACK RATTLES TAL DER PIRATEN",
        "JACK RATTLES TAL DER PIRATEN"
    ],
    [
        "JAG JAKOB AG",
        "JAG JAKOB"
    ],
    [
        "JAHN INTERPROF GMBH",
        "JAHN INTERPROF"
    ],
    [
        "JAYS AND FANCY INTERIORS, INC",
        "JAYS AND FANCY INTERIORS"
    ],
    [
        "JERRIKA M ANGLERO SANCHEZ",
        "JERRIKA M ANGLERO SANCHEZ"
    ],
    [
        "JOANNE RAMIREZ OD",
        "JOANNE RAMIREZ OD"
    ],
    [
        "JOB SEARCH AT US LLC",
        "JOB SEARCH"
    ],
    [
        "JOBS CARIBE INC",
        "JOBS CARIBE"
    ],
    [
        "JOHNSON & PERROTT MOTOR",
        "JOHNSON & PERROTT MOTOR"
    ],
    [
        "JR INSULATION S AND SERV",
        "JR INSULATION S AND SERV"
    ],
    [
        "JˆRG BRENNWALD",
        "JˆRG BRENNWALD"
    ],
    [
        "KALTBRUNNER TOP COATINGS",
        "KALTBRUNNER TOP COATINGS"
    ],
    [
        "KANTON SOLOTHURN",
        "KANTON SOLOTHURN"
    ],
    [
        "KATHOLISCHE AKADEMIE",
        "KATHOLISCHE AKADEMIE"
    ],
    [
        "KENS KEYS",
        "KENS KEYS"
    ],
    [
        "KERN KUNSTSTOFFTECHNIK",
        "KERN KUNSTSTOFFTECHNIK"
    ],
    [
        "KEVANE GRANT THORNTON LLP",
        "KEVANE GRANT THORNTON"
    ],
    [
        "KIELER W‰GETECHNIK GMBH",
        "KIELER W‰GETECHNIK"
    ],
    [
        "KK SPASS & FREIZEIT GMBH",
        "KK SPASS & FREIZEIT"
    ],
    [
        "KMB KAMPFMITTELBERGUNG",
        "KMB KAMPFMITTELBERGUNG"
    ],
    [
        "KONZERT- UND VERANSTALTUNGS-",
        "KONZERT UND VERANSTALTUNGS"
    ],
    [
        "KSH KIELER SCHROTTHAND.GMBH",
        "KSH KIELER SCHROTTHAND"
    ],
    [
        "LABORATOIRE DUBOIS",
        "LABORATOIRE DUBOIS"
    ],
    [
        "LABORATOIRE ECCI SARL",
        "LABORATOIRE ECCI"
    ],
    [
        "LABORATOIRE ICARE",
        "LABORATOIRE ICARE"
    ],
    [
        "LAKE MICHIGAN MAILERS INC",
        "LAKE MICHIGAN MAILERS"
    ],
    [
        "LANDGASTHOF LIPPACHMÜHLE",
        "LANDGASTHOF LIPPACHMÜHLE"
    ],
    [
        "LANDKREIS TUTTLINGEN",
        "LANDKREIS TUTTLINGEN"
    ],
    [
        "LASER LINES LTD",
        "LASER LINES"
    ],
    [
        "LATHAM & WATKINS LLP",
        "LATHAM & WATKINS"
    ],
    [
        "LDG TECHNICAL SERVICES",
        "LDG TECHNICAL SERVICES"
    ],
    [
        "LES ENTREPRISES POUR LA CITE",
        "LES ENTREPRISES POUR CITE"
    ],
    [
        "LIMK INSTITUTE OF TECH",
        "LIMK INSTITUTE OF TECH"
    ],
    [
        "LINEMASTER SWITCH CORP.",
        "LINEMASTER SWITCH"
    ],
    [
        "LINTEK LABORTECHNIK VERTR.",
        "LINTEK LABORTECHNIK VERTR"
    ],
    [
        "LIONBRIDGE INTERNATIONAL",
        "LIONBRIDGE INTERNATIONAL"
    ],
    [
        "LOGAR GÜNTHER HASEL E.K.",
        "LOGAR GÜNTHER HASEL E K"
    ],
    [
        "LSA LASER SCANNING",
        "LSA LASER SCANNING"
    ],
    [
        "M&F VERKEHRSTECHNIK",
        "M&F VERKEHRSTECHNIK"
    ],
    [
        "M3 DESIGN, INC.",
        "M3 DESIGN"
    ],
    [
        "MACKENZIE AUTOMATIC DOORS INC",
        "MACKENZIE AUTOMATIC DOORS"
    ],
    [
        "MACKEY PLANT CONSTRUCTION",
        "MACKEY PLANT CONSTRUCTION"
    ],
    [
        "MAF HEAVENLY GROUP",
        "MAF HEAVENLY GROUP"
    ],
    [
        "MAIRIE DE CESTAS",
        "MAIRIE CESTAS"
    ],
    [
        "MAITRE DUCLOS MARINE",
        "MAITRE DUCLOS MARINE"
    ],
    [
        "MAKO DATA LIMITED",
        "MAKO DATA"
    ],
    [
        "MANAN MEDICAL",
        "MANAN MEDICAL"
    ],
    [
        "MANAN MEDICAL PRODUCT INC",
        "MANAN MEDICAL"
    ],
    [
        "MANEPA LTD.,",
        "MANEPA"
    ],
    [
        "MANZ BAGGERBETRIEB",
        "MANZ BAGGERBETRIEB"
    ],
    [
        "MARKGRÄFLER TAXI",
        "MARKGRÄFLER TAXI"
    ],
    [
        "MARO WERBETECHNIK",
        "MARO WERBETECHNIK"
    ],
    [
        "MARTHA KUBICA",
        "MARTHA KUBICA"
    ],
    [
        "MARY SACILOWSKI",
        "MARY SACILOWSKI"
    ],
    [
        "MASCH. BUERO FUER VISUELLE",
        "MASCH BUERO FUER VISUELLE"
    ],
    [
        "MATIS HIGH TECH",
        "MATIS HIGH TECH"
    ],
    [
        "MAZARS",
        "MAZARS"
    ],
    [
        "ME 92 OPERATIONS",
        "ME 92 OPERATIONS"
    ],
    [
        "MEDCOR, INC. *",
        "MEDCOR"
    ],
    [
        "MEDDEV SOLUTIONS LIMITED",
        "MEDDEV SOLUTIONS"
    ],
    [
        "MEDIAN KONSTRUKTION",
        "MEDIAN KONSTRUKTION"
    ],
    [
        "MEDIAPLANET LTD",
        "MEDIAPLANET"
    ],
    [
        "MEDIMET GMBH",
        "MEDIMET"
    ],
    [
        "MEDIN CORP",
        "MEDIN CORPORATION"
    ],
    [
        "MEDIZIN-MECHANIK NORD GMBH",
        "MEDIZIN MECHANIK NORD"
    ],
    [
        "MEDSHARPS LLC",
        "MEDSHARPS"
    ],
    [
        "MENTOR TECHNICAL GROUP",
        "MENTOR TECHNICAL GROUP"
    ],
    [
        "MERCURY CREATION LTD",
        "MERCURY CREATION"
    ],
    [
        "MESSTRONIK GMBH",
        "MESSTRONIK"
    ],
    [
        "MG CORP",
        "MG CORP"
    ],
    [
        "MINDFUL MATCH TALENT SOL",
        "MINDFUL MATCH TALENT SOL"
    ],
    [
        "MINDFUL MATCH TALENT SOLUTIONS",
        "MINDFUL MATCH TALENT SOLUTIONS"
    ],
    [
        "MMG MÜLLERMÜLL",
        "MMG MÜLLERMÜLL"
    ],
    [
        "MOECKEL & DR. SCHAEUBLE",
        "MOECKEL & DR SCHAEUBLE"
    ],
    [
        "MPS PRECIMED",
        "MPS PRECIMED"
    ],
    [
        "MTD / ACTIVE MEDICAL / WESTL",
        "MTD ACTIVE MEDICAL WESTL"
    ],
    [
        "MUELLER KAELBER GMBH",
        "MUELLER"
    ],
    [
        "MULDER'S MOVING",
        "MULDER'S MOVING"
    ],
    [
        "MÜLLERKÄLBER GMBH",
        "MULLERKALBER"
    ],
    [
        "NAMSA BIOMATECH",
        "NAMSA"
    ],
    [
        "NAMSA",
        "NAMSA"
    ],
    [
        "NAMSA WORLD HEADQUARTERS",
        "NAMSA"
    ],
    [
        "NATIONAL BUREAU OF PROPER",
        "NATIONAL BUREAU OF PROPER"
    ],
    [
        "NATIONAL DUST CONTROL SERVICES CORP",
        "NATIONAL DUST CONTROL SERVICES"
    ],
    [
        "NATIONWIDE SHELVING AND A",
        "NATIONWIDE SHELVING"
    ],
    [
        "NAVIGANT",
        "NAVIGANT"
    ],
    [
        "NEXT VISION GMBH",
        "NEXT VISION"
    ],
    [
        "NJ DEPARTMENT OF TREASURY",
        "NJ DEPARTMENT OF TREASURY"
    ],
    [
        "NORDSON MEDICAL IRELAND LIMITED (IRELAND)",
        "NORDSON MEDICAL"
    ],
    [
        "NORTH AMERICA SCIENCE ASSOCIATES (NAMSA)",
        "NORTH AMERICA SCIENCE ASSOCIATES (NAMSA)"
    ],
    [
        "NORTH AMERICAN HOGANAS",
        "NORTH AMERICAN HOGANAS"
    ],
    [
        "NORTHWEST BERGEN COUNTY UTILITIES AUTHORITY",
        "NORTHWEST BERGEN COUNTY UTILITIES AUTHORITY"
    ],
    [
        "NOTFALLTRAINING SCHWEIZ",
        "NOTFALLTRAINING"
    ],
    [
        "NOUEHS CONSULTING",
        "NOUEHS CONSULTING"
    ],
    [
        "NOVIKA SOLUTIONS",
        "NOVIKA SOLUTIONS"
    ],
    [
        "NOWATZKI LOGISTIK GMBH",
        "NOWATZKI LOGISTIK"
    ],
    [
        "NOXILIZER INC.",
        "NOXILIZER"
    ],
    [
        "O'BRIEN'S BUTCHER AND DELI",
        "O'BRIEN'S BUTCHER AND DELI"
    ],
    [
        "OEC MEDICAL SYSTEMS INC",
        "OEC MEDICAL SYSTEMS"
    ],
    [
        "OELSERVICE STEFAN MOND",
        "OELSERVICE STEFAN MOND"
    ],
    [
        "ONLINE-SCHRAUBEN.DE, E.K.",
        "ONLINE SCHRAUBEN E K"
    ],
    [
        "OPTIKSHOP RIESELFELD",
        "OPTIKSHOP RIESELFELD"
    ],
    [
        "OPTIMA TRAINING & CONSULT",
        "OPTIMA TRAINING & CONSULT"
    ],
    [
        "OPTINERGIE",
        "OPTINERGIE"
    ],
    [
        "ORFEA ACOUSTIQUE",
        "ORFEA ACOUSTIQUE"
    ],
    [
        "ORIEL HOUSE HOTEL",
        "ORIEL HOUSE HOTEL"
    ],
    [
        "ORIEL STAT A MATRIX",
        "ORIEL STAT A MATRIX"
    ],
    [
        "ORLANDO LOCKS SERVICES",
        "ORLANDO LOCKS SERVICES"
    ],
    [
        "OUTSOURCE HR LTD",
        "OUTSOURCE"
    ],
    [
        "PACE ANALYTICAL",
        "PACE ANALYTICAL"
    ],
    [
        "PACIFIC PAINTING AND WALL",
        "PACIFIC PAINTING AND WALL"
    ],
    [
        "PACKAGING COMPLIANCE LABS LLC",
        "PACKAGING COMPLIANCE LABS"
    ],
    [
        "PACKAGING COMPLIANCE LABS, LLC",
        "PACKAGING COMPLIANCE LABS"
    ],
    [
        "PACKAGING TECHNOLOGIES & INSPECTION LLC",
        "PACKAGING TECHNOLOGIES & INSPECTION"
    ],
    [
        "PARA TECH COATING INC.",
        "PARA TECH COATING"
    ],
    [
        "EUROPA-PARK MACK KG",
        "PARK MACK"
    ],
    [
        "PHANTOM TECHNICAL",
        "PHANTOM TECHNICAL"
    ],
    [
        "PHOTOS EQUIVOX",
        "PHOTOS EQUIVOX"
    ],
    [
        "PHYSIK INSTRUMENTE (PI) GMBH & CO.",
        "PHYSIK INSTRUMENTE"
    ],
    [
        "PILZ INDUSTRIEELEKTRONIK",
        "PILZ INDUSTRIEELEKTRONIK"
    ],
    [
        "PLANUNGSGRUPPE KMO",
        "PLANUNGSGRUPPE KMO"
    ],
    [
        "PLUMBING AND SEWER",
        "PLUMBING AND SEWER"
    ],
    [
        "POLYMER SYSTEMS TECH LTD",
        "POLYMER SYSTEMS TECH"
    ],
    [
        "PPD DEVELOPMENT, L.P. *",
        "PPD DEVELOPMENT"
    ],
    [
        "PRECISION SIGN & GRAPHICS",
        "PRECISION SIGN & GRAPHICS"
    ],
    [
        "PRECISION SURFACING SYSTEMS INC",
        "PRECISION SURFACING SYSTEMS"
    ],
    [
        "PREUSS HYDROKULTUREN GMBH",
        "PREUSS HYDROKULTUREN"
    ],
    [
        "PRGX USA INC",
        "PRGX"
    ],
    [
        "PRIMO-VERLAGSDRUCK",
        "PRIMO VERLAGSDRUCK"
    ],
    [
        "PROBA SCRIPT",
        "PROBA SCRIPT"
    ],
    [
        "PROLINK SOFTWARE CORP",
        "PROLINK SOFTWARE"
    ],
    [
        "PROWORK AG",
        "PROWORK"
    ],
    [
        "PYXIDIS MEDICAL  MAS",
        "PYXIDIS MEDICAL MAS"
    ],
    [
        "QARAD BVBA",
        "QARAD"
    ],
    [
        "QUADRIGA MEDIA BERLIN GMBH",
        "QUADRIGA MEDIA BERLIN"
    ],
    [
        "QUALITY HOME SATELLITE",
        "QUALITY HOME SATELLITE"
    ],
    [
        "QUMSULT GBR",
        "QUMSULT"
    ],
    [
        "RACHEL PHOTOS",
        "RACHEL PHOTOS"
    ],
    [
        "RADSFATZ",
        "RADSFATZ"
    ],
    [
        "RAP CONSULTING ENGINEER",
        "RAP CONSULTING ENGINEER"
    ],
    [
        "RAPID ENTERPRISE CONSULTING",
        "RAPID ENTERPRISE CONSULTING"
    ],
    [
        "RAST REISEN GMBH",
        "RAST REISEN"
    ],
    [
        "REAL STAFFING GROUP",
        "REAL STAFFING GROUP"
    ],
    [
        "REAL STAFFING GROUP *",
        "REAL STAFFING GROUP"
    ],
    [
        "REBECA DIAZ LOPEZ",
        "REBECA DIAZ LOPEZ"
    ],
    [
        "RED PENGUIN",
        "RED PENGUIN"
    ],
    [
        "REDWOOD & CO",
        "REDWOOD"
    ],
    [
        "REDWOOD AND CO.",
        "REDWOOD AND CO."
    ],
    [
        "REFUSE SPECIALISTS LLC",
        "REFUSE SPECIALISTS"
    ],
    [
        "REGIOCAST GMBH&CO. KG",
        "REGIOCAST"
    ],
    [
        "REGIONAL HELP WANTED",
        "REGIONAL HELP WANTED"
    ],
    [
        "REGIS TECHNOLOGIES, INC. *",
        "REGIS TECHNOLOGIES"
    ],
    [
        "REPUBLIC OF WORK",
        "REPUBLIC OF WORK"
    ],
    [
        "RESOURCES CONNECTION INC",
        "RESOURCES CONNECTION"
    ],
    [
        "RETSCH (UK) LTD",
        "RETSCH"
    ],
    [
        "RIEDEL EDM L÷SUNGEN",
        "RIEDEL EDM"
    ],
    [
        "RISTORANTE BELLA ITALIA",
        "RISTORANTE BELLA"
    ],
    [
        "ROCKY MOUNTAIN STRIPING L",
        "ROCKY MOUNTAIN STRIPING"
    ],
    [
        "ROGER E JAY",
        "ROGER E JAY"
    ],
    [
        "RX OPTICAL 90KM",
        "RX OPTICAL 90KM"
    ],
    [
        "S4S SOLUTIONS",
        "S4S SOLUTIONS"
    ],
    [
        "SAFETY FIRSTNA, INC.*",
        "SAFETY FIRSTNA"
    ],
    [
        "SANDVIK OSPREY",
        "SANDVIK"
    ],
    [
        "SCHMIDT+BARTL GMBH",
        "SCHMIDT BARTL"
    ],
    [
        "SCP BUI-PARIES",
        "SCP BUI-PARIES"
    ],
    [
        "SCP LOUVION",
        "SCP LOUVION"
    ],
    [
        "SECUREX MEDICAL SERVICE",
        "SECUREX MEDICAL SERVICE"
    ],
    [
        "SECURITAS SECURITY SERVICES USA INC",
        "SECURITAS SECURITY SERVICES"
    ],
    [
        "SEKO WORLDWIDE, LLC",
        "SEKO WORLDWIDE"
    ],
    [
        "SELENIUM MEDICAL SAS",
        "SELENIUM MEDICAL"
    ],
    [
        "SET ENVIRONMENTAL, INC",
        "SET ENVIRONMENTAL"
    ],
    [
        "SGS GALSON LABORATORIES, INC.",
        "SGS GALSON LABORATORIES"
    ],
    [
        "SGS GOVMARK TESTING SERVICES",
        "SGS GOVMARK TESTING SERVICES"
    ],
    [
        "SGS LIFE SCIENCE SERVICES",
        "SGS LIFE SCIENCE SERVICES"
    ],
    [
        "SGS STAHLHANDEL GMBH",
        "SGS STAHLHANDEL"
    ],
    [
        "SHEYSSA VELEZ LOPEZ",
        "SHEYSSA VELEZ LOPEZ"
    ],
    [
        "SIBA BEWACHUNGSDIENST",
        "SIBA BEWACHUNGSDIENST"
    ],
    [
        "SIEMENS BUILDING TECHNOLOGIES",
        "SIEMENS BUILDING TECHNOLOGIES"
    ],
    [
        "SIMONIK MOVING & STORAGE INC",
        "SIMONIK MOVING & STORAGE"
    ],
    [
        "SINTEL BUSINESS SOLUTIONS",
        "SINTEL BUSINESS SOLUTIONS"
    ],
    [
        "SITE HAWK *",
        "SITE HAWK"
    ],
    [
        "SNDI",
        "SNDI SUISSE SA"
    ],
    [
        "SOCORRO PENA BERMUDEZ",
        "SOCORRO PENA BERMUDEZ"
    ],
    [
        "SOGECA",
        "SOGECA"
    ],
    [
        "SOPRA GROUP",
        "SOPRA GROUP"
    ],
    [
        "SOPRA GROUP MERIGNAC",
        "SOPRA GROUP MERIGNAC"
    ],
    [
        "SPDS LTD",
        "SPDS"
    ],
    [
        "SPECIAL EVENTS SERVICES LTD.",
        "SPECIAL EVENTS SERVICES"
    ],
    [
        "STAN RONAN",
        "STAN RONAN"
    ],
    [
        "STRATASYS GMBH",
        "STRATASYS"
    ],
    [
        "STRATIGO PERSONALBERATUNG",
        "STRATIGO PERSONALBERATUNG"
    ],
    [
        "STROMINSLAND",
        "STROMINSLAND"
    ],
    [
        "STRYKER CORPORATION CORP",
        "STRYKER"
    ],
    [
        "STRYKER TRAUMA GMBH",
        "STRYKER"
    ],
    [
        "SUCCESSORIES LLC",
        "SUCCESSORIES"
    ],
    [
        "SV BUSINESS CATERING GMBH",
        "SV BUSINESS CATERING"
    ],
    [
        "SVEN REESE",
        "SVEN REESE"
    ],
    [
        "SYNDEX",
        "SYNDEX"
    ],
    [
        "SYNERGY HEALTH MARSEILLE",
        "SYNERGY HEALTH"
    ],
    [
        "SYSTEM LINK IRELAND",
        "SYSTEM LINK"
    ],
    [
        "TALENT AND CAREER ASS LTD",
        "TALENT AND CAREER ASSESSMENT"
    ],
    [
        "TM TECH",
        "TECH"
    ],
    [
        "TECHMASTER DE MEXICO SA DE CV",
        "TECHMASTER"
    ],
    [
        "CN TECHNICAL SERVICES LTD",
        "TECHNICAL SERVICES"
    ],
    [
        "TECHNISCHE AKADEMIE",
        "TECHNISCHE AKADEMIE"
    ],
    [
        "TED DUNNE",
        "TED DUNNE"
    ],
    [
        "TEQ TRAINING & CONSULTING GMBH",
        "TEQ TRAINING & CONSULTING"
    ],
    [
        "TERMINIX INTERNATIONAL -",
        "TERMINIX"
    ],
    [
        "THE ALLIED GROUP",
        "THE ALLIED GROUP"
    ],
    [
        "THE NEW MOTION B.V.",
        "THE NEW MOTION B V"
    ],
    [
        "THINK EVOLVE SOLVE LTD",
        "THINK EVOLVE SOLVE LTD"
    ],
    [
        "THOMAS FLEMING COMPANY, INC.",
        "THOMAS FLEMING COMPANY"
    ],
    [
        "TOOLING U-SME",
        "TOOLING U-SME"
    ],
    [
        "TOTAL FRANCE",
        "TOTAL FRANCE"
    ],
    [
        "TOWER PINKSTER TITUS",
        "TOWER PINKSTER TITUS"
    ],
    [
        "TOWER WATSON GMBH",
        "TOWER WATSON"
    ],
    [
        "TOWERS WATSON DEUTSCHLAND GM",
        "TOWERS WATSON"
    ],
    [
        "TRATADOS INTEGRALES SA DE CV",
        "TRATADOS INTEGRALES"
    ],
    [
        "TRAVEL AGENCY SERVICES",
        "TRAVEL AGENCY SERVICES"
    ],
    [
        "TROPHY KING OF RAMSEY",
        "TROPHY KING OF RAMSEY"
    ],
    [
        "TRYCER",
        "TRYCER"
    ],
    [
        "T‹V NORD UMWELTSCHUTZ",
        "T‹V NORD UMWELTSCHUTZ"
    ],
    [
        "T‹V S‹D PRODUCT SERVICE GMBH",
        "T‹V S‹D PRODUCT SERVICE"
    ],
    [
        "T▄V NORD SYSTEMS GMBH&CO.KG",
        "T▄V NORD SYSTEMS"
    ],
    [
        "T▄V NORD UMWELTSCHUTZ",
        "T▄V NORD UMWELTSCHUTZ"
    ],
    [
        "T▄V S▄D PRODUCT SERVICE GMBH",
        "T▄V S▄D PRODUCT SERVICE"
    ],
    [
        "UCL UMWELT CONTROL LABOR",
        "UCL UMWELT CONTROL LABOR"
    ],
    [
        "UL VERIFICATION SERVICES INC. *",
        "UL VERIFICATION SERVICES"
    ],
    [
        "UMA BERUFSBEKLEIDUNG",
        "UMA BERUFSBEKLEIDUNG"
    ],
    [
        "UNDERWRITERS LABORATORIES, INC",
        "UNDERWRITERS LABORATORIES"
    ],
    [
        "UNION PLASTIC",
        "UNION PLASTIC"
    ],
    [
        "UNIVERSAL PROTECTION SERV",
        "UNIVERSAL PROTECTION SERV"
    ],
    [
        "UWE SCHMITT",
        "UWE SCHMITT"
    ],
    [
        "VAKUUM-HAERTEREI PETTER GMBH",
        "VAKUUM HAERTEREI PETTER"
    ],
    [
        "VAKUUM-HÄRTETECHNIK SCHWER GMBH",
        "VAKUUM HÄRTETECHNIK SCHWER"
    ],
    [
        "VATBOX LTD.",
        "VATBOX"
    ],
    [
        "VERMONT PRECISION TOOLS",
        "VERMONT PRECISION TOOLS"
    ],
    [
        "VGU MBH (NORDBRIEF)",
        "VGU MBH (NORDBRIEF)"
    ],
    [
        "VIANT PUERTO RICO INC",
        "VIANT"
    ],
    [
        "VISKA SYSTEMS",
        "VISKA SYSTEMS"
    ],
    [
        "VISTAMED LIMITED",
        "VISTAMED"
    ],
    [
        "VITA INSTITUT PROBSTEI",
        "VITA INSTITUT PROBSTEI"
    ],
    [
        "VIVA CARPETS CORP",
        "VIVA CARPETS"
    ],
    [
        "VIVES EQUIPMENT RENTAL",
        "VIVES EQUIPMENT RENTAL"
    ],
    [
        "W. KLUGE ENGINEERING GMBH",
        "W KLUGE ENGINEERING"
    ],
    [
        "WAGNER REGALBAU",
        "WAGNER REGALBAU"
    ],
    [
        "WALTRAUT LANGER",
        "WALTRAUT LANGER"
    ],
    [
        "WATERFORD INST OF TECH",
        "WATERFORD INST OF TECH"
    ],
    [
        "WD WET-DRY GMBH",
        "WD WET DRY"
    ],
    [
        "WEIDLING GMBH",
        "WEIDLING"
    ],
    [
        "WEKA GMBH",
        "WEKA"
    ],
    [
        "WEKA MEDIA GMBH & CO. KG",
        "WEKA MEDIA"
    ],
    [
        "WERNER BARGHOLZ",
        "WERNER BARGHOLZ"
    ],
    [
        "WERNER KOBSCH",
        "WERNER KOBSCH"
    ],
    [
        "WESTPAK INCORPORATED",
        "WESTPAK INCORPORATED"
    ],
    [
        "WISSENSCHAFTSZENTRUM KIEL",
        "WISSENSCHAFTSZENTRUM KIEL"
    ],
    [
        "WOLFSPERGER TEXTILPFLEGE GMBH",
        "WOLFSPERGER TEXTILPFLEGE"
    ],
    [
        "WOLTERS KLUWER",
        "WOLTERS KLUWER"
    ],
    [
        "X-SPECT LTD",
        "X SPECT"
    ],
    [
        "XTERRA GROUP",
        "XTERRA GROUP"
    ],
    [
        "ZEDAL AG",
        "ZEDAL"
    ],
    [
        "CROWN EQUIPMENT CORP",
        "CROWN EQUIPMENT"
    ],
    [
        "CROWN EQUIPMENT CORPORATION",
        "CROWN EQUIPMENT"
    ],
    [
        "NJ MALIN",
        "NJ MALIN"
    ],
    [
        "QUALITY LIFT TRUCKS DE MEXICO SA DE CV",
        "QUALITY LIFT TRUCKS"
    ],
    [
        "QUALITY LIFT TRUCKS",
        "QUALITY LIFT TRUCKS"
    ],
    [
        "RAYMOND HANDLING CONCEPTS",
        "RAYMOND HANDLING CONCEPTS"
    ],
    [
        "CROWN LIFT TRUCKS, INC.",
        "CROWN LIFT TRUCKS"
    ],
    [
        "ETTER ENGINEERING CO INC",
        "ETTER ENGINEERING"
    ],
    [
        "FAHY ANALYTICAL LTD",
        "FAHY ANALYTICAL"
    ],
    [
        "MICRONCLEAN (IRELAND) LTD",
        "MICRONCLEAN"
    ],
    [
        "MICRONCLEAN IRELAND",
        "MICRONCLEAN"
    ],
    [
        "PICKLING SYSTEMS",
        "PICKLING SYSTEMS"
    ],
    [
        "POLAR ICETECH IRELAND LTD",
        "POLAR ICETECH"
    ],
    [
        "CAMPBELL MACHINERY",
        "CAMPBELL MACHINERY"
    ],
    [
        "CAMPBELL MACHINERY LTD,",
        "CAMPBELL MACHINERY"
    ],
    [
        "MHG STRAHLANLAGEN GMBH",
        "MHG STRAHLANLAGEN"
    ],
    [
        "RÖSLER OBERFLÄCHENTECHNIK GMBH",
        "RÖSLER OBERFLÄCHENTECHNIK"
    ],
    [
        "SGT STRAHLMASCHINEN AG",
        "SGT STRAHLMASCHINEN"
    ],
    [
        "MILLER WELDING SUPPLY SOUTH",
        "MILLER WELDING SUPPLY SOUTH"
    ],
    [
        "EMUGE WERK R.GLIMPEL",
        "EMUGE WERK R GLIMPEL"
    ],
    [
        "ENV SERVICES",
        "ENV SERVICES"
    ],
    [
        "ENV SERVICES, INC. 1145",
        "ENV SERVICES"
    ],
    [
        "ENV SERVICES INC.,",
        "ENV SERVICES"
    ],
    [
        "SFS UNIMARKET AG",
        "SFS UNIMARKET"
    ],
    [
        "BOTEK PRΣZ.BOHRTECHNIK GMBH",
        "BOTEK"
    ],
    [
        "BOTEK PRÄZISIONS-",
        "BOTEK"
    ],
    [
        "BOTEK GMBH",
        "BOTEK"
    ],
    [
        "BRÜTSCH/RÜEGGER AG",
        "BRUTSCH RUEGGER"
    ],
    [
        "DIHAWAG",
        "DIHAWAG"
    ],
    [
        "DIXI OUTILS SA",
        "DIXI OUTILS"
    ],
    [
        "DULCO GMBH",
        "DULCO"
    ],
    [
        "GUNTER EFFGEN GMBH",
        "EFFGEN"
    ],
    [
        "ERICH KLINGSEISEN KG",
        "ERICH KLINGSEISEN"
    ],
    [
        "EROWA SYSTEM TECHNOLOGIEN GMBH",
        "EROWA"
    ],
    [
        "FRAISA SA",
        "FRAISA"
    ],
    [
        "FRANZ DOLLENMAYER",
        "FRANZ DOLLENMAYER"
    ],
    [
        "FRIEDRICH BRITSCH GMBH&CO.KG",
        "FRIEDRICH BRITSCH"
    ],
    [
        "FRIEDRICH BRITSCH GMBH & CO.",
        "FRIEDRICH BRITSCH"
    ],
    [
        "GLOOR PUMPENBAU AG",
        "GLOOR PUMPENBAU"
    ],
    [
        "GÜHRING KG",
        "GÜHRING"
    ],
    [
        "HANS-WALTER MOHR GMBH",
        "HANS WALTER MOHR"
    ],
    [
        "HENGST-KESSLER GMBH",
        "HENGST KESSLER"
    ],
    [
        "INGERSOLL WERKZEUGE GMBH",
        "INGERSOLL WERKZEUGE"
    ],
    [
        "LISTER MACHINE TOOLS LTD",
        "LISTER MACHINE TOOLS"
    ],
    [
        "LISTER MACHINE TOOLS LTD,",
        "LISTER MACHINE TOOLS"
    ],
    [
        "MALISANI SÀRL",
        "MALISANI SÀRL"
    ],
    [
        "MECANIQUE DE PRECISION",
        "MECANIQUE PRECISION"
    ],
    [
        "MILLTECH TOOLMAKING SERVICES LTD",
        "MILLTECH TOOLMAKING SERVICES"
    ],
    [
        "MOZAIR SA",
        "MOZAIR"
    ],
    [
        "OTTO DIETERLE",
        "OTTO DIETERLE"
    ],
    [
        "PAUL HORN GMBH",
        "PAUL HORN"
    ],
    [
        "PENN UNITED TECHNOLOGIES INC",
        "PENN UNITED TECHNOLOGIES"
    ],
    [
        "PREALPINA SUISSE GMBH",
        "PREALPINA SUISSE"
    ],
    [
        "PRINZBACH GMBH&CO.KG",
        "PRINZBACH"
    ],
    [
        "PXTOOLS SA",
        "PXTOOLS"
    ],
    [
        "REVIERLABOR",
        "REVIERLABOR"
    ],
    [
        "SCHRENK GMBH",
        "SCHRENK"
    ],
    [
        "HEGEDÜS VIKTOR GMBH",
        "VIKTOR HEGEDÜS"
    ],
    [
        "WALTER DEUTSCHLAND GMBH",
        "WALTER"
    ],
    [
        "WEBER ASSEMBLAGES",
        "WEBER ASSEMBLAGES"
    ],
    [
        "WERKZEUGSCHLEIFEREI KRAUSS",
        "WERKZEUGSCHLEIFEREI KRAUSS"
    ],
    [
        "WIBEMO SA",
        "WIBEMO"
    ],
    [
        "ZECHA HARTMETALL-WERKZ.FABRIK. GMBH",
        "ZECHA HARTMETALL WERKZ FABRIK"
    ],
    [
        "BURCHETT QUALITY TOOL",
        "BURCHETT QUALITY TOOL"
    ],
    [
        "BURCHETT QUALITY TOOL LTD",
        "BURCHETT QUALITY TOOL"
    ],
    [
        "ALLTEC GMBH",
        "ALLTEC"
    ],
    [
        "BRENCO MACHINE & TOOL, *",
        "BRENCO MACHINE & TOOL"
    ],
    [
        "CGMEC",
        "CGMEC"
    ],
    [
        "DAWNLOUGH LTD",
        "DAWNLOUGH"
    ],
    [
        "EROWA TECHNOLOGY INC",
        "EROWA"
    ],
    [
        "EROWA AG",
        "EROWA"
    ],
    [
        "FORBO SIEGLING LLC. *",
        "FORBO SIEGLING"
    ],
    [
        "GENERAL ASSEMBLY & MFG CORP.  *",
        "GENERAL ASSEMBLY & MFG"
    ],
    [
        "H.GEHRING&SOEHNE",
        "H GEHRING&SOEHNE"
    ],
    [
        "IMAGE PRECISION MACHINE",
        "IMAGE PRECISION MACHINE"
    ],
    [
        "INDEPENDENT ENGIN.SER.LTD",
        "INDEPENDENT ENGIN SER"
    ],
    [
        "INNOVATE TECHNOLOGIES, INC.",
        "INNOVATE TECHNOLOGIES"
    ],
    [
        "LLAMBRICH PRECISION S.L.",
        "LLAMBRICH PRECISION S.L."
    ],
    [
        "MAKINO INC DBA SINGLE SOURCE TECHNOLOGIES",
        "MAKINO"
    ],
    [
        "MAKINO INC",
        "MAKINO"
    ],
    [
        "MAKINO",
        "MAKINO"
    ],
    [
        "P & T PRECISION ENG.LTD",
        "P & T PRECISION ENG"
    ],
    [
        "PAOLUZZO AG",
        "PAOLUZZO"
    ],
    [
        "ROFIN-BAASEL UK LTD",
        "ROFIN BAASEL"
    ],
    [
        "ROFIN-BAASEL INC",
        "ROFIN BAASEL"
    ],
    [
        "ROFINBAASEL INC",
        "ROFIN BAASEL"
    ],
    [
        "SCHAEFER INDUSTRIES INC",
        "SCHAEFER INDUSTRIES INC"
    ],
    [
        "SLIEVE FOY TOOL AND DIE LTD",
        "SLIEVE FOY TOOL AND DIE"
    ],
    [
        "SMS PACKAGING INC.",
        "SMS PACKAGING"
    ],
    [
        "SOLID SOLUTIONS",
        "SOLID SOLUTIONS"
    ],
    [
        "URANIA ENGINEERING CO INC",
        "URANIA ENGINEERING"
    ],
    [
        "URANIA ENGINEERING CO., INC.",
        "URANIA ENGINEERING"
    ],
    [
        "WEISS WERKZEUGTECHNIK",
        "WEISS WERKZEUGTECHNIK"
    ],
    [
        "3D TECHNOLOGY LTD",
        "3D TECHNOLOGY"
    ],
    [
        "ALTRAN SUD OUEST SERVIE",
        "ALTRAN SUD OUEST SERVIE"
    ],
    [
        "ANDREAS & ASSOCIATES",
        "ANDREAS & ASSOCIATES"
    ],
    [
        "BIRLASOFT INC",
        "BIRLASOFT"
    ],
    [
        "BRIDGENET SOLUTIONS INC",
        "BRIDGENET SOLUTIONS"
    ],
    [
        "BSI",
        "BSI GROUP"
    ],
    [
        "BSI GROUP AMERICA",
        "BSI GROUP"
    ],
    [
        "CAUSACONCILIO",
        "CAUSACONCILIO"
    ],
    [
        "CORE INTERNATIONAL",
        "CORE INTERNATIONAL"
    ],
    [
        "DEUTSCHE GESLLSCHAFT FÜR ERNÄHRUNG",
        "DEUTSCHE GESLLSCHAFT FÜR ERNÄHRUNG"
    ],
    [
        "EAZYSAFE LIMITED",
        "EAZYSAFE"
    ],
    [
        "ENVIRONET CONSULTING IRE",
        "ENVIRONET CONSULTING IRE"
    ],
    [
        "ENVIRONMENTAL PROTECTION",
        "ENVIRONMENTAL PROTECTION"
    ],
    [
        "EXOVA (IRELAND) LTD",
        "EXOVA"
    ],
    [
        "FEHRENBACH ENGINEERING GBR",
        "FEHRENBACH ENGINEERING"
    ],
    [
        "FIBEROPTICS TECHNOLOGY INC.",
        "FIBEROPTICS TECHNOLOGY"
    ],
    [
        "FRAUNHOFER-INSTITUT FÜR",
        "FRAUNHOFER INSTITUT FÜR"
    ],
    [
        "GALLUP GMBH",
        "GALLUP"
    ],
    [
        "THE GALLUP ORGANISATION EUR",
        "GALLUP"
    ],
    [
        "GALLUP",
        "GALLUP"
    ],
    [
        "GALLUP INC",
        "GALLUP"
    ],
    [
        "GALLUP ORGANISATION UK",
        "GALLUP"
    ],
    [
        "THE GALLUP ORGANIZATION",
        "GALLUP"
    ],
    [
        "GENEVA HEALTHCARE",
        "GENEVA HEALTHCARE"
    ],
    [
        "GEOMETRIC RESULTS INC",
        "GEOMETRIC RESULTS"
    ],
    [
        "HEARTSAFETY SOLUTIONS",
        "HEARTSAFETY SOLUTIONS"
    ],
    [
        "HEINE + JUD",
        "HEINE JUD"
    ],
    [
        "HENN GMBH",
        "HENN"
    ],
    [
        "HENRICHSEN AG GESPERRT",
        "HENRICHSEN GESPERRT"
    ],
    [
        "IITR DATENSCHUTZT GMBH",
        "IITR DATENSCHUTZT"
    ],
    [
        "INSTITUT FÜRIT-RECHT IITR GMBH",
        "INSTITUT FÜRIT RECHT IITR"
    ],
    [
        "INSTITUTE OF TECHNOLOGY SLIGO",
        "INSTITUTE OF TECHNOLOGY SLIGO"
    ],
    [
        "JOHN LYNCH, T/A EMERGENCY RESPONSE TRAINING",
        "JOHN LYNCH T A EMERGENCY RESPONSE TRAINING"
    ],
    [
        "LAKELAND RUNNERS CLUB INC",
        "LAKELAND RUNNERS CLUB"
    ],
    [
        "MANUFACTURING EXCELLENCE",
        "MANUFACTURING EXCELLENCE"
    ],
    [
        "MICHAEL PAGE",
        "MICHAEL PAGE"
    ],
    [
        "NITTO AVECIA PHARMA SERVICES, INC.",
        "NITTO AVECIA PHARMA SERVICES"
    ],
    [
        "NOVACOS RECHTSANWAELTE",
        "NOVACOS RECHTSANWAELTE"
    ],
    [
        "OBERBERG, HASCHE, DUDDA",
        "OBERBERG HASCHE DUDDA"
    ],
    [
        "OFFICEMANAGER.CH",
        "OFFICEMANAGER"
    ],
    [
        "ONE LOOK SYSTEMS LTD",
        "ONE LOOK SYSTEMS"
    ],
    [
        "OPERATIONS LEADERSHIP, LLC",
        "OPERATIONS LEADERSHIP"
    ],
    [
        "PAUL HILLER CONSULTING",
        "PAUL HILLER CONSULTING"
    ],
    [
        "PAUL JOHNSON & ASSOC",
        "PAUL JOHNSON & ASSOCIATES"
    ],
    [
        "PHP ENVIRONMENTAL CONSULTING INC",
        "PHP ENVIRONMENTAL CONSULTING"
    ],
    [
        "PIPELINE DESIGN & ENGINEERING, LLC",
        "PIPELINE DESIGN & ENGINEERING"
    ],
    [
        "PRICEWATERHOUSECOOPERS",
        "PRICEWATERHOUSECOOPERS"
    ],
    [
        "PRICEWATERHOUSECOOPERS LLP",
        "PRICEWATERHOUSECOOPERS"
    ],
    [
        "PWC",
        "PRICEWATERHOUSECOOPERS"
    ],
    [
        "PROJECT MANAGEMENT LTD.,",
        "PROJECT MANAGEMENT"
    ],
    [
        "PSP RECHTSANWÄLTE",
        "PSP RECHTSANWÄLTE"
    ],
    [
        "R.T.COMMUNICATIONS LTD.",
        "R T COMMUNICATIONS"
    ],
    [
        "REGULATORY AND QUALITY SOLUTIONS",
        "REGULATORY AND QUALITY SOLUTIONS"
    ],
    [
        "REINER KRAHEBERGER",
        "REINER KRAHEBERGER"
    ],
    [
        "RESOURCES GLOBAL PROFESSIONALS",
        "RESOURCES GLOBAL PROFESSIONALS"
    ],
    [
        "RGIS HOLDINGS LLC",
        "RGIS HOLDINGS"
    ],
    [
        "STATOLITH AG",
        "STATOLITH"
    ],
    [
        "STRATOSPHERE",
        "STRATOSPHERE"
    ],
    [
        "TALENT AND CAREER ASSESS",
        "TALENT AND CAREER ASSESSMENT"
    ],
    [
        "WESTERN MICHIGAN UNIVERSITY",
        "WESTERN MICHIGAN UNIVERSITY"
    ],
    [
        "X FDA INC. *",
        "X FDA"
    ],
    [
        "ZOHO CORPORATION",
        "ZOHO CORPORATION"
    ],
    [
        "ALTEN",
        "ALTEN"
    ],
    [
        "ALTRAN",
        "ALTRAN"
    ],
    [
        "ARUP CONSULTING ENGINEERS",
        "ARUP"
    ],
    [
        "ARUP",
        "ARUP"
    ],
    [
        "ASPIRA CON LTD",
        "ASPIRACON"
    ],
    [
        "ASPIRACON",
        "ASPIRACON"
    ],
    [
        "FELSOMAT GMBH AND CO.KG",
        "FELSOMAT AND"
    ],
    [
        "INITIAL",
        "INITIAL"
    ],
    [
        "INITIAL SAINT SULPICE",
        "INITIAL SAINT SULPICE"
    ],
    [
        "LENTHOR ENGINEERING",
        "LENTHOR ENGINEERING"
    ],
    [
        "LENTHOR ENGINEREL000",
        "LENTHOR ENGINEERING"
    ],
    [
        "MATERIALISE GMBH",
        "MATERIALISE"
    ],
    [
        "MUNGRET ENGINEERING LTD.",
        "MUNGRET ENGINEERING"
    ],
    [
        "RENISHAW PLC",
        "RENISHAW"
    ],
    [
        "RENISHAW INC",
        "RENISHAW"
    ],
    [
        "RENISHAW GMBH",
        "RENISHAW"
    ],
    [
        "RENISHAW SAS",
        "RENISHAW"
    ],
    [
        "RENISHAW",
        "RENISHAW"
    ],
    [
        "RENISHAW AG",
        "RENISHAW"
    ],
    [
        "TEKNA SOLUTIONS INC",
        "TEKNA SOLUTIONS"
    ],
    [
        "A-MIX GMBH",
        "A MIX"
    ],
    [
        "IMAGINEERING FINISHING",
        "IMAGINEERING FINISHING TECHNOLOGIES"
    ],
    [
        "INDEXPRO",
        "INDEXPRO"
    ],
    [
        "NEOMETRIX TECHNOLOGIES, INC.",
        "NEOMETRIX TECHNOLOGIES"
    ],
    [
        "WTF GALVANOTECHNIK GMBH",
        "WTF GALVANOTECHNIK"
    ],
    [
        "3D SYSTEMS INC",
        "3D SYSTEMS"
    ],
    [
        "SAWBONES",
        "SAWBONES"
    ],
    [
        "BERTINI MARKUS",
        "BERTINI MARKUS"
    ],
    [
        "BPI FRANCE (OSEO)",
        "BPI"
    ],
    [
        "BPI",
        "BPI"
    ],
    [
        "CASS COMMERCIAL BANK",
        "CASS COMMERCIAL BANK"
    ],
    [
        "ATLANTIC A PROGRAM OF DE LAGE LANDEN FINANCIAL SRVS",
        "DE LAGE LANDEN"
    ],
    [
        "IKB LEASING GMBH",
        "IKB LEASING"
    ],
    [
        "SIEMENS FINANCE & LEASING",
        "SIEMENS"
    ],
    [
        "DELOITTE",
        "DELOITTE"
    ],
    [
        "DELOITTE & TOUCHE GMBH",
        "DELOITTE"
    ],
    [
        "DELOITTE AND TOUCHE",
        "DELOITTE"
    ],
    [
        "DELOITTE AG",
        "DELOITTE"
    ],
    [
        "STEUERVERWALTUNG DES KANTONS",
        "STEUERVERWALTUNG DES KANTONS"
    ],
    [
        "STEUERVERWALTUNG DES",
        "STEUERVERWALTUNG DES KANTONS"
    ],
    [
        "CORPORATE HEALTH IRELAND",
        "CORPORATE HEALTH IRELAND"
    ],
    [
        "EHA EMPLOYMENT HEALTH ADV",
        "EHA EMPLOYMENT HEALTH ADV"
    ],
    [
        "US HEALTHWORKS MEDICAL GROUP OF ARIZONA PC",
        "HEALTHWORKS MEDICAL GROUP"
    ],
    [
        "US HEALTHWORKS MEDICAL GROUP OF FLORIDA",
        "US HEALTHWORKS MEDICAL GROUP OF FLORIDA"
    ],
    [
        "ADP FRANCE",
        "ADP"
    ],
    [
        "CERTIPHI SCREENING INC",
        "CERTIPHI"
    ],
    [
        "CERTWOOD LTD",
        "CERTIPHI"
    ],
    [
        "CERTIPHI INC",
        "CERTIPHI"
    ],
    [
        "CERTIPHI SCREENING, INC.",
        "CERTIPHI"
    ],
    [
        "ABIR KHANDAKER",
        "ABIR KHANDAKER"
    ],
    [
        "ADITI KHATTAR",
        "ADITI KHATTAR"
    ],
    [
        "AEROTEK, INC.",
        "AEROTEK"
    ],
    [
        "AEROTEK, INC.*",
        "AEROTEK"
    ],
    [
        "ALFRED AMACHER AG RÜMLANG",
        "ALFRED AMACHER RÜMLANG"
    ],
    [
        "AMT F.WIRTSCHAFT U.ARBEIT",
        "AMT F WIRTSCHAFT U ARBEIT"
    ],
    [
        "APG SGA",
        "APG SGA"
    ],
    [
        "ARISTO RECRUITMENT GMBH",
        "ARISTO RECRUITMENT"
    ],
    [
        "AVETH ETH",
        "AVETH"
    ],
    [
        "AZ ZEITUNGEN AG",
        "AZ ZEITUNGEN"
    ],
    [
        "BRIGHTWATER SELECTION LTD",
        "BRIGHTWATER SELECTION"
    ],
    [
        "C&C MANAGEMENT AG",
        "C&C MANAGEMENT"
    ],
    [
        "CAMERON BROOKS INC",
        "CAMERON BROOKS"
    ],
    [
        "CAREERWISE RECRUIT",
        "CAREERWISE RECRUIT"
    ],
    [
        "CAREERWISE RECRUITMENT",
        "CAREERWISE RECRUITMENT"
    ],
    [
        "CENTENNIAL",
        "CENTENNIAL"
    ],
    [
        "COLLINS MCNICHOLAS,",
        "COLLINS MCNICHOLAS"
    ],
    [
        "COURTNEY LEARY",
        "COURTNEY LEARY"
    ],
    [
        "DOREEN DANSO",
        "DOREEN DANSO"
    ],
    [
        "ERIC HUDSON",
        "ERIC HUDSON"
    ],
    [
        "EXHIBITOR EVENTS ALL MEDIA",
        "EXHIBITOR EVENTS ALL MEDIA"
    ],
    [
        "FACHHOCHSCHULE NORDWESTSCHWE",
        "FACHHOCHSCHULE NORDWESTSCHWE"
    ],
    [
        "FARZANA CHOWDHURY",
        "FARZANA CHOWDHURY"
    ],
    [
        "FASTNET RECRUITMENT",
        "FASTNET RECRUITMENT"
    ],
    [
        "FEIDNER CHRISTIAN",
        "FEIDNER CHRISTIAN"
    ],
    [
        "FELLEITER WOLFGANG",
        "FELLEITER WOLFGANG"
    ],
    [
        "FRS RECRUITMENT SOCIETY",
        "FRS RECRUITMENT SOCIETY"
    ],
    [
        "GATEWAY SOLUTIONS GMBH",
        "GATEWAY SOLUTIONS"
    ],
    [
        "GEWA",
        "GEWA STIFTUNG"
    ],
    [
        "GMIT",
        "GMIT"
    ],
    [
        "GTI",
        "GTI"
    ],
    [
        "HADLEY HENRICK",
        "HADLEY HENRICK"
    ],
    [
        "HAYS (SCHWEIZ) AG",
        "HAYS"
    ],
    [
        "HAYS AG",
        "HAYS"
    ],
    [
        "HERO RECRUITMENT LTD",
        "HERO RECRUITMENT"
    ],
    [
        "INFINITY MANAGEMENT INC",
        "INFINITY MANAGEMENT"
    ],
    [
        "INTUITIVE TECHNOLOGY SOLUTIONS LLC",
        "INTUITIVE TECHNOLOGY SOLUTIONS"
    ],
    [
        "JASON BERESIK",
        "JASON BERESIK"
    ],
    [
        "JOBCLOUD SA",
        "JOBCLOUD"
    ],
    [
        "JOBCLOUD AG",
        "JOBCLOUD"
    ],
    [
        "JOKER PERSONAL AG",
        "JOKER PERSONAL"
    ],
    [
        "JOSH CUKIER",
        "JOSH CUKIER"
    ],
    [
        "KELLY SERVICES",
        "KELLY SERVICES"
    ],
    [
        "KELLY SERVICES, INC",
        "KELLY SERVICES"
    ],
    [
        "KEYUR PATEL",
        "KEYUR PATEL"
    ],
    [
        "KÖMEDIA  AG",
        "KOMEDIA"
    ],
    [
        "KYLE LYNES",
        "KYLE LYNES"
    ],
    [
        "KYLE NEWMAN",
        "KYLE NEWMAN"
    ],
    [
        "LEE HECHT HARRISON LLC",
        "LEE HECHT HARRISON"
    ],
    [
        "LIFE SCIENCE RECRUITMENT",
        "LIFE SCIENCE RECRUITMENT"
    ],
    [
        "LINDSAY QUARNO",
        "LINDSAY QUARNO"
    ],
    [
        "LINKEDIN CORPORATION",
        "LINKEDIN"
    ],
    [
        "LINKEDIN IRELAND",
        "LINKEDIN"
    ],
    [
        "LORENZO SERVEDIO",
        "LORENZO SERVEDIO"
    ],
    [
        "LUCAS ASSOCIATES INC",
        "LUCAS ASSOCIATES"
    ],
    [
        "MANUEL MARTINEZ",
        "MANUEL MARTINEZ"
    ],
    [
        "MAXIMILIAN THORINEG",
        "MAXIMILIAN THORINEG"
    ],
    [
        "MICHELLE WESTLAND",
        "MICHELLE WESTLAND"
    ],
    [
        "MIGRATIONSAMT",
        "MIGRATIONSAMT"
    ],
    [
        "MONSTER IRELAND",
        "MONSTER IRELAND"
    ],
    [
        "MPC EXECUTIVE SEARCH",
        "MPC EXECUTIVE SEARCH"
    ],
    [
        "MPC EXECUTIVE SEARCH SRV",
        "MPC EXECUTIVE SEARCH"
    ],
    [
        "MPC  EXECUTIVE SEARCH",
        "MPC EXECUTIVE SEARCH"
    ],
    [
        "MPC SERVICES",
        "MPC SERVICES"
    ],
    [
        "NAZARETH ZEITARBEIT GMBH",
        "NAZARETH ZEITARBEIT"
    ],
    [
        "NICHOLAS BURT",
        "NICHOLAS BURT"
    ],
    [
        "NICHOLAS FENTON",
        "NICHOLAS FENTON"
    ],
    [
        "NIHITHA KISHOR",
        "NIHITHA KISHOR"
    ],
    [
        "NIMA MOAZENI",
        "NIMA MOAZENI"
    ],
    [
        "PAUL WHITE",
        "PAUL WHITE"
    ],
    [
        "PETER LOSTRITTO",
        "PETER LOSTRITTO"
    ],
    [
        "PHAIDON INTERNATIONAL (CH)",
        "PHAIDON INTERNATIONAL"
    ],
    [
        "PROGRESSIVE GMBH",
        "PROGRESSIVE"
    ],
    [
        "PROVERB",
        "PROVERB"
    ],
    [
        "R.T. LANE RECRUITMENT LTD",
        "R T LANE RECRUITMENT"
    ],
    [
        "RANDSTAD (SCHWEIZ) AG",
        "RANDSTAD"
    ],
    [
        "REALSTAFFING GMBH",
        "REALSTAFFING"
    ],
    [
        "ROBERT BELL",
        "ROBERT BELL"
    ],
    [
        "RT LANE",
        "RT LANE"
    ],
    [
        "JORDAN RYLE",
        "RYLE"
    ],
    [
        "SABRINA LAW",
        "SABRINA LAW"
    ],
    [
        "KEN SEAH",
        "SEAH"
    ],
    [
        "STERLING STAFFING, INC.",
        "STERLING STAFFING"
    ],
    [
        "STRATOSPHERE QUALITY LLC",
        "STRATOSPHERE"
    ],
    [
        "SUDDATH",
        "SUDDATH"
    ],
    [
        "TANDEM PROJECT MANAGEMENT LTD",
        "TANDEM PROJECT MANAGEMENT"
    ],
    [
        "TANJILA SIDDIQI",
        "TANJILA SIDDIQI"
    ],
    [
        "TEMPO HOLDING COMPANY LLC",
        "TEMPO HOLDING"
    ],
    [
        "THOMAS BANNON",
        "THOMAS BANNON"
    ],
    [
        "TYLER SNELL",
        "TYLER SNELL"
    ],
    [
        "ULTIMATE STAFFING SERVICES",
        "ULTIMATE STAFFING SERVICES"
    ],
    [
        "UNIVERSITÄT BERN",
        "UNIVERSITÄT BERN"
    ],
    [
        "VATER PCS GMBH",
        "VATER PCS"
    ],
    [
        "VON ROHR & ASSOCIATES S.A.",
        "VON ROHR & ASSOCIATES S.A."
    ],
    [
        "WFB HASLACH",
        "WFB HASLACH"
    ],
    [
        "WILSON HCG",
        "WILSON HCG"
    ],
    [
        "WILSON HCG-EMEA LIMITED",
        "WILSON HCG-EMEA LIMITED"
    ],
    [
        "WYNDEN STARK LLC",
        "WYMED"
    ],
    [
        "YOUSTY AG",
        "YOUSTY"
    ],
    [
        "BROOKFIELD RELOCATION INC",
        "BROOKFIELD RELOCATION"
    ],
    [
        "INFINITY MANAGEMENT INC.",
        "INFINITY MANAGEMENT"
    ],
    [
        "NATIONAL EQUITY INC NEI",
        "NATIONAL EQUITY NEI"
    ],
    [
        "NELSON WESTERBERG INC",
        "NELSON WESTERBERG"
    ],
    [
        "NELSON WESTERBERG INCORPORATED",
        "NELSON WESTERBERG"
    ],
    [
        "NELSON WESTERBERG OF NEW JERSE",
        "NELSON WESTERBERG OF NEW JERSE"
    ],
    [
        "NEWSCO",
        "NEWSCO"
    ],
    [
        "THE SUDDATH COMPANIES",
        "THE SUDDATH COMPANIES"
    ],
    [
        "ACTION ONSITE INC",
        "ACTION ONSITE"
    ],
    [
        "ADPGSI",
        "ADP"
    ],
    [
        "AFPA AGENCE DE PESSAC",
        "AFPA AGENCE PESSAC"
    ],
    [
        "AFPA ENTREPRISES",
        "AFPA ENTREPRISES"
    ],
    [
        "AFPA ILE-DE-FRANCE",
        "AFPA ILE"
    ],
    [
        "AFPI",
        "AFPI"
    ],
    [
        "AGILEA FORMATION",
        "AGILEA FORMATION"
    ],
    [
        "ALEX MICHAEL DRAPER",
        "ALEX MICHAEL DRAPER"
    ],
    [
        "ALPHA QUALITY CONSULTING",
        "ALPHA QUALITY CONSULTING"
    ],
    [
        "AMT-IMTS",
        "AMT IMTS"
    ],
    [
        "APTITUDES 21",
        "APTITUDES 21"
    ],
    [
        "ARDIN CAREER DEVELOPMENT",
        "ARDIN CAREER DEVELOPMENT"
    ],
    [
        "ASME INTERNATIONAL",
        "ASME INTERNATIONAL"
    ],
    [
        "ASQ",
        "ASQ"
    ],
    [
        "ASQ ECOMMERCE",
        "ASQ ECOMMERCE"
    ],
    [
        "ASSOCIATION JUMP EGALITE",
        "ASSOCIATION JUMP EGALITE"
    ],
    [
        "AUTODESK LTD",
        "AUTODESK"
    ],
    [
        "BANDJWET ENTERPRISES, INC",
        "BANDJWET ENTERPRISES"
    ],
    [
        "BARNES & NOBLE.COM - BN",
        "BARNES & NOBLE.COM - BN"
    ],
    [
        "BERLITZ GMBH",
        "BERLITZ"
    ],
    [
        "BILDUNGSZENTRUM TANNENFELDE",
        "BILDUNGSZENTRUM TANNENFELDE"
    ],
    [
        "BLUE GRANITE INC",
        "BLUE GRANITE"
    ],
    [
        "BLUEGRASS ORTHOPAEDICS",
        "BLUEGRASS ORTHOPAEDICS"
    ],
    [
        "BUSINESS EXCELLENCE SKILL",
        "BUSINESS EXCELLENCE SKILL"
    ],
    [
        "CADUCEUM",
        "CADUCEUM"
    ],
    [
        "CAPORICCIO MICHEL",
        "CAPORICCIO MICHEL"
    ],
    [
        "CARL ZEIS LTD",
        "CARL ZEIS"
    ],
    [
        "CBI",
        "CBI"
    ],
    [
        "CCI ILE ET VILAINE",
        "CCI ILE ET VILAINE"
    ],
    [
        "CEGOS",
        "CEGOS"
    ],
    [
        "CHAMBRE NEUCHATELOISE DU",
        "CHAMBRE NEUCHATELOISE DU"
    ],
    [
        "CHECK PORT",
        "CHECK PORT"
    ],
    [
        "CHOC SECURITE",
        "CHOC SECURITE"
    ],
    [
        "CHRIS MEE SAFETY ENGINEER",
        "CHRIS MEE SAFETY ENGINEERING"
    ],
    [
        "CHRIS MEE SAFETY ENGINEERING",
        "CHRIS MEE SAFETY ENGINEERING"
    ],
    [
        "CLAYTON HOTEL SILVER SPRI",
        "CLAYTON HOTEL"
    ],
    [
        "CLICKSAFETY",
        "CLICKSAFETY"
    ],
    [
        "CNAM BRETAGNE",
        "CNAM BRETAGNE"
    ],
    [
        "CONCEPT HEIDELBERG GMBH",
        "CONCEPT HEIDELBERG"
    ],
    [
        "CONFEX TRAINING GMBH",
        "CONFEX TRAINING"
    ],
    [
        "CONFIDENTSPEAK",
        "CONFIDENTSPEAK"
    ],
    [
        "CORK INSTITUTE OF TECH",
        "CORK INSTITUTE OF TECH"
    ],
    [
        "CREATIVE DINING/STRYKER M",
        "CREATIVE DINING"
    ],
    [
        "CRYSTAL LAKE CHAMBER OF",
        "CRYSTAL LAKE CHAMBER OF"
    ],
    [
        "CTI ADVANCED",
        "CTI ADVANCED"
    ],
    [
        "CYBERGEAR",
        "CYBERGEAR"
    ],
    [
        "1001 DETENTES",
        "DETENTES"
    ],
    [
        "DEUTSCHE GESELLSCHAFT FUER",
        "DEUTSCHE GESELLSCHAFT FUER"
    ],
    [
        "DGB BILDUNGSWERK BUND",
        "DGB BILDUNGSWERK BUND"
    ],
    [
        "DRK LANDESVERBAND SCHLESWIG-",
        "DRK LANDESVERBAND SCHLESWIG"
    ],
    [
        "DT. GES. F. PERSONALFUEHRUNG",
        "DT GES F PERSONALFUEHRUNG"
    ],
    [
        "DTB DATENSCHUTZ+TECHNO-",
        "DTB DATENSCHUTZ TECHNO"
    ],
    [
        "DWA",
        "DWA"
    ],
    [
        "ECORNELL",
        "ECORNELL"
    ],
    [
        "EFFECTIVE TRAINING INC",
        "EFFECTIVE TRAINING"
    ],
    [
        "EHA LIMERICK",
        "EHA LIMERICK"
    ],
    [
        "ELECTROLAB TRAINING *",
        "ELECTROLAB TRAINING"
    ],
    [
        "EMA DESIGN AUTOMATION,INC",
        "EMA DESIGN AUTOMATION"
    ],
    [
        "ENGINEERS IRELAND",
        "ENGINEERS"
    ],
    [
        "ESAT BT",
        "ESAT BT"
    ],
    [
        "FAMILY FARE 1980",
        "FAMILY FARE 1980"
    ],
    [
        "FIDAL",
        "FIDAL"
    ],
    [
        "FIDAL FORMATION",
        "FIDAL FORMATION"
    ],
    [
        "FIRST POLYMER TRAINING SKILLNET",
        "FIRST POLYMER TRAINING SKILLNET"
    ],
    [
        "FIT -FUNKTONELLES INNO-",
        "FIT FUNKTONELLES INNO"
    ],
    [
        "FOCUSMEM SOLOTHURN",
        "FOCUSMEM SOLOTHURN"
    ],
    [
        "FOERDE CAMPUS GMBH",
        "FOERDE CAMPUS"
    ],
    [
        "FORGET FORMATION",
        "FORGET FORMATION"
    ],
    [
        "CM FORMATION",
        "FORMATION"
    ],
    [
        "FOTA ISLAND RESORT",
        "FOTA RESORT"
    ],
    [
        "FSRM",
        "FSRM"
    ],
    [
        "GEBRÜDER KNIE",
        "GEBRÜDER KNIE"
    ],
    [
        "GOGGIN RENTAL CORP",
        "GOGGIN RENTAL"
    ],
    [
        "GP STRATEGIES",
        "GP STRATEGIES"
    ],
    [
        "GRETA DE BORDEAUX",
        "GRETA BORDEAUX"
    ],
    [
        "GRETA EST BRETAGNE",
        "GRETA BRETAGNE"
    ],
    [
        "GRIFFITH COLLEGE",
        "GRIFFITH COLLEGE"
    ],
    [
        "GROUP SCHEMES",
        "GROUP SCHEMES"
    ],
    [
        "HANS JUERGEN JOEHNK",
        "HANS JUERGEN JOEHNK"
    ],
    [
        "HAUFE SERVICE CENTER GMBH",
        "HAUFE SERVICE CENTER"
    ],
    [
        "HAUS DER BERUFSBILDUNG AG",
        "HAUS DER BERUFSBILDUNG"
    ],
    [
        "HEG-ARC",
        "HEG ARC"
    ],
    [
        "HILTON WOODCLIFF LAKES RE",
        "HILTON"
    ],
    [
        "HOLIDAY INN BORDEAUX SUD",
        "HOLIDAY INN"
    ],
    [
        "HOLIDAY INN MART PLAZA",
        "HOLIDAY INN"
    ],
    [
        "IAE REUNION",
        "IAE REUNION"
    ],
    [
        "ICBE.IRISH CENT.BUS.EXCEL",
        "ICBE IRISH CENT BUS EXCEL"
    ],
    [
        "IESE BUSINESS SCHOOL",
        "IESE BUSINESS SCHOOL"
    ],
    [
        "IF2P",
        "IF2P"
    ],
    [
        "IFB KG INSTITUT FⁿR FORT-",
        "IFB INSTITUT FⁿR FORT"
    ],
    [
        "IFHE",
        "IFHE"
    ],
    [
        "IFT INSTIT. FUER TALENTENTW.",
        "IFT INSTIT FUER TALENTENTW"
    ],
    [
        "IG METALL BILDUNGSZENTRUM",
        "IG METALL BILDUNGSZENTRUM"
    ],
    [
        "IGKG-SO",
        "IGKG"
    ],
    [
        "IMDA SKILLNET",
        "IMDA SKILLNET"
    ],
    [
        "IMI",
        "IMI"
    ],
    [
        "IMTS REGISTRATION",
        "IMTS REGISTRATION"
    ],
    [
        "IN  RALPH NICHOLS GROUP,",
        "IN  RALPH NICHOLS GROUP,"
    ],
    [
        "INDUSTRIAL LASER SYSTEMS",
        "INDUSTRIAL LASER SYSTEMS"
    ],
    [
        "INNENMINISTERIUM DES",
        "INNENMINISTERIUM DES"
    ],
    [
        "INNOV ROBOTICS",
        "INNOV ROBOTICS"
    ],
    [
        "INTERLANGUES",
        "INTERLANGUES"
    ],
    [
        "IPC",
        "IPC"
    ],
    [
        "IPC INTL",
        "IPC INTL"
    ],
    [
        "IPICS EDUCATION LTD",
        "IPICS EDUCATION"
    ],
    [
        "IRISH MANAGEMENT INSTITUTE (IMI)",
        "IRISH MANAGEMENT INSTITUTE"
    ],
    [
        "ISI-TEC",
        "ISI TEC"
    ],
    [
        "JACKSON LEWIS LLC",
        "JACKSON LEWIS"
    ],
    [
        "JACS PIZZA",
        "JACS PIZZA"
    ],
    [
        "KALAMAZOO VALLEY COMM",
        "KALAMAZOO VALLEY COMM"
    ],
    [
        "KALAMAZOO VALLEY COMM COLLEGE",
        "KALAMAZOO VALLEY COMM"
    ],
    [
        "LASER TRADER LIMITED",
        "LASER TRADER"
    ],
    [
        "LEAD COACH LTD",
        "LEAD COACH"
    ],
    [
        "UK LEAN SYSTEMS PROGRAM",
        "LEAN SYSTEMS PROGRAM"
    ],
    [
        "LION TECHNOLOGY INC.",
        "LION TECHNOLOGY"
    ],
    [
        "M2I FORMATION",
        "M2I FORMATION"
    ],
    [
        "MAISON FAMILIALE PAYSAGE",
        "MAISON FAMILIALE PAYSAGE"
    ],
    [
        "MERCER (IRELAND) LTD",
        "MERCER"
    ],
    [
        "METAL COATING PROCESS LLC",
        "METAL COATING PROCESS"
    ],
    [
        "METAL POWDER IND FED",
        "METAL POWDER FED"
    ],
    [
        "MP PERSONAL TRAINING",
        "MP PERSONAL TRAINING"
    ],
    [
        "NORTHERN ILLINOIS UNIVERSITY",
        "NORTHERN ILLINOIS UNIVERSITY"
    ],
    [
        "NSF HEALTH SERVICES",
        "NSF HEALTH SERVICES"
    ],
    [
        "ONLC TRAINING CENTERS",
        "ONLC TRAINING CENTERS"
    ],
    [
        "OSISOFT",
        "OSISOFT"
    ],
    [
        "PATRICIA THOMPSON-MMTS",
        "PATRICIA THOMPSON MMTS"
    ],
    [
        "PILOCAP AQUITAINE",
        "PILOCAP AQUITAINE"
    ],
    [
        "PRESTON-HILL INC",
        "PRESTON HILL"
    ],
    [
        "PROJECT MANAGEMENT PARTNERS OF PR",
        "PROJECT MANAGEMENT PARTNERS OF PR"
    ],
    [
        "PROJECT MANAGMNT ACADEMY",
        "PROJECT MANAGMNT ACADEMY"
    ],
    [
        "PSYNAPSE",
        "PSYNAPSE"
    ],
    [
        "QAD EUROPE SASL",
        "QAD EUROPE SASL"
    ],
    [
        "RADISSON BLU HOTEL CORK",
        "RADISSON BLU HOTEL"
    ],
    [
        "RADISSON PLAZA HOTEL",
        "RADISSON PLAZA HOTEL"
    ],
    [
        "RENNES LANGUAGE CENTER",
        "RENNES LANGUAGE CENTER"
    ],
    [
        "RETRAVAILLER DANS L OUEST",
        "RETRAVAILLER DANS L OUEST"
    ],
    [
        "RIVER LEE HOTEL CORK",
        "RIVER LEE HOTEL CORK"
    ],
    [
        "RUCH, CHRISTOPH",
        "RUCH CHRISTOPH"
    ],
    [
        "SAQ-QUALICON AG",
        "SAQ QUALICON"
    ],
    [
        "SBTI",
        "SBTI"
    ],
    [
        "SCHENKER INC",
        "SCHENKER"
    ],
    [
        "SILVIA BLASER",
        "SILVIA BLASER"
    ],
    [
        "SKILLPATH SEMINARS",
        "SKILLPATH SEMINARS"
    ],
    [
        "SKYJACK",
        "SKYJACK"
    ],
    [
        "IT SLIGO",
        "SLIGO"
    ],
    [
        "SOLID PROFESSOR",
        "SOLID PROFESSOR"
    ],
    [
        "SOLOTHURNER",
        "SOLOTHURNER"
    ],
    [
        "SOUTHWEST MICHIGAN FIRST",
        "SOUTHWEST MICHIGAN FIRST"
    ],
    [
        "SOW DRIVE",
        "SOW DRIVE"
    ],
    [
        "SPRA SARL",
        "SPRA"
    ],
    [
        "SQT TRAINING",
        "SQT TRAINING"
    ],
    [
        "SQT TRAINING LIMITED",
        "SQT TRAINING"
    ],
    [
        "SQT TRAINING LTD",
        "SQT TRAINING"
    ],
    [
        "SSI SOLUTIONS INC. *",
        "SSI SOLUTIONS"
    ],
    [
        "SSIB SWISS SCHOOL FOR",
        "SSIB SWISS SCHOOL FOR"
    ],
    [
        "SVBL SCHWEIZ. VEREINIGUNG",
        "SVBL VEREINIGUNG"
    ],
    [
        "SWISS MEDTECH",
        "SWISS MEDTECH"
    ],
    [
        "SWISSCARD AECS GMBH",
        "SWISSCARD AECS"
    ],
    [
        "SWISSMECHANIC",
        "SWISSMECHANIC"
    ],
    [
        "SWISSMEM BERUFSBILDUNG",
        "SWISSMEM BERUFSBILDUNG"
    ],
    [
        "SYNERTEC CONSULTANTS",
        "SYNERTEC CONSULTANTS"
    ],
    [
        "TA BILDUNGSZENTRUM GMBH",
        "TA BILDUNGSZENTRUM"
    ],
    [
        "TE.I.AM TRAINING SOLUTION",
        "TE.I.AM TRAINING"
    ],
    [
        "TECHSTREET - INTERNET",
        "TECHSTREET INTERNET"
    ],
    [
        "TELEFLORA",
        "TELEFLORA"
    ],
    [
        "TEWENTYEIGHTY STRATEGY EXECUTI",
        "TEWENTYEIGHTY STRATEGY EXECUTI"
    ],
    [
        "THE BRASS RAIL",
        "THE BRASS RAIL"
    ],
    [
        "THE OPEN UNIVERSITY",
        "THE OPEN UNIVERSITY"
    ],
    [
        "TOASTMASTERS RENEW WEB",
        "TOASTMASTERS RENEW WEB"
    ],
    [
        "TOOLING UNIVERSITY LLC",
        "TOOLING UNIVERSITY"
    ],
    [
        "TOOLING UNIVERSITY",
        "TOOLING UNIVERSITY"
    ],
    [
        "TUEV SUED AKADEMIE GMBH",
        "TUEV SUED AKADEMIE"
    ],
    [
        "TWENTYEIGHTY STRATEGY",
        "TWENTYEIGHTY STRATEGY"
    ],
    [
        "T‹V NORD AKADEMIE GMBH+CO.KG",
        "T‹V NORD AKADEMIE"
    ],
    [
        "T▄V RHEINLAND AKADEMIE",
        "T▄V RHEINLAND AKADEMIE"
    ],
    [
        "UNIV COLLEGE DUBLIN",
        "UNIV COLLEGE DUBLIN"
    ],
    [
        "UNIV OF KENTUCKY",
        "UNIV OF KENTUCKY"
    ],
    [
        "UNIVERSITE RENNES 1",
        "UNIVERSITE RENNES 1"
    ],
    [
        "UNIVERSITE TOULOUSE III PAUL",
        "UNIVERSITE TOULOUSE III PAUL"
    ],
    [
        "UNIVERSITY COLLEGE CORK RESEARCH GRANTS & FINANCE",
        "UNIVERSITY COLLEGE CORK"
    ],
    [
        "UNIVERSITY COLLEGE CORK",
        "UNIVERSITY COLLEGE CORK"
    ],
    [
        "VEBO GENOSSENSCHAFT",
        "VEBO GENOSSENSCHAFT"
    ],
    [
        "VELOPI LTD",
        "VELOPI"
    ],
    [
        "VOREST AG",
        "VOREST"
    ],
    [
        "VSETH VERBAND DER",
        "VSETH VERBAND DER"
    ],
    [
        "WENDY SHEERAN",
        "WENDY SHEERAN"
    ],
    [
        "WIRTSCHAFTSAKADEMIE S.-H.",
        "WIRTSCHAFTSAKADEMIE S.-H."
    ],
    [
        "WMPMI",
        "WMPMI"
    ],
    [
        "WWW.SURVEYGIZMO.COM",
        "WWW.SURVEYGIZMO.COM"
    ],
    [
        "ZFU",
        "ZFU"
    ],
    [
        "BENISSAN JEAN PHILIPPE",
        "BENISSAN JEAN PHILIPPE"
    ],
    [
        "REICHARD & ESCALERA",
        "REICHARD & ESCALERA"
    ],
    [
        "JP ENTERPRISES",
        "JP ENTERPRISES"
    ],
    [
        "LUPTONS INC",
        "LUPTONS"
    ],
    [
        "ALESTRA COMUNICACION S DE RL DE CV",
        "ALESTRA COMUNICACION"
    ],
    [
        "ARKADIN GERMANY GMBH",
        "ARKADIN"
    ],
    [
        "ASF GMBH",
        "ASF"
    ],
    [
        "AT&T COMUNICACIONES DIGITALES S DE RL DE CV",
        "AT&T"
    ],
    [
        "AT& T MOBILITY",
        "AT&T"
    ],
    [
        "AT&T",
        "AT&T"
    ],
    [
        "AXESA SERVICIOS DE INFORM",
        "AXESA SERVICIOS INFORM"
    ],
    [
        "BARYCZA, PAWEL",
        "BARYCZA PAWEL"
    ],
    [
        "CLARO",
        "CLARO"
    ],
    [
        "COMCAST CORPORATION",
        "COMCAST"
    ],
    [
        "DATA ON CALL",
        "DATA ON CALL"
    ],
    [
        "DEUTSCHE TELEKOM AG",
        "DEUTSCHE TELEKOM"
    ],
    [
        "DIRECTABLE",
        "DIRECTABLE"
    ],
    [
        "EIRCOM,",
        "EIRCOM"
    ],
    [
        "FUTURE COMM AG",
        "FUTURE COMM"
    ],
    [
        "GA WEISSENSTEIN GMBH",
        "GA WEISSENSTEIN"
    ],
    [
        "GOGOAIR",
        "GOGOAIR"
    ],
    [
        "HAHN, DIRK",
        "HAHN DIRK"
    ],
    [
        "HEIKO DOLLING",
        "HEIKO DOLLING"
    ],
    [
        "LEAH LIVINGSTONE",
        "LEAH LIVINGSTONE"
    ],
    [
        "PREMIERE CONFERENCING",
        "PREMIERE CONFERENCING"
    ],
    [
        "PREMIERE GLOBAL SERVICES",
        "PREMIERE GLOBAL SERVICES"
    ],
    [
        "RR DONNELLEY MAGYARORSZAG",
        "RR DONNELLEY"
    ],
    [
        "RR DONNELLEY & SONS COMPANY",
        "RR DONNELLEY"
    ],
    [
        "RR DONNELLEY DEBRECEN",
        "RR DONNELLEY"
    ],
    [
        "RR DONNELLEY MAGYARORSZAG KFT",
        "RR DONNELLEY"
    ],
    [
        "SIMPLE SCIENCE",
        "SIMPLE SCIENCE"
    ],
    [
        "SIMPLE SCIENCE INC",
        "SIMPLE SCIENCE"
    ],
    [
        "SPRINT",
        "SPRINT"
    ],
    [
        "T-MOBILE CENTER",
        "T MOBILE CENTER"
    ],
    [
        "VIASAT VIRGIN AMERICA",
        "VIASAT VIRGIN"
    ],
    [
        "VON DⁿFFEL, LISA-CELINE",
        "VON DⁿFFEL, LISA-CELINE"
    ],
    [
        "WORLDNET TELECOMMUNICA",
        "WORLDNET TELECOMMUNICA"
    ],
    [
        "JUNG&HUNGRIG GMBH",
        "JUNG&HUNGRIG"
    ],
    [
        "RMGP",
        "RMGP"
    ],
    [
        "ZT MEDIEN AG",
        "ZT MEDIEN"
    ],
    [
        "K&M PRINTING CO. INC. *",
        "K&M PRINTING"
    ],
    [
        "LABEL GRAPHICS CORP.",
        "LABEL GRAPHICS"
    ],
    [
        "LIMERICK LASERS LTD",
        "LIMERICK LASERS"
    ],
    [
        "BLACKHAWK NETWORK INC",
        "BLACKHAWK NETWORK"
    ],
    [
        "PREMIERE PRINTING CORP",
        "PREMIERE PRINTING"
    ],
    [
        "PREMIERE PRINTREL000",
        "PREMIERE PRINTING"
    ],
    [
        "PROMOTION CONCEPTS",
        "PROMOTION CONCEPTS"
    ],
    [
        "PROMOTION CONCEPTS, INC.",
        "PROMOTION CONCEPTS"
    ],
    [
        "PROMOTION CONCEPTS I",
        "PROMOTION CONCEPTS"
    ],
    [
        "PROMOTION CONCEPTS INC.",
        "PROMOTION CONCEPTS"
    ],
    [
        "TARGET MARKETING SERVICE INC",
        "TARGET MARKETING SERVICE"
    ],
    [
        "WOODLAND PRINT&DESIGN",
        "WOODLAND PRINT & SIGNAGE"
    ],
    [
        "WOODLAND PRINT",
        "WOODLAND PRINT & SIGNAGE"
    ],
    [
        "WOODLAND PRINT & SIGNAGE",
        "WOODLAND PRINT & SIGNAGE"
    ],
    [
        "WOODLAND PRINT & DESIGN",
        "WOODLAND PRINT & SIGNAGE"
    ],
    [
        "GIFT VOUCHER SHOP",
        "GIFT VOUCHER SHOP"
    ],
    [
        "PROMOTION CONCEPTS INC",
        "PROMOTION CONCEPTS"
    ],
    [
        "THE GIFT VOUCHER SHOP",
        "THE GIFT VOUCHER SHOP"
    ],
    [
        "AIG EUROPE LIMITED",
        "AIG"
    ],
    [
        "ALLIANZ IARD",
        "ALLIANZ"
    ],
    [
        "ALLIANZ VERS. (SCHWEIZ) AG",
        "ALLIANZ VERS"
    ],
    [
        "AON CONSEIL ET COURTAGE SA",
        "AON"
    ],
    [
        "AON INSURANCE",
        "AON"
    ],
    [
        "AXA VERSICHERUNGEN AG",
        "AXA"
    ],
    [
        "AXA EPARGNE ENTREPRISE",
        "AXA"
    ],
    [
        "CSS VERSICHERUNG AG",
        "CSS VERSICHERUNG"
    ],
    [
        "ERGO",
        "ERGO"
    ],
    [
        "HELVETIA VERSICHERUNGEN",
        "HELVETIA VERSICHERUNGEN"
    ],
    [
        "SCAN - SERVICE CANTONAL DES",
        "SCAN SERVICE CANTONAL DES"
    ],
    [
        "SCHWEIZERISCHE MOBILIAR",
        "SCHWEIZERISCHE MOBILIAR"
    ],
    [
        "SOLOTHURNISCHE",
        "SOLOTHURNISCHE"
    ],
    [
        "TRANSPERFECT TRANSLATIONS",
        "TRANSPERFECT TRANSLATIONS"
    ],
    [
        "TRANSPERFECT TRANSLATIONS INTE",
        "TRANSPERFECT TRANSLATIONS"
    ],
    [
        "3 SIGMA CORPORATION *",
        "3 SIGMA"
    ],
    [
        "3A PRESS",
        "3A PRESS"
    ],
    [
        "3M",
        "3M"
    ],
    [
        "A L'ART NOUVEAU",
        "A L ART NOUVEAU"
    ],
    [
        "A&L GOODBODY SOLICITORS",
        "A&L GOODBODY SOLICITORS"
    ],
    [
        "AA IRELAND",
        "AA IRELAND"
    ],
    [
        "ABIDH-JOLLY MICKAEL",
        "ABIDH JOLLY MICKAEL"
    ],
    [
        "AC HOTEL",
        "AC HOTEL"
    ],
    [
        "AC HOTEL SAN JUAN CONDADO",
        "AC HOTEL SAN JUAN CONDADO"
    ],
    [
        "ACCUMED CORP",
        "ACCUMED"
    ],
    [
        "ACERDE",
        "ACERDE"
    ],
    [
        "ACGIH MEMBERSHIP",
        "ACGIH MEMBERSHIP"
    ],
    [
        "ACORN OAK PAPEL PRODUCTS CO.",
        "ACORN OAK PAPEL PRODUCTS"
    ],
    [
        "ADDOUCHE CYRIL",
        "ADDOUCHE CYRIL"
    ],
    [
        "AESCULAP",
        "AESCULAP"
    ],
    [
        "AGIO IMAGING *",
        "AGIO IMAGING"
    ],
    [
        "AICHOUCH RACHID",
        "AICHOUCH RACHID"
    ],
    [
        "AIDA GOOLD",
        "AIDA GOOLD"
    ],
    [
        "AIM PLASTICS INC",
        "AIM PLASTICS"
    ],
    [
        "AIRLINES PARKING",
        "AIRLINES PARKING"
    ],
    [
        "AIRPORT FOOD MART",
        "AIRPORT FOOD MART"
    ],
    [
        "AL PASQUAL",
        "AL PASQUAL"
    ],
    [
        "ALDEN TOOL COMPANY INC",
        "ALDEN TOOL"
    ],
    [
        "ALEXANDRIA INDUSTRIES",
        "ALEXANDRIA INDUSTRIES"
    ],
    [
        "ALLIANCE CNC",
        "ALLIANCE CNC"
    ],
    [
        "ALMA MEDIEN AG",
        "ALMA MEDIEN"
    ],
    [
        "ALMAX",
        "ALMAX"
    ],
    [
        "ALMETA RECYCLING AG",
        "ALMETA RECYCLING"
    ],
    [
        "ALP LIGHTING COMPONENTS",
        "ALP LIGHTING COMPONENTS"
    ],
    [
        "ALP'RECTIFICATION",
        "ALP RECTIFICATION"
    ],
    [
        "ALTAFID",
        "ALTAFID"
    ],
    [
        "AMBASSADOR BRIDGE",
        "AMBASSADOR BRIDGE"
    ],
    [
        "AMERICAN ASSOCIATION OF",
        "AMERICAN ASSOCIATION OF"
    ],
    [
        "AMERICAN SOCIETY OF SAFETY ENGINEERS",
        "AMERICAN SOCIETY OF SAFETY ENGINEERS"
    ],
    [
        "AMERICAN TRAFFIC SOLUTION",
        "AMERICAN TRAFFIC SOLUTION"
    ],
    [
        "AMS JÜRG BOSSHART",
        "AMS JÜRG BOSSHART"
    ],
    [
        "AMTSCHREIBEREIEN",
        "AMTSCHREIBEREIEN"
    ],
    [
        "AMTSGERICHT KIEL",
        "AMTSGERICHT KIEL"
    ],
    [
        "ANIXTER",
        "ANIXTER"
    ],
    [
        "ANKE RⁿNZEL",
        "ANKE RⁿNZEL"
    ],
    [
        "ANSYS, INC",
        "ANSYS"
    ],
    [
        "APAVE SUDEUROPE",
        "APAVE SUDEUROPE"
    ],
    [
        "APCOA-KALAMAZOO,MI",
        "APCOA KALAMAZOO MI"
    ],
    [
        "APFELKISTE.CH",
        "APFELKISTE"
    ],
    [
        "APM TECHNICA GMBH",
        "APM TECHNICA"
    ],
    [
        "APMI/MPIF",
        "APMI MPIF"
    ],
    [
        "APPLIED COMPUTER SERV",
        "APPLIED COMPUTER SERV"
    ],
    [
        "APPLIED INDUSTRIAL TECH.",
        "APPLIED IND TECH"
    ],
    [
        "ARIANE LUTVIC",
        "ARIANE LUTVIC"
    ],
    [
        "ARROW ELECTRONICS INC",
        "ARROW ELECTRONICS"
    ],
    [
        "ASS INDUSTR. ET PATRONALE",
        "ASS INDUSTR. ET PATRONALE"
    ],
    [
        "ASSOCIATED SPRREL000",
        "ASSOCIATED SPRREL"
    ],
    [
        "ATH",
        "ATH"
    ],
    [
        "ATMOSPHERE COMMERCIAL INTERIORS *",
        "ATMOSPHERE COMMERCIAL INTERIORS"
    ],
    [
        "ATTN CCA E-SERVICES",
        "ATTN CCA E SERVICES"
    ],
    [
        "AUSTIN-BERGSTROM 91280",
        "AUSTIN BERGSTROM"
    ],
    [
        "AUTOHAUS FRIEDRICH SÜTTERLIN GMBH",
        "AUTOHAUS FRIEDRICH SÜTTERLIN"
    ],
    [
        "AUTOMATED PACKAGING SYSTEMS",
        "AUTOMATED PACKAGING SYSTEMS"
    ],
    [
        "AVD GOLDACH AG",
        "AVD GOLDACH"
    ],
    [
        "AXSYS INCORPORATED",
        "AXSYS INCORPORATED"
    ],
    [
        "AZ ANZEIGER AG",
        "AZ ANZEIGER"
    ],
    [
        "BAKER + MCKENZIE",
        "BAKER MCKENZIE"
    ],
    [
        "BAL SEAL",
        "BAL SEAL"
    ],
    [
        "BANNER MEDICAL INNOVATIONS INC",
        "BANNER MEDICAL INNOVATIONS"
    ],
    [
        "BARBARA DEVELOPMENT INC",
        "BARBARA DEVELOPMENT"
    ],
    [
        "BARBARAS DEVELOPMENT",
        "BARBARAS DEVELOPMENT"
    ],
    [
        "BC GROUP INTERNATIONAL, INC.",
        "BC GROUP INTERNATIONAL"
    ],
    [
        "BECKER PUMPS CORP",
        "BECKER PUMPS"
    ],
    [
        "BELLION TAMPLEU CAPPE",
        "BELLION TAMPLEU CAPPE"
    ],
    [
        "BENDIXEN, KAY",
        "BENDIXEN KAY"
    ],
    [
        "BERLEB MEDIA GMBH",
        "BERLEB MEDIA"
    ],
    [
        "HR BERN",
        "BERN"
    ],
    [
        "BESINOVIC, ZELJKO",
        "BESINOVIC ZELJKO"
    ],
    [
        "BEYONICS INTERNATIONAL",
        "BEYONICS INTERNATIONAL"
    ],
    [
        "BILLAG AG",
        "BILLAG"
    ],
    [
        "BIO",
        "BIO"
    ],
    [
        "BIOSCIENCES LABORATORY *",
        "BIOSCIENCES LABORATORY"
    ],
    [
        "BIOUTAH",
        "BIOUTAH"
    ],
    [
        "BISCHOF SA",
        "BISCHOF"
    ],
    [
        "BISNODE DEUTSCHLAND",
        "BISNODE"
    ],
    [
        "BLUE WATER BRIDGE",
        "BLUE WATER BRIDGE"
    ],
    [
        "BLUE WATER BRIDGE (CANADA)",
        "BLUE WATER BRIDGE"
    ],
    [
        "BOARD OF EQUALIZATION *",
        "BOARD OF EQUALIZATION"
    ],
    [
        "BOEGE METALLVEREDELUNG",
        "BOEGE METALLVEREDELUNG"
    ],
    [
        "BOKERS INC    REL000",
        "BOKERS"
    ],
    [
        "BORDI MALER + GIPSER AG",
        "BORDI MALER GIPSER"
    ],
    [
        "BP FDMS CAT",
        "BP FDMS"
    ],
    [
        "BP FDMS INSIDE",
        "BP FDMS"
    ],
    [
        "BP GARAGE GALLIKER AG",
        "BP GARAGE GALLIKER"
    ],
    [
        "BRABOURNE LTD",
        "BRABOURNE"
    ],
    [
        "BRADDOCK METALLURGICAL INC TAMPA",
        "BRADDOCK METALLURGICAL"
    ],
    [
        "BRAND ADDITION IRELAND",
        "BRAND ADDITION"
    ],
    [
        "BRONSON",
        "BRONSON"
    ],
    [
        "BRONSON PLATING CO.",
        "BRONSON PLATING"
    ],
    [
        "BRYANT RUBBER CORP",
        "BRYANT RUBBER"
    ],
    [
        "BSG STAHLHANDEL",
        "BSG STAHLHANDEL"
    ],
    [
        "BUNDESANZEIGER VERLAGSGESELL",
        "BUNDESANZEIGER VERLAGSGESELL"
    ],
    [
        "BUNNYCONNELLAN",
        "BUNNYCONNELLAN"
    ],
    [
        "BVM-MASKSHOP",
        "BVM MASKSHOP"
    ],
    [
        "BVR TECHNOLOGIES",
        "BVR TECHNOLOGIES"
    ],
    [
        "BÜRO FÜR ARBEITSRECHT",
        "BÜRO FÜR ARBEITSRECHT"
    ],
    [
        "C.OTTO GEHRCKENS GMBH + CO",
        "C.OTTO GEHRCKENS"
    ],
    [
        "CA STATE BOARD OF EQUALIZ",
        "CA STATE BOARD OF EQUALIZ"
    ],
    [
        "CABIN PARTNERS LLC",
        "CABIN PARTNERS"
    ],
    [
        "CABINET ROUX",
        "CABINET ROUX"
    ],
    [
        "CADIC GERALDINE",
        "CADIC GERALDINE"
    ],
    [
        "CADMUS PARTG MBH",
        "CADMUS PARTG"
    ],
    [
        "CAMARA DE COMERCIO SUR",
        "CAMARA COMERCIO SUR"
    ],
    [
        "CAMDEN TOOL INC.",
        "CAMDEN TOOL"
    ],
    [
        "CANTON DE VAUD",
        "CANTON VAUD"
    ],
    [
        "CAROLINA INN",
        "CAROLINA INN"
    ],
    [
        "CARRIO CABLING CORP",
        "CARRIO CABLING"
    ],
    [
        "CASSON ARNAUD",
        "CASSON ARNAUD"
    ],
    [
        "CAT SALES",
        "CAT SALES"
    ],
    [
        "CAUBERT GREGOIRE",
        "CAUBERT GREGOIRE"
    ],
    [
        "CENTRAL CITY PARKING",
        "CENTRAL CITY PARKING"
    ],
    [
        "CENTRE PATRONAL",
        "CENTRE PATRONAL"
    ],
    [
        "CESI SAS",
        "CESI"
    ],
    [
        "CHARLESTON COUNTY TREASURER",
        "CHARLESTON COUNTY TREASURER"
    ],
    [
        "CHEVRON USA",
        "CHEVRON"
    ],
    [
        "CHICAGO SKYWAY EASTBOUND",
        "CHICAGO SKYWAY EASTBOUND"
    ],
    [
        "CHICAGO SKYWAY WESTBOUND",
        "CHICAGO SKYWAY WESTBOUND"
    ],
    [
        "CISION TECHNOLOGIES",
        "CISION TECHNOLOGIES"
    ],
    [
        "CITGO OIL CO",
        "CITGO OIL"
    ],
    [
        "CITY OF PUEBLO",
        "CITY OF PUEBLO"
    ],
    [
        "CLINIC-ID GMBH",
        "CLINIC"
    ],
    [
        "CNC SERVICES",
        "CNC SERVICES"
    ],
    [
        "COGEN SA",
        "COGEN"
    ],
    [
        "COLEGIOS INGENI Y AGRIME",
        "COLEGIOS INGENI Y AGRIME"
    ],
    [
        "COLORADO DEPT. OF REVENUE *",
        "COLORADO DEPT OF REVENUE"
    ],
    [
        "COMMISSIONER OF REVENUE *",
        "COMMISSIONER OF REVENUE"
    ],
    [
        "COMMODITY COMPONENTS",
        "COMMODITY COMPONENTS"
    ],
    [
        "COMPOSITE MANUFACTURING",
        "COMPOSITE MANUFACTURING"
    ],
    [
        "CONDADO VANDERBILT FD",
        "CONDADO VANDERBILT"
    ],
    [
        "DE CONINCK CEDRIC",
        "CONINCK CEDRIC"
    ],
    [
        "CONNECTICUT SPRING",
        "CONNECTICUT SPRING"
    ],
    [
        "CONSTELLATION TECHNOLOGY CORP.",
        "CONSTELLATION TECHNOLOGY"
    ],
    [
        "CONTRACT FLEET LEASING",
        "CONTRACT FLEET LEASING"
    ],
    [
        "CONTROL SOLUTIONS INC",
        "CONTROL SOLUTIONS"
    ],
    [
        "CONVERGEONE",
        "CONVERGEONE"
    ],
    [
        "COORSTEK INC",
        "COORSTEK"
    ],
    [
        "CORCORAN FOOD EQUIPMENT",
        "CORCORAN FOOD EQUIPMENT"
    ],
    [
        "COSTCO",
        "COSTCO"
    ],
    [
        "LA COURONNE",
        "COURONNE"
    ],
    [
        "COURTYARDS",
        "COURTYARDS"
    ],
    [
        "COVISION",
        "COVISION"
    ],
    [
        "CROSSTEX INTERNATIONAL INC",
        "CROSSTEX INTERNATIONAL"
    ],
    [
        "CROWN TREE CARE INC.",
        "CROWN TREE CARE"
    ],
    [
        "CSI MATERIAL HANDLING *",
        "CSI MATERIAL HANDLING"
    ],
    [
        "US CUSTOM",
        "CUSTOM"
    ],
    [
        "CUSTOM LOGOS INC",
        "CUSTOM LOGOS"
    ],
    [
        "DARBY METAL TREATING INC",
        "DARBY METAL TREATING"
    ],
    [
        "DARREN GAFFNEY",
        "DARREN GAFFNEY"
    ],
    [
        "DARREN MULLANE",
        "DARREN MULLANE"
    ],
    [
        "DEPATIE FLUID REL000",
        "DEPATIE FLUID POWER"
    ],
    [
        "GEORGIA DEPT OF REVENUE *",
        "DEPT OF REVENUE"
    ],
    [
        "DESCARTES US HOLDINGS INC",
        "DESCARTES US HOLDINGS"
    ],
    [
        "DESIGN QUALITY SERVICES",
        "DESIGN QUALITY SERVICES"
    ],
    [
        "DETROIT METRO AIRPORT",
        "DETROIT METRO AIRPORT"
    ],
    [
        "DEUTSCHER CARITASVERBAND CARITAS",
        "DEUTSCHER CARITASVERBAND CARITAS"
    ],
    [
        "DEUTSCHER KINDERSCHUTZBUND",
        "DEUTSCHER KINDERSCHUTZBUND"
    ],
    [
        "DEWYS MANUFACTURING",
        "DEWYS MANUFACTURING"
    ],
    [
        "DEYFO AG",
        "DEYFO"
    ],
    [
        "DFWPARKING",
        "DFWPARKING"
    ],
    [
        "DIAMOND PARKING #UP08",
        "DIAMOND PARKING"
    ],
    [
        "DIGI INTERNATIONAL INC",
        "DIGI INTERNATIONAL"
    ],
    [
        "DIGI-KEY CORPOREL000",
        "DIGI KEY"
    ],
    [
        "DINERS CLUB - AS",
        "DINERS CLUB"
    ],
    [
        "DIPL.-ING.WULF JESS",
        "DIPL ING WULF JESS"
    ],
    [
        "DIVISION STAMPINGS INC",
        "DIVISION STAMPINGS"
    ],
    [
        "DMT DREHMASCH.GMBH & CO. KG",
        "DMT DREHMASCH"
    ],
    [
        "DOL-OSHA",
        "DOL OSHA"
    ],
    [
        "DOROTHEA GRABOWSKI",
        "DOROTHEA GRABOWSKI"
    ],
    [
        "DOUBLE TREE BY HILTON LON",
        "DOUBLE TREE HILTON"
    ],
    [
        "DOUBLETREE DALLAS DFW AIRPORT",
        "DOUBLE TREE HILTON"
    ],
    [
        "DP TECHNOLOGY CORP.",
        "DOUGLAS CONTROL & AUTOMATION"
    ],
    [
        "DR.GUENTER DOERR&PARTNER",
        "DR GUENTER DOERR&PARTNER"
    ],
    [
        "DRAGO SMD GMBH",
        "DRAGO SMD"
    ],
    [
        "DTX MEDIA LLC",
        "DTX MEDIA"
    ],
    [
        "DUN & BRADSTREET",
        "DUN & BRADSTREET"
    ],
    [
        "DUSSELDORF INTERNATIONAL AIRPORT",
        "DUSSELDORF INTERNATIONAL AIRPORT"
    ],
    [
        "DYNATOOL INDUSTRIES, INC",
        "DYNATOOL INDUSTRIES"
    ],
    [
        "E3 COMPLIANCE LLC",
        "E3 COMPLIANCE"
    ],
    [
        "EARL OF SANDWICH",
        "EARL OF SANDWICH"
    ],
    [
        "ECHANTILLON AUDIT FDA",
        "ECHANTILLON AUDIT FDA"
    ],
    [
        "EDITA ONOFREI",
        "EDITA ONOFREI"
    ],
    [
        "EDMUND OPTICS INC",
        "EDMUND OPTICS"
    ],
    [
        "EGGERT AUTOMATISIERUNGS-",
        "EGGERT AUTOMATISIERUNGS"
    ],
    [
        "EIMO AMERICAS",
        "EIMO AMERICAS"
    ],
    [
        "EIMO TECHNOLOGIES INC.",
        "EIMO TECHNOLOGIES"
    ],
    [
        "EISENHENKEL LOGISTIK GMBH",
        "EISENHENKEL LOGISTIK"
    ],
    [
        "ELECTROFLUID",
        "ELECTROFLUID"
    ],
    [
        "ELEMENT MACHINERY LLC",
        "ELEMENT MACHINERY"
    ],
    [
        "ELLIS ARCAIDIA RAMP 4",
        "ELLIS ARCAIDIA RAMP 4"
    ],
    [
        "ELLIS - SPECTRUM HEALTH",
        "ELLIS SPECTRUM HEALTH"
    ],
    [
        "ELLSWORTH ADHESIVES SPEC CHEM DIST, INC.",
        "ELLSWORTH ADHESIVES SPEC CHEM DIST"
    ],
    [
        "ELMSTONE SYSTEMS LIMITED",
        "ELMSTONE SYSTEMS"
    ],
    [
        "EMBASSY SUITES",
        "EMBASSY SUITES"
    ],
    [
        "ENERGIE-AGENTUR",
        "ENERGIE-AGENTUR"
    ],
    [
        "ENERGIZER LLC",
        "ENERGIZER"
    ],
    [
        "ENTERPRISE RENT ACAR TOLL",
        "ENTERPRISE RENT ACAR TOLL"
    ],
    [
        "ENTRY EXIT LANE CLASS TOLL",
        "ENTRY EXIT LANE CLASS TOLL"
    ],
    [
        "ENVISAGE",
        "ENVISAGE"
    ],
    [
        "ERCHINGER MED TECHNOLOGY",
        "ERCHINGER MED TECHNOLOGY"
    ],
    [
        "ERCHINGER MEDTECH. GMBH",
        "ERCHINGER MED TECHNOLOGY"
    ],
    [
        "ERNIE GREEN INDUSTRIES",
        "ERNIE GREEN INDUSTRIES"
    ],
    [
        "ESAG AG",
        "ESAG"
    ],
    [
        "ESSO-TANKSTELLE",
        "ESSO TANKSTELLE"
    ],
    [
        "EUROPAC LTD",
        "EUROPAC"
    ],
    [
        "EUROPCAR",
        "EUROPCAR"
    ],
    [
        "EVANNE BURKLEY",
        "EVANNE BURKLEY"
    ],
    [
        "EVOSET AG",
        "EVOSET"
    ],
    [
        "EXCEL4APPS INC",
        "EXCEL4APPS"
    ],
    [
        "EXPRESSASSE",
        "EXPRESSASSE"
    ],
    [
        "EXXONMOBIL CAT OUTSIDE",
        "EXXONMOBIL CAT OUTSIDE"
    ],
    [
        "EXXONMOBIL INSIDE SALES",
        "EXXONMOBIL INSIDE SALES"
    ],
    [
        "F. REYHER NACHFOLGER",
        "F REYHER NACHFOLGER"
    ],
    [
        "FAMILY EXPRESS 52",
        "FAMILY EXPRESS 52"
    ],
    [
        "FAST PERFEKT",
        "FAST PERFEKT"
    ],
    [
        "FC GRENCHEN 15",
        "FC GRENCHEN 15"
    ],
    [
        "FC POST SOLOTHURN",
        "FC POST SOLOTHURN"
    ],
    [
        "FER CIAN 106.4 CAISSE DE",
        "FER CIAN 106 4 CAISSE"
    ],
    [
        "FERDIC INCORPORATED",
        "FERDIC INCORPORATED"
    ],
    [
        "FERRELLGAS, INC.",
        "FERRELLGAS"
    ],
    [
        "FFH SERVICE GMBH",
        "FFH SERVICE"
    ],
    [
        "FK KNOERZER GMBH",
        "FK KNOERZER"
    ],
    [
        "FL GAS SHELL LUQUILLO",
        "FL GAS SHELL LUQUILLO"
    ],
    [
        "FLEETONE *",
        "FLEETONE"
    ],
    [
        "FLORIDA DEPT OF REVENUE *",
        "FLORIDA DEPT OF REVENUE"
    ],
    [
        "FLOWER MOUND CHAMBER",
        "FLOWER MOUND CHAMBER"
    ],
    [
        "FLOWTECH CORPORATION",
        "FLOWTECH"
    ],
    [
        "FLYING J #685",
        "FLYING J"
    ],
    [
        "FLYING J #744",
        "FLYING J"
    ],
    [
        "FONG'S ENGINEERING & MFG",
        "FONG S ENGINEERING & MFG"
    ],
    [
        "FORCE ELECTRONICS",
        "FORCE ELECTRONICS"
    ],
    [
        "FORCK FˆRDE HYDRAULIK GMBH",
        "FORCK FˆRDE HYDRAULIK"
    ],
    [
        "FORNER BRICE",
        "FORNER BRICE"
    ],
    [
        "FRED KANDLER",
        "FRED KANDLER"
    ],
    [
        "FREIBURG INSTITUT",
        "FREIBURG INSTITUT"
    ],
    [
        "G&D GRAFIK& DRUCK",
        "G&D GRAFIK& DRUCK"
    ],
    [
        "G-MED NORTH AMERICA INC-LNE",
        "G-MED"
    ],
    [
        "GANTER GMBH + CO.KG",
        "GANTER"
    ],
    [
        "GAP-DESIGN",
        "GAP DESIGN"
    ],
    [
        "GARDEN HUSKY 6157",
        "GARDEN HUSKY"
    ],
    [
        "GASSMANN MEDIA AG",
        "GASSMANN MEDIA"
    ],
    [
        "GE INTELLIGENT",
        "GE INSPECTION TECHNOLOGIES"
    ],
    [
        "GEBR. FRIEDRICH",
        "GEBR FRIEDRICH"
    ],
    [
        "GEMA EUROPE S.R.L.",
        "GEMA"
    ],
    [
        "GEMÜ GMBH",
        "GEMÜ"
    ],
    [
        "GENERAL FASTENERS INC",
        "GENERAL FASTENERS"
    ],
    [
        "GENERAL PARKING 380",
        "GENERAL PARKING 380"
    ],
    [
        "GEOCORP, INC.",
        "GEOCORP"
    ],
    [
        "GEOMED MEDIZIN TECHNIK",
        "GEOMED MEDIZIN TECHNIK"
    ],
    [
        "GEORGE H. SWATEK, INC.",
        "GEORGE H SWATEK"
    ],
    [
        "GERALD R FORD INTNL AIRP",
        "GERALD R FORD INTNL AIRP"
    ],
    [
        "GERALD R. FORD",
        "GERALD R FORD INTNL AIRP"
    ],
    [
        "GERALD R. FORD INT'L AIRP",
        "GERALD R FORD INTNL AIRP"
    ],
    [
        "GLAS LÖFFLER GMBH & CO.KG",
        "GLAS LÖFFLER"
    ],
    [
        "GLOBAL TECHNOLOGIES, INC",
        "GLOBAL TECHNOLOGIES"
    ],
    [
        "GLOBAL UPHOLSTERY CO. INC.",
        "GLOBAL UPHOLSTERY"
    ],
    [
        "GLOBETROTTER AUSRÜSTUNG",
        "GLOBETROTTER AUSRÜSTUNG"
    ],
    [
        "GLOBTEK INC",
        "GLOBTEK"
    ],
    [
        "GOES MBH",
        "GOES MBH"
    ],
    [
        "GOETSCHI INGENIEURBÜRO AG",
        "GOETSCHI INGENIEURBÜRO"
    ],
    [
        "GOLDFUSS ENGINEERING GMBH",
        "GOLDFUSS ENGINEERING"
    ],
    [
        "GONCALO FERREIRA",
        "GONCALO FERREIRA"
    ],
    [
        "GRACE ENGINEERING",
        "GRACE ENGINEERING"
    ],
    [
        "GROSSENBACHER, ANDY",
        "GROSSENBACHER ANDY"
    ],
    [
        "GUERRA GULF",
        "GUERRA GULF"
    ],
    [
        "GUTEKUNST FORMFEDERN GMBH",
        "GUTEKUNST FORMFEDERN"
    ],
    [
        "GUTIERREZ VALDERRAMA DIVA LEONOR",
        "GUTIERREZ VALDERRAMA DIVA LEONOR"
    ],
    [
        "GUY JULIE",
        "GUY JULIE"
    ],
    [
        "GWS TOOL LLC",
        "GWS TOOL"
    ],
    [
        "HALKEY ROBERTS",
        "HALKEY ROBERTS"
    ],
    [
        "HAMDORF-KΣHLER, AXEL",
        "HAMDORF KΣHLER AXEL"
    ],
    [
        "HANDELSREGISTERAMT KT. SO",
        "HANDELSREGISTERAMT KT"
    ],
    [
        "HANLEY CONTROLS LTD.,",
        "HANLEY CONTROLS"
    ],
    [
        "HASPA GMBH",
        "HASPA"
    ],
    [
        "HAVEMEISTER, SVEN",
        "HAVEMEISTER"
    ],
    [
        "HAWAII DEPARTMENT OF TAXATION *",
        "HAWAII DEPARTMENT OF TAXATION"
    ],
    [
        "HAWAII STATE TAX COLLECTOR",
        "HAWAII STATE TAX COLLECTOR"
    ],
    [
        "HAWO GMBH",
        "HAWO"
    ],
    [
        "HEARTLAND REGION CS 1X",
        "HEARTLAND REGION"
    ],
    [
        "HELM POKALE E.K.",
        "HELM POKALE E K"
    ],
    [
        "HENRIKE-FEE KLEINE",
        "HENRIKE FEE KLEINE"
    ],
    [
        "HERBERT BAECK, LMS",
        "HERBERT BAECK LMS"
    ],
    [
        "HERTZ",
        "HERTZ"
    ],
    [
        "HILLER FEINWERKTECHNIK GMBH",
        "HILLER FEINWERKTECHNIK"
    ],
    [
        "HILTON GARDEN INN",
        "HILTON"
    ],
    [
        "HILTON HOTELS",
        "HILTON"
    ],
    [
        "HILTON LEXINGTON/DOWNTOWN",
        "HILTON"
    ],
    [
        "HITCHINER MANUFACTURING",
        "HITCHINER MANUFACTURING"
    ],
    [
        "HOBSON MOTZER INC",
        "HOBSON MOTZER"
    ],
    [
        "HOLIDAY 506",
        "HOLIDAY 506"
    ],
    [
        "HOLIDAY STATION STORE #43",
        "HOLIDAY STATION STORE 43"
    ],
    [
        "HOLIDAY STATIONSTORE 332",
        "HOLIDAY STATIONSTORE 332"
    ],
    [
        "HOMEWOOD SUITES",
        "HOMEWOOD SUITES"
    ],
    [
        "HOOSIER INC",
        "HOOSIER"
    ],
    [
        "HORST MEEMANN",
        "HORST MEEMANN"
    ],
    [
        "HOTEL LANDING",
        "HOTEL LANDING"
    ],
    [
        "HOTEL MONTANA",
        "HOTEL MONTANA"
    ],
    [
        "HUBBELL INCORPORATED",
        "HUBBELL INCORPORATED"
    ],
    [
        "HUBBELL INCORPORATED (DELAWARE",
        "HUBBELL INCORPORATED"
    ],
    [
        "HUGENDUBEL FACHINF.GMBH",
        "HUGENDUBEL FACHINF"
    ],
    [
        "HUMPHREY PRODUCTS",
        "HUMPHREY PRODUCTS"
    ],
    [
        "HWN TITAN GMBH",
        "HWN TITAN"
    ],
    [
        "HYATT HOUSE SAN JUAN",
        "HYATT HOUSE SAN JUAN"
    ],
    [
        "HÄRTEREI GERSTER AG",
        "HÄRTEREI GERSTER"
    ],
    [
        "HÖGG AG",
        "HÖGG"
    ],
    [
        "IAR SOFTWARE SYSTEMS INC",
        "IAR SOFTWARE SYSTEMS"
    ],
    [
        "IBELO GMBH & CO. KG",
        "IBELO"
    ],
    [
        "IBS MAGNET",
        "IBS MAGNET"
    ],
    [
        "IFM ELECTRONIC GMBH",
        "IFM ELECTRONIC"
    ],
    [
        "IHVG INDUSTRIE- UND",
        "IHVG INDUSTRIE UND"
    ],
    [
        "ILLINOIS DEPT. OF *",
        "ILLINOIS DEPT OF"
    ],
    [
        "IMPRO INDUSTRIES USA INC",
        "IMPRO INDUSTRIES"
    ],
    [
        "IN  MICRO ESTIMATING SYST",
        "IN  MICRO ESTIMATING SYST"
    ],
    [
        "INDIANA DEPT OF REVENUE *",
        "INDIANA DEPT OF REVENUE"
    ],
    [
        "INDIGO HOTELS",
        "INDIGO HOTELS"
    ],
    [
        "INDO-US MIM TEC PVT LTD",
        "INDO MIM TEC PVT"
    ],
    [
        "INDUCTIVE AUTOMATION LLC",
        "INDUCTIVE AUTOMATION"
    ],
    [
        "INDUSTRIE-U.HANDELSKAMMER",
        "INDUSTRIE U HANDELSKAMMER"
    ],
    [
        "INGUN PRÜFMITTELBAU GMBH",
        "INGUN PRÜFMITTELBAU"
    ],
    [
        "INKCUPS",
        "INKCUPS"
    ],
    [
        "INNOMED INC",
        "INNOMED"
    ],
    [
        "INSPECTION FDA",
        "INSPECTION FDA"
    ],
    [
        "INSTITUT DER DEUTSCHEN",
        "INSTITUT DER DEUTSCHEN"
    ],
    [
        "INSULATION SUPPLY CO",
        "INSULATION SUPPLY"
    ],
    [
        "INTERNATIONAL SAFE TRANSIT ASSOCIATION",
        "INTERNATIONAL SAFE TRANSIT ASSOCIATION"
    ],
    [
        "INTERTEK INDUSTRIAL CORP.",
        "INTERTEK INDUSTRIAL"
    ],
    [
        "INVENTEC PERFOREL000",
        "INVENTEC PERFORMANCE"
    ],
    [
        "IPSOS OPERATIONS GMBH",
        "IPSOS OPERATIONS"
    ],
    [
        "ITR CAP",
        "ITR CAP"
    ],
    [
        "J.F. BERNS COMPANY, INC.",
        "J F BERNS"
    ],
    [
        "J J KELLER & ASSOCIATES",
        "J J KELLER & ASSOCIATES"
    ],
    [
        "J. STERLING INDUSTRIES LTD",
        "J STERLING INDUSTRIES"
    ],
    [
        "JAKOB LˆWER",
        "JAKOB LˆWER"
    ],
    [
        "JASPARES PIZZA PORTAGE",
        "JASPARES PIZZA PORTAGE"
    ],
    [
        "JD MACHINE CORP",
        "JD MACHINE"
    ],
    [
        "JESUS SAAD NAZER FUNDACION",
        "JESUS SAAD NAZER FUNDACION"
    ],
    [
        "JIM ANNIS TOOLS",
        "JIM ANNIS TOOLS"
    ],
    [
        "JK MACHINING, INC.",
        "JK MACHINING"
    ],
    [
        "JLJ INDUSTRIAL SERVICES",
        "JLJ INDUSTRIAL SERVICES"
    ],
    [
        "JMC TOOL & MACHINE",
        "JMC TOOL & MACHINE"
    ],
    [
        "JOINEZPASS",
        "JOINEZPASS"
    ],
    [
        "JONAS TER HASEBORG",
        "JONAS TER HASEBORG"
    ],
    [
        "JUNCTION PRODUCTIONS",
        "JUNCTION PRODUCTIONS"
    ],
    [
        "JUNIOR ACHIEVEMENTIRELAND",
        "JUNIOR ACHIEVEMENT"
    ],
    [
        "JUNITOS SERVICE STATION S",
        "JUNITOS SERVICE STATION"
    ],
    [
        "JUNOPACIFIC",
        "JUNO PACIFIC"
    ],
    [
        "JURIJ RUDI",
        "JURIJ RUDI"
    ],
    [
        "JWF TECHNOLOGIES",
        "JWF TECHNOLOGIES"
    ],
    [
        "KANINCK, JENS",
        "KANINCK, JENS"
    ],
    [
        "KANSAS DEPT. OF REVENUE",
        "KANSAS DEPT OF REVENUE"
    ],
    [
        "KANTON AARGAU",
        "KANTON AARGAU"
    ],
    [
        "KANTONALES STEUERAMT ZÜRICH",
        "KANTONALES STEUERAMT ZÜRICH"
    ],
    [
        "KELLER BÜRO AG",
        "KELLER BÜRO"
    ],
    [
        "KENT MANUFACTUREL000",
        "KENT MANUFACTUREL"
    ],
    [
        "KENTUCKY DEPARTMENT OF REVENUE *",
        "KENTUCKY DEPARTMENT OF REVENUE"
    ],
    [
        "KEPCO, INC.",
        "KEPCO"
    ],
    [
        "KIEL PARKHAUS",
        "KIEL PARKHAUS"
    ],
    [
        "KIEL PARKING",
        "KIEL PARKING"
    ],
    [
        "KILGORE GAS N GO",
        "KILGORE GAS N GO"
    ],
    [
        "KIM TRAN THI",
        "KIM TRAN THI"
    ],
    [
        "KLAUS-PETER GRⁿNWALD",
        "KLAUS PETER GRⁿNWALD"
    ],
    [
        "KONICA MINOLTA",
        "KONICA MINOLTA"
    ],
    [
        "KONRADIN VERLAG",
        "KONRADIN VERLAG"
    ],
    [
        "KORUS EDITION",
        "KORUS EDITION"
    ],
    [
        "KOSCHER & WUERTZ GMBH",
        "KOSCHER & WUERTZ"
    ],
    [
        "KREIS PLˆN",
        "KREIS PLˆN"
    ],
    [
        "KREIS PL÷N",
        "KREIS ÷N"
    ],
    [
        "KURSIVAG",
        "KURSIVAG"
    ],
    [
        "KWIK TRIP 414",
        "KWIK TRIP 414"
    ],
    [
        "KWIK TRIP 464",
        "KWIK TRIP 464"
    ],
    [
        "KWIK TRIP 672",
        "KWIK TRIP 672"
    ],
    [
        "KÄSER, JOSUA",
        "KÄSER, JOSUA"
    ],
    [
        "KÜNTI, NATALIE",
        "KÜNTI NATALIE"
    ],
    [
        "L&J CORPORATE SERVICES INC",
        "L&J CORPORATE SERVICES"
    ],
    [
        "LAGARDE CELINE",
        "LAGARDE CELINE"
    ],
    [
        "LAKE FRANKLIN GARAGE",
        "LAKE FRANKLIN GARAGE"
    ],
    [
        "LAKE REGION MEDICAL COLLEGEVILLE",
        "LAKE REGION MEDICAL"
    ],
    [
        "LAKE REGION MEDICAL WHEELING",
        "LAKE REGION MEDICAL"
    ],
    [
        "LAKE REGION MEDICAL, INC.",
        "LAKE REGION MEDICAL"
    ],
    [
        "LAKE REGION MEDICAL CHASKA",
        "LAKE REGION MEDICAL CHASKA"
    ],
    [
        "LANDESKRIMINALAMT",
        "LANDESKRIMINALAMT"
    ],
    [
        "LANDESVERWALTUNGSAMT",
        "LANDESVERWALTUNGSAMT"
    ],
    [
        "LANSADE",
        "LANSADE"
    ],
    [
        "LASSEN, DIRK",
        "LASSEN DIRK"
    ],
    [
        "LASTEC AG",
        "LASTEC"
    ],
    [
        "LAUREN INTL LTD",
        "LAUREN INTL"
    ],
    [
        "LAZ PARKING 600101P",
        "LAZ PARKING 600101P"
    ],
    [
        "LE GOFF GROUPE",
        "LE GOFF GROUPE"
    ],
    [
        "LE RHEU ENVIRONNEMENT",
        "LE RHEU ENVIRONNEMENT"
    ],
    [
        "LEAX AVOCATS SARL",
        "LEAX AVOCATS"
    ],
    [
        "LEESE, JANINE",
        "LEESE JANINE"
    ],
    [
        "LEMO USA",
        "LEMO"
    ],
    [
        "LENZKES SPANNTECHNIK GMBH",
        "LENZKES SPANNTECHNIK"
    ],
    [
        "LES PRODUITS PLASTITEL, INC",
        "LES PRODUITS PLASTITEL"
    ],
    [
        "LEUZE, EUGEN G. VERLAG",
        "LEUZE EUGEN G VERLAG"
    ],
    [
        "LFI INC",
        "LFI"
    ],
    [
        "LIFE SCIENCE NORD E.V.",
        "LIFE SCIENCE NORD E V"
    ],
    [
        "LINAK US INC",
        "LINAK"
    ],
    [
        "AD-LINGUA",
        "LINGUA"
    ],
    [
        "LNE / G-MED",
        "LNE / G-MED"
    ],
    [
        "LOGMEIN",
        "LOGMEIN"
    ],
    [
        "LOS ANGELES SHELL SERVICE",
        "LOS ANGELES SHELL SERVICE"
    ],
    [
        "LOUE TA FETE",
        "LOUE FETE"
    ],
    [
        "LOVES #618",
        "LOVES 618"
    ],
    [
        "LSC COMMUNICATIONS EUROPE",
        "LSC COMMUNICATIONS"
    ],
    [
        "LUBECA GMBH & CO. TEGU KG",
        "LUBECA & TEGU"
    ],
    [
        "LUTHY+STOCKER AG(BUCHHAUS)",
        "LUTHY STOCKER"
    ],
    [
        "MAIKEN SEVERLOH",
        "MAIKEN SEVERLOH"
    ],
    [
        "MAJEWSKI, DIRK",
        "MAJEWSKI DIRK"
    ],
    [
        "MALL CITY CONTREL110",
        "MALL CITY CONTAINERS"
    ],
    [
        "MAN TRUCK + BUS DEUTSCHLAND",
        "MAN TRUCK BUS"
    ],
    [
        "MANA SHAHRYARI",
        "MANA SHAHRYARI"
    ],
    [
        "MANFRED URBAT",
        "MANFRED URBAT"
    ],
    [
        "MARIO L‰SSIG",
        "MARIO E LILLO"
    ],
    [
        "MARRIOTT HOTELS",
        "MARRIOTT HOTELS"
    ],
    [
        "MARTINEZ SUPER SHELL",
        "MARTINEZ SUPER SHELL"
    ],
    [
        "MASCHINENBAU KITZ GMBH",
        "MASCHINENBAU KITZ"
    ],
    [
        "MASS PRECISION  SHEET MET",
        "MASS PRECISION SHEET MET"
    ],
    [
        "MAT MASCHINENTECHNIK GMBH",
        "MAT MASCHINENTECHNIK"
    ],
    [
        "MATHEW, TISIL K",
        "MATHEW, TISIL K"
    ],
    [
        "MATRIX IV *",
        "MATRIX IV"
    ],
    [
        "MAVERIK 162",
        "MAVERIK 162"
    ],
    [
        "MAVERIK #423",
        "MAVERIK 423"
    ],
    [
        "MBI MARTIN BRUECKNER",
        "MBI MARTIN BRUECKNER"
    ],
    [
        "MCCORMICK CENTER SOUTH LOT",
        "MCCORMICK CENTER SOUTH LOT"
    ],
    [
        "MCCORMICK PLACE GARAGE",
        "MCCORMICK PLACE GARAGE"
    ],
    [
        "MCKESSON MEDICAL SURGICAL INC",
        "MCKESSON MEDICAL SURGICAL"
    ],
    [
        "MEASUREMENT SPECIALTIES",
        "MEASUREMENT SPECIALTIES"
    ],
    [
        "MEHMETI RAME",
        "MEHMETI RAME"
    ],
    [
        "MEIJER - CASCADE-MI - #05",
        "MEIJER CASCADE MI 05"
    ],
    [
        "MEIJER   KALAMAZOO MI   #",
        "MEIJER KALAMAZOO MI"
    ],
    [
        "DE MELLO THIMM, CAROLINA",
        "MELLO THIMM CAROLINA"
    ],
    [
        "MERAL EICHWALD",
        "MERAL EICHWALD"
    ],
    [
        "MERRILL BRINK INTL LTD",
        "MERRILL BRINK INTL"
    ],
    [
        "MICHAEL BRYAN",
        "MICHAEL BRYAN"
    ],
    [
        "MICHAEL- NORBERT RITSCHER",
        "MICHAEL NORBERT RITSCHER"
    ],
    [
        "MICRO LUMEN INC",
        "MICRO LUMEN"
    ],
    [
        "MICROTEK MEDICAL, INC",
        "MICROTEK MEDICAL"
    ],
    [
        "MIDWEST PETROLEUM #18",
        "MIDWEST PETROLEUM 18"
    ],
    [
        "MIDWEST RUBBER COMPANY",
        "MIDWEST RUBBER"
    ],
    [
        "MIGNATON MARJORIE",
        "MIGNATON MARJORIE"
    ],
    [
        "MINISTERIUM FUER JUSTIZ",
        "MINISTERIUM FUER JUSTIZ"
    ],
    [
        "MINITEC BTD SERVICE GMBH",
        "MINITEC BTD SERVICE"
    ],
    [
        "MINNESOTA DEPT. OF REVENUE *",
        "MINNESOTA DEPT OF REVENUE"
    ],
    [
        "MISSISSIPPI DEPARTMENT OF REVENUE *",
        "MISSISSIPPI DEPARTMENT OF REVENUE"
    ],
    [
        "MISSOURI DEPT. OF *",
        "MISSOURI DEPT OF"
    ],
    [
        "MLK SELF PARK 71930",
        "MLK SELF PARK"
    ],
    [
        "MONODE MARKINGREL000",
        "MONODE MARKING PRODUCTS"
    ],
    [
        "MONS ANTOINE",
        "MONS ANTOINE"
    ],
    [
        "MONTVALE AUTO SPA LLC",
        "MONTVALE AUTO"
    ],
    [
        "MORARD VINCENT",
        "MORARD VINCENT"
    ],
    [
        "MORDHORST U. BOCKENDAHL GMB",
        "MORDHORST U BOCKENDAHL"
    ],
    [
        "MORI SEIKI GMBH",
        "MORI SEIKI"
    ],
    [
        "MPV VERTRIEBS GMBH",
        "MPV VERTRIEBS"
    ],
    [
        "MUNOZ NAZARIO LAW OFFICES PSC",
        "MUNOZ NAZARIO LAW OFFICES PSC"
    ],
    [
        "MUNSTER TOOL AND DIE",
        "MUNSTER TOOL & DIE"
    ],
    [
        "MURRAY A PERCIREL000",
        "MURRAY A PERCIREL000"
    ],
    [
        "MˆSS, JENS",
        "MˆSS JENS"
    ],
    [
        "M÷SS, JENS",
        "MˆSS, JENS"
    ],
    [
        "N.C. DEPT OF REVENUE *",
        "N C DEPT OF REVENUE"
    ],
    [
        "NAME BADGES INTERNATIONAL",
        "NAME BADGES INTERNATIONAL"
    ],
    [
        "NATALJA TOKAREW",
        "NATALJA TOKAREW"
    ],
    [
        "NATIONAL CAR RENTAL",
        "NATIONAL CAR RENTAL"
    ],
    [
        "NATIONAL CAR RENTAL TOLLS",
        "NATIONAL CAR RENTAL"
    ],
    [
        "NATIONAL CITATIONS DEPT",
        "NATIONAL CITATIONS DEPT"
    ],
    [
        "NATIONAL IRISH SAFETY",
        "NATIONAL IRISH SAFETY"
    ],
    [
        "NATIONAL PRECISION",
        "NATIONAL PRECISION"
    ],
    [
        "NATURESCAPE DESIGN, INC.",
        "NATURESCAPE DESIGN"
    ],
    [
        "NETRA VEOLIA",
        "NETRA VEOLIA"
    ],
    [
        "HR NEUCHATEL",
        "NEUCHATEL"
    ],
    [
        "NEW ENGLAND WIRE TECH",
        "NEW ENGLAND WIRE TECH"
    ],
    [
        "NEW ENGLAND WIREL000",
        "NEW ENGLAND WIRE TECH"
    ],
    [
        "NEW YORK STATE SALES *",
        "NEW YORK STATE SALES"
    ],
    [
        "NEWARK INONE",
        "NEWARK"
    ],
    [
        "COMBAL NICOLAS",
        "NICOLAS COMBAL"
    ],
    [
        "NJ DIVISION OF TAXATION *",
        "NJ DIVISION OF TAXATION"
    ],
    [
        "NOAH STICKDESIGN",
        "NOAH STICKDESIGN"
    ],
    [
        "NOCO EXPRESS-204",
        "NOCO EXPRESS 204"
    ],
    [
        "NORCOM SYSTEMS, INC.",
        "NORCOM SYSTEMS"
    ],
    [
        "NORDMETALL",
        "NORDMETALL"
    ],
    [
        "NORTH COAST COMPONENTS",
        "NORTH COAST COMPONENTS"
    ],
    [
        "NORTH COAST COREL000",
        "NORTH COAST COREL000"
    ],
    [
        "NOTARE DR. THIELE & DR.SATZL",
        "NOTARE DR THIELE & DR SATZL"
    ],
    [
        "NOVOTEL FREIBURG AM KONZERTHAUS",
        "NOVOTEL FREIBURG AM KONZERTHAUS"
    ],
    [
        "NOVTECH INC",
        "NOVTECH"
    ],
    [
        "NYS SALES TAX PROCESSING",
        "NYS SALES TAX PROCESSING"
    ],
    [
        "OAK PAPER PRODUCTS",
        "OAK PAPER PRODUCTS"
    ],
    [
        "OAK PAPER PRODUCTS CO., INC",
        "OAK PAPER PRODUCTS"
    ],
    [
        "OBERG INDUSTRIES INC",
        "OBERG INDUSTRIES"
    ],
    [
        "OKAY INDUSTRIES INC",
        "OKAY INDUSTRIES"
    ],
    [
        "OKLAHOMA TAX COMMISSION",
        "OKLAHOMA TAX COMMISSION"
    ],
    [
        "OKLAHOMA TAX COMMISSION *",
        "OKLAHOMA TAX COMMISSION"
    ],
    [
        "OLIVER TOLAS HEALTHCARE PACKAGING",
        "OLIVER TOLAS HEALTHCARE PACKAGING"
    ],
    [
        "OLIVIER SCHWAB",
        "OLIVIER SCHWAB"
    ],
    [
        "ONELOOK SYSTEMS",
        "ONE LOOK SYSTEMS"
    ],
    [
        "ONROUTE PETROLEUM",
        "ONROUTE PETROLEUM"
    ],
    [
        "ONSTAFF GROUP",
        "ONSTAFF GROUP"
    ],
    [
        "ORACLE AMERICA INC",
        "ORACLE"
    ],
    [
        "CORPORATION SERVICE COMPANY",
        "ORATION SERVICE COMPANY"
    ],
    [
        "ORBITALUM TOOLS GMBH",
        "ORBITALUM TOOLS"
    ],
    [
        "ORCHID BRIDGEPORT",
        "ORCHID BRIDGEPORT"
    ],
    [
        "P & G ENGINEERING",
        "P & G ENGINEERING"
    ],
    [
        "PALLET DEPOT",
        "PALLET DEPOT"
    ],
    [
        "PARK SERVICE STATION",
        "PARK SERVICE STATION"
    ],
    [
        "PARKING AG SOLOTHURN",
        "PARKING AG SOLOTHURN"
    ],
    [
        "PDA",
        "PDA"
    ],
    [
        "PEPPERL + FUCHS VERTRIEB",
        "PEPPERL FUCHS VERTRIEB"
    ],
    [
        "PERENOM AGNES",
        "PERENOM AGNES"
    ],
    [
        "PETERSEN, MANUELA",
        "PETERSEN MANUELA"
    ],
    [
        "PETERSEN, VOLKER",
        "PETERSEN VOLKER"
    ],
    [
        "PETRA WIEJACK",
        "PETRA WIEJACK"
    ],
    [
        "PETRO CANADA",
        "PETRO"
    ],
    [
        "PETRO CANADA INC",
        "PETRO"
    ],
    [
        "PHAM TAN LOC",
        "PHAM TAN LOC"
    ],
    [
        "PHILIPP SCHOLZ-STAMMER",
        "PHILIPP SCHOLZ STAMMER"
    ],
    [
        "PHILIPS ELECTRONICS NORTH AMERICA CORP",
        "PHILIPS ELECTRONICS"
    ],
    [
        "PHILLIPS & JOHNSTON INC",
        "PHILLIPS & JOHNSTON"
    ],
    [
        "PHILLIPS 66-CONOCO-76 CAT",
        "PHILLIPS 66"
    ],
    [
        "PHILLIPS 66-CONOCO-76 ELE",
        "PHILLIPS 66"
    ],
    [
        "PILOT # 468",
        "PILOT 468"
    ],
    [
        "PILOT # 596",
        "PILOT 596"
    ],
    [
        "PINNACLE MANUFACTURING",
        "PINNACLE MANUFACTURING"
    ],
    [
        "PIONEER METAL FINISHING LLC",
        "PIONEER METAL FINISHING"
    ],
    [
        "PIRA TESTING LLC",
        "PIRA TESTING"
    ],
    [
        "PLAZA DE LAS CALIFORNIA",
        "PLAZA LAS CALIFORNIA"
    ],
    [
        "PONCE HILTON & CASINO",
        "PONCE HILTON"
    ],
    [
        "PRAXIS POWDER TECHNOLOGY",
        "PRAXIS POWDER TECHNOLOGY"
    ],
    [
        "PRECISION WIRE COMPONENTS",
        "PRECISION WIRE COMPONENTS"
    ],
    [
        "PRELEVEMENT POUR CONTROLE",
        "PRELEVEMENT POUR CONTROLE"
    ],
    [
        "PRELEVEMENT QA POUR TEST",
        "PRELEVEMENT POUR TEST"
    ],
    [
        "PRO SOURCE",
        "PRO SOURCE"
    ],
    [
        "PROCURE.CH",
        "PROCURE"
    ],
    [
        "PRODINGER VERPACKUNG",
        "PRODINGER VERPACKUNG"
    ],
    [
        "PRODUCTION EQUIPMENT LTD.",
        "PRODUCTION EQUIPMENT"
    ],
    [
        "PROFILE PLASTICS CORP.",
        "PROFILE PLASTICS"
    ],
    [
        "PROJECT MANAGMENT INS",
        "PROJECT MANAGMENT INS"
    ],
    [
        "PRONTO REPAIRS INC",
        "PRONTO REPAIRS"
    ],
    [
        "PTI ENGINEERED PLASTICS INC",
        "PTI ENGINEERED PLASTICS"
    ],
    [
        "IRN PUBLISHING",
        "PUBLISHING"
    ],
    [
        "IRN PUBLISHING LTD",
        "PUBLISHING"
    ],
    [
        "PUMA",
        "PUMA"
    ],
    [
        "PUMA - TRUJILLO ALTO",
        "PUMA"
    ],
    [
        "PUMA RIO HONDO",
        "PUMA"
    ],
    [
        "QI AVOIR PARAGON",
        "QI AVOIR PARAGON"
    ],
    [
        "QUALITY TOOL & STAMPING",
        "QUALITY TOOL & STAMPING"
    ],
    [
        "QUEEN CITY IMAGING INC",
        "QUEEN CITY IMAGING"
    ],
    [
        "QUIKTRIP 664",
        "QUIKTRIP 664"
    ],
    [
        "QUIKTRIP 664 CAT",
        "QUIKTRIP 664"
    ],
    [
        "RADIANT IRELAND",
        "RADIANT"
    ],
    [
        "RADISSON PARKING",
        "RADISSON PARKING"
    ],
    [
        "RAVELEX",
        "RAVELEX"
    ],
    [
        "REALVISION, INC.",
        "REALVISION"
    ],
    [
        "RECORD TÜRAUTOMATION AG",
        "RECORD TÜRAUTOMATION"
    ],
    [
        "REGI SERVI CNTR INC MAPP",
        "REGI SERVI CNTR INC MAPP"
    ],
    [
        "RENAISSANCE LA CONCHA",
        "RENAISSANCE CONCHA"
    ],
    [
        "RENE HEINE",
        "RENE HEINE"
    ],
    [
        "RESIDENCE INN AUSTIN DT",
        "RESIDENCE INN AUSTIN DT"
    ],
    [
        "RESIDENCE INNS",
        "RESIDENCE INNS"
    ],
    [
        "RICHARD O'SULLIVAN",
        "RICHARD O SULLIVAN"
    ],
    [
        "RIEDEL EDM LˆSUNGEN",
        "RIEDEL EDM"
    ],
    [
        "ROAD RANGER #132",
        "ROAD RANGER 132"
    ],
    [
        "ROBIN IND.ELASTO-TEC DIV",
        "ROBIN ELASTO TEC DIV"
    ],
    [
        "ROCKET MACHINING AND",
        "ROCKET MACHINING AND"
    ],
    [
        "ROUGIER ET PLE",
        "ROUGIER ET PLE"
    ],
    [
        "RPS SOUTH BEND AIRPORT",
        "RPS SOUTH BEND AIRPORT"
    ],
    [
        "RUNCO OFFICE SUPPLY",
        "RUNCO OFFICE SUPPLY"
    ],
    [
        "RX OPTICAL 90KM 0401",
        "RX OPTICAL 90KM"
    ],
    [
        "SABRINA HOF",
        "SABRINA HOF"
    ],
    [
        "SACEM",
        "SACEM"
    ],
    [
        "SAFETY-KLEEN DEUTSCHLAND",
        "SAFETY KLEEN"
    ],
    [
        "SAI GLOBAL ILI PUBLISHING",
        "SAI GLOBAL ILI PUBLISHING"
    ],
    [
        "SAM'S WHOLESALE CLUB",
        "SAM S WHOLESALE CLUB"
    ],
    [
        "SAMSCLUB.COM",
        "SAMSCLUB COM"
    ],
    [
        "SANDT, LARA",
        "SANDT LARA"
    ],
    [
        "SAQ SWISS ASSOCIATION FOR",
        "SAQ SWISS ASSOCIATION FOR"
    ],
    [
        "SCHAEFFLER CANADA INC",
        "SCHAEFFLER"
    ],
    [
        "SCHC (SOCIETY FOR CHEMICAL HAZARD COMM)",
        "SCHC (SOCIETY FOR CHEMICAL HAZARD COMM)"
    ],
    [
        "SCHUELKE + MAYR",
        "SCHUELKE MAYR"
    ],
    [
        "SCHUPAN & SONS, INC.",
        "SCHUPAN & SONS"
    ],
    [
        "SCHWEIZERISCHE GESELLSCHAFT",
        "SCHWEIZERISCHE GESELLSCHAFT"
    ],
    [
        "SCHÖFFLER + WÖRNER GMBH & CO.KG",
        "SCHÖFFLER WÖRNER"
    ],
    [
        "SCIENTIFIC MOLDING CORP.",
        "SCIENTIFIC MOLDING"
    ],
    [
        "SCRAP SUITE CONTROLE",
        "SCRAP SUITE CONTROLE"
    ],
    [
        "SERDAR CEVIK",
        "SERDAR CEVIK"
    ],
    [
        "SESA SYSTEMS EXPORT SALES REPRESENT",
        "SESA SYSTEMS EXPORT SALES REPRESENT"
    ],
    [
        "SESAME ADVOCATS",
        "SESAME ADVOCATS"
    ],
    [
        "SHELL",
        "SHELL"
    ],
    [
        "SHELL CONTINENTAL GASOLIN",
        "SHELL"
    ],
    [
        "SHELL DEGETAU",
        "SHELL"
    ],
    [
        "SHELL DEUTSCHLAND",
        "SHELL"
    ],
    [
        "SHELL FUEL PLAZA",
        "SHELL"
    ],
    [
        "SHELL RUEMLANG",
        "SHELL"
    ],
    [
        "SHELL TORRIMAR 804690",
        "SHELL"
    ],
    [
        "SHELL-KIOSK",
        "SHELL"
    ],
    [
        "SHELL CIRCLE K",
        "SHELL CIRCLE K"
    ],
    [
        "SHELL OIL",
        "SHELL OIL"
    ],
    [
        "SHERATON",
        "SHERATON"
    ],
    [
        "SHRM-ONLINE BOOK STORE",
        "SHRM ONLINE BOOK STORE"
    ],
    [
        "SILIKON-TECHNIK SILTEC",
        "SILIKON TECHNIK SILTEC"
    ],
    [
        "SINBON ELECTRONICS CO LTD",
        "SINBON ELECTRONICS"
    ],
    [
        "SKICLUB SELZACH",
        "SKICLUB SELZACH"
    ],
    [
        "SKYWAY CONCESSION",
        "SKYWAY CONCESSION"
    ],
    [
        "SMITHS MEDICAL",
        "SMITHS MEDICAL"
    ],
    [
        "SMK SURVEYMONKEY.COM",
        "SMK SURVEYMONKEY COM"
    ],
    [
        "SMURFIT KAPPA CONNAUGHT PACKAGING",
        "SMURFIT KAPPA CONNAUGHT PACKAGING"
    ],
    [
        "SOCOTEC EQUIP. BRETAGNE NORD",
        "SOCOTEC EQUIP BRETAGNE NORD"
    ],
    [
        "SOLDIER FIELD SOUTH",
        "SOLDIER FIELD SOUTH"
    ],
    [
        "SOTHEN, PETER",
        "SOTHEN PETER"
    ],
    [
        "SOUNDCOAT, INC.",
        "SOUNDCOAT"
    ],
    [
        "SOUTH CAROLINA DEPT. OF *",
        "SOUTH CAROLINA DEPT OF"
    ],
    [
        "SOUTH DAKOTA DEPT. OF *",
        "SOUTH DAKOTA DEPT OF"
    ],
    [
        "SPECTRALYTICS",
        "SPECTRALYTICS"
    ],
    [
        "SPECTRUM PLASTICS GROUP",
        "SPECTRUM PLASTICS GROUP"
    ],
    [
        "SPEEDWAY",
        "SPEEDWAY"
    ],
    [
        "SPHERA SOLUTIONS INC",
        "SPHERA SOLUTIONS"
    ],
    [
        "SPOK INC",
        "SPOK"
    ],
    [
        "SPRINGER CUSTOMER SERVICE",
        "SPRINGER CUSTOMER SERVICE"
    ],
    [
        "STAATLICHE ARBEITSSCHUTZBEH÷",
        "STAATLICHE ARBEITSSCHUTZBEH÷"
    ],
    [
        "STAATLICHE ARBEITSSCHUTZBEHˆ",
        "STAATLICHE ARBEITSSCHUTZBEHˆ"
    ],
    [
        "STAATSSEKRETARIAT FÜR",
        "STAATSSEKRETARIAT FÜR"
    ],
    [
        "STABLER, SEAN",
        "STABLER SEAN"
    ],
    [
        "STATE COMPTROLLER *",
        "STATE COMPTROLLER"
    ],
    [
        "STATE OF MARYLAND *",
        "STATE OF MARYLAND"
    ],
    [
        "STATE OF WASHINGTON",
        "STATE OF WASHINGTON"
    ],
    [
        "STATE OF WASHINGTON *",
        "STATE OF WASHINGTON"
    ],
    [
        "STATURE ELECTRIC INC",
        "STATURE ELECTRIC"
    ],
    [
        "STEIGENBERGER CONTI HANSA",
        "STEIGENBERGER CONTI HANSA"
    ],
    [
        "STEIGER GALVANOTECHNIQUE SA",
        "STEIGER GALVANOTECHNIQUE"
    ],
    [
        "STEUERAMT DES KT. SOLOTHURN",
        "STEUERAMT DES KT SOLOTHURN"
    ],
    [
        "STEUERVERWALTUNG BASEL-STADT",
        "STEUERVERWALTUNG BASEL STADT"
    ],
    [
        "STRUERS SAS",
        "STRUERS"
    ],
    [
        "STS INDUSTRIE SA",
        "STS INDUSTRIE"
    ],
    [
        "SUNOCO INSIDE SALES",
        "SUNOCO"
    ],
    [
        "SUNOCO PUMP",
        "SUNOCO"
    ],
    [
        "SUPERAMERICA",
        "SUPERAMERICA"
    ],
    [
        "SUPERIOR CLEANROOM PRODUC",
        "SUPERIOR CLEANROOM PRODUCT"
    ],
    [
        "SURFACE MOUNT TECHNOLOGY",
        "SURFACE MOUNT TECHNOLOGY"
    ],
    [
        "SURGICAL SPECIALTIES",
        "SURGICAL SPECIALTIES"
    ],
    [
        "SURVYVN LTD",
        "SURVYVN"
    ],
    [
        "SUTTER MEDIZINTECHNIK",
        "SUTTER MEDIZINTECHNIK"
    ],
    [
        "SWISS PROFESSIONAL MEDIA AG",
        "SWISS PROFESSIONAL MEDIA"
    ],
    [
        "SWS-TRIMAC, INC.",
        "SWS TRIMAC"
    ],
    [
        "SYMMCO INC",
        "SYMMCO"
    ],
    [
        "SYSTEC GMBH",
        "SYSTEC"
    ],
    [
        "SYSTEM TECHNIK FUER BETRIEBS",
        "SYSTEM TECHNIK FUER BETRIEBS"
    ],
    [
        "SYSTEMATIC MANUFACTURING",
        "SYSTEMATIC MANUFACTURING"
    ],
    [
        "SYSTEMS UNLIMITED INC",
        "SYSTEMS UNLIMITED INC"
    ],
    [
        "S¸VERKR¸P AUTOMOBILE GMBH",
        "S¸VERKR¸P AUTOMOBILE"
    ],
    [
        "TABLEAU SOFTWARE INC",
        "TABLEAU SOFTWARE INC"
    ],
    [
        "TAN PHAM LOC",
        "TAN PHAM LOC"
    ],
    [
        "TDK-LAMBDA AMERICAS",
        "TDK LAMBDA AMERICAS"
    ],
    [
        "TE CONNECTIVITY CORP",
        "TE CONNECTIVITY"
    ],
    [
        "TECHNICAL& EDUCATIONAL TRAINING AIDS INC",
        "TECHNICAL& EDUCATIONAL TRAINING AIDS"
    ],
    [
        "TEDI TECHNISCHE DIENSTE GMBH",
        "TEDI TECHNISCHE DIENSTE"
    ],
    [
        "TEEL PLASTICS CO., INC.",
        "TEEL PLASTICS"
    ],
    [
        "TEKNA PLASMA EUROPE SAS",
        "TEKNA PLASMA"
    ],
    [
        "TELEFLEX MEDICAL",
        "TELEFLEX MEDICAL"
    ],
    [
        "TENNESSEE DEPARTMENT OF *",
        "TENNESSEE DEPARTMENT OF"
    ],
    [
        "TENTE CASTERS, INC.",
        "TENTE CASTERS"
    ],
    [
        "TERESA CUSSEN GANNON",
        "TERESA CUSSEN GANNON"
    ],
    [
        "TESORO IN-STORE",
        "TESORO STORE"
    ],
    [
        "TEST B LANSADE",
        "TEST B LANSADE"
    ],
    [
        "TEST BIO",
        "TEST BIO"
    ],
    [
        "TEST BIO DESTRUCTIF",
        "TEST BIO"
    ],
    [
        "TEXACO CYNCAR CORP.",
        "TEXACO CYNCAR CORP."
    ],
    [
        "TG3 ELECTRONICS INC",
        "TG3 ELECTRONICS"
    ],
    [
        "THE STRAND",
        "THE STRAND"
    ],
    [
        "THE SURGICAL COMPANY INTERNATI",
        "THE SURGICAL INTERNATI"
    ],
    [
        "THE WIRE SHOP",
        "THE WIRE SHOP"
    ],
    [
        "THERMO ELECTRON SAS",
        "THERMO ELECTRON"
    ],
    [
        "THORO PACKAGING",
        "THORO PACKAGING"
    ],
    [
        "THREAD CHECK INC",
        "THREAD CHECK"
    ],
    [
        "TIGER DIRECT.COM",
        "TIGER DIRECT"
    ],
    [
        "TIXBO TIEFBOHR-CENTER GMBH",
        "TIXBO TIEFBOHR CENTER"
    ],
    [
        "TMD MACHINING INC",
        "TMD MACHINING"
    ],
    [
        "TOSHIBA AMERICA",
        "TOSHIBA"
    ],
    [
        "TOTAL CAYEY",
        "TOTAL CAYEY"
    ],
    [
        "TOTAL CENTRO",
        "TOTAL CENTRO"
    ],
    [
        "TOTAL MONTEHIEDRA",
        "TOTAL MONTEHIEDRA"
    ],
    [
        "TOTAL SERVICECENTER",
        "TOTAL SERVICECENTER"
    ],
    [
        "TOWER VIEW PRECISION",
        "TOWER VIEW PRECISION"
    ],
    [
        "TOWN OF FLOWER MOUND",
        "TOWN OF FLOWER MOUND"
    ],
    [
        "TREASURER OF STATE *",
        "TREASURER OF STATE"
    ],
    [
        "TRI TECH",
        "TRI TECH"
    ],
    [
        "TUCKER CREEK PRODUCT",
        "TUCKER CREEK PRODUCT"
    ],
    [
        "TUSTIN'S ASPHALT SEALING",
        "TUSTIN S ASPHALT SEALING"
    ],
    [
        "T▄V MEDIA GMBH",
        "TUV"
    ],
    [
        "TÜV RHEINLAND CONSULTING GMBH (LGA)",
        "TÜV RHEINLAND CONSULTING"
    ],
    [
        "T‹V MEDIA GMBH",
        "T‹V MEDIA"
    ],
    [
        "T▄V NORD AKADEMIE GMBH+CO.KG",
        "T▄V NORD AKADEMIE"
    ],
    [
        "T▄V NORD CERT GMBH",
        "T▄V NORD CERT"
    ],
    [
        "UL FOUNDATION",
        "UL FOUNDATION"
    ],
    [
        "ULTRA MACHINING COMPANY",
        "ULTRA MACHINING COMPANY"
    ],
    [
        "UNIVERSITY OF CALIFORNIA",
        "UNIVERSITY OF CALIFORNIA"
    ],
    [
        "UNIVERSITY OF UTAH",
        "UNIVERSITY OF UTAH"
    ],
    [
        "US CUSTOMS AND BORDER PROTECTION",
        "US CUSTOMS AND BORDER PROTECTION"
    ],
    [
        "USCUSTOMS TRUSTEDTRAVELER",
        "USCUSTOMS TRUSTEDTRAVELER"
    ],
    [
        "UTITEC INC-SPR-SPR",
        "UTITEC"
    ],
    [
        "UWS UMWELTMANAGEMENT GMBH",
        "UWS UMWELTMANAGEMENT"
    ],
    [
        "VALERO",
        "VALERO"
    ],
    [
        "VARI SALES CORPORATION *",
        "VARI SALES"
    ],
    [
        "VAUPELL MIDWEST MOLDING",
        "VAUPELL MIDWEST MOLDING"
    ],
    [
        "VDE MITGLIEDERSERVICE",
        "VDE MITGLIEDERSERVICE"
    ],
    [
        "VDSI VERBAND DEUTSCHER",
        "VDSI VERBAND DEUTSCHER"
    ],
    [
        "VEA - DIE ENERGIEMANAGER",
        "VEA DIE ENERGIEMANAGER"
    ],
    [
        "VELODROME SUISSE AG",
        "VELODROME"
    ],
    [
        "VENTURO TECHNOLOGIEN SWISS",
        "VENTURO TECHNOLOGIEN SWISS"
    ],
    [
        "VERLAG F¸R DIE DEUTSCHE WIRT",
        "VERLAG F¸R DIE DEUTSCHE WIRT"
    ],
    [
        "VNR VERLAG FÜR DIE DEUTSCHE",
        "VNR VERLAG FÜR DIE DEUTSCHE"
    ],
    [
        "VOLK-ZEICHENBÜRO",
        "VOLK ZEICHENBÜRO"
    ],
    [
        "W. FEHLMANN AG",
        "W FEHLMANN"
    ],
    [
        "WAYNE CTY AIRPORT",
        "WAYNE CTY AIRPORT"
    ],
    [
        "WEBER INSTRUMENTE GMBH",
        "WEBER INSTRUMENTE"
    ],
    [
        "WEBER SPECIALTIES CO",
        "WEBER SPECIALTIES"
    ],
    [
        "WEBER SPECIALTREL000",
        "WEBER SPECIALTIES"
    ],
    [
        "WELLS FARGO",
        "WELLS FARGO"
    ],
    [
        "WEST VIRGINIA BUSINESS REGISTRATION *",
        "WEST VIRGINIA BUSINESS REGISTRATION"
    ],
    [
        "WESTBAY INDUSTRIES",
        "WESTBAY INDUSTRIES"
    ],
    [
        "WEX BANK *",
        "WEX BANK"
    ],
    [
        "WFB WERBEARTIKEL HASLACH",
        "WFB WERBEARTIKEL HASLACH"
    ],
    [
        "WHR HOSSINGER GMBH",
        "WHR HOSSINGER"
    ],
    [
        "CANADA WIDE PARKING",
        "WIDE PARKING"
    ],
    [
        "WILBERT INC.",
        "WILBERT INC."
    ],
    [
        "WILHELM BOTT GMBH & CO.KG",
        "WILHELM BOTT"
    ],
    [
        "WILLY LANGE",
        "WILLY LANGE"
    ],
    [
        "WINDINGS INC.",
        "WINDINGS"
    ],
    [
        "WISCONSIN DEPT OF REVENUE",
        "WISCONSIN DEPT OF REVENUE"
    ],
    [
        "WISSEN. VERLAGS-GESELLSCHAFT",
        "WISSEN VERLAGS GESELLSCHAFT"
    ],
    [
        "WUESTHOFF & WUESTHOFF",
        "WUESTHOFF & WUESTHOFF"
    ],
    [
        "WYNDHAM GRAND RIO MAR BEA",
        "WYNDHAM GRAND RIO BEA"
    ],
    [
        "X-L ENGINEERING CORP.",
        "X L ENGINEERING"
    ],
    [
        "XP POWER INC. US",
        "XP POWER INCORPORATED"
    ],
    [
        "XP POWER INCORPORATED",
        "XP POWER INCORPORATED"
    ],
    [
        "XPEDX",
        "XPEDX"
    ],
    [
        "ZATKOFF SEALS REL000",
        "ZATKOFF SEALS"
    ],
    [
        "ZERR BENJAMIN",
        "ZERR BENJAMIN"
    ],
    [
        "ZGP ZÜRCHER GESELLSCHAFT",
        "ZGP ZÜRCHER GESELLSCHAFT"
    ],
    [
        "ZIHLMANN PROFILTECHNIK AG",
        "ZIHLMANN PROFILTECHNIK"
    ],
    [
        "ZIMMERMANN FLORIAN",
        "ZIMMERMANN FLORIAN"
    ],
    [
        "ZIMMERMANN PASCAL",
        "ZIMMERMANN PASCAL"
    ],
    [
        "ZKS ZEITKONTENSERVICE GMBH",
        "ZKS ZEITKONTENSERVICE"
    ]
];
