import './SupplierNormalization.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch} from '../../routing/routes';
import {useStores} from "../../stores";
import {Bag} from "../../services/classes/Bag";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {environment} from "../../env";
import {generateHomePath} from "../../routing/routing.utils";
import {ParentSuppliersCompare} from "./pages/parent-suppliers-compare/ParentSuppliersStep";
import {
    CmpBreakdownSingleVisualization,
    Data
} from "../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";
import {toCurrency} from "../../components/currency-component/CurrencyComponent";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

export const SupplierComparePage: React.FC = observer(() => {
    const {
        p,
        rootAppStore,
        bagStore,
        supplierNormalizationStore,
    } = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const bagId = bagStore.bagId;
    const bag = bagStore.bag as Bag;

    const mockDataSpendLg: Data = {
        top: {
            id: "1",
            total: 731,
            value: 11,
            valueTitle: "11",
            valueTitleHover: "1",
        },
        bottom: {
            id: "2",
            total: 379682112,
            value: 3977402,
            valueTitle: toCurrency(p.currencySymbol, 3977402, 3),
            valueTitleHover: toCurrency(p.currencySymbol, 3977402, 3,),
        },
    };


    const mockDataSpendEgg: Data = {
        top: {
            id: "1",
            total: 141,
            value: 9,
            valueTitle: "9",
            valueTitleHover: "9",
        },
        bottom: {
            id: "2",
            total: 9057077,
            value: 113033,
            valueTitle: toCurrency(p.currencySymbol, 113033, 3),
            valueTitleHover: toCurrency(p.currencySymbol, 113033, 3,),
        },
    };

    const optionsLeft = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: true,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }

    const optionsRight = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: false,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }

    // TODO: CAT-877: Split LG and SHV specific code
    useEffect(() => {
        if (!p.p.hardcodedCombinedBagId) throw new Error('No hardcodedCombinedBagId set');
        supplierNormalizationStore.init(p.p.hardcodedCombinedBagId, bag.taxonomy_size)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const activeState = supplierNormalizationStore.state;
    useEffect(() => {
        if (activeState === 'running') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('clean_parents');
            }, environment.aiResultFakeTimeout);
        }
        if (activeState === 'applying') {
            setTimeout(() => {
                supplierNormalizationStore.goToState('done');
            }, environment.aiResultFakeTimeout);
        }
    }, [activeState, supplierNormalizationStore]);

    return <KoiFacade
        title="Common Suppliers"
        intro={<></>}
        back={generateHomePath(routeMatch, {id: String(bagId)}, rootAppStore.app)}
        className="sup-norm-page">

        {/*TODO: CAT-877: We need to split off hardcoded data and move it to the profile store! (LG: CAT-879)*/}
        <Grid container justifyContent="center" style={{marginTop: '2em'}}>
            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <Typography variant="h5">Egg dataset</Typography>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <Typography variant="h5">Liberty Global (15 Jan)</Typography>
            </Grid>
            <Grid item md={2}></Grid>

            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <CmpBreakdownSingleVisualization
                    data={mockDataSpendEgg}
                    options={optionsLeft}
                />
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <CmpBreakdownSingleVisualization
                    data={mockDataSpendLg}
                    options={optionsRight}
                />
            </Grid>
            <Grid item md={2}></Grid>
        </Grid>
        <ParentSuppliersCompare/>

    </KoiFacade>
});
