import {action, makeAutoObservable} from "mobx";
import {ServerConfig} from "../services/ApiTypes";
import MithraApi from "../services/MithraApi";
import {Bag} from "../services/classes/Bag";

export default class DebugStore {
    constructor(private api: MithraApi) {
        makeAutoObservable(this)
    }

    config?: ServerConfig;

    ping = false;

    bags: Bag[] = [];

    public fetch() {
        Promise.all([
            this.api.getServerConfig(),
            // this.api.getServerPing(),
            this.api.getDataBagList(),
        ]).then(
            action('DebugStore.success', ([sc, js]) => {
                this.config = sc.data;
                // this.ping = p.status === 200;
                this.bags = js.data.reverse();
            }),
            action('DebugStore.reject', console.error)
        )
    }
}
