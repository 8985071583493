import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";
import {JobRouteMatch, MithraHistoryState} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {MainComponent} from "../../components/main/MainComponent";
import {LoadingBarComponent} from "../../components/loading-bar/LoadingBarComponent";
import {NotFound} from "../../routing/NotFound";
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {HeaderWrapper} from "./HeaderWrapper";

export const MultiEnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {rootAppStore, bagStore, bagLoadingStore, cmpStore} = useStores();
    const location = useLocation<MithraHistoryState>();
    const match = useRouteMatch<JobRouteMatch>();
    const history = useHistory();

    // TODO: See if this can be optimized, probably by the usage of react Context
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => rootAppStore.setApp(state), [])

    useEffect(() => {
        const estBagId = bagLoadingStore.initBag(location, history, match)
        if (!estBagId) {
            console.warn('Could not initialize master bag', estBagId);
            return;
        }
        const masterBagId = cmpStore.determineMasterBag({id: estBagId});
        if (masterBagId) {
            cmpStore.initBags(masterBagId)
        } else {
            cmpStore.setMissingMasterBag()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const bag = bagStore.bag;
    useEffect(() => {
        cmpStore.setCompareBag(bag);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bag?.id])

    // if(!rootAppStore.routeApp) return <></>;

    const desiredMasterBagStr = cmpStore.desiredMasterBagId ? `(${cmpStore.desiredMasterBagId}) ` : '';
    const masterError = `Could not find Master data ${desiredMasterBagStr}on the server anymore`;

    // console.debug('Re-rendering MultiEnvWrapper', state.dashEnv)
    return <MainComponent state={state} header={<HeaderWrapper state={state}/>}>
        <LoadingBarComponent/>
        {
            bagLoadingStore.bagNotFound
                ? <NotFound msg={"Could not find data on the server anymore"}/>
                : cmpStore.masterBagNotFound
                    ? <NotFound msg={masterError}/>
                    : bagStore.bag && cmpStore.masterBag
                        ? children
                        : <div className="loading-spinner">
                            <LoadingSpinnerPie/>
                        </div>
        }
    </MainComponent>;
});
