import {Typography} from "@mui/material";
import React from "react";

export const SpendConcentrationHelp = () => <>
    <Typography color="textSecondary" gutterBottom>
        Spend Concentration
    </Typography>
    <Typography component="p">
        The lower spend concentration percentage is the higher the potential captive value
    </Typography>
</>;
