import React from "react";
import './IconStyles.scss';

// Mithra-AI Solutions\CAT-Product - Documents\03-UI-UX Design\Resources\FE Icons\noun-classification-260814-183250 (1).svg
export const ClassificationIcon: React.FC = () => <svg width={50} height={50} viewBox="0 0 1200 1200"
                                                       className="ClassificationIcon icon">
    <g fill="#18314f">
        <path
            d="m604.8 580.8c81.602 0 151.2-56.398 171.6-132h254.4l-225.6-390-111.6 192c-26.398-15.602-56.398-24-88.801-24-14.398 0-27.602 1.1992-40.801 4.8008l0.003906-136.8h-390v388.8h272.4c30 57.598 88.801 97.199 158.4 97.199zm200.4-440.4 154.8 267.6h-178.8v-4.8008c0-50.398-21.602-96-55.199-128.4zm-99.598 172.8c21.602 24 34.801 56.398 34.801 91.199v4.8008h-90zm-100.8-45.598c25.199 0 49.199 7.1992 68.398 19.199l-93.602 163.2h153.6c-19.199 52.801-69.602 91.199-128.4 91.199-45.602 0-85.199-21.602-110.4-56.398h68.398v-211.2c13.199-3.6016 27.598-6 42-6zm-136.8 136.8c0-44.398 21.602-84 54-109.2v148.8h-48c-3.6016-13.199-6-26.398-6-39.602zm-253.2 39.602v-308.4h307.2v110.4c-56.398 30-94.801 88.801-94.801 157.2 0 13.199 1.1992 26.398 4.8008 39.602h-217.2z"/>
        <path
            d="m604.8 836.4c-82.801 0-151.2 67.199-151.2 151.2 0 82.801 67.199 151.2 151.2 151.2 82.801 0 151.2-67.199 151.2-151.2-1.1992-83.996-68.398-151.2-151.2-151.2zm0 258c-58.801 0-106.8-48-106.8-106.8 0-58.801 48-106.8 106.8-106.8s106.8 48 106.8 106.8c0 58.801-48 106.8-106.8 106.8z"/>
        <path d="m590.4 596.4h27.602v226.8h-27.602z"/>
        <path
            d="m535.2 660 28.801-54-24-13.199-28.801 54c-16.801 31.199-49.199 49.199-84 49.199h-88.801c-52.801 0-98.398 33.602-116.4 82.801l-13.199 39.602 25.199 8.3984 13.199-39.602c13.199-38.398 49.199-64.801 90-64.801h88.801c46.801 1.2031 87.602-22.797 109.2-62.398z"/>
        <path
            d="m960 788.4 13.199 39.602 25.199-8.3984-13.199-39.602c-16.801-49.199-63.602-82.801-116.4-82.801h-88.801c-34.801 0-67.199-19.199-84-49.199l-28.801-54-24 13.199 28.801 54c21.602 39.602 62.398 63.602 108 63.602h88.801c42-1.1992 78 25.199 91.199 63.598z"/>
        <path d="m84 1126.8h280.8v-279.6h-280.8zm40.801-238.8h199.2v199.2h-199.2z"/>
        <path d="m990 831.6-174 300h348zm0 81.598 103.2 178.8h-206.4z"/>
    </g>
</svg>
