import './SynergySuppliersPage.scss';
import {DataColumn} from "../../../components/data-table/DataTable";

export const HARDCODED_COMMON_SUPPLIERS = [
    {
        s__parent_name: 'ECS', s__name: 'East Coast Supplier',
        a: {s__spend: 73_747.87}, b: {s__spend: 44_468.14}, combined: {s__spend: 73_747.87 + 44_468.14}
    },
    {
        s__parent_name: 'WCS', s__name: 'West Coast Supplier',
        a: {s__spend: 73_747.87}, b: {s__spend: 44_468.14}, combined: {s__spend: 73_747.87 + 44_468.14}
    },
    {
        s__parent_name: 'Google', s__name: 'Google Cloud Service Provider',
        a: {s__spend: 73_747.87}, b: {s__spend: 44_468.14}, combined: {s__spend: 73_747.87 + 44_468.14}
    },
]

export const HARDCODED_SELECTED_SUPPLIER = {
    s__parent_name: 'ECS',
    s__name: 'East Coast Supplier',
    a: {
        categories: [
            ['Metals', 'Other',],
            ['Metals', 'Sheets',],
            ['Plastics', 'Other',],
        ],
        parts: [
            {p__name: 'P4124', part__l1: 'Metals', part__l2: 'Other', p__spend: 24582.62},
            {p__name: 'P42313', part__l1: 'Metals', part__l2: 'Sheets', p__spend: 391},
            {p__name: 'P42314', part__l1: 'Metals', part__l2: 'Sheets', p__spend: 391},
            {p__name: 'P42315', part__l1: 'Metals', part__l2: 'Sheets', p__spend: 391},
            {p__name: 'P42316', part__l1: 'Metals', part__l2: 'Sheets', p__spend: 391},
            {p__name: 'AXY67', part__l1: 'Plastics', part__l2: 'Other', p__spend: 47601.25},
        ]
    },
    b: {
        categories: [
            ['Metals', 'Other',],
            ['Metals', 'Sheets',],
            ['Metals', 'Nails',],
        ],
        parts: [
            {p__name: 'P322', part__l1: 'Metals', part__l2: 'Other', p__spend: 8893.61},
            {p__name: 'AX54', part__l1: 'Metals', part__l2: 'Sheets', p__spend: 9369.51},
            {p__name: 'AX55', part__l1: 'Metals', part__l2: 'Sheets', p__spend: 9369.51},
            {p__name: 'AX56', part__l1: 'Metals', part__l2: 'Sheets', p__spend: 9369.51},
            {p__name: 'AXY67', part__l1: 'Metals', part__l2: 'Sheets', p__spend: 7466},
        ]
    },
}

const HARDCODED_SUPPLIER_DATA_1 = [
    {s__parent_name: 'ECS', s__name: 'East Coast Supplier', s__spend: 73_747.87,},
    {s__parent_name: 'WCS', s__name: 'West Coast Supplier', s__spend: 73_747.87,},
    {s__parent_name: 'Google', s__name: 'Google Cloud Service Provider', s__spend: 73_747.87,},
]
const HARDCODED_SUPPLIER_DATA_2 = [
    {s__parent_name: 'WCS', s__name: 'West Coast Supplier', s__spend: 44_468.14,},
    {s__parent_name: 'Gaia', s__name: 'Gaia Inc.', s__spend: 4556.14,},
    {s__parent_name: 'Google', s__name: 'Google Cloud Service Provider', s__spend: 44_468.14,},
]
const HARDCODED_SUPPLIER_DATA_3 = [
    {s__parent_name: 'NCS', s__name: 'North Coast Supplier', s__spend: 44_786.14,},
    {s__parent_name: 'ECS', s__name: 'East Coast Supplier', s__spend: 31_415.14,},
    {s__parent_name: 'Amazon', s__name: 'Amazon Provider', s__spend: 44_111.14,},
]

export const SELECTION_COLUMNS: { parts: DataColumn[], suppliers: DataColumn[] } = {
    parts: [
        {dataIndex: 'p__name', headerText: 'Part name', ltr: true,},
        {dataIndex: 'p__spend', headerText: 'Spend', dataCategory: 'currency',},
    ],
    suppliers: [
        {dataIndex: 's__parent_name', headerText: 'Parent supplier', ltr: true,},
        {dataIndex: 's__name', headerText: 'Supplier name', ltr: true,},
        {dataIndex: 's__spend', headerText: 'Spend', dataCategory: 'currency',},
    ]
};
export const SELECTION_1_DATA = {
    parts: HARDCODED_SELECTED_SUPPLIER.a.parts.map(({p__name, p__spend}) => ({p__name, p__spend})),
    suppliers: HARDCODED_SUPPLIER_DATA_1,
};
export const SELECTION_2_DATA = {
    parts: HARDCODED_SELECTED_SUPPLIER.a.parts.map(
        ({p__name, p__spend}) => ({p__name, p__spend: p__spend * Math.random()})
    ),
    suppliers: HARDCODED_SUPPLIER_DATA_2,
};
export const SELECTION_3_DATA = {
    parts: HARDCODED_SELECTED_SUPPLIER.b.parts.map(({p__name, p__spend}) => ({p__name, p__spend})),
    suppliers: HARDCODED_SUPPLIER_DATA_3,
};
