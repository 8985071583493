import {TaxonomyCategory} from "../../stores/CategorizationReviewStore";
import {makeAutoObservable} from "mobx";
import {Categories} from "../../services/classes/AiClasses";

type OnSelect = (newCats: Categories) => void;

export class PartReCategorizationControllerStore {
    anchor: HTMLButtonElement | undefined

    private onSelect?: OnSelect

    constructor(public taxonomy: TaxonomyCategory[]) {
        makeAutoObservable(this, {
            setOnSelect: false,
            onSelectCategory: false,
        })
    }

    close() {
        this.anchor = undefined
        console.log('close:', Boolean(this.anchor))
    }

    open(anchor: HTMLButtonElement) {
        this.anchor = anchor
        console.log('open:', Boolean(this.anchor))
    }

    get isOpen() {
        return Boolean(this.anchor)
    }

    setOnSelect(onSelect: OnSelect) {
        this.onSelect = onSelect
    }

    onSelectCategory(newCats: string[]) {
        console.log('onSelectCategory', !!this.onSelect, newCats)
        if (this.onSelect) {
            this.onSelect(newCats)
        }
        this.close()
    }
}
