import React from "react";
import {BarDataPoint} from "../../../../components/visualization/BarChart";
import {BarChartV2, Options,} from "../../../../components/visualization/bar-chart/BarChart2";

const options: Partial<Options> = {
    vertical: false,
    width: 900,
    height: 'auto',
    redLine: .8,
    labelMargin: 300, // Of the total width
    valueLabelPxH: 26,
    valueAxisPercentage: true,
    hideAxis: true,
    padding: 'auto',
    barPadding: 0,
    redLineText1: '80%',
    redLineText2: '(of total spend with top 20% suppliers)',
}

type Props = {
    data?: BarDataPoint[],
    setSelected: (d: BarDataPoint) => void,
}
export const ParetoOverviewVisualization: React.FC<Props> = ({data, setSelected}) => {

    return <section className="pareto-overview-visualization">
        {data &&
            <BarChartV2 data={data} options={{
                ...options,
                onClicked: setSelected,
            }}/>
        }
    </section>
}


