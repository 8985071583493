import {CategorizationStore} from "../CategorizationStore";
import {BagStore} from "../BagStore";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import ProfileStore from "../ProfileStore";
import {makeAutoObservable} from "mobx";
import {RequestManager} from "../managers/RequestManager";
import {ApplyTaxonomyMappingRequestData} from "../../services/classes/MaterializedClasses";
import {forkJoin, from, interval, map, switchMap} from "rxjs";
import {TaxonomyMapperStore} from "../../pages/taxonomy-mapper/store/TaxonomyMapperStore";
import {AiManagerStore} from "../AiManagerStore";
import moment from "moment";

export const AI_OPTIONS = [
    'base' as const,
    'trained_single' as const,
    // 'trained_combined' as const,
];

export type AiOption = typeof AI_OPTIONS[number];

const EST_AI_RUN_TIME = 5 * 60; // in seconds

/**
 * TODO[integration]: Fix taxonomy size here
 */
const TAXONOMY_SIZE = 6;

export class ApplyTaxonomyMappingAiDelegate {
    readonly runSync = new RequestManager<ApplyTaxonomyMappingRequestData, any>(
        // data => {
        //     return this.categorizationStore.reInitializeV2(402)
        // }
        data => from(this.api.applyTaxonomyMappingAi(data))
            // timer(3000)
            .pipe(switchMap(resp => {
                console.log('Taxonomy mapping applied to dataset', resp.data);
                const databag = resp.data.dst_databag
                return from(this.api.materializeReview({databag, clear: true})).pipe(
                    switchMap(() => this.categorizationStore.reInitializeV2(databag, TAXONOMY_SIZE))
                );
            }))
    )
    readonly timer = interval(1000).pipe(
        map(i => (i + 1) / EST_AI_RUN_TIME),
    )
    readonly debugDeleteResults = new RequestManager<number, any>(databag => forkJoin([
        from(this.api.debugDeleteAiClassification(databag)),
        from(this.api.debugDeleteReviewMaterialization(databag)),
    ]))

    public progress = 0;
    public estCompletionTime: moment.Moment | undefined = undefined;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private aiManager: AiManagerStore,
        private categorizationStore: CategorizationStore,
        private api: MithraMaterializedApi,
        private profileStore: ProfileStore,
        private bagStore: BagStore,
        private taxonomyMapStore: TaxonomyMapperStore,
    ) {
        makeAutoObservable(this)
    }

    get startAiJobRequest(): ApplyTaxonomyMappingRequestData | undefined {
        const src_mat_taxonomy = this.taxonomyMapStore.baseMatTaxonomy?.id
        const dst_mat_taxonomy = this.taxonomyMapStore.targetMatTaxonomy?.id
        const map_src_classification_databag = this.bagStore.bag?.baseline;
        const map_dst_classification_databag = this.bagStore.bagId;
        if (src_mat_taxonomy && dst_mat_taxonomy && map_src_classification_databag && map_dst_classification_databag) {
            return {
                src_mat_taxonomy,
                dst_mat_taxonomy,
                map_src_classification_databag,
                map_dst_classification_databag,
            }
        }
        return undefined;
    }

    canStartAiJob() {
        if (this.profileStore.p.disableRunningOfModels) return false;
        return Boolean(this.startAiJobRequest)
    }

    startAiJob() {
        const data = this.startAiJobRequest;
        if (!data) return
        this.progress = 0;
        this.estCompletionTime = moment().add(EST_AI_RUN_TIME, 'seconds');
        const s = this.runSync.request(data);
        s.add(this.timer.subscribe({
            next: p => this.setProgress(p * 100),
        }))
        s.add(() => this.setProgressDone())
    }

    setProgress(p: number) {
        if (p >= 100) {
            this.progress = 100
            this.estCompletionTime = undefined;
        } else {
            this.progress = p;
        }
    }

    setProgressDone() {
        this.progress = 100
        this.estCompletionTime = undefined;
    }
}
