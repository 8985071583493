import {BagStore} from "../BagStore";
import ProfileStore from "../ProfileStore";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {CommonSuppliersDelegate} from "./CommonSuppliersDelegate";

export class SynergyStore {
    requestedSynergyId?: number = this.profileStore.p.hardcodedSynergyId || 1;

    readonly commonSuppliersDelegate = new CommonSuppliersDelegate(this, this.bagStore, this.matApi, this.profileStore)

    // noinspection JSUnusedLocalSymbols
    constructor(
        private bagStore: BagStore,
        private profileStore: ProfileStore,
        private matApi: MithraMaterializedApi,
    ) {
        makeAutoObservable(this)
    }
}
