import {makeAutoObservable, reaction} from "mobx";
import {from, Subscription} from "rxjs";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {CommonSuppliersDelegate} from "./CommonSuppliersDelegate";
import {PageResponseManager} from "../managers/PageResponseManager";
import {
    CommonSupplierReviewGroupRowState,
    CommonSupplierReviewMemberRowState,
    ViewMCSGroupRowSerializer,
    ViewMCSMemberRowSerializer
} from "../../pages/supplier-normalization/pages/SupplierNormalization.classes";
import {SynergyStore} from "./SynergyStore";
import {CommonSupplierFilterDelegate} from "./CommonSupplierFilterDelegate";

export type CommonSupplierQueryFilter = {
    group_search: string | undefined;
    showStandaloneSuppliers: boolean
}

/**
 * A copy of ParentSupplierReviewDelegate
 */
export class CommonSuppliersReviewDelegate {
    readonly filterDelegate = new CommonSupplierFilterDelegate(this, this.synergyStore);

    readonly _commonSupplierGroupRequest = new PageResponseManager<{
        synergy: number,
        filter: CommonSupplierQueryFilter
    }, CommonSupplierReviewGroupRowState, ViewMCSGroupRowSerializer>(
        20, // TODO: Page size ignored by PageResponseManager
        (page, {synergy, filter}) => this.api.listCommonSupplierGroups(synergy, page, filter),
        (d) => {
            const row: CommonSupplierReviewGroupRowState = {
                ...d,
                type: 'group',
                subRows: null, // Will be filled later
            }
            return row;
        }
    )
    isLoadingSubRows = false;
    subRowsSubscription: Subscription | undefined;
    allOpen: null | boolean = false;

    // noinspection JSUnusedLocalSymbols
    constructor(
        private readonly api: MithraMaterializedApi,
        private readonly commonSuppliersDelegate: CommonSuppliersDelegate,
        private readonly synergyStore: SynergyStore,
    ) {
        makeAutoObservable(this);
        reaction(() => [
            this._commonSupplierGroupRequest.data,
        ] as const, ([commonSupplierGroupRows]) => {
            if (this.subRowsSubscription) this.subRowsSubscription.unsubscribe();
            const synergy = this.synergyStore.requestedSynergyId;
            if (!synergy || !commonSupplierGroupRows) {
                this.setSubRowData([]);
                this.subRowsSubscription = undefined;
                return;
            }
            const rowIds = commonSupplierGroupRows.map(row => row.id);
            if (rowIds.length === 0) {
                this.setSubRowData([]);
                this.subRowsSubscription = undefined;
                return;
            }
            this.subRowsSubscription = from(this.api.listCommonSupplierMembers(synergy, rowIds)).subscribe({
                next: resp => this.setSubRowData(resp.data),
                complete: () => console.log('listSupplierReviewRows complete'),
            });
        })
    }

    toggleAllOpen() {
        this.allOpen = !this.allOpen;
    }

    setAllOpen(allOpen: boolean | null) {
        this.allOpen = allOpen;
    }

    get isLoadingRows() {
        return this._commonSupplierGroupRequest.isLoading
        // || this.supplierNormalizationStore.ps._parentSupplierManager.busy;
    }

    get isProcessing() {
        // return this.storeSupplierReviewUpdates.hasInPipe;
        return false;
    }

    setSubRowData(subRowData: ViewMCSMemberRowSerializer[]) {
        console.time('CommonSuppliersReviewDelegate setSubRowData()');
        // Collect the part data grouped by supplier
        const groupDatas = this._commonSupplierGroupRequest.data;

        const groupIndices = new Map(groupDatas?.map((d, i) => ([d.id, i])));
        const groupedSubRows = new Map<number, CommonSupplierReviewMemberRowState[]>();
        for (const d of subRowData) {

            // interpret data
            const row: CommonSupplierReviewMemberRowState = {
                ...d,
                // (optional) add other fields here
                type: 'member',
                group_row: undefined as any,  // Defined further below
                // match: foundParentMatch || null
            }

            const groupRowId = row.group_row_id
            const ds = groupedSubRows.get(groupRowId)
            if (ds) {
                ds.push(row)
            } else {
                groupedSubRows.set(groupRowId, [row])
            }
        }

        // Apply to the view
        groupedSubRows.forEach((subRows, groupKey) => {
            const i = groupIndices.get(groupKey);
            if (i === undefined) return;
            if (!groupDatas) return;
            const groupData = groupDatas[i];
            if (!groupData) return

            subRows.forEach(p => p.group_row = groupData) // Double link it for easy updating
            groupData.subRows = subRows;
            // (optionally) Calculate more fields of the group row
        })
        this.isLoadingSubRows = false;
        console.log(`setSubRowData() of ${subRowData.length} parts`);
        console.timeEnd('CommonSuppliersReviewDelegate setSubRowData()');
    }
}
