import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {
    PartReCategorizationControllerStore
} from "../../../components/categorization/PartReCategorizationControllerStore";
import {PartReCategorizationHandler} from "../../../components/categorization/PartRecategorizationHandler";
import {Button} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";

export const ChangeCategoryFooterButton: React.FC = observer(() => {
    const {categorizationStore, categorizationReviewStore} = useStores();

    const controller: any = useMemo(() => {
        if (!categorizationReviewStore.categories.taxonomy_categories) {
            if (categorizationReviewStore.categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categorizationReviewStore.categories.taxonomy_categories);
    }, [categorizationReviewStore.categories.taxonomy_categories])

    return <>
        {controller && <PartReCategorizationHandler
            cmpId="review-filter-footer"
            controller={controller}
            label="Change the category"
        />}
        {categorizationStore.data.anySelected && <div id="review-filter-footer">
            <Button
                variant="contained"
                endIcon={<KeyboardArrowDown/>}
                onClick={event => {
                    const anchor = event.currentTarget;
                    controller.setOnSelect((newCats) => {
                        categorizationReviewStore.clickRecatSelectedParts(newCats);
                    })
                    controller.open(anchor)
                }}
            >
                Overwrite selection
            </Button>
        </div>}
    </>
})
