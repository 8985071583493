import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {LinearProgress} from "@mui/material";

export const LoadingBarComponent: React.FC = observer(() => {
    const {loadingBarStore} = useStores();
    const state = loadingBarStore.state;
    if (state.state === 'determinate') {
        return <LinearProgress variant={"determinate"} value={state.progress}/>
    } else if (state.state === 'indefinite') {
        return <LinearProgress variant={"indeterminate"}/>;
    } else {
        return <></>
    }
});
