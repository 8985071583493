import '../../../../../components/data-table/DataTable.scss';
import '../CategorizationReviewTable.scss';
import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../stores";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {MithraTablePaginationActions} from '../../../../../components/table-pagination/MithraTablePaginationActions';
import {GroupedCategorizationReviewMainRow} from "./GroupedCategorizationReviewMainRow";
import {
    PartReCategorizationControllerStore
} from "../../../../../components/categorization/PartReCategorizationControllerStore";
import {PartReCategorizationHandler} from "../../../../../components/categorization/PartRecategorizationHandler";
import {MithraTableHeadColumns} from "../../../../../components/table/MithraTableHeadColumns";
import {getCatReviewColumns} from "../columns";
import {ChangeCategoryFooterButton} from "../../ChangeCategoryFooterButton";

export const GroupedCategorizationReviewMainTable: React.FC = observer(() => {
    const {p, categorizationStore, categorizationReviewStore} = useStores()
    const COLUMNS = getCatReviewColumns(p);

    const controller: any = useMemo(() => {
        if (!categorizationReviewStore.categories.taxonomy_categories) {
            if (categorizationReviewStore.categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categorizationReviewStore.categories.taxonomy_categories);
    }, [categorizationReviewStore.categories.taxonomy_categories])

    const supplierPages = categorizationStore.data.supplierPages;
    // let abbreviation: CurrencyAbbreviation = NO_ABBREVIATION;
    // if (supplierPages.data) {
    //     const spends = supplierPages.data.map(d => getSpend(d))
    //     if (spends.length > 0)
    //         abbreviation = findAbbreviationOfGroup(spends)
    // }
    // const data = undefined as any as any[];
    const isLoading = categorizationStore.data.isLoadingParts;
    const tableClass = 'mithra-table x-design categorization-review-table grouped-categorization-review-table x-design-narrow' + (isLoading ? ' loading' : '');
    const data = supplierPages.data;
    return <TableContainer className={tableClass}>
        {controller && <PartReCategorizationHandler controller={controller}/>}
        <Table cellPadding="0">
            <MithraTableHeadColumns columns={COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody className="categorization-review-table-body">
                {data ? <>
                        {data.map(row => <React.Fragment key={row.id}>
                            {/*<CurrencyAbbreviationContext.Provider value={abbreviation}>*/}
                            <GroupedCategorizationReviewMainRow row={row} controller={controller}/>
                            {/*</CurrencyAbbreviationContext.Provider>*/}
                        </React.Fragment>)}
                        {data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TableCell colSpan={3}>
                        <ChangeCategoryFooterButton/>
                    </TableCell>
                    <TablePagination
                        //
                        rowsPerPageOptions={[]}
                        colSpan={COLUMNS.length - 3}
                        count={supplierPages.count}
                        rowsPerPage={supplierPages.pageSize}
                        page={supplierPages.page - 1}
                        // SelectProps={{
                        //     inputProps: {'aria-label': 'rows per page'},
                        //     native: true,
                        // }}
                        // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                        onPageChange={(e, page) => supplierPages.changePage(page + 1)}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
