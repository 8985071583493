import React from "react";
import {Typography} from "@mui/material";
import {AcceptButtons} from "./AcceptButtons";
import {CombinedReviewState} from "../../stores/CategorizationReviewStore";

type Props = {
    onAccept?: () => void
    state: CombinedReviewState
}
export const CombinedReviewButtons: React.FC<Props> = ({onAccept, state}) => {
    const isAccept = state.btn === 'accept';
    let extra: JSX.Element;
    switch (state.extra) {
        case "waiting_approval":
            extra = <Typography>Waiting approval</Typography>
            break;
        case "all_approved_accepted":
            extra = <Typography>All approved</Typography>
            break;
        case "all_approval_rejected":
            extra = <Typography>Rejected</Typography>
            break;
        case "mixed_states":
            extra = <Typography>Mixed</Typography>
            break;
        case "all_approval_prev_rejected":
            extra = <Typography>Previously rejected</Typography>
            break;
        case "":
            extra = <></>;
            break;
    }
    return <>
        {state.btn !== 'hidden' &&
            <AcceptButtons
                isAccept={isAccept}
                onAccept={onAccept}
                byOther={state.reviewedByOther}
            />
        }
        {extra}
    </>
}
