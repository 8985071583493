import React from "react";
import {observer} from "mobx-react-lite";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {CategorizationReviewSubRow} from "./CategorizationReviewSubRow";
import {useStores} from "../../../../stores";
import {SomeMatSupplierReviewRowState} from "../../../../services/classes/MatReviewClasses";
import {
    CurrencyAbbreviation,
    findAbbreviationOfGroup,
    NO_ABBREVIATION
} from "../../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";

type Props = {
    data: SomeMatSupplierReviewRowState
    controller?: PartReCategorizationControllerStore // TODO: Move to context
};
export const CategorizationReviewSubTable: React.FC<Props> = observer(({data, controller}) => {
    const {p} = useStores();

    let abbreviation: CurrencyAbbreviation;
    if (p.p.hardcodedKPIAbbreviation) {
        abbreviation = p.p.hardcodedKPIAbbreviation;
    } else {
        abbreviation = NO_ABBREVIATION;
        if (data.parts) {
            const spends = data.parts.map(d => d.p_spend)
            if (spends.length > 0)
                abbreviation = findAbbreviationOfGroup(spends)
        }
    }

    return <Table
        className="sub-table"
        size="small">
        <TableHead>
            <TableRow>
                <TableCell className="col-p_name">{p.p.p_name_col_name || '-'}</TableCell>
                <TableCell className="col-p_spend">Amount</TableCell>

                {
                    p.p.p_description_col_name !== false &&
                    <TableCell className="col-p_description">{p.p.p_description_col_name}</TableCell>
                }

                {p.partContextColNames.map(({dataField, colName}) =>
                    <TableCell key={dataField} className={"col-" + dataField}>{colName}</TableCell>
                )}

                <TableCell className="col-p_category_l">Category</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {data.parts?.map(part =>
                <React.Fragment key={part.id}>
                    <CurrencyAbbreviationContext.Provider value={abbreviation}>
                        <CategorizationReviewSubRow part={part} controller={controller}/>
                    </CurrencyAbbreviationContext.Provider>
                </React.Fragment>
            )}
        </TableBody>
    </Table>
})
