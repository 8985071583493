// TODO: Move to company specific settings
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {range} from "d3";
import {getHardcodedLabel} from "../../../components/visualization/collapsible-indent-tree/labelmapping";


export const TaxonomyMapperReviewTable: React.FC = observer(() => {
    const {taxonomyMapperStore} = useStores();

    const isLoading = taxonomyMapperStore.mapper._catMapRequestManager.busy;
    const tableClass = 'mithra-table x-design' + (isLoading ? ' loading' : '');

    const matchData = taxonomyMapperStore.mapper._catMapRequestManager.result;

    const LEFT_TAXONOMY_SIZE = 4;
    const RIGHT_TAXONOMY_SIZE = 7;

    const LEFT_COLS = range(LEFT_TAXONOMY_SIZE).map(i => `source_l${i + 1}` as 'source_l1');
    const RIGHT_COLS = range(RIGHT_TAXONOMY_SIZE).map(i => `destination_l${i + 1}` as 'destination_l1');
    const N_COLS = LEFT_COLS.length + RIGHT_COLS.length;

    return <TableContainer className={'taxonomy-mapper-review-table ' + tableClass}>
        <Table cellPadding="0">
            <TableHead>
                <TableRow>
                    {LEFT_COLS.map((col, i) =>
                        <TableCell key={col} className="col-category col-left">
                            l{i + 1}
                        </TableCell>
                    )}
                    <TableCell className="col-result">
                        Source
                    </TableCell>
                    {RIGHT_COLS.map((col, i) =>
                        <TableCell key={col} className="col-category col-left">
                            l{i + 1}
                        </TableCell>
                    )}
                </TableRow>
                <tr>
                    <td className="col-spacer-left" colSpan={LEFT_COLS.length}/>
                    <td className="col-spacer-right" colSpan={RIGHT_COLS.length + 1}/>
                </tr>
            </TableHead>
            <TableBody className="taxonomy-mapper-review-table-body">
                <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
                    <td colSpan={N_COLS}>
                        {isLoading && <LinearProgress variant="indeterminate"/>}
                    </td>
                </tr>
                {matchData ?
                    <>{matchData.map(row => {
                        // Table matchData
                        return <TableRow key={row.id}>
                            {/*<TableCell className="col-dropdown" onClick={() => setOpen(!open)}>*/}
                            {/*    {canOpen &&*/}
                            {/*        <IconButton aria-label="expand row" size="small">*/}
                            {/*            {open ? <ExpandMore/> : <KeyboardArrowRight/>}*/}
                            {/*        </IconButton>*/}
                            {/*    }*/}
                            {/*    {nParts}*/}
                            {/*</TableCell>*/}

                            {LEFT_COLS.map(col => <TableCell key={col} className="col-category col-left">
                                {getHardcodedLabel(row[col])}
                            </TableCell>)}

                            <TableCell className="col-result">
                                {row.ai_suggestion ? 'Ai' : 'Manual'}
                            </TableCell>

                            {RIGHT_COLS.map(col => <TableCell key={col} className="col-category col-right">
                                {getHardcodedLabel(row[col])}
                            </TableCell>)}
                            {/*<TableCell className="col-s_spend">*/}
                            {/*    <CurrencyAbbreviationContext.Consumer>{abbreviation => {*/}
                            {/*        return <CurrencyComponent v={spend} abbreviation={abbreviation}/>;*/}
                            {/*    }*/}
                            {/*    }</CurrencyAbbreviationContext.Consumer>*/}
                            {/*</TableCell>*/}
                        </TableRow>;
                    })}{matchData.length === 0 &&
                        <TableRow className="no-data">
                            <TableCell colSpan={N_COLS}>
                                No data
                            </TableCell>
                        </TableRow>
                    }</>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
        </Table>
    </TableContainer>
});
