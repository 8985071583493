import {environment} from "../../env";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {Bag} from "../../services/classes/Bag";
import {SelectProps} from "@mui/material/Select/Select";
import Select from "@mui/material/Select";

type Props = SelectProps & {
    bags: Bag[]
};
export const SelectDatasetMenu: React.FC<Props> = ({bags, ...selectProps}) =>
    <Select {...selectProps}>
        {environment.production
            // https://github.com/mui/material-ui/issues/16181
            ? [[
                bags.filter(b => !b.hidden)
                    .map(bag => <MenuItem key={bag.id} value={bag.id}>
                            {bag.name}
                        </MenuItem>
                    )
            ]] : [[
                bags.map(bag =>
                    <MenuItem key={bag.id} value={bag.id}>
                        {bag.hidden ? '(H) ' : ''}[{bag.id}] {bag.name}
                    </MenuItem>
                )
            ]]
        }
    </Select>
