import React from "react";
import {observer} from "mobx-react-lite";
import {Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {ExpandMore, KeyboardArrowRight} from "@mui/icons-material";
import {CommonSupplierData, SupplierComparePartData} from "../hardcoded/hardcoded_dpw_types";

const ROOT_TABLE_COLS = 2; // excl dropdown element

function getSpend(d: CommonSupplierData, is_master: boolean) {
    return is_master ? d.spend_master_ds : d.spend_cmp_ds
}

type Props = {
    data: Array<CommonSupplierData>
    is_master: boolean
};
export const SynergyCategoryDetailTable: React.FC<Props> = observer(({data, is_master}) => {
    const totalSpend = data.reduce((sum, v) =>
            sum + getSpend(v, is_master),
        0,
    )
    const d = [...data];
    d.sort((a, b) => getSpend(b, is_master) - getSpend(a, is_master));
    return <TableContainer className="mithra-table x-design synergy-categories-cmp-table">
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className="col-dropdown"/>
                    <TableCell className="col-s_name">
                        Supplier ({d.length})
                    </TableCell>
                    <TableCell className="col-s_spend">
                        Spend (<CurrencyComponent v={totalSpend}/>)
                    </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {d.map((row, i) =>
                        <SynergyCategoryMainRow key={i} data={row} is_master={is_master}/>
                    )}
                </TableBody>
            </Table>
        </TableContainer>;
    }
)

type RowProps = {
    data: CommonSupplierData
    is_master: boolean
};
const SynergyCategoryMainRow: React.FC<RowProps> = observer(({data, is_master}) => {
    const [open, setOpen] = React.useState(false);
    // const [isMarked, setMarked] = React.useState(false);

    const partData = data.parts_cmp_ds.concat(data.parts_master_ds);
    partData.sort((a, b) => b.spend - a.spend);

    const spend = is_master ? data.spend_master_ds : data.spend_cmp_ds;

    return <>
        <TableRow>
            <TableCell className="col-dropdown" onClick={() => setOpen(!open)}>
                <IconButton aria-label="expand row" size="small">
                    {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                </IconButton>
            </TableCell>

            <TableCell className="col-s_name" component="th" scope="row">
                {data.name}
            </TableCell>
            <TableCell className="col-s_spend">
                <CurrencyComponent v={spend}/>
            </TableCell>
            {/*<TableCell className="col-opportunity" align="right">*/}
            {/*    <MarkOpportunityButton isMarked={isMarked} onMark={() => setMarked(!isMarked)}/>*/}
            {/*</TableCell>*/}
        </TableRow>
        <TableRow>
            <TableCell className="cell-sub-table" colSpan={ROOT_TABLE_COLS + 1}>
                {partData.length === 0
                    ? undefined
                    : <Collapse in={open} unmountOnExit>
                        <Table
                            className="sub-table"
                            size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="col-p_name" colSpan={ROOT_TABLE_COLS}>SKU</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {partData.map((part, i) =>
                                    <SynergyCategoryTableSubRow key={i} part={part} is_master={is_master}/>
                                )}
                            </TableBody>
                        </Table>
                    </Collapse>
                }
            </TableCell>
        </TableRow>
    </>
})

// noinspection JSUnusedLocalSymbols
const SynergyCategoryTableSubRow: React.FC<{
    part: SupplierComparePartData
    is_master: boolean
}> = ({part, is_master}) => {
    // const [isMarked, setMarked] = useState(false);
    return <TableRow>
        <TableCell className="col-p_name" component="th" scope="row">
            {part.name}
        </TableCell>
        <TableCell className="col-p_spend">
            <CurrencyComponent v={part.spend}/>
        </TableCell>
        {/*<TableCell className="col-p_categories">*/}
        {/*    <CategoryChip labels={part.categories}/>*/}
        {/*</TableCell>*/}
        {/*<TableCell className="col-opportunity" align="right">*/}
        {/*    <MarkOpportunityButton isMarked={isMarked} onMark={() => setMarked(!isMarked)}/>*/}
        {/*</TableCell>*/}
    </TableRow>
}

