export const GROUP_SUPER = 'customer_super' as const;
export const GROUP_USER = 'customer_user' as const;

export declare interface GraphResponseNode {
    nodeId: number;
    name: string;
}

export declare interface GraphResponseLink<D> {
    source: number;
    target: number;
    value: D;
}

export declare interface GraphResponse<D = number> {
    nodes: GraphResponseNode[],
    links: GraphResponseLink<D>[],
}

export declare type PageResponse<D> = {
    count: number;
    next: string;
    previous: string;
    results: D[];
}

export declare interface TreeResponse<D = number> {
    label: string,
    children: TreeResponse<D>[],
    value: D,
}

export declare interface TreeValuesResponse<D> {
    label: string,
    children: TreeValuesResponse<D>[],
    values: D,
}

export declare interface ShallowHierarchyApiResponse<D = number> {
    label: string;
    children: _ShallowHierarchyApiResponse<D>[];
    value: D;
}

declare interface _ShallowHierarchyApiResponse<D = number> {
    label: string;
    children: never[];
    value: D;
}

export declare interface HierarchyApiResponse<D = number> {
    label: string;
    children: HierarchyApiResponse<D>[];
    value: D;
}

export declare interface HierarchyMultiValueResponse<D = number> {
    label: string;
    children: HierarchyMultiValueResponse<D>[];
    values: D;
}

export type BaseHierarchy<D> = HierarchyApiResponse<D> | _ShallowHierarchyApiResponse<D>;

export type MatCommonKpiSerializer = {
    n_categories: number
    n_suppliers: number
    total_spend: number
    unclassified_spend: number
}
export type MatKpiTreeData = {
    spend: number;
    parts: number;
    distinct_suppliers: number; // not existing yet
};
export declare type MatKpiTreeValues = keyof MatKpiTreeData;

export declare type JobPriceVarianceResp = {
    groups: {
        p__description: string;
        group_size: number;
        p__unit_cost__min: number;
        p__unit_cost__sum: number;
        p__quantity__sum: number;
        p__spend__sum: number;
        p__unit_cost__avg: number;
        pv_delta: number;
        pv: number;
        pv_opportunity: number;
    }[];
    total_opportunity: number;
};

/**
 * job_processing.views.JobConfigViewSet.list
 */
export interface ServerConfig {
    SKIP_AI_INTEGRATION: boolean;
    default_type: string;
    all_types: string[];
}

/**
 * KPI's, as taken from /api/bag/kpi_common/{:bagId}/{:CommonKpiBuilder_kpis}/
 * Generated by: http://localhost:8500/api/bag/kpi_common/1/all_names/?format=ts-type
 */
export declare type CommonKpiBuilder_kpis =
    'all'
    | 'get_all_labels'
    | 'get_business_units'
    | 'get_categories'
    | 'get_l1_categories'
    | 'get_suppliers'
    | 'get_total_categories'
    | 'get_total_l1_categories'
    | 'n_business_units'
    | 'n_categories'
    | 'n_l1_categories'
    | 'n_labels'
    | 'n_levels'
    | 'n_parts'
    | 'n_suppliers'
    | 'n_total_categories'
    | 'n_total_l1_categories'
    | 'n_unclassified'
    | 'total_pos_spend'
    | 'total_spend'
    | 'unclassified_pos_spend'
    | 'unclassified_spend';

/**
 * KPI's, as taken from /api/bag/kpi_viz/{:bagId}/{:CommonKpiBuilder_kpis}/
 * Generated by: http://localhost:8500/api/bag/kpi_viz/1/all_names/?format=ts-type
 */
export declare type UserVisualizationKpiBuilder_kpis =
    'categorization_spend_result'
    | 'count_tree'
    | 'count_tree_total'
    | 'get_all_categories'
    | 'get_l1'
    | 'out_spend_tree'
    | 'out_spend_tree_total'
    | 'spend_tree'
    | 'spend_tree_total'
    | 'total_used_labels';
export declare type KpiBuilder_kpis = UserVisualizationKpiBuilder_kpis;

export interface LoginUser {
    id: number
    username: string
    email: string
    first_name: string
    last_name: string
    groups: string[]
    is_staff: boolean
    mithrauserdata: null | MithraUserData
}

export interface MithraUserData {
    is_edit_allowed: boolean
    is_upload_allowed: boolean
}

export interface MithraLoginResponse {
    access_token: string;
    refresh_token: string;
    user: LoginUser;
}

export type ppv_all_groups_Response = {
    ppv: {
        p__in_l1: "" | string,
        pv_opportunity__sum: number,
    }[]
}
export type job_data_Response = {
    p__id: string,
    p__description: string,
    s__id: string,
    bu__id: "" | string,
    p__spend: number,
    p__unit_cost: 0 | number,
    p__quantity: number,
    p__in_l1: "" | string,
    // And more
}
export type job_supplier_meta_Response = {
    s__id: string,
    p__spend__count: number,
    p__spend__sum: number,
    s__payment_term: number,
}
export type CashFlowOpportunityResponse = {
    summary: {
        n_hit_parts: number,
        n_hit_suppliers: number,
        total_opportunity: number,
        total_avg_extended_days_part: number,
        total_avg_extended_days_supplier: number,
    },
    suppliers: any[]
}

export enum SupplierSegmentationEnum {
    cr = 'Critical',
    st = 'Strategic',
    tr = 'Transactional',
}

export type DataFileUploadSerializer = {
    id: number;
    file_name: string;
    date: string;
}

export type DataFrameSerializer = {
    columns: string[];
    data: any[][];
    column_indexes: number[]
    dataframe_id: number;
    dataframe_size: number;
    types: string[];
    columns_letters: string[];
}
//TODO: it's not really a serializer, but it''s 
export type MithraColumnSerializer = {
    example: string;
    id: number;
    name: string;
    type: string;
    key: string;
    description: string;

    is_required: boolean;
}

export type DataMappingSerializer = {
    id?: number;
    data_file: number;
    client_column_name: string;
    client_column_type: string;
    client_column_index: number;
    mithra_column_key: string;
    ai_result: boolean;
    user_result: boolean;
}

export type KpiListSerializer = {
    total_spend: number;
    suppliers: number;
    parts: number;
    business_units: number;
}

export type DataMappingResultTableSerializer = {
    columns: string[];
    data: string[][];
    column_indexes: number[];
    dataframe_size: number;
    types: string[];
}

export type TaxonomySuggestionSerializer<T> = {
    id: number;
    create_date: string;
    last_update: string;
    suggestion_state: T;
}
