import React from "react";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router";
import {Button, Grid, Typography} from "@mui/material";
import './KoiFacade.scss'
import {HelpComponent} from "../help-component/HelpComponent";
import {ChevronLeft} from "@mui/icons-material";

type ButtonSpec = {
    type: 'btn'
    link: string
    label: string
} | {
    type: 'component'
    node: React.ReactNode
}

type Props = {
    // avatar?: React.ReactNode;
    title: string | React.ReactNode;
    subTitle?: string;
    intro?: React.ReactNode;
    back?: string | false;
    backLabel?: string;
    onBack?: () => void
    next?: ButtonSpec
    helpContent?: React.ReactNode;
    simpleAction?: React.ReactNode;
    className?: string;
}

export const KoiFacade: React.FC<Props> = observer((
    {
        children,
        title,
        subTitle,
        back,
        onBack,
        backLabel,
        next,
        intro,
        helpContent,
        simpleAction,
        className
    }) => {
    const history = useHistory();
    const titleColWidth = 10 - (next ? 2 : 0);
    const hasSubtitle = Boolean(subTitle);
    return <div className={"koi-facade" + (className ? ` ${className}` : '')}>
        <Grid container>
            <Grid container item className="header" alignItems="center">
                <Grid item xs={2} className="text-center">
                    {back !== false && <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => onBack ? onBack() : history.push('' + back)}>
                        {backLabel === undefined ? 'Back' : backLabel}
                    </Button>}
                </Grid>
                <Grid item xs={titleColWidth}>
                    {typeof title === 'string' ?
                        <Typography variant="h2" component={hasSubtitle ? 'div' : 'h1'}>
                            {title}
                            {helpContent ? <HelpComponent helpContent={helpContent}/> : <></>}
                            {simpleAction}
                            {hasSubtitle &&
                                <Typography variant="h4" component="h4" className="taxonomy-title">
                                    {subTitle}
                                </Typography>
                            }
                        </Typography>
                        : title
                    }
                </Grid>
                {next &&
                    <Grid item xs={2} className="text-center">
                        {next?.type === 'btn' &&
                            <Button
                                color="primary"
                                variant="contained"
                                // startIcon={<ArrowBack/>}
                                onClick={() => history.push('' + next.link)}>
                                {next.label === undefined ? 'Next' : next.label}
                            </Button>
                        }
                        {next?.type === 'component' && next.node}
                    </Grid>
                }
            </Grid>
            <Grid item xs={2}/>
            <Grid item xs={10} className="intro">
                {intro}
            </Grid>
        </Grid>
        {children}
    </div>;
});
