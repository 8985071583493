import React, {CSSProperties} from "react";
import {LinearProgress, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import {useStores} from "../../stores";

export type ColSpec = {
    txt: string
    cls: string
    align?: 'right' | 'center'
    minWidth?: number
    width?: CSSProperties['width']
    /**
     * Only used for identifying which fields has an active ordering
     */
    columnFieldId?: string
};

type Props = {
    columns: ColSpec[]
    enableLoading?: boolean
    isLoading?: boolean
    orderBy?: ColSpec['columnFieldId']
    orderDirection?: 'asc' | 'desc'
    onClickOrdering?: (c: ColSpec) => void
};
export const MithraTableHeadColumns: React.FC<Props> = ({
                                                            columns,
                                                            enableLoading,
                                                            isLoading,
                                                            orderBy,
                                                            orderDirection,
                                                            onClickOrdering
                                                        }) => {
    const {p} = useStores();
    return <TableHead>
        <TableRow>
            {columns.map((c, i) => {
                const isSortable = p.isColumnSortable(c.columnFieldId)
                return <TableCell
                    key={i}
                    className={c.cls + (isSortable ? ' sortable' : '')}
                    align={c.align}
                    style={{width: c.width, minWidth: c.minWidth}}>
                    {isSortable
                        ? <TableSortLabel
                            onClick={() => onClickOrdering ? onClickOrdering(c) : undefined}
                            active={c.columnFieldId === orderBy}
                            direction={orderDirection}>
                            {c.txt}
                        </TableSortLabel>
                        : c.txt
                    }
                    {/*({c.columnFieldId})*/}
                </TableCell>;
            })}
        </TableRow>
        {enableLoading && <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
            <td colSpan={columns.length}>
                {isLoading && <LinearProgress variant="indeterminate"/>}
            </td>
        </tr>}
    </TableHead>;
}
