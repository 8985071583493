import './TaxonomyMapper.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch, routes} from "../../routing/routes";
import {useStores} from "../../stores";
import {Button, Grid} from "@mui/material";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {generatePath, useHistory} from "react-router";
import Typography from "@mui/material/Typography";
import {BaseTaxonomySupplierTable} from "./mapper/BaseTaxonomySupplierTable";
import {TaxonomyMapperComponent} from "./mapper/TaxonomyMapperComponent";
import {ChevronRight, Refresh} from "@mui/icons-material";
import {TaxonomyMapperToolbar} from "./mapper/TaxonomyMapperToolbar";
import {generateHomePath} from "../../routing/routing.utils";
import {environment} from "../../env";
import {
    CmpBreakdownSingleVisualization,
    Data
} from "../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";
import {toCurrency} from "../../components/currency-component/CurrencyComponent";
import {C} from "../../configurations/active-configuration";
import {hackGetTaxonomyMapperStore} from '../../stores/hack';

export const TaxonomyMapperPage: React.FC = observer(() => {
    let {p, rootAppStore, taxonomyMapperStore} = useStores();
    if (C.package === 'merge_x') {
        // FIXME: CAT-877: This should only be applied for SHV
        taxonomyMapperStore = hackGetTaxonomyMapperStore(rootAppStore.app);
    }
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    // const theme = useTheme();
    // const mediaTablet = useMediaQuery(theme.breakpoints.down('md'));

    const mockDataCatEgg: Data = {
        top: {
            id: "1",
            total: 182,
            value: 130,
            valueTitle: "130",
            valueTitleHover: "130",
        },
        bottom: {
            id: "2",
            total: 9057077,
            value: 8403536,
            valueTitle: toCurrency(p.currencySymbol, 8403536, 3),
            valueTitleHover: toCurrency(p.currencySymbol, 8403536, 3,),
        },
    };

    const mockDataCatLg: Data = {
        top: {
            id: "1",
            total: 638,
            value: 32,
            valueTitle: "32",
            valueTitleHover: "32",
        },
        bottom: {
            id: "2",
            total: 499480942,
            value: 73001067,
            valueTitle: toCurrency(p.currencySymbol, 73001067, 3),
            valueTitleHover: toCurrency(p.currencySymbol, 73001067, 3,),
        },
    };

    const optionsLeft = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: true,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }

    const optionsRight = {
        width: 1000,
        height: 500,
        margin: {top: 50, left: 0, bottom: 50, right: 0},
        alignRight: false,
        fixedWidth: false,
        barPortion: 0.5,
        flowStrokeWidth: 0,
        hoverVisible: false,
        defaultWideMargin: true,
    }


    return <KoiFacade
        title="Taxonomy Mapper"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generateHomePath(routeMatch, undefined, rootAppStore.app)}
        next={{
            type: 'component',
            node: <Button
                color="primary"
                variant="outlined"
                endIcon={<ChevronRight/>}
                onClick={() => {

                    if (environment.package === 'merge_1') {
                        // In the merge_1 package there are no id's in the url
                        history.push(generatePath(routes.merge_1_common_categories_review, routeMatch.params))
                        return
                    }

                    // After the taxonomy mapper is completed, go to the review page
                    history.push(generatePath(routes.taxonomy_mapper_review, routeMatch.params))
                    // // TODO: CAT-712
                    // if (env === 'simple') {
                    //     // TaxonomyMapperReviewPage
                    //     history.push(generatePath(routes.pic_common_categories_review, routeMatch.params))
                    // } else if (env === 'nestle') {
                    //     history.push(generatePath(routes.nestle_common_categories_review, routeMatch.params))
                    // } else if (env === 'multi') {
                    //     // TaxonomyMapperReviewPage
                    //     history.push(generatePath(routes.synergy_merge_taxonomy_mapper_review, routeMatch.params))
                    // } else {
                    //     // TaxonomyMapperReviewPage
                    //     history.push(generatePath(routes.synergy_v2_taxonomy_review, routeMatch.params))
                    // }
                }}>
                Review
            </Button>
        }}
        className="taxonomy-mapper-page centered-header">

        {/*TODO: CAT-877: We need to split off hardcoded data and move it to the profile store!*/}
        <Grid container justifyContent="center" style={{marginTop: '2em'}}>
            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <Typography variant="h5">Egg dataset</Typography>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <Typography variant="h5">Liberty Global (15 Jan)</Typography>
            </Grid>
            <Grid item md={2}></Grid>

            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <CmpBreakdownSingleVisualization
                    data={mockDataCatEgg}
                    options={optionsLeft}
                />
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={3}>
                <CmpBreakdownSingleVisualization
                    data={mockDataCatLg}
                    options={optionsRight}
                />
            </Grid>
            <Grid item md={2}></Grid>
        </Grid>

        <Grid container justifyContent="end" className="taxonomy-titles">
            <Grid item sm={4}>{/* Or 7 to let the title span over the table as well*/}
                <Typography variant="h3" className="taxonomy-title base-title">
                    {taxonomyMapperStore.baseTaxonomyName}
                </Typography>
            </Grid>
            <Grid item sm={1} className="text-center">
                <Button
                    aria-label="refresh"
                    onClick={() => window.location.reload()}
                    disabled={taxonomyMapperStore.mapper.busy}>
                    <Refresh/>
                </Button>
            </Grid>
            <Grid item sm={4}>
                <Typography variant="h3" className="taxonomy-title target-title">
                    {taxonomyMapperStore.targetTaxonomyName}
                </Typography>
            </Grid>
        </Grid>

        <section className="taxonomy-mapper-main">
            <Grid container className="taxonomy-mapper-main-header">
                <Grid item sm={3}/>
                <Grid item sm={9}>
                    <TaxonomyMapperToolbar/>
                </Grid>
            </Grid>
            <Grid container className="taxonomy-mapper-main-body">
                <Grid item sm={3}>
                    <BaseTaxonomySupplierTable/>
                </Grid>
                <Grid item sm={9} id="taxonomy-mapper-viz-wrapper">
                    {/*<Grid container>*/}
                    {/*    <Grid item xs={5}>a</Grid>*/}
                    {/*    <Grid item xs={2}>b</Grid>*/}
                    {/*    <Grid item xs={5}>c</Grid>*/}
                    {/*</Grid>*/}
                    <TaxonomyMapperComponent/>
                </Grid>
            </Grid>
        </section>
    </KoiFacade>
});
