// import '../../../../components/data-table/DataTable.scss';
import './CategorizationReviewTable.scss';
import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {MithraTablePaginationActions} from '../../../../components/table-pagination/MithraTablePaginationActions';
import {CategorizationReviewMainRow} from "./CategorizationReviewMainRow";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {PartReCategorizationHandler} from "../../../../components/categorization/PartRecategorizationHandler";
import {getSpend} from "../../../../services/classes/MatReviewClasses";
import {
    CurrencyAbbreviation,
    findAbbreviationOfGroup,
    NO_ABBREVIATION
} from "../../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";

export const CategorizationReviewMainTable: React.FC = observer(() => {
    const {categorizationStore, categorizationReviewStore, p} = useStores()

    const controller: any = useMemo(() => {
        if (!categorizationReviewStore.categories.taxonomy_categories) {
            if (categorizationReviewStore.categories.taxonomy_categories === null) {
                console.error('No taxonomy set...')
            }
            return undefined
        }
        return new PartReCategorizationControllerStore(categorizationReviewStore.categories.taxonomy_categories);
    }, [categorizationReviewStore.categories.taxonomy_categories])

    const supplierPages = categorizationStore.data.supplierPages;
    let abbreviation: CurrencyAbbreviation = NO_ABBREVIATION;
    if (supplierPages.data) {
        const spends = supplierPages.data.map(d => getSpend(d))
        if (spends.length > 0)
            abbreviation = findAbbreviationOfGroup(spends)
    }
    // const data = undefined as any as any[];
    const isLoading = categorizationStore.data.isLoadingParts;
    const tableClass = 'mithra-table x-design categorization-review-table' + (isLoading ? ' loading' : '');
    return <TableContainer className={tableClass}>
        {controller && <PartReCategorizationHandler controller={controller}/>}
        <Table cellPadding="0">
            <TableHead>
                <TableRow>
                    {p.reviewTableColumnsMain.map((c, i) =>
                        <TableCell key={i} className={c.cls} align={c.align}>{c.txt}</TableCell>
                    )}
                </TableRow>
                <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
                    <td colSpan={p.reviewTableColumnsMain.length}>
                        {isLoading && <LinearProgress variant="indeterminate"/>}
                    </td>
                </tr>

            </TableHead>
            <TableBody className="categorization-review-table-body">
                {supplierPages.data ? <>
                        {supplierPages.data.map(row =>
                            <React.Fragment key={row.id}>
                                <CurrencyAbbreviationContext.Provider value={abbreviation}>
                                    <CategorizationReviewMainRow row={row} controller={controller}/>
                                </CurrencyAbbreviationContext.Provider>
                            </React.Fragment>
                        )}
                        {supplierPages.data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={p.reviewTableColumnsMain.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        //
                        rowsPerPageOptions={[]}
                        colSpan={p.reviewTableColumnsMain.length}
                        count={supplierPages.count}
                        rowsPerPage={supplierPages.pageSize}
                        page={supplierPages.page - 1}
                        // SelectProps={{
                        //     inputProps: {'aria-label': 'rows per page'},
                        //     native: true,
                        // }}
                        // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                        onPageChange={(e, page) => supplierPages.changePage(page + 1)}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={MithraTablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
