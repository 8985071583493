import React from "react";
import {CardActions, CardContent, Grid, Paper} from "@mui/material";
import {SmallHeader} from "../main/SmallHeader";
import {Card} from "react-bootstrap";
import {MainFooter} from "../main/MainFooter";
import Container from "@mui/material/Container";

type Props = {
    className?: string,
    content: JSX.Element,
    actions?: JSX.Element,
    minHeight?: string,
};

export const FullPageCard: React.FC<Props> = ({className, content, actions, minHeight}) => <>
    <Grid className={"vh-100" + (className ? ` ${className}` : '')}
          container justifyContent="center" direction="column">
        <Grid item>
            <Container>
                <Grid container justifyContent="center">
                    <Grid item xs={9} sm={6} md={4}>
                        <SmallHeader/>
                        <Paper className="" variant="outlined" square>
                            <Card style={{minHeight: minHeight}}>
                                <CardContent>{content}</CardContent>
                                {actions &&
                                    <CardActions
                                        className='text-center justify-content-center mb-3'>{actions}</CardActions>}
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    </Grid>
    <div style={{position: 'absolute', bottom: 0, left: 0, right: 0}}>
        <MainFooter/>
    </div>
</>;
