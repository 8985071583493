import './CategorizationReport.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import Typography from "@mui/material/Typography";
import {FormControl, Grid, IconButton, Input, InputAdornment, InputLabel} from "@mui/material";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {SingleBarChart} from "../../../components/visualization/single-barchart/SingleBarChart";
import {NO_MARGIN} from "../../../utils/margin";
import {UNCATEGORIZED_VALUE} from "../../../constants";
import {CategorizationReviewMainTable} from './review-table/CategorizationReviewMainTable';
import {CurrencyAbbreviationContext} from '../../../components/currency-component/CurrencyAbbreviationContext';
import {ReportBreadcrumbs} from "./ReportBreadcrumbs";
import {Clear, Search} from "@mui/icons-material";

export const CategorizationReport: React.FC = observer(() => {
    const {categorizationStore} = useStores();

    const keyValues = categorizationStore.data.summaryResultKeyValues;

    const showTable = Boolean(categorizationStore.data.supplierPages);

    useEffect(() => {
        categorizationStore.data.reloadView(); // TODO: Should this be initial?
    }, [categorizationStore]);

    const dataMax = Math.max(
        categorizationStore.data.onlyNewSelectionCharts?.max || 1,
        categorizationStore.data.parentCharts?.max || 1
    );
    return <div className="categorization-review">

        <Grid container justifyContent="center">
            <Grid className=""
                  item xs={6}
                  container>
                <ReportBreadcrumbs/>
            </Grid>
        </Grid>

        <Grid container justifyContent="center">
            <Grid className="key-value-container narrow-margin text-center font-bigger"
                  item xs={6}
                  container justifyContent="space-between">
                <Grid item>
                    <Typography variant="caption">
                        Categorized
                    </Typography>
                    <Typography variant="h5" className="font-weight-bold key-value">
                        {keyValues
                            ? <CurrencyComponent
                                v={keyValues.after.classified_spend}
                                nDigits={3}
                                abbreviation={keyValues.abbreviation}
                                hideAlmostZero={true}
                            />
                            : <>&nbsp;</>}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">
                        Re-categorized
                    </Typography>
                    <Typography variant="h5" className="font-weight-bold key-value">
                        {keyValues
                            ? <CurrencyComponent
                                v={keyValues.after.reclassified_spend}
                                nDigits={3}
                                abbreviation={keyValues.abbreviation}
                                hideAlmostZero={true}
                            />
                            : <>&nbsp;</>}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="caption">
                        Uncategorized
                    </Typography>
                    <Typography variant="h5" className="font-weight-bold key-value value-warning">
                        {keyValues
                            ? <CurrencyComponent
                                v={keyValues.after.unclassified_spend}
                                nDigits={3}
                                abbreviation={keyValues.abbreviation}
                                hideAlmostZero={true}
                            />
                            : <>&nbsp;</>}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>

        {categorizationStore.data.onlyNewSelectionCharts &&
            <Grid container justifyContent="center">
                <Grid item xs={3}/>
                <Grid item xs={9} className="overview-bar-charts">
                    {/* The bars of the bar chart */}
                    {categorizationStore.data.onlyNewSelectionCharts.data.map(changeChartData =>
                        <div key={changeChartData.category}
                             className={`clickable bar-chart-container l${categorizationStore.data.filterManager.selectedLevel + 1}`}
                             onClick={() => categorizationStore.data.filterManager.selectNextCategoryDown(changeChartData.category)}>
                            <SingleBarChart
                                key={changeChartData.category}
                                data={{
                                    mainLabel: changeChartData.label,
                                    values: changeChartData.values,
                                }}
                                className={
                                    (changeChartData.category === UNCATEGORIZED_VALUE ? 'uncat' : '')
                                    + ' hack-hide-old'
                                }
                                options={{
                                    width: 1300,
                                    height: 20,
                                    margin: NO_MARGIN,
                                    alwaysVisibleLabels: true,
                                    dataMax,
                                    barPadding: 0,
                                }}
                            />
                        </div>
                    )}
                </Grid>
            </Grid>
        }
        {showTable &&
            <>
                <Grid container justifyContent="start">
                    <Grid item xs={12}>
                        <FormControl variant="standard" fullWidth={true} className="search-field">
                            <InputLabel htmlFor="supplier-search-field">
                                Vendor search
                                {/*{categorizationStore.data.filter.hits !== undefined &&*/}
                                {/*    ` (${categorizationStore.data.filter.hits} result${categorizationStore.data.hits.leftHits === 1 ? '' : 's'})`*/}
                                {/*}*/}
                            </InputLabel>
                            <Input
                                id="supplier-search-field"
                                type="text"
                                fullWidth={true}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="clear search"
                                            onClick={() => categorizationStore.data.filterManager.supplierSearch.clearSearch()}
                                        >
                                            <Clear/>
                                        </IconButton>
                                        <IconButton
                                            aria-label="search"
                                            onClick={() => categorizationStore.data.filterManager.supplierSearch.doSearch()}
                                        >
                                            <Search/>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                value={categorizationStore.data.filterManager.supplierSearch.searchString}
                                onChange={e => categorizationStore.data.filterManager.supplierSearch.setSearch(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' ? categorizationStore.data.filterManager.supplierSearch.doSearch() : undefined}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        {keyValues?.abbreviation
                            && <CurrencyAbbreviationContext.Provider value={keyValues.abbreviation}>
                                <CategorizationReviewMainTable/>
                            </CurrencyAbbreviationContext.Provider>
                        }
                    </Grid>
                </Grid>
            </>
        }
    </div>
});
