import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {SupplierNormalizationStore} from "../SupplierNormalizationStore";
import {
    ApiParentSupplierMatch,
    ParentSupplierMatch,
    SuggestedParentSupplierSerializer
} from "../pages/SupplierNormalization.classes";
import {RequestManager} from "../../../stores/managers/RequestManager";
import {AxiosResponse} from "axios";
import {from} from "rxjs";


type ParentSupplierData = {
    sp_id: string
    sp_name: string
}
/**
 * A model existing in the backend
 */
export type ParentSupplier = { id: number | null } & ParentSupplierData;
/**
 * The data the user can fill in
 */
export type ParentSupplierUserData = Pick<ParentSupplierData, 'sp_name'>;

/**
 * For searching and creating supplier parents
 */
export class ParentSupplierDelegate {

    readonly _parentSupplierManager = new RequestManager<{ databag: number, search?: string }, AxiosResponse<SuggestedParentSupplierSerializer[]>>(
        ({databag, search}) => from(this.api.listParentSupplierSuggestions(databag, search || ''))
    );

    newlyCreatedParentSupplier: ParentSupplierMatch[] = [];
    newParentSupplier: ParentSupplierUserData = {
        sp_name: '',
    }

    // noinspection JSUnusedLocalSymbols
    constructor(
        private readonly api: MithraMaterializedApi,
        private readonly supplierNormalizationStore: SupplierNormalizationStore,
    ) {
        makeAutoObservable(this);
    }

    get apiParentSuppliers(): ApiParentSupplierMatch[] {
        return this._parentSupplierManager.result?.data.map(data => ({
            type: 'parent_supplier', data
        })) || []
    }

    get allSuggestedParentsSupplier(): ParentSupplierMatch[] {
        return (this.apiParentSuppliers as ParentSupplierMatch[])
            .concat(this.newlyCreatedParentSupplier);
    }

    initNewSupplier(name: string) {
        this.newParentSupplier.sp_name = name;
    }

    setNewParentSupplierName(name: string) {
        this.newParentSupplier.sp_name = name;
    }

    createNewParentSupplier(): ParentSupplierMatch {
        // Create a new object
        const data: ParentSupplierUserData = {...this.newParentSupplier};
        const newParentSupplier: ParentSupplierMatch = {type: 'new_parent_supplier', data};
        // Reset the interface
        this.newParentSupplier.sp_name = '';
        // Add the new object
        this.newlyCreatedParentSupplier.push(newParentSupplier);
        return newParentSupplier;
    }

    findApiParentMatch(sp_id: string): ApiParentSupplierMatch | undefined {
        return this.apiParentSuppliers.find(match => match.data.sp_id === sp_id);
    }
}
