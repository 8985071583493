import MithraApi from "../services/MithraApi";
import {makeAutoObservable} from "mobx";
import {EMPTY, Subscription} from "rxjs";
import {BagStore} from "./BagStore";
import ProfileStore from "./ProfileStore";

type OpportunityData = {
    type: 'supplier_consolidation',
    otherSupplierName: string,
    opportunitySize: number,
    notes: string
} | {
    type: 'any',
    bodyValue: number
}

export type OpportunityItem = {
    id: string
    title: string
    data: OpportunityData
    checked: boolean
}

export type OpportunityClasses = 'identified' | 'qualified' | 'realized';

type Base = {
    [c in OpportunityClasses]: OpportunityItem[];
};

export class OpportunityStore implements Base {
    identified: OpportunityItem[] = [];
    qualified: OpportunityItem[] = [];
    realized: OpportunityItem[] = [];

    // noinspection JSUnusedLocalSymbols
    constructor(private profileStore: ProfileStore, private api: MithraApi, private bagStore: BagStore) {
        if (!profileStore.p.hideHardcodedOpportunities) {
            this.identified = HARDCODED_ITEMS_1.map(p => partialToAnyOpportunity(p));
            this.qualified = HARDCODED_ITEMS_2.map(p => partialToAnyOpportunity(p));
            this.realized = HARDCODED_ITEMS_3.map(p => partialToAnyOpportunity(p));
        }
        makeAutoObservable(this)
    }

    editingScOpportunity: OpportunityItem = {
        id: '',
        title: '',
        data: {
            type: 'supplier_consolidation',
            otherSupplierName: '',
            opportunitySize: 1000,
            notes: '',
        },
        checked: false,
    };
    isEdit = false;

    get editScData() {
        console.assert(this.editingScOpportunity.data.type === 'supplier_consolidation', 'Unexpected opportunity type', String(this.editingScOpportunity.data.type));
        return this.editingScOpportunity.data as OpportunityData & { type: 'supplier_consolidation' };
    }

    $init(): Subscription {
        return EMPTY.subscribe();
        // const mapToOpportunityItem = (opp: Opportunity): OpportunityItem => ({
        //     id: `${opp.id}`,
        //     title: `${(opp as any).supplier_id} - ${opp.opportunity_type_label}`,
        //     bodyValue: opp.total_opportunity,
        //     checked: false,
        // });
        // return this.jobStore.listOpportunities()
        //     .pipe(map(([ppvOs, cfOs]) => {
        //         const opportunities: Opportunity[] = (ppvOs as Opportunity[]).concat(cfOs)
        //         const opportunitiesIdentified = HARDCODED_ITEMS_1.concat(
        //             opportunities.filter(o => o.current_status === OpportunityStatusEnum.Identified).map(mapToOpportunity)
        //         );
        //         const opportunitiesQualified = HARDCODED_ITEMS_2.concat(
        //             opportunities.filter(o => o.current_status === OpportunityStatusEnum.Qualified).map(mapToOpportunity)
        //         );
        //         const opportunitiesRealized = HARDCODED_ITEMS_3.concat(
        //             opportunities.filter(o => o.current_status === OpportunityStatusEnum.Realized).map(mapToOpportunity)
        //         );
        //         return {opportunitiesIdentified, opportunitiesQualified, opportunitiesRealized};
        //     }))
        //     .subscribe(({opportunitiesIdentified, opportunitiesQualified, opportunitiesRealized}) => runInAction(() => {
        //         this.opportunitiesIdentified = opportunitiesIdentified;
        //         this.opportunitiesQualified = opportunitiesQualified;
        //         this.opportunitiesRealized = opportunitiesRealized;
        //     }))
    }

    get hasCheckedIdentified() {
        return OpportunityStore.hasChecked(this.identified);
    }

    get hasCheckedQualified() {
        return OpportunityStore.hasChecked(this.qualified);
    }

    get hasCheckedRealized() {
        return OpportunityStore.hasChecked(this.realized);
    }

    private static hasChecked(list: OpportunityItem[]): boolean {
        if (list.length === 0) {
            return false;
        }
        return list.some(o => o.checked);
    }

    move(srcList: OpportunityClasses, destList: OpportunityClasses) {
        const src = this[srcList];
        const dest = this[destList];

        const newSrc = [] as OpportunityItem[];
        const newDest = dest;
        src.forEach(value => {
            if (value.checked) {
                value.checked = false;
                newDest.push(value);
            } else {
                newSrc.push(value);
            }
        })
        this[srcList] = newSrc;
    }

    toggle(val: OpportunityItem) {
        val.checked = !val.checked;
    }

    createOpportunity(opportunity: OpportunityItem) {
        this.identified.push({...opportunity});
    }

    createAnyOpportunity(opportunity: Partial<OpportunityItem>) {
        this.identified.push(partialToAnyOpportunity(opportunity));
    }

    delete(list: OpportunityClasses) {
        this[list] = this[list].filter(v => !v.checked);
    }

    hasOpportunity(opportunityId: string | undefined) {
        let hasOpportunity = false;
        if (!opportunityId) return hasOpportunity;
        hasOpportunity = this.identified.some(v => v.id === opportunityId)
            || this.qualified.some(v => v.id === opportunityId)
            || this.realized.some(v => v.id === opportunityId)
        console.log('hasOpportunity', opportunityId, hasOpportunity);
        return hasOpportunity
    }

    setEditOpportunity(item: OpportunityItem) {
        if (item.data.type === 'supplier_consolidation') {
            this.editingScOpportunity = item;
        } else {
            console.warn('Not supported to edit the opportunity type:', String(item.data.type));
        }
        this.isEdit = true;
    }
}

const HARDCODED_ITEMS_1: Partial<OpportunityItem>[] = [
    // {
    //     id: 'cf1',
    //     title: 'Sunways - Cashflow',
    //     otherSupplierName: 'Sunways - Cashffla',
    //     opportunitySize: 57000,
    //     notes: 'Actually not that many',
    // },

    {id: 'ppv1', title: 'BRAKCO Technology - PPV', data: {type: 'any', bodyValue: 60000}},
    {id: 'cf1', title: 'Sunways - Cashflow', data: {type: 'any', bodyValue: 57000}},
    {id: 'ppv2', title: 'BRAKCO Technology - PPV', data: {type: 'any', bodyValue: 52000}},
]
const HARDCODED_ITEMS_2: Partial<OpportunityItem>[] = [
    {id: 'cf2', title: 'Sunways - Cashflow', data: {type: 'any', bodyValue: 192000}},
]
const HARDCODED_ITEMS_3: Partial<OpportunityItem>[] = [
    {id: 'ppv3', title: 'BRAKCO Technology - PPV', data: {type: 'any', bodyValue: 37000}},
]

// const partialToSCOpportunity = (o: Partial<OpportunityItem>): OpportunityItem => ({
//     id: '',
//     title: '',
//     data: {
//         type: 'supplier_consolidation',
//         otherSupplierName: '',
//         opportunitySize: 0,
//         notes: '',
//     },
//     checked: false,
//     ...o,
// })

const partialToAnyOpportunity = (o: Partial<OpportunityItem>): OpportunityItem => ({
    id: '',
    title: '',
    data: {
        type: 'any',
        bodyValue: 0,
    },
    checked: false,
    ...o,
})
