import './GenericModal.scss';
import React from "react";
import {Button, Dialog, Grid, PaperProps, Typography} from "@mui/material";
import {Check, Close} from "@mui/icons-material";

// TODO: Move this to a more generic component and set the ID field properly
export const GenericModel: React.FC<{
    className?: string

    isOpen: boolean,

    paperProps?: Partial<PaperProps>,

    onSave: () => void
    saveDisabled?: boolean
    saveTitle?: string,

    onCancel: () => void
    cancelDisabled?: boolean
    cancelTitle?: string,

    title: string
    titleId?: string
}> = ({
          className,
          isOpen,
          onSave,
          saveDisabled,
          saveTitle,
          onCancel,
          cancelDisabled,
          cancelTitle,
          title,
          titleId,
          paperProps,
          children,
      }) => {
    className = "edit-model-component" + (className ? ' ' + className : '');
    titleId = titleId || 'generic-modal';
    return <Dialog
        open={isOpen}
        onClose={onCancel}
        aria-labelledby={titleId}
        maxWidth="lg"
        PaperProps={paperProps as any}
    >
        <Grid container className={className}>
            <Grid item xs={8}
                  container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={3}>
                <Grid item>
                    <Typography id={titleId || ''} variant="h5">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    {children}
                </Grid>
            </Grid>
            <Grid item xs={2}/>
            <Grid item xs={2} alignItems="stretch"
                  container direction="column-reverse" justifyContent="space-between">
                <Grid item>
                    <Button
                        fullWidth
                        disabled={saveDisabled}
                        startIcon={<Check/>}
                        onClick={onSave}
                        variant="outlined"
                        // tabIndex="2"
                    >
                        {saveTitle || 'Save'}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        disabled={cancelDisabled}
                        className="btn-red"
                        startIcon={<Close/>}
                        onClick={onCancel}
                        variant="outlined"
                        // tabIndex="3"
                    >
                        {cancelTitle || 'Cancel'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Dialog>
}

