import {makeAutoObservable} from "mobx";
import {BagStore} from "../BagStore";
import ProfileStore from "../ProfileStore";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {SynergyStore} from "./SynergyStore";
import {CommonSuppliersReviewDelegate} from "./CommonSuppliersReviewDelegate";

/**
 * The synergy store for common suppliers
 */
export class CommonSuppliersDelegate {

    // 1500 and 1501 are for LG only, TODO: Remove this
    readonly numberTotalSuppliers1500: number = 0;
    readonly numberTotalSuppliers1501: number = 0;
    readonly totalSpend1500: number = 0;
    readonly totalSpend1501: number = 0;
    readonly commonSpend1500: number = 0;
    readonly commonSpend1501: number = 0;

    readonly review = new CommonSuppliersReviewDelegate(this.api, this, this.synergyStore);

    // noinspection JSUnusedLocalSymbols
    constructor(
        private synergyStore: SynergyStore,
        private bagStore: BagStore,
        private api: MithraMaterializedApi,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
        // reaction(() => ([this.bagStore.bag?.id, this.ps._parentSupplierManager.result] as const), ([databag, result]) => {
        //     console.log('SupplierNormalizationStore.reaction', databag, result?.data.length)
        //     if (databag && result) {
        //         // Only when the _parentSupplierManager is done re-trigger the startRowRequest
        //         this.review.filterDelegate.startRowsRequest();
        //     }
        // })
    }

    init() {
        const synergy = this.synergyStore.requestedSynergyId;
        console.log('CommonSuppliersDelegate.init', synergy)

        this.review._commonSupplierGroupRequest.reset(); // Ensures that the subRow request is stopped
        if (!synergy) return;
        this.review.filterDelegate.startRowsRequest();
    }
}
