import {makeAutoObservable, toJS} from "mobx";
import {AxoisRequestManager} from "./RequestManager";
import {
    GraphResponse,
    HierarchyMultiValueResponse,
    MatCommonKpiSerializer,
    MatKpiTreeData,
    MatKpiTreeValues
} from "../../services/ApiTypes";
import {from} from "rxjs";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {
    CollapsibleIndentTreeData,
    TreeDataType
} from "../../components/visualization/collapsible-indent-tree/CollapsibleIndentTree";
import {processTree} from "../../services/ApiHelpers";
import {hierarchy} from "d3-hierarchy";
import {Bag} from "../../services/classes/Bag";
import {BagStore} from "../BagStore";

export class BagKpiManager {

    _commonRequestManager = new AxoisRequestManager<number, MatCommonKpiSerializer>(bagId => from(this.api.getCommonKpi(bagId)))
    _taxonomyRequestManager = new AxoisRequestManager<{ databag: number, taxonomySize: number }, HierarchyMultiValueResponse<MatKpiTreeData>>(
        ({databag, taxonomySize}) => from(this.api.getTaxonomyKpi(databag, taxonomySize))
    )
    _taxonomyGraphRequestManager = new AxoisRequestManager<{ databag: number, value: MatKpiTreeValues }, GraphResponse>(
        ({databag, value}) => from(this.api.getTaxonomyGraphKpi(databag, value))
    )

    constructor(private api: MithraMaterializedApi, private bagStore: BagStore) {
        makeAutoObservable(this);
    }

    static readonly AVAILABLE_KPIS: MatKpiTreeValues[] = [
        'parts',
        'spend',
    ]

    init(bag: Bag) {
        this._commonRequestManager.request(bag.id);
        this._taxonomyRequestManager.request({databag: bag.id, taxonomySize: bag.taxonomy_size});
        this._taxonomyGraphRequestManager.cleanup();
        this.setSelectedGraphKpi(BagKpiManager.AVAILABLE_KPIS[0]);
    }

    get common() {
        return this._commonRequestManager.result;
    }

    get taxonomyData(): CollapsibleIndentTreeData<MatKpiTreeData> | undefined {
        if (!this._taxonomyRequestManager.result) return undefined;
        const result = toJS(this._taxonomyRequestManager.result)
        const tree = processTree<MatKpiTreeData>(result)
        return hierarchy<TreeDataType<MatKpiTreeData>>(tree, d => d['children']);
    }

    // Graph KPI (TODO: Move to FilteredSankeyComponent)

    selectedGraphKpi: MatKpiTreeValues | undefined;

    setSelectedGraphKpi(kpi: MatKpiTreeValues) {
        console.debug('setSelectedGraphKpi() called', kpi);
        this.selectedGraphKpi = kpi;
        this._taxonomyGraphRequestManager.request({
            databag: this.bagStore.bagId,
            value: kpi
        })
    }

    get taxonomyGraphLoading() {
        return this._taxonomyGraphRequestManager.busy;
    }

    get taxonomyGraphReloading() {
        return this._taxonomyGraphRequestManager.isReloading;
    }

    getFlatTaxonomyData(ensurePositive = true, filterBelow = 100): GraphResponse | undefined {
        const data = toJS(this._taxonomyGraphRequestManager.result);
        if (!data) return undefined;

        if (ensurePositive || filterBelow !== 0) {
            data.links.forEach(l => l.value = Math.abs(l.value));
            if (filterBelow > 0) {
                data.links = data.links.filter(l => l.value >= filterBelow);
            }
        }

        return data;
    }
}
