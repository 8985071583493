import React from "react";
import {styled} from '@mui/material/styles';
import {Step, StepConnector, StepIconProps, StepLabel, Stepper, Typography} from "@mui/material";
import {useStores} from "../../stores";
import {Check} from "@mui/icons-material";
import PropTypes from "prop-types";
import clsx from 'clsx';
import {theme} from "../../style/theme";

const PREFIX = 'CategorizationStepper';

const classes = {
    alternativeLabel: `${PREFIX}-alternativeLabel`,
    active: `${PREFIX}-active`,
    completed: `${PREFIX}-completed`,
    line: `${PREFIX}-line`,
    root: `${PREFIX}-root`,
    circle: `${PREFIX}-circle`,
    inactive_circle: `${PREFIX}-inactive_circle`,
    check: `${PREFIX}-check`
};

const _blue = (theme.palette?.primary as any).main || 'purple';
const SIZE = 20;
const CHECKBOX_MARGIN = 2;

const StyledMithraStepIcon = styled('div')({
    [`& .${classes.root}`]: {
        color: 'gray',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    [`& .${classes.active}`]: {
        color: _blue,
    },
    [`& .${classes.completed}`]: {
        color: _blue,
    },
    [`& .${classes.circle}`]: {
        width: SIZE,
        height: SIZE,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    [`& .${classes.inactive_circle}`]: {
        border: 'solid 1px',
        backgroundColor: 'white',
    },
    [`& .${classes.check}`]: {
        color: 'white',
        zIndex: 1,
        width: SIZE - CHECKBOX_MARGIN * 2,
        height: SIZE - CHECKBOX_MARGIN * 2,
        marginLeft: `${CHECKBOX_MARGIN}px`,
        marginTop: `${CHECKBOX_MARGIN}px`,
    },
});
const MithraStepIcon = (props: StepIconProps) => {
    const {active, completed} = props;
    return <StyledMithraStepIcon
        className={clsx(classes.root, {
            [classes.active]: active,
            [classes.completed]: completed,
        })}
    >
        <div className={clsx(classes.circle, {
            [classes.inactive_circle]: !active && !completed,
        })}>
            {completed && <Check className={classes.check}/>}
        </div>
    </StyledMithraStepIcon>
}
MithraStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};


const StyledStepper = styled(Stepper)({
    [`& .${classes.root}`]: {
        color: 'gray',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    [`& .${classes.active}`]: {
        color: _blue,
    },
    [`& .${classes.completed}`]: {
        color: _blue,
    },
    [`& .${classes.circle}`]: {
        width: SIZE,
        height: SIZE,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    [`& .${classes.inactive_circle}`]: {
        border: 'solid 1px',
        backgroundColor: 'white',
    },
    [`& .${classes.check}`]: {
        color: 'white',
        zIndex: 1,
        width: SIZE - CHECKBOX_MARGIN * 2,
        height: SIZE - CHECKBOX_MARGIN * 2,
        marginLeft: `${CHECKBOX_MARGIN}px`,
        marginTop: `${CHECKBOX_MARGIN}px`,
    },
});

const StyledMithraConnector = styled(StepConnector)({
    // TODO: This must be simplified
    [`&`]: {
        top: SIZE / 2,
        left: `calc(-50% + ${SIZE / 2}px)`,
        right: `calc(50% + ${SIZE / 2}px)`,
    },
})

type Props = {
    activeStep: number
    error?: string
}
export const CategorizationStepper: React.FC<Props> = props => {
    const {authStore, categorizationStore} = useStores();
    return <StyledStepper
        className="categorization-stepper"
        orientation="horizontal"
        alternativeLabel
        activeStep={props.activeStep}
        connector={<StyledMithraConnector/>}>
        <Step>
            <StepLabel
                onClick={() => categorizationStore.backToStart()}
                StepIconComponent={MithraStepIcon}>
                <Typography variant="caption">
                    Categorization
                </Typography>
            </StepLabel>
            {/*<StepButton*/}
            {/*    onClick={() => categorizationStore.setStep(0)}>*/}
            {/*    Categorization*/}
            {/*</StepButton>*/}
            {/*<StepLabel>Categorization</StepLabel>*/}
        </Step>
        <Step>
            <StepLabel StepIconComponent={MithraStepIcon}>
                <Typography variant="caption">
                    Review{authStore.viewOnly && ' (view only)'}
                </Typography>
            </StepLabel>
        </Step>
        <Step>
            <StepLabel StepIconComponent={MithraStepIcon}>
                <Typography variant="caption">
                    Finish
                </Typography>
            </StepLabel>
        </Step>
    </StyledStepper>
};
