import {TransitionProps} from "@mui/material/transitions/transition";

/**
 * @deprecated in favor of getCollapseAnimationTiming
 * @param elements
 */
export function getCollapseAnimationTiming(elements: undefined | any[] | null): TransitionProps['timeout'] | number | 'auto' {
    if (elements) {
        if (elements.length > 100) {
            // Performance issue
            return 0
        } else if (elements.length > 30) {
            // Does not fit on the screen anymore
            return {
                enter: 350,
                exit: 100,
            }
        }
    }
    return 'auto'
}
