import React from "react";

type Props = {
    name: string;
    true_txt?: string;
    false_txt?: string;
    val: boolean | any;
}

export const DebugValue: React.FC<Props> = ({name, true_txt, false_txt, val}) => {
    true_txt = typeof true_txt === "undefined" ? "true" : true_txt;
    false_txt = typeof false_txt === "undefined" ? "false" : false_txt;
    const isFalse = typeof val === "boolean" && !val;
    const isTrue = typeof val === "boolean" && val;
    return <div className={"debug-value" + (isFalse ? " value-false" : isTrue ? " value-true" : " value-other")}>
        {name}={isTrue
        ? <span className="value">{true_txt}</span>
        : isFalse
            ? <span className="value">{false_txt}</span>
            : <pre>{JSON.stringify(val)}</pre>
    }
    </div>;
}
