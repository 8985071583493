import './CollapsibleSuggestionIndentTree.scss';
import * as d3 from 'd3';
import React, {useEffect, useMemo, useRef, useState} from "react";
import {m_taxonomy} from "../../../services/classes/TaxonomyClasses";
import {SuggestionTreeStoreType} from "../../../stores/TaxonomySuggestorStore";
import {Options, TaxonomySuggestionTreeBuilder, UpdateData} from "./TaxonomySuggestionTreeBuilder";

type Props = UpdateData & {
    // data: ApiSuggestionTreeResponse<m_taxonomy.Data> //Old one
    data: SuggestionTreeStoreType<m_taxonomy.Data> //New one
    options?: Partial<Options>
}


export const CollapsibleSuggestionIndentTree: React.FC<Props> = ({
                                                                     data,
                                                                     options,
                                                                     filter,
                                                                     hiddenRoot,
                                                                     hiddenUncat,
                                                                     hiddenColumns
                                                                 }) => {
    const svgRef = useRef<SVGSVGElement>(null)
    const o = useMemo<Options>(() => ({
        width: 610,
        height: 0,
        margin: {
            left: 7,
            right: 0,
            top: 0,
            bottom: 0,
        },
        leftToRight: true,
        columns: [],
        ...options,
    }), [options]);

    const [controller, setController] = useState<TaxonomySuggestionTreeBuilder | undefined>();
    useEffect(() => {
        if (controller || !svgRef.current || !data) return;

        //FIXME: MOVE THIS LINES TO THE BUILDER
        const svg = d3.select(svgRef.current as SVGElement)
        // svg.html('')
        const root = svg.append('g')


        const sortBy: (a: any, b: any) => number = (a, b) => b.spend - a.spend || 0;
        setController(new TaxonomySuggestionTreeBuilder(root, o, TaxonomySuggestionTreeBuilder.processTreeData(data), hiddenRoot, sortBy as any))
    }, [data, svgRef, o, controller])

    useEffect(() => {
        if (!controller) return;
        controller.update({filter, hiddenRoot, hiddenUncat, hiddenColumns});
    }, [controller, filter, hiddenRoot, hiddenUncat, hiddenColumns])

    return <svg
        ref={svgRef}
        className="taxonomy-suggestion-tree-viz"

        viewBox={`0 0 ${o.width} ${o.height}`}
        style={{width: '100%', height: 'auto'}}
    />;
}
