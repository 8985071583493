import React from "react";
import {observer} from "mobx-react-lite";
import {Checkbox, TableRow} from "@mui/material";
import {PartCategorizationComponent} from "../../../../components/categorization/PartCategorizationComponent";
import {ReviewChoice} from "../../../../services/classes/MaterializedClasses";
import {SingularMatPartReviewRowState} from "../../../../services/classes/MatReviewClasses";
import {useStores} from "../../../../stores";
import {getSpecificCategorization} from "../../../../services/classes/MatReviewHelpers";
import {MithraTableCell} from "../../../../components/table/MithraTableCell";
import {GoogleLink} from "../../../../components/_tiny/GoogleLink";
import {environment} from "../../../../env";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {SingleApprovalButtons} from "../../../../components/approval-acc-rej/SingleApprovalButtons";
import {getCatApprovalSubColumns} from "../CategorizationApprovalColumns";
import {CategorizationReviewFeedbackDelegate} from "../../../../stores/approval/CategorizationReviewFeedbackDelegate";
import {ApprovalStatusEnum} from "../../../../services/classes/AiClasses";
import moment from "moment/moment";


type Props = {
    part: SingularMatPartReviewRowState
};
export const SingularCategorizationApprovalRow: React.FC<Props> = observer(({part}) => {
    const {p, authStore, bagStore, approvalStore, categorizationReviewStore} = useStores();
    const COLUMNS = getCatApprovalSubColumns(p, approvalStore.approval?.baseline_bag?.id, true);

    const partState = CategorizationReviewFeedbackDelegate.calcSingleState(part);
    partState.editAllowed = !authStore.viewOnly && ApprovalStatusEnum.userCanUpdate(approvalStore.approval?.current_status.status);

    // FIXME: This Taxonomy size should come from the approvalStore?
    const leftCats = getSpecificCategorization(part, bagStore.taxonomy_size, partState.leftCat);
    const rightCats = getSpecificCategorization(part, bagStore.taxonomy_size, partState.rightCat);

    let onAccept: undefined | (() => void) = undefined;
    let onReject: undefined | (() => void) = undefined;
    if (partState.editAllowed) {
        onAccept = () => approvalStore.cat.reviewFeedback.clickAcceptReject(part, ReviewChoice.ACCEPT);
        onReject = () => approvalStore.cat.reviewFeedback.clickAcceptReject(part, ReviewChoice.REJECT);
        // if (approvalStore.cat.categories.taxonomy_categories) {
        //     categoryComponent = <PartReCategorizationComponent
        //         cats={currentCats}
        //         handleOpenSearch={event => {
        //             const anchor = event.currentTarget;
        //             controller.setOnSelect((newCats) => {
        //                 approvalStore.clickRecatPart(part, newCats)
        //             })
        //             controller.open(anchor)
        //         }}
        //     />
        // } else {
    }

    // TODO: Cleanup types of SingularMatPartReviewRowState
    const partWithSupplier = part as SingularMatPartReviewRowState & { s_name: string };
    let i = 0;

    return <TableRow className={part.isLastSelected ? ' is-last-selected' : ''}>
        <MithraTableCell c={COLUMNS[i++]} component="th" scope="row">
            <Checkbox checked={part.selected}
                      onClick={event => categorizationReviewStore.toggleSelectionSubRow(part, event.shiftKey)}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <GoogleLink type="part_with_supplier" objs={[part]}>
                {partWithSupplier.s_name}
            </GoogleLink>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            {environment.isTest && <>p_id={part.p_id}<br/></>}
            {part.p_name}
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                <CurrencyComponent v={part.p_spend} abbreviation={abbreviation}/>
            }</CurrencyAbbreviationContext.Consumer>
        </MithraTableCell>
        {p.p.p_description_col_name !== false &&
            <MithraTableCell c={COLUMNS[i++]}>
                <GoogleLink type="part" objs={[part]}>
                    {part.p_description}
                </GoogleLink>
            </MithraTableCell>
        }

        {p.partContextColNames.map(({dataField, colType}) =>
            <MithraTableCell key={dataField} c={COLUMNS[i++]}>
                {colType === 'date'
                    ? moment(part[dataField]).format('L')
                    : String(part[dataField])}
            </MithraTableCell>
        )}

        <MithraTableCell c={COLUMNS[i++]}>
            <PartCategorizationComponent cats={leftCats}/>
        </MithraTableCell>
        <MithraTableCell c={COLUMNS[i++]}>
            <PartCategorizationComponent cats={rightCats}/>
        </MithraTableCell>

        {p.showCategorizationScoreForBag(bagStore.bagId) &&
            <MithraTableCell c={COLUMNS[i++]}>
                {part.p_suggestion_score}
            </MithraTableCell>
        }

        <MithraTableCell c={COLUMNS[i++]}>
            <SingleApprovalButtons
                onAccept={onAccept}
                onReject={onReject}
                state={partState}
            />
        </MithraTableCell>
    </TableRow>
})
