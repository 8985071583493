import React from "react";

/**
 * Taken from https://loading.io/spinner/wedges/-pie-wedge-pizza-circle-round-rotate
 * - #0c3353
 * - #436185
 * - #0c3353
 * - #ffe7c9
 * - Opacity=0.6, Speed=0.56
 */
export const LoadingSpinnerPie: React.FC = () => {
    return <svg viewBox="0 0 100 100" width={50} height={50}>
        <g transform="translate(50 50)">
            <g transform="scale(1)">
                <g transform="translate(-50 -50)">
                    {/*<g>*/}
                    {/*    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.5625s"/>*/}
                    {/*    <path fillOpacity="0.92" fill="#dea01e" d="M50 50L50 0A50 50 0 0 1 100 50Z"/>*/}
                    {/*</g>*/}
                    {/*<g>*/}
                    {/*    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="2.0833333333333335s"/>*/}
                    {/*    <path fillOpacity="0.92" fill="#0c3353" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(90 50 50)"/>*/}
                    {/*</g>*/}
                    {/*<g>*/}
                    {/*    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="3.125s"/>*/}
                    {/*    <path fillOpacity="0.92" fill="#ffe7c9" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(180 50 50)"/>*/}
                    {/*</g>*/}
                    {/*<g>*/}
                    {/*    <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" values="0 50 50;360 50 50" keyTimes="0;1" dur="6.25s"/>*/}
                    {/*    <path fillOpacity="0.92" fill="#436185" d="M50 50L50 0A50 50 0 0 1 100 50Z" transform="rotate(270 50 50)"/>*/}
                    {/*</g>*/}
                    <g>
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                                          values="0 50 50;360 50 50" keyTimes="0;1" dur="0.4464285714285714s"/>
                        <path fillOpacity="0.6" fill="#0c3353" d="M50 50L50 0A50 50 0 0 1 100 50Z"/>
                    </g>
                    <g>
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                                          values="0 50 50;360 50 50" keyTimes="0;1" dur="0.5952380952380952s"/>
                        <path fillOpacity="0.6" fill="#436185" d="M50 50L50 0A50 50 0 0 1 100 50Z"
                              transform="rotate(90 50 50)"/>
                    </g>
                    <g>
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                                          values="0 50 50;360 50 50" keyTimes="0;1" dur="0.8928571428571428s"/>
                        <path fillOpacity="0.6" fill="#0c3353" d="M50 50L50 0A50 50 0 0 1 100 50Z"
                              transform="rotate(180 50 50)"/>
                    </g>
                    <g>
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite"
                                          values="0 50 50;360 50 50" keyTimes="0;1" dur="1.7857142857142856s"/>
                        <path fillOpacity="0.6" fill="#ffe7c9" d="M50 50L50 0A50 50 0 0 1 100 50Z"
                              transform="rotate(270 50 50)"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}
// <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="154px" height="154px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">

// <!-- [ldio] generated by https://loading.io/ --></svg>
