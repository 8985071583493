import {makeAutoObservable, runInAction} from "mobx";
import MithraDataIngestionApi from "../services/MithraDataIngestionApi";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {BagStore} from "./BagStore";
import ProfileStore from "./ProfileStore";
import {
    ClientArea,
    ClientColumn,
    MappingTableItemType,
    MithraArea,
    MithraColumn
} from "../pages/data-management/data-mapping/MappingTableTypes";
import {
    DataFileUploadSerializer,
    DataFrameSerializer,
    DataMappingResultTableSerializer,
    DataMappingSerializer,
    KpiListSerializer,
    MithraColumnSerializer
} from '../services/ApiTypes';
import {v4 as uuid} from "uuid";
import {History} from "history";
import {match as Match} from "react-router";
import {DataFileRouteMatch, routes} from "../routing/routes";
import AuthStore from "./AuthStore";


type Page = 'dataset_upload'
    | 'data_mapping'
    | 'data_check'
    | 'data_finish'

type ResultTableRows = {
    bu__id: string | number
    bu__name: string
    s__id: string | number
    s__name: string
    s__city: string
    p__id: string | number
    p__name: string
    p__spend: string | number
    p__description: string
    p__context_1: string
    p__purchase_date: string
    p__quantity: string | number
    p__in_l1: string | number
    p__in_l2: string | number
    p__in_l3: string | number
}


export default class DataIngestionStore {

    dataFile: DataFileUploadSerializer | undefined
    dataFileNotFound: boolean | undefined
    datasetList
    datasetName: string = ''
    dataFileId: number | undefined
    dataMapping: DataMappingSerializer[] | undefined
    dataMappingResult: KpiListSerializer | undefined
    dataMappingResultTableUnformatted: DataMappingResultTableSerializer | undefined
    dataMappingResultTableFormatted: ResultTableRows[] | undefined
    mithraColumns: MithraColumnSerializer[] | undefined
    clientColumns: DataFrameSerializer | undefined
    allowedOperations
    clientAreaState: ClientArea = []
    mithraAreaState: MithraArea = []
    step = 0;
    page: Page | undefined = undefined;
    errorsToShow: string[] = [];

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private dataIngestionApi: MithraDataIngestionApi,
        private bagStore: BagStore,
        private authStore: AuthStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    getDatasetList() {
        this.dataIngestionApi.getDatasetList().then((data) => {
            // If we want to add a loading state here we can use the `AxoisRequestManager` helper class
            runInAction(() => {
                console.log('Dataset list: ');
                console.log(data);
                this.datasetList = data
            })
        })
    }

    async uploadDatafile(file: File) {
        const data = await this.dataIngestionApi.uploadDatafile(file, this.datasetName).then((data) => {
            console.log('File uploaded! ' + data.id);
            this.setDataFileId(data.id)
            this.resetErrorToShow();
            return data
        }).catch((error) => {
            console.error('Error uploading file!!!');
            console.error(error);
            this.resetErrorToShow();
            this.pushErrorToShow("Error uploading file")
            throw error;
        })
        return data.id
    }

    async startAiMapping(dataFileId: number) {
        await this.dataIngestionApi.startAiDataMapping(dataFileId)
            .then(() => {
                console.log('AI Mapping started...');
                this.resetErrorToShow();
            }).catch((error) => {
                console.error('Error starting AI Mapping...');
                console.error(error);
                this.resetErrorToShow();
                this.pushErrorToShow("Error starting AI Mapping")
                throw error;
            })
    }

    setInitialStateMithraArea(mithraColumns: MithraColumnSerializer[], dataMapping) {
        const listOfMithraColumns = mithraColumns
        const mithraAreaToSet: MithraArea = []
        let itemArray: MappingTableItemType[] = []

        for (const mithraColumn of listOfMithraColumns) {
            for (const mithraItem of dataMapping) {
                if (mithraColumn.key === mithraItem.mithra_column_key) {
                    console.log('mithraItem');
                    console.log(mithraItem);
                    const tempItem: MappingTableItemType = {
                        name: mithraItem.client_column_name,
                        example: this.clientColumns?.data[0][mithraItem.client_column_index],
                        index: mithraItem.client_column_index,
                        id: uuid(),
                        parentList: mithraItem.client_column_name,
                        type: mithraItem.client_column_type,
                        ai_result: mithraItem.ai_result,
                        user_result: mithraItem.user_result,
                        // column_letter: mithraItem.column_letter,
                        column_letter: this.clientColumns?.columns_letters[mithraItem.client_column_index] || '',
                    }
                    itemArray.push(tempItem)
                }
            }

            const tempColumn: MithraColumn = {
                id: uuid(),
                items: itemArray,
                name: mithraColumn.name,
                type: mithraColumn.type,
                key: mithraColumn.key,
                description: mithraColumn.description,
                example: mithraColumn.example,
                disabled: false,
                is_required: mithraColumn.is_required,
            }
            itemArray = []
            mithraAreaToSet.push(tempColumn)
        }

        runInAction(() => {
            this.mithraColumns = mithraColumns
            this.mithraAreaState = mithraAreaToSet
            console.log(this.mithraAreaState)
            console.log(this.mithraColumns)
        });

    }

    setInitialStateClientArea(clientColumns: DataFrameSerializer) {
        const copyClientColumns = clientColumns
        const clientAreaToSet: ClientArea = []

        for (let i = 0; i < copyClientColumns.columns.length; i++) {
            const temp: ClientColumn = {
                id: uuid(),
                name: copyClientColumns.columns[i],
                example: copyClientColumns.data[0][i],
                index: copyClientColumns.column_indexes[i],
                type: copyClientColumns.types[i],
                column_letter: copyClientColumns.columns_letters[i],
            }
            clientAreaToSet.push(temp)
        }
        this.clientColumns = clientColumns
        this.clientAreaState = clientAreaToSet
    }

    /**
     * Delete item from list, this function is passed to the child component via context
     * @param parentMithraColumn id of the parent list
     * @param itemId of the item to delete inside the parent list
     * @returns void
     */
    deleteItemFromList = (parentMithraColumn: string, itemId: string) => {
        const newMithraArea = [...this.mithraAreaState];
        const mithraColumn = this.mithraAreaState.findIndex((e) => e.id === parentMithraColumn);
        const itemToDeleteIndex = this.mithraAreaState[mithraColumn].items.findIndex((e) => e.id === itemId);
        newMithraArea[mithraColumn].items.splice(itemToDeleteIndex, 1);
        this.mithraAreaState = newMithraArea;
    }

    async postDataMappingsList() {
        if (!this.dataFileId) {
            throw new Error('Data file id is not set');
        }
        const arrayToPush: DataMappingSerializer[] = []

        for (const mithraColumn of this.mithraAreaState) {
            for (const item of mithraColumn.items) {
                console.log('item');
                console.log(item);

                const temp: DataMappingSerializer = {
                    data_file: this.dataFileId,
                    client_column_index: item.index,
                    client_column_name: item.name,
                    client_column_type: item.type,
                    mithra_column_key: mithraColumn.key,
                    user_result: item.user_result,
                    ai_result: item.ai_result,
                }
                arrayToPush.push(temp)

            }
        }

        /*
        * 1) post mappings
        * 2) apply mappings
        * 3) get mapping result table
        * 3) get KPIs
        * */

        await this.dataIngestionApi.postDataMappingList(arrayToPush).then((data) => {
            console.log('Data mapping list posted...');
            console.log(data);
            this.resetErrorToShow()

        }).catch((err) => {
            this.resetErrorToShow()
            this.pushErrorToShow("Failed to post data mapping list")
            console.error(err)
            throw new Error("Error uploading file");
        })
        await this.dataIngestionApi.applyDataMapping(this.dataFileId).then((data) => {
            console.log('Data mapping applied...');
            console.log(data);
            this.resetErrorToShow()
        }).catch((err) => {
            this.resetErrorToShow()
            this.pushErrorToShow("Failed to apply data mapping")
            console.error(err)
            throw new Error("Error uploading file");
        })

        const promises = [
            this.dataIngestionApi.getMappingResultTable(this.dataFileId).then((data) => {
                runInAction(() => {
                    this.dataMappingResultTableUnformatted = data
                    this.dataMappingResultTableFormatted = this.convertResponseToResultTable(data)
                });
            }).catch((err) => {
                this.pushErrorToShow("Failed to get mapping result table")
                console.error(err)
                throw new Error("Failed to get mapping result table");
            }),

            this.dataIngestionApi.getKpiList(this.dataFileId).then((data) => {
                runInAction(() => {
                    this.dataMappingResult = data
                });
            }).catch((err) => {
                this.pushErrorToShow("Failed to get KPI list")
                console.error(err)
                throw new Error("Failed to get KPI list");
            })
        ];

        await Promise.all(promises);

    }

    resetIngestionStore() {
        this.datasetList = []
        this.datasetName = ''
        this.dataFileId = undefined
        this.dataMapping = []
        this.allowedOperations = []
        this.clientAreaState = []
        this.mithraAreaState = []
        this.step = 0;
        this.page = 'dataset_upload';
    }


    navigateToPage(page: Page) {
        switch (page) {
            case "dataset_upload":
                this.step = 0;
                break;
            case "data_mapping":
                this.step = 1;
                break;
            case "data_check":
                this.step = 2;
                break;
            case "data_finish":
                this.step = 3;
                break;
        }
        this.page = page;
    }

    get all_required_columns_mapped(): boolean {
        for (const mithraColumn of this.mithraAreaState) {
            if (mithraColumn.items.length === 0 && mithraColumn.is_required) {
                return false
            }
        }
        return true;
    }

    convertResponseToResultTable(response: DataMappingResultTableSerializer): ResultTableRows[] {
        const columnMap: Record<string, keyof ResultTableRows> = {
            bu__id: 'bu__id',
            bu__name: 'bu__name',
            s__id: 's__id',
            s__name: 's__name',
            s__city: 's__city',
            p__id: 'p__id',
            p__name: 'p__name',
            p__spend: 'p__spend',
            p__description: 'p__description',
            p__context_1: 'p__context_1',
            p__purchase_date: 'p__purchase_date',
            p__quantity: 'p__quantity',
            p__in_l1: 'p__in_l1',
            p__in_l2: 'p__in_l2',
            p__in_l3: 'p__in_l3',
        };

        const resultTable: ResultTableRows[] = response.data.map((row) =>
            row.reduce((obj, value, index) => {
                const columnName = response.columns[index];
                const key = columnMap[columnName];
                obj[key] = value;
                return obj;
            }, {} as ResultTableRows)
        );

        return resultTable;
    };


    initDataFile(
        history: History<unknown>,
        match: Match<DataFileRouteMatch>
    ) {
        const dataFileId = match.params.dataFileId;
        const id = Number(dataFileId);
        console.log('INIT DATAFILE: id');
        console.log(id);
        if (isNaN(id)) {
            console.log('id is NaN')
            this.setDataFileNotFound()
            return
        }
        this.setDataFile(id)
            .catch((err) => {
                    const unAuth = this.authStore.authentication.catchApiError(err);
                    console.log('Error in initDataFile: ')
                    console.log(err)

                    if (err) {
                        console.log('initDataFile: Error loading file')
                        this.resetErrorToShow()
                        this.pushErrorToShow("Failed to load file")
                        this.setDataFileNotFound()
                        history.push(routes.data_upload);
                        return
                    } else if (unAuth) {
                        console.log('initDataFile: Error authentication')
                        this.setDataFileNotFound()
                        history.push(routes.login);
                        return
                    }
                }
            )
    }


    async setDataFile(dataFileId: number) {
        const promiseGetDataMappingList = this.dataIngestionApi.getDataMappingList(dataFileId)
        const promiseGetAllClientColumns = this.dataIngestionApi.getAllClientColumns(dataFileId)
        const promiseGetAllMithraColumns = this.dataIngestionApi.getAllMithraColumns()
        const promiseGetAllowedOperations = this.dataIngestionApi.getAllowedOperations()
        const promiseGetDataFile = this.dataIngestionApi.getDataset(dataFileId)

        await Promise.all([promiseGetDataMappingList, promiseGetAllClientColumns, promiseGetAllMithraColumns, promiseGetAllowedOperations, promiseGetDataFile])
            .then((data) => {

                console.log('Data file loaded...');

                runInAction(() => {
                    this.dataMapping = data[0]
                    this.clientColumns = data[1]
                    this.mithraColumns = data[2]
                    this.allowedOperations = data[3]

                    this.dataFileId = dataFileId
                    this.dataFile = data[4]
                    this.dataFileNotFound = false;

                    //The order of the following two lines is important because the mithraAreaState depends on the clientAreaState
                    this.setInitialStateClientArea(this.clientColumns)
                    this.setInitialStateMithraArea(this.mithraColumns, this.dataMapping)

                    this.resetErrorToShow()
                })

            })
            .catch((err) => {
                this.resetErrorToShow()
                this.pushErrorToShow("Error loading file")
                console.error("SetDataFile: Error loading file");
                console.error(err);
                throw err
            })
    }

    setDataFileNotFound() {
        this.dataFileNotFound = true;
        this.dataFile = undefined;
        this.dataFileId = undefined;
    }

    resetErrorToShow() {
        this.errorsToShow = [];
    }

    pushErrorToShow(error: string) {
        this.errorsToShow.push(error);
    }

    setDataFileId(dataFileId: number) {
        this.dataFileId = dataFileId;
    }

}