import React from "react";
import {SingleHeader} from "../../pages/default-menus/SingleHeader";
import {MultiHeader} from "../../pages/multi-dashboard/menu/MultiHeader";
import {MainComponentProp} from "../../components/main/MainComponent";
import {environment} from "../../env";
import {MergeXHeader} from "../../pages/multi-dashboard/merge-x-dashboard/MergeXHeader";

export const HeaderWrapper: React.FC<MainComponentProp> = ({state}) => {
    // console.debug('Re-rendering HeaderWrapper', state?.dashEnv)

    const testElement = environment.showDebugInfoInMenu
        ? <div style={{background: 'olivedrab'}}>
            <span style={{textDecoration: "line-through"}}>dashEnv:{state?.dashEnv}</span>
            &nbsp;dashEnv2:{state?.dashboardEnvironment}
            &nbsp;PACKAGE:{environment.package}.{environment.dashboardVariant}
        </div> : <></>;

    let headerElement: JSX.Element | undefined;
    if (environment.package === 'merge_x') {
        headerElement = <MergeXHeader/>
    } else {
        switch (state?.dashEnv) {
            case 'simple':
                headerElement = <SingleHeader/>;
                break;
            case 'dual':
                headerElement = <SingleHeader/>;
                break
            case 'multi':
                headerElement = <MultiHeader/>;
                break
        }
    }

    return <>
        {testElement}
        {headerElement}
    </>;
}
