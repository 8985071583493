import React from "react";
import './SynergySuppliersDetail.scss';
import {Card, CardContent, Grid} from "@mui/material";
import {DataColumn, DataTable} from "../../../components/data-table/DataTable";
import {HARDCODED_COMMON_SUPPLIERS, HARDCODED_SELECTED_SUPPLIER} from "./SynergySuppliersDetail_data";
import Typography from "@mui/material/Typography";
import {useStores} from "../../../stores";

const PART_COLUMNS: DataColumn[] = [
    {dataIndex: 'p__name', headerText: 'Part name', ltr: true,},
    {dataIndex: 'part__l1', headerText: 'L1', ltr: true,},
    {dataIndex: 'part__l2', headerText: 'L2', ltr: true,},
    {dataIndex: 'p__spend', headerText: 'Spend', dataCategory: 'currency',},
]
const SUPPLIER_COLUMNS: DataColumn[] = [
    {dataIndex: 's__parent_name', headerText: 'Parent supplier', ltr: true,},
    {dataIndex: 's__name', headerText: 'Supplier name', ltr: true,},
    {dataIndex: 'spend_a', headerText: 'Spend (selected dataset)', dataCategory: 'currency',},
    {
        dataIndex: 'spend_combined',
        headerText: 'Spend (combined)',
        dataCategory: 'currency',
        help: 'Total spend if both dataset would have been combined',
    },
    {dataIndex: 'spend_b', headerText: 'Spend (master dataset)', dataCategory: 'currency',},
]

type HeadProps = {
    supplierData: any | undefined;
}
const SelectedSupplierDetail: React.FC<HeadProps> = ({supplierData}) => {
    const {cmpStore} = useStores();
    return <Card className="supplier-detail">
        {/*<CardHeader*/}
        {/*    // avatar={avatar}*/}
        {/*    // action={!helpContent ? undefined : <HelpComponent onChange={setHelpOpened} helpContent={helpContent}/>}*/}
        {/*    title={}*/}
        {/*    titleTypographyProps={{variant: "h6"}}*/}
        {/*/>*/}
        <CardContent>

            <Typography variant="h4" component="h2" className="title">
                Selected supplier: {supplierData?.s__name || 'none'}
            </Typography>

            <Grid className="text-center"
                  container spacing={4} direction="row">
                <Grid item xs={6}>
                    <p>Categories in {cmpStore.getCmpBagName()}:</p>
                    <p>
                        {supplierData
                            ? supplierData.a.categories.map((c: string[]) => {
                                const s = c.join(', ');
                                return <span key={s}>{s}<br/></span>;
                            })
                            : '-'
                        }
                    </p>
                </Grid>
                <Grid item xs={6}>
                    <p>Categories in {cmpStore.getMasterBagName()}:</p>
                    <p>
                        {supplierData
                            ? supplierData.b.categories.map((c: string[]) => {
                                const s = c.join(', ');
                                return <span key={s}>{s}<br/></span>;
                            })
                            : '-'
                        }
                    </p>
                </Grid>
            </Grid>
            {supplierData &&
                <Grid container spacing={4} direction="row">
                    <Grid item xs={6}>
                        <DataTable className="alt-rows extra-narrow-pagination" fluidHeight={true}
                                   data={supplierData.a.parts} tableColumns={PART_COLUMNS}/>
                    </Grid>
                    <Grid item xs={6}>
                        <DataTable className="alt-rows extra-narrow-pagination" fluidHeight={true}
                                   data={supplierData.b.parts} tableColumns={PART_COLUMNS}/>
                    </Grid>
                </Grid>
            }
        </CardContent>
    </Card>
    // return <section className="synergy-suppliers-head cmp-head text-center">
    //     <header>
    //         <Typography><span>Selected supplier:</span> {}</Typography>
    //     </header>
    //     <Grid className="key-value-container"
    //           container direction="column" spacing={5}
    //           style={{justifyContent: "space-between"}}>
    //         <Grid item>
    //             <Typography variant="caption" className="key-value-title">
    //                 Total suppliers
    //             </Typography>
    //             <Typography variant="h5" className="font-weight-bold key-value">
    //                 {keyValues
    //                     ? <AnnotatedValueComponent v={keyValues.n_suppliers || 0}/>
    //                     : <>&nbsp;</>}
    //             </Typography>
    //         </Grid>
    //     </Grid>
    // </section>
};

const supplierData = HARDCODED_COMMON_SUPPLIERS.map((sd, i) => ({
    id: `id${i}`,
    s__parent_name: sd.s__parent_name,
    s__name: sd.s__name,
    spend_a: sd.a.s__spend,
    spend_b: sd.b.s__spend,
    spend_combined: sd.combined.s__spend,
}));

export const SynergySuppliersDetail: React.FC = () => {
    // const routeMatch = useRouteMatch<CmpDataBagRouteMatch>();

    const [selectedSupplier, setSelectedSupplier] = React.useState<undefined | any>(undefined);

    const onSupplierSelection = {
        dataKeySelector: (d, i) => d.id,
        onSelectData: setSelectedSupplier,
    };

    function generate(d, H) {
        const r = {...H};
        r.s__parent_name = d.s__parent_name
        r.s__name = d.s__name
        r.a = {...r.a};
        r.b = {...r.b};
        return r;
    }

    return <section className="page-item synergy-suppliers-page">
        <Grid className=""
              container spacing={4} direction="row"
              style={{justifyContent: "space-evenly"}}>
            <Grid item xs={5}>
                <Typography variant="h4" component="h2" className="title">
                    Common suppliers
                </Typography>
                <DataTable className="alt-rows" fluidHeight={true}
                           onSelection={onSupplierSelection}
                           data={supplierData} tableColumns={SUPPLIER_COLUMNS}/>
            </Grid>
            <Grid item xs={6}>
                <SelectedSupplierDetail
                    supplierData={selectedSupplier ? generate(selectedSupplier, HARDCODED_SELECTED_SUPPLIER) : undefined}/>
            </Grid>
        </Grid>
    </section>
};
