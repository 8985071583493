import React from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {useStores} from '../../../stores'
import {Button, Typography} from '@mui/material'
import {CloseOutlined} from '@mui/icons-material';

type Props = {
    name: string
    example: string
    index: number
    id: string
    parentList: string
    isClone?: boolean
    ai_result?: boolean
    column_letter?: string
}
export const MappingTableItem: React.FC<Props> = ({
                                                      name,
                                                      example,
                                                      index,
                                                      id,
                                                      parentList,
                                                      isClone,
                                                      ai_result,
                                                      column_letter
                                                  }) => {

    const {dataIngestionStore} = useStores();

    if (isClone) {
        //This is the clone that will stay in the client area
        return (
            <>
                <div className="react-beatiful-dnd-copy">
                    {/*sx={{wordBreak: "break-word"}}*/}
                    <Typography className='item-name' sx={{wordBreak: "break-word"}}>
                        {column_letter}: {name}
                    </Typography>
                    {/*sx={{color: 'text.secondary'}}*/}
                    <Typography className="item-example" sx={{color: 'text.secondary'}}>
                        {example}
                    </Typography>
                </div>
            </>
        )
    } else {

        if (parentList === 'CLIENTCOLUMN') {
            //This will be the item in the client area
            return (
                <Draggable key={id} draggableId={id} index={index}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                            className="item"
                        >
                            <Typography className='item-name'>
                                {column_letter}: {name}
                            </Typography>
                            <Typography className='item-example' variant="body1"> {example} </Typography>
                        </div>
                    )}
                </Draggable>
            )
        } else {
            //This will be the item in the mithra area

            if (ai_result) {
                return (
                    <Draggable key={id} draggableId={id} index={index}>
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={provided.draggableProps.style}
                                className="item ai-suggestion"
                            >
                                <div className='item-wrapper' style={{display: 'flex', alignItems: 'center'}}>

                                    <Typography className='item-name'
                                                sx={{
                                                    width: '70%',
                                                    flexShrink: 0,
                                                    overflow: 'hidden'
                                                }}> {name} (AI)
                                    </Typography>

                                    <Typography className='item-delete-button'>
                                        <Button
                                            endIcon={<CloseOutlined/>}
                                            onClick={() => {
                                                dataIngestionStore.deleteItemFromList(parentList, id)
                                            }
                                            }
                                        ></Button>
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </Draggable>
                )
            } else {
                return (
                    <Draggable key={id} draggableId={id} index={index}>
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={provided.draggableProps.style}
                                className="item"
                            >
                                <div className='item-wrapper' style={{display: 'flex', alignItems: 'center'}}>

                                    <Typography className='item-name'
                                                sx={{
                                                    width: '70%',
                                                    flexShrink: 0,
                                                    overflow: 'hidden'
                                                }}> {name}
                                    </Typography>

                                    <Typography className='item-delete-button'>
                                        <Button
                                            endIcon={<CloseOutlined/>}
                                            onClick={() => {
                                                dataIngestionStore.deleteItemFromList(parentList, id)
                                            }
                                            }
                                        ></Button>
                                    </Typography>
                                </div>
                            </div>
                        )}
                    </Draggable>
                )
            }


            // return (
            //     <Draggable key={id} draggableId={id} index={index}>
            //         {(provided) => (
            //             <div
            //                 ref={provided.innerRef}
            //                 {...provided.draggableProps}
            //                 {...provided.dragHandleProps}
            //                 style={provided.draggableProps.style}
            //                 className="item"
            //             >
            //                 <div className='item-wrapper' style={{display: 'flex', alignItems: 'center'}}>
            //
            //                     {ai_result ? <Typography className='item-name'
            //                                              sx={{
            //                                                  width: '70%',
            //                                                  flexShrink: 0,
            //                                                  overflow: 'hidden'
            //                                              }}> {name} (AI) </Typography> :
            //                         <Typography className='item-name'
            //                                     sx={{
            //                                         width: '70%',
            //                                         flexShrink: 0,
            //                                         overflow: 'hidden'
            //                                     }}>
            //                             {column_letter}: {name}
            //                         </Typography>}
            //
            //                     <Typography className='item-delete-button'>
            //                         <Button
            //                             endIcon={<CloseOutlined/>}
            //                             onClick={() => {
            //                                 dataIngestionStore.deleteItemFromList(parentList, id)
            //                             }
            //                             }
            //                         ></Button>
            //                     </Typography>
            //                 </div>
            //             </div>
            //         )}
            //     </Draggable>
            // )
        }
    }
}
