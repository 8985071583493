import React from "react";
import {Container} from "@mui/material";
import {MainHeader} from "./MainHeader";
import {MainFooter} from "./MainFooter";
import {AppState} from "../../stores/RootAppStore";

/**
 * All main components, should be readable without observables for sake of speed and simplicity
 */
export type MainComponentProp = {
    state?: AppState
}

type Props = MainComponentProp & {
    dropElement?
    header: React.ReactNode
}

export const MainComponent: React.FC<Props> = ({dropElement, state, header, children}) => {
    const className = (state?.fluid ? ' fluid' : '') + (state?.dashType ? ` ${state.dashType}` : '');
    const body = <>
        <MainHeader>{header}</MainHeader>
        {children}
        <MainFooter/>
    </>;
    return (
        <div ref={dropElement} className={'main-component min-vh-100' + className}>
            {state?.fluid
                ? <Container maxWidth={false} className="main-component-container">{body}</Container>
                : <Container maxWidth="xl" className="main-component-container">{body}</Container>
            }
        </div>
    )
};
