export type CCSubData = {
    key: string;
    group_value: number;
    group_start_value: number;
    active: boolean;
}
export type CCDataPoint = {
    name: string;
    label: string;
    active_combined_value: number;
    total_combined_value: number;
    graph_parts: CCSubData[];
}
export type CCSubDataPoint = [CCDataPoint, CCSubData];

export const byCombinedThenTotal = (a: CCDataPoint, b: CCDataPoint) => {
    const cmp = b.active_combined_value - a.active_combined_value;
    if (cmp === 0) {
        return b.total_combined_value - a.total_combined_value;
    } else {
        return cmp;
    }
}

export class ParetoSpec {
    pareto: (p: number) => number;

    constructor(private param = 0.8) {
        const a = param;
        const X = Math.log(1 - a) / Math.log(1 / (2 - a));
        // const MAX = cumulativeTotal;
        this.pareto = p => {
            // const x = p * plotPercentage;
            const x = p;
            const r = 1 - Math.pow((1 / ((x) + 1)), X); // r \in (0, 1)
            // console.log(`p[a=${a}, max=${MAX}] (${p}) = ${r}`);
            // return r * MAX;
            return r;
        };
    }

    getData(samples: number, x0: number, deltaX: number) {
        const dx = deltaX / (samples - 1);
        const data = [...Array(samples)].map((_, i) => {
            const percentageX = i / (samples - 1);
            const paretoX = x0 + i * dx;
            const y = this.pareto(paretoX);
            return {x: percentageX, y};
        })
        // console.log('data', data);
        return data;
    }
}
