import {makeAutoObservable} from "mobx";
import ProfileStore from "./ProfileStore";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {BagStore} from "./BagStore";
import AuthStore from "./AuthStore";
import {
    ApiSuggestionTreeResponse,
    ApiUpdateSuggestionTreeResponse,
    ReviewStatusApiDataType
} from "../services/ApiHelpers";
import TaxonomyManagerStore from "./TaxonomyManagerStore";
import {m_taxonomy} from "../services/classes/TaxonomyClasses";


// Volatile state in FE
export type ReviewStatusChangesType = {
    hasChangesInChildren: boolean
}

// // Persistent state in BE
// ReviewStatusApiDataType

export type SuggestionTreeStoreType<T> = {
    label: string;
    children: SuggestionTreeStoreType<T>[];
    values: T;
    review?: {
        apiData: ReviewStatusApiDataType;
        stateData: ReviewStatusChangesType;
    };
}

export type ResultTableRows = {
    p__description: string
    d__source: string
    s__name: string
    p__spend: string | number
    p__purchase_date: string
}


export type SelectionTableRows = {
    p__spend: string | number
    p__spend_group_a: string | number
    p__spend_group_b: string | number
    p__spend_group_c: string | number
    s_suppliers: string | number
    p__transactions: string | number
}

export type TableColumns = {
    columnTitle: string
    getValue: (d: any, c: any) => string
    xOffset: number
}


export default class TaxonomySuggestorStore {

    originalTaxonomyId: number = 0
    suggestedTaxonomyId: number = 1
    taxonomyApiResponse: ApiSuggestionTreeResponse<m_taxonomy.Data> | undefined = undefined
    taxonomySuggestionState: SuggestionTreeStoreType<m_taxonomy.Data> | undefined = undefined
    // selectedNode: SuggestionTreeStoreType<m_taxonomy.Data> | undefined = undefined
    numberOfAdditions: number = 0
    numberOfDeletions: number = 0
    numberOfRenames: number = 0
    selectedNodeLabel: string | undefined = ''
    selectedNodeData: any | undefined = undefined
    tableColumns: TableColumns[] = [
        {
            columnTitle: 'Total Spend',
            getValue: (d, c) => String(d.data.values.p__spend__sum),
            xOffset: 420,
        },
        {
            columnTitle: 'Spend group A',
            getValue: (d, c) => '100',
            xOffset: 520,
        },
        {
            columnTitle: 'Spend group B',
            getValue: (d, c) => String(d.data.values.p__spend__sum),
            // getValue: (d, c) => '100',
            xOffset: 620,
        },
        {
            columnTitle: 'Spend group C',
            // getValue: (d, c) => String(d.data.values.p__spend__sum),
            getValue: (d, c) => '100',
            xOffset: 720,
        },
        {
            columnTitle: 'Suppliers',
            // getValue: (d, c) => String(d.data.values.p__spend__sum),
            getValue: (d, c) => '100',
            xOffset: 820,
        },
        {
            columnTitle: 'Transactions',
            // getValue: (d, c) => String(d.data.values.p__spend__sum),
            getValue: (d, c) => '100',
            xOffset: 920,
        },
    ]

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private authStore: AuthStore,
        private taxonomyManagerStore: TaxonomyManagerStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    async getTaxonomySuggestion(suggestedTaxonomyId: number) {
        return await this.api.getTaxonomySuggestions(suggestedTaxonomyId).then((data) => {
            console.log('Got taxonomy suggestions')
            console.log(data)
            this.setTaxonomySuggestionState(data.suggestion_state)
            this.setTaxonomyApiResponse(data.suggestion_state)
            this.countSuggestions(data.suggestion_state)
            return

        }).catch((error) => {
            console.error('Error while getting taxonomy suggestions');
            console.error(error);
            throw error;
        })
    }

    async updateTaxonomySuggestionValues() {
        return await this.api.updateTaxonomySuggestionValues(this.suggestedTaxonomyId).then((data) => {
            console.log('Updated taxonomy suggestions')
            return
        }).catch((error) => {
            console.error('Error while updating taxonomy suggestions');
            console.error(error);
            throw error;
        })
    }

    setTaxonomySuggestionState(apiResponse: ApiSuggestionTreeResponse<m_taxonomy.Data>) {
        this.taxonomySuggestionState = this.extractTaxonomySuggestionState(apiResponse);
    }

    extractTaxonomySuggestionState(apiResponse: ApiSuggestionTreeResponse<m_taxonomy.Data>): SuggestionTreeStoreType<m_taxonomy.Data> {
        const {label, children, values, review_status} = apiResponse;
        const {...reviewApiData} = review_status ?? {};

        const storeNode: SuggestionTreeStoreType<m_taxonomy.Data> = {
            label,
            children: [],
            values,
            review: {
                apiData: reviewApiData,
                stateData: {hasChangesInChildren: false}
            }
        };

        if (children) {
            storeNode.children = children.map(child => this.extractTaxonomySuggestionState(child));
        }

        return storeNode;
    }

    async putTaxonomySuggestion(state: ApiUpdateSuggestionTreeResponse<m_taxonomy.Data>) {

        console.log('Updating taxonomy suggestions')
        console.log(state)

        return await this.api.putTaxonomySuggestions(this.suggestedTaxonomyId, state).then((data) => {
            console.log('Updated taxonomy suggestions')
            console.log(data)
            return
        }).catch((error) => {
            console.error('Error while updating taxonomy suggestions');
            console.error(error);
            throw error;
        })
    }


    setTaxonomyApiResponse(taxonomyApiResponse: ApiSuggestionTreeResponse<m_taxonomy.Data>) {
        this.taxonomyApiResponse = taxonomyApiResponse;
    }

    saveTaxonomySuggestion() {
        this.api.saveTaxonomySuggestions(this.suggestedTaxonomyId)
    }

    // setSelectedNode(node: SuggestionTreeStoreType<m_taxonomy.Data> | undefined) {
    //     this.selectedNode = node
    // }

    setSelectedNodeLabel(label: string | undefined) {
        this.selectedNodeLabel = label
    }

    setSelectedNodeValues(data: m_taxonomy.Data | undefined) {
        this.selectedNodeData = data
    }

    // selectedPreviousNode(node: SuggestionTreeStoreType<m_taxonomy.Data> | undefined) {
    //     if (!this.selectedNode) {
    //         if (this.selectedNode == ){
    //             return true
    //         }
    //     }
    //     return false
    // }

    countSuggestions(suggestion_state: ApiSuggestionTreeResponse<m_taxonomy.Data>) {
        const iterateSuggestions = (suggestion) => {
            if (suggestion?.review_status?.added) {
                this.numberOfAdditions += 1;
            } else if (suggestion?.review_status?.deleted) {
                this.numberOfDeletions += 1;
            } else if (suggestion?.review_status?.renamed) {
                this.numberOfRenames += 1;
            }
            if (suggestion?.children) {
                suggestion.children.forEach(child => iterateSuggestions(child));
            }
        };
        iterateSuggestions(suggestion_state);
    }

}
